<template>
	<div class="field-type">
		<div class="field-input">
			<el-input
				v-if="items.type==='INPUT'"
				:placeholder="items.extInfo.placeholder"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				:class="(items.extInfo.inputType==='NUMBER'|| items.extInfo.inputType==='RATE')?'number-input':''"
				:maxlength="items.extInfo.maxNum?Number(items.extInfo.maxNum):100"
				:type="items.extInfo.inputType==='NUMBER'|| items.extInfo.inputType==='RATE'? 'number':'text'"
				@blur="typeCheck(items)"
				:min="items.extInfo.inputType==='NUMBER'|| items.extInfo.inputType==='RATE'?Number(items.extInfo.range.start):''"
				:max="items.extInfo.inputType==='NUMBER'|| items.extInfo.inputType==='RATE'?Number(items.extInfo.range.end):''"
				v-model="fieldValue[items.id]"
				@change="changeFieldValues(items)"
				@input="changeFieldValues(items)"
			></el-input>
			<el-input
				v-else-if="(items.type==='TEXTAREA')"
				type="textarea"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				:maxlength="items.extInfo.maxNum?Number(items.extInfo.maxNum):300"
				:placeholder="items.extInfo.placeholder"
				@change="changeFieldValues(items)"
				@input="changeFieldValues(items)"
			></el-input>
			<!-- <ckeditor
				:editor="ckeditor.editor"
				:config="editorConfig"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-else-if="items.type==='RICHTEXT'"
				v-model="fieldValue[items.id]"
			></ckeditor> -->
			<!-- <custom-upload
				:items="items"
				:fieldValue="fieldValue"
				:extInfoFieldValue="extInfoFieldValue"
				v-else-if="(items.type==='ATTACHMENT'||
							items.type==='VIDEO'||
							items.type==='IMAGE')"
				@uploadSuccess="()=>{
					$emit('changeFieldValues')
				}"
				ref="customUpload"
			></custom-upload> -->
			<div v-else-if="items.type ==='CASCADER'" class="cascade-class" @click="openCascadeOption(items)">
				<template v-if="extInfoFieldValue && extInfoFieldValue[items.id] &&extInfoFieldValue[items.id].length !== 0">
					<div v-if="items.extInfo&&items.extInfo.selectType ==='单选'">
						{{ extInfoFieldValue && extInfoFieldValue[items.id] && extInfoFieldValue[items.id].length !== 0 && extInfoFieldValue[items.id][0]?extInfoFieldValue[items.id][0].value:''}}
					</div>
					<div
						v-else-if="extInfoFieldValue && extInfoFieldValue[items.id].length !== 0"
						v-for="(cas,index) in extInfoFieldValue[items.id]"
						:key="cas + index">
						{{index === 0 ? cas[cas.length-1] : '，' + cas[cas.length-1]}}
					</div>
				</template>
				<div style="color:#dcdfe6;" v-else>请选择</div>
			</div>
			<el-select
				:placeholder="items.extInfo.placeholder"
				v-else-if="items.type==='SELECT'"
				style="width: 100%"
				clearable
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				collapse-tags
				v-model="fieldValue[items.id]"
				:filterable="true"
				:multiple="items.extInfo&&items.extInfo.selectType==='多选'?true:false"
				@change="changeSelect($event,items)"
			>
				<el-option
				v-for="item in options(items)[items.id]"
				:key="item.value"
				:label="item.label"
				:value="item.value"
				></el-option>
			</el-select>
			<el-radio-group
				v-else-if="items.type==='RADIO'"
				v-model="fieldValue[items.id]"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				@change="changeSelect($event,items)"
			>
				<el-radio
				v-for="(radio,index) in options(items)[items.id]"
				:key="radio.value + index"
				:label="radio.value"
				:value="radio.label"
				></el-radio>
			</el-radio-group>
			<el-checkbox-group
				v-else-if="items.type==='CHECKBOX'"
				v-model="fieldValue[items.id]"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				@change="changeSelect($event,items)"
			>
				<el-checkbox
				v-for="(check,index) in options(items)[items.id]"
				v-model="check.value"
				:label="check.label"
				:value="check.value"
				:key="check.value + index"
				></el-checkbox>
			</el-checkbox-group>
			<el-time-picker
				style="width: 100%"
				clearable
				v-model="fieldValue[items.id]"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				@change="changeFieldValues(items)"
				v-else-if="items.type === 'TIME_PICKER'&&items.extInfo.mold&&(items.extInfo.mold==='TIME'||(items.extInfo.mold==='OTHER'&& getMoldTime[items.id]==='TIME'))"
				:placeholder="items.extInfo.placeholder"
				format="HH:mm"
				value-format="HH:mm"
				popper-class="add-count-time-picker"
			></el-time-picker>
			<el-date-picker
				type="date"
				style="width: 100%"
				clearable
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				@change="changeFieldValues(items)"
				v-else-if="items.type === 'TIME_PICKER'&&items.extInfo.mold&&(items.extInfo.mold==='DATE'||(items.extInfo.mold==='OTHER'&& getMoldTime[items.id]==='DATE') || items.extInfo.mold==='DATA')"
				:placeholder="items.extInfo.placeholder"
				format="yyyy-MM-dd"
				value-format="yyyy-MM-dd"
				popper-class="add-count-time-picker"
			></el-date-picker>
			<el-date-picker
				type="datetime"
				clearable
				style="width: 100%"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				@change="changeFieldValues(items)"
				v-else-if="items.type === 'TIME_PICKER'&&items.extInfo.mold&&(items.extInfo.mold==='DATE_TIME'||(items.extInfo.mold==='OTHER'&& getMoldTime[items.id]==='DATE_TIME'))"
				:placeholder="items.extInfo.placeholder"
				format="yyyy-MM-dd HH:mm"
				value-format="yyyy-MM-dd HH:mm"
				popper-class="add-count-time-picker"
			></el-date-picker>
			<el-time-picker
				is-range
				style="width: 100%"
				clearable
				unlink-panels
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				v-else-if="items.type === 'TIME_PICKER_RANGE'&&items.extInfo.mold&&(items.extInfo.mold==='TIME'||(items.extInfo.mold==='OTHER'&&getMoldTime[items.id]==='TIME'))"
				range-separator="至"
				start-placeholder="开始时间"
				end-placeholder="结束时间"
				@change="changeFieldValues(items)"
				:placeholder="items.extInfo.placeholder"
				format="HH:mm"
				popper-class="add-count-time-picker"
			></el-time-picker>
			<el-date-picker
				type="daterange"
				clearable
				style="width: 100%"
				unlink-panels
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				@change="changeFieldValues(items)"
				v-else-if="items.type === 'TIME_PICKER_RANGE'&&items.extInfo.mold&&(items.extInfo.mold==='DATE'||(items.extInfo.mold==='OTHER'&& getMoldTime[items.id]==='DATE') || items.extInfo.mold==='DATA')"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				format="yyyy-MM-dd"
				popper-class="add-count-time-picker"
			></el-date-picker>
			<el-date-picker
				type="datetimerange"
				clearable
				style="width: 100%"
				unlink-panels
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				@change="changeFieldValues(items)"
				v-else-if="items.type === 'TIME_PICKER_RANGE'&&items.extInfo.mold&&(items.extInfo.mold==='DATE_TIME'||(items.extInfo.mold==='OTHER'&& getMoldTime[items.id]==='DATE_TIME'))"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				format="yyyy-MM-dd HH:mm"
				:default-time="['00:00:00', '23:59:59']"
				popper-class="add-count-time-picker"
			></el-date-picker>
			<div v-else-if="items.type === 'EXPLAIN'" class="explain-class">
				<span><i class="el-icon-warning"></i></span>
				<span v-if="items.extInfo.url">
					<a :href="items.extInfo.url" target="_blank">
						{{items.defaultValue ? items.defaultValue : (items.extInfo.placeholder ? items.extInfo.placeholder :'--')}}
					</a>
				</span>
				<span v-else>{{items.defaultValue ? items.defaultValue : (items.extInfo.placeholder ? items.extInfo.placeholder : '--')}}</span>
			</div>
		<!-- <div
			v-else-if="items.type === 'REF_TEMPLATE' || items.type==='REF'"
			class="ref-template"
		>
			<div class="select-value">
			<el-select
				:placeholder="items.extInfo.placeholder"
				style="width: 100%"
				clearable
				collapse-tags
				
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				@change="changeRefData($event,items)"
				:filterable="true"
				:multiple="items.extInfo.selectType == '多选'"
				v-if="items.type==='REF'"
				@visible-change="visibleChange($event,items)"
				@focus="setOptionWidth"
				remote
				:remote-method="(query) => remoteMethod(query,items)"
			>

				<el-option
				v-for="(item,index) in dataTemplateList[items.id]"
				:key="index"
				:label="item.name"
				:value="item.id"
				:style="{width:selectOptionWidth}"
				></el-option>
				<div
				class="load-more"
				v-if="isHasNext[items.id]"
				@click="loadMoreRefData(items.extInfo.ref.source,items)"
				>
				<span>查看更多</span>
				</div>
			</el-select>

			<el-select
				:placeholder="items.extInfo.placeholder"
				style="width: 100%"
				clearable
				collapse-tags
				
				:filterable="true"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				v-model="fieldValue[items.id]"
				:multiple="items.extInfo.selectType == '多选'"
				@change="changeRefData($event,items)"
				v-else
				@visible-change="visibleChange($event,items)"
				@remove-tag="removeTag($event,items)"
				@focus="setOptionWidth"
				remote
				:remote-method="(query) => remoteMethod(query,items)"
			>
				<el-option
				v-for="(item,index) in dataTemplateList[items.id]"
				:key="index"
				:label="item.name"
				:value="item.id"
				:style="{width:selectOptionWidth}"
				></el-option>
				<div
				class="load-more"
				v-if="isHasNext[items.id]"
				@click="loadMoreRefData(items.extInfo.ref.source,items)"
				>
				<span>查看更多</span>
				</div>
			</el-select>
			<el-button
				class="add-template"
				v-if="items.type === 'REF_TEMPLATE' && items.extInfo.supportAdd"
				:disabled="(!items.disabled && items.view) || items.relationDisabled"
				@click="addTemplate(items)"
			>
				<i class="el-icon-plus"></i>
			</el-button>
			</div>
		</div> -->
		<!-- <template v-if="items.type === 'REF_TEMPLATE'">
			<refTempalteView
				v-for="(detail,detailIndex) in templateDetailView[items.id]"
				:name="extInfoFieldValue[items.id]&&extInfoFieldValue[items.id][detailIndex]&&extInfoFieldValue[items.id][detailIndex].name"
				:key="detail.id"
				:items="items"
				:extInfo="detail.extInfo"
				:fieldValue="detail.fieldValues"
				v-if="(items.type === 'REF_TEMPLATE' && items.extInfo.chooseShow && items.extInfo.chooseShow.length > 0)"
			></refTempalteView>
		</template> -->
			<!-- <div v-else-if="items.type === 'IMAGE_SELECT'" class="image-select">
				<template v-for="(img,imgIndex) in options(items)[items.id]">
				<div
					:key="imgIndex"
					class="image-select-item"
					@click="checkImage(items,img)"
					v-if="img.url"
				>
					<img :src="img.url" />
					<span class="image-des">{{img.value}}</span>
					<span
					v-if="img.checked"
					:class="[items.extInfo.selectType==='单选'?
									'singleSign':'multipleSign',
									'sign']"
					>
					<i class="el-icon-check"></i>
					</span>
				</div>
				</template>
			</div> -->
		</div>
		<popup v-if="showCascadePop" @closeEvent="showCascadePop = false" class="cascade-tree" :width="1000">
			<div slot="popup-name" class="popup-name">请选择级联项</div>
			<div slot="popup-con" class="popup-con">
				<el-input placeholder="输入关键字进行过滤"  v-model="filterText"></el-input>
				<div class="container-tree">
				<el-tree
					:data="setCascadeOption"
					:default-expand-all="true"
					:expand-on-click-node="false"
					ref="tree"
					:filter-node-method="filterNode"
					node-key="value"
					check-strictly
					@check-change="handleCheckChange"
					@node-click="handleTreeClick"
				>
				
					<span class="el-tree-title" slot-scope="{ node, data }" >
					<span class="el-tree-title-content" 
						>
					{{ data.label }}
					</span>
					<span class="check-icon" @click.stop="()=>{}" v-if="cascadeDetail.extInfo.selectType === '多选'">
						<el-checkbox v-model="data.checked" :value="data.value" ></el-checkbox>
					</span>
					<span class="check-icon" @click.stop="()=>{}" v-else>
						<el-radio v-model="cascadeValue" :label="setRadioData(data)" ></el-radio>
					</span>
					</span>
				</el-tree>
				</div>
			</div>
			<div slot="dialog-footer">
				<el-button size="small" plain @click="showCascadePop = false">取 消</el-button>
				<el-button type="primary" size="small" @click="saveCascadeOption">确 定</el-button>
			</div>
		</popup>
		<popup v-if="addTemplatePop" @closeEvent="addTemplatePop=false" class="popup-add-ref-data">
		<div slot="popup-name">新增{{templateDetail.name}}数据</div>
		<div slot="popup-con" class="popup-con">
			<div
			v-for="(item,index) in addTemplateList.fieldColumns"
			class="fieldColumnItem"
			:key="index"
			v-if="item.display"
			>
			<div class="column-item"
				:style="{backgroundColor:item.backgroundColor?item.backgroundColor:'#FBFCFD'}"
			>
				<span class="column-icon">
					<i class="iconfont guoran-a-14_01"></i>
				</span>
			<span>{{ item.name }}</span>
			</div>
			<!-- <add-data-template
				:item="item"
				:isWorkOrder="true"
				:newFieldValue="{fieldValue:refFieldValue}"
				:extInfoFieldValue="refExtInfoFieldValue"
				:dataTemplate="addTemplateList"
				@typeCheck="typeCheck"
				@checkImage="checkImage"
				ref="addDataTemplate"
				:isAddData="true"
				@changeSelect="changeDataSelect"
				@changeFieldValues="changeDataSelect"
				:columnList="newFieldList[item.id]"
			></add-data-template> -->
			</div>
		</div>
		<div slot="dialog-footer">
			<el-button size="small" plain @click="addTemplatePop = false">取 消</el-button>
			<el-button type="primary" size="small" @click="addRefData">确 定</el-button>
		</div>
		</popup>
		<popup v-if="showUserOrg" @closeEvent="showUserOrg = false">
		<div slot="popup-name">请选择</div>
		<div slot="popup-con" class="popup-con">
			<account-tabs :activeObj="detailField" ref="accountTab"></account-tabs>
		</div>
		<div slot="dialog-footer">
			<el-button @click="showUserOrg = false" size="small" plain>取 消</el-button>
			<el-button type="primary" @click="saveData" size="small">保 存</el-button>
		</div>
		</popup>
  	</div>
</template>
<script>
/* eslint-disabled */
import { ossConfig, upload } from '@/assets/js/AliyunIssUtil'
import Popup from '@/components/popup'
import {uniqueData} from '../common'
import { refTemplateName } from "../common";
export default {
	props: ['items', 'fieldValue', 'extInfoFieldValue','dataTemplate','statusPriorityFieldValue','orderDefaultContent'],
	data() {
		return {
			// ckeditor: {
			//   editor: CKEDITOR.ClassicEditor
			// },
			// editorConfig: {
			//   extraPlugins: [MyCustomUploadAdapterPlugin],
			//   toolbar: ['imageUpload']
			// },
			refExtInfoFieldValue: {},
			showCascadePop: false,
			showUserOrg: false,
			addTemplatePop: false,
			dataTemplateList: {},
			isHasNext: [],
			filterText:"",
			dataQuery: {
				filterId: '',
				keywords: '',
				pageInfo: {
				pageNo: 1,
				pageSize: 20
				},
				sortInfos: [],
				templateId: ''
			},
			templateDetail: {},
			addTemplateList: {
				fieldColumns: [],
				name: '',
				fieldLayout: ''
			},
			cascadeOption:[],
			selectOptionWidth:null,
			templateDetailView:{},
			cascadeValue:"",
			valid:true,
			refFieldValue:{},
			newFieldList:{},
			associatedControlsCopy:[],
			cascaderIdsList:[],
			isDesDetail:{},
			firstLoadRefData:true,
			}
	},
	mounted() {
		if(this.items.type == 'REF_TEMPLATE' && this.fieldValue[this.items.id] && this.fieldValue[this.items.id].length != 0) {
		this.getTemplateDetail(this.items.extInfo.ref.source,this.fieldValue[this.items.id],this.items.id)
		}
		this.$nextTick(()=>{
			if (this.orderDefaultContent && this.orderDefaultContent.allFields){
				this.orderDefaultContent.allFields.forEach(item=>{
				if (item.systemFieldKey === 'description'){
					this.isDesDetail = item.field ? item.field : item
				}
				})
			}
		})
	},
	components: { Popup },
	computed: {
		options() {
			return function (field) {
				let options = {}
				let flag = false
				// if (!extInfo.dependent || (extInfo.dependent && !Object.keys(extInfo.dependent).length) || (extInfo.selfDependent && !Object.keys(extInfo.selfDependent).length)){
				 options[field.id] = field.extInfo.options
				// }
				let newOptions = {}
				newOptions[field.id] = uniqueData(options[field.id])
				return newOptions
			}
		},
		getRefLabel(){
			let varsss = ''
			if(this.items == 'REF_TEMPLATE' && this.items.extInfo.ref.vars) {
				this.items.extInfo.ref.vars.forEach(vars=>{
				varsss += vars
				})
			}
			console.log(varsss,'varsss');
			return varsss
		},
		getMoldTime(){
		let otherTime = {}
		if ((this.items.type === 'TIME_PICKER' || this.items.type === 'TIME_PICKER_RANGE') && this.items.extInfo.mold === 'OTHER') {
			let pattern = this.items.extInfo.pattern
			let yearVal = pattern.indexOf('YEAR') !== -1
			let monthVal = pattern.indexOf('MONTH') !== -1
			let dayVal = pattern.indexOf('DAY') !== -1
			let hourVal = pattern.indexOf('HOUR') !== -1
			let minuteVal = pattern.indexOf('MINUTE') !== -1
			let secondVal = pattern.indexOf('SECOND') !== -1
			if ((yearVal || monthVal || dayVal) && (!hourVal && !minuteVal && !secondVal)) {
			otherTime[this.items.id] = 'DATE'
			}
			else if ((yearVal || monthVal || dayVal) && (hourVal || minuteVal || secondVal)) {
			otherTime[this.items.id] = 'DATE_TIME'
			}
			else if ((hourVal || minuteVal || secondVal) && (!yearVal && !monthVal && !dayVal)) {
			otherTime[this.items.id] = 'TIME'
			}
		}
		return otherTime
		},
		//规则中限制极限的选项
		setCascadeOption(){
			let options = [];
			let  arr = this.cascadeOption;
			if (this.cascadeDetail.extInfo.dependent&&JSON.stringify(this.cascadeDetail.extInfo.dependent)!=='{}'){
				for (let k in this.cascadeDetail.extInfo.dependent){
				// debugger
				if (Array.isArray(this.fieldValue[k]) && Array.isArray(this.fieldValue[k][0])){
					this.fieldValue[k].forEach(item=>{
					this.cascadeDetail.extInfo.dependent[k].filterStruct.forEach(filter=>{
						if (filter.input === item || item[item.length - 1] == filter.input){
						if (!options){
							options = []
						}
						options = options.concat(filter.output)
						}
					})
					})
				}
				else {
					console.log(this.cascadeDetail.extInfo.dependent[k].filterStruct);
					this.cascadeDetail.extInfo.dependent[k].filterStruct.forEach(filter=>{
					if (filter.input === this.fieldValue[k] || this.fieldValue[k][this.fieldValue[k].length - 1] == filter.input){
						options = filter.output;
						
					}
					})
				}
				}
				const initCascade = (arr, parent)=>{
				return JSON.parse(JSON.stringify(arr)).filter(item =>{
					if (item.children){
					item.children = initCascade(item.children, item)
					}
					let flag = null
					for (let i = 0; i < options.length; i++) {
					const optArr = options[i];
					if(optArr.indexOf(item.value) !== -1) {
						if(parent === null || item.children && item.children.length != 0) {
						flag = true
						} else if(parent && optArr.indexOf(parent.value) !== -1 && 
						(Math.abs(optArr.indexOf(parent.value) - optArr.indexOf(item.value)) === 1 )) {
						flag = true
						}
					}
					}
					return flag && item
				})
				}
				arr = initCascade(arr,null)
			}
			else {
				return arr
			}
			return arr
		},
		getTemplateValue() {
		let str = ''
		if(this.extInfoFieldValue && this.extInfoFieldValue[this.items.id] && this.extInfoFieldValue[this.items.id].length != 0) {
			this.extInfoFieldValue[this.items.id].forEach(item =>{
			str = str ? '，' + str + item.name : str + item.name
			})
		}
		return str
		}
	},
	methods: {
		setRadioData (data) {
		let obj = {}
		let array = Object.keys(data)
		for (let index = 0; index < array.length; index++) {
			let key = array[index]
			if (key != 'children') {
			obj[key] = data[key]
			}
		}
		// console.log(obj);
		// return obj
		return JSON.stringify(obj)
		},
		handleCheckChange(data, checked,) {
		if(!checked) return
		if (this.cascadeDetail&&this.cascadeDetail.extInfo.selectType ==='单选'){
			if(this.$refs.tree.getCheckedNodes().length > 1) {
			this.$nextTick(() =>{
				this.$refs.tree.setChecked(data,false)
				this.$message.warning('当前字段为单选')
			})
			}
		}
		else {
			this.$nextTick(() =>{
			this.$set(data,'checked',checked);
			this.$refs.tree.setChecked(data,checked)
			})
		}
		},
		async openCascadeOption(items){
			if ((!items.disabled && items.view) || items.relationDisabled){
				this.$message.warning('当前字段不可编辑')
				return
			}
			this.cascadeOption = items.extInfo.cascadeDown.options?items.extInfo.cascadeDown.options:items.extInfo.cascadeDown[0].options;
			this.cascadeDetail = items;
			this.showCascadePop = true;
			this.$nextTick(() =>{
				if (items.extInfo.selectType === '多选'){
					let list = this.extInfoFieldValue[items.id]? this.extInfoFieldValue[items.id].map(item=>{return item.value}):[]
					this.ergodicCascadeCheckedValue(items.extInfo.cascadeDown.options,list)
				}
				else {
					let val = this.extInfoFieldValue[items.id]?this.extInfoFieldValue[items.id].map(item=>item.value):[]
					this.ergodicCascadeCheckedValue(items.extInfo.cascadeDown.options,val[0])
				}
			})
		},
		//遍历级联选项
		ergodicCascade(list){
		list.forEach(item=>{
			if (item.checked){
			let data = this.$refs.tree.getNode(item)
			let checkList = []
			let arr = this.serchParent(data,checkList)
			this.checkList.push(arr)
			this.checkDatas.push(item)
			}
			if (item.children){
			this.ergodicCascade(item.children)
			}
		})
		},
		//遍历级联选中选项
		ergodicCascadeCheckedValue(list,checkList){
		list.forEach(item=>{
			this.$set(item,'checked',false)
			// item.checked = false
			if (checkList){
			if (Array.isArray(checkList)){
				if (checkList.indexOf(item.value)!==-1){
				this.$set(item,'checked',true)
				}
			}
			else {
				if (checkList === item.value){
				this.$set(item,'checked',true)
				setTimeout(() =>{
					this.$set(this,'cascadeValue', this.setRadioData(item))
				},0)
				}
			}
			}
			if (item.children){
			this.ergodicCascadeCheckedValue(item.children,checkList)
			}
		})
		},
		async saveCascadeOption(){
			this.clearOptionStruct()
			this.$set(this.fieldValue,this.cascadeDetail.id,[])
			this.extInfoFieldValue[this.cascadeDetail.id] = []
			if(this.cascadeDetail.extInfo.selectType == '单选') {
				let val = JSON.parse(this.cascadeValue)
				let node = this.$refs.tree.getNode(val)
				let arr = []
				arr = this.serchParent(node,arr)
				this.$set(this.fieldValue,this.cascadeDetail.id,arr)
				this.$set(this.extInfoFieldValue,this.cascadeDetail.id,[val])
			} else {
				this.checkList = [];
				this.checkDatas = [];
				this.ergodicCascade(this.setCascadeOption)
				this.extInfoFieldValue[this.cascadeDetail.id]= this.checkDatas;
				this.fieldValue[this.cascadeDetail.id] = this.checkList;
			}
			this.showCascadePop = false;
			this.$emit('changeSelect',this.items)
			this.$emit('changeFieldValues', this.items)
			this.$emit('getDispatchRule');
		},
		serchParent(node,list) {
		if(node) {
			if(Object.prototype.toString.call(node.data) == '[object Object]') {
			list.unshift(node.data.value)
			}
			if(node.parent) {
			list = this.serchParent(node.parent,list)
			}
		}
		return list
		},
		filterNode(value, data, node) {
		if (!value) return true;
		let upperCase = value.toUpperCase()
		let lowerCase = value.toLowerCase()
		let serchFlag = false
		if (node.parent && Object.prototype.toString.call(node.parent.data) == '[object Object]') {
			serchFlag = node.parent.data.label.indexOf(value) !== -1 || node.parent.data.label.indexOf(upperCase) !== -1 || node.parent.data.label.indexOf(lowerCase) !== -1
		}
		return data.label.indexOf(value) !== -1 || data.label.indexOf(upperCase) !== -1 || data.label.indexOf(lowerCase) !== -1 || serchFlag;
		},
		// 清除当前节点关联的值
		clearOptionStruct() {
		// this.fieldValue,this.cascadeDetail.id
		console.log(this.cascadeDetail,'this.cascadeDetail');
		let options = this.cascadeDetail.extInfo.cascadeDown.options
		let clear = (options, parent) => {
			for (let index = 0; index < options.length; index++) {
			const opt = options[index];
			for (let i = 0; i < this.fieldValue[this.cascadeDetail.id].length; i++) {
				const value = this.fieldValue[this.cascadeDetail.id][i];
				if (Array.isArray(value)) {
				if (value.includes(opt.value)) {
					for (let k in opt.optionStruct) {
					if (k != this.cascadeDetail.id) {
						this.fieldValue[k] = []
						if (this.extInfoFieldValue[k]) {
						this.$set(this.extInfoFieldValue, k, [])
						}
					}
					if (k != this.isDesDetail.id && this.isDesDetail.extInfo.relationControlsId && this.isDesDetail.extInfo.relationControlsId.indexOf(k) !==-1){
						this.$emit('clearInputTheme')
					}
					}
				}
				} else {
				if (value === opt.value) {
					for (let k in opt.optionStruct) {
					if (k != this.cascadeDetail.field.id) {
						this.fieldValue[k] = []
						if (this.extInfoFieldValue[k]) {
						this.$set(this.extInfoFieldValue, k, [])
						}
					}
					if (k != this.isDesDetail.id && this.isDesDetail.extInfo.relationControlsId && this.isDesDetail.extInfo.relationControlsId.indexOf(k) !==-1){
						this.$emit('clearInputTheme')
					}
					}
				}
				}
			}
			if(opt.children && opt.children.length != 0) {
				clear(opt.children)
			}
			}
		}
		clear(options)
		},
		changeSelect(value, item) {
		let options = []
		if (item.type === 'CASCADER'){
			options = item.extInfo.cascadeDown.options
		}
		else {
			options = item.extInfo.options
		}
		options.forEach(items=>{
			if (value && value.length && Array.isArray(value)){
			if (value.indexOf(items.value) === -1 && items.optionStruct && Object.keys(items.optionStruct).length){
				for (let k in items.optionStruct){
				if (k != item.id){
					this.fieldValue[k] = []
					if (this.extInfoFieldValue[k]){
					this.$set(this.extInfoFieldValue,k,[])
					}
				}
				if (k != this.isDesDetail.id && this.isDesDetail.extInfo.relationControlsId && this.isDesDetail.extInfo.relationControlsId.indexOf(k) !==-1){
					this.$emit('clearInputTheme')
				}
				}
			}
			}
			else if (value){
			if (items.value == value && items.optionStruct&& Object.keys(items.optionStruct).length){
				for (let k in items.optionStruct){
				if (k != item.id){
					this.fieldValue[k] = []
					if (this.extInfoFieldValue[k]){
					this.$set(this.extInfoFieldValue,k,[])
					}
				}
				if (k != this.isDesDetail.id && this.isDesDetail.extInfo.relationControlsId && this.isDesDetail.extInfo.relationControlsId.indexOf(k) !==-1){
					this.$emit('clearInputTheme')
				}
				}
			}
			}
			else{
			if (items.optionStruct&& Object.keys(items.optionStruct).length){
				for (let k in items.optionStruct){
				if (k != item.id){
					this.fieldValue[k] = []
					if (this.extInfoFieldValue[k]){
					this.$set(this.extInfoFieldValue,k,[])
					}
				}
				}
			}
			}
		})

		this.$emit('changeSelect',this.items,value)
		this.$emit('changeFieldValues', this.items, value)
		this.$forceUpdate()
		},
		//根据选项设置默认值
		setFileDefault(option) {
		for (const key in option.setDefaultFieldId) {
			// if((this.fieldValue[key] !== null || this.fieldValue[key] !== undefined) && option.setDefaultFieldId[key]) {
			this.$set(this.fieldValue,key,option.setDefaultFieldId[key].fieldValue)
			if(option.setDefaultFieldId[key].extInfoFieldValue) {
				this.$set(this.extInfoFieldValue,key,option.setDefaultFieldId[key].extInfoFieldValue)
			}
			// }
		}
		},
		// 失去焦点时触发改变值
		typeCheck(field,isChange) {
		if (!this.fieldValue[field.id]){
			return
		}
		if (field.extInfo.inputType==='MOBILE'){
			const phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			if (!phone.test(this.fieldValue[field.id])) {
			this.$message({
				message: "请输入有效的手机号",
				duration: 2000,
				type: "warning",
			});
			this.fieldValue[field.id] = ''
			this.valid = false
			return
			}
		}
		else if (field.extInfo.inputType ==='MAIL'){
			const reg =
				/^([a-z0-9A-Z]+[-|_|\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-zA-Z]{2,4}$/;
			if (!reg.test(this.fieldValue[field.id])) {
			this.$message({
				message: "请输入有效的邮箱",
				duration: 2000,
				type: "warning",
			});
			this.fieldValue[field.id] = ''
			this.valid = false
			return
			}

		}
		else if (field.extInfo.inputType === 'ID_CARD'){
			const reg = /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))$/
			if (!reg.test(this.fieldValue[field.id])) {
			this.$message({
				message: "请输入有效的身份证号",
				duration: 2000,
				type: "warning",
			});
			this.fieldValue[field.id] = ''
			this.valid = false
			return
			}
		}
		else if (field.extInfo.inputType ==='LINK'){
			const reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])/;
			if (!reg.test(this.fieldValue[field.id])) {
			this.$message({
				message: "请输入合法的链接地址",
				duration: 2000,
				type: "warning",
			});
			this.fieldValue[field.id] = ''
			this.valid = false
			return
			}
		}
		else if (field.extInfo.inputType ==='NUMBER'||field.extInfo.inputType ==='RATE'){
			if (field.extInfo.range && field.extInfo.range.start && this.fieldValue[field.id] < Number(field.extInfo.range.start)){
			this.fieldValue[field.id] = Number(field.extInfo.range.start)
			}
			else if (field.extInfo.range && field.extInfo.range.end && this.fieldValue[field.id] > Number(field.extInfo.range.end)){
			this.fieldValue[field.id] = Number(field.extInfo.range.end)
			}
		}
		/*  this.$emit('changeFieldValues', this.items)*/
		},
		changeFieldValues() {
		this.$emit('changeFieldValues', this.items)
		},
		// 文件预览
		onPreview(file) {
		console.debug('file', file)
		window.open(file.url, '_black')
		},
		// visibleChange(value, field) {
		// 	let fields =
		// 		field.type === 'REF' ? field.extInfo.ref.field : field.extInfo.ref.vars
		// 	// 好德 账号的特殊处理，添加数据模版时不加载当前模版列表的数据，只回显本次添加的数据
		// 	let _mainId = localStorage.getItem('_mainId')
		// 	let mainIdList = ['ab0f90737c8b4f2d85ba2157e4473110']
		// 	if (mainIdList.includes(_mainId)) {
		// 		return
		// 	}
		// 	if (value) {
		// 		if (
		// 		!this.dataTemplateList[field.id] || this.firstLoadRefData ||
		// 		(this.addRefDatas && !this.isHasNext[field.id])
		// 		) {
		// 		this.$set(this.dataTemplateList, field.id, [])
		// 		this.getTemplateList(field.extInfo.ref.source, fields, field)
		// 		}
		// 	} else{
		// 		if ((field.type === 'REF_TEMPLATE' && field.extInfo.chooseShow && field.extInfo.chooseShow.length > 0)){
		// 		this.getTemplateDetail(field.extInfo.ref.source,this.fieldValue[field.id],field.id)
		// 		}
		// 	}
		// },
		//查找checked为true的选项赋值fieldValue
		// searchCheckedList(list, type) {
		// 	list.forEach(item => {
		// 		if (item.checked) {
		// 		if (type === '单选') {
		// 			this.$set(this.fieldValue, this.cascadeDetail.field.id, item.value)
		// 			this.extInfoFieldValue[this.cascadeDetail.field.id] = item
		// 		} else {
		// 			if (!this.fieldValue[this.cascadeDetail.field.id]) {
		// 			this.$set(this.fieldValue, this.cascadeDetail.field.id, [])
		// 			}
		// 			if (!this.extInfoFieldValue[this.cascadeDetail.field.id]) {
		// 			this.$set(this.extInfoFieldValue, this.cascadeDetail.field.id, [])
		// 			}
		// 			this.fieldValue[this.cascadeDetail.field.id].push(item.value)
		// 			this.extInfoFieldValue[this.cascadeDetail.field.id].push(item)
		// 		}
		// 		}
		// 		if (item.children) {
		// 		this.searchCheckedList(item.children, type)
		// 		}
		// 	})
		// },
		// getTemplateList(templateId, fieldId, field) {
		// 	let url = '/api/data-template/data/page'
		// 	console.debug('templateId', templateId, fieldId)
		// 	// this.dataQuery.templateId = '6260ff58122dae34a4dc9a8e'
		// 	this.dataQuery.templateId = templateId
		// 	this.$http.post(url, this.dataQuery).then(res => {
		// 		if (res.data.code === '0') {
		// 		let list = res.data.data.data
		// 		this.isHasNext[field.id] = res.data.data.hasNextPage
		// 		let templateList = []
		// 		templateList = list.map(res => {
		// 			let info = {}
		// 			for (let key in res.fieldValues) {
		// 			if (Array.isArray(fieldId)) {
		// 				let vals = ''
		// 				let values = ''
		// 				fieldId.forEach(val => {
		// 				vals += val
		// 				if (res.fieldValues[val]) {
		// 					values += res.fieldValues[val]
		// 				}
		// 				})
		// 				info[vals] = values
		// 				info.id = res.id
		// 				info.name = values
		// 			} else {
		// 				if (fieldId === key) {
		// 				info[key] = res.fieldValues[key]
		// 				info.id = res.id
		// 				info.name = res.fieldValues[key]
		// 				}
		// 			}
		// 			}
		// 			return info
		// 		})

		// 		this.dataTemplateList[field.id] = [
		// 			...this.dataTemplateList[field.id],
		// 			...templateList
		// 		]
		// 		this.firstLoadRefData = false;
		// 		}
		// 	})
		// },
		// changeRefData(value, item) {
		// 	console.log(value);
		// 	console.debug('value', value, item, this.dataTemplateList[item.id])
		// 	let arr = []
		// 	if (this.dataTemplateList[item.id]) {
		// 		this.dataTemplateList[item.id].forEach(data => {
		// 		if(typeof value == 'string' || typeof value == 'number') {
		// 			if(value == data.id) {
		// 			let obj = {
		// 				name: data.name,
		// 				id: data.id
		// 			}
		// 			arr.push(data)
		// 			}
		// 		} else {
		// 			if (value.indexOf(data.id) !== -1) {
		// 			let obj = {
		// 				name: data.name,
		// 				id: data.id
		// 			}
		// 			arr.push(data)
		// 			}
		// 		}
		// 		})
		// 	}
		// 	this.refExtInfoFieldValue[item.id] = arr
		// 	console.log(arr);
		// 	this.$emit('changeRefData', item, arr)
		// 	console.debug('this.refExtInfoFieldValue', this.refExtInfoFieldValue)
		// 	if (item.extInfo.selectType !== '多选' && !this.fieldValue[item.id]){
		// 		this.getTemplateDetail(item.extInfo.ref.source,this.fieldValue[item.id],item.id)
		// 	}
		// },
		// loadMoreRefData(source, field) {
		// 	let fields =
		// 		field.type === 'REF' ? field.extInfo.ref.field : field.extInfo.ref.vars
		// 	this.dataQuery.pageInfo.pageNo += 1
		// 	this.getTemplateList(source, fields, field)
		// },
		// addTemplate(data) {
		// 	console.debug('data', data)
		// 	this.templateDetail = data
		// 	this.getRefTemplate(data.field.extInfo.ref.source)
		// 	this.addTemplatePop = true;
		// 	this.setCascaderIds();
		// 	/*this.$emit('addTemplate',data)*/
		// },
		// getRefTemplate(template) {
		// 	console.debug('template', template)
		// 	let url = '/api/data-template/' + template
		// 	this.$http.get(url).then(res => {
		// 		let fieldColumns = res.data.data.fieldColumns
		// 		let columnList = JSON.parse(JSON.stringify(fieldColumns))
		// 		let fieldColumn = columnList.map((item, index) => {
		// 		let info = {
		// 			x: 0,
		// 			y: 0,
		// 			w: 0,
		// 			h: 0,
		// 			field: item,
		// 			i: item.widgetId,
		// 			type: item.type,
		// 			name: item.name
		// 		}
		// 		if (info.field.allFields) {
		// 			let fields = info.field.allFields.map(items => {
		// 			let info2 = {
		// 				x: items.left ? items.left : 0,
		// 				y: items.top,
		// 				w: items.width,
		// 				h: items.height,
		// 				i: items.widgetId,
		// 				type: item.type,
		// 				field: items
		// 			}
		// 			return info2
		// 			})
		// 			info.field.allFields = fields
		// 		}
		// 		return info
		// 		})

		// 		this.addTemplateList.fieldColumns = JSON.parse(
		// 		JSON.stringify(fieldColumn)
		// 		)
		// 		this.addTemplateList.fieldLayout = res.data.data.fieldLayout
		// 		this.initFieldValues()
		// 	})
		// },
		// initFieldValues() {
		// 	this.refFieldValue = {}
		// 	this.refExtInfoFieldValue = {}
		// 	this.addTemplateList.fieldColumns.forEach(item => {
		// 		if (item.field.allFields) {
		// 		item.field.allFields.forEach(items => {
		// 			if (items.type === 'CHECKBOX') {
		// 			this.$set(this.refFieldValue, items.id, [])
		// 			}

		// 			if (items.defaultValue) {
		// 			this.$set(
		// 				this.refFieldValue,
		// 				items.id,
		// 				items.defaultValue
		// 			)
		// 			}
		// 		})
		// 		}
		// 	})
		// },
		//图片选择
		// checkImage(item,img){
		// 	if (!item.field.display || item.field.isDisabled) {
		// 		return
		// 	}
		// 	if (item.field.extInfo.selectType==='单选'){
		// 		item.field.extInfo.options.forEach(imgs=>{
		// 		this.$set(imgs,'checked',false)
		// 		})
		// 	}
		// 	this.$set(img,'checked',!img.checked)
		// 	if (img.checked){
		// 		if (item.field.extInfo.selectType==='单选'){
		// 		this.fieldValue[item.field.id] = img.value
		// 		this.extInfoFieldValue[item.field.id] = img.url
		// 		return;
		// 		}
		// 		if (!this.fieldValue[item.field.id]){
		// 		this.fieldValue[item.field.id] = []
		// 		}
		// 		if (!this.extInfoFieldValue[item.field.id]){
		// 		this.extInfoFieldValue[item.field.id] = []
		// 		}
		// 		this.extInfoFieldValue[item.field.id].push(img.url)
		// 		this.fieldValue[item.field.id].push(img.value)
		// 		return
		// 	}
		// 	if (item.field.extInfo.selectType==='单选'){
		// 		this.fieldValue[item.field.id] = ''
		// 		return;
		// 	}
		// 	if (this.fieldValue[item.field.id]){
		// 		let spliceIndex = 0
		// 		let extIndex = 0
		// 		this.fieldValue[item.field.id].forEach((imgs,index)=>{
		// 		if (JSON.stringify(imgs)===JSON.stringify(img.value)){
		// 			spliceIndex = index
		// 		}
		// 		})
		// 		this.extInfoFieldValue[item.field.id].forEach((imgs,index)=>{
		// 		if (JSON.stringify(imgs)===JSON.stringify(img.url)){
		// 			extIndex = index
		// 		}
		// 		})
		// 		this.fieldValue[item.field.id].splice(spliceIndex,1)
		// 		this.extInfoFieldValue[item.field.id].splice(extIndex,1)
		// 	}
		// },
		addRefData() {
		if(!this.ckEditorUploading(this.$refs.addDataTemplate.currentEditor)) {
			return
		}
		let fieldValue = {}
		let templateId = {}
		let extInfoFieldValue = {}
		let copyFieldValue = {}
		fieldValue = JSON.parse(JSON.stringify(this.refFieldValue))
		templateId = this.templateDetail.field.extInfo.ref.source
		extInfoFieldValue = JSON.parse(JSON.stringify(this.refExtInfoFieldValue))
		copyFieldValue = JSON.parse(JSON.stringify(this.refFieldValue))
		let url ='/api/data-template/data/save?checked=true'
		for (let key in extInfoFieldValue) {
			for (let key2 in copyFieldValue) {
			if (key === key2) {
				copyFieldValue[key2] = extInfoFieldValue[key]
			}
			}
		}
		let bool = false
		let fieldColumns = JSON.parse(
			JSON.stringify(this.addTemplateList.fieldColumns)
		)
		for (let i = 0; i < fieldColumns.length; i++) {
			if (fieldColumns[i].field.display) {
			for (let j = 0; j < fieldColumns[i].field.allFields.length; j++) {
				if (fieldColumns[i].field.allFields[j].field.display &&
					!fieldColumns[i].field.allFields[j].field.relationDisabled &&
					(fieldColumns[i].field.allFields[j].field.required || this.isRequiredFn(fieldColumns[i].field.allFields[j].field))&&
				((fieldValue[fieldColumns[i].field.allFields[j].field.id] && fieldValue[fieldColumns[i].field.allFields[j].field.id].length == 0) || !fieldValue[fieldColumns[i].field.allFields[j].field.id])
				) {
				this.$message({
					message:
					fieldColumns[i].field.allFields[j].field.name + '是必填的',
					type: 'warning',
					duration: 2000
				})
				bool = true
				break
				}
			}
			}
		}
		if (bool) {
			return
		}
		let parmas = {
			templateId: templateId,
			fieldValues: fieldValue,
			extInfo: JSON.stringify(copyFieldValue)
		}
		console.debug('parmas', parmas)
		this.$http.post(url, parmas).then(res => {
			console.debug('save', res)
			if (res.data.code === '0'){
			this.$message({
				message: '新增数据成功',
				type: 'success',
				duration: 2000
			})
			this.addRefSuccess(this.templateDetail.field.extInfo.ref.source, res.data.data, this.templateDetail.field)
			this.addRefDatas = true
			this.addTemplatePop = false
			}
			else if (res.data.code === '-1'){
			let fields = res.data.data && res.data.data.fields ? res.data.data.fields : [];
			let fieldIds = res.data.data && res.data.data.fieldIds ? res.data.data.fieldIds : [];
			for (let i=0;i<fields.length;i++){
				if (fieldIds.includes(fields[i].id)){
				this.$message({
					dangerouslyUseHTMLString: true,
					message:'<span style="color: red;margin-right: 6px">' +fields[i].name + '</span>' + '有重复数据，请您重新填写',
					type:"warning",
					duration:3000
				})
				break;
				}
			}
			}
		})
		},
		isRequiredFn(field){
		return this.relationSet(field,'requiredDependentFieldId')
		},
		addRefSuccess(templateId, id, field){
		let fields = field.extInfo.ref.vars
		this.$http.get('/api/data-template/data/data-list', {
			query: {
			ids: id,
			templateId: templateId
			}
		}).then(res => {
			if (res.data.code == '0') {
			let templateList = [];
			if(this.templateDetailView[field.id]) {
				this.templateDetailView[field.id].push(res.data.data[0])
			} else {
				this.$set(this.templateDetailView,field.id,res.data.data)
			}
			templateList = res.data.data.map(ress => {
				let info = {}
				console.debug('ress',ress,fields)
				for (let key in ress.fieldValues) {
				if (Array.isArray(fields)) {
					let vals = ''
					let values = ''
					fields.forEach(val => {
					vals += val
					if (ress.fieldValues[val]) {
						values += ress.fieldValues[val]
					}
					})
					info[vals] = values
					info.id = ress.id
					info.name = values
				} else {
					if (fields === key) {
					info[key] = ress.fieldValues[key]
					info.id = ress.id
					info.name = ress.fieldValues[key]
					}
				}
				}
				return info
			})

			if (!this.dataTemplateList[this.templateDetail.field.id]){
				this.dataTemplateList[this.templateDetail.field.id] = []
			}
			this.dataTemplateList[this.templateDetail.field.id] = [
				...this.dataTemplateList[this.templateDetail.field.id],
				...templateList
			]
			if (this.templateDetail.field.extInfo.selectType === '多选'){
				if (!this.fieldValue[this.templateDetail.field.id]){
				this.$set(this.fieldValue,this.templateDetail.field.id,[id])
				}
				else {
				this.fieldValue[this.templateDetail.field.id].push(id)
				}
			}
			else {
				this.$set(this.fieldValue,this.templateDetail.field.id,id)
			}
			let arr = []
			if (this.dataTemplateList[this.templateDetail.field.id]) {
				this.dataTemplateList[this.templateDetail.field.id].forEach(data => {
				if (typeof this.fieldValue[this.templateDetail.field.id] == 'string' || typeof this.fieldValue[this.templateDetail.field.id] == 'number') {
					if (this.fieldValue[this.templateDetail.field.id] == data.id) {
					let obj = {
						name: data.name,
						id: data.id
					}
					arr.push(data)
					}
				} else {
					if (this.fieldValue[this.templateDetail.field.id].indexOf(data.id) !== -1) {
					let obj = {
						name: data.name,
						id: data.id
					}
					arr.push(data)
					}
				}
				})
			}
			this.extInfoFieldValue[this.templateDetail.field.id] = arr
			console.debug('templateDetail',templateList)
			}
		})
		},
		//上传超出显示
		uploadExceed(){
		this.$message({
			message:"超出可上传的最大数量",
			duration:2000,
			type:'warning'
		})
		return
		},
		//动态设置下拉框宽度
		setOptionWidth(event){
		this.selectOptionWidth = event.srcElement.offsetParent.offsetWidth + 40 + "px";
		},
		getTemplateDetail(templateId,fields,fieldId) {
		this.$http.get('/api/data-template/data/data-list',{
			query:{
			ids:fields,
			templateId:templateId
			}
		}).then(res =>{
			if(res.data.code == '0') {
			this.$set(this.templateDetailView,fieldId,res.data.data);
			if (this.items.defaultValue){
				let data = refTemplateName(res.data.data,this.items.extInfo.ref.vars);
				if (!this.dataTemplateList[fieldId]){
				this.$set(this.dataTemplateList,fieldId,[])
				this.dataTemplateList[fieldId] = data;
				} else {
				let findIndex = -1;
				if (data.length){
					findIndex = this.dataTemplateList[fieldId].findIndex(item => item.id == data[0].id);
					if (findIndex == -1) {
					this.dataTemplateList[fieldId] = [...this.dataTemplateList[fieldId],...data];
					}
				}
				}
			}
			console.debug('dataTemplateList',this.dataTemplateList)
			}
		})
		},
		removeTag(value,field){
		this.getTemplateDetail(field.extInfo.ref.source,this.fieldValue[field.id],field.id)
		},
		submitEvent() {
		// 单行 多行 字段类型输入 需要判断 valid 字段的校验状态，true 的状态下才可以保存
		// 类型未校验成功会把值清空，因此 valid 为 false 的情况直接 重新设为true
		if (this.items.type == 'INPUT' ||
			this.items.type == 'MOBILE' ||
			this.items.type == 'MAIL' ||
			this.items.type == 'ID_CARD' ||
			this.items.type == 'LINK' ||
			this.items.type == 'NUMBER' ||
			this.items.type == 'RATE'
		) {
			if (this.valid) {
			console.log('抛出方法');
			this.$emit('')
			} else {
			this.valid = true
			}
		}
		if(this.items.type == 'RADIO' || this.items.type == 'CHECKOUT' || this.items.type == 'SELECT') {

		}
	},
		changeDataSelect(field){
		let arr  = field.extInfo.relationControlsId ? JSON.parse(JSON.stringify(field.extInfo.relationControlsId)) : []
		this.associatedControlsCopy = [...this.associatedControlsCopy, ...arr]
		let option = null;
		let options = field.type === 'CASCADER'?field.extInfo.cascadeDown.options:(field.extInfo.options?field.extInfo.options:[])
		options.forEach(ele => {
			if(this.refFieldValue[field.id] && this.refFieldValue[field.id].length != 0 &&  ele.value == this.refFieldValue[field.id][this.refFieldValue[field.id].length - 1]) {
			option = ele
			}
			ele.children && ele.children.forEach(element =>{
			if(this.refFieldValue[field.id] && this.refFieldValue[field.id].length != 0 &&  element.value == this.refFieldValue[field.id][this.refFieldValue[field.id].length - 1]) {
				option = element
			}
			})
		})
		if (field.type === 'CASCADER' && field.extInfo.selectType === '单选' && option && option.setDefaultFieldId){
			this.setDataFieldDefault(option)
		}
		},
		fieldSetting() {
		for (let index = 0; index < this.addTemplateList.fieldColumns.length; index++) {
			let item = this.addTemplateList.fieldColumns[index].field;
			this.$set(item,'display',this.relationSet(item,'dependentFieldId'));
			for (let j = 0; j < item.allFields.length; j++) {
			let field = item.allFields[j];
			this.$set(field.field,'display', this.relationSet(field.field,'dependentFieldId'))
			this.$set(field.field,'relationDisabled', this.relationSet(field.field,'disabledFieldId'))
			if(this.refFieldValue[field.field.id] &&  !this.associatedControlsCopy.includes(field.field.id) && (field.field.extInfo.selectType == '单选' || field.field.type == 'RADIO')) {
				let option = null
				if(field.field.extInfo.options) {
				option = field.field.extInfo.options.find(ele => { return ele.value == this.refFieldValue[field.field.id]})
				} else if (field.field.extInfo.cascadeDown) {
				option = this.serchCascaderOption(field.field.extInfo.cascadeDown.options, field)
				// field.field.extInfo.cascadeDown.options.forEach(ele => {
				//   if(this.refFieldValue[field.field.id] && this.refFieldValue[field.field.id].length != 0 &&  ele.value == this.refFieldValue[field.field.id][this.refFieldValue[field.field.id].length - 1]) {
				//     option = ele
				//   }
				//   ele.children && ele.children.forEach(element =>{
				//     if(this.refFieldValue[field.field.id] && this.refFieldValue[field.field.id].length != 0 &&  element.value == this.refFieldValue[field.field.id][this.refFieldValue[field.field.id].length - 1]) {
				//       option = element
				//     }
				//   })
				// })
				} else {
				option = this.refExtInfoFieldValue[field.field.id] && this.refExtInfoFieldValue[field.field.id][0]
				}
				if(option && option.setDefaultFieldId) {
				this.$nextTick(()=>{
					this.setDataFieldDefault(option,field.field)
				})

				}
			}
			}
			let list = {}
			this.addTemplateList.fieldColumns.forEach(item=>{
			if (item.field.display !== false){
				if (!list[item.field.id]){
				this.$set(list,item.field.id,[])
				}
				item.field.allFields.forEach(items=>{
				if (items.display){
					list[item.field.id].push(items)
				}
				})
				// this.$set(this.newFieldList,item.field.id,makeTree(list[item.field.id]))
			}
			})
		}
		this.$forceUpdate();
		},
		serchCascaderOption (options, items) {
		let opt = null
		for (let index = 0; index < options.length; index++) {
			const row = options[index];
			if (this.refFieldValue[items.id] &&
			this.refFieldValue[items.id].length != 0 &&
			row.value == this.refFieldValue[items.id][this.refFieldValue[items.id].length - 1]
			) {
			opt = row
			}
			if (!opt && row.children) {
			opt = this.serchCascaderOption(row.children, items)
			}
		}
		return opt
		},
		relationSet(field,list) {
		let flag = false;
		// 如果在extInfo下有该字段，表示已被关联，否则根据 类型去判断 retrun true 或者false
		if(field.extInfo && field.extInfo[list] && Object.keys(field.extInfo[list]).length !== 0) {
			for (const key in field.extInfo[list]) {
			const element = field.extInfo[list][key];
			let fieldValue = null
			if (this.cascaderIdsList.includes(key)){
				fieldValue = this.refFieldValue[key]?this.refFieldValue[key][this.refFieldValue[key].length - 1]:null;
			}
			else {
				fieldValue = this.refFieldValue[key]
			}
			if(fieldValue) {
				if(Array.isArray(fieldValue)) {
				if(Array.isArray(element)) {
					for (let index = 0; index < element.length; index++) {
					let valueList = []
					/*flag = fieldValue.some(item =>{ return item == element[index] })
					if(flag) {
						return flag
					}*/
					let isArr = false;
					fieldValue.forEach(vals=>{
						if (Array.isArray(vals)){
						if (valueList.indexOf(vals[vals.length -1] == -1)){
							valueList.push(vals[vals.length-1])
						}
						isArr = true
						}
					})
					if (isArr){
						flag = valueList.includes(element[index])
						if(flag) {
						return flag
						}
					}
					else {
						flag = fieldValue.some(item =>{ return item == element[index] })
						if(flag) {
						return flag
						}
					}

					}
				} else {
					flag = fieldValue.some(item =>{ return item == element })
					if(flag) {
					return flag
					}
				}
				} else {
				if(Array.isArray(element)) {
					for (let index = 0; index < element.length; index++) {
					flag =  element[index] == fieldValue
					if(flag) {
						return flag
					}
					}
				} else {
					flag =  element == fieldValue
					if(flag) {
					return flag
					}
				}
				}
			} else if(list == 'dependentFieldId') {
				flag = false
			}
			}
		} else if(list == 'dependentFieldId') {
			return  flag = true
		} else if(list == 'requiredDependentFieldId') {
			return  flag = false
		}
		else if(list == 'disabledFieldId') {
			return  flag = false
		}
		return flag
		},
		setCascaderIds(){
		this.cascaderIdsList = [];
		this.addTemplateList.fieldColumns.forEach(item=>{
			item.field.allFields.forEach(items=>{
			if (items.type === 'CASCADER' && items.extInfo.selectType === '单选'){
				this.cascaderIdsList.push(items.id)
			}
			})
		})
		},
		setDataFieldDefault(option,field) {
		this.addTemplateList.fieldColumns.forEach(item=>{
			if (item.field.display && item.field.allFields){
			item.field.allFields.forEach(items=>{
				for (const key in option.setDefaultFieldId) {
				if (items.id === key && items.display){
					this.$set(this.refFieldValue,key,option.setDefaultFieldId[key].fieldValue)
					if(option.setDefaultFieldId[key].extInfoFieldValue) {
					this.$set(this.refExtInfoFieldValue,key,option.setDefaultFieldId[key].extInfoFieldValue)
					}
					else {
					this.$set(this.refExtInfoFieldValue,key,option.setDefaultFieldId[key].fieldValue)
					}
					this.$forceUpdate();
				}
				/*if (items.id === key && !items.display){
					this.refFieldValue[key] = items.type === 'CHECKBOX'?[]:"";
					this.refExtInfoFieldValue[key] = items.type === 'CHECKBOX'?[]:"";
					this.$forceUpdate();
				}*/
				}
			})
			}
		})
		},
		remoteMethod(query,field){
		console.debug('query',query,field)
		let url = "/api/data-template/data/page";
		let fields =
			field.type === 'REF' ? field.extInfo.ref.field : field.extInfo.ref.vars
		if (!field.extInfo.ref.source){
			return
		}
		let dataQuery ={
			filterId:'',
			keywords:query,
			pageInfo:{
			pageNo:1,
			pageSize:20
			},
			sortInfos:[],
			templateId:field.extInfo.ref.source,
		}
		if (fields){
			let publicFieldColumns = []
			if (Array.isArray(fields)){
			publicFieldColumns = JSON.parse(JSON.stringify(fields))
			}
			else {
			publicFieldColumns = JSON.parse(JSON.stringify([fields]))
			}
			publicFieldColumns = publicFieldColumns.map(item=>{
			let info = {
				id:item
			}
			return info
			})
			dataQuery.publicFieldColumns= publicFieldColumns
		}
		this.$http.post(url, dataQuery).then(res=>{
			if (res.data.code === '0'){
			let list = res.data.data.data
			this.isHasNext[field.id] = res.data.data.hasNextPage
			let templateList = []
			templateList = list.map(res => {
				let info = {}
				for (let key in res.fieldValues) {
				if (Array.isArray(fields)) {
					let vals = ''
					let values = ''
					fields.forEach(val => {
					vals += val
					if (res.fieldValues[val]) {
						values += res.fieldValues[val]
					}
					})
					info[vals] = values
					info.id = res.id
					info.name = values
				} else {
					if (fields === key) {
					info[key] = res.fieldValues[key]
					info.id = res.id
					info.name = res.fieldValues[key]
					}
				}
				}
				return info
			})

			if (!this.dataTemplateList[field.id]){
				this.dataTemplateList[field.id] = []
			}
			this.dataTemplateList[field.id] = templateList;
			}
		})
		},
		handleTreeClick(data,node,row) {
			if(this.cascadeDetail.extInfo.selectType === '多选') {
				data.checked = !data.checked
			} else {
				this.cascadeValue = this.setRadioData(data)
				// JSON.stringify(data)
			}
		},
	},
	watch:{
		filterText(val) {
			this.$refs.tree.filter(val);
		},
		// extInfoFieldValue:{
		// 	handler(value){
		// 		if(value) {
		// 			// for (const key in value) {
		// 			// 	if(this.items.id == key && (this.items.type == 'REF' || this.items.type == 'REF_TEMPLATE')) {
		// 			// 		this.$set(this.dataTemplateList, this.items.id, value[key])
		// 			// 		this.addRefDatas = true
		// 			// 	}
		// 			// }
		// 		}
		// 	},
		// 	// deep:true,
		// 	immediate:true
		// },
		refFieldValue:{
			handler(value){
				this.$nextTick(()=>{
					setTimeout(()=>{
						this.fieldSetting()
					},100)
				})


			},
			deep:true,
			immediate:true
		},
		items:{
			handler(n){
				console.log(n,'itemsitemsitemsitems');
			},
			deep:true,
		}
	}
}
</script>

<style lang="less" scoped>
	.field-type {
		width: 100%;
		height: 100%;
		/* overflow-x: hidden;
			overflow-y: scroll;*/
		display: flex;
		.field-input {
			width: 100%;
			height: 100%;
		}
		.number-input {
			/deep/.el-input__inner {
				line-height: 1px !important;
			}
		}
		.placeholder-text {
			color: #c0c4cc;
		}
		.field-viewable {
			min-height: 34px;
			display: flex;
			align-items: center;
			width: 100%;
			cursor: pointer;
		}
		.cascade-class {
			min-height: 16px;
			padding: 10px 12px;
			width: 100%;
			height: 36px;
			box-sizing: border-box;
			border: 1px solid #dcdfe6;
			border-radius: 4px;
			cursor: pointer;
			color: #606266;
			display: flex;
			align-items: center;
		}
		.ref-template {
			width: 100%;
			.select-value {
			display: flex;
			align-items: center;
			}
			.add-template {
			flex: none;
			width: 29px;
			height: 29px;
			background: #366aff;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;
			color: #ffffff;
			cursor: pointer;
			padding: 0 15px
			}
		}
		.explain-text {
			height: 70%;
			display: flex;
			align-items: center;
			color: #000000;
		}
		.image-select {
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			.image-select-item {
			width: 80px;
			height: 110px;
			margin: 0 20px 20px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			.image-des {
				padding-top: 8px;
				color: #a9b3c6;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal;
				max-width: 80px;
			}
			img {
				width: 80px;
				height: 80px;
				border-radius: 5px;
			}
			.singleSign {
				border-radius: 11px;
			}
			.multipleSign {
				border-radius: 5px;
			}
			.sign {
				width: 16px;
				height: 16px;
				background: #366aff;
				position: absolute;
				top: 4px;
				right: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
				color: #ffffff;
				}
			}
			}
		}
		/deep/ .el-textarea {
			height: 90%;
		}

		/deep/ .el-textarea__inner {
			height: 100%;
		}
		.hide-upload {
			.el-upload--picture-card {
				display: none;
			}
		}
		/deep/ .el-upload--picture-card {
			width: 60px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: none;
		}
		.image-item-class {
			display: flex;
			/deep/.el-upload-list__item {
			width: 60px;
			height: 60px;
			}
		}
		.filed-value-default {
			width: 100%;
			height: 32px;
			display: flex;
			align-items: center;
			cursor: pointer;
			box-sizing: border-box;
			padding: 0 12px;
		}

		.filed-value-default:hover {
			background: #E8EEFF;
		}
		a {
			text-decoration: none;
		}
		.explain-class {
			color: gray;
			display: flex;
			align-items: center;
			a {
				text-decoration: none;
				color: gray
			}

			padding: 10px 10px 10px 0;

			i {
				font-size: 18px;
				padding-right: 12px;
			}
		}
	}
	/deep/.el-input.is-disabled .el-input__inner {
	background-color: #f5f7fa;
	border-color: #e4e7ed;
	color: #c0c4cc;
	cursor: not-allowed;
	}

	.leftRightField {
	display: flex;
	align-items: center;
	.field-name {
		width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 4px;
		display: flex;
	}

	.field-type {
		height: 100%;
		display: flex;
		align-items: center;
		flex: 1;
	}
	.avatar-uploader {
		height: 100%;
		display: flex;
		align-items: center;
	}
	}

	.upDownField {
	.ref-template {
		height: 100%;
	}
	.field-name {
		padding-bottom: 10px;
		display: flex;
		align-items: center;
	}
	.field-type {
		height: calc(100% - 20px);
	}
	.avatar-uploader {
		height: 100%;
	}
	}
	/deep/ .ck.ck-editor {
	height: 100%;
	width: 100%;
	}
	/deep/.ck-editor__main {
	height: calc(100% - 42px);
	}
	/deep/ .ck-editor__editable {
	height: 100% !important;
	}

	.cascade-tree {
	.container-tree {
		margin-top: 16px;
		.el-tree-title {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// .el-tree-title-content {
		//   flex: 1;
		// }
		.setDisabled{
			color: #dcdfe6;
		}
		.check-icon{
			i{
			font-size: 16px;
			font-weight: 600;
			}
		}
		}
		/deep/.el-radio__label{
		display: none;
		}
		/deep/.el-radio{
		min-width: 0;
		}

	}
	/deep/.el-checkbox {
		min-width: 0px !important;
		}
	}
	.fieldColumnItem {
	margin-bottom: 16px;
	.column-item {
		color: #616161;
		height: 38px;
		background: #FBFCFD;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;

		.column-icon {
		width: 22px;
		height: 22px;
		background: #366AFF;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 6px;

		i {
			color: #FFFFFF;
			font-size: 14px;
		}
		}
	}
	}
	.load-more {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #366aff;
	cursor: pointer;
	line-height: 30px;
	}
	/deep/.popup-add-ref-data {
	#popup-assembly {
		width: 800px !important;
	}
	}
</style>