<template>
    <div  class="tree-container">
        <div v-loading="departLoading" >
            <div class="tree-header">
                <span class="tree-header-name">{{$t('staffManage.tabDept')}}</span>
                <span class="tree-header-tag" v-if="isTB">
                    <el-dropdown @command="handleMemberTags" offset="-80" trigger="click" placement="bottom" @visible-change="visibleTagsChange">
                        <span class="el-dropdown-link">
                            {{$t('staffManage.tag')}}
                            <i :class="tagsIcon"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="null">{{$t('staffManage.all')}}</el-dropdown-item>
                            <el-dropdown-item class="tags-dropdown" v-for="tag in tagsList" :key="tag.id" :command="tag.id">
                                <span class="tags-dropdown-name">{{tag.label}}</span>
                                <i class="el-icon-check" v-show="tagsId.includes(tag.id)"></i>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <span class="tree-header-num">{{$t('staffManage.departList.departmentNumber')}}</span>
                <span class="tree-header-bot">{{$t('staffManage.bot')}}</span>
                <span class="tree-header-model">{{$t('staffManage.bigModel')}}</span>
                <span class="tree-header-work">{{$t('staffManage.workOrder')}}</span>

            </div>
            <DefaultImage v-if="treeData.length == 0"></DefaultImage>
            <div
                v-if="isSeaching && treeData.length != 0"
                class="depart-search-list"
            >
            <div class="depart-search-list-c">
                <el-tree
                    show-checkbox
                    :data="searchTreeData"
                    :props="defaultProps"
                    class="tree-line"
                    node-key="id"
                    ref="dTrees"
                    :indent="0"
                    :load="loadSearchNode"
                    lazy
                    check-strictly
                >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <!-- v-if="isNumber(node.label)" -->
                        <span
                            class="left-name"
                            v-if="corpType == 0 && authType == 0"
                            style="left:0px"
                        >
                            <open-data
                                :type="'departmentName'"
                                :openid="node.label"
                            ></open-data>
                        </span>
                        <span  v-else class="left-name" style="left:0px">
                            {{ node.label }}
                        </span>
                        <span class="left-staff-tags" v-if="isTB">
                            <template v-if="data.tags && data.tags.length != 0">
                                <span class="tags-view" v-for="(tag, tagIndex) in data.tags" :key="tagIndex">{{ filterTagName(tag) }}</span>
                            </template>
                            <template v-else>
                                --
                            </template>
                        </span>
                        <span class="left-staff-num">{{ data.peopleCount }}</span>
                        <!--<span class="left-sys-acc">
                            <span v-if="data.botAuth">可咨询机器人</span>
                            <!~~ <span v-if="data.botAuthBeyond" class="left-sys-acc-tip"
                            >超出人数，不可咨询</span
                            > ~~>
                            <template  v-if="CompanyIsOld">
                                <span class="left-sys-acc-tip"  v-if="data.botAuthBeyond">超出人数，不可咨询</span>
                            </template>
                            <template v-else>
                                <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 && data.botAuthBeyond">超出人数，不可咨询</span>
                            </template>
                            <span v-if="data.botAuth && data.workOrderAuth"
                            >，</span
                            >
                            <span v-if="data.workOrderAuth">可使用工单</span>
                            <span
                                v-if="
                                    !data.botAuthBeyond &&
                                    !data.botAuth &&
                                    !data.workOrderAuth
                                "
                            >--</span
                            >
                        <!~~ <span
                            v-if="!data.workOrderAuth"
                            class="left-sys-acc-tip"
                            >超出数量，不可使用</span
                        > ~~>
                        </span>-->
                        <span class="left-sys-bothAuth">
                            <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.botAuth"></i>
                            <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </span>
                        <span class="left-sys-ai">
                            <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.bigModel"></i>
                            <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </span>
                        <span class="left-sys-workAuth">
                            <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.workOrderAuth"></i>
                            <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </span>
                    </span>
                </el-tree>
            </div>
            
            </div>
            <!-- <el-tree
                v-show="lazyLoad && !isSeaching"
                show-checkbox
                :data="treeData"
                :props="defaultProps"
                :default-expand-all="false"
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
                class="tree-line"
                ref="dTree_lazy"
                :indent="0"
                node-key="id"
                :load="loadNode"
                lazy
            >
                <span
                    @mouseover="nodeMouseover('handele' + node.id)"
                    @mouseleave="nodeMouseleave('handele' + node.id)"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                >
                    <span
                        class="left-name"
                        v-if="isNumber(node.label)"
                    >
                        <open-data
                            :type="'departmentName'"
                            :openid="node.label"
                        ></open-data>
                    </span>
                    <span v-else class="left-name">{{ node.label }}</span>
                    <span class="left-staff-num">{{ data.peopleCount }}</span>
                    <span class="left-sys-acc"
                        ><span v-if="data.botAuth">可咨询机器人</span>
                        <template  v-if="CompanyIsOld">
                            <span class="left-sys-acc-tip"  v-if="data.botAuthBeyond">超出人数，不可咨询</span>
                        </template>
                        <template v-else>
                            <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 &&data.botAuthBeyond">超出人数，不可咨询</span>
                        </template>
                        <span v-if="data.botAuth && data.workOrderAuth">，</span>
                        <span v-if="data.workOrderAuth">可使用工单</span>
                        <span
                            v-if="
                                !data.botAuthBeyond &&
                                !data.botAuth &&
                                !data.workOrderAuth
                            "
                            >--</span
                        >
                    </span>
                </span></el-tree
            > -->
            <el-tree
                key="selectOrganizationTreeLazy"
                v-show="lazyLoad && !isSeaching"
                show-checkbox
                :data="treeData"
                :props="defaultProps"
                :default-expand-all="false"
                @node-click="handleNodeClick"
                @node-expand="nodeExpandNew"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpandedKeysMember"
                class="lazy-tree-line"
                ref="dTree_lazy"
                :indent="0"
                node-key="id"
                check-strictly
            >
                <span
                    @mouseover="nodeMouseover('handele' + node.id)"
                    @mouseleave="nodeMouseleave('handele' + node.id)"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                    :class="!data.type ? 'load-more-node' : ''"
                >
                    <i
                        :class="['el-icon-caret-new',
                            data.showChildren ? 'el-icon-caret-bottom' : 'el-icon-caret-right',
                        ]"
                        v-show="data.type == 1"
                        @click.stop="nodeExpandNew(data, node)"
                    >
                    </i>
                    <!-- v-if="isNumber(node.label)" -->
                    <span
                        class="left-name"
                        v-if="corpType == 0 && authType == 0"
                    >
                        <open-data
                            :type="'departmentName'"
                            :openid="node.label"
                        ></open-data>
                    </span>
                    <span v-else class="left-name">
                        {{ node.label }}
                    </span>
                    <span class="left-staff-tags" v-if="isTB">
                        <template v-if="data.tags && data.tags.length != 0">
                            <span class="tags-view" v-for="(tag, tagIndex) in data.tags" :key="tagIndex">{{ filterTagName(tag) }}</span>
                        </template>
                        <template v-else>
                            --
                        </template>
                    </span>
                    <span class="left-staff-num">{{ data.peopleCount }}</span>
                    <span class="left-sys-bothAuth">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.botAuth"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    <span class="left-sys-ai">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.bigModel"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    <span class="left-sys-workAuth">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.workOrderAuth"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    
                    <!--<span class="left-sys-acc">
                        <span v-if="data.botAuth">可咨询机器人</span>
                        <!~~ <span v-if="data.botAuthBeyond" class="left-sys-acc-tip"
                            >超出人数，不可咨询</span
                        > ~~>
                        <template  v-if="CompanyIsOld">
                            <span class="left-sys-acc-tip"  v-if="data.botAuthBeyond">超出人数，不可咨询</span>
                        </template>
                        <template v-else>
                            <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 &&data.botAuthBeyond">超出人数，不可咨询</span>
                        </template>
                        <span v-if="data.botAuth && data.workOrderAuth">，</span>
                        <span v-if="data.workOrderAuth">可使用工单</span>
                        <span
                            v-if="
                                !data.botAuthBeyond &&
                                !data.botAuth &&
                                !data.workOrderAuth
                            "
                            >--</span
                        >
                        <!~~ <span v-if="!data.workOrderAuth" class="left-sys-acc-tip"
                            >超出数量，不可使用</span
                        > ~~>
                    </span>-->
                </span></el-tree
            >
            <el-tree
                v-show="!lazyLoad && !isSeaching"
                show-checkbox
                :data="treeData"
                :props="defaultProps"
                :default-expand-all="true"
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
                :empty-text="emptyText"
                class="tree-line"
                ref="dTree"
                node-key="id"
                :indent="0"
                check-strictly
            >
                <span
                    @mouseover="nodeMouseover('handele' + node.id)"
                    @mouseleave="nodeMouseleave('handele' + node.id)"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                >
                    <!-- v-if="isNumber(node.label)" -->
                    <span
                        class="left-name"
                        v-if="corpType == 0 && authType == 0"
                    >
                        <open-data
                            :type="'departmentName'"
                            :openid="node.label"
                        ></open-data>
                    </span>
                    
                    <span v-else class="left-name">
                        {{ node.label }}
                    </span>
                    <span class="left-staff-tags" v-if="isTB">
                        <template v-if="data.tags && data.tags.length != 0">
                            <span class="tags-view" v-for="(tag, tagIndex) in data.tags" :key="tagIndex">{{ filterTagName(tag) }}</span>
                        </template>
                        <template v-else>
                            --
                        </template>
                    </span>
                    <span class="left-staff-num">{{ data.peopleCount }}</span>
                    <span class="left-sys-bothAuth">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.botAuth"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    <span class="left-sys-ai">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;" v-if="data.bigModel"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    <span class="left-sys-workAuth">
                        <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="data.workOrderAuth"></i>
                        <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                    </span>
                    
                    <!--<span class="left-sys-acc">
                        <span v-if="data.botAuth">可咨询机器人</span>
                        <!~~ <span v-if="data.botAuthBeyond" class="left-sys-acc-tip"
                            >超出人数，不可咨询</span
                        > ~~>
                        <!~~ <template  v-if="CompanyIsOld">
                            <span class="left-sys-acc-tip"  v-if="data.botAuthBeyond">超出人数，不可咨询</span>
                        </template>
                        <template v-else>
                            <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 &&data.botAuthBeyond">超出人数，不可咨询</span>
                        </template> ~~>

                        <span v-if="data.botAuth && data.workOrderAuth">，</span>
                        <span v-if="data.workOrderAuth">可使用工单</span>
                        <span
                            v-if="
                                !data.botAuthBeyond &&
                                !data.botAuth &&
                                !data.workOrderAuth
                            "
                            >--</span
                        >
                        <!~~ <span v-if="!data.workOrderAuth" class="left-sys-acc-tip"
                            >超出数量，不可使用</span
                        > ~~>
                    </span>-->
                </span>
            </el-tree>
         </div>
         <div v-if="totalNumber > 20" class="depart-search-list-b">
            <pagination
                :pageSize="pageSize"
                :currentPage="currentPage"
                :total="totalNumber"
                @currentChange="handleCurrentChange">
            </pagination>
        </div>
        <popup v-if="setSysAccDialog" @closeEvent="setSysAccDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.setSysAccTitle')}}</div>
            <div slot="popup-tip">{{$t('staffManage.setSysAccTip')}}</div>
            <div slot="popup-con">
                <SetSysuse
                    ref="setSysuse"
                    :handleType="handleType"
                    :selectedList="JSON.stringify(selectedList)"
                    @reloadData="reloadData"
                    @setDisableSave="setDisableSave"
                    @setNoDisableSave="setNoDisableSave"
                    @saveSetSysAccSuccess="saveSetSysAccSuccess"
                />
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="setSysAccDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    :disabled="disableSave"
                    style="color: #ffffff"
                    @click="saveSetSysAccDialog"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import { formatTree } from "./../utils/formatTree";
import SetSysuse from "./SetSysuse.vue";
import Popup from "@/components/popup";
import DefaultImage from "@/components/DefaultImage.vue";
import OpenData from "@/components/openData";
import pagination from '../../../../components/pagination'
import { isTB } from '../utils/customerMainId'
// disabled:function (data,node){ return !data.type}
export default {
    props: ["corpId", "tagsList" , "corpType", "authType"],
    components: { Popup, SetSysuse, DefaultImage, OpenData ,pagination },
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: "children",
                isLeaf: "isLeaf"
            },
            lazyLoad: false,
            departLoading: true,
            // 设置系统权限
            setSysAccDialog: false,
            selectedList: [{ name: "部门1" }, { name: "部门2" }],
            handleType: "staff",
            // 搜索
            searchTreeData: [],
            isSeaching: false,
            disableSave: false,
            emptyText: "",
            currentPage:1,
            pageSize:20,
            keyword:"",
            mainValue:"",
            totalNumber:"",
            promoteSurplusDay:-1,
            node:null,
            resolve:null,
            newCurrentPage: 1,
            newPageSize: 10,
            defaultExpandedKeys: [],
            defaultExpandedKeysMember: [],
            tagsIcon:'el-icon-arrow-down',
            tagsId:[],
            isTB:false,
        };
    },
    methods: {
        // 节点被展开
        nodeExpandNew(data, node) {
            data.showChildren = !data.showChildren;
            if (data.showChildren) {
                this.newCurrentPage = 1;
                this.getData(data.id, data, node);
            } else {
                this.newCurrentPage = 1;
                data.children = [];
            }
        },
        // 获取员工数据
        getData(departId, data, node, isLoadMore) {
            let axiosDatas = [];
            let userId = localStorage.getItem("_uid");
            if (!isLoadMore) {
                this.currentPage = 1;
            }
            let url = ''
            if(departId) {
                url = "/api/department/corpListLazy-v2" +
                    "?departId=" +
                    departId +
                    "&userId=" +
                    userId +
                    "&corpId=" +
                    this.corpId + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize
            } else {
                "/api/department/corpListLazy-v2" +
                "?userId=" +
                userId +
                "&corpId=" +
                this.corpId  + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize;
            }
            if(this.isTB) {
                url += '&needDepartTag=true'
            }
            this.$http.get(url).then((res) => {
                // let memberId = Number(this.memberInfo4Client.memberId);
                let ids = [];
                let datas = res.data.list.filter(item => item.type !== 0)
                axiosDatas = this.handlerCheckedSelf(
                    datas || [],
                    ids,
                    departId ? departId : -1
                );
                console.log(res.data.list, axiosDatas);
                this.getAllNodeId(axiosDatas, "show");
                if (departId) {
                    if (res.data.hasNextPage){
                        let info = {
                            label: "加载更多",
                            dataType: 'hasNext',
                            id: departId + 'hasNext',
                            parentId: departId,
                            currentPage: res.data.nextPage?res.data.nextPage:1
                        }
                        axiosDatas = axiosDatas.concat([info])
                    }
                    axiosDatas.forEach(element => {
                        if(element.type === 0 || element.dataType === 'hasNext') {
                            element.leaf = true
                        }
                    })
                    if (isLoadMore) {
                        let flagFind = true;
                        let findParent = (list) => {
                            if (flagFind) {
                                list.forEach(item => {
                                    if (item.id == departId) {
                                        flagFind = false;
                                        if (item.children[item.children.length - 1].dataType == 'hasNext') {
                                            item.children.length = item.children.length - 1;
                                        }
                                        item.children = [...item.children, ...axiosDatas];
                                        this.defaultExpandedKeysMember = [
                                            ...this.defaultExpandedKeysMember,
                                            ...[departId],
                                        ];
                                        item.showChildren = true;
                                    } else {
                                        findParent(item.children);
                                    }
                                })
                            }
                            
                        }
                        findParent(this.treeData);
                    } else {
                        data.children = [...data.children, ...axiosDatas];
                        this.defaultExpandedKeysMember = [
                            ...this.defaultExpandedKeysMember,
                            ...[departId],
                        ];
                        data.showChildren = true;
                    }
                } else {
                    this.treeData = [...axiosDatas];
                    this.defaultExpandedKeysMember = [];
                }
                console.log(this.treeData);
                this.treeData.forEach((item) => {
                    if (!departId) {
                        item.showChildren = false;
                        item.children = [];
                    }
                });
            });
        },
        handlerCheckedSelf(datas, ids, parentId) {
            datas.forEach((item) => {
                item.checked = false;
                item.showChildren = false;
                if (item.type === 1) {
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                if (ids.indexOf(item.id) > -1) {
                    let ref = item.id + "TreeLazy";
                    // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                } else {
                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                }
            });
            return datas;
        },
        // 递归获取所有节点
        getAllNodeId(moduleDataList, type) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        if (type) {
                            [].forEach((item) => {
                                if (moduleDataList[i].id == item) {
                                    // this.selectDepts.push(moduleDataList[i]);
                                    this.$set(
                                        moduleDataList[i],
                                        "name",
                                        moduleDataList[i].label
                                    );
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            });
                        }
                        if (moduleDataList[i].children) {
                            this.getAllNodeId(moduleDataList[i].children, type);
                        }
                    }
                }
                resolve(true);
            });
        },
        //部门是否是纯数字
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        setDisableSave() {
            this.disableSave = true;
        },
        setNoDisableSave() {
            this.disableSave = false;
        },
        saveSetSysAccSuccess() {
            this.reloadData()
        },
        reloadData() {
            this.setSysAccDialog = false;
            this.disableSave = false;
            if (!this.isSeaching && !this.lazyLoad) {
                this.$refs.dTree.setCheckedKeys([]);
            }
            if (!this.isSeaching && this.lazyLoad) {
                this.$refs.dTree_lazy.setCheckedKeys([]);
            }
            if (this.isSeaching && this.treeData.length != 0) {
                this.$refs.dTrees.setCheckedKeys([]);
            }
            if(!this.isSeaching){
                this.getdepartmentTree(this.corpId,true);
            } else {
                this.loadSearchNode(this.node,this.resolve)
            }
            
        },
        saveSetSysAccDialog() {
            this.$refs.setSysuse.saveSetSysAccDialog();
        },
        //部门搜索改为懒加载
        loadSearchNode(node, resolve){
            this.node = node;
            this.resolve = resolve;
            if ((this.keyword.trim() !== "" || this.tagsList.length != 0) && node.level == 0){
                this.departLoading = true;
                let url = this.requestUrl.department.newSearchDepartment +
                            "?keyword=" +
                                this.keyword +
                            "&corpId=" +
                                this.mainValue +
                            '&page=' +
                                this.currentPage +
                            '&size=' +
                                this.pageSize
                if(this.isTB) {
                    url += '&needDepartTag=true' + '&tags=' + this.tagsId.join(',')
                }
                this.$http
                    .get(url)
                    .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log('resData',res.data);
                        this.isSeaching = true;
                        this.searchTreeData = res.data.data.list?res.data.data.list:[];
                        this.totalNumber = res.data.data.total;
                        this.searchTreeData.forEach((item) => {
                        this.$set(item,'label',item.name);
                        /*this.$set(item,'isLeaf',item.haveChildren);*/
                        });
                        this.departLoading = false;
                        console.debug('searchTreeData',this.searchTreeData)
                        resolve(this.searchTreeData)
                    } else {
                        this.$message.error(res.data.message);
                    }
                    });
            }   else if (node.level >= 1){
                let url = this.requestUrl.department.getDepartmentTreeLazy +
                            "?corpId=" +
                                this.corpId +
                            "&parentId=" +
                                node.data.id +
                            '&lazy=true' +
                                '&needDepartPeopleNum=true'
                if(this.isTB) {
                    url += '&needDepartTag=true' + '&tags=' + this.tagsId.join(',')
                }
                this.$http
                    .get(url)
                    .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                        if (res.data.data.length > 0) {
                            let list = res.data.data;
                            list.forEach((item) => {
                            item.isLeaf = !item.haveChildren;
                            });
                            node.data.children = list;
                            return resolve(list);
                        } else {
                            resolve([]);
                        }
                        }
                    } else {
                        // this.$message.error(res.data.message);
                    }
                    });
            }
        },
        handleCurrentChange(e){
            this.currentPage = e;
            this.searchMemberList()
        },
        search(keyword, mainValue) {
            if (keyword.trim() === "" && this.tagsId.length == 0) {
                this.currentPage = 1;
                this.isSeaching = false;
                /*this.getMemberListByDeptId({
                    id: this.activeDeptId,
                    currentPage: 1,
                });*/
            } else {
                if(this.isSeaching) {
                    this.isSeaching = false
                }
                this.$nextTick(() =>{
                    this.keyword = keyword;
                    this.mainValue = mainValue;
                    this.isSeaching = true;
                })
            }
        },
        searchMemberList(){
            let url = this.requestUrl.department.newSearchDepartment +
                        "?keyword=" +
                            this.keyword +
                        "&corpId=" +
                            this.mainValue +
                        '&page=' +
                            this.currentPage +
                        '&size=' +
                            this.pageSize
            if(this.isTB) {
                url += '&needDepartTag=true' + '&tags=' + this.tagsId.join(',')
            }
            this.$http.get(url).then((res) => {
                if (res.data && res.data.code === "0") {
                    console.log('resData',res.data);
                    this.isSeaching = true;
                    this.searchTreeData = res.data.data.list?res.data.data.list:[];
                    this.totalNumber = res.data.data.total;
                    this.searchTreeData.forEach((item) => {
                    this.$set(item,'label',item.name);
                    /*this.$set(item,'isLeaf',!item.haveChildren);*/
                    });
                    console.debug('searchTreeData',this.searchTreeData)
                } else {
                    this.$message.error(res.data.message);
                }
                });
        },
        setSysAcc(mainId) {
            this.handleType = "depart";
            let items = [];
            if (this.isSeaching) {
                items = this.$refs.dTrees.getCheckedNodes();
            } else {
                if (this.lazyLoad) {
                    items = this.$refs.dTree_lazy.getCheckedNodes();
                } else {
                    items = this.$refs.dTree.getCheckedNodes();
                }
            }
            items.forEach((i) => {
                i.name = i.label;
            });
            this.selectedList = [...items];
            if (this.selectedList.length === 0) {
                this.$message.warning(this.$t('staffManage.departList.permissionRequired'));
            } else {
                this.setSysAccDialog = true;
            }
        },
        loadNode(node, resolve) {
            let url = this.requestUrl.department.getDepartmentTreeLazy +
                            "?corpId=" +
                            this.corpId +
                            "&parentId=" +
                            node.data.id +
                            '&needDepartPeopleNum=true'
                            
            if(this.isTB) {
                url += "&needDepartTag=true"
            }
            node.data.id &&
                this.$http.get(url).then((res) => {
                        if (res.data && res.data.code === "0") {
                            if (res.data.data != null) {
                                if (res.data.data.length > 0) {
                                    let list = res.data.data;
                                    list.forEach((item) => {
                                        item.isLeaf = !item.haveChildren;
                                    });
                                    resolve(list);
                                } else {
                                    resolve([]);
                                }
                            }
                        } else {
                            // this.$message.error(res.data.message);
                        }
                    });
        },
        handleNodeClick(data, node, el) {
            console.log(data, node, el);
            if (data.dataType === "hasNext") {
                this.newCurrentPage = data.currentPage;
                this.getData(data.parentId, {}, "", true);
            }
        },
        nodeMouseover() {},
        nodeMouseleave() {},
        editCapital() {},
        deleteCapital() {},
        getdepartmentTree(val, isSwitchMain) {
            console.log(3333);
            this.isSeaching = false;
            if (this.treeData.length == 0 || isSwitchMain) {
                this.departLoading = true;
                this.$http
                    .get(
                        this.requestUrl.department.getDepartmentTreeLazy +
                            "?corpId=" +
                            val + '&needDepartPeopleNum=true' +
                            "&needDepartTag=true"
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            if (res.data.data != null) {
                                if (res.data.data.length > 0) {
                                    this.treeData = [
                                        ...formatTree(res.data.data),
                                    ];
                                    // this.departLoading = false;
                                    this.lazyLoad = this.treeData[0].lazy;
                                }
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                        this.departLoading = false;
                    });
            }
        },
        batchSetDeptTags(corpId) {
            if(this.isSeaching) {
                this.$emit("openTagsDialog", this.$refs.dTrees.getCheckedNodes())
            } else {
                if(this.lazyLoad) {
                    this.$emit("openTagsDialog", this.$refs.dTree_lazy.getCheckedNodes())
                } else {
                    this.$emit("openTagsDialog", this.$refs.dTree.getCheckedNodes())
                }
            }
        },
        filterTagName(tags) {
            if(tags) {
                if(Array.isArray(tags) && tags.length != 0) {
                    return this.tagsList.filter(item =>{ 
                        return tags.includes(Number(item.id))
                    }).map(item =>{return item.label})
                } else {
                    return this.tagsList.find(item =>{ 
                        return tags == Number(item.id)
                    }).label
                }
            } else {
                return ''
            }  
        },
        handleMemberTags(command) {
            this.isSeaching = false
            if(command == null) {
                this.tagsId = []
            } else {
                // if(this.tagsId.includes(command)) {
                //     this.tagsId = this.tagsId.filter( id => { return id != command})
                // } else {
                //     this.tagsId.push(command)
                // }
                this.tagsId = [command]
            }
            setTimeout(() =>{
                this.search(this.keyword, this.corpId);
            }, 200)
        },
    },
    mounted(){
        this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
        this.isTB = isTB()
    }
};
</script>
<style lang="less">
.more_dept_tag_content {
    max-width: 200px;
    span {
        line-height: 22px;
        margin-right: 5px;
        margin-top: 5px;
        background: #e0e8ff;
        color: #366AFF;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 4px;
        white-space: nowrap;
        cursor: pointer;
    }
}
</style>
<style lang="less" scoped>
.tree-container {
    background-color: white;
    height: calc(100vh - 165px);
    min-width: 1100px;
    overflow: hidden;
  position: relative;
  overflow-y: scroll;
  .el-loading-parent--relative{
      height: 100%;
  }
    .tree-header {
        position: relative;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #f6f8fd;
        .tree-header-name {
            padding-left: 46px;
            min-width: 680px;
        }
        .tree-header-tag {
            width: 220px;
            position: absolute;
            right: 370px;
            text-align: center;
            cursor: pointer;
        }
        .tree-header-num {
            width: 100px;
            position: absolute;
            right: 270px;
            text-align: center;
        }
        .tree-header-work {
            width: 90px;
            position: absolute;
            right: 0;
            text-align: center;
        }
        .tree-header-model {
            width: 90px;
            position: absolute;
            right: 90px;
            text-align: center;
        }
        .tree-header-bot {
            width: 90px;
            position: absolute;
            right: 180px;
            text-align: center;
        }
        // .tree-header-acc {
        //     width: 280px;
        //     position: absolute;
        //     right: 0;
        //     text-align: center;
        // }
    }
  .depart-search-list{
    height: calc(100% - 100px);
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .depart-search-list-b{
    // position: absolute; 搜索样式错乱紧急处理
    bottom: 0;
    width: 100%;
  }
  .lazy-tree-line{
    /deep/.el-tree-node__expand-icon{
      display: none;
    }
    /deep/.is-disabled{
      display: none;
    }
  }
    .custom-tree-node {
        position: relative;
        display: block;
        width: 100%;
        display: flex;
        height: 100%;
        .left-name {
            line-height: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: none;
            // min-width: 680px;
            max-width: 400px;
            width: 100%;
            position: absolute;
            left: 20px;
            // display: flex;
            // align-items: center;
            .dept_tag {
                line-height: 20px;
                height: 20px;
                margin: 0 8px;
                background: #e0e8ff;
                color: #366AFF;
                border-radius: 2px;
                font-size: 12px;
                padding: 0 4px;
            }
            .more_dept_tag_btn {
                width: 20px;
                height: 20px;
                border-radius: 2px;
                background: #e0e8ff;
                line-height: 20px;
                text-align: center;
                cursor: pointer;
                i {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 500;
                    color: #366AFF !important;
                }
            }
        }
        .left-staff-tags {
            width: 220px;
            height: 62px;
            position: absolute;
            right: 380px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .tags-view {
                height: 16px;
                margin: 2px 4px;
                background: #e0e8ff;
                color: #366AFF;
                border-radius: 2px;
                font-size: 12px;
                padding: 0 4px;
                flex: none;
            }
        }
        .left-staff-num {
            line-height: 62px;
            width: 100px;
            position: absolute;
            right: 280px;
            text-align: center;
        }
        .left-sys-acc {
            width: 280px;
            position: absolute;
            right: 0;
            line-height: 62px;
            text-align: center;
            .left-sys-acc-tip {
                height: 20px;
                background: #ffebec;
                border-radius: 3px;
                color: #fe5965;
                font-size: 12px;
                border-radius: 3px;
                padding: 2px 3px;
            }
        }
        .left-sys-bothAuth {
            width: 90px;
            position: absolute;
            right: 180px;
            line-height: 62px;
            text-align: center;
        }
        .left-sys-ai {
            width: 90px;
            position: absolute;
            right: 90px;
            line-height: 62px;
            text-align: center;
        }
        .left-sys-workAuth {
            width: 90px;
            position: absolute;
            right: 0;
            line-height: 62px;
            text-align: center;
        }
    }
  .load-more-node{
    .left-name{
      color: #366aff;
    }
  }
}
.tree-container /deep/ .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.tree-container /deep/ .el-icon-caret-right:before {
    content: "\e791";
    font-size: 18px;
}

.tree-container /deep/ .el-tree-node__expand-icon {
    margin-left: 15px;
    padding: 0px;
}

.tree-container /deep/ .el-tree-node__expand-icon.is-leaf {
    margin-left: 0px;
}

.tree-container /deep/ .el-tree-node {
    position: relative;
    padding-left: 16px;
}

.tree-container /deep/ .el-tree-node__children {
    padding-left: 16px;
}

.tree-container /deep/ .el-tree > .el-tree-node:before {
    border-left: none;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
    border-top: none;
}
.tree-container /deep/ .el-tree > .el-tree-node:before {
    border-left: none;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
    border-top: none;
}

.tree-container /deep/ .el-tree-node:before {
    content: "";
    left: 10px;
    position: absolute;
    right: auto;
    border-width: 1px;
}

.tree-container /deep/ .el-tree-node:after {
    content: "";
    left: 10px;
    position: absolute;
    right: auto;
    border-width: 1px;
}

.tree-container /deep/ .el-tree-node:before {
    border-left: 1px dashed #a1b9ff;
    bottom: 0px;
    height: 100%;
    top: -29px;
    width: 1px;
}

.tree-container /deep/ .el-tree-node:after {
    border-top: 1px dashed #a1b9ff;
    height: 50px;
    top: 30px;
    width: 20px;
}

.el-tree-node :last-child:before {
    height: 60px;
}

.tree-container {
    // margin: 10px;
}
.tree-container /deep/ .el-tree .el-tree-node {
    position: relative;
}
.tree-container /deep/ .el-tree-node .el-tree-node__content {
    height: 60px;
    padding-left: 18px;
    margin-left: 12px;
    border-bottom: solid 1px #eff2f9;
}
.tree-container /deep/ .el-tree-node .el-tree-node__content::before {
    border-left: 1px dashed #a1b9ff;
    height: 100%;
    top: 0;
    width: 1px;
    margin-left: 1px;
    margin-top: 0px;
    z-index: 8;
}
.tree-container /deep/ .el-tree-node__children {
    border-left: none;
    margin-left: 12px;
}
.tree-container
    /deep/
    .el-tree-node
    .el-tree-node__children
    .el-tree-node__content::before {
    border-left: none;
    height: 100%;
    top: 0;
    width: 1px;
    margin-left: 1px;
    margin-top: 0px;
    z-index: 8;
}

.tree-container /deep/ .el-tree-node .el-tree-node__content::after {
    border-top: 1px dashed #a1b9ff;
    height: 1px;
    top: 18px;
    width: 13px;
    margin-left: 1px;
    z-index: 8;
}

.tree-container
    /deep/
    .el-tree-node
    .el-tree-node__children
    .el-tree-node__content::after {
    border-top: 0px dashed #a1b9ff;
}

.tree-container .el-tree-node .el-tree-node__content::before,
.tree-container .el-tree-node .el-tree-node__content::after {
    content: "";
    position: absolute;
    right: auto;
}

.tags-dropdown {
    width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    // word-break: break-all;
    line-height: 24px;
    padding-bottom: 5px;
    padding-top: 5px;
    
    .tags-dropdown-name {
        display: inline-block;
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
