<template>
    <div id="addRole">
        <left-menu></left-menu>
        <div class="addRole-container">
            <div class="addRole-container-header">
                <div class="header-left" @click="goBack">
                    <i class="arsenal_icon arsenalfanhui" style="font-weight: 600"></i>
                    <span>返回角色列表</span>
                </div>
                <div class="header-title">{{addRoleTitle}}</div>
                <div class="header-right">
                    <el-button size="small" @click="closeAddRole">关 闭</el-button>
                    <el-button type="primary" size="small" @click="preserve" v-if="preserveBtn">保 存</el-button>
                </div>
            </div>
            <div class="addRole-container-container">
                <div class="container">
                    <div class="roleName">
                        <span class="spanItem"><span style="color: red">*</span>角色名称</span>
                        <el-input v-model="roleName" placeholder="请输入角色名称" style="width: 60%" :readonly=IsReadOnly></el-input>
                    </div>
                    <div class="roleDescribe">
                        <span class="spanItem">角色描述</span>
                        <el-input v-model="roleDescribe" placeholder="请输入角色描述" style="width: 60%" :readonly="IsReadOnly"></el-input>
                    </div>
                    <div class="tabCheck">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane
                                    :label="item.name"
                                    :name="item.id"
                                    v-for="(item, index) in systemList"
                                    :key="index"
                                    v-if="item.id!=='IM'&&item.id!=='WORKORDER'"
                            >
                                <ask-bot
                                        :privilegeTree="item"
                                        :handleClicked="handleClicked"
                                        :roleType="$route.query.type"
                                        :system="systemList"

                                ></ask-bot>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import leftMenu from "../../../menu/leftMenu";
    import AskBot from "./askBot";
    import {operable} from "../../js/operable";

    export default {
        name: "addRole",
        data(){
            return{
                addRoleTitle:'新增角色',
                roleDescribe:"",
                roleName:"",
                activeName:"PORTAL",
                roleId:"",
                systemList:[
                ],
                rolesInfo:{},
                handleClicked:0,
                rolePrivilegesList:[],
                openChecked:false,
                IMChecked:false,
                WorkOrderChecked:false,
                IsReadOnly:false,
                preserveBtn:true

            }
        },
        components:{AskBot, leftMenu},
        computed:{
            operableRoleDetail(){
                return function (type) {
                    return operable(type)
                }
            }
        },
        watch:{
          /*  systemList:{
                handler(value){
                   console.log(value)
                    this.choose(value)
                },
                deep:true,
                immediate:true
            }*/
        },
        mounted() {
            this.roleId=''
            if(this.$route.query.type==='add')
            {
                this.addRoleTitle='新增角色'
                this.getPrivilegeTree()
            }
           else if (this.$route.query.type==='edit')
            {
                this.addRoleTitle='编辑角色'
            }
           else
               this.addRoleTitle='角色详情'
            if (this.$route.query.id!==undefined)
            {
                this.roleId=this.$route.query.id
                console.log(this.roleId)
                this.getRRoleDetail()
            }
            this.rolePrivilegesList=this.getRolePrivileges()
            if (this.$route.query.type==='details') {
                this.IsReadOnly=true
                this.preserveBtn=false
            }
            else if (operable('PORTAL_MANAGEMENT_ROLE_UPDATE')&&this.$route.query.type==='edit'){
                this.IsReadOnly=false
                this.preserveBtn=true

            }
            else if (operable('PORTAL_MANAGEMENT_ROLE_ADD')&&this.$route.query.type==='add'){
                this.IsReadOnly=false
                this.preserveBtn=true
            }
            else {
                this.IsReadOnly=true
                this.preserveBtn=false
            }
        },
        methods:{
            //获取权限数
            getPrivilegeTree(){
                let url='/api/privilege/tree'
                this.$http.get(url).then(res=>{
                    console.log('getPrivilegeTree',res.data)
                    if (res.data.code==='0')
                    {
                        this.systemList=res.data.data
                    }

                })
            },
            //获取角色详情
            getRRoleDetail(){
                let url='api/role/detail/'+this.roleId
                this.$http.get(url).then(res=>{
                    if (res.data.code==='0')
                    {
                        this.rolesInfo=res.data.data
                        this.roleName=res.data.data.name
                        this.roleDescribe=res.data.data.remark
                        if (res.data.data.privileges!==null)
                        {
                            this.systemList=res.data.data.privileges.privileges
                        }
                    }
                })
            },
            goBack(){
                this.$router.go(-1)
            },
            handleClick(value){
                this.handleClicked+=1
            },
            closeAddRole(){
                this.$router.go(-1)
            },
            choose(value){
                let openChecked=false
                let IMChecked=false
                let WorkOrderChecked=false
                value.forEach(item=>{
                    if(item.id==='OPEN'){
                        if (item.checked){
                            openChecked=true
                        }
                        else {
                            openChecked=false
                        }
                    }
                    if (item.id==='IM'){
                        if (item.checked){
                            IMChecked=true
                        }
                        else {
                            IMChecked=false
                        }
                    }
                    if (item.id==='WORKORDER'){
                        if (item.checked){
                            WorkOrderChecked=true
                        }
                        else {
                            WorkOrderChecked=false
                        }
                    }
                })
                value.forEach(item=>{
                    if (item.id==='PORTAL'){
                        item.children.forEach(portalItem=>{
                            if (portalItem.id==='PORTAL_ENTRANCE'){
                                portalItem.children.forEach(portalItemItem=>{
                                    if (portalItemItem.id==='PORTAL_ENTRANCE_OPEN'){
                                        if (openChecked){
                                            portalItemItem.checked=true
                                        }
                                        else {
                                            portalItemItem.checked=false
                                        }
                                    }
                                    if (portalItemItem.id==='PORTAL_ENTRANCE_IM'){
                                        if (IMChecked){
                                            portalItemItem.checked=true
                                        }
                                        else {
                                            portalItemItem.checked=false
                                        }

                                    }
                                    if (portalItemItem.id==='PORTAL_ENTRANCE_WORKORDER'){
                                        if (WorkOrderChecked){
                                            portalItemItem.checked=true
                                        }
                                        else {
                                            portalItemItem.checked=false
                                        }

                                    }
                                })
                            }
                        })
                    }
                })

            },
            preserve(){
                console.log(this.systemList)
                let flag=0
                this.systemList.forEach(item=>{
                    if (item.checked){
                        flag+=1
                    }
                })
                console.log(flag)
                if (flag===0){
                    this.$message({
                        type:'warning',
                        message:'请至少选择一个权限',
                        duration:2000
                    })
                    return
                }
                if (this.roleName==='')
                {
                    this.$message({
                        message:'角色名称不能为空',
                        type:'error',
                        duration:2000
                    })
                    return
                }
                console.log('addSystem',this.systemList)
                if (this.$route.query.type==='add')
                {
                    let url='/api/role'
                    let role={
                        name:this.roleName,
                        remark:this.roleDescribe,
                        privileges:{
                            privileges:this.systemList
                        }
                    }
                    this.$http.post(url,role).then(res=>{
                        if (res.data.code==='0')
                        {
                            this.$message({
                                message:'新增角色成功',
                                type:'success',
                                duration:2000
                            })
                        }
                    })
                }
                else {
                    let url='/api/role/'+this.roleId
                    let role={
                        name:this.roleName,
                        remark:this.roleDescribe,
                        privileges:{
                            privileges:this.systemList
                        }
                    }
                    this.$http.put(url,role).then(res=>{
                        if (res.data.code==='0'){
                            this.$message({
                                message:'更新角色成功',
                                type:'success',
                                duration:2000
                            })
                        }
                    })
                }
                setTimeout(()=>{
                    this.$router.push({
                        path:'/roleManage'
                    })
                },2000)
            }
        }
    }
</script>

<style  lang="less">
#addRole{
    display: flex;
    .addRole-container{
        position: absolute;
        left: 64px;
        height: 100vh;
        width: calc(100vw - 60px);
        text-align: left;
        .addRole-container-header{
            padding: 8px 16px;
            background: #f5f8fb;
            -webkit-box-shadow: 0 2px 10px -5px #d1dfec;
            box-shadow: 0 2px 10px -5px #d1dfec;
            border-bottom: 1px solid #d1dfec;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-left{
                width: 150px;
                flex: none;
                cursor: pointer;
            }
            .header-title{
                flex: 1;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
            }
            .header-right{
                flex: none;
                display: flex;
                justify-content: space-around;
            }
        }
        .addRole-container-container{
            height: calc(100vh - 60px);
            margin: 15px 20px 0 15px;
            background-color: white;
            display: flex;
            justify-content: center;
            .container{
                width: 60%;
                padding: 20px 0;
                .roleName,.roleDescribe{
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .spanItem{
                        width: 80px;
                    }
                }
                .tabCheck{

                    .el-tabs__nav{
                        width: 200px;
                    }
                    .el-tabs__active-bar{
                        background-color: #366AFF!important;
                        height: 2px!important;
                    }
                }
            }
        }
    }
    .notClick{
        pointer-events: auto;
    }
}
</style>