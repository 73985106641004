<template>
  <div class="accessCompleted">
    <img src="../../../assets/images/accessCompleted.png">
    <div class="tips">
      {{$t('channel.accessCompletedTip')}}
    </div>
  </div>
</template>

<script>
export default {
  name: "accessCompleted"
};
</script>

<style scoped lang="less">
.accessCompleted{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tips{
    margin-top: 30px;
    color: #616161;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>