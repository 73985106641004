<template>
  <div id="myInfo">
      <div class="content-container">
          <div class="header">
                <div class="leftInfo">
                    <span class="headerName">成员管理</span>
                </div>
            </div>
            <div class="myInfo-content">
                <div class="content-box">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="头像">
                        <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="用户角色">
                        <el-input v-model="form.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                    </el-form-item>
                </el-form>
                </div>
            </div>
      </div>
      <leftMenu></leftMenu>
  </div>
</template>

<script>
import leftMenu from './../menu/leftMenu.vue'
export default {
name: 'myInfo',
    components:{
        leftMenu
    },
    data(){
        return{
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            imageUrl: ''
        }
    },
    methods:{
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        onSubmit(){

        },
    }
}
</script>

<style lang="less" scoped>
@import "./../../assets/less/myInfo/myInfo.less";
/deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
    border-color: #366AFF;
}
/deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
}
/deep/.avatar {
    width: 88px;
    height: 88px;
    display: block;
}
.el-form{
    width: 50%;
}
</style>