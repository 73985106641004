var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"askBot"}},[(_vm.privilegeTree.id !== 'WORKORDER')?_c('div',{staticClass:"checkAll"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate,"disabled":_vm.isDisabled},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.privilegeTree.checked),callback:function ($$v) {_vm.$set(_vm.privilegeTree, "checked", $$v)},expression:"privilegeTree.checked"}},[_vm._v("全选")])],1):_vm._e(),_c('div',{staticClass:"manage-permissions"},_vm._l((_vm.privilegeTree.children),function(item,index){return _c('div',{key:index,staticClass:"manage-permissions-title"},[_c('div',{staticClass:"oneLevel"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-checkbox',{attrs:{"indeterminate":item.isIndeterminate,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.checkUnit(
                                $event,
                                item.id,
                                _vm.privilegeTree.children
                            )}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.type === 1)?_c('div',[_c('span',{staticStyle:{"margin-right":"10px","color":"#606266"}},[_vm._v(_vm._s(item.title))]),_c('el-select',{attrs:{"multiple":"","placeholder":"请选择","size":"small","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.changeSelect($event, item.items)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((item.items),function(role){return _c('el-option',{key:role.id,attrs:{"label":role.name,"value":role.id}})}),1)],1):_vm._e()],1)]),_c('div',{staticClass:"manage-permissions-list"},_vm._l((item.children),function(its,ind){return _c('div',{key:ind,staticClass:"manage-permissions-list-item",style:({
                        width: _vm.privilegeTree.id == 'WORKORDER' ? '100%' : ''
                    })},[_c('div',{class:(its.children !== null && its.children.length !== 0)? 'twoLevel' : '',staticStyle:{"display":"flex","align-items":"center"}},[_c('el-checkbox',{style:(its.type === 2 ? 'min-width:100px' : ''),attrs:{"indeterminate":its.isIndeterminate,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.checkUnit($event, its.id, item.children)}},model:{value:(its.checked),callback:function ($$v) {_vm.$set(its, "checked", $$v)},expression:"its.checked"}},[_vm._v(_vm._s(its.name))]),(its.type === 2)?_c('div',[_c('span',{staticStyle:{"color":"#606266","margin-right":"10px"}},[_vm._v(_vm._s(its.title))]),_c('el-radio-group',{attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.changeRadio($event, its)}},model:{value:(its.value),callback:function ($$v) {_vm.$set(its, "value", $$v)},expression:"its.value"}},_vm._l((its.items),function(radio){return _c('el-radio',{key:radio.id,attrs:{"label":radio.id}},[_vm._v(" "+_vm._s(radio.name)+" ")])}),1)],1):_vm._e()],1),_c('div',{class:its.children !== null ? 'list-item-item' : ''},_vm._l((its.children),function(list,listIndex){return _c('div',{key:listIndex,class:[
                                its.children !== null ? 'item-item' : '',
                                list.children !== null
                                    ? 'last-item-item'
                                    : '',
                            ]},[_c('el-checkbox',{class:(list.children !== null && list.children.length !== 0)
                                        ? 'threeLevel'
                                        : '',attrs:{"indeterminate":list.isIndeterminate,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.checkUnit($event, list.id, its.children)}},model:{value:(list.checked),callback:function ($$v) {_vm.$set(list, "checked", $$v)},expression:"list.checked"}},[_vm._v(_vm._s(list.name))]),_c('div',{class:list.children !== null
                                        ? 'lastChildren'
                                        : ''},_vm._l((list.children),function(listChildren,listChildrenIndex){return _c('div',{key:listChildrenIndex,staticClass:"listChildren"},[_c('el-checkbox',{attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.childrenChange(
                                                $event,
                                                listChildren,
                                                its.children
                                            )}},model:{value:(listChildren.checked),callback:function ($$v) {_vm.$set(listChildren, "checked", $$v)},expression:"listChildren.checked"}},[_vm._v(_vm._s(listChildren.name))])],1)}),0)],1)}),0)])}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }