<template>
  <div class="choose-tree">
        <popup @closeEvent="closeEvent" v-if="chooseTreeDialog">
            <div slot="popup-name">{{isTitle}}</div>
            <div slot="popup-tip">{{isTips}}</div>
            <div slot="popup-con" class="popup-con">
                <div class="content-tree">
                    <el-tree
                        id="content-tree"
                        :data="treeOptions"
                        default-expand-all
                        :check-strictly="true"
                        :options="{
                          key:'key'
                        }"
                        ref="cascaderTree"
                        node-key="key"
                        :filter-node-method="filterNode"
                        :expand-on-click-node="false"
                        @check-change="handleCheckChange"
                        @node-click="handleTreeClick"
                    >
                        <span class="el-tree-title" slot-scope="{ node, data }">
                            <span class="el-tree-title-contain">
                                <span class="el-tree-title-word" >
                                  <i
                                    v-if="data.children && data.children.length > 0"
                                    style="color: #a1b9ff;margin-right:5px"
                                    class="
                                    iconfont
                                    guoran-wenjianjia-zhankaizhuangtai--folderOpen
                                    "
                                ></i>
                                </span>
                                <span  class="el-tree-title-word-name">
                                  <span class="word-name">{{ data.label }}</span>
                                </span>
                              <span class="check-icon" @click.stop="()=>{}" v-if="activeObj.extInfo.selectType === '多选' || isAddRule">
                                <el-checkbox v-model="data.checked" @change="changeChecked(data, isAddRule)" :value="data.value" :disabled="data.disabled"></el-checkbox>
                              </span>
                              <span class="check-icon" @click.stop="()=>{}" v-else>
                                <el-radio v-model="cascadeValue" :label="JSON.stringify(data)" :disabled="data.disabled"></el-radio>
                              </span>
                            </span>
                            
                        </span>
                    </el-tree>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveBtn" size="small">确 定</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from "../../../../components/popup";
export default {
    components:{
        Popup
    },
    data(){
        return{
            data: [{
                id: 1,
                label: '一级 1',
                children: [{
                    id: 4,
                    label: '二级 1-1',
                    children: [{
                        id: 9,
                        label: '三级 1-1-1'
                    }, {
                        id: 10,
                        label: '三级 1-1-2'
                    }]
                }]
                }, {
                id: 2,
                label: '一级 2',
                children: [{
                    id: 5,
                    label: '二级 2-1'
                }, {
                    id: 6,
                    label: '二级 2-2'
                }]
            }],
          checkList:[],
          checkDatas:[],
          cascadeValue:''
        }
    },
    props:{
        chooseTreeDialog:{
            type:Boolean,
            default:false
        },
      activeObj:Object,
      isMultiple:Boolean,
      isSetDefault:Boolean,
      fieldItem:Object,
      isAddRule:Boolean,
      isTitle:{
          type:String,
        default() {
          return '选择默认值'
        }
      },
      isTips:{
        type:String,
        default() {
          return '选择级联字段的默认值'
        }
      }
    },
  mounted() {
      console.debug('activeObjs',this.activeObj)
  },
  computed:{
    options(){
      let arr = JSON.parse(JSON.stringify(this.activeObj.extInfo.cascadeDown.options));
      return arr;
    },
    treeOptions() {
      let count = {
        num : 0
      }
      return this.loopSetKey(JSON.parse(JSON.stringify(this.activeObj.extInfo.cascadeDown.options)), count)
    }
    
  },
  methods:{
    loopSetKey(list,count) {
      for (let index = 0; index < list.length; index++) {
        let item = list[index];
        this.$set(item, 'key' , count.num)
        // this.$set(item, 'checked' , false)
        count.num++
        if(item.children) {
          item.children = this.loopSetKey(item.children, count)
        }
      }
      return list
    },
    closeEvent () {
      this.$emit('closeChooseTree')
    },
    saveBtn () {
      //关联控件控制默认值
      if (this.isAddRule) {
        // if (this.activeObj.extInfo.selectType == '单选') {
        //   if (!this.cascadeValue) {
        //     this.$emit('saveChooseTree')
        //     return
        //   }
        //   let val = JSON.parse(this.cascadeValue)
        //   let node = this.$refs.cascaderTree.getNode(val)
        //   let arr = []
        //   arr = this.serchParent(node, arr)
        //   this.$emit('saveChooseTree', arr, [val])
        // } else {
          this.checkList = [];
          this.checkDatas = [];
          this.ergodicCascade(this.treeOptions)
          this.$emit('saveChooseTree', this.checkDatas, this.checkList)
        // }
        return;
      }
      if (this.isSetDefault) {
        if (this.activeObj.extInfo.selectType == '单选') {
          if (!this.cascadeValue) {
            this.$emit('saveChooseTree')
            return
          }
          let val = JSON.parse(this.cascadeValue)
          let node = this.$refs.cascaderTree.getNode(val)
          let arr = []
          arr = this.serchParent(node, arr)
          this.fieldItem['setDefaultFieldId'][this.activeObj.id]['fieldValue'] = arr;
          this.fieldItem['setDefaultFieldId'][this.activeObj.id]['extInfoFieldValue'] = [val]
        }
        else {
          this.checkList = [];
          this.checkDatas = [];
          this.ergodicCascade(this.activeObj.extInfo.cascadeDown.options)
          this.fieldItem['setDefaultFieldId'][this.activeObj.id]['extInfoFieldValue'] = this.checkDatas;
          this.fieldItem['setDefaultFieldId'][this.activeObj.id]['fieldValue'] = this.checkList;
        }
        this.$emit('saveChooseTree')
        return;
      }
      if (this.activeObj.extInfo.selectType == '单选') {
        if (!this.cascadeValue) {
          this.$emit('saveChooseTree')
          return
        }
        let val = JSON.parse(this.cascadeValue)
        let node = this.$refs.cascaderTree.getNode(val)
        let arr = []
        arr = this.serchParent(node, arr)
        this.activeObj.defaultValue = arr;
        this.activeObj.extInfo.fieldValue = [val]
        console.debug('fieldValue', this.activeObj.extInfo.fieldValue)
      } else {
        this.checkList = [];
        this.checkDatas = [];
        this.ergodicCascade(this.activeObj.extInfo.cascadeDown.options)
        this.activeObj.extInfo.fieldValue = this.checkDatas;
        this.activeObj.defaultValue = this.checkList;
      }
      this.$emit('saveChooseTree')
    },
    serchParent (node, list) {
      if (node) {
        if (Object.prototype.toString.call(node.data) == '[object Object]') {
          list.unshift(node.data.value)
        }
        if (node.parent) {
          list = this.serchParent(node.parent, list)
        }
      }
      return list
    },
    checkCascadeOption (node, data) {
      if (this.activeObj.extInfo.selectType === '单选' && !this.isMultiple) {
        this.ergodicCascade(this.activeObj.extInfo.cascadeDown.options);
        this.$set(data, 'checked', !data.checked);
      }
      else {
        this.$set(data, 'checked', !data.checked);
      }
      this.$emit('checkCascadeOption', data)
    },
    //遍历级联选项
    ergodicCascade (list) {
      list.forEach(item => {
        if (item.checked) {
          let data = this.$refs.cascaderTree.getNode(item)
          if(data && data.data.key) {
            delete data.data.key
          }
          let checkList = []
          let arr = this.serchParent(data, checkList)
          this.checkList.push(arr)
          this.checkDatas.push(item)
        }
        if (item.children) {
          this.ergodicCascade(item.children)
        }
      })
    },
    //遍历级联选中选项
    ergodicCascadeCheckedValue (list, checkList, parent) {
      console.log(checkList,'checkList');
      // debugger
      list.forEach(item => {
        this.$set(item, 'checked', false)
        if (Array.isArray(checkList)) {
          checkList.forEach(check => {
            if(!parent) {
              if((check.indexOf(item.value) !== -1 &&
              check[check.length - 1] == item.value) || check == item.value) {
                this.$set(item, 'checked', true)
              }
            } else {
              if ((check.indexOf(item.value) !== -1 &&
                check[check.length - 1] == item.value && 
                Math.abs(check.indexOf(parent.value) - check.indexOf(item.value)) == 1
              )|| check == item.value) {
                this.$set(item, 'checked', true)
              }
            }
          })
        }
        else {
          if (checkList === item.value) {
            this.$set(item, 'checked', true)
            setTimeout(() => {
              this.$set(this, 'cascadeValue', JSON.stringify(item))
            }, 0)
          }
        }
        if (item.children) {
          this.ergodicCascadeCheckedValue(item.children, checkList, item)
        }
      })
    },
    initCascadeOption () {
      this.$nextTick(() => {
        this.$refs.cascaderTree.setCheckedNodes(this.activeObj.extInfo.fieldValue || [])
      })
      /*await this.ergodicCascade(this.activeObj.extInfo.cascadeDown.options)
      this.ergodicCascadeCheckedValue(this.activeObj.extInfo.cascadeDown.options,this.activeObj.defaultValue)*/
    },
    setCascadeOption () {
      let val = [];
      if (this.fieldItem['setDefaultFieldId'][this.activeObj.id] && this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue) {
        val = this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue
        this.$nextTick(() => {
          this.$refs.cascaderTree.setCheckedNodes(val || [])
        })
      }
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleCheckChange (data, checked,) {
      if (!checked) return
      if (this.activeObj && this.activeObj.extInfo.selectType === '单选') {
        if (this.$refs.cascaderTree.getCheckedNodes().length > 1) {
          this.$nextTick(() => {
            this.$refs.cascaderTree.setChecked(data, false)
            this.$message({
              message: "当前字段为单选",
              type: 'warning',
              duration: 2000
            })
            return
          })
        }
      }
      else {
        this.$nextTick(() => {
          this.$set(data, 'checked', checked);
          this.$refs.cascaderTree.setChecked(data, checked)
        })
      }
    },
    handleTreeClick(data,node,row) {
      if(this.activeObj.extInfo.selectType === '多选') {
        data.checked = !data.checked
      } else {
        this.cascadeValue = JSON.stringify(data)
      }
    },
    changeChecked(data, isAddRule) {
      if(!isAddRule) {
        return 
      } else {
        data.children && data.children.forEach(child =>{
          child.checked = data.checked
        })
      }
    }
  },
  watch:{
    chooseTreeDialog:{
      handler(value){
        if (value){
          if (!this.isAddRule) {
            if (this.isSetDefault){
              this.cascadeValue = this.fieldItem['setDefaultFieldId'][this.activeObj.id] && this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue?
                  JSON.stringify(this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue[0]):''
            }
            else {
              this.cascadeValue = this.activeObj.extInfo.fieldValue?JSON.stringify(this.activeObj.extInfo.fieldValue[0]):'';
            }
            if (this.activeObj.extInfo.selectType === '多选'){
              let list = []
              if (this.isSetDefault){
                list = this.fieldItem['setDefaultFieldId'][this.activeObj.id] && this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue?
                    this.fieldItem['setDefaultFieldId'][this.activeObj.id].extInfoFieldValue.map(item=>{return item.value}):[]
              }
              else {
                list = this.activeObj.extInfo.fieldValue? this.activeObj.extInfo.fieldValue.map(item=>{return item.value}):[]
              }
              this.ergodicCascadeCheckedValue(this.activeObj.extInfo.cascadeDown.options,list)
            }
          }

        }
      },
      immediate:true,
      deep:true
    }
  },

}
</script>

<style lang="less" scoped>
.choose-tree{
    ::v-deep .el-tree{
        .el-tree-node__content{
            margin:5px 0;
        }
    }
  .content-tree{
    .el-tree-title{
      width: 100%;
      /deep/.el-radio__label{
        display: none;
      }
      /deep/.el-radio{
        min-width: 0;
      }
    }
    .el-tree-title-contain{
      display: flex;
      align-items: center;
    }
    .el-tree-title-word-name{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .word-name{
        flex: 1;
      }
      .check-icon{
        i{
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  /deep/.el-checkbox{
    min-width: 0;
  }
}

</style>