<template>
    <div v-loading="loading" id="duty-list">
        <div class="duty-list-handle">
            <div class="duty-list-handle-group">
                <el-select class="dlhg-select" size="small" @change="changeActiveGroup" v-model="activeGroup"
                    :placeholder="$t('common.selectPlaceholder')">
                    <el-option class="dlhg-select-item" v-for="item in dutyGroup" :key="item.id" :label="item.name"
                        :value="item.id">
                        <span>{{ item.name }}</span>
                    </el-option>
                    <el-option class="dlhg-select-item dlhg-select-item-handle"
                        style="border-top: solid 1px #E0E6F7;padding-top: 5px;background-color: white;">
                        <span style="color: #366AFF;display: flex;align-items: center;"> <i
                                class="iconfont guoran-tongyichicun-16-14-shezhiliebiao"></i> <span
                                style="margin-left: 8px;">{{$t('workDuty.manageSchedulingTeams')}}</span> </span>
                    </el-option>
                </el-select>
            </div>
            <div class="duty-list-handle-month">
                <span @click="switchMonth(-1)" class="dlhm-switch"> <i class="el-icon-arrow-left"></i> </span>
                <div class="month-show">
                    {{ activeYear }} {{$t('workDuty.year')}} {{ activeMonth }} {{$t('workDuty.month')}}
                </div>
                <span @click="switchMonth(1)" class="dlhm-switch"> <i class="el-icon-arrow-right"></i> </span>
            </div>
        </div>
        <div class="duty-list-header">
            <span :class="['duty-list-cell', itemIndex != 0 ? 'border-l' : '']" v-for="(item, itemIndex) in weekList"
                :key="item">{{ item }}</span>
            <span v-show="hasScoll" class="header-empty"></span>
        </div>
        <div class="duty-list-content">
            <div v-for="(calendarRow, calendarRowIndex) in calendar" :key="calendarRowIndex"
                class="duty-list-content-cell">
                <div :class="[
                'dlcc-cell',
                calendarCellIndex != 0 ? 'dlcc-cell-border-left' : '']"
                    v-for="(calendarCell, calendarCellIndex) in calendarRow"
                    :key="('key' + calendarCell.month + calendarCell.day)">
                    <div class="blu-bg">
                        <span v-if="calendarCell.bBackcolor == 'left'" class="blu-bg-l"></span>
                        <span v-if="calendarCell.bBackcolor == 'all'" class="blu-bg-all"></span>
                        <span v-if="calendarCell.bBackcolor == 'right'" class="blu-bg-r"></span>
                        <span v-if="calendarCell.bBackcolor == 'none'" class="blu-bg-l-r"></span>
                    </div>
                    <div
                        :class="activeToday == String(calendarCell.year) + String(calendarCell.month) + String(calendarCell.day) ? 'active-day-border' : ''">
                    </div>
                    <div class="dlcc-inner">
                        <span
                            :class="['dlcc-inner-day', activeToday == String(calendarCell.year) + String(calendarCell.month) + String(calendarCell.day) ? 'active-b-color' : '', calendarCell.month != activeMonth ? 'not-active-month' : '']">{{
                                    calendarCell.day
                            }}</span>
                        <span
                            :class="['dlcc-inner-chineseday', activeToday == String(calendarCell.year) + String(calendarCell.month) + String(calendarCell.day) ? 'active-b-color' : '', calendarCell.month != activeMonth ? 'not-active-month' : '']">{{
                                    calendarCell.chineseDay
                            }}</span>
                        <span v-if="(calendarCell.schedulingPeopleNum != 0 && calendarCell.schedulingPeopleNum != null)"
                            class="dlcc-inner-record">{{
                                    calendarCell.schedulingPeopleNum
                            }}人</span>
                        <span v-else class="dlcc-inner-record-empty"></span>
                    </div>
                    <div @click="addDuty(calendarCell)"
                        v-if="(calendarCell.schedulingPeopleNum == 0 || calendarCell.schedulingPeopleNum == null)"
                        class="dc-empty-duty">
                        {{$t('workDuty.addScheduling')}}
                    </div>
                    <div @click="viewDuty(calendarCell)"
                        v-if="(calendarCell.schedulingPeopleNum != 0 && calendarCell.schedulingPeopleNum != null)"
                        class="dc-add-duty">
                        {{$t('workDuty.lookScheduling')}}
                    </div>
                </div>
            </div>
        </div>
        <el-drawer size="700px" :visible.sync="viewDrawer" :with-header="false">
            <div id="view-duty">
                <div class="view-duty-header">
                    <span class="vdh-title">{{$t('workDuty.lookScheduling')}}</span>
                    <span @click="(viewDrawer = false)" class="vdh-close arsenal_icon arsenalcuo1"></span>
                </div>
                <div class="view-duty-content">
                    <div class="vdc-des">
                        <span class="vdc-des-icon"><i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i></span>
                        <span class="vdc-des-text">{{ activeDayInfo.year }}-{{ activeDayInfo.month }}-{{
                                activeDayInfo.day
                        }}</span>
                    </div>
                    <div class="vdc-title">
                        <span class="vdc-title-name">{{$t('workDuty.vdcName')}}</span>
                        <span class="vdc-title-time">{{$t('workDuty.workTime')}}</span>
                    </div>
                    <div class="vdc-list">
                        <div v-for="(item, index) in viewList" :key="index" class="vdc-list-cell">
                            <div class="vlc-name">
                                <span class="vlc-name-num">{{ (index + 1) }}</span>
                                <span class="vlc-name-text">{{ item.userRealName }}</span>
                            </div>
                            <div v-if="item.inWorkTime" class="vlc-time">
                                <span class="vlc-time-icon">
                                    <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
                                </span>
                                <span v-for="(cell, cellIndex) in item.workTime" :key="cellIndex" class="vlc-time-text">
                                    {{ cell }}
                                </span>
                            </div>
                            <div style="color: #FF587B;" v-if="!item.inWorkTime" class="vlc-time">
                                {{$t('workDuty.notDuringWorking')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-duty-footer">
                    <div @click="editDuty" class="manage-duty">
                        <i class="iconfont guoran-tongyichicun-shezhiquanxian"></i>
                        <span class="md-text">{{$t('workDuty.manageScheduling')}}</span>
                    </div>
                    <div @click="(viewDrawer = false)" class="view-duty-close">
                      {{$t('common.close')}}
                    </div>
                </div>
            </div>
        </el-drawer>
        <popup @closeEvent="addDutyDia = false" v-if="addDutyDia">
            <div slot="popup-name" class="popup-name">{{$t('workDuty.addDutyTitle')}}</div>
            <div slot="popup-tip">{{$t('workDuty.addDutyTip')}}</div>
            <div slot="popup-con" class="add-duty-contanier">
                <div class="add-duty-time">
                    <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
                    {{ activeDayInfo.year }}-{{ activeDayInfo.month }}-{{ activeDayInfo.day }}
                </div>
                <div class="select-cous ask-select-item">
                    <span class="ask-select-item-title">{{$t('workDuty.selectDuty')}}</span>
                    <div class="ask-select-item-content">
                        <el-select filterable :props="{ label: 'realName', value: 'id' }" v-model="selectedDutyValue"
                            multiple :placeholder="$t('common.selectPlaceholder')">
                            <el-option v-for="item in dutyOptions" :key="item.id" :label="item.realName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <div class="selected-cous ask-select-item">
                    <span class="ask-select-item-title">已选择值班员工</span>
                    <div class="ask-select-item-content">
                        <div class="ask-select-item-content-selected">
                            <div class="asics-cell">
                                <span class="asics-cell-name">你好</span><span class="asics-cell-delete"><i
                                        class="arsenal_icon arsenalcuo1"></i></span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div slot="dialog-footer">
                <el-button @click="(addDutyDia = false)" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button v-if="isCanScheduling" type="primary" @click="submitAddduty" size="small">{{$t('common.confirm')}}</el-button>
                <el-button style="opacity: 0.5;" v-if="!isCanScheduling" type="primary" @click="showTip" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from "@/components/popup";
export default {
    name: "workTimeTable",
    components: { Popup },
    data() {
        return {
            loading: false,
            dutyGroup: [],
            activeGroup: "",
            preActiveGroup: "",
            activeYear: new Date().getFullYear(),
            activeMonth: new Date().getMonth() + 1,
            weekList: [this.$t('workDuty.Sunday'),
              this.$t('workDuty.Monday'),
              this.$t('workDuty.Tuesday'),
              this.$t('workDuty.Wednesday'),
              this.$t('workDuty.Thursday'),
              this.$t('workDuty.Friday'),
              this.$t('workDuty.Saturday')],
            calendar: [], // 长度为6的二维数组 每一项长度为7 
            hasScoll: false,
            activeToday: String(new Date().getFullYear()) + String(new Date().getMonth() + 1) + String(new Date().getDate()),
            viewDrawer: false,
            addDutyDia: false,
            selectedDutyValue: [],
            dutyOptions: [],
            activeDayInfo: "",
            viewList: [],
            viewListIds: [],
            isCanScheduling: true,
        };
    },
    methods: {
        showTip() {
            this.$message.warning(this.$t('workDuty.noPermissionTip'));
        },
        canScheduling() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.queryUserCanScheduling}?schedulingGroupId=${this.activeGroup}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        console.log(res.data.data);
                        this.isCanScheduling = res.data.data;
                    }
                });
        },
        editDuty() {
            this.dutyHandleType = "edit";
            this.selectedDutyValue = this.viewListIds;
            this.addDutyDia = true;
        },
        submitAddduty() {
            if (this.selectedDutyValue.length == 0) {
                this.$message.warning('请选择值班员工');
                return false;
            }
            if (this.dutyHandleType == "add") {
                this.$http
                    .post(
                        `${this.requestUrl.schedulingManagement.configureScheduling}`, {
                        schedulingDayId: this.activeDayInfo.id,
                        schedulingGroupId: this.activeGroup,
                        schedulingPeopleIdList: this.selectedDutyValue,
                    }
                    )
                    .then((res) => {
                        if (res.data.code === "0") {
                            this.getCalendar(this.activeYear, this.activeMonth);
                            this.addDutyDia = false;
                            this.selectedDutyValue = [];
                        }
                    });
            } else {
                let param = this.activeDayInfo;
                param.schedulingDayId = this.activeDayInfo.id;
                param.schedulingGroupId = this.activeGroup;
                param.schedulingPeopleIdList = this.selectedDutyValue;
                this.$http
                    .put(
                        `${this.requestUrl.schedulingManagement.updateConfigureScheduling}`, param
                    )
                    .then((res) => {
                        if (res.data.code === "0") {
                            this.getCalendar(this.activeYear, this.activeMonth);
                            this.addDutyDia = false;
                            this.selectedDutyValue = [];
                            this.viewDuty(this.activeDayInfo);
                        }
                    });
            }

        },
        // 获取可值班员工
        querySchedulingUser() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.querySchedulingUser}?schedulingGroupId=${this.activeGroup}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        console.log(res.data);
                        this.dutyOptions = res.data.data;
                    }
                });
        },
        changeActiveGroup(value) {
            console.log(value)
            if (value == undefined) {
                this.activeGroup = this.preActiveGroup;
                this.$emit("showManageGroup");
            } else {
                this.preActiveGroup = value;
            }
            this.getCalendar(this.activeYear, this.activeMonth);
            this.querySchedulingUser();
        },
        addDuty(item) {
            this.canScheduling();
            this.dutyHandleType = "add";
            this.selectedDutyValue = [];
            this.activeDayInfo = item;
            this.addDutyDia = true;
        },
        viewDuty(item) {
            this.canScheduling();
            this.dutyHandleType = "edit";
            this.activeDayInfo = item;
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.queryScheduling}?schedulingGroupId=${this.activeGroup}&dayId=${this.activeDayInfo.id}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        console.log(res.data.data);
                        this.viewList = [...res.data.data];
                        this.viewListIds = [...res.data.data].map(item => {
                            return item.userId
                        })
                        console.log(this.viewListIds)
                        this.viewDrawer = true;
                    }
                });

        },
        switchMonth(type) {
            if (type === -1) {
                if (this.activeMonth === 1) {
                    this.activeMonth = 12;
                    this.activeYear = this.activeYear - 1;
                } else {
                    this.activeMonth = this.activeMonth - 1;
                }
            } else {
                if (this.activeMonth === 12) {
                    this.activeMonth = 1;
                    this.activeYear = this.activeYear + 1;
                } else {
                    this.activeMonth = this.activeMonth + 1;
                }
            }
            this.querySchedulingUser();
            this.getCalendar(this.activeYear, this.activeMonth);
        },
        getAllGroup() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.queryAllSchedulingGroups}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.dutyGroup = res.data.data;
                        this.activeGroup = this.dutyGroup[0].id;
                        this.preActiveGroup = this.activeGroup;
                        this.getCalendar(this.activeYear, this.activeMonth);
                        this.querySchedulingUser();
                    }
                });
        },
        getCalendar(year, month) {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.getCalendar}?month=${month}&year=${year}&schedulingGroupId=${this.activeGroup}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.calendar = this.initCalendar(res.data.data);
                    }
                });
        },
        initCalendar(list) {
            let k = 0;
            let arr = [];
            list.forEach((item, index) => {
                if (item.schedulingPeopleNum == null || item.schedulingPeopleNum == 'null') {
                    this.$set(item, "schedulingPeopleNum", 0);
                }
            })
            list.forEach((item, index) => {
                if (item.schedulingPeopleNum == 0) {
                    // 没有蓝色背景
                } else {
                    if (index == 10) {
                        console.log(list[index - 1].schedulingPeopleNum, list[index + 1].schedulingPeopleNum, list[index + 1], k)
                    }
                    console.log(3666, index - 1, list[index - 1]);
                    if (index != 0) {
                        if (list[index - 1].schedulingPeopleNum == 0 && list[index + 1].schedulingPeopleNum != 0 && k != 6) {
                            item.bBackcolor = "left";
                        } else {
                            if ((list[index - 1].schedulingPeopleNum == 0 && list[index + 1].schedulingPeopleNum == 0) || (k == 0 && list[index + 1].schedulingPeopleNum == 0) || (k == 6 && list[index - 1].schedulingPeopleNum == 0)) {
                                item.bBackcolor = "none";
                            } else {
                                if (k != 6 && k != 0 && list[index - 1].schedulingPeopleNum != 0 && list[index + 1].schedulingPeopleNum != 0) {
                                    item.bBackcolor = "all";
                                } else {
                                    item.bBackcolor = "right";
                                }
                            }
                        }
                    }
                }
                if (k == 0) {
                    arr.push([item]);
                    k++;
                } else {
                    if (k == 6) {
                        k = 0;
                    } else {
                        k++;
                    }
                    arr[arr.length - 1].push(item);
                }
            });
            return arr;
        }
    },
    mounted() {
        this.getAllGroup();

        this.$nextTick(() => {
            let div = document.querySelector(".duty-list-content");
            if ((div.scrollHeight > div.clientHeight) || (div.offsetHeight > div.clientHeight)) {
                console.log('该div有滚动条！');
                this.hasScoll = true;
            }
        })
    },
};
</script>

<style lang="less">
#duty-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    // input:focus {
    //     border: none !important;
    //     box-shadow: none !important;
    // }

    .add-duty-contanier {
        .add-duty-time {
            height: 38px;
            background: #FBFCFD;
            border-radius: 5px;
            display: flex;
            align-items: center;

            i {
                margin: 0 6px 0 8px;
                color: #D2D8E3;
            }
        }

        .ask-select-item {
            display: flex;
            flex-direction: column;

            .ask-select-item-title {
                margin-top: 8px;
                height: 38px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #616161;
                line-height: 38px;
            }

            .ask-select-item-content {
                .el-select {
                    width: 100%;

                    // /deep/.el-select__tags {
                    //     display: none;
                    // }
                    input:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }

                .ask-select-item-content-selected {
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    padding: 6px;

                    .asics-cell {
                        height: 23px;
                        line-height: 23px;
                        background: #ECF1FF;
                        color: #366AFF;
                        margin: 6px;
                        padding: 0 6px 0 8px;
                        display: flex;
                        align-items: center;

                        .asics-cell-delete {
                            scale: 0.8;
                            margin-left: 6px;
                            font-size: 12px;
                            width: 12px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .duty-list-handle {
        flex: none;
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #E0E6F7;

        .duty-list-handle-group {
            margin-left: 16px;

            .dlhg-select {
              flex: none;
                width: 210px;
            }
        }

        .duty-list-handle-month {
            margin-right: 16px;
            display: flex;
            align-items: center;
            height: 18px;
            font-size: 18px;
            // font-weight: 700;
            color: #333333;
            line-height: 14px;

            .dlhm-switch {
                cursor: pointer;
                width: 50px;
                text-align: center;
                font-size: 14px;
            }
        }

    }

    .duty-list-header {
        flex: none;
        background-color: gold;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        // font-weight: 700;

        .header-empty {
            width: 6px;
            background-color: #F9FBFF;
        }

        .duty-list-cell {
            color: #333333;
            line-height: 50px;
            flex: 1;
            background: #F9FBFF;
            text-align: center;
        }

        .border-l {
            border-left: solid 1px #EFF2F9;
        }

    }

    .duty-list-content {
        flex: auto;
        overflow-y: auto;

        .duty-list-content-cell {
            height: 500px;
            background-color: white;
            height: 118px;
            border-bottom: solid 1px #EFF2F9;
            display: flex;
            justify-content: flex-start;


            .dlcc-cell:hover {
                .dc-empty-duty {
                    display: block;
                }

                .dc-add-duty {
                    display: block;
                }
            }

            .dlcc-cell {
                flex: 1;
                position: relative;

                .dc-empty-duty {
                    display: none;
                    position: absolute;
                    width: calc(100% - 24px);
                    height: 90px;
                    background-color: #FF587B;
                    opacity: 0.85;
                    color: white;
                    font-weight: 700;
                    border-radius: 5px;
                    left: 12px;
                    top: 14px;
                    text-align: center;
                    line-height: 90px;
                    cursor: pointer;
                    font-size: 12px;
                }

                .dc-add-duty {
                    display: none;
                    position: absolute;
                    width: calc(100% - 24px);
                    height: 90px;
                    background-color: #366AFF;
                    opacity: 0.85;
                    color: white;
                    font-weight: 700;
                    border-radius: 5px;
                    left: 12px;
                    top: 14px;
                    text-align: center;
                    line-height: 90px;
                    cursor: pointer;
                    font-size: 12px;
                }

                .dlcc-inner {
                    position: absolute;
                    height: 112px;
                    width: 94%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    .active-b-color {
                        color: #366AFF;
                    }

                    .not-active-month {
                        color: #A9B3C6 !important;
                    }

                    .dlcc-inner-day {
                        margin-top: 32px;
                        text-align: center;
                        font-size: 24px;
                        font-weight: 500;
                        color: #333333;
                    }

                    .dlcc-inner-chineseday {
                        margin-top: 0px;
                        text-align: center;
                        font-size: 12px;
                        // font-weight: 700;
                        color: #616161;
                    }

                    .dlcc-inner-record {
                        margin-top: 4px;
                        text-align: center;
                        font-size: 12px;
                        color: #366AFF;
                        font-weight: 700;
                    }

                    .dlcc-inner-record-empty {
                        margin-top: 4px;
                        text-align: center;
                        font-size: 12px;
                        color: #FF587B;
                        font-weight: 700;
                    }
                }

                .active-day-border {
                    position: absolute;
                    height: 112px;
                    width: calc(100% - 6px);
                    border: solid 3px #366AFF;
                    border-radius: 5px;
                }

                .blu-bg {
                    position: absolute;
                    top: 40px;
                    height: 37px;
                    width: 100%;
                    display: flex;

                    .blu-bg-all {
                        flex: auto;
                        height: 100%;
                        background-color: #EDF2FF;
                    }

                    .blu-bg-l {
                        flex: auto;
                        height: 100%;
                        background-color: #EDF2FF;
                        margin-left: 8px;
                        border-radius: 5px 0 0 5px;
                    }

                    .blu-bg-r {
                        flex: auto;
                        height: 100%;
                        background-color: #EDF2FF;
                        margin-right: 8px;
                        border-radius: 0 5px 5px 0;
                    }

                    .blu-bg-l-r {
                        flex: auto;
                        height: 100%;
                        background-color: #EDF2FF;
                        margin: 0 8px;
                        border-radius: 5px;
                    }
                }
            }

            .dlcc-cell-border-left {
                border-left: solid 1px #EFF2F9;
            }
        }
    }
}

#view-duty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    .view-duty-header {
        width: 100%;
        flex: none;
        height: 45px;
        background: #F6F8FD;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vdh-title {
            margin-left: 20px;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
        }

        .vdh-close {
            margin-right: 20px;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .view-duty-content {
        flex: auto;
        width: 100%;

        .vdc-des {
            flex: none;
            margin-left: 20px;
            text-align: left;
            display: flex;
            height: 60px;
            align-items: center;

            .vdc-des-icon {
                color: #D2D8E3;
                margin-right: 5px;
            }
        }

        .vdc-title {
            margin: 0 20px;
            height: 38px;
            line-height: 38px;
            background: #F6F8FD;
            display: flex;
            align-items: center;

            .vdc-title-name {
                flex: 1;
                padding-left: 50px;
            }

            .vdc-title-time {
                flex: 1;
                text-align: left;
            }
        }

        .vdc-list {
            flex: none;
            height: calc(100vh - 240px);
            overflow-y: auto;

            .vdc-list-cell {
                display: flex;
                height: 48px;
                align-items: center;
                border-bottom: solid 1px #E0E6F7;
                margin-left: 20px;
                margin-right: 20px;

                .vlc-name {
                    flex: 1;
                    display: flex;
                    

                    .vlc-name-num {
                        width: 50px;
                        flex: none;
                        text-align: center;
                    }

                    .vlc-name-text {}
                }

                .vlc-time {
                    flex: 1;
                    text-align: left;
                    margin-left: 50px;

                    .vlc-time-icon {
                        color: #D2D8E3;
                    }
                }
            }
        }
    }

    .view-duty-footer {
        width: 100%;
        flex: none;
        height: 36px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        .manage-duty {
            margin-left: 20px;
            width: 90px;
            height: 30px;
            line-height: 30px;
            background: #FFFFFF;
            border: 2px solid #A1B9FF;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 0 3px;
            color: #366AFF;
            font-weight: 600;
            cursor: pointer;
        }

        .view-duty-close {
            margin-right: 20px;
            width: 99px;
            height: 33px;
            line-height: 33px;
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            border-radius: 19px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>