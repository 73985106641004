<template>
  <div class="channelAccess">
    <div class="list">
      <div class="cardItem"
           v-for="(item,index) in channelList"
           :key="index"
           @click="gotoConfig(item)"
           :style="{height:$i18n.locale === 'en' ? '270px' : ''}"
      >
        <img :src="item.image" v-if="item.image">
        <i v-else :class="item.icon"></i>
        <div class="cardName">{{$t('channel.' + item.type + '.name')}}</div>
        <div class="cardTip">{{$t('channel.' + item.type + '.tips')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "channelAccess",
  data(){
    return{
      channelList:[
        {
          name:"网站接入",
          tips:"可以在网站接入对话机器人，通过在线形式进行咨询。",
          image:require(`../../assets/images/webchat.png`),
          type:"website"
        },
        {
          name:"企业微信",
          tips:"可以将机器人接入到企业微信自建应用，便于企业员工进行内部咨询服务。",
          image:require(`../../assets/images/qiyechat.png`),
          type:"wechat"
        },
        {
          name:"微信小程序",
          tips:"自动生成专属机器人的小程序码，可引导用户扫码进入专属小程序。",
          image:require(`../../assets/images/xiaochengxu.jpeg`),
          type:"miniProgram"

        },
        {
          name:"微信公众号",
          tips:"可将机器人接入到微信公众号内，便于完善对外咨询业务。",
          image:require(`../../assets/images/Wechat_128px_1230528.png`),
          type:"wechatAccount"

        },
        {
          name:"钉钉",
          tips:"将机器人接入到钉钉自建H5应用中，打造钉钉数字员工。",
          image:require(`../../assets/images/dingding.jpeg`),
          type:"dingding"
        },
        {
          name:"钉钉群",
          tips:"将机器人接入到钉钉群聊中，打造钉钉智慧群。",
          image:require(`../../assets/images/dingding.jpeg`),
          type:"dingdingGroup"
        },
        {
          name:"钉钉单聊机器人",
          tips:"将机器人接入到钉钉消息列表中，打造专属钉钉数字员工。",
          image:require(`../../assets/images/dingding.jpeg`),
          type:"dingSingleChatBot"
        },
        {
          name:"飞书",
          tips:"将机器人接入到飞书，打造专属飞书数字员工。",
          image:require(`../../assets/images/feishu.webp`),
          type:"feishu"
        },
        {
          name:"云之家",
          tips:"将机器人接入到云之家中，打造专属AI数字员工。",
          icon:"iconfont guoran-yun",
          type:"yunzhijia"
        },
        // {
        //   name:"weChat即时通讯IM",
        //   tips:"可设置即时通讯IM智能助手使用哪些机器人。",
        //   icon:"iconfont guoran-guanlixitong1",
        //   type:"IM"
        // },
        // {
        //   name:"AskBot客户端",
        //   tips:"更好的用户体验与更强大的数字员工。",
        //   image:require(`../../assets/images/fav.png`),
        //   type:"askBotClient"
        // }
      ]
    }
  },
  methods:{
    gotoConfig(item){
      this.$router.push({
        path:"/accessConfiguration",
        query:{
          type:item.type
        }
      })
    }
  }
};
</script>

<style scoped lang="less">
.channelAccess{
  height: 100%;
  overflow-y: scroll;
  .list{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .cardItem{
      //flex: none;
      width: 258px;
      height: 258px;
      margin-bottom: 16px;
      margin-right: 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      //justify-content: space-evenly;
      cursor: pointer;
      border: 2px solid #FFFFFF;
      img{
        flex: none;
        height: 60px;
        //width: 60px;
        margin-top: 20px;
      }
      .guoran-yun{
        font-size: 60px;
        color: rgb(60, 186, 255);
      }
      .guoran-guanlixitong1{
        font-size: 58px;
        color: rgb(9, 221, 213);
      }
      .cardName{
        font-size: 18px;
        color: #000000;
        font-weight: bold;
        margin: 16px 0;
        text-align: center;
      }
      .cardTip{
        color: #A9B3C6;
        font-size: 14px;
      }
    }
    .cardItem:hover{
      border: 2px solid #B8CCFF;
    }
  }
}
</style>