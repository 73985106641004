<!-- 老版本-->
<template>
    <div id="upDownManage" v-cloak >
        <leftMenu></leftMenu>
        <div class="content-container-upDownManage">
            <div class="header">
                <div class="leftInfo">
                    <span class="headerName">上下游管理</span>
                </div>
            </div>
            <div class="upDownManage-content">
                <div style="width: 150px; height: 100vh; background: white">
                    <leftSubMenu></leftSubMenu>
                </div>
 				<div class="content-box-upDownManage">
					<div class="left-tree">
						<div class="left-tree-box">
							<div class="default-tip" v-if="!treeData || treeData.length === 0">
								<img src="../../../assets/images/no-data.png">
								<el-button type="primary" @click="addDept" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">添加上下游</el-button>
							</div>
							<el-tree
								id="selectOrganizationTree"
								v-else
								:data="treeData"
								highlight-current
								node-key="id"
								:current-node-key="currentId"
								:default-expanded-keys="defaultCheckedKeys"
								:default-checked-keys="defaultCheckedKeys"
								@node-click="handleNodeClick"
								ref="elTree"
								>
								<span class="el-tree-title" slot-scope="{ node, data }">
									<span>
										<span class="el-tree-title-word" >
										<i
											style="color: #a1b9ff;margin-right:5px"
											class="
											iconfont
											guoran-wenjianjia-zhankaizhuangtai--folderOpen
											"
										></i>
										</span>
										<span  class="el-tree-title-word-name" >{{ data.name }}</span>
									</span>
									<span >
										<span class="el-tree-title-num" v-if="data.peopleLimitType === 0 ">{{data.peopleNum}}/{{data.peopleLimit}}</span>
										<el-dropdown @command="handleCommand($event,node,data)" trigger="click">
											<span class="el-dropdown-link">
												<i class="arsenal_icon arsenalgengduo"></i>
											</span>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item 
													:command="'addSameDept'" 
													:disabled="source === 5 && node.level === 1"
													v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">
													添加同级部门</el-dropdown-item>
												<el-dropdown-item :command="'setLeader'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">设置负责人</el-dropdown-item>
												<el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">添加子部门</el-dropdown-item>
												<el-dropdown-item :command="'up'" :disabled="data.orderNum < 2">上移</el-dropdown-item>
												<el-dropdown-item :command="'down'" :disabled="data.orderNum === node.parent.childNodes.length">下移</el-dropdown-item>
												<el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">编辑</el-dropdown-item>
												<el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_DELETE') !== -1">删除</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</span>
								</span>
							</el-tree>
						</div>
					</div>
					<div class="right-table">
						<div class="default-tip" v-if="!treeData || treeData.length === 0">
							<img src="../../../assets/images/no-data.png">
							<span class="default-tip-text">先在左侧添加上下游</span>
						</div>
						<div v-else>
							<div class="work-order-header">
								<div class="work-order-header-l">
									<el-tooltip class="item" effect="dark" content="编辑" placement="top-start" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_UPDATE') !== -1">
										<span @click="batchEditUser"><i class="iconfont guoran-piliangbianjiiocn"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="删除" placement="top-start" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_DELETE') !== -1">
										<span @click="deleteUser"><i class="iconfont guoran-a-18-13"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="导出" placement="top-start" >
										<span @click="exportFile"><i class="iconfont guoran-a-18-19"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="导入" placement="top-start" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_IMPORT') !== -1">
										<span @click="importFile"><i class="iconfont guoran-a-18-20"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="筛选" placement="top-start">
										<span @click="searchClick"><i class="iconfont guoran-gaojishaixuan"></i></span>
									</el-tooltip>
									<el-input v-model="keyword" placeholder="搜索姓名" prefix-icon="el-icon-search" @keyup.enter.native="searchFn" size="small" style="width: 70%"></el-input>
								</div>
									<seniorSearch
										ref="seniorSearch"
										:checkedTreeItem="checkedTreeItem"
										@screenCondition="screenCondition"
									></seniorSearch>
								<div class="work-order-header-r">
									<el-button type="primary" v-model="kerword" @click="addMember" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_ADD') !== -1">添加成员</el-button>
								</div>
							</div>
							<div class="search-tag" v-if="dynamicTags.length > 0">
								<div class="showTag">
									<el-tag
											closable
											v-for="(tag,tagIndex) in dynamicTags"
											:key="tagIndex"
											:disable-transitions="false"
											@close="handleClose(tag,tagIndex)"
											style="margin-right: 8px;"
											size="small"
									>
										<span>{{tag.name}}:
											<template v-if=" Array.isArray(tag.value)">
												<span v-for="(item,index) in tag.value" :key="index">
													<span>{{item.name}}</span>
													<span v-if="index < tag.value.length -1">,</span>
												</span>
											</template>
											<span v-else>
												<span>{{tag.value}}</span>
											</span>
										</span>
									</el-tag>
									<el-tooltip class="item" effect="dark" content="清除所有" placement="top-start">
										<span class="delete-all" @click="deleteAllTag('deleteAllTag')">
											<i class="iconfont guoran-a-16_01"></i>
										</span>
									</el-tooltip>

								</div>
							</div>
							<div class="tabel-box">
								<el-table
									ref="multipleTable"
								    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
									:data="tableData"
									style="width: 100%"
									row-key="id"
									empty-text='暂无数据'
									@row-click="getDetails"
									@selection-change="handleSelectionChange">
									<el-table-column
									   :reserve-selection="true"
										type="selection"
										align="center"
										width="55">
									 </el-table-column>
									<el-table-column
										prop="name"
										align="center"
										label="姓名">
									</el-table-column>
									<el-table-column
										prop="telephone"
										align="center"
										label="手机号">
									</el-table-column>
									<el-table-column
										prop="organizationName"
										align="left"
										class-name="organizationName"
										label="组织架构">
										<template slot-scope="scope">
											<el-tooltip class="item" effect="dark" :content="scope.row.organizationName" placement="bottom-end">
												<span>{{scope.row.organizationName}}</span>
											</el-tooltip>
										</template>
									</el-table-column>
									<el-table-column
										prop="email"
										align="center"
										label="邮箱">
									</el-table-column>
									<el-table-column
										prop="birthdayStr"
										align="center"
										label="生日">
									</el-table-column>
									<el-table-column
										prop="staffInStr"
										align="center"
										label="入职日期">
									</el-table-column>
									<el-table-column
										prop="status"
										align="center"
										label="状态">
										<template slot-scope="scope">
											<!-- true 启用，false禁用 -->
											<span>{{scope.row.status ? '使用中' : '已禁用'}}</span>
										</template>
									</el-table-column>
								</el-table>
								<pagination 
									v-if="total > pageSize"
									:pageSize="pageSize" 
									:currentPage="currentPage" 
									:total="total"
                    				@handleSizeChange="handleSizeChange" 
									@currentChange="handleCurrentChange">
								</pagination>
							</div>
						</div>
					</div>   
				</div>
            </div>
   		</div>
		<addMember 
			:treeData="treeData"
			:rolePrivilegesList="rolePrivilegesList"
			:visible.sync="addMemberVisible" 
			:userDetail="userDetail" 
			:checkedUserList="checkedUserList"
			:deptList="deptList"
			@addMemberSuccess="addMemberSuccess">
		</addMember>
		<addDept 
			:source="source"
			:addDeptVisible.sync="addDeptVisible" 
			:type="addDeptType" 
			:checkedTreeItem="checkedTreeItem"
			:checkedTreeItemNode="checkedTreeItemNode"
			@addDeptSuccess="addDeptSuccess" 
			>
		</addDept>
		<importFile :importFileVisible.sync="importFileVisible" @uploadFileSuccess="uploadFileSuccess"></importFile>
		<setLeader 
			:setLeaderVisible.sync="setLeaderVisible" 
			:checkedTreeItem="checkedTreeItem"
			@setLeaderSuccess="setLeaderSuccess">
		</setLeader>
	</div>
</template>
<script>
import leftMenu from "./../../menu/leftMenu.vue";
import leftSubMenu from "./../../menu/leftSubMenuV2.vue";
import SideNav from "./../../menu/SideNav.vue";
import pagination from "../../../components/pagination";
import addMember from "./components/addMember";
import addDept from './components/addDept.vue';
import importFile from './components/importFile.vue';
import seniorSearch from './components/seniorSearch.vue';
import setLeader from './components/setLeader.vue'
import $ from 'jquery';
export default {
    name: "upDownManage",
    components: {
        leftMenu,
        leftSubMenu,
		pagination,
		addMember,
		addDept,
		importFile,
		seniorSearch,
		setLeader,
        SideNav,
    },
	data(){
		return{
			tableData:[], // 表格数据
			treeData: [], // tree结果数据
			pageSize:15,
			currentPage:1,
			total:null,
			addMemberVisible:false,
			addDeptVisible:false,
			importFileVisible:false,
			setLeaderVisible:false,
			addDeptType:'', // tree结构操作的类型
			checkedTreeItem:{}, // 选中的treeItem
			defaultCheckedKeys:[], // tree机构默认选中节点
			userDetail:{},
			checkedUserList:[],
			checkedTreeItemNode:{}, //tree结构node节点
			deptList:[], // s
			keyword:'',
			rolePrivilegesList:[],
			screenConditionObj:{}, // 高级检索的筛选条件
			currentId:null,
			source:null, // 5：负责人账号，
			dynamicTags:[]
		}
	},
	mounted(){
		this.rolePrivilegesList = [];
		let list = JSON.parse(localStorage.getItem('rolePrivileges'));
		list.forEach(item=>{
			if(item.checked){
				this.rolePrivilegesList.push(item.id)
			}
		})
		this.getTree(true);
		this.source = JSON.parse(localStorage.getItem('userInfo')).source;
		console.log(JSON.parse(localStorage.getItem('userInfo')).source,'sourcesource');
	},
	methods: {
		// 获取tree结构
		getTree(isReload){
			this.$http.get(this.requestUrl.upAndDown.getTreeData)
				.then((res) => {
					if(res.data.code == 0){
						this.treeData = res.data.data || [];
						if(this.treeData.length > 0){
							if(isReload){
								this.defaultCheckedKeys = [this.treeData[0].id];
								this.checkedTreeItem = JSON.parse(JSON.stringify(this.treeData[0]));
								this.$nextTick(()=>{
									setTimeout(()=>{
										let dom = document.getElementById('selectOrganizationTree').firstChild;
										dom.className += " is-current";
									},10)
								})
							} else {
								this.$nextTick(()=>{
									this.currentId = this.checkedTreeItem.id;
									this.$refs.elTree.setCurrentKey(this.checkedTreeItem.id);
								})
							}
							this.currentPage = 1;
							this.getUserTable();
							this.getSelectData(this.checkedTreeItem.id);
							this.$forceUpdate();
						}
					} else {
						this.$message.error(res.data.message)
					}		
				}); 
		},
		// 获取当前tree节点下的组织架构
		getSelectData(n){
			this.$http.get(this.requestUrl.upAndDown.selectOrganizationTreeById + "/" + n)
				.then((res) => {
					if(res.data.code == 0){
						this.deptList = res.data.data || [];
					} else {
						this.$message.error(res.data.message)
					}
				});
		},
		// 无组织架构时 添加部门
		addDept(){
			this.addDeptType = 'one';
			this.addDeptVisible = true;
		},
		// 添加组织架构成功时
		addDeptSuccess(isUpdate,isReload){
			this.addDeptVisible = false;
			this.addDeptType = '';
			if(isUpdate){
				this.getTree(isReload && isReload === 'reload' ? true : false);
			}
			
		},
		// 添加/编辑 成员成功
		addMemberSuccess(type,isUpdate){
			this.getTree(false);
			this.currentPage = 1;
			if(!type || type !== 'contince'){
				this.addMemberVisible = false;
			}
			// if(!isUpdate || isUpdate !== 'noUpdate'){
			// 	this.getUserTable();
			// } 
			this.userDetail = {};
			this.$refs.multipleTable.clearSelection();
		},
		uploadFileSuccess(){
			this.importFileVisible = false;
			this.$refs.multipleTable.clearSelection();
			this.getTree(true);
		},
		// 设置负责人成功
		setLeaderSuccess(isUpdate){
			this.setLeaderVisible = false;
			this.getTree(false);
		},
		// 点击tree
		handleNodeClick(data,node,el) {
			this.dynamicTags = [];
			this.deleteAllTag();
			this.defaultCheckedKeys = [data.id];
			this.$refs.multipleTable.clearSelection();
			document.getElementById('selectOrganizationTree').firstChild.classList.remove('is-current')
			console.log(data,'data');
			console.log(node,'node');
			console.log(el,'el');
			this.checkedTreeItemNode = node;
			this.checkedTreeItem = JSON.parse(JSON.stringify(data));
			this.currentPage = 1;
			this.getUserTable();
			this.getSelectData(data.id);
		},
		// 点击下拉操作列表
		handleCommand(type,node,data){
			this.$refs.multipleTable.clearSelection();
			console.log(type);
			console.log(type,node,data);
			this.checkedTreeItemNode = node;
			this.checkedTreeItem = JSON.parse(JSON.stringify(data));
			this.addDeptType = type;
			let list = node.parent.childNodes;
			if(type === 'setLeader'){
				this.setLeaderVisible = true;
			} else if(type === 'addSameDept' || type === 'addChildDept' || type === 'edit')  {
				this.addDeptVisible = true;
			} else if (type === 'up' || type === 'down' ){
				let currentData = JSON.parse(JSON.stringify(data));
				let params = [];
				list.forEach((item,index) => {
					if(currentData.id === item.data.id){
						if(type === 'up'){
							currentData.orderNum  = currentData.orderNum - 1;
							list[index-1].data.orderNum += 1;
							params = [...[currentData],...[list[index-1].data]];
						} else {
							currentData.orderNum += 1;
							list[index+1].data.orderNum -= 1;
							params = [...[currentData],...[list[index+1].data]];
						}
					}
				})
				console.log(params,type === 'up' ? '上移' : '下移');
				this.$http.put(this.requestUrl.upAndDown.updateUpAndDownStreamOrderNum,params)
					.then((res) => {
						if(res.data.code == 0){
							this.$message.success(type === 'up' ? '上移成功' : '下移成功')
							this.getTree(true);
						} else {
							this.$message.error(res.data.message)
						}
					});
			} else if (type === 'del'){
				let arr = [];
				list.forEach(item => {
					arr.push(item.data);
				})
				this.$confirm('此操作将永久删除该组织架构, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.delete(this.requestUrl.upAndDown.deleteUpAndDownStreamById + '/' + data.id,{
						data: arr
					})
					.then(res=>{
						if (res.data.code === '0'){
							this.$message({
								message:'删除成功',
								type:'success',
								duration:1000
							})
							this.getTree(true);
						} else {
							this.$message.error(res.data.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			}
		},
		// 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times); // 13位时间戳
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			let D = getBYT(date.getDate() )+ ' ';
			return Y+M+D;
		},
		//点击高级检索显示
		searchClick() {
			setTimeout(() => {
				$('#senior-search').slideToggle(300);
			}, 300)
		},
		searchFn(){
			this.screenConditionObj.input = this.keyword;
			this.getUserTable(this.screenConditionObj,'false');
		},
		// 高级检索--进行搜索
		screenCondition(datas){
			console.log(datas,'-------');
			this.currentPage = 1;
			datas.input = this.keyword;
			this.screenConditionObj = JSON.parse(JSON.stringify(datas));
			// this.screenConditionObj.departmentIds = [];
			// datas.departmentIds.length > 0 && datas.departmentIds.forEach(item => {
			// 	this.screenConditionObj.departmentIds.push(item.id);
			// })
			// this.$refs.seniorSearch.resetSeniorSearch();
			console.log(this.screenConditionObj,'screenConditionObj');
			this.getUserTable(this.screenConditionObj,'is');
			this.dynamicTags = [];
			if(datas.birthdayRange.length > 0){
				this.dynamicTags.push({
					name:"生日",
					key:"birthdayRange",
					value:this.getDataTime(datas.birthdayRange[0]) + '至 ' + this.getDataTime(datas.birthdayRange[1])
				})
			}
			if(datas.staffInRange.length > 0){
				this.dynamicTags.push({
					name:"入职日期",
					key:"staffInRange",
					value:this.getDataTime(datas.staffInRange[0]) + '至 ' + this.getDataTime(datas.staffInRange[1])
				})
			}
			// if(datas.departmentIds.length > 0){
			// 	this.dynamicTags.push({
			// 		name:"组织架构",
			// 		key:"departmentIds",
			// 		value:datas.departmentIds
			// 	})
			// }
			this.dynamicTags.push({
				name:"状态",
				key:"status",
				value:datas.status === null  ? '全部' : datas.status ? '正常' : '禁用' 
			})
			console.log(this.dynamicTags,'dynamicTagdynamicTag');
			
		},
		handleClose(item,indx){
			this.dynamicTags.splice(indx, 1);
			if(item.key === 'birthdayRange'){
				this.screenConditionObj.birthdayRange = [];
			} else if(item.key === 'staffInRange'){
				this.screenConditionObj.staffInRange = [];
			}
			//  else if(item.key === 'departmentIds'){
			// 	this.screenConditionObj.departmentIds = [];
			// }
			 else if(item.key === 'status'){
				this.screenConditionObj.status = null;
			}
			this.$refs.seniorSearch.resetSeniorSearch(item);
			this.getUserTable(this.screenConditionObj,'is');
		},
		deleteAllTag(type){
			this.screenConditionObj = {
				birthdayRange:[],
				staffInRange:[],
				// departmentIds:[],
				status:null
			}
			this.dynamicTags = [];
			this.$refs.seniorSearch.resetSeniorSearch({},'all');
			if(type === 'deleteAllTag'){
				this.getUserTable(this.screenConditionObj,'is');
			}
		},
		// 获取表格数据
		getUserTable(datas,type){
			this.$http.post(this.requestUrl.upAndDown.getUserData + "?organizationId=" + this.checkedTreeItem.id + '&page=' + this.currentPage + '&size=' + this.pageSize,datas || {})
				.then((res) => {
					if(res.data.code == 0){
						this.tableData = res.data.data.list || [];
						this.total = res.data.data.total || null;
						if(type === 'is'){
							$('#senior-search').slideUp(300);
						}
					} else {
						this.$message.error(res.data.message)
					}
				});
		},
		// 表格用户详情
		getDetails(datas){
			console.log(datas,'表格用户详情');
			this.checkedUserList = [];
			this.userDetail = datas;
			this.addMemberVisible = true;
		},
		// 表格多选
		handleSelectionChange(data){
			console.log(data);
			this.checkedUserList = [...[],...data];
		},
		// 分页器切换每页条数
		handleSizeChange(data){
			this.pageSize = data;
			this.getUserTable();
		},
		// 分页器切换页码
		handleCurrentChange(data){
			this.currentPage = data;
			this.getUserTable();
		},
		// 添加成员
		addMember(){
			this.userDetail = {};
			this.checkedUserList = [];
			this.addMemberVisible = true;
			// this.getUserTable();
		},
		// 批量编辑成员
		batchEditUser(){
			if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
				this.checkedUserList = [...[],...this.$refs.multipleTable.selection];
				this.addMemberVisible = true;
			} else {
				this.$message.error('请选择要编辑的数据')
			}
		},
		// 删除用户
		deleteUser(){
			if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
				let ids = [];
				this.checkedUserList.forEach((item,index) => {
					ids.push(item.id)
				})
				this.$http.post(this.requestUrl.upAndDown.confirmPrincipal ,ids)
					.then((res) => {
						if (res.data.code === '0'){
							console.log(res.data.data);
							let str = res.data.data.length > 0 ? '所选成员中包含'+res.data.data.join(',')+'负责人，是否确认删除 ?' : '删除所选成员，是否确认删除'
							this.$confirm(str, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								
								this.$http.delete(this.requestUrl.upAndDown.deleteUpAndDownMemberById,{
									data: ids
								}).then(res=>{
										if (res.data.code === '0'){
											this.getTree(false);
											// this.getUserTable();
											this.$refs.multipleTable.clearSelection();
											this.$message({
												message:'删除成员成功',
												type:'success',
												duration:1000
											})
											
										} else {
											this.$message.error(res.data.message)
										}
									})
							}).catch(() => {
								this.$message({
									type: 'info',
									message: '已取消删除'
								});          
							});
								
							

						} else {
							this.$message({
								type: 'error',
								message: '获取成员身份信息出错'
							});
						}
					})
			} else {
				this.$message.error('请选择要删除的数据')
			}
			
				
			
		},
		// 导入弹框显隐
		importFile(){
			this.importFileVisible = true;
		},
		// 导出
		exportFile(){
			console.log(this.$refs.multipleTable.selection);
			if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
				this.$http.post(this.requestUrl.upAndDown.memberExport,this.$refs.multipleTable.selection)
					.then(res=>{
						if (res.data.code === '0'){
							window.location.href = res.data.data;
							this.$message({
								message:'导出成功,您可在下载中心查看进度',
								type:'success',
								duration:1000
							})
							this.$refs.multipleTable.clearSelection();
						} else {
							this.$message.error(res.data.message)
						}
					})
			} else {
				this.$message.error('请选择要导出的数据')
			}
		}
    }
};
</script>
<style lang="less" scoped>
@import "./../../../assets/less/upDownManage/upDownManage.less";
[v-cloak]{
	display: none;
}
</style>