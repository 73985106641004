
<template>
	<div class="add-member">
		<el-drawer
		:visible.sync="visible"
		:with-header="false"
		size="358px"
		:wrapperClosable="false"
		:close-on-press-escape="false">
			<div class="add-member-title">
				<h4>{{headerName}}</h4>
				<i class="el-icon-close" @click="closeDrawer"></i>
			</div>
			<div class="add-member-content">
				<el-form :model="ruleForm" ref="ruleForm" label-position="top" label-width="80px" class="demo-ruleForm">
					<el-form-item v-if="idBatchEdit" class="batch-edit">
						<el-select v-model="bacthEditList" multiple placeholder="请选择">
							<el-option
							v-for="item in checkedUserList"
							:key="item.id"
							:label="item.name"
							:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名" prop="name" v-if="!idBatchEdit">
						<span slot="label">{{$t('addMember.name')}}<span class="red">*</span></span>
						<el-input v-model="ruleForm.name" :placeholder="$t('common.placeholder')" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="telephone" v-if="!idBatchEdit">
						<span slot="label">{{$t('addMember.mobile')}}<span class="red">*</span></span>
						<el-input v-model="ruleForm.telephone" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('addMember.organizationName')" prop="organizationId">
						<span v-if="!idBatchEdit" slot="label">{{$t('addMember.organizationName')}}<span class="red">*</span></span>
						 <!-- <el-cascader
						 	ref="cascaderCascader"
						 	:key="cascaderKey"
						 	:props='props'
						 	v-model="ruleForm.organizationId"
						 	style="width:320px"
							:options="deptList"
							clearable></el-cascader> -->
						<div class="staff-input" @click="openMemberTree">
							<span class="placeholder-text" v-if="checkedDeptPopup.length === 0">{{$t('common.selectPlaceholder')}}</span>
							<div v-else class="client-value">
								<div v-for="item in checkedDeptPopup" :key="item.id" class="item">
									{{item.name}}
									<!-- <i class="el-icon-close"></i> -->
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('addMember.email')" prop="email" v-if="!idBatchEdit">
						<el-input v-model="ruleForm.email" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('addMember.birthday')" prop="birthday" v-if="!idBatchEdit">
						<el-date-picker
							v-model="ruleForm.birthday"
							type="date"
							:placeholder="$t('addMember.birthdayPlaceholder')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addMember.staffIn')" prop="staffIn">
						<el-date-picker
							v-model="ruleForm.staffIn"
							type="date"
							:placeholder="$t('addMember.staffInPlaceholder')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addMember.setStatus')" v-if="idBatchEdit" prop="status">
						<el-select v-model="ruleForm.status" :placeholder="$t('common.selectPlaceholder')" clearable>
							<el-option
							v-for="item in statusOptions"
							:key="item.value"
							:label="item.label"
							:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item >
						<span slot="label" >{{$t('upDownManage.setSystemPermissions')}}<span class="red" v-if="!idBatchEdit">*</span></span>
                        <el-checkbox v-model="ruleForm.botAuth" :disabled="botAuthDisabled" :label="$t('addMember.botPermission')" @change="botAuthChange"></el-checkbox>
                        <el-checkbox v-model="ruleForm.workOrderAuth" :disabled="workOrderAuthDisabled" :label="$t('addMember.workOrderPermission')" @change="botAuthChange"></el-checkbox>
						<el-checkbox v-model="ruleForm.authFollowDepart" :label="$t('addMember.followDepart')" @change="authFollowDepartChange"></el-checkbox>
					</el-form-item>
					<el-form-item v-if="idBatchEdit">
                       <div class="bot-num">
                           <div class="icon">{{$t('addMember.capital')}}</div>
                           <div class="type">{{$t('addMember.numberOfConsultationBot')}}</div>
                           <span>{{totalUseBotPeopleNum === -1 ? $t('addMember.unrestricted') : totalUseBotPeopleNum + $t('addMember.person')}}</span>
                       </div>
                       <div class="choose-num">
							<div class="icon choose">{{$t('addMember.choose')}}</div>
							<div class="type">{{$t('addMember.selectedNumberOfPeople')}}</div>
							<span>{{existUseBotPeopleNum}}{{$t('addMember.person') }}</span>
							<template v-if="existUseBotPeopleNum > totalUseBotPeopleNum && totalUseBotPeopleNum !== -1">
                                <div class="go-beyond" v-if="promoteSurplusDay < 0">{{$t('addMember.exceeded')}}</div>
                           </template>
                       </div>
					</el-form-item>
				</el-form>
			</div>
			<div class="footer-btns">
				<el-button 
					icon="iconfont guoran-a-18-13" 
					@click="deleteMember('ruleForm')" 
					v-if="!idBatchEdit && ruleForm.id && rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_DELETE') !== -1" 
					size="small">
				</el-button>
				<div 
					:class="[ruleForm.status ? 'disabled' : '','operation-btn']" 
					v-if="!idBatchEdit && ruleForm.id && rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_UPDATE') !== -1" 
					@click="switchStatus">
					<i class="iconfont guoran-huifuchengyuan" v-if="ruleForm.status"></i>
					<!-- status  true 启用，false禁用-->
					<i class="iconfont guoran-jinyongchengyuan" v-else></i>
					<span> {{ruleForm.status? $t('addMember.disableMembers') : $t('addMember.restoreMembers')}}</span>
				</div>
				<el-button round @click="closeDrawer('ruleForm')">{{$t('common.close')}}</el-button>
				<el-button round @click="submitForm('ruleForm','contince')" v-if="!ruleForm.id && !idBatchEdit" class="save-continue">{{$t('common.saveContinue')}}</el-button>
				<el-button 
					type="primary" 
					round 
					v-if="btnIsShow"
					@click="submitForm('ruleForm')">{{ $t('common.save') }}</el-button>
			</div>
		</el-drawer>
		<chooseDept :showPopup.sync="showPopup" :treeData="treeDataCopy" @checkedDept="checkedDept"></chooseDept>
	</div>
</template>

<script>
import chooseDept from './chooseDept.vue';

export default {
	components:{
		chooseDept
	},
    data(){
        return {
			isMultiple:false,
			checkedDeptPopup:[],
			showPopup:false,
			cascaderKey:0,
			treeList:[],
            ruleForm:{
                name:"",
				telephone:'',
				organizationId:[],
				email:"",
				staffIn:"",
				birthday:'',
				status:true, //true 启用，false禁用
				botAuth:false,
				workOrderAuth:false,
				authFollowDepart:true,
			},
			props: { 
				multiple: false,
				checkStrictly: true,
				value:'id',
				label:'name',
				children:'children'
			},
			statusOptions:[{
				value:false,
				label:this.$t('addMember.disabled')
			},{
				value:true,
				label:this.$t('addMember.enable')
			}],
			headerName:"",
			idBatchEdit:false, // 是否是批量编辑成员
			bacthEditList:[], // 批量编辑时 ， 选中的成员
			oldOrganizationIds:null , // 修改前成员的组织架构
			defaultProps:{
				children:'children',
				label:'name'
			},
			treeDataCopy:[],
			botAuthDisabled:true,
			workOrderAuthDisabled:true,
			existUseBotPeopleNum:0,
            totalUseBotPeopleNum:0,
			promoteSurplusDay:-1
        }
    },
	props:{
		visible:{
			default:false,
			type:Boolean
		},
		userDetail:{
			type:Object,
			default(){
				return{}
			}
		},
		checkedUserList:{
			type:Array,
			default(){
				return[]
			}
		},
		deptList:{
			type:Array,
			default(){
				return[]
			}
		},
		rolePrivilegesList:{
			type:Array,
			default(){
				return[]
			}
		},
		treeData:{
			type:Array,
			default(){
				return[]
			}
		},
		checkedTreeItem:{
            type:Object,
            default(){
                return{}
            }
        },
		 promoteSurplusDay:{
            type:Number,
            default:-1
        }
	},
	watch:{
		visible(n,o){
			this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
			++this.cascaderKey;
			this.checkedDeptPopup = [];
			console.log(this.treeData,'======');
			this.treeDataCopy = JSON.parse(JSON.stringify(this.treeData));
			if(this.checkedUserList && this.checkedUserList.length > 0){
				this.getFollowNum();
				this.resetForm();
				this.idBatchEdit = true;
				this.headerName = this.$t('addMember.batchUpdate');
				this.bacthEditList = [];
				this.checkedUserList.forEach(item => {
					this.bacthEditList.push(item.id);
				})
			} else {
				this.idBatchEdit = false;
			}
		},
		userDetail:{
			handler(n,o){
				if(n.id){
					this.headerName = this.$t('addMember.memberManage');
						this.ruleForm = JSON.parse(JSON.stringify(n));
						this.oldOrganizationIds = n.allOrganizationId;
						console.log(this.oldOrganizationIds,'oldOrganizationIds');
						this.handlerId(n);
						this.authFollowDepartChange();
				} else {
					this.headerName = this.$t('addMember.addMember');
					this.resetForm();
				}
			},
			deep:true,
			immediate:true
		},
		treeDataCopy:{
			handler(n){
				console.log(n,'------------treeDataCopy');
			},
			deep:true,
			immediate:true
		}
	},
	computed:{
		btnIsShow(){
			let flag = false;
			if(this.userDetail.id){
				if(this.rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_UPDATE') !== -1 && this.userDetail.id){
					flag = true;
				} else {
					flag = false;
				}
			} else {
				if(this.rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_ADD') !== -1 ){
					flag = true;
				} else {
					flag = false;
				}
			}
			return flag;
		}
	},
	methods:{
		botAuthChange(){
			if(this.idBatchEdit){
				this.queryPeopleAuthNum(); 
			}
        },
		// 查询主体下可咨询机器人的总数及已有可咨询机器人人数
		 // 0部门,1员工,2上下游部门,3上下游员工
        queryPeopleAuthNum(){
			let ids = [];
			this.checkedUserList.forEach(item => {
				ids.push(item.id)
			})
			this.$http.post(this.requestUrl.upAndDown.queryPeopleAuthNum + '?type=3' + '&authBot=' + this.ruleForm.botAuth,ids)
                .then((res) => {
                    if(res.data.code == 0){
                        this.existUseBotPeopleNum = res.data.data.existUseBotPeopleNum;
                        this.totalUseBotPeopleNum = res.data.data.totalUseBotPeopleNum;
                    } else {
                        this.$message.error(res.data.message)
                    }
                });
           
        },
		getFollowNum(){
			let ids = [];
			this.checkedUserList.forEach(item => {
				ids.push(item.id);
			})
			this.$http.post(this.requestUrl.upAndDown.queryFollowDept + '?type=2',ids)
				.then((res) => {
					if(res.data.code == 0){
						this.existUseBotPeopleNum = res.data.data.existUseBotPeopleNum;
						this.totalUseBotPeopleNum = res.data.data.totalUseBotPeopleNum;
					} else {
						this.$message.error(res.data.message)
					}
				});
		},
		// 跟随部门true:工单/机器人 禁用并且清除选中
		authFollowDepartChange(){
			if(this.ruleForm.authFollowDepart){
				if(this.idBatchEdit ){
					this.getFollowNum();
				}
				this.ruleForm.botAuth = false;
				this.ruleForm.workOrderAuth = false;
				this.botAuthDisabled = true;
				this.workOrderAuthDisabled = true;
			} else {
				this.botAuthDisabled = false;
				this.workOrderAuthDisabled = false;
			}
			
		},
		handlerId(n){
			// n.pageEcho = [221, 249, 250];
			this.ruleForm.organizationId = [];
			if( n.allOrganizationId){
				this.judgeChildrens(this.treeDataCopy,n.allOrganizationId);
			} else {
				this.ruleForm.organizationId = [];
			}
		},
		// 找出树结构中选中的数据
		judgeChildrens(data,checkedList) {
			data.forEach(item => {
				if(checkedList.indexOf(item.id) !== -1){
					this.checkedDeptPopup.push(item);
					item.checked = true;
				}
				if (item.children && item.children.length > 0) {
					this.judgeChildrens(item.children || [],checkedList);
				}
			});
			return 
		},
		resetForm(){
			this.ruleForm = {
				name:"",
				telephone:'',
				organizationId:[],
				email:"",
				staffIn:"",
				birthday:'',
				status:true, //true 启用，false禁用
				botAuth:false,
				workOrderAuth:false,
				authFollowDepart:true,
			}
			this.workOrderAuthDisabled = true;
			this.botAuthDisabled = true;
			this.checkedDeptPopup = [];
			this.treeDataCopy = JSON.parse(JSON.stringify(this.treeData));
		},
		handlerCascaderData(data){
			data.forEach(item => {
				if(item.id === this.userDetail.organizationId){
					if(item.parentId !== -1){
						this.handlerCascaderDatas.push(item.parentId);
					} else {
						this.handlerCascaderDatas.push(item.id);
					}
					this.handlerCascaderDatas.push(item.id);
				} else {
					this.handlerCascaderData(item.children || []);
				}
			});
			return this.handlerCascaderDatas;
		},
		// 按钮关闭弹框
		closeDrawer() {
			this.$emit('addMemberSuccess','','noUpdate');
		}, 
		// 删除成员
		deleteMember(){
			this.$http.post(this.requestUrl.upAndDown.confirmPrincipal ,[this.ruleForm.id])
				.then(res=>{
					if (res.data.code === '0'){
						let str = res.data.data.length > 0 ? this.$t('addMember.deleteMemberTip1') : this.$t('addMember.deleteMemberTip2')
						this.$confirm(str, this.$t('addMember.tip'), {
							confirmButtonText: this.$t('common.confirm'),
							cancelButtonText: this.$t('common.cancel'),
							type: 'warning'
						}).then(() => {
							this.$http.delete(this.requestUrl.upAndDown.deleteUpAndDownMemberById,{
								data: [this.ruleForm.id]
							})
								.then(res=>{
									if (res.data.code === '0'){
										this.$emit('addMemberSuccess','','update');
										this.$message({
											message:this.$t('addMember.deleteSuccess'),
											type:'success',
											duration:1000
										})
										
									}
								})
						}).catch(() => {
							this.$message({
								type: 'info',
								message: this.$t('addMember.cancelDelete')
							});          
						});
					} else {
						this.$message({
							type: 'error',
							message: '获取成员身份信息出错'
						}); 
					}
				})
		},
		// 修改成员状态
		switchStatus(){
      let message = this.$t('addMember.switchStatusTip');
      let title = this.$t('addMember.tip');
			this.$confirm(message, title, {
				confirmButtonText: this.$t('common.confirm'),
				cancelButtonText: this.$t('common.cancel'),
				type: 'warning'
			}).then(() => {
				this.$http.put(this.requestUrl.upAndDown.updateUserStatus,{
					status:!this.ruleForm.status,
					id:this.userDetail.id,
					portalUserId:this.userDetail.portalUserId
				})
					.then((res) => {
						if(res.data.code == 0){
							this.$message.success(this.$t('addMember.editStatusSuccess'))
							this.$emit('addMemberSuccess','switchStatus','update');
						} else {
							this.$message.error(res.data.message.replace(/\//g,''))
						}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: this.$t('addMember.cancelDelete')
				});          
			});
		},
		handlerTimes(times){
			if(times){
				if(typeof(times) === 'number'){
					return times
				} else {
					return times.getTime();
				}
			} else {
				return null
			}
		},
		// 修改
		editForm(params,type){
			this.$http.put(this.requestUrl.upAndDown.updateUpAndDownMemberById,params)
				.then((res) => {
					if(res.data.code == 0){
						// this.$refs[formName].resetFields();
						this.$message.success('操作成功')
						this.$emit('addMemberSuccess',type,'update');
					} else {
						this.$message.error(res.data.message.replace(/\//g,''))
					}
			});
		},
		// 保存
		submitForm(formName,type){
			console.log(this.ruleForm,'ruleForm');
			this.$refs[formName].validate((valid,data) => {
				let flag = true,organizationIds = [];
				if(!this.idBatchEdit) {
					if(this.ruleForm.name === ''){
						flag = false;
						this.$message.error(this.$t('addMember.inputName'))
						return false;
					}
					if(this.ruleForm.telephone === ''){
						flag = false;
						this.$message.error(this.$t('addMember.mobileRequired'))
						return false;
					} else if (!/^1[3-9][0-9]{9}$/.test(this.ruleForm.telephone)){
						flag = false;
						this.$message.error(this.$t('addMember.checkPhone'))
						return false;
					} 
					if(this.checkedDeptPopup.length === 0 ){
						flag = false;
						this.$message.error(this.$t('addMember.organizationRequired'))
						return false;
					}
					if(this.ruleForm.email && this.ruleForm.email !== '' && !/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(this.ruleForm.email)){
						flag = false;
						this.$message.error(this.$t('addMember.checkEmail'));
						return false;
					}
					if(!this.ruleForm.authFollowDepart && !this.ruleForm.botAuth && !this.ruleForm.workOrderAuth){
						flag = false;
						this.$message.error(this.$t('addMember.permissionRequired'));
						return false;
					}
				}
				
				if(flag){
					this.checkedDeptPopup.forEach(item => {
						organizationIds.push(item.id)
					})
					let params = {
						...Object.assign(this.ruleForm),
						organizationId:this.ruleForm.organizationId[this.ruleForm.organizationId.length-1],
						staffIn:this.handlerTimes(this.ruleForm.staffIn),
						birthday:this.handlerTimes(this.ruleForm.birthday),
						oldOrganizationIds:this.oldOrganizationIds,
						organizationIds:organizationIds,
						botAuth:this.ruleForm.botAuth,
						workOrderAuth:this.ruleForm.workOrderAuth,
						authFollowDepart:this.ruleForm.authFollowDepart,
					} 
					// 批量编辑
					if(this.idBatchEdit){
						let obj = {}
						if(!this.ruleForm.authFollowDepart && !this.ruleForm.botAuth && !this.ruleForm.workOrderAuth){
							obj.botAuth = null
							obj.workOrderAuth = null
							obj.authFollowDepart = null
						}
							this.$http.put(this.requestUrl.upAndDown.updateUpAndDownMemberByIds,{
								memberIds: Array.from(new Set(this.bacthEditList)),
								upAndDownMember:{
									status:this.ruleForm.status === '' ? null : this.ruleForm.status,
									staffIn:this.handlerTimes(this.ruleForm.staffIn),
									organizationIds:organizationIds,
									oldOrganizationIds:this.oldOrganizationIds,
									botAuth:this.ruleForm.botAuth,
									workOrderAuth:this.ruleForm.workOrderAuth,
									authFollowDepart:this.ruleForm.authFollowDepart,
									...obj
								}
							})
								.then((res) => {
									if(res.data.code == 0){
										// this.$refs[formName].resetFields();
										this.$emit('addMemberSuccess','batchEdit','update');
									} else {
										this.$message.error(res.data.message)
									}
							});
					} else {
						// 编辑
						if(this.userDetail.id){
							params.principal = null;
							params.status = this.ruleForm.status === '' ? null : this.ruleForm.status
							this.editForm(params,type);
						} else {
							this.$http.post(this.requestUrl.upAndDown.addUpAndDownMember,{
								...params,
								oldOrganizationIds:[],
								principal:false,
							})
								.then((res) => {
									if(res.data.code == 0){
										this.$message.success(this.$t('addMember.addMemberSuccess'))
										if(type === 'contince'){
											this.resetForm();
										}
										this.$emit('addMemberSuccess',type,'update');
									} else {
										this.$message.error(res.data.message)
									}
							});
						}
					}
				}
			});
		},
		// d打开组织架构tree弹框
		openMemberTree(){
			this.showPopup = true;
		},
		// 选择部门后
		checkedDept(list,treeData){
			this.checkedDeptPopup = [...[],...list];
			this.treeDataCopy = JSON.parse(JSON.stringify(treeData))
			this.showPopup = false;

		}
	}
}
</script>

<style lang="less" scoped>
.add-member {
	.el-form-item{
		display: flex;
		flex-direction: column;
	}
	::v-deep .el-drawer__wrapper{
		z-index: 2001;
	}
	::v-deep .choose-dept{
		z-index: 9999;
		.popup-container{
			min-height: 300px;
		}
		.custom-tree-node{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			color: #000;
			.custom-tree-node-left{
				display: flex;
				align-items: center;
				width: 90%;
				i{
					font-size: 14px;
					display: flex;
					align-items: center;
					padding-right: 6px;
				}
				.data-label{
					width: 100%;
					height: 28px;
					display: flex;
					align-items: center;
				}
			}
			.el-icon-check{
				font-size: 14px;
				font-weight: 600;
				margin-right: 10px;
				color: #909399;
			}
			// .el-icon-close{
			// 	color: transparent;
			// 	font-weight: 600;
			// }
			// &:hover{
			// 	.el-icon-close{
			// 		color: #000;
					
			// 	}
			// }

		}
	}
	.add-member-title {
		height: 45px;
		background: #f6f8fd;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h4 {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}
		i {
			cursor: pointer;
		}
	}
	.add-member-content{
		padding: 20px;
		height: calc(100vh - 160px);
		overflow-y: scroll;
		overflow-x: hidden;
		font-family: inherit;
		::v-deep .el-form-item__label{
			line-height: 20px;
		}
		::v-deep .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
		::v-deep .el-input{
			width: 320px;
			
		}
		.red {
			color: red;
			margin-left: 6px;
		}
		.staff-input{
			// height: 36px;
			width:320px;
			border-radius: 5px;
			border:1px solid #E0E6F7;
			cursor: pointer;
			display: flex;
			align-items: center;
			.placeholder-text{
				padding-left: 15px;
				color: #A9B3C6;
			}
			.client-value{
				padding: 5px;
				color: #606266;
				display: flex;
				// justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				line-height: 30px;
				width: 100%;
				.item{
					border:1px solid hsl(240, 5%, 92%);
					height: 24px;
					line-height: 24px;
					padding: 0 8px;
					line-height: 22px;
					position: relative;
					background-color: #f4f4f5;
					color: #909399;
					border-radius: 3px;
					font-size: 12px;
					margin: 2px 0 2px 6px;
					.el-icon-close{
						display: inline-block;
						border-radius: 50%;
						text-align: center;
						position: relative;
						cursor: pointer;
						font-size: 12px;
						height: 14px;
						width: 14px;
						line-height: 14px;
						background-color: #c0c4cc;
						color: #909399;
						cursor: pointer;
					}
				}
				

			}
		}
		  .bot-num{
            margin-bottom: 30px;
        }
        .bot-num,.choose-num{
            height: 21px;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            color: #616161;
            .icon{
                width: 21px;
                height: 21px;
                background: #09DDD5;
                border-radius: 50%;
                text-align: center;
                line-height: 21px;
                color: #fff;
                
                &.choose{
                    background-color: #87A6FF;
                }
            }
            .type{
                margin: 0 15px 0 10px;
                width: 122px;
                text-align: left;
                color: #A9B3C6;
            }
            .go-beyond{
                // width: 92px;
				padding: 0 6px;
                height: 20px;
                font-size: 12px;
                background: #FFEBEC;
                border-radius: 3px;
                color: #FE5965;
                text-align: center;
                line-height: 20px;
                margin-left: 5px;
            }

        }
		
	}
	.footer-btns{
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		position: absolute;
		bottom: 0;
		padding:20px 0;
		box-shadow: 2px -2px 2px #eef2f6;
		.el-button{
			height: 37px;
			padding: 10px 20px;
		}
		.el-button--primary{
			background-color: #366AFF;
			border-color: #366AFF;
			height: 37px;
		}
		.el-button--small{
			width: 34px;
			height: 37px;
			padding: 0;
		}
		.operation-btn{
			width: 94px;
			height: 34px;
			background: #E1FAF9;
			border-radius: 5px;
			color: #00C2BB;
			text-align: center;
			line-height: 34px;
			cursor: pointer;
			&.disabled{
				background: #FFF3F4;
				color: #FE5965;
			}
		}
		.save-continue{
			color: #366AFF;
			border:1px solid #A1B9FF;
			width: 99px;
			padding: 0;
			text-align: center;
			line-height: 37px;
		}


	}
}
</style>>
