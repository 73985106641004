<template>
    <div v-loading="loading" id="workTimeTable">
        <el-table
            :data="tableData"
            style="width: 100%"
            height="calc(100vh - 160px)"
        >
            <el-table-column prop="name" :label="$t('workTime.table.name')" align="left">
                <template slot-scope="scope">
                    <div class="work-time-des">
                        {{ scope.row.workTimeName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                :label="$t('workTime.table.operation')"
                align="center"
                width="120"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('workTime.table.edit')"
                        placement="top"
                    >
                        <span
                            class="table-handle-icon"
                            @click="editRow(scope.row)"
                        >
                            <i class="iconfont guoran-a-16-15"></i>
                        </span>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('workTime.table.delete')"
                        placement="top"
                    >
                        <span
                            v-if="!scope.row.workTimeDefault"
                            class="table-handle-icon"
                            @click="deleteRow(scope.row)"
                            ><i class="iconfont guoran-a-18-13"></i
                        ></span>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="tableData.length != 0" class="pagination">
            <pagination
                :pageSize="pageSize"
                :currentPage="currentPage"
                :total="total"
                @currentChange="handleCurrentChange"
            ></pagination>
        </div>
        <el-drawer
            :visible.sync="drawerWorkTime"
            :with-header="false"
            size="560px"
            id="work-time-drewer"
        >
            <div class="drawer-container">
                <div class="drawer-header">
                    <span class="drawer-header-wt-name">{{$t('workTime.table.drawerWorkTimeTitle')}}</span>
                    <span @click="closeWtDrawer" class="drawer-header-wt-close"
                        ><i class="el-icon-close"></i
                    ></span>
                </div>
                <div class="drawer-content-wt">
                    <div class="wt-form-cell">
                        <div class="wtf-title">
                          {{$t('workTime.table.workOrderName')}} <span class="wtf-title-point"> *</span>
                        </div>
                        <div class="wtf-content">
                            <el-input
                                size="small"
                                v-model="activeWeek.workTimeName"
                                maxlength="45"
                                :placeholder="$t('workTime.table.workOrderNamePla')"
                            ></el-input>
                        </div>
                    </div>
                    <div class="wt-form-cell">
                        <div class="wtf-title">{{$t('workTime.table.serviceTime')}}</div>
                        <div
                            class="wtf-content-radio wtf-content wtf-content-bg"
                        >
                            <el-radio
                                v-model="activeWeek.serviceTimeType"
                                :label="0"
                                >24{{$t('workTime.hour')}} x 7{{$t('workTime.day')}}</el-radio
                            >
                            <el-radio
                                v-model="activeWeek.serviceTimeType"
                                :label="1"
                                >{{$t('workTime.table.selectType')}}</el-radio
                            >
                        </div>
                        <div
                            v-show="activeWeek.serviceTimeType === 1"
                            class="
                                wtf-content
                                wtf-content-bg
                                wtf-content-padding-12
                                wtf-content-border-top
                            "
                        >
                            <el-checkbox-group
                                v-model="activeWeek.workDay"
                                @change="handleCheckedWeeksChange"
                            >
                                <el-checkbox
                                    v-for="week in weeks"
                                    :label="week.id"
                                    :key="week.id"
                                    >{{ week.name }}</el-checkbox
                                >
                            </el-checkbox-group>
                        </div>
                        <div
                            v-show="activeWeek.serviceTimeType === 1"
                            class="
                                wtf-content
                                wtf-content-bg
                                wtf-content-padding-top-0
                            "
                        >
                            <div
                                v-for="(
                                    wCell, wCellIndex
                                ) in activeWeek.workTimes"
                                :key="wCellIndex"
                                class="week-cell"
                            >
                                <div class="week-cell-name">
                                    {{ weeks[Number(wCell.week) - 1].name }}
                                </div>
                                <div class="week-cell-content">
                                    <div
                                        v-for="(
                                            wCellTime, wCellTimeIndex
                                        ) in wCell.workTime"
                                        :key="wCellTimeIndex"
                                        class="wcell-cell"
                                    >
                                        <div class="wcell-cell-time-picker">
                                            <el-time-picker
                                                is-range
                                                size="small"
                                                format="HH:mm"
                                                value-format="HH:mm"
                                                v-model="
                                                    wCell.workTime[
                                                        wCellTimeIndex
                                                    ]
                                                "
                                                :range-separator="$t('workTime.table.range')"
                                                :start-placeholder="$t('workTime.table.startDate')"
                                                :end-placeholder="$t('workTime.table.endDate')"
                                                :placeholder="$t('workTime.table.dateRangePlaceholder')"
                                            >
                                            </el-time-picker>
                                            <span
                                                v-if="wCellTimeIndex === 0"
                                                class="add-wcell-cell"
                                                @click="
                                                    addWtTimeDur(wCellIndex)
                                                "
                                            >
                                                <i class="el-icon-plus"></i>
                                            </span>
                                            <span
                                                v-if="wCellTimeIndex !== 0"
                                                class="close-wcell-cell"
                                                @click="
                                                    deleteWtTimeDur(
                                                        wCellIndex,
                                                        wCellTimeIndex
                                                    )
                                                "
                                                ><i class="el-icon-close"></i
                                            ></span>
                                        </div>
                                        <span
                                            v-if="
                                                wCell.workTime.length - 1 !==
                                                wCellTimeIndex
                                            "
                                            class="wt-to"
                                            >{{$t('workTime.table.range')}}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="(activeWeek.serviceTimeType === 1 && activeWeek.workDay.indexOf(8) == -1)"
                        class="wt-form-cell"
                    >
                        <div class="wtf-title">{{$t('workTime.table.workDay')}}</div>
                        <div class="wtf-content wtf-content-bg">
                            <el-radio-group
                                class="wtf-content-radio"
                                fill="#366AFF"
                                text-color="#366AFF"
                                v-model="activeWeek.filterStatutoryHolidays"
                                size="small"
                            >
                                <el-radio :label="true">{{$t('workTime.table.filter')}}</el-radio>
                                <el-radio :label="false">{{$t('workTime.table.noFilter')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div
                        v-show="(activeWeek.serviceTimeType === 1 && activeWeek.workDay.indexOf(8) == -1)"
                        class="wt-form-cell"
                    >
                        <div class="wtf-title">{{$t('workTime.table.balanceWorkTimes')}}</div>
                        <div class="wtf-content wtf-content-bg">
                            <div
                                v-for="(
                                    wCellTime, wCellTimeIndex
                                ) in activeWeek.balanceWorkTimes"
                                :key="wCellTimeIndex"
                                class="wcell-cell"
                            >
                                <div class="wcell-cell-time-picker">
                                    <el-time-picker
                                        is-range
                                        size="small"
                                        format="HH:mm"
                                        value-format="HH:mm"
                                        v-model="
                                            activeWeek.balanceWorkTimes[
                                                wCellTimeIndex
                                            ]
                                        "
                                        :range-separator="$t('workTime.table.range')"
                                        :start-placeholder="$t('workTime.table.startDate')"
                                        :end-placeholder="$t('workTime.table.endDate')"
                                        :placeholder="$t('workTime.table.dateRangePlaceholder')"
                                    >
                                    </el-time-picker>
                                    <span
                                        v-if="wCellTimeIndex === 0"
                                        class="add-wcell-cell"
                                        @click="addWtBlTimeDur"
                                    >
                                        <i class="el-icon-plus"></i>
                                    </span>
                                    <span
                                        v-if="wCellTimeIndex !== 0"
                                        class="close-wcell-cell"
                                        @click="
                                            deleteWtBlTimeDur(wCellTimeIndex)
                                        "
                                        ><i class="el-icon-close"></i
                                    ></span>
                                </div>
                                <span
                                    v-if="
                                        activeWeek.balanceWorkTimes.length -
                                            1 !==
                                        wCellTimeIndex
                                    "
                                    class="wt-to"
                                    >{{$t('workTime.table.range')}}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-footer">
                    <el-button @click="closeWtDrawer">{{$t('common.cancel')}}</el-button>
                    <el-button type="primary" @click="saveWtDrawer"
                        >{{$t('common.save')}}</el-button
                    >
                </div>
            </div>
        </el-drawer>
        <delete-dialog @closeEvent="dialogVisible = false" v-if="dialogVisible">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('workTime.table.deleteTitle')}}</div>
                <div>
                    <span style="text-align: left; padding-left: 14px"
                        >{{$t('workTime.table.deleteTip')}}
                        <span style="padding-left: 4px; font-weight: 600">{{
                            activeWeek.workTimeName
                        }}</span></span
                    >
                    <p
                        style="
                            text-align: left;
                            padding-left: 14px;
                            margin-top: 12px;
                            color: #909399;
                        "
                        v-if="deleteUseNum != 0"
                    >
                        <span
                            >{{$t('workTime.table.tip')}}</span
                        >
                        <br />
                        <br />
                        <el-select
                            style="width: 100%"
                            v-model="moToWorkTime"
                            size="small"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                            <el-option
                                v-for="item in filtermoToWorkTime(tableData)"
                                :key="item.id"
                                :label="item.workTimeName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogVisible = false" size="small" plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteWorkTime"
                    style="background-color: #fe5965; color: white"
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
    </div>
</template>

<script>
import pagination from "./../../../../components/pagination.vue";
import DeleteDialog from "./../../../../components/deleteDialogue.vue";
export default {
    name: "workTimeTable",
    components: { pagination, DeleteDialog },
    data() {
        return {
            dialogVisible: false,
            defaultTime: "",
            moToWorkTime: "",
            deleteUseNum: 0,
            userWorkTimeInfo: "",
            handleType: "add",
            total: 100,
            currentPage: 1,
            pageSize: 20,
            tableData: [],
            drawerWorkTime: false,
            value1: "",
            loading: false,

            weeks: [
                { name: this.$t('workTime.table.Monday'), id: 1 },
                { name: this.$t('workTime.table.Tuesday'), id: 2 },
                { name: this.$t('workTime.table.Wednesday'), id: 3 },
                { name: this.$t('workTime.table.Thursday'), id: 4 },
                { name: this.$t('workTime.table.Friday'), id: 5 },
                { name: this.$t('workTime.table.Saturday'), id: 6 },
                { name: this.$t('workTime.table.Sunday'), id: 7 },
                { name: this.$t('workTime.table.legalHolidays'), id: 8 },
            ],
            activeWeek: {
                filterStatutoryHolidays: true,
                serviceTimeType: 1,
                workDay: [1],
                workTimeDefault: false,
                workTimeName: "",
                balanceWorkTimes: [],
                workTimes: [],
            },
        };
    },
    methods: {
        deleteRow(val) {
            this.activeWeek = val;
            this.selectWhoUseWorkTimeById(val.id);
            this.moToWorkTime = "";
            this.dialogVisible = true;
        },
        deleteWorkTime() {
            if (this.deleteUseNum > 0) {
                if (this.moToWorkTime === "") {
                    this.$message.error(this.$t('workTime.table.selectWorkTime'));
                } else {
                    this.$http
                        .put(
                            this.requestUrl.workTime.migrationUserWorkTime +
                                this.moToWorkTime,
                            this.userWorkTimeInfo
                        )
                        .then((res) => {
                            if (res.data && res.data.code === "0") {
                                this.confirmDelete(this.activeWeek.id);
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                }
            } else {
                this.confirmDelete(this.activeWeek.id);
            }
        },
        filtermoToWorkTime(list) {
            let arr = [];
            list.forEach((item) => {
                if (item.id != this.activeWeek.id) {
                    arr.push(item);
                }
            });
            return arr;
        },
        confirmDelete(id) {
            this.$http
                .delete(this.requestUrl.workTime.deleteWorkTime + id)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.getWorkTimeList();
                        this.moToWorkTime = "";
                        this.dialogVisible = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        selectWhoUseWorkTimeById(id) {
            this.$http
                .get(this.requestUrl.workTime.selectWhoUseWorkTimeById + id)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.deleteUseNum = res.data.data.length;
                        this.userWorkTimeInfo = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getWorkTimeList();
        },
        // 分页获取工作时间列表
        getWorkTimeList() {
            this.loading = true;
            this.$http
                .get(
                    this.requestUrl.workTime.queryAllUserWorkTimePage +
                        "?pageNo=" +
                        this.currentPage +
                        "&pageSize=" +
                        this.pageSize
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        let arr = res.data.data.list;
                        arr.forEach(item => {
                            console.log(item.workDay);
                            if (item.workDay != null) {
                                item.workDay.forEach((wdItem,index) => {
                                    if (wdItem == "statutoryHoliday") {
                                        this.$set(item.workDay, index, 8);
                                    }
                                })
                            }
                            
                            item.workTimes.forEach(cell => {
                                if (cell.week == "statutoryHoliday") {
                                    console.log(cell);
                                    this.$set(cell, 'week', 8);
                                }
                            })
                        })
                        this.tableData = [...res.data.data.list];
                        this.total = res.data.data.total;
                        this.loading = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 新增工作时间
        addWorkTime() {
            this.handleType = "add";
            this.activeWeek = {
                filterStatutoryHolidays: true,
                serviceTimeType: 0,
                workDay: [],
                workTimeDefault: false,
                workTimeName: "",
                workTimes: [],
                balanceWorkTimes: [this.defaultTime],
            };
            this.drawerWorkTime = true;
        },
        addWtTimeDur(wCellIndex) {
            this.activeWeek.workTimes[wCellIndex].workTime.push(
                this.defaultTime
            );
        },
        addWtBlTimeDur() {
            this.activeWeek.balanceWorkTimes.push(this.defaultTime);
        },
        deleteWtTimeDur(wCellIndex, wCellTimeIndex) {
            this.activeWeek.workTimes[wCellIndex].workTime.splice(
                wCellTimeIndex,
                1
            );
        },
        deleteWtBlTimeDur(wCellTimeIndex) {
            this.activeWeek.balanceWorkTimes.splice(wCellTimeIndex, 1);
        },
        // 回显时间误差处理 返回标准时间
        fixTime8(timestamp) {
            let timestampRes = timestamp;
            return new Date(
                new Date(timestampRes).getFullYear(),
                new Date(timestampRes).getMonth() + 1,
                new Date(timestampRes).getDate(),
                new Date(timestampRes).getHours(),
                new Date(timestampRes).getMinutes()
            );
        },

        editRow(row) {
            this.handleType = "edit";
            this.activeWeek = JSON.parse(JSON.stringify(row));
            if (this.activeWeek.workTimeDefault) {
                if (this.activeWeek.balanceWorkTime == null) {
                    let date = new Date();
                    this.$set(this.activeWeek, "balanceWorkTimes", [
                        {
                            endTime: "2022-08-11T10:00:00.000+00:00",
                            endTimeTimestamp: 1660212000000,
                            startTime: "2022-08-11T00:00:00.000+00:00",
                            startTimeTimestamp: 1660176000000,
                        },
                    ]);
                }
                if (this.activeWeek.filterStatutoryHolidays == null) {
                    this.activeWeek.filterStatutoryHolidays = false;
                }
                if (this.activeWeek.workDay == null) {
                    this.activeWeek.workDay = [];
                }
                if (this.activeWeek.workTime == null) {
                    this.activeWeek.workTime = "[]";
                }
            }
            this.activeWeek.workTimes.forEach((oCell, oCellIndex) => {
                if (oCell.workTime) {
                    oCell.workTime.forEach((iCell, iCellIndex) => {
                        oCell.workTime[iCellIndex] = [
                            this.fixTime8(iCell.startTimeTimestamp),
                            this.fixTime8(iCell.endTimeTimestamp),
                        ];
                    });
                }
            });
            this.activeWeek.balanceWorkTimes &&
                this.activeWeek.balanceWorkTimes.forEach(
                    (ocell, oCellIndex) => {
                        this.activeWeek.balanceWorkTimes[oCellIndex] = [
                            ...[
                                this.fixTime8(ocell.startTimeTimestamp),
                                this.fixTime8(ocell.endTimeTimestamp),
                            ],
                        ];
                    }
                );
            this.activeWeek.workDay = this.activeWeek.workDay.map((item) =>
                Number(item)
            );
            this.activeWeek.workTimes = [...this.activeWeek.workTimes];
            this.drawerWorkTime = true;
        },
        //
        handleCheckedWeeksChange(val) {
            this.activeWeek.workDay = val;

            if (val.length > this.activeWeek.workTimes.length) {
                this.activeWeek.workTimes.push({
                    week: String(val[val.length - 1]),
                    workTime: [this.defaultTime],
                });
            } else {
                let arr = [];
                this.activeWeek.workTimes.forEach((item, index) => {
                    if (val.indexOf(Number(item.week)) != -1) {
                        arr[val.indexOf(Number(item.week))] = item;
                    }
                });
                this.activeWeek.workTimes = [...arr];
            }

            // 排序
            this.activeWeek.workTimes = this.activeWeek.workTimes.sort(
                function (a, b) {
                    return Number(a.week) - Number(b.week);
                }
            );
        },
        closeWtDrawer() {
            this.drawerWorkTime = false;
        },
        // 时刻 18:00 转 当天标准时间
        timeToStandardTime(time) {
            let date = new Date();
            if (time.length === 5) {
                return new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate(),
                    time.split(":")[0],
                    time.split(":")[1]
                );
            } else {
                return new Date(time);
            }
        },
        // 时刻 18:00 转 当天时间戳
        timeToTimestamp(time) {
            let date = new Date();
            if (time.length == 5) {
                return new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate(),
                    time.split(":")[0],
                    time.split(":")[1]
                ).getTime();
            } else {
                return new Date(time).getTime();
            }
        },
        saveWtDrawer() {
            let param = JSON.parse(JSON.stringify(this.activeWeek));
            let wtFlag = false, btFlag = false;
            param.workDay.forEach((item,index) => {
                if (item == 8) {
                    console.log("++++++++")
                    this.$set(param.workDay, index, 'statutoryHoliday');
                    this.$set(param, 'filterStatutoryHolidays', false);
                }
            })
            console.log(param.workDay);
            param.workTimes.forEach((item, ind) => {
                let obj = {};
                if (item.week == 8) {
                    this.$set(item, 'week', 'statutoryHoliday');
                }
                item.workTime.forEach((timeCell, index) => {
                    console.log(timeCell);
                    if (timeCell == null) {
                        wtFlag = true;
                    } else {
                        obj = {
                            endTime: this.timeToStandardTime(timeCell[1]),
                            endTimeTimestamp: this.timeToTimestamp(timeCell[1]),
                            startTime: this.timeToStandardTime(timeCell[0]),
                            startTimeTimestamp: this.timeToTimestamp(timeCell[0]),
                        };
                        item.workTime[index] = { ...obj };
                    }
                });
                param.workTimes[ind] = item;
            });
            param.balanceWorkTimes.forEach((timeCell, index) => {
                if (timeCell == null) {
                    btFlag = true; 
                } else {
                    if (timeCell[0] != null && timeCell[1] != null) {
                        param.balanceWorkTimes[index] = {
                            ...{
                                endTime: this.timeToStandardTime(timeCell[1]),
                                endTimeTimestamp: this.timeToTimestamp(timeCell[1]),
                                startTime: this.timeToStandardTime(timeCell[0]),
                                startTimeTimestamp: this.timeToTimestamp(
                                    timeCell[0]
                                ),
                            },
                        };
                    }
                }  
            });
            if (wtFlag) {
                this.$message.error(this.$t('workTime.table.serviceTimeRequired'));
                return false;
            }
            if (btFlag) {
                this.$message.error(this.$t('workTime.table.balanceWorkTimesRequired'));
                return false;
            }
            if (param.workTimeName.trim() == "") {
                this.$message.error(this.$t('workTime.table.workTimeNameRequired'));
                return false;
            }
            if (this.handleType === "add") {
                this.$http
                    .post(this.requestUrl.workTime.addWorkTime, param)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.getWorkTimeList();
                            this.$message.success(this.$t('workTime.table.addSuccess'));
                            this.drawerWorkTime = false;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }

            if (this.handleType === "edit") {
                this.$http
                    .put(this.requestUrl.workTime.updateUserWorkTimeById, param)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.getWorkTimeList();
                            this.$message.success(this.$t('workTime.table.editSuccess'));
                            this.drawerWorkTime = false;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },

        // 时间点 取合集
        toRang() {
            let arr = [
                ["01:00", "02:00"],
                ["08:00", "18:00"],
                ["05:00", "18:00"],
                ["09:00", "20:00"],
                ["20:05", "20:08"],
                ["20:08", "20:10"],
                ["21:00", "21:05"],
            ];
            let numArr = [];
            // 时间点组合转换数字组合
            arr.forEach((item) => {
                numArr.push([
                    Number(item[0].split(":")[0]) * 60 +
                        Number(item[0].split(":")[1]),
                    Number(item[1].split(":")[0]) * 60 +
                        Number(item[1].split(":")[1]),
                ]);
            });
            // 数字组合按开始值排序
            numArr = numArr.sort(function (a, b) {
                return Number(a[0]) - Number(b[0]);
            });

            // numArr 上一次循环合并结果 flag 循环次数 递归结束条件
            function combine(numArr, flag) {
                let start = 0;
                let end = 1440;
                let tampArr = [];
                numArr.forEach((item, index) => {
                    if (index < flag) {
                        // 已经合并完数组
                        tampArr.push(item);
                    } else if (index === flag) {
                        // 本次循环合并基准数组
                        start = item[0];
                        end = item[1];
                        tampArr.push([start, end]);
                    } else {
                        // 两个 如 [200, 300] [250, 350] 数组的八种关系
                        if (item[0] < start && item[1] > end) {
                            // 3
                            start = item[0];
                            end = item[1];
                            tampArr[tampArr.length - 1] = [start, end];
                        } else if (
                            item[0] > start &&
                            item[1] > end &&
                            item[0] < end
                        ) {
                            // 5
                            end = item[1];
                            tampArr[tampArr.length - 1] = [start, end];
                        } else if (item[0] > start && item[1] < end) {
                            // 4
                            // 不做处理
                            tampArr[tampArr.length - 1] = [start, end];
                        } else if (
                            item[0] < start &&
                            item[1] < end &&
                            item[1] > start
                        ) {
                            // 1
                            start = item[0];
                            tampArr[tampArr.length - 1] = [start, end];
                        } else if (item[1] < start && item[0] < start) {
                            // 2
                            tampArr.push([item[0], item[1]]);
                        } else if (item[0] > end && item[1] > end) {
                            // 6
                            tampArr.push([item[0], item[1]]);
                        } else if (item[0] === end) {
                            // 7
                            end = item[1];
                            tampArr[tampArr.length - 1] = [start, end];
                        } else if (item[1] === start) {
                            // 8
                            start = item[0];
                            tampArr[tampArr.length - 1] = [start, end];
                        }
                    }
                });
                flag++; // 递归递增下标
                if (flag < tampArr.length) {
                    combine(tampArr, flag); // 递归
                }
            }
            combine(numArr, 0);
        },
    },
    mounted() {
        this.toRang();
        let date = new Date();
        this.weeks = [
          { name: this.$t('workTime.table.Monday'), id: 1 },
          { name: this.$t('workTime.table.Tuesday'), id: 2 },
          { name: this.$t('workTime.table.Wednesday'), id: 3 },
          { name: this.$t('workTime.table.Thursday'), id: 4 },
          { name: this.$t('workTime.table.Friday'), id: 5 },
          { name: this.$t('workTime.table.Saturday'), id: 6 },
          { name: this.$t('workTime.table.Sunday'), id: 7 },
          { name: this.$t('workTime.table.legalHolidays'), id: 8 },
        ]
        this.defaultTime = [
            new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8),
            new Date(
                new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    18
                )
            ),
        ];
        this.getWorkTimeList();
    },
};
</script>

<style scoped lang="less">
#work-time-drewer {
    .drawer-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .drawer-header {
            flex: none;
            height: 45px;
            background: #f6f8fd;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .drawer-header-wt-name {
                height: 43px;
                line-height: 43px;
                margin-left: 20px;
                border-bottom: solid 2px #366aff;
            }
            .drawer-header-wt-close {
                font-size: 16px;
                color: #616161;
                margin-right: 20px;
                cursor: pointer;
            }
        }
        .drawer-content-wt {
            height: calc(100vh - 170px);
            padding: 20px;
            overflow: auto;
            .wt-form-cell {
                margin-bottom: 20px;
                .wtf-title {
                    color: #616161;
                    margin-bottom: 10px;
                    .wtf-title-point {
                        color: #fe5965;
                    }
                }
                .wtf-content {
                    .week-cell-name {
                        margin: 0 0 8px;
                    }
                    .week-cell-content {
                        margin-bottom: 8px;
                    }
                    .wcell-cell {
                        width: 100%;
                        .wcell-cell-time-picker {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            /deep/.el-range-input {
                                border: none;
                            }
                            /deep/input:focus {
                                box-shadow: none;
                            }
                            .el-date-editor {
                                flex: auto;
                            }
                            .add-wcell-cell {
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                background: #366aff;
                                color: white;
                                border-radius: 5px;
                                font-size: 14px;
                                cursor: pointer;
                                margin: 0 8px;
                                i {
                                    font-weight: 700;
                                }
                            }
                            .close-wcell-cell {
                                width: 28px;
                                height: 28px;
                                line-height: 28px;
                                border: solid 1px #e0e6f7;
                                border-radius: 5px;
                                color: #366aff;
                                text-align: center;
                                font-size: 14px;
                                cursor: pointer;
                                margin: 0 8px;
                                i {
                                    font-weight: 800px;
                                }
                            }
                        }
                        .wt-to {
                            margin: -2px 0;
                            display: block;
                            color: white;
                            font-size: 12px;
                            text-align: center;
                            width: 17px;
                            height: 17px;
                            background: #366aff;
                            border-radius: 50%;
                            transform: scale(0.8);
                        }
                    }
                }
                .wtf-content-radio {
                    display: flex;
                    justify-content: space-around;
                    .el-radio {
                        flex: 1;
                    }
                }
                .wtf-content-bg {
                    background: #fbfcfd;
                    border-radius: 5px;
                    padding: 10px;
                }
                .wtf-content-border-top {
                    border-top: 1px dashed #e0e6f7;
                }
                /deep/.el-checkbox-group > .el-checkbox > .el-checkbox__label {
                    font-size: 14px !important;
                    margin-bottom: 6px;
                }
                .wtf-content-padding-12 {
                    padding: 12px 0 -12px;
                }
                .wtf-content-padding-18 {
                    padding: 0 48px;
                }
                .wtf-content-padding-top-0 {
                    padding-top: 0;
                    padding-bottom: 2px;
                }
            }
        }
        .drawer-footer {
            padding-bottom: 30px;
            padding-top: 30px;
            display: flex;
            justify-content: flex-end;
            margin-right: 24px;
            .delete-footer {
                position: absolute;
                left: 64px;
                .delete-btn {
                    width: 28px;
                    height: 28px;
                    background: #ffffff;
                    border: 1px solid #e0e6f7;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
            /deep/.el-button {
                padding: 8px 30px !important;

                border-radius: 19px !important;
            }
            /deep/.el-button--primary {
                background: #366aff !important;
            }
            /deep/.el-button--default:hover {
                background: none;
                color: #366aff;
                border: solid 1px #366aff;
            }
        }
    }
}
#workTimeTable {
    /deep/.el-radio__input.is-checked .el-radio__inner {
        border-color: #366aff;
        background: #366aff;
    }
    /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #366aff;
    }
    .work-time-des {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
</style>