<template>
  <div class="selectBot">
    <div class="title">{{$t('channel.miniProgram.selectBotTitle')}}</div>
    <el-select v-model="botId" :placeholder="$t('flowHeader.selectBot')" style="margin: 10px;width: calc(100% - 20px)">
      <el-option
          v-for="item in botList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "selectBot",
  data(){
    return{
      botId:"",
      botList:[]
    }
  },
  methods:{
    getBotList(){
      let url = this.requestUrl.bot.getBotList + '?page=1&size=1000';
      this.$http.get(url).then(res => {
        console.log('botList',res)
        if (res.data.code == 0 && res.data.data){
          this.botList = res.data.data.list;
        }
      })
    }
  },
  mounted() {
    this.getBotList();
  }
};
</script>

<style scoped lang="less">
.selectBot{
  border: 1px solid #E0E6F7;
  .title{
    padding: 10px;
    background: #F6F8FD;
    border-radius: 5px 5px 0px 0px;
  }
}
</style>