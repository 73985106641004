<template>
    <div id="data-role-manage">
        <left-menu></left-menu>
        <div class="content-container-roleManage">
            <div class="roleManage-header">
                <div class="leftInfo">
                    <span class="headerName">企业管理</span>
                </div>
                <div class="rightButton">
                    <el-button type="primary" size="small" @click="addRole"
                        >新增数据权限</el-button
                    >
                </div>
            </div>
            <div class="roleManage-content">
                <div style="width: 150px; height: 100vh; background: white">
                    <left-sub-menu></left-sub-menu>
                </div>
                <div class="right-content">
                    <role-table
                        ref="roleTable"
                        @c_editDataRole="p_editDataRole"
                    ></role-table>
                </div>
            </div>
        </div>
        <el-dialog
            :title="addDialoTitle"
            :visible.sync="handleVisible"
            :close-on-click-modal="false"
            width="560px"
            :before-close="handleClose"
        >
            <div id="add-or-edit-data-role-box">
                <AddDataRole
                    v-if="handleVisible"
                    :editObjStr="editObjStr"
                    :handleType="handleType"
                    @c_closeHandleDataRole="p_closeHandleDataRole"
                />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import leftSubMenu from "../../menu/leftSubMenu";
import leftMenu from "../../menu/leftMenu";
import RoleTable from "./components/dataRoleTable";
import AddDataRole from "./components/addRole.vue";
import { operable } from "../js/operable";

export default {
    name: "roleManage",
    data() {
        return {
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            rolePrivilegesList: [],
            handleVisible: false,
            addDialoTitle: "新增数据权限",
            handleType: "add", // add edit
            editObjStr: "",
        };
    },
    components: { RoleTable, leftSubMenu, leftMenu, AddDataRole },
    mounted() {
        // this.rolePrivilegesList=this.getRolePrivileges()
        console.log(this.$route.params.showAdd);
        if (this.$route.params.showAdd) {
            this.handleVisible = true;
        }
    },
    computed: {
        operableRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        addRole() {
            this.editObjStr = "";
            this.addDialoTitle = "新增数据权限";
            this.handleType = "add";
            this.handleVisible = true;
        },
        p_closeHandleDataRole(type) {
            if (type == "save") {
                this.$refs.roleTable.getRoles();
            }
            // if (type == 'cancel') {

            // }
            this.handleVisible = false;
        },
        p_editDataRole(row) {
            this.handleType = "edit";
            this.addDialoTitle = "编辑数据权限";
            let skills = [];
            JSON.parse(row).userBotPrivileges.forEach((bot) => {
                if (!bot.skillIds||bot.skillIds.length == 0) {
                    skills.push([bot.botId]);
                } else {
                    skills = skills.concat(bot.skillIds.map((id) => {
                        return [bot.botId, id];
                    }));
                }
            });
            let woInfoList = [];
            JSON.parse(row).userWorkOrderPrivileges.forEach((wo) => {
                woInfoList.push({
                    type: wo.fatherNodeType,
                    name: wo.fatherNodeTag,
                    scopes:
                        wo.fatherNodeType == 4
                            ? wo.workOrderId.map((x) => Number(x))
                            : wo.workOrderId,
                });
            });
            this.editObjStr = JSON.stringify({
                name: JSON.parse(row).privilegesName,
                description: JSON.parse(row).description,
                id: JSON.parse(row).id,
                skills: skills,
                woInfoList: woInfoList,
            });
            this.handleVisible = true;
        },
        handleSizeChange() {},
        handleCurrentChange() {},
    },
};
</script>

<style scoped lang="less">
@import "../../../assets/less/dataRoleManage/dataRoleManage";
</style>