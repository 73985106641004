<template>
    <div id="workDuty">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i></span>
                        <span>{{$t('common.enterpriseEdition')}} - </span>
                        <span>{{$t('workDuty.title')}}</span>
                    </div>
                    <div class="setup-header-right-l" slot="setup-header-right-l">
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <duty-list ref="dutylist" @showManageGroup="showManageGroup"></duty-list>
                </div>
            </div>
            <el-drawer size="700px" :visible.sync="dutyGroupDrawer" :with-header="false">
                <manage-dutygroup @closeGroupManage="closeGroupManage" @updateGroupList="updateGroupList"></manage-dutygroup>
            </el-drawer>
            
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import Popup from "@/components/popup";
import SideNav from "../../menu/SideNav.vue";
import TopHeader from "../../../components/top-header.vue";
import DeleteDialog from "../../../components/deleteDialogue.vue";
import ManangeNav from "../../menu/ManageNave.vue";
import dutyList from "./components/dutyList";
import ManageDutygroup from "./components/manageDutygroup.vue";
export default {
    name: "workDuty",
    data() {
        return {
            dutyGroupDrawer: false,
        };
    },
    components: {
        dutyList,
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        ManangeNav,
        ManageDutygroup,
    },
    methods: {
        showManageGroup() {
            this.dutyGroupDrawer = true;
        },
        closeGroupManage(){
            this.dutyGroupDrawer = false;
        },
        updateGroupList(){
            this.$refs.dutylist.getAllGroup();
        },
       
    },
    mounted() {
       
    }
};
</script>

<style scoped lang="less">
#workDuty {

    .gr-content-container {
        position: absolute;
        left: 64px;
        width: calc(100vw - 96px);
        height: calc(100vh - 24px);
        padding: 12px 16px;
        min-width: 1000px;
        overflow-x: auto;
        text-align: left;

        .content {
            width: 100%;
            height: calc(100% - 84px);
            margin-top: 16px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;

            .manage-nav-section {
                //width: 216px;
              width: 300px;
                flex: none;
                margin-right: 16px;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;
            }

            .content-section {
                flex: auto;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;
            }
        }
    }
}
</style>