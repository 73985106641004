<!---购买明细-->
<template>
    <el-drawer
        custom-class="buy-detail-drawer"
        title="购买明细"
        :visible.sync="buyDetailVisible"
        :with-header="false"
        :destroy-on-close="true"
		size="700px"
		:wrapperClosable="false"
        @close="closeDrawer">
            <div class="add-member-title">
				<h4>购买明细</h4>
				<i class="el-icon-close" @click="closeDrawer"></i>
			</div>
           <div class="drawer-content">
                <div class="search-box">
                    <el-input v-model="keyword"  placeholder="搜索员工/部门" prefix-icon="el-icon-search"></el-input>
                    <!-- <el-cascader :props="props" v-model="name" ></el-cascader> -->
                </div>
                <el-table
                    :data="tableData"
                    class="table-box">
                    <el-table-column
                        prop="userName"
                        align="left"
                        min-width="140"
                        class-name="user-name-cell"
                        label="申请人">
                       <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.profilePhoto && scope.row.profilePhoto !== ''"
                                class="user-icon profilePhoto"
                                :src="scope.row.profilePhoto"
                                :fit="'container'">
                            </el-image>
                            <div v-else class="user-icon">
                                <span  v-if="!scope.row.ewechat">{{scope.row.userName.slice(1,scope.row.userName.length+1)}}</span>
                                <i v-else class="iconfont guoran-a-18-32"></i>
                            </div>
                            <div class="user-name">
                                <el-tooltip class="item" :open-delay="500" effect="dark" :content="scope.row.userName" placement="top-end">
                                    <div class="one-column-ellipsis" v-if="!scope.row.ewechat">{{scope.row.userName}}</div>
                                    <open-data v-else :type="'userName'" :openid="scope.row.userName"></open-data>
                                </el-tooltip>
                                <div class="dept one-column-ellipsis">
                                    <span v-for="(item,index) in scope.row.departmentNameList" :key="index">
                                        <el-tooltip placement="top" effect="light">
                                            <div slot="content">
                                                <span v-if="!scope.row.ewechat">{{item}}</span>
                                                <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                            </div>
                                            <span v-if="!scope.row.ewechat">{{item}}</span>
                                            <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                            <span v-if="index + 1 < scope.row.departmentNameList.length">/</span>
                                        </el-tooltip>
                                    </span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="buyType"
                        align="left"
                        min-width="120"
                        label="类型">
                    </el-table-column>
                    <el-table-column
                        prop="manay"
                        align="left"
                        label="金额"
                        sortable>
                        <template slot-scope="scope">
                            <span>¥{{scope.row.pay}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        align="left"
                        label="时间"
                        width="150"
                        sortable>
                    </el-table-column>
                </el-table>
           </div>
           <pagination 
                :pageSize="pageSize" 
                :currentPage="currentPage" 
                :total="total"
                @handleSizeChange="handleSizeChange" 
                @currentChange="handleCurrentChange">
            </pagination>
    </el-drawer>    
</template>

<script>
import pagination from "../../../../components/pagination.vue";
import OpenData from "@/components/openData";
import {getDataTime} from './data'
export default {
    data(){
        return{
            keyword:'',
            tableData:[],
            props: {
                lazy: true,
                lazyLoad :(node, resolve) =>{
                    // let departId = node.level !== 0 ? node.data.value : '';
                    // let userId = localStorage.getItem("_uid");
                    // let url = node.level === 0 ?  "/api/department/corpListLazy" + "?userId=" + userId : "/api/department/corpListLazy" + "?departId=" + departId + "&userId=" + userId
                    // this.$http.get(url).then(res => {
                    //     console.log(res,'res');
                    //     // type=0是员工，type=1是部门
                    //     // if(res.data.length > 0){
                    //         const nodes = res.data.map(item => ({
                    //             value: item.id,
                    //             label: item.label,
                    //             leaf: item.type === 1 ? false : true
                    //         }));
                    //         resolve(nodes);
                    //     // } 
                    //     // else {
                    //     //     resolve({
                    //     //         value: node.data.value,
                    //     //         label: node.data.label,
                    //     //         leaf: true
                    //     //     });
                    //     // }
                        
                    // })
                }
            },
            pageSize:15,
			currentPage:1,
			total:0,
        }
    },
    props:{
        buyDetailVisible:{
            typeof:Boolean,
            default:false
        },
        pageType:{
            type:String,
            default:""
        },
        corpId:{
            type:String,
            default:""
        }
    },
    components:{pagination,OpenData},
    watch:{
        buyDetailVisible(n){
            if(n){
                this.getTableData()
            }
        }
       
    },
    methods:{
        closeDrawer(){
            this.$emit('update:buyDetailVisible',false)
        },
         // 分页器切换每页条数
		handleSizeChange(data){
			this.pageSize = data;
			this.getTableData();
		},
		// 分页器切换页码
		handleCurrentChange(data){
			this.currentPage = data;
			this.getTableData();
		},
        getTableData(){
            let url = this.pageType === 'capacity' ? this.requestUrl.knowledge.selectCapacityBuyApply : this.requestUrl.knowledge.selectFlowBuyApply;
            let params = {
                "page":this.currentPage, // 页码
                "size":this.pageSize, // 每页条数
                "mainId":localStorage.getItem('_mainId'),
                "corpId":this.corpId,
                "keyword":this.keyword, // 搜索关键词
                "sort":1, // 0 申请时间升序，1 申请时间降序  默认给1
                priceSort:1, //  0 金额升序，1 金额降序
            }
            this.$http.post(url,params).then((res) => {
                if(res.data && res.data.data && res.data.data.list){
                    res.data.data.list.forEach((item,index) => {
                        // item.userName = item.userName
                        item.departmentNameList = JSON.parse(item.departmentName) || [];
                        item.time = getDataTime(item.payTime);
                    })
                    this.total = res.data.data.total;
                    this.tableData = res.data.data.list;
                    this.$forceUpdate()
                } else {
                    this.messageFn('error', '网络异常,请稍后重试');
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.buy-detail-drawer{
    .add-member-title {
		height: 45px;
		background: #f6f8fd;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h4 {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}
		i {
			cursor: pointer;
		}
	}
    .drawer-content{
        padding: 20px;
        width: 100%;
        height: calc(100% - 95px);
        .search-box{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
            /deep/.el-input{
                width: 284px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                .el-input__inner{
                    width: 284px;
                    height: 32px;
                }
                .el-input__icon{
                    font-size: 16px;
                    line-height: 32px;
                }
            }
            /deep/.el-select{
                .el-input__inner,.el-input{
                    width: 174px;
                }
            }
        }
        /deep/.el-table{
            height: calc(100% - 30px);
            overflow-x: hidden;
            overflow-y: auto;
            .el-table__header-wrapper{
                height: 40px;
                line-height: 40px;
                background-color: #EFF3FF;
                th{
                    height: 40px;
                    padding: 0;
                    background: #EFF3FF;
                }
            }
            .cell{
                text-align: left;
            }
            .el-table__body{
                td{
                    height: 60px;
                    padding: 0;
                }
            }
            .user-name-cell .cell{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .user-icon{
                        width: 30px;
                        height: 30px;
                        background: #366AFF;
                        border-radius: 50%;
                        color: #fff;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 6px;
                        flex-shrink: 0;
                        // margin-left: 10px;
                        span{
                            font-size: 10px;
                            transform: scale(0.5);
                        }
                    }
                    .user-name{
                        text-align: left;
                        .dept{
                            font-size: 12px;
                            color: #999999;
                        }
                    }
                    
                }
        }
    }
}
</style>