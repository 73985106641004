// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router/index_u'
// import router from './router/index'
import ElementUI from 'element-ui'
import CKEditor from '@ckeditor/ckeditor5-vue2';

// import 'element-ui/lib/theme-chalk/index.css'
// import './assets/less/theme/index.css'
import './assets/style/theme/index.css' // element 自定义样式
import '@/assets/fonts/iconfont.css';
import {requestUrl} from "./api/requestUrl";
import VueClipboard from 'vue-clipboard2'
import instance from "./http";
import ignore_auth_instance from "./http_ignore_auth";
import QRCode from 'qrcodejs2'; // 链接转二维码

import VueI18n from 'vue-i18n'; //多语言

// 导入多语言的 JSON 文件
import enLocale from './locales/en.json';
import cnLocale from './locales/cn.json';

Vue.use(VueI18n);
// 创建 VueI18n 实例，并设置默认语言和多语言内容
const i18n = new VueI18n({
    locale: 'cn', // 默认语言
    messages: {
        en: enLocale, // 英文
        cn: cnLocale, // 中文
        // 其他语言...
    }
});
// 公用方法
import tool from './common/tools';
//把tools的工具挂载到vue原型上
Object.keys(tool).forEach(val=>{
  Vue.prototype[val] = tool[val];
})
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.use(CKEditor);
Vue.config.productionTip = false
Vue.prototype.$http = instance;
Vue.prototype.$http_ignore_auth = ignore_auth_instance;
Vue.prototype.QRCode = QRCode;
Vue.prototype.requestUrl = requestUrl;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.getRolePrivileges=function(){
    let Privileges=[]
    let value2=JSON.parse(localStorage.getItem('rolePrivileges')) || []
    value2.forEach(item=>{
        Privileges.push(item.id)
    })
    return Privileges
}
Vue.prototype.getRoleId=function() {
    let value2 = JSON.parse(localStorage.getItem('rolePrivileges')) || []
    let roleEdit = []
    value2.forEach(item => {
        if (item.id === 'PORTAL_MANAGEMENT') {
            if (item.items.length > 0) {
                item.items.forEach(role => {
                    if (role.checked) {
                        roleEdit.push(role.id)
                    }
                })
            }
        }
    })
    return roleEdit
}
Vue.prototype.operable=function(type){
    let roleId=Number(localStorage.getItem('roleId'))
    if (this.roleOperable.indexOf(-1)!==-1||this.roleOperable.indexOf(0)!==-1||this.roleOperable.indexOf(roleId)!==-1){
        if (this.rolePrivilegesList.indexOf(type)!==-1){
            return  true
        }
    }
}
// 初始化后端返回特殊时间格式  调用方式 new Date("2019-12-14T00:09:16.000+0000").Format("yyyy-MM-dd hh:mm:ss")
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
Array.prototype.distinct = function (){
    var arr = this,
        result = [],
        len = arr.length;
    arr.forEach(function(v, i ,arr){  //这里利用map，filter方法也可以实现
        var bool = arr.indexOf(v,i+1);  //从传入参数的下一个索引值开始寻找是否存在重复
        if(bool === -1){
            result.push(v);
        }
    })
    return result;
};

Vue.prototype.renderText = (uid, type) =>{
    if(uid == '20281' || uid == '117431') {
        switch (type) {
            case 'OPEN':
                return i18n.t('common.intelligentBot')
            case 'WORKORDER':
                return i18n.t('common.workOrder')
            case 'IM':
                return i18n.t('common.IM')
            case 'KNOWLEDGE':
                return i18n.t('common.knowledge')
            case 'ASKAI':
                return i18n.t('common.modelBot')
            case 'QUESTION':
                return i18n.t('common.question')
        }
    } else if(window.location.hostname.includes('ebot.isheely.com')){
        switch (type) {
            case 'OPEN':
                return i18n.t('common.eBot')
            case 'WORKORDER':
                return i18n.t('common.eServiceWorkOrder')
            case 'IM':
                return i18n.t('common.eChatIM')
            case 'KNOWLEDGE':
                return i18n.t('common.eKMS')
            case 'ASKAI':
                return i18n.t('common.eAI')
            case 'QUESTION':
                    return i18n.t('common.eQ')
        }
    } else {
        switch (type) {
            case 'OPEN':
                return i18n.t('common.askBot')
            case 'WORKORDER':
                return i18n.t('common.askServiceWorkOrder')
            case 'IM':
                return i18n.t('common.askChatIM')
            case 'KNOWLEDGE':
                return i18n.t('common.askBotKnowledge')
            case 'ASKAI':
                return i18n.t('common.askAI')
            case 'QUESTION':
                return i18n.t('common.askQuesiton')
        }
    }
}
Vue.prototype.isE = () =>{
    if(window.location.hostname.includes('ebot.isheely.com')) {
        return true
    }  else {
        return false
    }
}
let bool = false;
let mainId = localStorage.getItem('_mainId');
let mainIdList = ['51778574f46346ce96478d7b7fc2ca1d','ab0f90737c8b4f2d85ba2157e4473110','31623ccfe9dd4957bbd59c5823878bbe','3e83057a1bd74b79b86859ad619fdbbb','43aadcaac45f4be19b63fd18ca68f2e6'];
if (mainIdList.indexOf(mainId) !== -1 ){
    bool = true;
} else {
    bool = false;
}
Vue.prototype.isMENGNIU = bool;
import determineMainId from './utils/determineMainId'
Vue.prototype.determineMainId = determineMainId;
import { RP_Visible } from './utils/rolePrivilegesVisible';
Vue.prototype.RP_Visible = RP_Visible;

import JSONView from 'vue-json-viewer'
Vue.use(JSONView)

/* eslint-disable no-new */
// 6cdfcac6a7d611eabdcd506b4b2f3acc
// console.log(localStorage.getItem("_mainId"))
// let mainIdList = ['6cdfcac6a7d611eabdcd506b4b2f3acc'];
// let routerVue = router;
// // if (mainIdList.indexOf(localStorage.getItem("_mainId")) !== -1) {
// //     routerVue = router_u;
// // }


new Vue({
    router,
    i18n,
    components: {App},
    render: h => h(App)
}).$mount("#app")



