<template>
    <div class="default-image">
        <div class="default-tip">
            <img src="../assets/images/default.png" />
            <span class="default-tip-text">{{$t('upDownManage.noRecord')}}</span>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {}
    }
}
</script>
<style lang="less" scoped>
.default-image {
    // background-color: white;
    // box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .default-tip {
        display: flex;
        align-items: center;
        flex-direction: column;

        .default-tip-text {
            color: #616161;
            font-size: 18px;
            margin-top: 28px;
            font-family: "Microsoft YaHei";
        }
    }
}
</style>