<template>
    <div id="accountTemplateManage">
        <div class="gr-content-container">
            <div class="staffManageHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <div class="go-back iconfont guoran-tongyichicun-18-22-fanhui" @click="goBack"></div>
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-05"></i>
                        </span>
                        <span class="text">{{$t('accountTemplate.title')}}</span>
                    </div>
                    <div class="setup-header-right-l" slot="setup-header-right-l">
                        <el-button
                            size="small"
                            type="primary"
                            @click="addAccountTemplate">
                            <i class="iconfont guoran-a-16-13"></i>
                          {{$t('accountTemplate.addTemplate')}}
                        </el-button>
                    </div>
                </top-header>
            </div>
            <div class="accountTemplateManage-content">
                <el-table
                    height="calc(100% - 50px)"
                    class="accountTemplateManage-table"
                    :data="tableData"
                    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}">
                    <el-table-column
                        prop="name"
                        align="left"
                        :label="$t('accountTemplate.name')"
                        width="350"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="roleNames"
                        align="left"
                        :label="$t('accountTemplate.roleName')"
                        show-overflow-tooltip>
                    </el-table-column>
                   <el-table-column
                    :label="$t('common.operation')"
                    width="100"
                    align="center">
                    <template slot-scope="scope">
                        <div class="operation-cell">
                            <el-tooltip open-delay="500" class="item" effect="dark" :content="$t('accountTemplate.edit')" placement="top">
                                <!-- 上下游负责人64    子主体超级管理员 -1 -->
                                <i :class="['iconfont guoran-a-16-15',scope.row.roleIds && scope.row.roleIds.length > 0 && (scope.row.roleIds.indexOf(64) !== -1 || scope.row.roleIds.indexOf(-1) !== -1) ? 'disabled' : '']" @click="editAccountTemplate(scope.row)"></i>
                            </el-tooltip>
                             <el-tooltip open-delay="500" class="item" effect="dark" :content="$t('accountTemplate.delete')" placement="top">
                                <i 
                                    @click="delTemplate(scope.row)"
                                    :class="['iconfont guoran-a-18-13',scope.row.defaulted ? 'disabled' : '']">
                                </i>
                            </el-tooltip>
                        </div>
                    </template>
                    </el-table-column>
                </el-table>
                <pagination 
                    v-if="tableData.length > 0"
                    :pageSize="pageSize" 
                    :currentPage="currentPage" 
                    :total="total"
                    @handleSizeChange="handleSizeChange" 
                    @currentChange="handleCurrentChange">
                </pagination>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
        <delete-dialog @closeEvent="deleteTemplateVisible = false"  v-if="deleteTemplateVisible">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('accountTemplate.deleteTitle')}}</div>
                <div>{{$t('accountTemplate.deleteTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="deleteTemplateVisible = false" size="small" plain>{{$t('common.cancel')}}</el-button>
                <el-button @click="deleteTemplateConfirm" style="background-color: #fe5965;color: white;" size="small" >{{$t('common.delete')}}</el-button>
            </div>
        </delete-dialog>
    </div>
</template>
<script>
import SideNav from "../../menu/SideNav.vue";
import TopHeader from "../../../components/top-header.vue";
import pagination from "../../../components/pagination";
import DeleteDialog from "../../../components/deleteDialogue.vue";
export default {
    name: "staffManage",
    components: {
        SideNav,
        TopHeader,
        pagination,
        DeleteDialog
    },
    data(){
        return{
            tableData: [],
            pageSize:20,
            currentPage:0,
            total:0,
            deleteTemplateVisible:false,
            deleteItem:{}
        }
    },
    props:{
        
    },
    created(){
        this.getRoles()
    },
    methods:{
        goBack(){
            this.$router.push({
				path:"/_accountManage"
			})
        },
        //获取所有角色
		getRoles() {
			let url = "api/role/page?pageSize=100&pageNo=1";
			this.$http.get(url).then((res) => {
				if (res.data.code === "0") {
					this.roleData = res.data.data.list;
                    this.getTableData();
				}
			});
		},
       	// 分页器切换每页条数
		handleSizeChange(data){
			this.pageSize = data;
			this.getTableData();
		},
		// 分页器切换页码
		handleCurrentChange(data){
			this.currentPage = data;
			this.getTableData();
		},
        getTableData(){
            this.$http.get('/api/account-template?page=' + this.currentPage + '&pageSize=' + this.pageSize + '&name=').then(res=>{
                if (res.data.code === '0'){
                    res.data.data.content.forEach(item => {
                        item.roleNames = '';
                        item.roleIds && item.roleIds.forEach((_v,_i) => {
                            this.roleData.forEach(roleItem => {
                                if(roleItem.id === _v){
                                    if(_i + 1 < item.roleIds.length){
                                        item.roleNames += roleItem.name + '、';
                                    } else {
                                        item.roleNames += roleItem.name;
                                    }
                                }
                            })
                        })
                    })
                    this.tableData = res.data.data.content;
                    this.total = res.data.data.totalElements;
                }
            })
        },
        delTemplate(row){
            if(row.defaulted){
                return false;
            }
            if(row.roleIds && row.roleIds.length > 0){
                this.$message.warning(this.$t('accountTemplate.noDelete'))
                return false;
            }
            this.deleteItem = row;
            this.deleteTemplateVisible = true;
        },
        deleteTemplateConfirm(){
            this.$http.delete('/api/account-template/' + this.deleteItem.id + '?checked=false').then(res=>{
                if (res.data.code === '0'){
                    this.$message.success(this.$t('accountTemplate.deleteSuccess'))
                    this.deleteTemplateVisible = false;
                    this.getTableData();
                }
            })
        },
        addAccountTemplate(){
            this.$router.push({
                name:"accountFieldType",
            })
        },
        editAccountTemplate(row){
            if(row.roleIds && row.roleIds.length > 0 && (row.roleIds.indexOf(64) !== -1 || row.roleIds.indexOf(-1) !== -1)){
                return false;
            }
            this.$router.push({
                name:"accountFieldType",
                query:{
                    id:row.id
                }
            })
        },
        
    }
};
</script>
<style lang="less" scoped>
@import "./../../../assets/less/accountManageCustom/accountTemplateManage.less";
</style>