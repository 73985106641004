<!-- 
/**
pageName:智能识别组件
auther:liuyuli
time:2022-4-24
 */
 -->
<template>
    <div class="ai-distinguish" v-if="false">
        <div class="switch-top">
            <div class="left">
                <div class="iconfont guoran-tongyichicun-jiqirentouxiang"></div>
                <span>智能识别</span>
            </div>
            <div class="right">
                <span>智能填值</span>
                <div class="switch-icon-box">
                    <el-switch
                        :width="42"
                        v-model="activeObjs.extInfo.autoInput.enable"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2"
                        active-icon-class="el-icon-check"
                        inactive-icon-class="el-icon-close"
                        :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                    >
                    </el-switch>
                </div>
            </div>
        </div>
        <div class="middle-tips" v-if="activeObjs.extInfo.autoInput.enable">
            <span>智能识别数据源</span>
            <span>选择根据哪个字段的值识别</span>
        </div>
        <div class="bottom-input" v-if="activeObjs.extInfo.autoInput.enable" @click.stop="showPopup">
            <span class="placeholder-text" v-if="checkedList.length === 0">请选择</span>
            <div v-else class="client-value">
                <div v-for="(item,index) in checkedList" :key="item.widgetId" class="item">
                    {{item.name}}
                    <i class="el-icon-close" @click.stop="delChecked(item,index)"></i>
                </div>
            </div>
        </div>
        <div class="choose-edit-field">
            <popup @closeEvent="closeEvent" v-if="chooseEditFieldDialog">
                <div slot="popup-name">智能识别</div>
                <div slot="popup-tip">选择根据哪个字段的值识别</div>
                <div slot="popup-con" class="popup-con">
                    <div class="content-tree">
                        <div 
                            @click="checkItem(item,index)"
                            :class="['item',item.checked ? 'active' : '']" 
                            v-for="(item,index) in centerlistNews" 
                            :key="index">
                            {{item.name}}
                            <span class="iconfont el-icon-check"></span>
                        </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="closeEvent" size="small" plain>取 消</el-button>
                    <el-button type="primary" @click="saveBtn" size="small">确 定</el-button>
                </div>
            </popup>
        </div>
    </div>
</template>

<script>
import Popup from "../../../../components/popup.vue";
export default {
    data(){
        return {
            value:[],
            chooseEditFieldDialog:false,
            centerlistNews:[],
            activeId:'',
            checkedList:[],
        }
    },
    components:{
        Popup
    },
    props:{
        activeObj:{
			type:Object,
			default(){
				return{}
			}
		},
        centerlist:{
            type:Array,
            default(){
                return []
            }
        }
    },
    computed:{
        activeObjs(){
            console.log(this.activeObj,'当前的');
            return this.activeObj;
        },
    },
    watch:{
        centerlist:{
            handler(n,o){
                console.log(n,'nnnnnnn------智能识别');
                this.centerlistNews = [];
                let copy = JSON.parse(JSON.stringify(n));
                let field = [];
                copy.forEach(item => {
                    // 分组
                    if(item.field){
                        field = [...field,...item.field.allFields];
                    } else {
                        // 公有字段
                        if(item.widgetId !== this.activeObj.widgetId){
                            item.checked = false;
                            this.centerlistNews.push(item);
                        }
                    }
                })
                field.forEach((item,index) => {
                  console.debug('item',item)
                    if(item.field.widgetId !== this.activeObj.widgetId){
                        let obj = JSON.parse(JSON.stringify(item.field));
                        obj.checked = false;
                        this.centerlistNews.push(obj);
                    }
                })
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        // 删除智能识别
        delChecked(item,index){
            this.checkedList.splice(index,1)
        },
        // 打开弹框
        showPopup(){
            if(this.centerlistNews.length === 0){
                this.$message.error('请先添加控件');
            } else {
                console.log(this.checkedList,'回显的');
                this.centerlistNews.forEach((item,index) => {
                    this.checkedList.forEach((v,i) => {
                        if(v.widgetId === item.widgetId){
                            item.checked = true;
                        } 
                    })
                })
                this.chooseEditFieldDialog = true;
            }
        },
        checkItem(item,index){
            // this.activeId = item.widgetId;
            item.checked = !item.checked;

        },
        closeEvent(){
            this.chooseEditFieldDialog = false;
        },
        saveBtn(){
            this.checkedList = []
            this.centerlistNews.forEach(item => {
                if(item.checked){
                    this.checkedList.push(item);
                }
            })
            this.chooseEditFieldDialog = false;
            this.activeObjs.extInfo.autoInput.source = [];
            if(this.checkedList && this.checkedList.length > 0){
                this.checkedList.forEach(item => {
                    this.activeObjs.extInfo.autoInput.source.push(item.widgetId)
                })
            }
            // this.$emit('chooseAiDIstinguish',this.list)
        }
    }
}
</script>

<style lang="less" scoped>
    .ai-distinguish{
        margin: 0 0 20px 0;
        border-top: 1px dotted #E0E6F7;
        border-bottom: 1px dotted #E0E6F7;
        .switch-top{
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #000;
            height: 38px;
            background: #FBFCFD;
            border-radius: 5px;
            padding-left: 10px;
            .left{
                display: flex;
                align-items: center;
                .iconfont{
                    width: 22px;
                    height: 22px;
                    background: #366AFF;
                    border-radius: 50%;
                    margin-right: 5px;
                    color: #fff;
                    text-align: center;
                    line-height: 22px;
                }
            }
            .right{
               color: #A9B3C6; 
               display: flex;
               align-items: center;
               .switch-icon-box{
                   margin-left: 10px;
                    /deep/ .el-switch{
                        .el-switch__core{
                            height: 23px;
                            border-radius: 12px;
                        }
                        .el-switch__core:after{
                            width: 14px;
                            height: 14px;
                            left: 5px;
                            top: 3px;
                        }
                        .el-switch__label--left{
                            i{
                                color: #6E6B7B;
                                font-weight: 800;
                            }
                            position: absolute;
                            left: 25px;
                            top:0px;
                            z-index: 2;
                        
                        }
                        .el-icon-check{
                            color: #fff;
                            font-weight: 800;
                            position: absolute;
                        }
                    
                        &.is-checked{
                            .el-switch__core:after{
                                left: 40px;
                                top: 3px;
                            }
                            .el-icon-check{
                                position: absolute;
                                left: 5px;
                                top: 2px;
                                z-index: 2;
                                color: #fff;
                                font-weight: 800;
                            }
                            .el-icon-close{
                                color: #fff;
                            }
                        }
                            
                    }
                }
            }
        }
        .middle-tips{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #616161;
            line-height: 35px;
            // margin-bottom: 10px;
            span:last-child{
                color: #A9B3C6;
            }
        }
        .bottom-input{
            margin-bottom: 20px;
			height: 38px;
			width:100%;
			border-radius: 5px;
			border:1px solid #dcdfe6;
			cursor: pointer;
			display: flex;
			align-items: center;
			.placeholder-text{
				padding-left: 15px;
				color: #A9B3C6;
			}
			.client-value{
				padding: 5px;
				color: #606266;
				display: flex;
				// justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				line-height: 30px;
				width: 100%;
				.item{
					border:1px solid hsl(240, 5%, 92%);
					height: 24px;
					line-height: 24px;
					padding: 0 6px;
					position: relative;
					color: #fff;
					font-size: 13px;
					margin: 2px 0 2px 6px;
                    height: 24px;
                    background: #366AFF;
                    border-radius: 3px;
                    cursor: pointer;
					.el-icon-close{
						margin-left: 4px;
						cursor: pointer;
					}
				}
			}
        }
        .choose-edit-field{
            /deep/ #popup-assembly{
                width: 646px !important;
                .content-tree{
                    // background: #FBFCFD;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    padding: 20px;
                    height: 300px;
                    overflow-y: auto;
                    //  /*定义滚动条轨道 内阴影+圆角*/
                    // &::-webkit-scrollbar-track {
                    //     box-shadow: inset 0 0 6px transparent;
                    //     border-radius: 10px;
                    //     background-color:  #E7ECF6;
                    // }

                    // /*定义滚动条高宽及背景*/
                    // &::-webkit-scrollbar {
                    //     width: 5px;
                    // }

                    // /*定义滚动条滑块*/
                    // &::-webkit-scrollbar-thumb {
                    //     background-color: #366AFF;
                    //     border-radius: 3px;
                    // }
                   .item{
                        font-size: 14px;
                        color: #616161;
                        line-height: 38px;
                        padding: 0 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        .iconfont{
                            display: none;
                        }
                        &:hover{
                            background: #F5F7FB;
                        }
                        &.active .iconfont{
                            display: block;
                        }
                   }
                }   
            }
        }
    }
</style>