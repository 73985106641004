<template>
  <div class="entrance-image">
    <template v-if="activeNav === 'entranceImage'">
      <div class="entrance-content">
        <div class="title">
          {{$t('channel.entranceImage')}}
          <div class="bottom-save">
            <el-button
                size="medium"
                round
                type="primary"
                @click="save"
                icon="guoran-tongyichicun-16-03-baocun iconfont"
            >{{$t('common.save')}}
            </el-button>
          </div>
        </div>
        <div class="static-img">
          <el-upload
              class="avatar-uploader"
              accept=".jpg,.png,.jpeg,.gif"
              :action="requestUrl.channel.uploadFile"
              :show-file-list="false"
              :on-success="(response,file,fileList)=>handleAvatarSuccess(response,file,fileList,'entrance')"
              :before-upload="beforeAvatarUpload">
            <span v-if="!isShowUpload" class="nav_icon_view" @mouseenter="mouseenter"
                @mouseleave="mouseleave">
              <svgOrIcon
                  :icon="entranceIcon"
                  iconClass="iconfont"
              ></svgOrIcon>
              <span class="avatar-uploader-icon" v-show="isShowMark && entranceIcon !== ''">{{$t('channel.entranceImagePage.upload')}}</span>
            </span>
            <div v-if="isShowUpload" class="el-icon-plus avatar-uploader-icon"></div>
          </el-upload>
          <div class="right-text">
            <div class="top-text">{{$t('channel.entranceImagePage.title')}}</div>
            <div class="bottom-text">{{$t('channel.entranceImagePage.description')}}</div>
          </div>
        </div>
        <div class="hover-img">
          <el-upload
              class="avatar-uploader"
              accept=".jpg,.png,.jpeg,.gif"
              :action="requestUrl.channel.uploadFile"
              :show-file-list="false"
              :on-success="(response,file,fileList)=>handleAvatarSuccess(response,file,fileList,'entrance-hover')"
              :before-upload="beforeAvatarUpload">
            <span v-if="!isShowUploadHover" class="nav_icon_view"
                  @mouseenter="mouseenter('entrance-hover')"
                  @mouseleave="mouseleave('entrance-hover')">
              <svgOrIcon
                  :icon="hoverEntranceIcon"
                  iconClass="iconfont"
              ></svgOrIcon>
              <span class="avatar-uploader-icon"
                    v-show="isShowMarkHover && hoverEntranceIcon !== ''">{{$t('channel.entranceImagePage.upload')}}</span>
            </span>
            <div v-if="isShowUploadHover" class="el-icon-plus avatar-uploader-icon"></div>
          </el-upload>
          <div class="right-text">
            <div class="top-text">{{$t('channel.entranceImagePage.hoverTitle')}}</div>
            <div class="bottom-text">{{$t('channel.entranceImagePage.hoverDescription')}}</div>
          </div>
        </div>
        <div class="img-list">
          <div :class="['img-box-item',item.checked ? 'checked' : '']" v-for="(item,index) in defaultAgentLogoList"
               :key="item.id" @click="chooseDefaultImg(item,index)">
            <el-image
                :src="item.agentLogoUrl"
                :fit="'cover'">
            </el-image>
            <div class="triangle-bottomright" v-if="item.checked"></div>
            <i class="el-icon-check" v-if="item.checked"></i>
          </div>
        </div>
        <div class="entrance-init-position">
          <div class="right-text">
            <div class="top-text">{{$t('channel.entranceImagePage.initialPosition')}}</div>
            <div class="bottom-text">{{$t('channel.entranceImagePage.initialPositionDescription')}}</div>
          </div>
        </div>
        <div class="entrance-size">
          <div class="right-text">
            <div class="top-text">{{$t('channel.entranceImagePage.imageSize')}}</div>
            <div class="bottom-text">
              <div>{{$t('channel.entranceImagePage.width')}}
                <el-input v-model.trim.number="width" :placeholder="$t('common.placeholder')" @input="changeSize($event,'width')"><i
                    slot="suffix" style="font-style:normal;">px</i></el-input>
              </div>
              <div>{{$t('channel.entranceImagePage.height')}}
                <el-input v-model.trim.number="height" :placeholder="$t('common.placeholder')" @input="changeSize($event,'height')"><i
                    slot="suffix" style="font-style:normal;">px</i></el-input>
              </div>
            </div>
          </div>
        </div>
        <div
            @mouseenter="mouseenterWebImg"
            @mouseleave="mouseleaverWebImg"
            class="entrance-move-img"
            :style="{width:width+'px',height:height+'px',right:xcoordinate+'px',bottom:ycoordinate+'px',backgroundImage:'url(' + entranceIcon + ')',backgroundSize:width+'px' + ' ' + height+'px'}">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="app-content">
        <div class="left-content">
          <div class="title">
            {{$t('channel.entranceImagePage.appTitle')}}
            <div class="bottom-save">
              <el-button
                  size="medium"
                  round
                  type="primary"
                  @click="save"
                  icon="guoran-tongyichicun-16-03-baocun iconfont"
              >{{$t('common.save')}}</el-button>
            </div>
          </div>
          <div class="first-img">
            <el-upload
                accept=".jpg,.png,.jpeg,.gif,.svg"
                class="avatar-uploader"
                :action="requestUrl.channel.uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccessApp"
                :before-upload="beforeAvatarUploadApp">
              <span v-if="!isShowUpload" class="nav_icon_view" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <svgOrIcon
                    :icon="appIcon"
                    iconClass="iconfont"
                ></svgOrIcon>
                <span class="avatar-uploader-icon" v-show="isShowMark && appIcon !== ''">{{$t('channel.entranceImagePage.upload')}}</span>
              </span>
              <div v-if="isShowUpload" class="el-icon-plus avatar-uploader-icon"></div>
            </el-upload>
            <div class="app-text">
              {{$t('channel.entranceImagePage.appDescription')}}
              <a href="https://www.iconfont.cn/" target="_blank">iconfont</a>
              {{$t('channel.entranceImagePage.appDescription2')}}
            </div>
          </div>
          <div class="img-list">
            <div :class="['img-box-item',item.checked ? 'checked' : '']"  v-for="(item,index) in defaultAgentLogoList" :key="item.id" @click="chooseDefaultImgApp(item,index)">
              <el-image
                  :src="item.agentLogoUrl"
                  :fit="'cover'">
              </el-image>
              <div class="triangle-bottomright" v-if="item.checked"></div>
              <i class="el-icon-check" v-if="item.checked"></i>
            </div>
          </div>
        </div>
        <div class="right-phone">
          <div class="guess-qa">
            <svgOrIcon
                class="bot-icon"
                :icon="appIcon"
                :isSpecial="appIcon.indexOf('2.png')!==-1 || appIcon.indexOf('5.png')!==-1|| appIcon.indexOf('6.png')!==-1"
                iconClass="iconfont"
                type="app"
                :theDefault="checkedImg.theDefault ? checkedImg.theDefault : false"
            ></svgOrIcon>
            <div class="right-content">
              <div class="right-list">
                <div class="jiao-recommend"></div>
                <div class="text">{{$t('channel.entranceImagePage.greeting')}}</div>
              </div>
            </div>
          </div>
          <div class="bottom-config">
            <div class="bottom-config-set">
              <div class="speak iconfont guoran-arsenalchess-pawn-solid1"></div>
              <div class="input">{{$t('channel.entranceImagePage.speak')}}</div>
              <div class="add-btn iconfont guoran-tongyichicun-16-13-xinjian"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SvgOrIcon from "./svgOrIcon";
export default {
  name: "entranceImage",
  components: { SvgOrIcon },
  data(){
    return{
      isShowUpload:false,
      isShowUploadHover:false,
      defaultAgentLogoList:[],
      agentLogoCopy:"[]",
      width:45,
      height:45,
      appIcon:'',
      entranceIcon:'',
      hoverEntranceIcon:'',
      isShowMark:false,
      xcoordinate:100,
      ycoordinate:100,
      checkedImg:{},
      hoverCheckedImg:{},
      id:null,
      isShowMarkHover:false,
      isMove:false,
      logoDetail:{}
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    },
    activeNav:{
      type:String,
      default:"entranceImage"
    }
  },
  methods:{
    getInfo(){
      this.$http.get(this.requestUrl.bot.selectPlatformLogoSetByPlatformId+'?platformId='+ this.bindDetail.id).then(res => {
        if (res.data.code === "0") {
          console.log(res,9999999);
          let data = res.data.data;
          this.logoDetail = data;
          this.id = data.id;
          this.appIcon = data.agentLogoUrl;
          this.entranceIcon = data.silentLogoUrl;
          this.hoverEntranceIcon = data.suspendedLogoUrl;
          this.xcoordinate = data.xcoordinate;
          this.ycoordinate = data.ycoordinate;
          this.width = data.entranceLogoWidth;
          this.height = data.entranceLogoHigh;
          this.getDefaultAgentLogo();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取默认应用内头像/默认网站接入形像, type=1应用内, type=2网站接入
    getDefaultAgentLogo(){
      let type = this.activeNav === 'entranceImage' ? 2 : 1
      this.$http.get(this.requestUrl.bot.selectDefaultAgentLogo+'?type=' + type).then(res => {
        if (res.data.code === "0" && res.data.data) {
          res.data.data.forEach((item,index) => {
            item.checked = false;
          })
          this.defaultAgentLogoList = res.data.data;
          this.agentLogoCopy = JSON.stringify(res.data.data);
          this.defaultAgentLogoList.forEach((item,index) => {
            if(item.agentLogoUrl.indexOf('.svg') !== -1){
              item.theDefault = false;
            }
            if(type === 2){
              if(item.agentLogoUrl === this.entranceIcon){
                this.checkedImg = item;
                item.checked = true;
              } else {
                this.checkedImg.theDefault = false;
                item.checked = false;
              }
              if(item.agentLogoUrl === this.hoverEntranceIcon){
                this.hoverCheckedImg = item;
                item.checked = true;
              } else {
                this.hoverCheckedImg.theDefault = false;
                item.checked = false;
              }
            } else {
              if(item.agentLogoUrl === this.appIcon){
                this.checkedImg = item;
                item.checked = true;
              } else {
                this.checkedImg.theDefault = false;
                item.checked = false;
              }
            }
          })
          console.log(this.checkedImg,'checkedImg');
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    initAgentLogo(){
      let type = this.activeNav === 'entranceImage' ? 2 : 1
      let agentLogo = JSON.parse(this.agentLogoCopy);
      this.defaultAgentLogoList = agentLogo;
      this.defaultAgentLogoList.forEach(item => {
        item.checked = false;
        if(type === 2){
          if(item.agentLogoUrl === this.entranceIcon){
            this.checkedImg = item;
            item.checked = true;
          } else {
            this.checkedImg.theDefault = false;
            item.checked = false;
          }
          if(item.agentLogoUrl === this.hoverEntranceIcon){
            this.hoverCheckedImg = item;
            item.checked = true;
          } else {
            this.hoverCheckedImg.theDefault = false;
            item.checked = false;
          }
        } else {
          if(item.agentLogoUrl === this.appIcon){
            this.checkedImg = item;
            item.checked = true;
          } else {
            this.checkedImg.theDefault = false;
            item.checked = false;
          }
        }
      })
      console.log('defaultAgentLogoList',this.defaultAgentLogoList,agentLogo)
    },
    save(){
      let params = {}
      if(this.activeNav === 'app'){
        params = {
          "agentLogoUrl": this.appIcon,
          "platformId": this.bindDetail.id,
          "bindCode":this.$route.query.bindCode
        }
      } else {
        let dom = document.querySelector('.entrance-move-img');
        let windowWidth = document.documentElement.clientWidth;
        let windowHeight = document.documentElement.clientHeight;
        let xcoordinate = windowWidth - dom.getBoundingClientRect().right;
        let ycoordinate = windowHeight - dom.getBoundingClientRect().bottom;
        console.log(dom.getBoundingClientRect());
        console.log(xcoordinate, ycoordinate);
        params = {
          "bindCode":this.$route.query.bindCode,
          "suspendedLogoUrl":this.hoverEntranceIcon,
          "silentLogoUrl": this.entranceIcon,
          "entranceLogoHigh": this.height,
          "entranceLogoWidth": this.width,
          "platformId": this.bindDetail.id,
          "xcoordinate": xcoordinate < 0 ? 0 : xcoordinate,
          "ycoordinate": ycoordinate < 0 ? 0 : ycoordinate,
        }
      }
      params.id = this.bindDetail.id;
      this.$http.post(this.requestUrl.bot.savePlatformLogoSet,params).then(res => {
        if (res.data.code === "0") {
          let tips = this.activeNav === 'app' ? this.$t('channel.entranceImagePage.appTitle') : this.$t('channel.entranceImage')
          this.$message.success(tips + this.$t('channel.entranceImagePage.setSuccess'));
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleAvatarSuccess(response,file,fileList,type) {
      console.log(response,file,fileList,type);
      if(type === 'entrance'){
        this.entranceIcon = response.data;
        console.log(this.entranceIcon,2222);
        this.isShowUpload = false;
      } else {
        this.hoverEntranceIcon = response.data;
        this.isShowUploadHover = false;
      }
      this.$forceUpdate()
    },
    beforeAvatarUpload(file) {
      if (file) {
        if (!this.getFileSuffix(file.name, ["png", "jpg", "jpeg",'gif'])) {
          this.$message.error(this.$t('channel.entranceImagePage.imageTypeMsg'));
          return false;
        }
      }
    },
    mouseenter(type){
      if(type && type === 'entrance-hover'){
        this.isShowMarkHover = true;
      } else {
        this.isShowMark = true;
      }
    },
    mouseleave(type){
      if(type && type === 'entrance-hover'){
        this.isShowMarkHover = false;
      } else {
        this.isShowMark = false;
      }
    },
    getFileSuffix(url,arr,isGetName) {
      let index1 = url.lastIndexOf(".")+1;
      let index2 = url.length;
      let postf = url.substring(index1, index2);//后缀名
      if (isGetName) {
        return postf;
      } else {
        if (!arr || arr.indexOf(postf) == -1) {
          return false;
        } else {
          return true;
        }
      }
    },
    chooseDefaultImg(item,index){
      this.defaultAgentLogoList.forEach((v,i) => {
        v.checked = false;
      })
      item.checked = true;
      this.isShowMark = false;
      if(item.checked){
        this.checkedImg = item;
        this.hoverEntranceIcon = this.entranceIcon = item.agentLogoUrl;
        this.isShowUpload = false;
      } else {
        this.entranceIcon = '';
        this.checkedImg = {};
      }
      console.log(this.checkedImg);
    },
    // 入口形象大小改变
    changeSize(value,type){
      console.log(type);
      if(Number(value) > 200){
        this.$message.warning(this.$t('channel.entranceImagePage.sizeMsg'));
        if(type === 'width'){
          this.width = 200;
        } else {
          this.height = 200;
        }
      }
    },
    mouseenterWebImg(){
      document.querySelector('.entrance-move-img').style.backgroundImage = "url(" + this.hoverEntranceIcon + ")";
    },
    mouseleaverWebImg(){
      document.querySelector('.entrance-move-img').style.backgroundImage = "url(" + this.entranceIcon + ")";
    },
    handleAvatarSuccessApp(res) {
      this.appIcon = res.data;
      this.isShowUpload = false;
      this.$forceUpdate()
    },
    beforeAvatarUploadApp(file){
      if (!this.getFileSuffix(file.name, ["png", "jpg", "jpeg",'gif'])) {
        this.$message.error(this.$t('channel.entranceImagePage.imageTypeMsg'));
        return false;
      }
    },

    // app
    chooseDefaultImgApp(item,index){
      this.defaultAgentLogoList.forEach((v,i) => {
        v.checked = false;
      })
      item.checked = true;
      this.isShowMark = false;
      if(item.checked){
        this.checkedImg = item;
        this.appIcon = item.agentLogoUrl;
        this.isShowUpload = false;
      } else {
        this.appIcon = '';
        this.checkedImg = {};
      }
      console.log(this.checkedImg);
    },
    //拖拽功能
    dragFn(eg) {
      let that = this;
      eg.onmousedown =  (ev) => {
        let e = ev || window.event;
        let eX = e.clientX;
        let eY = e.clientY;
        let disX = eX - eg.offsetLeft;
        let disY = eY - eg.offsetTop;
        document.onmousemove =  (ev) => {
          this.isMove = true;
          let e = ev || window.event;
          let l = e.clientX - disX;
          let h = e.clientY - disY;
          let windowWidth = document.documentElement.clientWidth;
          let windowHeight = document.documentElement.clientHeight;
          l = that.range(l, 0, windowWidth - eg.offsetWidth);
          h = that.range(h, 0, windowHeight - eg.offsetHeight);
          // eg.style.left = `${l}px`;
          // eg.style.top = `${h}px`;
          eg.style.right = `${windowWidth-l-eg.offsetWidth}px`;
          eg.style.bottom = `${windowHeight-h-eg.offsetHeight}px`;
          console.log(windowWidth-l-eg.offsetWidth,windowHeight-h-eg.offsetHeight);
        }
      }
      document.onmouseup =  () => {
        document.onmousemove = null;
        setTimeout(() => {
          this.isMove = false;
        }, 300)
      }
    },
    //判断边界问题
    range(loc, min, max) {
      if (loc > max) {
        return max;
      } else if (loc < min) {
        return min;
      } else {
        return loc;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.getInfo();
    })
  },
  watch:{
    activeNav:{
      handler(){
        if (this.activeNav === 'entranceImage'){
          setTimeout(() => {
            this.dragFn(document.querySelector('.entrance-move-img'));
          },500)
          // this.entranceIcon = this.logoDetail.silentLogoUrl;
          // this.hoverEntranceIcon = this.logoDetail.suspendedLogoUrl;
        } else {
          // this.entranceIcon = this.logoDetail.agentLogoUrl;
        }
        this.initAgentLogo();
      },
      immediate:false
    },
    bindDetail:{
      handler(){
        if (this.bindDetail.id){
          this.getInfo();
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.entrance-image {
  display: flex;
  overflow-y: auto;
  //margin-left: 12px;
  height: 100%;
  box-sizing: border-box;
  .entrance-content {
    flex: 1;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 5px;
    position: relative;
    text-align: left;
    min-width: 600px;
    //padding-right: 20px;
    padding: 20px;
    .entrance-init-position {
      height: 40px;
      background: #FAFBFF;
      border: 1px solid #E0E6F7;
      border-radius: 5px;
      padding: 25px 20px 32px;
      margin-bottom: 26px;

      .right-text {
        height: auto;
      }
    }

    .entrance-size {
      height: 70px;
      background: #FAFBFF;
      border-radius: 5px;
      padding: 25px 20px 17px;

      .right-text {
        height: auto;
      }

      .bottom-text {
        display: flex;
        color: #616161;
        flex-wrap: nowrap;

        > div {
          display: flex;
          color: #616161;
          flex-wrap: nowrap;
          align-items: center;

          &:first-child {
            margin-right: 40px;
          }

        }

        /deep/ .el-input {
          height: 38px;
          width: 150px;
          margin: 0 18px 0 10px;

          .el-input__inner {
            height: 38px;
          }

          .el-input__suffix {
            color: #000000;
            font-size: 14px;
            top: 7px;
            right: 10px;
          }
        }
      }
    }

    .entrance-move-img {
      // left: calc(100% - 100px);
      // top: calc(100% - 100px);
      position: fixed;
      cursor: pointer;
      background-repeat: no-repeat;
      z-index: 99999999999
    }
  }

  .app-content {
    text-align: left;
    display: flex;
    height: 100%;
    width: 100%;

    .left-content {
      flex: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 5px;
      position: relative;
      //padding-right: 20px;
      padding: 20px;
      box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      .app-text {
        font-size: 13px;
        color: #A9B3C6;

        a {
          color: #366AFF;
          cursor: pointer;
        }
      }
    }

    .right-phone {
      position: relative;
      width: 292px;
      height: 619px;
      background: url(../../../assets/images/phone-example.png);
      margin-left: 12px;

      .guess-qa {
        margin-top: 25px;
        display: flex;
        position: absolute;
        top: 90px;
        left: 10px;

        .bot-icon {
          text-align: center;
          line-height: 33px;
          color: #366AFF;
          font-size: 32px;
          margin-right: 15px;
          box-sizing: border-box;
        }

        .right-content {
          .right-list {
            position: relative;
            width: 224px;
            background: #F5F7FB;
            border: 1px solid #E9ECF2;
            border-radius: 0 40px 40px 40px;

            .jiao-recommend {
              position: absolute;
              top: -1px;
              left: -14px;
              width: 0;
              height: 0;
              border-top: 15px solid #F5F7FB;
              border-left: 15px solid transparent;
            }

            .text {
              padding: 0 15px;
              line-height: 40px;
              height: 44px;
              font-size: 14px;
              color: #222222;
              text-align: left;
              display: -webkit-box;
              /*设置为弹性盒子*/
              -webkit-line-clamp: 1;
              /*最多显示3行*/
              overflow: hidden;
              /*超出隐藏*/
              text-overflow: ellipsis;
              /*超出显示为省略号*/
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }

      .bottom-config {
        position: absolute;
        bottom: 0;
        width: 276px;
        // height: 72px;
        background: #EEF1F7;
        padding: 8px;

        .bottom-config-set {
          display: flex;
          align-items: center;
          margin-top: 6px;
          height: 30px;
          // justify-content: space-between;
          padding: 0 10px;

          .speak {
            color: #81A2FF;
            font-size: 20px;
            width: 28px;
            height: 28px;
            border: 2px solid #81A2FF;
            border-radius: 50%;
            text-align: center;
            line-height: 28px;
          }

          .input {
            width: 180px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 15px;
            font-size: 12px;
            font-weight: 500;
            color: #999999;
            padding-left: 10px;
            text-align: left;
            line-height: 30px;
            margin: 0 8px;
          }

          .guoran-tongyichicun-16-13-xinjian {
            width: 28px;
            height: 28px;
            background: #366AFF;
            border: 1px solid #E4E7EB;
            border-radius: 50%;
            color: #FFFFFF;
            font-size: 12px;
            text-align: center;
            line-height: 28px
          }
        }
      }
    }
  }

  .title {
    font-size: 16px;
    color: #000000;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .first-img, .static-img, .hover-img {
    height: 100px;
    background: #FBFCFD;
    border-radius: 5px;
    display: flex;
    padding: 14px;
    align-items: center;

    .avatar-uploader {
      width: 67px;
      height: 67px;
      border: 1px solid #A9BFFF;
      text-align: center;
      line-height: 96px;
      font-weight: 600;
      color: #87A9FF;
      cursor: pointer;
      width: 97px;
      height: 96px;
      background: #FAFBFF;
      border: 1px solid #E0E6F7;
      border-radius: 5px;
      margin-right: 16px;
      position: relative;

      .el-icon-plus {
        font-size: 22px;
      }

      .avatar-uploader-icon {
        font-size: 14px;
        color: #fff;
        position: absolute;
        width: 97px;
        height: 96px;
        background: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    &.static-img {
      margin: 20px 0;
    }

    .nav_icon_view {
      width: 97px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      // background: #1D2E54;
      border-radius: 5px;
      // color: #FFFFFF;
      margin-right: 10px;

      .plus {
        font-size: 16px;
        font-weight: 900;
      }
    }
  }

  .right-text {
    display: flex;
    flex-direction: column;
    height: 96px;
    justify-content: center;
    flex: 1;

    .top-text {
      margin-bottom: 12px;
      color: #000000;
    }

    .bottom-text {
      color: #A9B3C6;
      font-size: 13px;
    }
  }

  .img-list {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 14px;

    .img-box-item {
      margin-right: 25px;
      cursor: pointer;
      border: 2px solid #fff;
      width: 103px;
      height: 102px;
      margin-bottom: 10px;
      position: relative;

      .el-image {
        width: 103px;
        height: 102px;
      }

      &.checked {
        border: 2px solid #366AFF;

        .triangle-bottomright {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-bottom: 31px solid #366AFF;
          border-left: 31px solid transparent;

        }

        .el-icon-check {
          color: #fff;
          position: absolute;
          right: 2px;
          bottom: 2px;
        }
      }
    }
  }

  .bottom-save {
    display: flex;

    /deep/ .el-button {
      height: 26px;
      padding: 0 14px;
      background: #366AFF;
      border-radius: 17px;
      font-size: 12px;

      i {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
}
</style>