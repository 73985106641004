<template>
    <div class="delDeptHasMember">
         <popup @closeEvent="closeEvent" v-if="delDeptHasMemberPopup">
           <div slot="popup-name" class="popup-name">{{isBatchDel ? $t('delDeptHasMember.batchTitle') : $t('delDeptHasMember.title')}}</div>
                <div slot="popup-tip">{{$t('delDeptHasMember.tip')}}</div>
            <div slot="popup-con">
                  <el-form  ref="ruleForms" label-position="top" label-width="80px" class="demo-ruleForm">
                    <el-form-item :label="$t('delDeptHasMember.formItem')" >
                        <el-radio v-model="radio" label="remove">{{$t('delDeptHasMember.formTip')}}</el-radio>
                        <el-radio v-model="radio" label="del">{{$t('delDeptHasMember.formTip1')}}</el-radio>
                    </el-form-item>
                    <el-form-item :label="$t('delDeptHasMember.formItem')" v-if="radio === 'remove'">
                        <el-tree
                            key="chooseDept"
                            ref="chooseDept"
                            :data="treeDataCopy"
                            @node-click="onNodeClick"
                            :expand-on-click-node="false"
                            :default-expand-all="true">
                                <span
                                        class="custom-tree-node"
                                        slot-scope="{node,data}">
                                    <span class="custom-tree-node-left">
                                        <span>
                                            <i class="arsenal_icon arsenalsitemap1"></i>
                                        </span>
                                        <span class="data-label" >{{data.name}}</span>
                                    </span>
                                    <span v-show="data.checked">
                                        <i class="el-icon-check"></i>
                                    </span>
                                </span>
                        </el-tree>
                    </el-form-item>
                    <div class="del-tips" v-else>
                        {{$t('delDeptHasMember.formTip2')}}
                    </div>
                  </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm" round >{{$t('common.delete')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
import mixins  from '../mixins'  
export default {
	mixins:[mixins],
    components:{
        Popup
    },
    data(){
        return{
            radio:'remove',
            moveStreamId:null,
            isMultiple:false,
            checkedDeptPopup:[],
            treeDataCopy:[]
        }
    },
    props:{
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
        isBatchDel:{
            type:Boolean,
            default:false
        },
        delDeptHasMemberPopup:{
            type:Boolean,
            default:false
        },
        delDeptHasMemberList:{
            type:Array,
            default(){
                return []
            }
        }
    },
    watch:{
        delDeptHasMemberPopup:{
            handler(n){
                if(n){
                    console.log(this.delDeptHasMemberList,11111111);
                    this.radio = 'remove';
                    this.treeDataCopy = this.handlerChildrens(JSON.parse(JSON.stringify(this.treeData)),this.delDeptHasMemberList,this.treeDataCopy,'delDept');
                    console.log(this.treeDataCopy,'处理过的tree');
               } 
            },
            // immediate:true
        },
        // treeData:{
        //     handler(n){
        //         this.treeDataCopy = this.handlerChildrens(JSON.parse(JSON.stringify(n)));
        //     },
        //     deep:true
        // }
    },
    methods:{
        // 点击tree
		onNodeClick(data,node,el) {
            let list = JSON.parse(JSON.stringify(this.treeDataCopy))
			this.getAllNodeId(list,data);
            this.treeDataCopy = JSON.parse(JSON.stringify(list))
		},
        closeEvent(){
            this.$emit('update:delDeptHasMemberPopup',false)
        },
        submitForm(){
            console.log(this.treeDataCopy);
            this.judgeChildrens(this.treeDataCopy);
            console.log(this.checkedDeptPopup,'99999999');
            if(this.checkedDeptPopup.length === 0 && this.radio === 'remove'){
                this.$message.error(this.$t('delDeptHasMember.deleteDeptMsg'))
				return false;
            } else {
                let url = ''
                url = this.radio === 'remove' ? this.requestUrl.upAndDown.deleteUpAndDownStreamById + '?moveStreamId=' + this.checkedDeptPopup[0].id : this.requestUrl.upAndDown.deleteUpAndDownStreamById
                this.$http.delete(url,{
                        data: this.delDeptHasMemberList
                    })
                    .then(res=>{
                        if (res.data.code === '0'){
                            this.$message({
                                message:this.$t('delDeptHasMember.deleteSuccess'),
                                type:'success',
                                duration:1000
                            })
                            this.$emit('delDeptSuccess')
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
            }
            
        }
    }

}
</script>

<style scoped lang="less">
    .delDeptHasMember{
         ::v-deep .el-form{
            .el-form-item{
                display: flex;
                flex-direction: column;
                 .el-radio__input.is-checked .el-radio__inner{
                    border-color: #366AFF;
                    background: #366AFF;            
                }
                .el-radio__input.is-checked+.el-radio__label{
                    color: #366AFF;
                }
            }
            .el-form-item__label{
                line-height: 20px;
                float: left !important;
                &.after{
                    clear: both;
                }
            }
            .del-tips{
                   height: 50px;
                    background: #FFF8F4;
                    border-radius: 5px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 700;
                    font-weight: 400;
                    color: #FF9555;
                    line-height: 20px;
                    text-align: left;
                    line-height: 50px;
                    padding: 0 10px;
            }
        }
    }
</style>