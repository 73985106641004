<template>
    <div class="invite-info">
        <div :class="['dept-box',needBgc == 'true' ? 'need-bgc' : '']">
            <div class="company">
                <div class="iconfont guoran-gongsiicon"></div>  
                <open-data  :openid="companyName" :type="'departmentName'"></open-data>
                <!-- <span v-else>{{companyName}}</span> -->
            </div>
            <div class="dept">
                <div></div>
                <span class="name">
                    <open-data :openid="departmentName" :type="'departmentName'"></open-data>
                    <!-- <span v-else>{{departmentName}}</span> -->
                </span>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import OpenData from "@/components/openData";
import agentConfig from "@/common/wechat/agentConfig";
export default {
    data(){
        return {
            companyName:"北京果然智汇科技有限公司",
            departmentName:"研发部门",
            source:2, // 0：企业微信 3：钉钉 10：飞书 ,
            needBgc:true,
            corpId:''
        }
    },
    components:{
        OpenData
    },
    beforeMount(){
        console.log(this.$route.query,'this.$route.query');
        this.corpId = this.$route.query.corpId;
        this.companyName = this.$route.query.companyName;
        this.departmentName = this.$route.query.departmentName;
        this.source = this.$route.query.source;
        this.needBgc = this.$route.query.needBgc;
        if(this.needBgc == 'true'){
            document.body.setAttribute('invite-info-need-bgc',true)
        } else {
            document.body.setAttribute('invite-info-data',true)
        }
        console.log(this.source,'source');
    },
    mounted(){
       this.initWxAgentConfig(this.corpId)
    },
    methods:{
        initWxAgentConfig(corpId){
            let currentUrl = encodeURIComponent(window.location.origin.indexOf('test') !== -1 ? 'https://portal.test.askbot.cn/#/inviteInfo' : 'https://portal.askbot.cn/#/inviteInfo')
            let url =  this.requestUrl.wechatKF.agentConfigNew + "?corpId="+corpId+ "&url="+currentUrl;
            this.$http.get(url).then(res=>{
                console.info('load init wx agent config ',corpId,res.data.data.data);
                if (res.data&&res.data.data){
                agentConfig(corpId,res.data.data.data,(res)=>{
                    console.info('agent auth config success',res);
                    WWOpenData.bind(this.$el);
                });
                }
            })
        },
    }

}
</script>
<style lang="less">
    body[invite-info-need-bgc=true]{
        background-color: #fff;
        #app{
            overflow: hidden;
        }
    }
     body[invite-info-data=true]{
        background-color: transparent;
        #app{
            overflow: hidden;
        }
    }
</style>
<style lang="less" scoped>
.invite-info{
    .dept-box{
        height: 54px;
        border-radius: 37px;
        padding: 10px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 23px;
        &.need-bgc{
            background: #F6F7FB;
        }
        .company{
            font-size: 17px;
            color: #000;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            .iconfont{
                width: 29px;
                height: 29px;
                line-height: 29px;
                background-color: #366AFF;
                border-radius: 50%;
                text-align: center;
                color: #fff;
                margin-right: 4px;
            }
        }
        .dept{
            display: flex;
            align-items: center;
            div{
                width: 16px;
                height: 1px;
                background-color: #CFCFCF;
            }
            .name{
                color: #8D8D8D;
                font-size: 14px;
                margin:  0 10px;
            }
        }
    }
}
</style>