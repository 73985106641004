<template>
    <div>
        <DepartmentTreeNode
            v-for="(node, index) in data"
            :node="node"
            :key="'str' + index"
            @onNodeClick="onNodeClick"
        >
            {{ node }}
        </DepartmentTreeNode>
    </div>
</template>
<script>
import DepartmentTreeNode from "./DepartmentTreeNode";

export default {
    name: "DepartmentTree",
    components: { DepartmentTreeNode },
    data() {
        return {};
    },
    props: {
        data: Array,
    },
    methods: {
        onNodeClick(node) {
            this.$emit("onNodeClick", node);
        },
    },
};
</script>