let capacityOption = [{
        value: '0GB',
        label: '0GB'
    },{
        value: '100MB',
        label: '100MB'
    },{
        value: '200MB',
        label: '200MB'
    },{
        value: '500MB',
        label: '500MB'
    },{
        value: '1GB',
        label: '1GB'
    },{
        value: '2GB',
        label: '2GB'
    },{
        value: '5GB',
        label: '5GB'
    },{
        value: '10GB',
        label: '10GB'
    },{
        value: '-1',
        label: '无限制'
    },{
        value: '-2',
        label: '自定义'
    }
]
let flowOption = [{
        value: '-3',
        label: '不可用'
    },{
        value: '100次',
        label: '100次'
    },{
        value: '100次/月',
        label: '100次/月'
    },{
        value: '200次',
        label: '200次'
    },{
        value: '200次/月',
        label: '200次/月'
    },{
        value: '500次',
        label: '500次'
    },{
        value: '500次/月',
        label: '500次/月'
    },{
        value: '1000次/月',
        label: '1000次/月'
    },{
        value: '1000次/月',
        label: '1000次/月'
    },{
        value: '-1',
        label: '无限制'
    },{
        value: '-2',
        label: '自定义'
    }
]
let capacityUnitOption = [{
        value: 'MB',
        label: 'MB'
    },{
        value: 'GB',
        label: 'GB'
    }
]
let flowUnitOption = [{
    value: '次',
    label: '次'
},{
    value: '次/月',
    label: '次/月'
}
]
//   时间戳转换成时间
function getDataTime(times){
    if (!times) {
        return '-'
    }
    let getBYT = (num) => {
        if(num == 0) {
            return '00'
        } else if(num < 10) {
            return '0' + num;
        } else {
            return num;
        }
    }
    let date = new Date(times); // 13位时间戳
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = getBYT(date.getDate() )+ ' ';
    let H = getBYT(date.getHours() )+ ':';
    let m = getBYT(date.getMinutes())
    return Y+M+D+H+m;
}
export { 
    capacityOption,
    flowOption,
    capacityUnitOption,
    flowUnitOption,
    getDataTime
}