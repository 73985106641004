<template>
    <div id="edit-nickname">
        <popup v-if="editDialog" @closeEvent="editDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('personal.editNickName')}}</div>
            <div slot="popup-tip">{{$t('personal.editNickNameTip')}}</div>
            <div slot="popup-con">
                <div class="form-item">
                    <input
                        type="text"
                        id="nick-name"
                        v-model="nickName"
                        maxlength="15"
                        show-word-limit
                        @input="changeNickName"
                        :placeholder="$t('personal.nickNamePlaceholder')"
                    />
                    <div class="gr-tips">{{ nickNameTips }}</div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="editDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="submitEdit"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
export default {
    data() {
        return {
            editDialog: false,
            nickName: "",
            nickNameTips: "",
        };
    },
    components: {
        Popup,
    },
    methods: {
        initShow(val) {
            this.editDialog = true;
            this.nickName = val;
            this.nickNameTips = "";
        },
        changeNickName() {
            if (this.nickName.trim() !== "") {
                this.nickNameTips = "";
            }
        },

        submitEdit() {
            if (this.nickName.trim() === "") {
                this.nickNameTips = this.$t('personal.nickNameNull');
                return false;
            }
            this.$emit('saveCustomerInfo', 'nickName', this.nickName);
            this.editDialog = false;
        },
    },
    watch:{
        editDialog(value){
            this.$emit('changeDialog',value)
        }
    }
};
</script>
<style lang="less" scoped>
.form-item {
    width: 100%;
    height: 79px;
    .gr-tips {
        color: #f56c6c;
        text-align: right;
        font-size: 11px;
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
    }
}
.form-item input {
    width: calc(100% - 30px);
    outline-style: none;
    border: 1px solid #e0e6f7;
    border-radius: 5px;
    padding: 10px 14px;
    font-size: 14px;
}

.form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
}
</style>