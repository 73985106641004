<template>
    <div class="set-detail" v-cloak>
        <div class="title">
            <span>{{pageType === 'capacity' ? $t('capacityFlowManage.capacityUsage') : $t('capacityFlowManage.trafficUsage')}}</span>
            <div class="right-operation">
                <el-badge :is-dot="detailInfo.companyPayAbility.isDot" class="item">
                    <el-button round icon="iconfont guoran-shenqing" @click="capacityOrFlowApply">{{pageType === 'capacity' ? $t('capacityFlowManage.capacityApplication') : $t('capacityFlowManage.trafficApplication')}}</el-button>
                </el-badge>
            </div>
        </div>
        <div :class="['use-card',pageType === 'capacity' ? 'capacity-card' : 'flow-card']">
            <div class="had-used">
                <div class="iconfont guoran-yishiyong"></div>
                <span>{{$t('capacityFlowManage.used')}}</span>
                <span class="num">{{handlerUnit(detailInfo.hadUsed,'num')}}</span>
                <span class="unit">{{handlerUnit(detailInfo.hadUsed,'unit')}}</span>
                <div class="look-detail" v-if="pageType === 'flow'" @click="lookUseDetail">{{$t('capacityFlowManage.viewDetails')}}</div>
            </div>
            <div :class="['can-use',detailInfo.proportionBoo ? 'use-up' : '' ]">
                <div class="iconfont guoran-keyongxing"></div>
                <span>{{$t('capacityFlowManage.available')}}</span>
                <span class="num">{{handlerUnit(detailInfo.canUse,'num')}}</span>
                <span class="unit">{{handlerUnit(detailInfo.canUse,'unit')}}</span>
            </div>
            <div class="total">
               <div class="top">
                    <div class="iconfont guoran-zongji"></div>
                    <span>{{$t('capacityFlowManage.total')}}</span>
                    <span class="num">{{handlerUnit(detailInfo.totalValue,'num')}}</span>
                    <span class="unit">{{handlerUnit(detailInfo.totalValue,'unit')}}</span>
                    <div style="padding-top:14px" v-if="pageType !== 'capacity'">
                        <div :class="['buy-capacity',detailInfo.proportionBoo ? 'proportion' : '']" @click="goBuy(pageType)">{{pageType === 'capacity' ? $t('capacityFlowManage.expansion') : $t('capacityFlowManage.purchaseFrequency')}}</div>
                        <div class="look-buy-detail" @click="lookBuyDetail">{{$t('capacityFlowManage.purchaseDetails')}}</div>
                    </div>
               </div>
            </div>
        </div>
        <div class="progress-box">
           <div class="progress-boxs">
               <template v-if="pageType === 'capacity'">
                   <!-- <el-tooltip v-if="pageType === 'capacity'" class="item" :open-delay="500" :hide-after="200" effect="dark" placement="bottom-end">
                        <div slot="content" class="tooltips-content-text">
                            {{'员工占用 : ' + handlerUnit(detailInfo.memberUsedCapacity,'num') + handlerUnit(detailInfo.memberUsedCapacity,'unit')}}
                            <br>
                            {{'员工占用 : ' + detailInfo.memberUsedCapacityPercentage + '%'}}
                        </div> -->
                        <div class="member-use"  :style="{width: detailInfo.memberUsedCapacityPercentage + '%'}">
                            <div :class="['circle',detailInfo.memberUsedCapacityPercentage === 1 || detailInfo.memberUsedCapacityPercentage < 5? 'min' : 'max']"></div>
                        </div>
                    <!-- </el-tooltip> -->
                    <!-- <el-tooltip v-if="pageType === 'capacity'" class="item" :open-delay="500" effect="dark"  placement="bottom-end">
                        <div slot="content" class="tooltips-content-text">
                            {{'企业占用 : ' + handlerUnit(detailInfo.companyUsedCapacity,'num') + handlerUnit(detailInfo.totalCapacity,'unit')}}
                            <br>
                            {{'企业占用 : ' + detailInfo.companyUsedCapacityPercentage + '%'}}
                        </div> -->
                        <div class="company-use" :style="{width: detailInfo.companyUsedCapacityPercentage + '%'}">
                            <div :class="['circle',detailInfo.companyUsedCapacityPercentage === 1 || detailInfo.companyUsedCapacityPercentage < 5 ? 'min' : 'max']"></div>
                        </div>
                    <!-- </el-tooltip> -->
                    <!-- <el-tooltip v-if="pageType === 'capacity'" class="item" :open-delay="500" effect="dark"  placement="bottom-end">
                        <div slot="content" class="tooltips-content-text">
                            {{'可用容量 : ' + handlerUnit(detailInfo.canUsedCapacity,'num') + handlerUnit(detailInfo.canUsedCapacity,'unit')}}
                            <br>
                            {{'可用容量 : ' + detailInfo.proportion + '%'}}
                        </div> -->
                        <div :class="['surplus-can-use',detailInfo.proportionBoo ? 'use-up' : 'max']" :style="{width: detailInfo.proportion + '%'}">
                            <div :class="['circle',detailInfo.proportion === 1 || detailInfo.proportion < 5 ? 'min' : 'max']"></div>
                        </div>
                    <!-- </el-tooltip> -->
               </template>
                <template v-else>
                    <!-- <el-tooltip v-if="pageType === 'flow'" class="item" :open-delay="500" :hide-after="200" effect="dark" placement="bottom-end">
                         <div slot="content" class="tooltips-content-text">
                            {{'员工使用 : ' + handlerUnit(detailInfo.memberUsedFlow,'num') + handlerUnit(detailInfo.memberUsedFlow,'unit')}}
                            <br>
                            {{'员工使用 : ' + detailInfo.memberUsedFlowPercentage + '%'}}
                        </div> -->
                        <div class="member-use flow"  :style="{width: detailInfo.memberUsedFlowPercentage + '%'}">
                            <div :class="['circle',detailInfo.memberUsedFlowPercentage === 1 || detailInfo.memberUsedFlowPercentage < 5 ? 'min' : 'max']"></div>
                        </div>
                    <!-- </el-tooltip> -->
                    <!-- <el-tooltip v-if="pageType === 'flow'" class="item" :open-delay="500" effect="dark"  placement="bottom-end">
                        <div slot="content" class="tooltips-content-text">
                            {{'可用次数 : ' + handlerUnit(detailInfo.totalFlow,'num') + handlerUnit(detailInfo.totalFlow,'unit')}}
                            <br>
                            {{'可用次数 : ' + detailInfo.memberUsedFlowPercentage + '%'}}
                        </div> -->
                        <div :class="['surplus-can-use flow',detailInfo.proportionBoo ? 'use-up' : '']" :style="{width: detailInfo.proportion + '%'}">
                           <div :class="['circle',detailInfo.proportion === 1 || detailInfo.proportion < 5 ? 'min' : 'max']"></div>
                        </div>
                    <!-- </el-tooltip> -->
                </template>
            </div>
            <div :class="['progress-tips',pageType === 'capacity' ? '' : 'flow']" >
                <div class="tips-item user"> <div></div> <span>{{pageType === 'capacity' ? $t('capacityFlowManage.employeeOccupation') : $t('capacityFlowManage.employeeUse')}}</span></div>
                <div class="tips-item company" v-if="pageType === 'capacity'"> <div></div> <span>{{$t('capacityFlowManage.enterpriseOccupation')}}</span></div>
                <div :class="['tips-item can-use',detailInfo.proportionBoo ? 'use-up' : '']"> <div></div> <span>{{pageType === 'capacity' ? $t('capacityFlowManage.availableCapacity') : $t('capacityFlowManage.availableTimes')}}</span></div>
            </div>
        </div>
        <div class="setting-box">
            <div v-if="pageType === 'capacity'" class="company-can-use">
                <span>{{$t('capacityFlowManage.knowledgeCanUse')}}</span>
                <div class="right-setting">
                    <el-select v-model="detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom"  @change="changeCompanyCustom" :placeholder="$t('common.selectPlaceholder')" size="small">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label === '无限制' ? $t('staffManage.unrestricted') : (item.label === '自定义' ? $t('common.custom') : item.label)"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <template v-if="detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom == -2">
                        <el-input v-model="detailInfo.companyPayAbility.companyKnowledgeCapacityView.value" type="number" :placeholder="$t('common.selectPlaceholder')" size="small"></el-input>
                        <el-select v-model="detailInfo.companyPayAbility.companyKnowledgeCapacityView.unit" :placeholder="$t('common.selectPlaceholder')" size="small">
                            <el-option
                                v-for="item in unitOptions"
                                :key="item.value"
                                :label="item.label === '无限制' ? $t('staffManage.unrestricted') : (item.label === '自定义' ? $t('common.custom') : item.label)"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="everybody-can-use">
                <div class="everybody-setting">
                    <span>{{$t('capacityFlowManage.availablePerPerson')}}</span>
                    <div class="right-setting">
                    <el-select v-model="detailInfo.companyPayAbility.companyUserView.custom" @change="changeCustom" :placeholder="$t('common.selectPlaceholder')" size="small">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label === '无限制' ? $t('staffManage.unrestricted') : (item.label === '自定义' ? $t('common.custom') : item.label)"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <template v-if="detailInfo.companyPayAbility.companyUserView.custom == -2">
                        <el-input v-model="detailInfo.companyPayAbility.companyUserView.value"  type="number" :placeholder="$t('common.selectPlaceholder')" size="small"></el-input>
                        <el-select v-model="detailInfo.companyPayAbility.companyUserView.unit" :placeholder="$t('common.selectPlaceholder')" size="small">
                            <el-option
                                v-for="item in unitOptions"
                                :key="item.value"
                                :label="item.label === '无限制' ? $t('staffManage.unrestricted') : (item.label === '自定义' ? $t('common.custom') : item.label)"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </div>
                </div>
                <el-table
                    :data="detailInfo.companySeparateConfiguration.tableData"
                    max-height="200"
                    :empty-text="$t('common.emptyData')"
                    class="table-box">
                    <el-table-column
                        width="300"
                        prop="name"
                        align="left"
                        class-name="user-name"
                        :label="$t('capacityFlowManage.deptOrMember')">
                        <template slot-scope="scope">
                            <!-- // 员工树结构的数据 type=0是员工，type=1是部门 -->
                            <div :class="[scope.row.type === 0 ? 'user' : 'dept']">
                                    <div class="user-icon">{{scope.row.name.slice(0,1)}}</div>
                                    <span>{{scope.row.name}}</span>
                            </div>
                            <!-- <div
                                    class="data-label"
                                    v-if="scope.row.isWeWorkThirdData ||
                                    scope.row.isWeWorkThirdData ||
                                    (scope.row.originData &&
                                    scope.row.originData.wechatBotDTO &&
                                    scope.row.originData.wechatBotDTO.bindType===0 &&
                                    scope.row.originData.wechatBotDTO.wechatType===0)">
                                <open-data :type="scope.row.type == 1 ? 'departmentName' : 'userName'" :openid="data.label"></open-data>
                            </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="usedValue"
                        align="left"
                        :label="$t('capacityFlowManage.usedValue')">
                        <template slot-scope="scope">
                          <div class="right-setting">
                            <el-select v-model="scope.row.view.custom" @change="changeUserCustom(scope.row)" :placeholder="$t('common.selectPlaceholder')" size="small">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label === '无限制' ? $t('staffManage.unrestricted') : item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                           <template v-if="scope.row.view.custom == -2">
                                <el-input v-model="scope.row.view.value"  type="number" :placeholder="$t('common.selectPlaceholder')" size="small"></el-input>
                                <el-select v-model="scope.row.view.unit" :placeholder="$t('common.selectPlaceholder')" size="small">
                                    <el-option
                                        v-for="item in unitOptions"
                                        :key="item.value"
                                        :label="item.label === '无限制' ? $t('staffManage.unrestricted') : item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                           </template>
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('capacityFlowManage.operation')"
                        align="left"
                        class-name="operation-cell"
                        width="120">
                        <template slot-scope="scope">
                           <div class="iconfont guoran-a-16-15" @click="edit(scope.row)"></div>
                           <div class="iconfont guoran-tongyichicun-16-09-shanchu2"  @click="del(scope.row,scope.$index)"></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="add-single-config">
                <el-button  type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian" @click="setSignConfig"></el-button>
                <span @click="setSignConfig">{{$t('capacityFlowManage.addSeparateConfiguration')}}</span>
            </div>
            <div class="save-btn">
                <el-button  type="primary" round @click="saveSetting">{{$t('capacityFlowManage.preserve')}}</el-button>
                <span>{{$t('capacityFlowManage.saveEffectiveImmediately')}}</span>
            </div>
        </div>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name" class="popup-name">{{$t('capacityFlowManage.selectDeptOrMember')}}</div>
            <div slot="popup-con" class="member-contanier">
                <staff-customer
                    :isCheckDept="true"
                    :isShowUpDown="false"
                    :notModifyChecked="notModifyChecked"
                    @onNodeClick="onNodeClick"
                    :clientType="'ASKBOT_MEMBER'"
                    :memberInfo4Client="memberInfo4Client"
                    :isMultiple="isMultiple"
                    :activeTableItem="activeTableItem"
                    ref="staffCustomer"
                ></staff-customer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeStaff" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" size="small">{{$t('common.confirm')}}</el-button>
            </div>
      </popup>
        <capacityOrFlowApply 
            :pageType="pageType" 
            :applyVisible.sync="applyVisible" 
            :corpId="corpId" 
            @closeApplyDrawer="closeApplyDrawer"
            @applySuccess="applySuccess">
        </capacityOrFlowApply>
        <buyDetail ref="buyDetail" :corpId="corpId" :pageType="pageType" :buyDetailVisible.sync="buyDetailVisible"></buyDetail>
        <useDetail  :pageType="pageType" :corpId="corpId" :useDetailVisible.sync="useDetailVisible"></useDetail>
        <payPopup 
            ref="payPopup" 
            :payPopupVisable.sync="payPopupVisable" 
            @paySuccess="paySuccess">
        </payPopup>
    </div>
</template>

<script>
import capacityOrFlowApply from './capacityOrFlowApply.vue';
import buyDetail from './buyDetail.vue';
import useDetail from './useDetail.vue';
import StaffCustomer from "./staffCustomer.vue";
import Popup from "@/components/popup";
import payPopup from './payPopup.vue';
import {capacityOption,flowOption,capacityUnitOption,flowUnitOption} from './data'
export default {
    data(){
        return {
            applyListNew:true,
            isUseUp:false, // 是否不足10%
            options:[],
            unitOptions:[],
            companyCanUse:'', // 企业知识可使用
            everybodyCanUse:"", // 企业员工每人可使用
            everybodyCanUseSelf:'', // 企业员工每人可使用-自定义数量
            everybodyCanUseUnit:"", //  企业员工每人可使用-自定义单位
            applyVisible:false,
            buyDetailVisible:false,
            useDetailVisible:false,
            dialogMemberTree:false,
            checkedList:[],
            memberInfo4Client:{
                clientInfo:[]
            },
            notModifyChecked:false, //  是否是不可修改已经选中的数据
            isMultiple:true,
            activeTableItem:{},
            payPopupVisable:false
        }
    },
    components:{capacityOrFlowApply,buyDetail,useDetail,StaffCustomer,Popup,payPopup},
    props:{
        pageType:{
            type:String,
            default:""
        },
        detailInfo:{
            type:Object,
            default(){
                return {}
            }
        },
        corpId:{
            type:String,
            default:""
        }
    },
    watch:{
        detailInfo:{
            handler(n){
                console.log(n,'detailInfo');
                this.setProgressMargin();
                  
            },
            deep:true
        },
        pageType(n){
            if(n === 'capacity'){
                this.options = capacityOption;
                this.unitOptions = capacityUnitOption;
            } else {
                this.options = flowOption;
                this.unitOptions = flowUnitOption;
            }
        }
    },
    mounted(){
        window.addEventListener("resize", () => {
            this.setProgressMargin();
        })
    },
    beforeDestroy(){
        window.removeEventListener("resize", () => {
            this.setProgressMargin();
        });
    },
    methods:{
        applySuccess(){
            this.$emit('updateInfo')
        },
        mouseenterFn(){
            let dom = document.querySelector('.capacity-progress .company');
            console.log(dom,dom);
        },
        changeCompanyCustom(){
            this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.unit = 'GB';
        },
        changeCustom(){
            this.detailInfo.companyPayAbility.companyUserView.unit = this.pageType === 'capacity' ? 'GB' : this.$t('capacityFlowManage.second')
        },
        changeUserCustom(data){
            data.view.unit = this.pageType === 'capacity' ? 'GB' : this.$t('capacityFlowManage.second')
        },
        handlerUnit(value,type){
            if(this.pageType === 'capacity'){
                if(type === 'num'){
                    return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) : (value/1024/1024).toFixed(1)
                } else {
                    return value/1024/1024/1024>=1 ? 'GB' : 'MB';
                }
            } else {
                return type === 'num' ? value : this.$t('capacityFlowManage.second');
            }
        },
        closeApplyDrawer(){
            this.$set(this.detailInfo.companyPayAbility,'isDot',false)
        },
        setSignConfig(){
            this.not = false;
            this.isMultiple = true;
            this.notModifyChecked = true;
            this.checkedList = this.detailInfo.companySeparateConfiguration.tableData;
            this.memberInfo4Client.clientInfo = this.detailInfo.companySeparateConfiguration.tableData;
            this.dialogMemberTree = true;
        },
        edit(row){
            this.notModifyChecked = true;
            this.isMultiple = true;
            this.dialogMemberTree = true;
            this.checkedList = this.detailInfo.companySeparateConfiguration.tableData;
            this.memberInfo4Client.clientInfo = this.detailInfo.companySeparateConfiguration.tableData;
            this.activeTableItem = row;
        },
        onNodeClick(data,type,memberInfo,row){
            console.log(data,type,memberInfo,row);
            let list = JSON.parse(JSON.stringify(this.checkedList))
            if (data.checked) {
                list.push(data);
            } else {
                list.forEach((v, i) => {
                    if (data.id == v.id) {
                        list.splice(i, 1)
                    }
                })
            }
            this.checkedList = this.handlerArr(list);
            // this.memberInfo4Client.clientInfo = this.checkedList
            console.log(this.checkedList,'this.checkedList');
        },
        closeStaff(){
            this.checkedList = null;
            this.dialogMemberTree = false;
        },
        preserStaff(){
            console.log( this.memberInfo4Client,222);
            console.log(this.checkedList,'this.checkedList');
            this.detailInfo.companySeparateConfiguration.tableData = [];
            if (this.checkedList.length > 0){
                this.checkedList.forEach((item,index) => {
                    this.detailInfo.companySeparateConfiguration.tableData.push({
                        id:item.id,
                        name:item.name || item.label,
                        type:item.type, // 类型 0 员工 1 部门
                        view:{
                            custom:item.view && item.view.custom ? item.view && item.view.custom : '-1',  // 自定义
                            value:item.view && item.view.value ? item.view.value : '', 
                            unit: item.view && item.view.unit ? item.view.unit : ''// 单位
                        }
                    });
                })
            }
            console.log( this.detailInfo.companySeparateConfiguration.tableData,'最后选中的数据');
            this.dialogMemberTree = false;
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        // 容量/容量申请
        capacityOrFlowApply(){
            this.applyVisible = true;
        },
        lookBuyDetail(){
            this.buyDetailVisible = true;
        },
        lookUseDetail(){
            this.useDetailVisible = true;
        },
       
        del(row,index){
            this.detailInfo.companySeparateConfiguration.tableData.splice(index,1);
        },
        // 保存设置
        saveSetting(){
            this.$emit('saveSetting')
        },
        // 购买容量/流量
        goBuy(type){
            this.$refs.payPopup.popupType = type;
            if(type === 'capacity'){
                this.$refs.payPopup.popupTips = this.detailInfo.proportionBoo ? this.$t('capacityFlowManage.capacityTip1') : this.$t('capacityFlowManage.capacityTip2');
            } else {
                this.$refs.payPopup.popupTips = this.detailInfo.proportionBoo ? this.$t('capacityFlowManage.trafficTip1') : this.$t('capacityFlowManage.trafficTip2');
            }
            this.payPopupVisable = true;

        },
        paySuccess(){
            this.payPopupVisable = false;
             console.log(2);
            this.$emit('updateInfo');
        },
        // 动态计算进度条的margin-left
        setProgressMargin(){
            this.$nextTick(() => {
                let memberDom = document.querySelector('.member-use');
                let companyDom = document.querySelector('.company-use');
                let surplusCanDom = document.querySelector('.surplus-can-use');
                // console.debug(memberDom,companyDom,surplusCanDom,'DOM');
                // console.debug(memberDom.offsetwidth,companyDom.offsetwidth,surplusCanDom.offsetwidth,'offsetwidth');
                // console.debug(memberDom.clientWidth,companyDom.clientWidth,surplusCanDom.clientWidth,'clientWidth');
                if(this.pageType === 'capacity'){
                    if(memberDom.clientWidth < 12){
                        companyDom.style.marginLeft = '-' + memberDom.clientWidth + 'px';
                    } else {
                        companyDom.style.marginLeft = '-12px';
                    }
                    if(companyDom.clientWidth < 12){
                        surplusCanDom.style.marginLeft = '-' + companyDom.clientWidth + 'px';
                    } else {
                        surplusCanDom.style.marginLeft = '-12px';
                    }
                } else {
                    if(memberDom.clientWidth < 12){
                        surplusCanDom.style.marginLeft = '-' + memberDom.clientWidth + 'px';
                    } else {
                        surplusCanDom.style.marginLeft = '-12px';
                    }
                }
            })  
        }
    }
}
</script>
<style lang="less">
.el-tooltip__popper{
    .tooltips-content-text{
        font-size: 12px;
    }
}
</style>
<style lang="less" scoped>
[v-clock]{
    display: none;
}
@import "../../../../assets/less/capacityAndFlowManage/setDetail.less";
</style>