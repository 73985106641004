<template>
    <div class="pay-popup">
        <Popup @closeEvent="closeEvent"  v-show="payPopupVisable" class="pay-popup-content">
            <div slot="popup-name" class="popup-name">{{popupTitle}}</div>
			<div slot="popup-tip">{{popupTips}}</div>
            <div slot="popup-con">
                <template v-if="!isPaySuccess">
                    <div class="choose-content">
                        <template v-if="popupType === 'flow' || popupType === 'capacity'">
                            <div 
                                :class="['flow-times-item',activeIndex === index ? 'active' : '', versionLoading?'forbid-click':'']" 
                                v-for="(item,index) in priceList" 
                                :key="index"
                                @click="chooseflowTimes(item,index,'flow')">
                                <div class="times">{{item.name}}</div>
                                <div class="price">{{item.price}} <span>元</span> </div>
                            </div>
                        </template>
                    </div>
                    <el-select v-model="paymentMethod" placeholder="请选择" popper-class="choose-pay-method">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                                <i :class="item.icon"></i>
                                <span>{{ item.label }}</span>
                        </el-option>
                    </el-select>
                    <div 
                        v-if="loading"
                        class="loading-content"
                        v-loading="loading"
                        element-loading-text="拼命加载中..."
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="#fff"></div>
                    <template v-else>
                        <div 
                            v-if="flag"
                            class="wechatcode" >
                            <div id="wechatcode-content"  v-if="flag"></div>
                            <div class="pay-method-tips"  v-if="flag">请使用{{paymentMethod === 'wx' ? '微信' : '支付宝'}}扫一扫付款</div>
                        </div>
                        <div class="code-fail"  v-else>
                            <template v-if="activeItem.time === 0 || activeItem.time === 2">
                                <div>二维码生成失败</div>
                                <div>您已开通自动扣费服务，如需变更版本请先在微信关闭自动扣费</div>
                            </template>
                            <template v-else>
                                <div>二维码生成失败</div>
                                <div>请刷新重试</div>
                                <div class="refresh-code" @click="getQrCode">刷新二维码</div>
                            </template>
                        </div>
                    </template>
                </template>
                <!-- 支付成功 -->
                <div v-else class="pay-success">
                    <img src="../../../../assets/images/pay-success.png" alt="" class="">
                    <div class="pay-success-tips">{{closeTime}}s后将自动关闭弹窗</div>
                </div>
               
            </div>
        </Popup>
    </div>
</template>

<script>
import Popup from "@/components/popup";
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
    data (){
        return {
            popupTitle:"",
            popupTips:"",
            // popupType:"", //flow 流量包  capacity 容量包
            activeItem:{},
            activeIndex:0,
            paymentMethod:"wx",
            options:[{
                label:"微信支付",
                value:"wx",
                icon:"iconfont guoran-tongyichicun-weixin",
            }],
            // ,{
            //     label:"支付宝支付",
            //     value:"zfb",
            //     icon:"iconfont guoran-zhifubaozhifu",
            // }
            loading:true,
            wechatPayUrl:"",
            flag:false,
            priceList:[], // 价格列表
            isPaySuccess:false, // 是否支付成功
            closeTimer:null,
            closeTime:3,
            tradeNo:"",
            selectOrderPayStatusTimer:null,
            versionLoading: false,
        }
    },
    components:{Popup},
    props:{
        payPopupVisable:{
            type:Boolean,
            default:false
        },
       
        // priceList:{
        //     type:Array,
        //     default(){
        //         return[]
        //     }
        // }
    },
    watch:{
        payPopupVisable(n){
            if(n){
                this.resetData();
                this.isPaySuccess = false;
                if(this.popupType === 'flow'){
                    this.popupTitle = '购买智能问答与写作次数包';
                    // this.popupTips = '次数包有效期为365天';
                    this.getSelectFlowPrice();
                } else if (this.popupType === 'capacity'){
                    this.popupTitle = '扩容';
                    // this.popupTips = '购买永久容量';
                    this.getSelectCapacityPrice()
                }
            } else {
                clearInterval(this.selectOrderPayStatusTimer);
                clearInterval(this.closeTimer);
            }
        },
    },
    methods:{
        resetData(){
            this.activeItem = {};
            this.activeIndex = 0;
        },
        // 查询容量包价格
        getSelectCapacityPrice(){
            this.$http.get(this.requestUrl.pay.selectCapacityPrice + '?personage=false&company=true').then((res) => {
                if (res.data.code == 0) {
                    this.priceList = res.data.data;
                    this.activeItem = this.priceList[0];
                    this.activeIndex = 0;
                    this.loading = false;
                    this.getQrCode();
                }
            })
        },
        // 流量包/版本选择
        chooseflowTimes(item,index){
            if(this.activeIndex === index) return;
            this.activeItem = item;
            this.activeIndex = index;
            this.wechatPayUrl = '';
            console.log(this.flag,'this.flag');
            if(item.time === 0 || item.time === 2){
                this.loading = false;
                this.flag = false;
            } else {
                this.flag = false;
                this.versionLoading = true; // 防止多次点击
                console.log('获取支付二维码');
                this.getQrCode();
            }
        },
        closeEvent(){
            this.$emit('update:payPopupVisable',false);
            clearInterval(this.closeTimer);
            this.closeTimer = null;
            // this.$emit('paySuccess');
        },
        // 查询流量包价格
        getSelectFlowPrice(){
            this.$http.get(this.requestUrl.pay.selectFlowPrice+"?personage=false&company=true").then((res) => {
                if (res.data.code == 0) {
                    this.priceList = res.data.data;
                    this.activeItem = this.priceList[0];
                    this.activeIndex = 0;
                    this.loading = false;
                    this.getQrCode();
                }
            })
        },
        // 获取支付二维码
        getQrCode(){
            this.loading = true;
            console.log( this.activeItem,this.popupType);
            let params = {
                "type":null, // 购买会员类型 1：超级会员，2：高级会员
                "time":null,  // 开通时长 0：包月，1：包年，2：连续包月，3：连续包年
                "flowType":null,// 购买流量包类型 0：100次流量包，1：500次流量包，2：1000次流量包
                "capacityType":null,
                // type、time为购买会员必须参数，flowType为购买流量包必须参数，capacityType为购买容量包必须参数
            }
            if (this.popupType === 'capacity'){
                params.capacityType = this.activeItem.capacityType
            } {
                params.flowType = this.activeItem.flowType
            }
            this.$http.post(this.requestUrl.pay.getWxPayCodeData,params).then((res) => {
                console.log(res,'获取支付二维码');
                if (res.data.code == 0) {
                    this.loading = false;
                    this.versionLoading = false;
                    this.flag = true;
                    console.log(this.loading,this.flag);
                    this.wechatPayUrl = res.data.data.codeUrl;
                    this.tradeNo = res.data.data.tradeNo;
                    // 生成二维码
                    this.$nextTick(() => {
                        new QRCode('wechatcode-content', {
                            width: 200,
                            height: 200,
                            text: this.wechatPayUrl, // 二维码地址
                            colorDark: "#000",
                            colorLight: "#fff",
                        })
                        this.selectOrderPayStatus();
                    })
                } else {
                    this.flag = false;
                    this.versionLoading = false;
                    this.loading = false;
                }
            }).catch((err) => {
                this.flag = false;
                this.loading = false;
                console.log(err,'err');
            })
        },
        // 根据订单号查询支付状态
        selectOrderPayStatus(){
            clearInterval(this.selectOrderPayStatusTimer);
            this.selectOrderPayStatusTimer = setInterval(() => {
                this.$http.get(this.requestUrl.pay.selectOrderPayStatus + '?orderNum=' + this.tradeNo).then((res) => {
                    if (res.data.code == 0) {
                        let status = JSON.stringify(res.data.data)
                        // true 支付成功，false 支付失败，null 订单号错误
                        if(status === 'true'){
                            // this.messageFn('success', '付款成功');
                            this.paySuccessHandler();
                            clearInterval(this.selectOrderPayStatusTimer);
                        } else if(status === 'false'){
                            this.messageFn('error', '支付失败');
                            clearInterval(this.selectOrderPayStatusTimer);
                        } else {
                            // this.messageFn('error', '订单号错误');
                            // clearInterval(this.selectOrderPayStatusTimer);
                        }
                    } else {
                        this.messageFn('error', res.data.message);
                    }
                })
            },1000)
        },
         // 支付成功后的处理
        paySuccessHandler(){
            clearInterval(this.closeTimer);
            this.closeTimer = null;
            this.closeTime = 3;
            this.isPaySuccess = true;
            this.popupTitle = '付款成功';
            this.popupTips = '服务将在5分钟内生效';
            this.closeTimer = setInterval(() => {
                this.closeTime--
                if(this.closeTime <= 0){
                    clearInterval(this.closeTimer);
                    this.closeTimer = null;
                    this.$emit('paySuccess');
                }
            }, 1000);
        }

    }
}
</script>
<style lang="less">
.choose-pay-method{
    i{
        margin-right: 6px;
    }
    .guoran-zhifubaozhifu{
        color: #2580fc;
    }
    .guoran-tongyichicun-weixin{
        color: #22bc3b
    }
}
</style>
<style lang="less" scoped>
.pay-popup{
    .forbid-click {
        // opacity: 0.7;
        pointer-events: none;
        cursor: not-allowed;
    }
    .pay-popup-content{
        .choose-content{
            display: flex;
            margin-bottom: 20px;
            justify-content: space-between;
            .flow-times-item{
                width: 156px;
                height: 107px;
                background: #F1F5FF;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 2px solid #fff;
                &.active{
                    border-color:#A1B9FF;
                }
                .times{
                    min-width: 94px;
                    padding: 0 10px;
                    height: 31px;
                    background: #366AFF;
                    border-radius: 16px;
                    color: #fff;
                    text-align: center;
                    line-height: 31px;
                    margin-bottom: 10px;
                }
                .price{
                    font-size: 18px;
                    color: #FE5965;
                    height: 15px;
                    span{
                        color: #000000;
                        font-size: 14px;
                    }
                }
            }
            .version-item{
                height: 30px;
                border-radius: 15px;
                padding: 0 16px;
                line-height: 30px;
                color: #000;
                border: 1px solid #E2E6EE;
                cursor: pointer;
                margin-right: 20px;
                &.active{
                    color: #fff;
                    background: linear-gradient(249deg, #5A7BE7, #8DABFF);
                }
            }
        }
        .choose-content-tips{
            margin-bottom: 26px;
            color: #A0A0A0;
            .guoran-tishi{
                color: #CAD3F0;
            }
        }
        .el-select{
            width: 100%;
            margin-bottom: 40px;
        }
        .loading-content{
            height: 250px;
            /deep/.el-loading-mask{
                .el-icon-loading,.el-loading-text{
                    color: #366AFF;
                }
            }
        }
        .wechatcode{
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 250px;
            #wechatcode-content{
                width: 200px;
                height: 200px;
            }
             .pay-method-tips{
                text-align:center;
                margin-top: 14px;
            }
        }
        .code-fail{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 250px;
            >div{
                font-weight: bold;
                margin-bottom: 4px;
                font-size: 15px;
            }
            .refresh-code{
                background: linear-gradient(249deg, #5A7BE7, #8DABFF);
                color: #fff;
                height: 30px;
                width: 100px;
                border-radius: 15px;
                line-height: 30px;
                border: 1px solid #E2E6EE;
                text-align: center;
                cursor: pointer;
                margin-top: 10px;
            }
        }
        .pay-success{
            display: flex;
            align-items: center;
            flex-direction: column;
            img{
                width: 302px;
                height: 270px;
                margin: 60px 0 80px 0;
            }
            .pay-success-tips{
                font-size: 13px;
                color: #616161;
            }
        }
    }
}
</style>