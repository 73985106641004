<template>
    <div class="action-set-entity">
        <div class="simple-cell-satisfaction">
            <span class="title">{{$t('flowComponent.integrateEntities')}}</span>
            <select-addentity
                v-if="!isStartOrEnd"
                :showBorder="true"
                :entityOptions="entityOptions"
                :entityValue="unit.content.entity"
                :entitySelectARef="`action-set-entity_sis${unitIndex}`"
                @handleChangeEntity="handleChangeEntity"
                @getAllEntityBySkill="getAllEntityBySkill"
            ></select-addentity>
            <!-- <el-cascader
                placeholder="请搜索选择实体"
                size="small"
                v-if="!isStartOrEnd"
                v-model="unit.content.entity"
                :key="`action-set-entity_si${unitIndex}`"
                :options="entityOptions"
                @change="handleChangeEntity"
                filterable
            ></el-cascader> -->
            <!-- <el-cascader
                placeholder="请搜索选择实体"
                v-if="isStartOrEnd"
                size="small"
                v-model="unit.content.entity"
                :key="`action-set-entity_bot${unitIndex}`"
                :options="entityOptionsBot"
                @change="handleChangeEntity"
                filterable
            ></el-cascader> -->
            <select-addentity
                v-if="isStartOrEnd"
                :showBorder="true"
                :entityOptions="entityOptionsBot"
                :entityValue="unit.content.entity"
                :entitySelectARef="`action-set-entity_sisb${unitIndex}`"
                :skillId="skillId"
                :isStartOrEnd="isStartOrEnd"
                @handleChangeEntity="handleChangeEntity"
                @getAllEntityBySkill="getAllEntityBySkill"
            ></select-addentity>
        </div>
        <br />
        <div class="simple-cell-satisfaction margin-left-12">
            <span class="title">{{$t('flowComponent.setTo')}}</span>
            <div>
                <el-autocomplete
                    size="small"
                    :key="`action-set-entity_value${unitIndex}`"
                    class="inline-input"
                    v-model="unit.content.value"
                    :fetch-suggestions="querySearchEntityValue"
                    :placeholder="$t('flowComponent.entityValuePla')"
                ></el-autocomplete>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddentity from "../cell/SelectAddentity.vue";
export default {
    props: [
        "unit",
        "unitIndex",
        "isStartOrEnd",
        "entityOptions",
        "entityOptionsBot",
        "skillId",
    ],
    components: { SelectAddentity },
    data() {
        return {
            allEntityValue: [],
            activeEntityValue: "",
        };
    },
    methods: {
        getAllEntityBySkill(entityId){
            if (this.isStartOrEnd) {
                this.$emit("getAllEntityByBot");
            } else {
                this.$emit("getAllEntityBySkill")
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    this.entityOptions.forEach((item) => {
                        item.children.forEach((cell) => {
                            if (entityId == cell.id) {
                                this.unit.content.entity = [item.label, cell.label];
                            }
                        });
                    });
                }, 500)
            })
        },
        // 选择实体
        handleChangeEntity(e) {
            this.unit.content.value = "";
            console.log(e);
            this.unit.content.entity = e;
            let tampArr = [];
            this.entityOptions.forEach((item) => {
                if (item.value === e[0]) {
                    item.children.forEach((cell) => {
                        if (cell.value === e[1]) {
                            tampArr = cell.content;
                        }
                    });
                }
            });
            tampArr.forEach((item) => {
                item.value = item.standardWord;
                item.label = item.standardWord;
            });
            this.allEntityValue = [...tampArr];
            this.activeEntityValue = "";
        },
         // 实体值选择
         querySearchEntityValue(queryString, cb) {
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e = this.unit.content.entity;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        loadAllEntity() {
            return [];
        },
    },
};
</script>
<style lang="less" scoped>
.action-set-entity {
    display: flex;
    justify-content: space-around;
    .simple-cell-satisfaction {
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      text-align: left;
      margin-bottom: 8px;
    }

    .title-other {
      margin-bottom: 8px;
      color: grey;
      text-align: left;
    }

    .el-textarea {
      width: 100%;
      margin-bottom: 12px;
    }

    .option-sa {
      display: flex;
      justify-content: flex-start;

      .el-tag {
        margin-right: 8px;
      }

      margin-bottom: 12px;
    }

    .el-autocomplete {
      width: 100%;
    }

    .satisfaction-radio-box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
    .margin-left-12 {
        margin-left: 12px;
    }
}
</style>