<!-- 
/**
pageName:右侧下部分公共字段配置 默认值/是否备注
auther:liuyuli
time:2022-4-22
 */
 -->
<template>
    <div class="bottom-common">
        <el-form  :model="activeObjs" :label-position="labelPosition">
            <!-- 默认值的处理 -->
            <el-form-item label="默认值"
                v-if="(activeObjs.type !== 'TIME_PICKER_RANGE' && activeObjs.extInfo && activeObjs.extInfo.fieldDetail && activeObjs.extInfo.fieldDetail.hasOwnProperty('type')) ||
                (activeObjs.type !== 'TIME_PICKER_RANGE' && activeObjs.type !== 'EXPLAIN' && !isSystemField)">
                <!-- 单行输入框/ 多行输入框 /富文本-->
                <el-input 
                    v-if="showDefaultType === 'INPUT' || showDefaultType === 'TEXTAREA' || showDefaultType === 'RICHTEXT'"
                    v-model="activeObjs.defaultValue"
                    @input="changeInput"
                    placeholder="请输入"
                    :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                ></el-input>
                <!-- 单选/多选/下拉/省份 -->
                <el-select
                    v-else-if="showDefaultType === 'RADIO' || showDefaultType === 'CHECKBOX' || showDefaultType === 'SELECT'"
                    v-model="activeObj.defaultValue"
                    :multiple="showDefaultType === 'CHECKBOX' || (showDefaultType === 'SELECT' && activeObjs.extInfo.selectType === '多选') || (showDefaultType === 'PROVINCE' && activeObjs.extInfo.selectType === '多选')"
                    filterable
                    style="width: 100%"
                    clearable
                    @change="changeSelect"
                    placeholder="请选择"
                    :key="activeObjs.id + activeObjs.extInfo.selectType"
                    :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                >
                    <el-option
                        v-for="item in selectOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <!-- 级联 -->
                <div class="bottom-input"  v-else-if="showDefaultType === 'CASCADER'" @click="openPopup">
                    <div class="placeholder-text" v-if="!activeObjs.extInfo.fieldValue||activeObjs.extInfo.fieldValue.length===0">请选择</div>
                    <div class="client-value" v-else-if="activeObjs.extInfo.selectType==='多选'">
                        <div v-for="(item,index) in activeObjs.extInfo.fieldValue" :key="index" class="item">
                            {{index===0?(item.value?item.value:item):','+(item.value?item.value:item)}}
                        </div>
                    </div>
                    <div class="client-value" v-else>
                     {{activeObjs.extInfo.fieldValue && activeObjs.extInfo.fieldValue.length!=0?activeObjs.extInfo.fieldValue[0].value:''}}
                    </div>
                </div>

                <!-- 时间日期 -->
                <div :class="[showDefaultType === 'TIME_PICKER' ? 'date' : '']"  v-else-if="showDefaultType === 'TIME_PICKER' && (activeObjs.extInfo && activeObjs.extInfo.mold !== 'OTHER')">

                  <el-time-picker
                      v-model="activeObjs.defaultValue"
                      style="width: 100%"
                       v-if="activeObjs.extInfo.mold&&activeObjs.extInfo.mold==='TIME'"
                      placeholder="请选择"
                      :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                      format="HH:mm"
                      value-format="HH:mm"
                  >
                  </el-time-picker>
                  <el-date-picker
                      v-model="activeObjs.defaultValue"
                      type="date"
                      style="width: 100%"
                      v-else-if="activeObjs.extInfo.mold&&activeObjs.extInfo.mold==='DATE'"
                      placeholder="请选择"
                      :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                      :picker-options="PICKER_OPTIONS_COLLECT"
                      format="yyyy-MM-dd"
				              value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <el-date-picker
                      v-model="activeObjs.defaultValue"
                      type="datetime"
                      style="width: 100%"
                      v-else-if="activeObjs.extInfo.mold&&activeObjs.extInfo.mold==='DATE_TIME'"
                      placeholder="请选择"
                      :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                      format="yyyy-MM-dd HH:mm"
				              value-format="yyyy-MM-dd HH:mm"
                      :picker-options="PICKER_OPTIONS_COLLECT"
                  >
                  </el-date-picker>
                    <!--span class="default-date">{{activeObjs.defaultValue}}</span>-->
                </div>
                <!-- 图片选择 -->
                <el-select 
                    v-else-if="showDefaultType === 'IMAGE_SELECT'"
                    v-model="activeObjs.defaultValue"
                    :multiple="activeObjs.extInfo&&activeObjs.extInfo.selectType==='多选'?true:false"
                    style="width: 100%"
                    value-key="value"
                    filterable
                    clearable
                    placeholder="请选择"
                    :key="activeObjs.id + activeObjs.extInfo.selectType"
                    :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                >
                    <el-option
                        v-for="item in activeObjs.extInfo.options"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value">
                    </el-option>
                </el-select>
                <!-- 组织架构/用户/ -->
                <div class="bottom-input" 
                    v-else-if="showDefaultType ==='USER' || showDefaultType ==='ORG'
                        || showDefaultType ==='ASSIGNEE' || showDefaultType ==='FOLLOWER' || showDefaultType ==='REPORTER'" 
                    @click="openOrgPopup">
                    <span class="placeholder-text" v-if="!activeObjs.extInfo.fieldValue">请选择</span>
                    <div class="client-value" v-else>
                      <template v-if="setDefault(activeObjs.extInfo.fieldValue) && setDefault(activeObjs.extInfo.fieldValue).length > 0">
                        <span v-for="(item,orgIndex) in setDefault(activeObjs.extInfo.fieldValue)">
                          {{orgIndex===0?(item.name?item.name:item.label):','+(item.name?item.name:item.label)}}
                        </span>
                      </template>
                    </div>
                </div>
                <!--系统账号-->
              <el-cascader
                  :options="accountListComputed"
                  v-else-if="showDefaultType === 'ACCOUNT'"
                  v-model="activeObjs.defaultValue"
                  style="width: 100%"
                  ref="accountCascader"
                  @change="changeAccount"
                  :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                  :props="{ multiple: activeObjs.extInfo.selectType==='多选'?true:false,
                   checkStrictly: false,
                   children:'accountInfos',
                   label:'name',
                   value:'id',
                   emitPath:false}"
                  clearable>
              </el-cascader>
                <!-- 关联数据模板 -->
                <el-select 
                    v-else-if="showDefaultType === 'REF_TEMPLATE' || showDefaultType === 'REF'"
                    v-model="activeObjs.defaultValue"
                    :multiple="activeObjs.extInfo&&activeObjs.extInfo.selectType==='多选'?true:false"
                    style="width: 100%"
                    value-key="value"
                    filterable
                    clearable
                    @visible-change="visibleChange"
                    placeholder="请选择"
                    :key="activeObjs.id + activeObjs.extInfo.selectType"
                    :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                    @focus="setOptionWidth"
                >
                  <el-option
                      v-for="(item,index) in dataTemplateList[activeObjs.id]"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :style="{width:selectOptionWidth}"

                  >
                    <span v-html="item.name"></span>
                  </el-option>
                </el-select>
                <!-- 省份/城市/行业 -->
              <el-cascader
                  v-model="activeObjs.defaultValue"
                  style="width: 100%"
                  ref="cascaderDefault"
                  clearable
                  filterable
                  placeholder="请选择"
                  :options="getOptions"
                  collapse-tags
                  @change="changeDefault"
                  :show-all-levels="false"
                  :props="{value:'code',
                  label:'name',
                  multiple:activeObjs.extInfo.selectType==='多选'?true:false,
                  emitPath:false
                  }"
                  :key="activeObjs.id"
                  :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                  v-else-if=" showDefaultType === 'INDUSTRY' || showDefaultType === 'CITY' || showDefaultType === 'PROVINCE'"
              ></el-cascader>
            </el-form-item>
            <!-- <el-form-item v-if="activeObjs.type === 'SELECT'">
              <div class="is-verification-required">
                <div class="left">
                  <span>切换选项是否校验当前必填项</span>
                </div>
                <div class="switch-icon-box">
                  <el-switch
                      :width="42"
                      v-model="activeObjs.extInfo.verificationRequireds"
                      active-color="#366AFF"
                      inactive-color="#E2E2E2"
                      active-icon-class="el-icon-check"
                      inactive-icon-class="el-icon-close"
                      :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                  >
                  </el-switch>
                </div>
              </div>
              <div class="verification-required-tip">
                场景:当处于选项1时，要将下拉切换为选项2时，如存在必填项未填写时，是否允许切换选项
              </div>
            </el-form-item> -->
            <el-form-item v-if="!isSystemField">
                <div class="is-remark">
                    <div class="left">
                        <span>是否必填</span>
                    </div>
                  <div class="switch-icon-box">
                        <el-switch
                            :width="42"
                            v-model="activeObjs.required"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                            active-icon-class="el-icon-check"
                            inactive-icon-class="el-icon-close"
                            :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                        >
                        </el-switch>
                  </div>
                </div>
            </el-form-item>
            <!-- <el-form-item v-if="activeObjs.type === 'INPUT' || activeObjs.type === 'TEXTAREA'">
            <div class="is-duplicate-verification">
              <div class="left">新增数据时校验重复</div>
              <div class="switch-icon-box">
                <el-switch
                    :width="42"
                    v-model="activeObjs.unique"
                    active-color="#366AFF"
                    pl
                    inactive-color="#E2E2E2"
                    active-icon-class="el-icon-check"
                    inactive-icon-class="el-icon-close"
                    :disabled="activeObjs.extInfo && activeObjs.extInfo.isCommon && !$route.query.type"
                >
                </el-switch>
              </div>
            </div>
          </el-form-item> -->
            <!-- <el-form-item  v-if="activeObjs.type === 'IMAGE' || activeObjs.type === 'VIDEO' || activeObjs.type === 'ATTACHMENT'">
                <div class="is-remark">
                    <div class="left">
                        <span>是否允许添加水印</span>
                    </div>
                     <div class="switch-icon-box">
                        <el-switch
                            :width="42"
                            v-model="activeObjs.extInfo.watermark.enable"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                            active-icon-class="el-icon-check"
                            inactive-icon-class="el-icon-close"
                            :disabled="activeObjs.extInfo.isCommon && !$route.query.type"
                        >
                        </el-switch>
                    </div>
                </div>
            </el-form-item> -->
            <el-form-item v-if="activeObjs.type !== 'EMAIL' && activeObjs.type !== 'THIRD_PARTY_ACCOUNT' && activeObjs.type !== 'USER_NAME' && activeObjs.type !== 'ROLE' && activeObjs.type !== 'ACCOUNT_GROUP' && activeObjs.type !== 'DATA_PERMISSIONS'">
                <div class="is-remark">
                    <div class="left">
                        <span>是否备注</span>
                        <span class="tips">在字段后面加备注说明</span>
                    </div>
                    <div class="switch-icon-box">
                        <el-switch
                            :width="42"
                            v-model="activeObjs.remark.enable"
                            active-color="#366AFF"
                            pl
                            inactive-color="#E2E2E2"
                            active-icon-class="el-icon-check"
                            inactive-icon-class="el-icon-close"
                            :disabled="activeObjs.extInfo && activeObjs.extInfo.isCommon && !$route.query.type"
                        >
                        </el-switch>
                    </div>
                </div>
                <el-input v-if="activeObjs.remark.enable" v-model="activeObjs.remark.text" placeholder="请输入"></el-input>
            </el-form-item>
           
        </el-form>
         <chooseTree 
            v-if="activeObjs.type === 'CASCADER'"
            :chooseTreeDialog="chooseTreeDialog"
            ref="chooseTree"
            :activeObj="activeObjs"
            @closeChooseTree="closeChooseTree"
            @saveChooseTree="saveChooseTree"
         ></chooseTree>
    </div>
</template>

<script>
import chooseTree from '../chooseTree.vue';
// import { getRefTemplateDataDetail } from "@/assets/js/common";

export default {
    components:{
        chooseTree,
    },
    data(){
        return {
            labelPosition:'top',
            chooseTreeDialog:false,
            dataFilterPopupDialog:false,
            showDefaultType:'', // 展示默认值的类型
            selectOption:[], // 单选/多选/下拉的下拉中的值
            list:[],
            options:[{

            }],
            chooseCityIndustryDefaultDialog:false,
          defaultValue:[],
          accountList:[],
          props:{
            value:"code",
            label:"name"
          },
          loadData:{},
          provinceList:[],
          cityList:[],
          industryList:[],
          dataTemplateList:{},
          selectOptionWidth:null,
          dataQuery:{
            filterId:'',
            keywords:"",
            pageInfo:{
              pageNo:1,
              pageSize:20
            },
            sortInfos:[],
            templateId:"",
          },
          isHasNext:{},
          refTemplateDataDetail:null,
          firstLoadRefData:true
        }
    },
    props:{
		activeObj:{
			type:Object,
			default(){
				return{}
			}
		},
		isSystemField:{
			type:Boolean,
			default:false,
		}
	},
  filters:{

  },
    computed:{
        activeObjs(){
            console.log(this.activeObj,111);
            if(this.activeObj.type === 'REF'){
                this.showDefaultType = this.activeObj.type;
                this.selectOption = this.activeObj.extInfo.fieldDetail.extInfo && this.activeObj.extInfo.fieldDetail.extInfo.options;
            } else if (this.activeObj.type === 'TIME_PICKER'){
                this.showDefaultType = this.activeObj.type;
                // this.activeObj.defaultValue = this.activeObj.defaultValue?this.activeObj.defaultValue:new Date();
            } else if(this.activeObj.type === 'PROVINCE'){

                this.showDefaultType = this.activeObj.type;
                // this.selectOption = [{
                //     label:'北京',
                //     value:'1'
                // },{
                //     value:'2'
                // }]
            } else {
                this.showDefaultType = this.activeObj.type;
                this.selectOption = (this.activeObj.extInfo&&this.activeObj.extInfo.options) || [];
            }
            let obj = {}
            if(this.activeObj.extInfo&&(this.activeObj.extInfo.selectType === '多选'||this.activeObj.type === 'CHECKBOX')){
              /*this.$set( this.activeObj,'defaultValue',[])*/
            } else {
             /* this.$set( this.activeObj,'defaultValue','')*/
            }
            return this.activeObj;
        },
        getOptions(){
          let arr = [];
          if (this.$refs.cascaderDefault){
            this.$refs.cascaderDefault.dropDownVisible = false;
          }
          if (this.activeObjs.type === 'PROVINCE'){
            arr = this.provinceList;
          }
          else if (this.activeObjs.type === 'CITY'){
            arr = this.cityList;
          }
          else if (this.activeObjs.type === 'INDUSTRY'){
            arr = this.industryList;
          }
          return arr;
        },
        accountListComputed(){
          let arr = [];
          let filterIds = [];
          let newArr = JSON.parse(JSON.stringify(this.accountList))
          if (this.activeObjs.extInfo.ref.filter.enable && this.activeObjs.extInfo.accountList){
            this.activeObjs.extInfo.accountList.forEach(item=>{
              if (item.groupId && filterIds.indexOf(item.groupId)==-1){
                filterIds.push(item.groupId)
              }
              if (filterIds.indexOf(item.id) == -1){
                filterIds.push(item.id)
              }
            })
          }
          if (filterIds.length > 0){
            arr = newArr.filter(item=>filterIds.indexOf(item.id)!==-1)
            for (let k =0 ;k<arr.length;k++){
              if (arr[k].accountInfos &&  arr[k].accountInfos.length > 0){
                arr[k].accountInfos = arr[k].accountInfos.filter(items=>filterIds.indexOf(items.id)!==-1);
              }
            }
          }
          else {
            arr = newArr
          }
          return arr
        }
    },
    methods:{
      setDefault(value){
        console.debug('value',value)
        let defaultVal = [];
        for (let k in value){
          let itemVal = ""
         /* for (let i =0;i<value[k].length;i++){
            itemVal += i == 0?value[k][i].name:+','+value[k][i].name
          }*/
          defaultVal = defaultVal.concat(value[k])
        }
        console.debug('defaultVal',defaultVal)
        return defaultVal
      },
        changeInput(datas){
            console.log(datas,'datas');
           /* this.activeObjs.defaultValue = datas;*/
            // this.$emit('changeConfigTop',this.activeObjs);
        },
        openPopup(){
        if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
          this.$message({
            message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
            duration:2000,
            type:'warning'
          })
          return
        }
            this.chooseTreeDialog = true;
            this.$refs.chooseTree.initCascadeOption()
        },
        closeChooseTree(){
            this.chooseTreeDialog = false;
           /* this.activeObjs.defaultValue = null
            this.searchCheckedList(this.activeObj.extInfo.cascadeDown.options,this.activeObj.extInfo.selectType)*/
        },
        saveChooseTree(){
          this.chooseTreeDialog = false;
        },
        openOrgPopup(){
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            this.dataFilterPopupDialog = true;
        },
        //查找checked为true的选项赋值fieldValue
        searchCheckedList(list,type){
          list.forEach(item=>{
            if (item.checked){
              if (type === '单选'){
                this.activeObjs.defaultValue = item.value
              }
              else {
                if (!this.activeObjs.defaultValue){
                  this.activeObjs.defaultValue = []
                }
                this.activeObjs.defaultValue.push(item.value)
              }
            }
            if (item.children){
              this.searchCheckedList(item.children,type)
            }
          })
        },
      changeAccount(value){
        if (this.activeObjs.extInfo.selectType ==='单选'){
          this.$set(this.activeObjs.extInfo,'defaultValues',"");
          let values = value[value.length - 1];
          this.forMapList(this.accountList,values,'sim');
        }
        else {
          this.$set(this.activeObjs.extInfo,'defaultValues',[]);
          console.debug('accountCascader',this.$refs.accountCascader.getCheckedNodes())
          let checkNode = this.$refs.accountCascader.getCheckedNodes().map(item=>{
            return item.data
          })
          let values = [];
          values = checkNode.map(item=>{
            return item.name
          })
          this.activeObjs.extInfo.defaultValues = values
        }
        console.debug('extInfo',this.activeObjs.extInfo)
      },
       changeDefault(value){
        if (this.activeObjs.extInfo.selectType ==='单选'){
          this.$set(this.activeObjs.extInfo,'defaultValues',"");
          let values = value[value.length - 1];
          this.forMapList(this.getOptions,values,'sim');
        }
        else {
          this.$set(this.activeObjs.extInfo,'defaultValues',[]);
          console.debug('cascaderDefault',this.$refs.cascaderDefault.getCheckedNodes())
          let checkNode = this.$refs.cascaderDefault.getCheckedNodes().map(item=>{
            return item.data
          })
          let values = [];
          values = checkNode.map(item=>{
            return item.name
          })
          this.activeObjs.extInfo.defaultValues = values
          /*value.forEach(items=>{
            let values = items[items.length - 1];
            this.forMapList(this.getOptions,values,'mul');
          })*/
        }
        console.debug('extInfo',this.activeObjs.extInfo)
      },
      forMapList(item,value,type){
        if (!item){
          return
        }
          item.forEach(items=>{
            if (items.code === value || items.id === value){
              if (type === 'mul'){
                if (this.activeObjs.extInfo.defaultValues.indexOf(items.name) === -1){
                  this.activeObjs.extInfo.defaultValues.push(items.name);
                }
              }
              else {
                this.activeObjs.extInfo.defaultValues = items.name;
              }
            }
            if (items.children){
              this.forMapList(items.children,value,type)
            }
            if (items.accountInfos){
              this.forMapList(items.accountInfos,value,type)
            }
          })

      },
      changeSelect(){
        this.$forceUpdate()
      },
      getAccountList(){
        let url ='/workerorder-api/account/principals?groupIds=%20&includedOpPrivileges=';
        this.$http.get(url).then(res=>{
          if (res.data.code === '0'){
            this.accountList = res.data.data;
          }
        })
      },
      //获取省份
      getProvince(){
        let url = "/api/data-template/field/province";
        this.$http.get(url).then(res=>{
          if (res.data.data && res.data.data[0]){
            this.provinceList = res.data.data[0].children
          }
        })
      },
      //获取城市
      getCity(){
        let url = "/api/data-template/field/city";
        this.$http.get(url).then(res=>{
          if (res.data.data && res.data.data[0]){
            this.cityList = res.data.data[0].children
          }
        })
      },
      //获取行业
      getIndustry(){
        let url = "/api/data-template/field/industry";
        this.$http.get(url).then(res=>{
          this.industryList = res.data.data;
        })
      },
      visibleChange(value){
        let fields = this.activeObjs.type==='REF'?this.activeObjs.extInfo.ref.field:this.activeObjs.extInfo.ref.vars
        if (value){
          if (fields.length && (!this.dataTemplateList[this.activeObjs.id] || this.firstLoadRefData ||(this.addRefDatas&&!this.isHasNext[this.activeObjs.id] ))){
            this.$set(this.dataTemplateList,this.activeObjs.id,[])
            this.getTemplateList(this.activeObjs.extInfo.ref.source,fields,this.activeObjs)
          } else if (!fields.length){
            this.$set(this.dataTemplateList,this.activeObjs.id,[])
          }
        }
      },
      getTemplateList(templateId,fieldId,field){
        let url = "/api/data-template/data/page"
        this.dataQuery.templateId = templateId
        this.$http.post(url,this.dataQuery).then(res=>{
          if (res.data.code === '0'){
            let list = res.data.data.data
            this.isHasNext[field.id] = res.data.data.hasNextPage
            let templateList = []
            templateList = list.map(res=>{
              let info = {}
              for (let key in res.fieldValues) {
                if (Array.isArray(fieldId)){
                  let vals = ''
                  let values = ''
                  fieldId.forEach(val=>{
                    vals+=val
                    if (res.fieldValues[val]){
                      values+=res.fieldValues[val]
                    }
                  })
                  info[vals] = values
                  info.id = res.id
                  info.name = values
                }
                else {
                  if (fieldId===key){
                    info[key] = res.fieldValues[key]
                    info.id = res.id
                    info.name = res.fieldValues[key]
                  }
                }

              }
              return info
            })
            if (this.refTemplateDataDetail) {
              let findIndex = -1;
              findIndex = templateList.findIndex(item => item.id == this.refTemplateDataDetail.id);
              if (findIndex == -1) {
                templateList.push(this.refTemplateDataDetail)
              }
            }
            this.dataTemplateList[field.id] = [...this.dataTemplateList[field.id],...templateList].filter(item=>item.name && item.name!=='[]')
            this.firstLoadRefData = false;
          }
        })
      },
      //动态设置下拉框宽度
      setOptionWidth(event){
        this.selectOptionWidth = event.srcElement.offsetParent.offsetWidth + 40 + "px";
      },
      getTemplateDetail(){
        let id = this.activeObj.defaultValue;
        let templateId = this.activeObj.refTemplateId
        let vars = this.activeObj.type === 'REF' ? this.activeObj.extInfo.ref.field : this.activeObj.extInfo.ref.vars;
        // getRefTemplateDataDetail(id,templateId,vars).then(res => {
        //   this.refTemplateDataDetail = res[0] ? res[0] : null
        //   if (!this.dataTemplateList[this.activeObjs.id]){
        //     this.$set(this.dataTemplateList,this.activeObjs.id,[])
        //   }
        //   this.dataTemplateList[this.activeObjs.id] = res;
        // })
      },
    },
  mounted() {
      this.$nextTick(() => {
        if ((this.activeObj.type === 'REF' || this.activeObj.type === 'REF_TEMPLATE') && this.activeObj.defaultValue){
          this.getTemplateDetail()
        }
      })
  },
  watch:{
    "activeObjs.type":{
      handler(value){
        console.debug('activeObjs.type',this.activeObjs.type)
        if (this.activeObjs.type === 'ACCOUNT'){
          this.getAccountList();
        }
        if (this.activeObjs.type === 'PROVINCE'){
          this.getProvince();
        }
        else if (this.activeObjs.type === 'CITY'){
          this.getCity();
        }
        else if (this.activeObjs.type === 'INDUSTRY'){
          this.getIndustry();
        }
      },
      immediate:true
    }
  }
}
</script>

<style lang="less" scoped>
    .bottom-common{
        /deep/ .el-form-item{
            position: relative;
          .el-form-item__content{
            line-height: 20px!important;
          }
            .el-form-item__label{
                // margin-bottom: 10px;
            }
        }
      .bottom-input{
        border: 1px solid #dcdfe6;
        border-radius: 5px;
        padding: 8px 12px;
        color: #c0c4cc;
        cursor: pointer;
        .client-value{
          display: flex;
          align-items: center;
          min-height: 38px;
          line-height: 20px;
          word-break: break-all;
          flex-wrap: wrap;
          color: #606266;
        }
        /*.placeholder-text{
          padding: 8px 0;
        }*/
      }
      .is-verification-required{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color:#616161 ;
        height: 38px;
        background: #FBFCFD;
        border-radius: 5px;

      }
      .verification-required-tip{
        color: #A9B3C6;
        font-size: 13px;
      }
      .is-duplicate-verification{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color:#616161 ;
        height: 38px;
        background: #FBFCFD;
        border-radius: 5px;
        line-height: 38px;
      }
        .is-remark{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color:#616161 ;
            height: 38px;
            background: #FBFCFD;
            border-radius: 5px;
            line-height: 38px;
            .tips{
                color: #A9B3C6;
                margin-left: 10px;
            }
        }
        .switch-icon-box{
             /deep/ .el-switch{
              .el-switch__core{
                height: 22px;
                border-radius: 12px;
              }
                .el-switch__core:after{
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    top: 3px;
                }
                .el-switch__label--left{
                    i{
                        color: #6E6B7B;
                        font-weight: 800;
                    }
                    position: absolute;
                    left: 25px;
                    top:0px;
                    z-index: 2;

                }
                .el-icon-check{
                    color: #fff;
                    font-weight: 800;
                    position: absolute;
                }

                &.is-checked{
                    .el-switch__core:after{
                        left: 40px;
                        top: 3px;
                    }
                    .el-icon-check{
                        position: absolute;
                        left: 5px;
                        top: 2px;
                        z-index: 2;
                        color: #fff;
                        font-weight: 800;
                    }
                    .el-icon-close{
                        color: #fff;
                    }
                }

            }
        }
    }
</style>>

