<!-- 
/**
pageName:单行/多行输入框 
auther:liuyuli
time:2022-4-24
 */
 -->
<template>
   <div class="input-config">
      <top-config :activeObj="activeObjs" ></top-config>
       <el-form :label-position="labelPosition"  :model="activeObjs">
            <el-form-item label="输入类型" v-if="activeObjs.type === 'INPUT'">
                 <el-select v-model="activeObjs.extInfo.inputType"  placeholder="请选择" @change="changeinput" filterable>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="字数上限" v-if="(activeObjs.extInfo.inputType === 'TEXT' &&activeObjs.type === 'INPUT') || activeObjs.type === 'TEXTAREA'">
                <el-input v-model="activeObjs.extInfo.maxNum" :maxlength="20"></el-input>
            </el-form-item>
			<template v-if="activeObjs.extInfo.inputType === 'NUMBER' || activeObjs.extInfo.inputType === 'RATE'">
				<el-form-item>
					<div class="is-inline range">
						<div class="left">
							<span>可输入范围</span>
							<span class="tips">为空时,不限制</span>
						</div>
						<div class="bottom">
							<el-input v-model="activeObjs.extInfo.range.start"></el-input>
							<span>-</span>
							<el-input v-model="activeObjs.extInfo.range.end"></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="is-inline">
						<div class="left">
							<span>单位</span>
							<span class="tips">为空时,没有单位</span>
						</div>
						<el-input v-model="activeObjs.extInfo.unit" :disabled="activeObjs.extInfo.inputType === 'RATE'"></el-input>
					</div>
				</el-form-item>
			</template>
			<!-- <template v-if="activeObjs.extInfo.inputType === 'MOBILE' || activeObjs.extInfo.inputType === 'ID_CARD' || activeObjs.extInfo.inputType === 'MAIL' || activeObjs.extInfo.inputType === 'LINK'">
				<aiDistinguish :centerlist="centerlist"  :activeObj="activeObjs"></aiDistinguish>
			</template> -->
        </el-form>
      <bottom-common :activeObj="activeObjs"></bottom-common>
   </div>
</template>

<script>
import topConfig from '../common/topCommon.vue';
import bottomCommon from '../common/bottomCommon.vue';
import aiDistinguish from '../aiDistinguish.vue';
// import mixins from './../../mixins';
export default {
   components:{
      topConfig, 
      bottomCommon,
	  aiDistinguish, // 智能识别
   },
//    mixins:[mixins],
    data(){
        return {
            labelPosition:'top',
			options:[{
				value: 'TEXT',
				label: '文本'
			},{
				value: 'NUMBER',
				label: '数字'
			},{
				value: 'MOBILE',
				label: '手机号'
			},{
				value: 'RATE',
				label: '百分比'
			},
			{
				value: 'ID_CARD',
				label: '身份证号'
			},{
				value: 'MAIL',
				label: '邮箱'
			},{
				value: 'LINK',
				label: '链接'
			}],
        }
    },
	props:{
		activeObj:{
			type:Object,
			default(){
				return{}
			}
		},
		centerlist:{
            type:Array,
            default(){
                return []
            }
        },
		fieldData:{
			type:Object,
			default(){
				return{}
			}
		}
	},
	computed:{
        activeObjs(){
            return this.activeObj;
        }
    },
	methods:{
		changeinput(datas){
			if(datas === 'TEXT'){
				this.activeObjs.extInfo.maxNum = '';
				this.activeObjs.extInfo.range.end = '';
				this.activeObjs.extInfo.range.start = '';
				this.activeObjs.extInfo.unit = '';
			} else 	if(datas === 'RATE'){
				this.activeObjs.extInfo.maxNum = '';
				this.activeObjs.extInfo.range.end = 1;
				this.activeObjs.extInfo.range.start = 0;
				this.activeObjs.extInfo.unit = '%'
			} else if(datas === 'NUMBER' || datas === 'MOBILE' || datas === 'ID_CARD' || datas === 'MAIL' || datas === 'LINK'){
				this.activeObjs.extInfo.maxNum = '';
				this.activeObjs.extInfo.range.end = 100;
				this.activeObjs.extInfo.range.start = 0;
				this.activeObjs.extInfo.unit = ''
			} 
			console.log(this.activeObjs);
		},
		

	}
}
</script>

<style lang="less" scoped>
	.input-config{
		.red{
			color: red;
		}
		::v-deep .el-input__inner,.el-select{
			width: 100%;
		}
		.is-inline{
			font-size: 14px;
			color:#616161;
			.left{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.tips{
					color: #A9B3C6;
					margin-left: 10px;
				}
			}
			.bottom{
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			&.range{
				::v-deep .el-input{
					width: 45%;
				}
			}

			
		}
	}
</style>>

