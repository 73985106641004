<template>
    <div class="switch-and-icon">
        <el-switch
            @click.native.prevent="changeValue('switch')"
            :disabled="disabled"
            :width="42"
            v-model="value"
            active-color="#366AFF"
            inactive-color="#E2E2E2">
        >
        </el-switch>
        <span
            class="switch-open-icon"
            @click="changeValue('open')"
            v-if="value">
            <i class="iconfont guoran-a-16-17"></i>
        </span>
        <span
            class="switch-close-icon"
            @click="changeValue('close')"
            v-if="!value">
            <i class="arsenal_icon arsenalcuo1"></i>
        </span>
    </div>
</template>

<script>
export default {
    props:{
        value:{
            style:Boolean,
            default:true
        },
        disabled:{
            style:Boolean,
            default:false
        }
        
    },
    model:{
        prop:"value",
        event:"change"
    },
    methods:{
        changeValue(datas){
            console.log(datas);
            if(datas === 'open'){
               this.$emit('change',false)
            } else if (datas === 'close'){
                this.$emit('change',true)
            } else if (datas === 'switch'){
                this.$emit('change',!this.value)
            }
            console.log(this.value);
            this.$forceUpdate()
        },
    }
}
</script>

<style lang="less" scoped>
.switch-and-icon {
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    text-align: center;
    align-items: center;
    width: 42px;
    .el-switch {
        text-align: center;
        
    }

    .switch-open-icon {
        position: absolute;
        // z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;

        i {
            color: white;
            font-size: 18px;
        }
    }

    .switch-close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 21px;
        i {
            color: #6E6B7B;
            font-size: 14px;
            font-weight: 600;
        }
    }

    i {
        color: #366AFF;
        font-size: 18px;
    }
    .el-switch__core::after{
        width: 14px;
        height: 14px;
        top: 2px;
        left: 2px;
    }
    .is-checked .el-switch__core::after{
        left: 40px;
    }
    &.disabled{
        i,.el-switch__input,.el-switch__core{
            cursor: no-drop;
        }
    }
}
</style>