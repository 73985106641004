<template>
    <div id="import-file">
        <popup @closeEvent="closeEvent" v-if="importFileVisible">
            <div slot="popup-name" class="popup-name">{{$t('importFile.title')}}</div>
                <div slot="popup-tip">{{$t('importFile.tips')}}</div>
            <div slot="popup-con">
                <div class="content-import-file">
                    <div class="tips"> <span>1</span> <span>{{$t('importFile.contentTip1')}}</span></div>
                    <div class="tips"> <span>2</span> <span>{{$t('importFile.contentTip2')}}</span></div>
                    <div class="tips"> <span>3</span> <span>{{$t('importFile.contentTip3')}}</span></div>
                    <el-upload
                        v-show="!haveFile"
                        ref="uploadFile"
                        class="upload-demo"
                        action="/api/upAndDown/memberImport"
                        accept=".xlsx,.xls"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-error="uploadError"
                        :on-success="uploadSuccess"
                        :on-change="beforeUpload">
                        <div class="choose-file" >{{$t('importFile.chooseFile')}}</div>
                    </el-upload>
                    <div class="have-file" v-show="haveFile">
                        <el-input v-model="fileName" :disabled="disabled"></el-input>
                        <!-- <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="disabled = false"></el-button> -->
                        <el-button icon="el-icon-delete" size="small" @click="delFile"></el-button>
                    </div>
                    <div class="no-template">{{$t('importFile.downLoadTip')}} <span @click="downloadTemplate">{{$t('importFile.template')}}</span></div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm('')" round :class="[haveFile ? 'have' : 'no']" style="padding:0 !important;">{{$t('importFile.startUpload')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue'
export default {
    data(){
        return {
            haveFile:false,
            fileName:'',
            disabled:true,
        }
    },
    components:{
        Popup
    },
   props:{
		importFileVisible:{
			default:false,
			type:Boolean
		},
        checkedTreeItem:{
            type:Object,
            default(){
                return{}
            }
        },
	},
    watch:{
        importFileVisible(n,o){
            this.haveFile = false;
            this.disabled = true;
            this.fileName = '';
        }
    },
    methods:{
        // 关闭弹框
        closeEvent(){
            this.$emit('update:importFileVisible',false);
        },
        // 获取文件后缀名
        getFileSuffix(url,arr) {
            let index1 = url.lastIndexOf(".")+1;
            let index2 = url.length;
            let postf = url.substring(index1, index2);//后缀名
            if (!arr || arr.indexOf(postf) == -1) {
                return false;
            } else {
                return true
            }
        },
        // 上传之前的钩子
        beforeUpload(file){
            console.log(file,'上传之前的钩子');
            this.disabled = true;
            console.log(file,'上传之前的钩子');
            if(file.status === 'ready'){
                if(!this.getFileSuffix(file.name,['xls','xlsx'])){
                    this.$message.error(this.$t('importFile.uploadExcel'));
                    return false;
                }
                //判断上传的视频大小
                if(file.size / 1024 /1024 > 2) {
                    this.$message.error(this.$t('importFile.fileSize'));
                    return false;
                }
                this.fileName = file.name;
                if(this.fileName !== ''){
                    this.haveFile = true;
                }
            } 
        },
        // 上传失败
        uploadError(){
            this.$message.error(this.$t('importFile.uploadFail'));
        },
        uploadSuccess(res){
            if(res.code == '0'){
                this.$message.success(this.$t('importFile.uploadSuccess'));
                this.$emit('uploadFileSuccess');
            } else {
                this.$message.error(res.message);
            }
        },
        // 删除文件
        delFile(){
            this.fileName = '';
            this.haveFile = false;
        },
        // 下载模板
        downloadTemplate(){
            window.location.href = 'https://static.guoranbot.com/statistics/%E6%A8%A1%E6%9D%BF%EF%BC%88%E7%AC%AC%E4%B8%80%E8%A1%8C%E8%A1%A8%E5%A4%B4%E5%8B%BF%E5%88%A0%EF%BC%89.xlsx';
        },
        // 保存
        submitForm(){
            let flag = true;
            if(this.fileName === ''){
                flag = false;
                this.$message.error(this.$t('importFile.fileNameMsg'))
				return false;
            }
            if(flag){
                this.$refs.uploadFile.submit();  // 手动上传
            } 
        }
    }

}
</script>

<style scoped lang="less">
    #import-file{
        ::v-deep #popup-assembly{
            width: 646px;
             .popup-header{
                padding: 55px 50px 50px !important;
            }
            .popup-container{
                overflow-y: inherit !important;
            }
        }
        ::v-deep .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
        .content-import-file{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .tips{
                text-align: left;
                //white-space: nowrap;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 36px;
                >span:first-child{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: #A1B9FF;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 20px;
                    color: #fff;
                    margin-right: 5px;
                }
            }
            .have-file{
                margin: 20px 0;
                display: flex;
                .el-input{
                    margin-right: 10px;
                }

            }
            .choose-file{
                width: 116px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #B7CAFF;
                border-radius: 19px;font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                text-align: center;
                line-height: 40px;
                margin: 25px 0;
            }
            .no-template{
                height: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                text-align: left; 
                padding-bottom: 26px;
                span{
                    color: #366AFF;
                    cursor: pointer;
                }
            }
            .upload-demo{
                display: flex;
                justify-content: flex-start;
            }
        }
        ::v-deep .dialog-footer{
            padding-top: 46px;
            .el-button{
                width: 99px;
                height: 37px;
                padding: 0;
            }
            .have{
                background: #366AFF;
                border-color: #366AFF;
            }
            .no{
                background: #E0E5F0;
                border-color: #E0E5F0;
            }
        }
    }
</style>