<template>
    <div class="actionQuestion">
        <div class="rhetorical-question-input">
            <div class="tal">{{$t('flowComponent.actionMail.rhetoricalQuestion')}}</div>
            <div
                class="rhetorical-question-input-cell"
                v-for="(q_item_text, q_item_text_index) in unit.content
                    .questions"
                :key="q_item_text_index"
            >
                <!-- <el-input
                    autosize
                    type="textarea"
                    v-model="unit.content.questions[q_item_text_index]"
                    placeholder="请输入要反问的问题"
                ></el-input> -->
                <!-- <ckeditor :ref="'auCkeditor_' + q_item_text_index" class="gr-answer-text-ckeditor" :editor="editorInline"
                    :config="editorConfig" v-model="unit.content.questions[q_item_text_index]" @focus="onEditorFocus">
                </ckeditor>
                <span @click="templateConfigVisibleHandle('usual-aq-placeholder', q_item_text_index)" class="insert-var">
                    <i class="iconfont guoran-tongyichicun-bianliang"></i>
                </span> -->
                <input-insertentity class="answer-text-cell-input-insertentity" :placeholderType="placeholderType" :ref="'inputInsertaut' + q_item_text_index" :unit="unit" :unitIndex="unitIndex" :textCellInd="q_item_text_index"
                    @templateConfigVisibleHandle="templateConfigVisibleHandle"></input-insertentity>
                <span
                    v-if="q_item_text_index === 0"
                    @click="handleActionQuestionCellTextItem('add')"
                    class="answer-text-cell-handle-icon-add"
                >
                    <i class="el-icon-plus"></i>
                </span>
                <span
                    v-else
                    @click="
                        handleActionQuestionCellTextItem(
                            'delete',
                            q_item_text_index
                        )
                    "
                    class="answer-text-cell-handle-icon-delete"
                >
                    <i class="el-icon-close"></i
                ></span>
            </div>
        </div>
        <div class="rhetorical-question-entity">
            <div class="cell_55">
                <div class="rhetorical-question-entity-cell">
                    <span class="rqec-title">{{$t('flowComponent.actionMail.detectingEntities')}}</span>
                    <el-cascader
                        :placeholder="$t('flowComponent.searchEntity')"
                        size="small"
                        v-model="unit.content.entity"
                        :key="`action_q_entity_e_${index}`"
                        :options="entityOptions"
                        @change="handleChangeEntity"
                        filterable
                    ></el-cascader>
                </div>
                <div class="rhetorical-question-entity-cell margin-left-12">
                    <span class="rqec-title">{{$t('flowComponent.actionMail.rhetoricalQuestionsNumber')}}</span>
                    <el-select
                        size="small"
                        v-model="unit.content.questionNum"
                        :placeholder="$t('common.selectPlaceholder')"
                    >
                        <el-option
                            v-for="item in noGetParamQNumber"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
            </div>

            <div class="rhetorical-question-entity-cell">
                <span class="rqec-title-top">{{$t('flowComponent.existenceEntity')}}</span>
            </div>
            <div class="simple-cell-satisfaction">
                <div class="jump-cell">
                    <el-select
                        v-model="unit.content.goToAction.scope"
                        size="small"
                        @change="questionJumpTypeValueChange"
                        :placeholder="$t('common.selectPlaceholder')"
                    >
                        <el-option
                            v-for="item in jumpTypeOptionsE"
                            :key="item.value"
                            :label="$t('common.' + item.value)"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <div class="right-select">
                        <!-- <el-select
                            v-show="unit.content.goToAction.scope === 'NODE'"
                            v-model="unit.content.goToAction.value"
                            @change="nodeSelectChange"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            v-if="unit.content.goToAction.scope === 'NODE'"
                            :nodeOptions="nodeOptions"
                            :nodeId="unit.content.goToAction.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionQue_op_${index}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeIdE"
                            :index="index"
                        ></SelectAddnode>
                        <el-cascader
                            filterable
                            v-show="unit.content.goToAction.scope === 'INTENT'"
                            v-model="unit.content.goToAction.value"
                            :options="intentTree4Radio"
                            :key="`action_q_getEnty_e_${index}`"
                            :placeholder="$t('flowComponent.confirmValue.jumpIntentPla')"
                            size="small"
                            :props="{
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                        <el-input
                            size="small"
                            v-show="unit.content.goToAction.scope === 'TEXT'"
                            v-model="unit.content.goToAction.value"
                            maxlength="2000"
                            :placeholder="$t('flowComponent.textAnswer')"
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="rhetorical-question-entity-cell">
                <span class="rqec-title-top">{{$t('flowComponent.noEntityExists')}}</span>
            </div>
            <div class="simple-cell-satisfaction">
                <div class="jump-cell">
                    <el-select
                        v-model="unit.content.entityNotFountAction.type"
                        size="small"
                        @change="questionJumpTypeValueChange"
                        :placeholder="$t('common.selectPlaceholder')"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            :key="item.value"
                            :label="$t('common.' + item.value)"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <div class="right-select">
                        <!-- <el-select
                            v-if="
                                unit.content.entityNotFountAction.type ===
                                'GOTO_NODE'
                            "
                            v-model="
                                unit.content.entityNotFountAction.gotoNode.id
                            "
                            @change="nodeSelectChange"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            v-if="
                                unit.content.entityNotFountAction.type ===
                                'GOTO_NODE'
                            "
                            :nodeOptions="nodeOptions"
                            :nodeId="
                                unit.content.entityNotFountAction.gotoNode.id
                            "
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionQue_opn_${index}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeIdNE"
                            :index="index"
                        ></SelectAddnode>
                        <el-cascader
                            filterable
                            v-if="
                                unit.content.entityNotFountAction.type ===
                                'GOTO_INTENT'
                            "
                            v-model="
                                unit.content.entityNotFountAction.gotoIntent.id
                            "
                            :options="intentTree4Radio"
                            :key="`action_q_nogetEnty_e_${index}`"
                            :placeholder="$t('flowComponent.confirmValue.jumpIntentPla')"
                            size="small"
                            :props="{
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                        <el-input
                            size="small"
                            v-if="
                                unit.content.entityNotFountAction.type ===
                                'SEND_MSG_TO_USER'
                            "
                            v-model="
                                unit.content.entityNotFountAction.sendMsgToUser
                                    .content
                            "
                            maxlength="2000"
                            :placeholder="$t('flowComponent.textAnswer')"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="unit.content.entityNotFountAction.type === 'RECOGNIZE_INTENT'"
            class="and-step-box"
        >
            <span>{{$t('flowComponent.andInThe')}}</span>
            <el-input-number
                size="mini"
                :precision="0"
                :min="1"
                :max="100"
                v-model="
                    unit.content.entityNotFountAction.recognizeIntent
                        .roundToContinue
                "
                :step="1"
            ></el-input-number>
            <span>{{$t('flowComponent.andInTheTip')}}</span>
        </div>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

// import {
//     lastEditor,
//     currentEditor,
//     changeEditor,
// } from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
import InputInsertentity from "./../other/InputInsertentity.vue";
import SelectAddnode from "../cell/SelectAddnode.vue";
export default {
    name: "aQuestion",
    components: {
        SelectAddnode,
        InputInsertentity
    },
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "index",
        "nodeId",
        "intentId",
        "entityOptions",
        "unitIndex"
    ],
    data() {
        return {
            placeholderType: 'usual-aq-placeholder',
            noGetParamQNumber: [
                { name: 1, id: 1 },
                { name: 2, id: 2 },
                { name: 3, id: 3 },
                { name: 4, id: 4 },
                { name: 5, id: 5 },
                { name: "直到检测到实体", id: -1 },
            ],
            jumpTypeOptionsE: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            jumpTypeOptions: [
                {
                    value: "RECOGNIZE_INTENT",
                    label: "识别用户意图",
                },
                {
                    value: "GOTO_NODE",
                    label: "跳转到节点",
                },
                {
                    value: "GOTO_INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "回复文本",
                },
            ],
            editorDataInline: "",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
            currentEditorId: "",
            currentEditorPosition: "",
        };
    },
    methods: {
        // 操作反问类型答案cell中单条文案
        handleActionQuestionCellTextItem(type, q_item_text_index) {
            if (type === "delete") {
                this.unit.content.questions.splice(q_item_text_index, 1);
            }
            if (type === "add") {
                console.log(this.index);
                this.unit.content.questions.push("");
            }
        },
        // 改版反问跳转类型切换
        questionJumpTypeValueChange() {
            // this.unit.content.entityNotFountAction.type = "";
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeIdE(nodeId) {
            this.$set(this.unit.content.goToAction, "value", nodeId);
        },
        setAddNodeIdNE(nodeId) {
            this.$set(
                this.unit.content.entityNotFountAction.gotoNode,
                "id",
                nodeId
            );
        },

        templateConfigVisibleHandle(data) {
            let param = { type: data.type };
            param.unitIndex = this.unitIndex;
            param.lastEditor = null;
            param.multiple = false;
            param.webhookId_key = [""];
            param.cellIndex = data.cellIndex;
            this.$emit("templateConfigVisibleHandle", param);
        },
        onEditorFocus(zh, editor) {
            changeEditor(editor);
        },
        // addConfirmDataResponse() {
        //     let command = currentEditor.commands.get("insertAskComponent");
        //     command.execute({
        //         tag: "span-editable",
        //         options: {
        //             name: "单个webhook返回值",
        //             editable: false,
        //             data: webHookDataHolder(51, "data.name"),
        //         },
        //     });
        // },

        addWebHookSinglePlace(data, webhookId, cellIndex) {
            this.$refs['inputInsertaut' + cellIndex][0].addWebHookSinglePlace(data, webhookId);
        },

        addConfirmDataEntity(data, editable, cellIndex) {
            this.$refs['inputInsertaut' + cellIndex][0].addConfirmDataEntity(data, editable);
        },
    },
    mounted() {
        // setTimeout(() => {
        //     this.$refs['auCkeditor_' + 0][0].$el.focus();
        //     this.currentEditorId = currentEditor.id;
        //     this.currentEditorPosition = "end";
        //     // this.$refs['auCkeditor_' + 0][0].$el.blur();
        // }, 300);
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";
.actionQuestion {
    .answer-text-cell-input-insertentity{
        width: calc(100% - 40px);
    }
    .rqec-title-top {
        display: block;
        margin-top: 12px;
        margin-bottom: 8px;
        color: #616161;
        text-align: left;
    }
    .jump-cell {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        background-color: white;
        border-radius: 5px;
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
        .left-select {
            width: 120px;
            flex: none;
        }
        .right-select {
            flex: auto;
            .el-select {
                width: 100%;
            }
        }
    }
    .cell_55 {
        display: flex;
        .rhetorical-question-entity-cell {
            flex: 1;
            display: flex;
            flex-direction: column;
            .rqec-title {
                margin-top: 12px;
                margin-bottom: 8px;
                color: #616161;
                text-align: left;
            }
        }
        .margin-left-12 {
            margin-left: 12px;
        }
    }
    .and-step-box {
        text-align: left;
        margin-top: 8px;
        padding: 0 12px;
        height: 58px;
        //line-height: 58px;
        background: #fbfcfd;
        border-radius: 5px;
        display: flex;
        align-items: center;
        .el-input-number {
            width: 100px !important;
            margin: 0 4px;
          flex: none;
        }
        .el-input {
            width: 30px;
        }
    }
    .rhetorical-question-input {
        .tal {
            margin-bottom: 6px;
            color: #616161;
        }
        .el-select--small {
            width: 100% !important;
        }
        .rhetorical-question-input-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 4px 0;
            .el-select--small {
                flex: none;
                width: 100% !important;
            }
        }
    }

    .gr-answer-text-ckeditor {
        width: 95%;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding-right: 38px;
      height: auto;
    }
    .ck-focused {
        border: 1px solid #366aff;
    }
    .insert-var {
        color: black;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-color: #DCDFE6;
        border-radius: 4px;
        cursor: pointer;
        margin-left: -32px;
        margin-right: 6px;
    }
}
</style>