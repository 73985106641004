<template>
    <div class="body-content">
        <div class="expired-title">版本过期</div>
        <div class="expired-title-des">系统版本已过期，请及时续费</div>
        <div class="content-img">
            <img src="./../../assets/images/u1215.png">
        </div>
        <div class="content-text" 
            v-clipboard:copy="'4001-789-800'"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError">
            拨打 4001-789-800 续费
        </div>
    </div>
</template>
<script>
export default{
    methods:{
        // Copy成功
        onCopy(e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            this.$message.error("抱歉，复制失败！");
        },
    }
        
}
    
</script>
<style lang="less" scoped>
.body-content{
    background-color: #f2f4f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .expired-title{
        font-size: 24px;
        font-weight: 600;
        margin: 16px 0 0;
    }
    .expired-title-des{
        color: gray;
        margin-bottom: 30px;
    }
    .content-img{
        width: 50%;
        margin-bottom: 20px;
        img{
            width: 500px;
        }
    }
    .content-text{
        cursor: pointer;
        padding: 0 16px;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        background-color: #366aff;
    }
}
</style>
