import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/IndexV2.vue'; // new-ui
import Home from '@/views/home/home.vue'
import PersonalV2 from '@/views/personal/PersonalV2.vue'; // new-ui
import Personal from '@/views/personal/personal.vue'
// import accountManageV2 from '@/views/companyManage/accountManage/AccountManageU.vue' // new-ui
import wxAccount from '@/views/companyManage/wxAccount/wxAccount.vue'
import companyInfo from '@/views/companyManage/companyInfo/companyInfo.vue'
import staffManageV2 from '@/views/companyManage/staffManage/StaffDepartment.vue' // new-ui
import staffManage from '@/views/companyManage/staffManage/staffManage.vue'
import myInfo from '@/views/myInfo/myInfo.vue'
import Auth from '@/views/auth/Auth.vue'
import CheckAuth from '@/views/auth/CheckAuth.vue'
import Expired from '@/views/auth/Expired.vue'
import RoleManage from '@/views/companyManage/roleManage/roleManage.vue'
import RoleManageV2 from '@/views/companyManage/roleManage/roleManageU.vue' // new-ui
import AddRole from "@/views/companyManage/roleManage/components/addRole";
import AddRoleV2 from "@/views/companyManage/roleManage/components/addRoleU"; // new-ui
import dataRole from "@/views/companyManage/dataRoleManage/DataRole";
import dataRoleV2 from "@/views/companyManage/dataRoleManage/DataRoleU"; // new-ui
import ForgetPassword from "@/views/auth/ForgetPassword";
import Register from "@/views/auth/Register";
import Appointment from "@/views/auth/Appointment";
import Version from "@/views/version/Version";
import AddNotice from "@/views/version/AddNotice"; // 添加更新公告
import Notice from "@/views/version/Notice"; // 更新公告列表
import Registerview from "@/views/auth/Registerview"; // 用于产品组件演示
import upDownManage from '@/views/companyManage/upDownManage/upDownManage.vue'
import upDownManageV2 from '@/views/companyManage/upDownManage/upDownManageV2.vue'
import personalContent from '@/views/personal/components/personalContent'
import help from '@/views/help/help.vue';
import workTime from "../views/companyManage/workTime/workTime";
import workDuty from "../views/companyManage/workDuty/workDuty";
import inviteEnterEnterprise from "../views/companyManage/inviteEnterEnterprise/inviteEnterEnterprise";
import inviteEnterLogin from "../views/auth/inviteEnterLogin/login.vue";
import capacityManage from "../views/companyManage/capacityAndFlowManage/capacityFlowManage.vue";
import viewFile from "../views/viewFile/viewFile.vue";
import inviteQrcode from "../views/viewFile/inviteQrcode.vue";
import inviteInfo from "../views/viewFile/inviteInfo.vue";
import accountManageCustom from '@/views/companyManage/accountManageCustom/index.vue' // 账号管理自定义字段
import accountTemplate from '@/views/companyManage/accountManageCustom/accountTemplateManage.vue' 
import accountFieldType from '@/views/companyManage/accountManageCustom/accountFieldType.vue' 
import setLanguage from '@/views/companyManage/setLanguage/index.vue'
import channel from "@/views/channelManage/channel";
import accessConfiguration from "@/views/channelManage/accessConfiguration";
import accessDetails from "../views/channelManage/accessDetails";
import knowledgeWelcomeMessage from "../views/knowledgeWelcomeMessage";
import IntentionalWelcomeMessage from "../views/intentionalWelcomeMessage";
import IntelligentAgentConfig from "../views/channelManage/intelligentAgentConfig";
/**
 * 重写路由的push方法
 * 解决:vue在3.0以上的版本中的错误提示，不允许从当前路由跳转到当前路由
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // 这个语句用来解决报错 调用原来的push函数，并捕获异常
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
let routesList = [
    {
        path: '/index',
        name: 'Index',
        meta: { title: '主页 - AskBot管理系统' },
        component: Index,
    },
    {
        path: '/',
        name: 'Index',
        meta: { title: '主页 - AskBot管理系统' },
        component: Index,
    },
    {
        path: '/addnotice',
        name: 'Addnotice',
        meta: { title: 'askbot - 新增发布公告' },
        component: AddNotice,
    },
    {
        path: '/notice',
        name: 'notice',
        meta: { title: 'askbot - 更新公告' },
        component: Notice,
    },
    {
        path: '/version',
        name: 'version',
        meta: { title: '产品与服务 - AskBot服务版本' },
        component: Version
    },
    {
        path: '/personal',
        name: 'personal',
        meta: { title: '个人中心 - AskBot管理系统' },
        component: Personal
    },
    {
        path: '/_personal',
        name: '_personal',
        meta: { title: '个人中心 - AskBot管理系统' },
        component: PersonalV2
    },
    // {
    //     path: '/_accountManage',
    //     name: 'accountManageu',
    //     meta: { title: '账号管理 - AskBot管理系统' },
    //     component: accountManageV2
    // },
    {
        path: '/_accountManage',
        name: 'accountManageu',
        meta: { title: '账号管理 - AskBot管理系统' },
        component: accountManageCustom
    },
    
    {
        path: '/_dataRoleManage',
        name: '_dataRoleManage',
        meta: { title: '数据权限管理 - AskBot工单系统' },
        component: dataRoleV2
    },
    {
        path: '/dataRoleManage',
        name: 'dataRoleManage',
        meta: { title: '数据权限管理 - AskBot工单系统' },
        component: dataRole
    },

    {
        path: '/wxAccount',
        name: 'wxAccount',
        meta: { title: '微信客服账号' },
        component: wxAccount
    },
    {
        path: '/_wxAccount',
        name: '_wxAccount',
        meta: { title: '微信客服账号' },
        component: wxAccount
    },
    {
        path: '/companyInfo',
        name: 'companyInfo',
        meta: { title: '企业管理 - AskBot工单系统' },
        component: companyInfo
    },

    {
        path: '/_staffManage',
        name: '_staffManage',
        meta: { title: '员工管理 - AskBot工单系统' },
        component: staffManageV2
    },
    {
        path: '/staffManage',
        name: 'staffManage',
        meta: { title: '员工管理 - AskBot工单系统' },
        component: staffManage
    },
    {
        path: '/_roleManage',
        name: '_roleManage',
        meta: { title: '角色管理 - AskBot工单系统' },
        component: RoleManageV2
    },
    {
        path: '/roleManage',
        name: 'roleManage',
        meta: { title: '角色管理 - AskBot工单系统' },
        component: RoleManage
    },
    {
        path: '/_addRole',
        name: '_addRole',
        meta: { title: '新增角色 - AskBot工单系统' },
        component: AddRoleV2
    },
    {
        path: '/addRole',
        name: 'addRole',
        meta: { title: '新增角色 - AskBot工单系统' },
        component: AddRole
    },
    {
        path: '/myInfo',
        name: 'myInfo',
        meta: { title: '个人信息 - AskBot工单系统' },
        component: myInfo
    },
    {
        path: '/_workTime',
        name: 'workTime',
        meta: { title: '工作时间 - AskBot工单系统' },
        component: workTime
    },
    {
        path: '/setLanguage',
        name:'setLanguage',
        meta: { title: '系统语言 - AskBot工单系统' },
        component: setLanguage
    },
    {
        path: '/_workDuty',
        name: 'workDuty',
        meta: { title: '排版管理 - AskBot工单系统' },
        component: workDuty
    },
    {
        path: '/_inviteEnterEnterprise',
        name: 'inviteEnterEnterprise',
        meta: { title: '排版管理 - AskBot工单系统' },
        component: inviteEnterEnterprise
    },
    {
        path: '/auth',
        name: 'auth',
        component: Auth
    },
    {
        path: '/login',
        name: 'CheckAuth',
        component: CheckAuth
    },
    {
        path: '/expired',
        name: 'expired',
        component: Expired
    },
    {
        path: '/forgetPassword',
        name: 'ForgetPassword',
        component: ForgetPassword
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/registerview',
        name: 'Registerview',
        component: Registerview
    },
    {
        path: '/appointment',
        name: 'Appointment',
        component: Appointment
    },
    {
        path: '/upDownManage',
        name: 'upDownManage',
        component: upDownManage
    },
    {
        path: '/_upDownManage',
        name: '_upDownManage',
        component: upDownManageV2
    },
    {
        path: '/help',
        name: 'help',
        component: help
    },
    {
        path: '/personalContent',
        name: 'personalContent',
        component: personalContent
    },
    {
        path: '/inviteEnterLogin',
        name: 'inviteEnterLogin',
        component: inviteEnterLogin
    },
    {
        path: '/_capacity',
        name: 'capacity',
        meta: { title: '容量管理 - AskBot工单系统' },
        component: capacityManage
    },
    {
        path: '/_flow',
        name: 'flow',
        meta: { title: '流量管理 - AskBot工单系统' },
        component: capacityManage
    },
    {
        path: '/viewFile',
        name: 'viewFile',
        component: viewFile
    },
    {
        path: '/inviteQrcode',
        name: 'inviteQrcode',
        component: inviteQrcode
    },
    {
        path: '/inviteInfo',
        name: 'inviteInfo',
        component: inviteInfo
    },
    {
        path: '/accountTemplate',
        name: 'accountTemplate',
        meta: { title: '账号模版 - AskBot管理系统' },
        component: accountTemplate
    },
    {
        path: '/accountFieldType',
        name: 'accountFieldType',
        meta: { title: '账号字段类型 - AskBot管理系统' },
        component: accountFieldType
    },
    {
        path:"/channel",
        name:"channel",
        meta:{ title: "渠道管理" },
        component: channel
    },
    {
        path:"/accessConfiguration",
        name:"accessConfiguration",
        meta: { title:"接入" },
        component: accessConfiguration
    },
    {
        path:"/accessDetails",
        name:"accessDetails",
        meta: {},
        component: accessDetails
    },
    {
        path:"/knowledgeWelcomeMessage",
        name:"knowledgeWelcomeMessage",
        meta:{},
        component: knowledgeWelcomeMessage
    },
    {
        path:"/intentWelcomeMessage",
        name:"intentWelcomeMessage",
        meta: {},
        component: IntentionalWelcomeMessage
    },
    {
        path:"/intelligentAgentConfig",
        name:"intelligentAgentConfig",
        meta: {},
        component: IntelligentAgentConfig
    }
    
]
// if (localStorage.getItem("turnOn") == "true" && JSON.parse(localStorage.getItem("wlMainIds")).indexOf(localStorage.getItem("_mainId")) !== -1) {
//     routesList = [
//         {
//             path: '/',
//             name: 'Index',
//             meta: { title: '主页 - AskBot管理系统' },
//             component: Index,
//         },
//         {
//             path: '/addnotice',
//             name: 'Addnotice',
//             meta: { title: 'askbot - 新增发布公告' },
//             component: AddNotice,
//         },
//         {
//             path: '/notice',
//             name: 'notice',
//             meta: { title: 'askbot - 更新公告' },
//             component: Notice,
//         },
//         {
//             path: '/version',
//             name: 'version',
//             meta: { title: '产品与服务 - AskBot服务版本' },
//             component: Version
//         },
//         {
//             path: '/personal',
//             name: 'personal',
//             meta: { title: '个人中心 - AskBot管理系统' },
//             component: PersonalV2
//         },
//         {
//             path: '/accountManage',
//             name: 'accountManage',
//             meta: { title: '账号管理 - AskBot管理系统' },
//             component: accountManageV2
//         },
//         {
//             path: '/dataRoleManage',
//             name: 'dataRoleManage',
//             meta: { title: '数据权限管理 - AskBot工单系统' },
//             component: dataRoleV2
//         },

//         {
//             path: '/wxAccount',
//             name: 'wxAccount',
//             meta: { title: '微信客服账号' },
//             component: wxAccount
//         },
//         {
//             path: '/companyInfo',
//             name: 'companyInfo',
//             meta: { title: '企业管理 - AskBot工单系统' },
//             component: companyInfo
//         },

//         {
//             path: '/staffManage',
//             name: 'staffManage',
//             meta: { title: '员工管理 - AskBot工单系统' },
//             component: staffManageV2
//         },
//         {
//             path: '/roleManage',
//             name: 'roleManage',
//             meta: { title: '角色管理 - AskBot工单系统' },
//             component: RoleManageV2
//         },
//         // {
//         //   path:'/workTime',
//         //   name:'workTime',
//         //   meta: {title: '工作时间 - AskBot工单系统'},
//         //   component: workTime
//         // },
//         {
//             path: '/addRole',
//             name: 'addRole',
//             meta: { title: '新增角色 - AskBot工单系统' },
//             component: AddRoleV2
//         },
//         {
//             path: '/myInfo',
//             name: 'myInfo',
//             meta: { title: '个人信息 - AskBot工单系统' },
//             component: myInfo
//         },
//         {
//             path: '/auth',
//             name: 'auth',
//             component: Auth
//         },
//         {
//             path: '/login',
//             name: 'CheckAuth',
//             component: CheckAuth
//         },
//         {
//             path: '/expired',
//             name: 'expired',
//             component: Expired
//         },
//         {
//             path: '/forgetPassword',
//             name: 'ForgetPassword',
//             component: ForgetPassword
//         },
//         {
//             path: '/register',
//             name: 'Register',
//             component: Register
//         },
//         {
//             path: '/registerview',
//             name: 'Registerview',
//             component: Registerview
//         },
//         {
//             path: '/appointment',
//             name: 'Appointment',
//             component: Appointment
//         },
//         {
//             path: '/upDownManage',
//             name:'upDownManage',
//             component: upDownManage
//         },
//     ]
// } else {
//     routesList = [
//         {
//             path: '/',
//             name: 'Home',
//             meta: { title: '主页 - AskBot工单系统' },
//             component: Home,
//         },
//         {
//             path: '/dataRoleManage',
//             name: 'dataRoleManage',
//             meta: { title: '数据权限管理 - AskBot工单系统' },
//             component: dataRole
//         },
//         {
//             path: '/accountManage',
//             name: 'accountManage',
//             meta: { title: '账号管理 - AskBot工单系统' },
//             component: accountManage
//         },
//         {
//             path: '/wxAccount',
//             name: 'wxAccount',
//             meta: { title: '微信客服账号' },
//             component: wxAccount
//         },
//         {
//             path: '/companyInfo',
//             name: 'companyInfo',
//             meta: { title: '企业管理 - AskBot工单系统' },
//             component: companyInfo
//         },
//         {
//             path: '/personal',
//             name: 'personal',
//             component: Personal
//         },
//         {
//             path: '/staffManage',
//             name: 'staffManage',
//             meta: { title: '员工管理 - AskBot工单系统' },
//             component: staffManage
//         },
//         {
//             path: '/roleManage',
//             name: 'roleManage',
//             meta: { title: '角色管理 - AskBot工单系统' },
//             component: RoleManage
//         },
//         // {
//         //   path:'/workTime',
//         //   name:'workTime',
//         //   meta: {title: '工作时间 - AskBot工单系统'},
//         //   component: workTime
//         // },
//         {
//             path: '/addRole',
//             name: 'addRole',
//             meta: { title: '新增角色 - AskBot工单系统' },
//             component: AddRole
//         },
//         {
//             path: '/myInfo',
//             name: 'myInfo',
//             meta: { title: '个人信息 - AskBot工单系统' },
//             component: myInfo
//         },
//         {
//             path: '/auth',
//             name: 'auth',
//             component: Auth
//         },
//         {
//             path: '/login',
//             name: 'CheckAuth',
//             component: CheckAuth
//         },
//         {
//             path: '/expired',
//             name: 'expired',
//             component: Expired
//         },
//         {
//             path: '/forgetPassword',
//             name: 'ForgetPassword',
//             component: ForgetPassword
//         },
//         {
//             path: '/register',
//             name: 'Register',
//             component: Register
//         },
//         {
//             path: '/registerview',
//             name: 'Registerview',
//             component: Registerview
//         },
//         {
//             path: '/appointment',
//             name: 'Appointment',
//             component: Appointment
//         },
//         {
//             path: '/upDownManage',
//             name:'upDownManage',
//             component: upDownManage
//         },
//     ]
// }

export default new VueRouter({
    routes: routesList
})
