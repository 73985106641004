<template>
  <div>
      <popup @closeEvent="closeEvent" v-if="showPopup" class="choose-dept">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.chooseDeptTitle')}}</div>
            <div slot="popup-con">
				<el-input
					:placeholder="$t('common.search')"
					v-model="filterText">
				</el-input>
				<el-tree
					key="chooseDept"
					ref="chooseDept"
					:filter-node-method="filterNode"
					:data="treeDataCopy"
					@node-click="onNodeClick"
					:expand-on-click-node="false">
						<span
								class="custom-tree-node"
								slot-scope="{node,data}">
							<span class="custom-tree-node-left">
								<!-- <span>
									<i class="arsenal_icon arsenalsitemap1"></i>
								</span> -->
								<span class="data-label" >{{data.label}}</span>
							</span>
							<span v-show="data.checked">
								<i class="el-icon-check"></i>
							</span>
						</span>
                </el-tree>
            
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveChooseDept" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
  </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
import mixins  from '../../upDownManage/mixins'  
export default {
	mixins:[mixins],
	props:{
		treeData:{
            type:Array,
            default(){
                return []
            }
        },
		showPopup:{
			type:Boolean,
			default:false
		},
		isMultiple:{
			type:Boolean,
			default:true
		},
	},
    components:{
		Popup
	},
    data(){
        return {
            checkedDeptPopup:[],
			treeDataCopy:[],
			isMultiple:false,
			filterText:''
        }
    },
	watch:{
		showPopup:{
			handler(n){
				if(n){
					this.treeDataCopy = JSON.parse(JSON.stringify(this.treeData));
					console.log(this.treeDataCopy,'nnnn');
				}
			},
			// immediate:true
		},
		filterText(val) {
			this.$refs.chooseDept.filter(val);
		}
	},
    methods:{
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		},
        // 关闭选择组织架构弹框
		closeEvent(){
            this.$emit('update:showPopup',false)
		},
		// 保存组织架构
		saveChooseDept(){
			this.checkedDeptPopup = [];
			this.judgeChildrens(this.treeDataCopy);
            this.$emit('checkedDept',this.checkedDeptPopup)
		},
		// 点击tree
		onNodeClick(data,node,el) {
			this.treeDataCopy = this.handlerCheck(this.treeDataCopy,data,false);
			this.$set(data,'checked',true);
		},
		handlerCheck(treeData,data){
			treeData.forEach((item,index) => {
				item.checked = false;
				if(item.children && item.children.length > 0){
					this.handlerCheck(item.children)
				}
			})
			return treeData
		}
    }
}
</script>

<style lang="less" scoped>
.choose-dept{
	.el-input{
		margin-bottom: 10px;
	}
	/deep/.el-tree{
		// .el-tree-node{
		// 	&.is-focusable{
		// 		background-color: #fff !important;
		// 	}
		// }
		.el-tree-node__content{
			height: 38px !important;
			background-color: #fff!important;
			&:hover{
				background-color: #f6f8fd!important;
			}
			.el-icon-check{
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}

</style>