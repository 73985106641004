<template>
    <div v-if="visible" id="role-detail-list">
        <p class="class-title">机器人权限</p>
        <div
            class="r-d-cell-bot"
            v-for="(bot, index) in botSkillInfoList"
            :key="index"
        >
            <div class="r-d-cell-bot-cell">
                <span class="name">{{ bot.botName }}:</span>
                <span
                    v-for="(skill, skillIndex) in bot.skillList"
                    :key="skillIndex"
                    class="skill"
                >
                    <em v-if="skillIndex != 0"> / </em>
                    {{ skill }}
                </span>
            </div>
        </div>
        <br />
        <p class="class-title">工单权限</p>
        <div
            v-for="(cell, cellIndex) in tampWorkOrderList"
            :key="cellIndex"
            class="r-d-cell-content-c"
        >
            <div
                v-if="
                    cell.workOrderName.length != 0 || (cell.fatherNodeType == 2 && tampWorkOrderList.length == 1) || cell.fatherNodeType == 1 
                "
            >
                <span class="r-d-cell-content-c-title"
                    >{{ cell.fatherNodeTag }}
                    <span v-if="cell.fatherNodeType != 2"> - </span>
                </span>
                <span
                    class="r-d-cell-content-c-text"
                    v-if="cell.fatherNodeType == 1"
                    >（含我创建的、分配给我的、我关注的工单）</span
                >
                <span
                    class="r-d-cell-content-c-text"
                    v-if="cell.fatherNodeType != 1"
                >
                    <span
                        v-for="(
                            innerCell, innerCellIndex
                        ) in cell.workOrderName"
                        :key="innerCell"
                        ><em v-if="innerCellIndex != 0"> / </em
                        >{{ innerCell }}</span
                    >
                </span>
            </div>
        </div>
        <br />
        <p class="class-title">工单数据权限</p>
        <div
            v-for="(cell, cellIndex) in tampWorkOrderStaList"
            :key="cellIndex"
            class="r-d-cell-content-c"
        >
            <div
                v-if="
                    cell.workOrderName.length != 0 || (cell.fatherNodeType == 1 || cell.fatherNodeType == 3)
                "
            >
                <span class="r-d-cell-content-c-title"
                    >{{ cell.fatherNodeTag }}
                    <span v-if="cell.fatherNodeType == 2"> - </span>
                </span>
                <span
                    class="r-d-cell-content-c-text"
                    v-if="cell.fatherNodeType == 2"
                >
                    <span
                        v-for="(
                            innerCell, innerCellIndex
                        ) in cell.workOrderName"
                        :key="innerCell"
                        ><em v-if="innerCellIndex != 0"> / </em
                        >{{ innerCell }}</span
                    >
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            infoList: [],
            botSkillInfoList: [],
            visible: false,
            tampWorkOrderStaList: []
        };
    },
    props: ["detailIds", "activeViewAcounp"],
    methods: {
        uniqueArr(arr1, arr2) {
            //合并两个数组
            arr1.push(...arr2); //或者arr1 = [...arr1,...arr2]
            //去重
            let arr3 = Array.from(new Set(arr1)); //let arr3 = [...new Set(arr1)]
            return arr3;
        },
        getInfoList() {
            this.visible = false;
            this.$http
                .get(
                    this.requestUrl.userDataPrivilege
                        .getUserDataPrivilegesAllList
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        console.log(res.data, "detailIds", this.detailIds, this.activeViewAcounp);
                        let tampInfoList = [],
                            tampBotSkillList = [],
                            
                            tampWorkOrderList = [
                                {
                                    fatherNodeTag: "我的工单",
                                    fatherNodeType: 1,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "所有工单",
                                    fatherNodeType: 2,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "指定应用提交的工单",
                                    fatherNodeType: 3,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "指定组工单（含子分组）",
                                    fatherNodeType: 4,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "指定模板的所有工单",
                                    fatherNodeType: 5,
                                    workOrderName: [],
                                },
                            ];

                            let tampWorkOrderStaList = [
                                {
                                    fatherNodeTag: "当前账号",
                                    fatherNodeType: 1,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "指定组",
                                    fatherNodeType: 2,
                                    workOrderName: [],
                                },
                                {
                                    fatherNodeTag: "全部",
                                    fatherNodeType: 3,
                                    workOrderName: [],
                                }
                            ]
                        this.infoList = [];
                        this.botSkillInfoList = [];
                        this.userDataPrivileges = res.data.data;
                        JSON.parse(this.detailIds).forEach((outId) => {
                            this.userDataPrivileges.forEach((item) => {
                                if (item.id == outId) {
                                    tampInfoList.push(item);
                                }
                            });
                        });
                        let isAll = false;
                        let staIsAll = false;
                        console.log(tampInfoList);
                        tampInfoList.forEach((item) => {
                            console.log(item);
                            JSON.parse(item.dataPrivileges).forEach(
                                (bot, botIndex) => {
                                    let flag = -1;
                                    tampBotSkillList.forEach(
                                        (tampBot, tampBotIndex) => {
                                            if (bot.botId == tampBot.botId) {
                                                flag = [botIndex, tampBotIndex];
                                            }
                                        }
                                    );
                                    if (flag == -1) {
                                        tampBotSkillList.push(bot);
                                    } else {
                                        tampBotSkillList[flag[1]].skillList =
                                            this.uniqueArr(
                                                tampBotSkillList[flag[1]]
                                                    .skillList,
                                                bot.skillList
                                            );
                                    }
                                }
                            );
                            item.userWorkOrderPrivileges.forEach(
                                (privili, priviliIndex) => {
                                    console.log(privili.fatherNodeType);
                                    if (privili.fatherNodeType != 2) {
                                        tampWorkOrderList[
                                            privili.fatherNodeType - 1
                                        ].workOrderName = this.uniqueArr(
                                            tampWorkOrderList[
                                                privili.fatherNodeType - 1
                                            ].workOrderName,
                                            privili.workOrderName
                                        );
                                    } else {
                                        isAll = true;
                                    }
                                }
                            );
                            console.log(item.userWorkOrderStatisticsPrivileges);
                            item.userWorkOrderStatisticsPrivileges.forEach(
                                (privili, priviliIndex) => {
                                    console.log(privili.fatherNodeType);
                                    if (privili.fatherNodeType == 2) {
                                        tampWorkOrderStaList[1].workOrderName = this.uniqueArr(
                                            tampWorkOrderStaList[1].workOrderName,
                                            privili.workOrderName
                                        );
                                        this.tampWorkOrderStaList[1] = tampWorkOrderStaList[1];
                                    }
                                    if (privili.fatherNodeType == 3){
                                        staIsAll = true;
                                    }
                                }
                            );
                        });
                        if (isAll) {
                            tampWorkOrderList = [
                                {
                                    fatherNodeTag: "所有工单",
                                    fatherNodeType: 2,
                                    workOrderName: [],
                                },
                            ];
                        }
                        if (staIsAll) {
                            this.tampWorkOrderStaList = [
                                {
                                    fatherNodeTag: "全部",
                                    fatherNodeType: 3,
                                    workOrderName: [],
                                }
                            ]
                        } else {
                            this.tampWorkOrderStaList[0] = {
                                fatherNodeTag: "当前账号",
                                fatherNodeType: 1,
                                workOrderName: [],
                            }
                        }
                        this.botSkillInfoList = [...tampBotSkillList];
                        tampWorkOrderList.forEach(item => {
                            if(item.workOrderName.length != 0 || (item.fatherNodeType == 2 && tampWorkOrderList.length == 1) || item.fatherNodeType == 1 ){
                            } else {
                                if(item.fatherNodeType == 4 && JSON.parse(this.detailIds).indexOf(99999) !== -1){
                                    item.workOrderName = ['当前账号组']
                                }
                            }
                        })
                        if (JSON.parse(this.detailIds).indexOf(99999) !== -1) {
                            let exitZdz = false;
                            this.tampWorkOrderStaList.forEach(item => {
                                if (item.fatherNodeType == 2) {
                                    exitZdz = true;
                                }
                            })
                            if (!exitZdz) {
                                this.tampWorkOrderStaList.push(
                                    {
                                        fatherNodeTag: "指定组",
                                        fatherNodeType: 2,
                                        workOrderName: ["当前账号组"],
                                    }
                                )
                            }
                        }
                        this.tampWorkOrderList = [...tampWorkOrderList];
                        console.log(this.tampWorkOrderStaList);
                        this.visible = true;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
#role-detail-list {
    text-align: left;
    .class-title {
        font-weight: 700;
        margin: 8px 0;
        font-size: 15px;
    }
    .r-d-cell-bot {
        margin-bottom: 8px;
        .r-d-cell-bot-cell {
            .name {
                font-weight: 500;
            }
            .skill {
                font-size: 14px;
            }
        }
    }
    .r-d-cell-content-c-title {
        font-weight: 500;
    }
    .r-d-cell-content-c-text {
        font-size: 14px;
    }
    .r-d-cell {
        margin-bottom: 24px;
        .r-d-cell-title {
            display: block;
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 6px;
        }
        .r-d-cell-content {
            .r-d-cell-content-c {
                margin-bottom: 6px;
            }
        }
    }
}
</style>