<template>
    <div id="home">
        <div class="content-container">
            <div class="header">
                <div class="leftInfo">
                    <span class="headerName">AskBot管理中心</span>
                </div>
            </div>
            <div class="content">
                <div class="left-content">
                    <div class="title">
                        <!-- <span v-show="isCertified" class="opened-service">已开通应用</span> -->
                        <span v-show="userCompany.status == 1"
                            >亲爱的{{
                                realname == null || realname == "null"
                                    ? username
                                    : realname
                            }}，您的企业认证
                            <el-tag effect="dark" size="small">审核中</el-tag>
                            ，认证结果会在1~2个工作日内以短信及邮件的方式通知您，请您耐心等待。</span
                        >
                        <span
                            v-show="userCompany.status == 2 && !serviceExpired"
                            >亲爱的{{
                                realname == null || realname == "null"
                                    ? username
                                    : realname
                            }}，您的企业
                            <el-tag effect="dark" size="small">已认证</el-tag>
                            ，已为您开通以下服务</span
                        >
                        <span v-show="userCompany.status == 3"
                            >亲爱的{{
                                realname == null || realname == "null"
                                    ? username
                                    : realname
                            }}，您的企业
                            <el-tag effect="dark" size="small" type="danger"
                                >认证未通过</el-tag
                            >
                            ，<el-button
                                size="mini"
                                type="danger"
                                @click="retryAuth"
                                >继续认证</el-button
                            >如有疑问，请联系159-1108-6419。</span
                        >
                        <span v-show="userCompany.status == 0"
                            >亲爱的{{
                                realname == null || realname == "null"
                                    ? username
                                    : realname
                            }}，您的企业
                            <el-tag effect="dark" size="small" type="info"
                                >未认证</el-tag
                            >
                            ，认证通过后，您才以正常使用，请您尽快认证。</span
                        >
                        <el-button
                            v-show="userCompany.status == 0"
                            size="mini"
                            type="danger"
                            @click="retryAuth"
                            plain
                            >立即认证</el-button
                        >
                        <span v-show="userCompany.status == 2 && serviceExpired"
                            >亲爱的{{
                                realname == null || realname == "null"
                                    ? username
                                    : realname
                            }}，您的部分服务已到期，如需继续使用，请联系159-1108-6419。</span
                        >
                    </div>
                    <div class="service">
                        <div
                            class="chatbot"
                            v-for="(m, index) in userCompany.modules"
                            :key="index"
                            v-if="m.checked === true"
                        >
                            <i
                                :class="m.sysModule.icon"
                                style="font-size: 54px"
                            ></i>
                            <div class="title">
                                <div class="label">应用名称：</div>
                                <div class="name">{{ m.sysModule.name }}</div>
                            </div>
                            <div class="title">
                                <div class="label">版本：</div>
                                <div class="name">
                                    {{
                                        m.config == null ||
                                        m.config.moduleVersion == null
                                            ? "普通试用版"
                                            : m.config.moduleVersion.name
                                    }}
                                </div>
                            </div>
                            <!-- <div class="title"><div class="label">流量：</div><div class="name">还剩10万轮次</div></div> -->
                            <div class="title">
                                <div class="label">有效期：</div>
                                <div class="name" v-if="m.expiresIn != 0">
                                    {{ m.expiresIn }}天
                                </div>
                                <div
                                    class="name"
                                    v-if="m.expiresIn == 0"
                                    style="color: red"
                                >
                                    已过期
                                </div>
                            </div>
                            <div
                                class="use"
                                v-if="
                                    entranceStatus[m.moduleCode] &&
                                    m.expiresIn != 0 &&
                                    userCompany.status == 2
                                "
                                @click="enterModule(m.sysModule.portalUrl)"
                            >
                                <span class="text">进入</span>
                            </div>
                            <div
                                class="use"
                                v-if="
                                    !(
                                        entranceStatus[m.moduleCode] &&
                                        m.expiresIn != 0 &&
                                        userCompany.status == 2
                                    )
                                "
                            >
                                <span class="text">暂不可用</span>
                            </div>
                        </div>
                        <div
                            class="get-more-edition"
                            @click="openGetMoreEdition"
                        >
                            <div class="edition-content">
                                <i
                                    class="
                                        arsenal_icon
                                        arsenalplus-circle-solid
                                    "
                                ></i>
                                <span class="">获取更多版本</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-content">
                    <div class="update">
                        <div class="title">最新更新</div>
                        <div class="update-content">
                            <div
                                class="content-item"
                                v-for="(item, index) in articleList"
                                :key="index"
                            >
                                <span class="date">{{
                                    new Date(articleList[index].created).Format(
                                        "yyyy-MM-dd"
                                    )
                                }}</span>
                                <span class="name"
                                    ><el-link
                                        @click="openUpdateContent(index)"
                                        >{{ articleList[index].title }}</el-link
                                    ></span
                                ><el-badge
                                    v-show="
                                        articleList[index].commentStatus == 0
                                    "
                                    is-dot
                                    class="item"
                                ></el-badge>
                            </div>
                        </div>
                    </div>
                    <el-dialog
                        :title="currentUpdateTitle"
                        :visible.sync="dialogVisible"
                        width="40%"
                    >
                        <div class="dialog-update-content">
                            <div v-html="currentUpdateContent"></div>
                            <p class="team">AskBot团队</p>
                            <p class="update-time">
                                {{
                                    new Date(currentUpdateTime).Format(
                                        "yyyy-MM-dd"
                                    )
                                }}
                            </p>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="uploadDialogVisible"
            width="600px"
            top="8vh"
            custom-class="dialogImg"
        >
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-dialog
            :visible.sync="uploadExampleVisible"
            top="8vh"
            width="600px"
            custom-class="dialogImg"
        >
            <div style="overflow: auto">
                <img :src="uploadExampleUrl" alt="" />
            </div>
        </el-dialog>
        <leftMenu
            ref="leftMenuObj"
            @setIsShowTel="setIsShowTel"
            @setHasCompanyName="setHasCompanyName"
        ></leftMenu>
        <popup v-if="dialogFormVisible" @closeEvent="dialogFormVisible = false">
            <div slot="popup-name" class="popup-name">
                {{ skillAndBaseinfoTitle }}
            </div>
            <div slot="popup-con">
                <div v-if="false" class="system-list">
                    <div
                        :class="['service-item', item.checked ? 'click' : '']"
                        :id="item.code"
                        v-for="(item, index) in systemModelFilterList"
                        :key="index"
                        @click="onClickService(item)"
                    >
                        <img
                            src="../../assets/images/workorderIcon.png"
                            v-if="item.code === 'WORKORDER'"
                        />
                        <img
                            src="../../assets/images/openIcon.png"
                            v-if="item.code === 'OPEN'"
                        />
                        <span>{{ item.name }}</span>
                        <div class="check-icon" v-if="item.checked">
                            <i class="el-icon-check"></i>
                        </div>
                    </div>
                </div>
                <div v-if="selectStep == 1" class="service-list">
                    <div v-if="false" class="header-name">
                        请选择您需要的服务
                    </div>
                    <div class="service-mode-list">
                        <div
                            class="service-item"
                            v-for="(item, index) in serviceModeList"
                            :key="index"
                            @click="checkTemplate(item)"
                            :class="
                                checkTemplateCode === item.versionCode
                                    ? 'check-template'
                                    : ''
                            "
                        >
                            <div class="item-left">
                                <img
                                    src="../../assets/images/retail.png"
                                    v-if="item.versionCode === 'RETAIL'"
                                />
                                <img
                                    src="../../assets/images/currency.png"
                                    v-else
                                />
                            </div>
                            <div class="item-right">
                                <div class="version-name">
                                    {{ item.versionName }}
                                </div>
                                <div
                                    v-if="item.versionCode === 'RETAIL'"
                                    class="version-detail"
                                >
                                    知识库包含店内常见的硬件故障（电脑、网络、收音机、扫码枪、电视、货架等），
                                    软件故障（OA、考勤、排版、ERP等），业务故障（日结、盘点、退换货等）
                                </div>
                                <div v-else class="version-detail">
                                    知识库包含IT、HR、财务、行政等基础知识
                                </div>
                            </div>
                            <div
                                class="check-icon"
                                v-if="checkTemplateCode === item.versionCode"
                            >
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectStep == 2">
                    <el-form :model="baseInfoForm">
                        <el-form-item>
                            <el-input
                                v-model="baseInfoForm.realName"
                                autocomplete="off"
                                prefix-icon="el-icon-user"
                                placeholder="请输入您的名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                v-model="baseInfoForm.phone"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @blur="phoneChange"
                                autocomplete="off"
                                maxlength="11"
                                prefix-icon="el-icon-phone-outline"
                                placeholder="请输入您的手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                placeholder="请输入内容验证码"
                                v-model="baseInfoForm.checkCode"
                                prefix-icon="el-icon-c-scale-to-original"
                            >
                                <template slot="append">
                                    <el-button
                                        :disabled="verifyIng"
                                        @click="getCheckcode"
                                        style="
                                            background: #366aff;
                                            border-radius: 0 4px 4px 0;
                                            color: white;
                                        "
                                        type="primary"
                                        ><span v-if="verifyIng"
                                            >（{{
                                                verifyTime
                                            }}
                                            S）后重新发送</span
                                        ><span v-if="!verifyIng">{{
                                            verifyName
                                        }}</span></el-button
                                    ></template
                                >
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-model="baseInfoForm.email"
                                autocomplete="off"
                                prefix-icon="el-icon-postcard"
                                placeholder="请输入您的邮箱"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-if="!this.hasCompanyName"
                                v-model="baseInfoForm.companyName"
                                autocomplete="off"
                                prefix-icon="iconfont guoran-a-14-03"
                                placeholder="请输入您所在的企业全称"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <el-button
                    v-if="certificationVisible"
                    type="primary"
                    @click="addCompanyInfo"
                    >下一步</el-button
                >
                <el-button
                    v-if="!certificationVisible"
                    type="primary"
                    @click="addCompanyInfo"
                    >提 交</el-button
                >
            </div>
        </popup>
        <popup v-if="phoneDialogFormVisible" @closeEvent="cancelBind">
            <div slot="popup-name" class="popup-name">绑定手机号</div>
            <div slot="popup-tip">绑定手机号后，您可以使用手机号登陆</div>
            <div slot="popup-con">
                <div>
                    <el-form :model="basePhoneInfoForm">
                        <el-form-item v-if="isShowTel">
                            <el-input
                                v-model="basePhoneInfoForm.phone"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @blur="phoneChange"
                                autocomplete="off"
                                maxlength="11"
                                prefix-icon="el-icon-phone-outline"
                                placeholder="请输入您的手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                placeholder="请输入内容验证码"
                                v-model="basePhoneInfoForm.checkCode"
                                prefix-icon="el-icon-c-scale-to-original"
                            >
                                <template slot="append">
                                    <el-button
                                        :disabled="verifyIng"
                                        @click="onlyPhoneGetCheckcode"
                                        style="
                                            background: #366aff;
                                            border-radius: 0 4px 4px 0;
                                            color: white;
                                        "
                                        type="primary"
                                        ><span v-if="verifyIng"
                                            >（{{
                                                verifyTime
                                            }}
                                            S）后重新发送</span
                                        ><span v-if="!verifyIng">{{
                                            verifyName
                                        }}</span></el-button
                                    ></template
                                >
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <span style="" class="cancel-bind" @click="cancelBind"
                    >暂不绑定</span
                >
                <el-button type="primary" @click="bindPhone"
                    >立即绑定</el-button
                >
            </div>
        </popup>
        <popup
            v-if="certificationDialog"
            @closeEvent="certificationDialog = false"
        >
            <div slot="popup-name" class="popup-name">申请认证</div>
            <div slot="popup-tip">请输入认证所需要的相关信息</div>
            <div slot="popup-con">
                <div class="certification-content">
                    <el-tabs tab-position="bottom" type="">
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label"
                                ><i class="iconfont guoran-qiyeyouxiang"></i>
                                <span class="tabs-name">企业邮箱</span></span
                            >
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div
                                class="certification-form-item"
                                :class="
                                    isPersonalEmailDomain
                                        ? 'inputErrorBorder'
                                        : ''
                                "
                            >
                                <el-input
                                    placeholder="请输入企业邮箱，公共邮箱无法通过审核"
                                    @blur="blurCompanyEmailInput()"
                                    prefix-icon="iconfont guoran-qiyeyouxiang"
                                    v-model="certification.companyEmail"
                                >
                                </el-input>
                            </div>
                            <div
                                class="inputErrorText"
                                v-show="isPersonalEmailDomain"
                            >
                                请填写企业邮箱，个人邮箱无法通过审核。
                            </div>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入收到的邮箱验证码"
                                    prefix-icon="iconfont guoran-yanzhengma"
                                    v-model="mailCode"
                                >
                                </el-input>
                                <el-button
                                    v-if="!sendDisabled"
                                    type="primary"
                                    @click="sendMailCode()"
                                    >{{ buttonName }}</el-button
                                >
                                <el-button
                                    v-if="sendDisabled"
                                    type="info"
                                    :disabled="true"
                                    >{{ buttonName }}</el-button
                                >
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    :disabled="isPersonalEmailDomain"
                                    @click="saveCertificationInfo(0)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-yingyezhizhao"></i>
                                <span class="tabs-name">营业执照</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleCertChange"
                                        :file-list="fileList.cert"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadCertSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleCertRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.请上传营业执照清晰彩色原件扫描件或照片</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.支持.jpg .jpeg .png
                                            .bmp格式照片，大小不超过10M</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_cert.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>点击查看图例</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(1)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-qiyeweixin1"></i>
                                <span class="tabs-name">企业微信</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleEwxChange"
                                        :file-list="fileList.ewx"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadEwxSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleEwxRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.打开企业微信→点击“我”→截图并上传</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >.支持.jpg .jpeg .png
                                            .bmp格式照片，大小不超过10M</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_ewx.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>点击查看图例</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(2)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-dingding1"></i>
                                <span class="tabs-name">钉钉</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleDdChange"
                                        :file-list="fileList.dd"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadDdSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleDdRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.打开钉钉→点击“头像”→点击“您的姓名”→点击“企业/组织/团队”截图并上传</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >.支持.jpg .jpeg .png
                                            .bmp格式照片，大小不超过10M</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="reviewExample('example_dd.png')"
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>点击查看图例</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(3)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-gongpaimingpian"></i>
                                <span class="tabs-name">工牌/名片</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleCardChange"
                                        :file-list="fileList.card"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadCardSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleCardRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.公司名片、工牌、证件的照片或截图</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >.支持.jpg .jpeg .png
                                            .bmp格式照片，大小不超过10M</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_card.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>点击查看图例</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(4)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-qita"></i>
                                <span class="tabs-name">其他认证</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    placeholder="请输入企业名称，须与营业执照一致"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleOtherChange"
                                        :file-list="fileList.other"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadOtherSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleOtherRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >.在职证明等可以证明在职信息的照片或截图</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >.支持.jpg .jpeg .png
                                            .bmp格式照片，大小不超过10M</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_other.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>点击查看图例</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(5)"
                                    >提交认证</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >上一步</span
                                >
                                <span class="q-tip"
                                    >如有疑问请联系客服：159-1108-6419</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >其他认证方式</el-divider
                            >
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </popup>
    </div>
</template>

<script>
import leftMenu from "./../menu/leftMenu.vue";
import { redirectUri } from "../../const/redirectUri";
import Popup from "@/components/popup";
export default {
    name: "Home",
    components: {
        Popup,
        leftMenu,
    },
    data() {
        return {
            basePhoneInfoForm: {
                phone: "",
                checkCode: "",
            },
            phoneDialogFormVisible: false,
            verifyIng: false,
            verifyTime: 60,
            verifyName: "获取验证码",
            isShowTel: false,
            hasCompanyName: true,
            hasSkill: false,
            skillAndBaseinfoTitle: "请选择您需要的服务",
            selectStep: 1,
            certificationVisible: true,
            currentIp: "",
            tips: {
                phoneNull: "手机号不能为空，请填写",
                checkCodeNull: "验证码不能为空，请填写",
                realNameNull: "名称不能为空，请填写",
                mailNull: "邮箱不能为空，请填写",
                emailNull: "企业邮箱不能为空，请填写",
                emailCodeNull: "验证码不能为空，请填写",
                emailCodeError: "验证码不匹配，请检查",
                codeSendSuccess: "验证码已发送，请登录邮箱查看",
                companyNameNull: "公司名称不能为空，请填写",
                certUrlNull: "请上传营业执照清晰彩色原件扫描件或数码照",
                ewxUrlNull: "请上传企业微信个人信息截图",
                ddUrlNull: "请上传钉钉个人信息截图",
                cardUrlNull: "请上传公司名片、工牌、证件的照片或截图",
                otherUrlNull: "请上传其他认证资料图片",
                saveCertError: "提交认证失败，请检查填写的信息",
                temCodeNull: "请选择需要的服务",
            },
            dialogVisible: false,
            updateItem: [
                {
                    title: "工单系统Beta版上线",
                    type: "产品更新",
                    status: "0",
                    content:
                        "用户您好，工单系统已经开放测试，可在AskBot管理中心进入工单系统进行体验测试，如有问题请联系客服进行处理！",
                    time: "2020-10-20",
                },
                {
                    title: "WebHook功能上线",
                    type: "产品更新",
                    status: "1",
                    content:
                        "用户您好，WebHook是AskBot对话机器人非常核心的功能，经过一段时间技术攻关，目前已经上线，从此AskBot机器人不仅可以回答问题，同时可以与各类系统进行对接，让机器人具备一句话办理业务的能力，具体应用场景我们会陆续更新模板，同时也欢迎和我们联系做演示讲解。",
                    time: "2020-11-11",
                },
            ],
            uploadExampleVisible: false,
            uploadExampleUrl: "",
            dialogFormVisible: false,
            certificationDialog: false,
            baseInfoForm: {
                email: "",
                companyName: "",
                realName: "",
                phone: "",
                checkCode: "",
            },
            formLabelWidth: "120px",
            dialogImageUrl: "",
            uploadDialogVisible: false,
            disabled: false,
            isCertified: true,
            username: "",
            realname: "",
            systemModelList: [],
            companyId: "",
            certification: {
                companyName: "",
                companyEmail: "",
                type: 0,
                certUrl: "",
                ewxUrl: "",
                ddUrl: "",
                cardUrl: "",
                otherUrl: "",
            },
            mailCode: "",
            userCompany: {
                status: 0,
                companyName: "",
            },
            entranceStatus: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            moduleShow: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            fileList: {
                cert: [],
                ewx: [],
                dd: [],
                card: [],
                other: [],
            },
            intervalTime: 60,
            buttonName: "获取验证码",
            sendDisabled: false,
            serviceExpired: false,
            currentUpdateContent: "",
            currentUpdateTitle: "",
            currentUpdateTime: "",
            articleList: [],
            isPersonalEmailDomain: false,
            rolePrivilegesList: [],
            serviceModeList: [],
            checkTemplateCode: "",
        };
    },
    computed: {
        reverseUpdateItem() {
            return this.updateItem.reverse();
        },
        systemModelFilterList() {
            let arr = [];
            this.systemModelList.forEach((item) => {
                if (item.code !== "IM") {
                    arr.push(item);
                }
            });
            return arr;
        },
    },
    directives: {
        numberOnly: {
            bind(el) {
                el.handler = function () {
                    el.value = el.value.replace(/\D+/, "");
                    if (el.value.length > 11) {
                        el.value = el.value.substring(0, 11);
                    }
                };
                el.addEventListener("input", el.handler);
            },
            unbind(el) {
                el.removeEventListener("input", el.handler);
            },
        },
    },
    methods: {
        cancelBind() {
            localStorage.setItem("cancel_bind_phone", 1);
            this.phoneDialogFormVisible = false;
        },
        onlyPhoneGetCheckcode() {
            if (this.basePhoneInfoForm.phone.trim() === "") {
                this.$message.error(this.tips.phoneNull);
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.basePhoneInfoForm.phone.trim())) {
                    this.$message.error("请检查手机号码是否正确");
                    return false;
                }
            }
            this.$http_ignore_auth
                .get(
                    "/api/register/check?phone=" +
                        this.basePhoneInfoForm.phone.trim()
                )
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.$message.error("手机号已经存在");
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.basePhoneInfoForm.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = "重新获取";
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
        bindPhone() {
            if (this.isBlank(this.basePhoneInfoForm.phone)) {
                this.$message.error(this.tips.phoneNull);
                return;
            }
            let phonReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!phonReg.test(this.basePhoneInfoForm.phone)) {
                this.$message.error("请填写正确的手机号！");
                return;
            }
            if (this.isBlank(this.basePhoneInfoForm.checkCode)) {
                this.$message.error(this.tips.checkCodeNull);
                return;
            }
            this.$http
                .put(
                    this.requestUrl.companyManage.onlyBindPhone +
                        "?phone=" +
                        this.basePhoneInfoForm.phone +
                        "&checkCode=" +
                        this.basePhoneInfoForm.checkCode
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.phoneDialogFormVisible = false;
                        localStorage.setItem("cancel_bind_phone", 1);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        phoneChange(e) {
            this.baseInfoForm.phone = e.target.value;
        },
        setIsShowTel(obj) {
            console.log(obj);
            if (obj.tel != null && obj.tel != "null") {
                this.isShowTel = false;
            } else {
                this.isShowTel = true;
                let isCancel = localStorage.getItem("cancel_bind_phone");
                if (
                    obj.oldUser &&
                    (obj.source == 2 || obj.source == 4) &&
                    isCancel != 1
                ) {
                    this.phoneDialogFormVisible = true;
                }
                if (
                    isCancel != 1 &&
                    !(obj.oldUser && (obj.source == 2 || obj.source == 4))
                ) {
                    this.dialogFormVisible = true;
                }

                this.certificationDialog = false;
            }
        },

        setHasCompanyName(companyName) {
            if (
                localStorage.getItem("has_companyName") == null ||
                localStorage.getItem("has_companyName") == ""
            ) {
                if (companyName != null && companyName != "null") {
                    this.hasCompanyName = true;
                    localStorage.setItem("has_companyName", 1);
                } else {
                    this.hasCompanyName = false;
                    localStorage.setItem("has_companyName", 0);
                }
            } else {
                this.hasCompanyName = Boolean(
                    Number(localStorage.getItem("has_companyName"))
                );
            }
        },

        getCheckcode() {
            if (this.baseInfoForm.phone.trim() === "") {
                this.$message.error(this.tips.phoneNull);
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.baseInfoForm.phone.trim())) {
                    this.$message.error("请检查手机号码是否正确");
                    return false;
                }
            }
            this.$http_ignore_auth
                .get(
                    "/api/register/check?phone=" +
                        this.baseInfoForm.phone.trim()
                )
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.$message.error("手机号已经存在");
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.baseInfoForm.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = "重新获取";
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
        //选择服务模块
        checkTemplate(item) {
            this.checkTemplateCode = item.versionCode;
        },
        retryAuth() {
            if (this.userCompany.status == 0) {
                this.dialogFormVisible = true;
                this.certificationDialog = false;
                this.baseInfoForm.email = "";
                this.baseInfoForm.companyName = "";
                this.baseInfoForm.realName = "";
                this.baseInfoForm.phone = "";
                this.baseInfoForm.checkCode = "";
                this.checkTemplateCode = "";
            } else {
                this.certificationDialog = true;
                this.dialogFormVisible = false;
            }
            this.certification.certUrl = "";
            this.certification.ewxUrl = "";
            this.certification.ddUrl = "";
            this.certification.cardUrl = "";
            this.certification.otherUrl = "";
        },
        onClickService(item) {
            this.$set(item, "checked", !item.checked);
            this.systemModelList = [...this.systemModelList];
        },
        prevStepClick() {
            this.dialogFormVisible = true;
            this.certificationDialog = false;
        },
        reviewExample(imgUrl) {
            this.uploadExampleUrl =
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/" +
                imgUrl;
            console.log(this.uploadExampleUrl);
            this.uploadExampleVisible = true;
        },
        addCompanyInfo() {
            if (this.selectStep == 1) {
                if (!this.hasSkill && this.isBlank(this.checkTemplateCode)) {
                    this.$message.error(this.tips.temCodeNull);
                    return;
                }
                let checkModules = [];
                this.systemModelList.forEach((m, i) => {
                    if (m.checked) {
                        checkModules.push(m);
                    }
                });
                this.$http
                    .post(
                        this.requestUrl.companyManage.newFirstBind +
                            "?version=" +
                            this.checkTemplateCode,
                        checkModules
                    )
                    .then((res) => {
                        console.log("bind module result:", res.data);
                        if (res.data && res.data.code === "0") {
                            this.companyId = res.data.data;
                            this.hasSkill = true;
                            this.selectStep = 2;
                            this.skillAndBaseinfoTitle = "请填写基本信息";
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            }
            if (this.selectStep == 2) {
                if (this.isBlank(this.baseInfoForm.realName)) {
                    this.$message.error(this.tips.realNameNull);
                    return;
                }

                if (this.isShowTel) {
                    if (this.isBlank(this.baseInfoForm.phone)) {
                        this.$message.error(this.tips.phoneNull);
                        return;
                    }
                    let phonReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                    if (!phonReg.test(this.baseInfoForm.phone)) {
                        this.$message.error("请填写正确的手机号！");
                        return;
                    }
                    if (this.isBlank(this.baseInfoForm.checkCode)) {
                        this.$message.error(this.tips.checkCodeNull);
                        return;
                    }
                }
                let emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                // 邮箱合法校验
                if (
                    this.baseInfoForm.email.trim() !== "" &&
                    !emailReg.test(this.baseInfoForm.email)
                ) {
                    this.$message.error("请输入正确的邮箱地址！");
                    return;
                }
                if (
                    !this.hasCompanyName &&
                    this.isBlank(this.baseInfoForm.companyName)
                ) {
                    this.$message.error(this.tips.companyNameNull);
                    return;
                }
                let paramObj = {
                    companyId: this.companyId,
                    email: this.baseInfoForm.email,
                    companyName: this.baseInfoForm.companyName,
                    realName: this.baseInfoForm.realName,
                };

                let url =
                    this.requestUrl.companyManage.userSubmitBasicInfo +
                    "?realName=" +
                    this.baseInfoForm.realName +
                    "&companyName=" +
                    this.baseInfoForm.companyName +
                    "&companyId=" +
                    this.companyId +
                    "&email=" +
                    this.baseInfoForm.email +
                    "&status=" +
                    this.userCompany.status;

                if (this.isShowTel) {
                    paramObj.phone = this.baseInfoForm.phone;
                    paramObj.checkCode = this.baseInfoForm.checkCode;
                    url =
                        url +
                        "&phone=" +
                        this.baseInfoForm.phone +
                        "&checkCode=" +
                        this.baseInfoForm.checkCode;
                }

                this.$http
                    .get(url)
                    .then((res) => {
                        console.log("bind module result:", res.data);
                        if (res.data && res.data.code === "0") {
                            // this.companyId = res.data.data;
                            this.dialogFormVisible = false;
                            if (this.userCompany.status == 0) {
                                this.certificationDialog = true;
                            } else {
                                this.$message.success("提交成功！");
                            }
                            this.certification.companyName =
                                paramObj.companyName;
                        } else {
                            this.$message.error("验证码填写错误！");
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            }
        },
        openUpdateContent(index) {
            this.dialogVisible = true;
            this.currentUpdateTitle = this.articleList[index].title;
            this.currentUpdateContent = this.articleList[index].content;
            this.currentUpdateTime = this.articleList[index].created;
        },
        handleCertRemove(file) {
            this.fileList.cert = [];
            this.certification.certUrl = "";
        },
        handleEwxRemove(file) {
            this.fileList.ewx = [];
            this.certification.ewxUrl = "";
        },
        handleDdRemove(file) {
            this.fileList.dd = [];
            this.certification.ddUrl = "";
        },
        handleCardRemove(file) {
            this.fileList.card = [];
            this.certification.cardUrl = "";
        },
        handleOtherRemove(file) {
            this.fileList.other = [];
            this.certification.otherUrl = "";
        },
        handleCertChange(file, fileList) {
            this.fileList.cert = [file];
        },
        handleEwxChange(file, fileList) {
            this.fileList.ewx = [file];
        },
        handleDdChange(file, fileList) {
            this.fileList.dd = [file];
        },
        handleCardChange(file, fileList) {
            this.fileList.card = [file];
        },
        handleOtherChange(file, fileList) {
            this.fileList.other = [file];
        },
        onUploadCertSuccess(response, file, fileList) {
            this.certification.certUrl = response.data;
            console.log(fileList);
        },
        onUploadEwxSuccess(response, file, fileList) {
            this.certification.ewxUrl = response.data;
            console.log(fileList);
        },
        onUploadDdSuccess(response, file, fileList) {
            this.certification.ddUrl = response.data;
            console.log(fileList);
        },
        onUploadCardSuccess(response, file, fileList) {
            this.certification.cardUrl = response.data;
            console.log(fileList);
        },
        onUploadOtherSuccess(response, file, fileList) {
            this.certification.otherUrl = response.data;
            console.log(fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.uploadDialogVisible = true;
        },
        saveMoreInfo() {
            this.dialogFormVisible = false;
            let that = this;
            setTimeout(function () {
                that.certificationDialog = true;
            }, 1000);
        },
        sendMailCode() {
            let email = this.certification.companyEmail;
            if (email != null && email.length > 0) {
                this.$http
                    .post(this.requestUrl.code.mailSend, null, {
                        params: { email: email },
                    })
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log("send mailCode result:", res.data.data);
                            this.$message.success(this.tips.codeSendSuccess);
                            let that = this;
                            that.sendDisabled = true;
                            let interval = window.setInterval(function () {
                                that.buttonName =
                                    "（" + that.intervalTime + "秒）后重新获取";
                                that.intervalTime = that.intervalTime - 1;
                                console.log(
                                    "current time:",
                                    that.intervalTime,
                                    that.sendDisabled
                                );
                                if (that.intervalTime < 0) {
                                    that.buttonName = "获取验证码";
                                    that.intervalTime = 60;
                                    that.sendDisabled = false;
                                    window.clearInterval(interval);
                                }
                            }, 1000);
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            } else {
                this.$message.error(this.tips.emailNull);
            }
        },
        saveCertificationInfo(type) {
            this.certification.type = type;
            console.log("certification:", this.certification);
            if (this.isBlank(this.certification.companyName)) {
                this.$message.error(this.tips.companyNameNull);
                return;
            }
            if (type == 0) {
                let email = this.certification.companyEmail;
                if (this.isBlank(email)) {
                    this.$message.error(this.tips.emailNull);
                    return;
                }
                if (this.isBlank(this.mailCode)) {
                    this.$message.error(this.tips.emailCodeNull);
                    return;
                }
                this.$http
                    .post(this.requestUrl.code.mailCheck, null, {
                        params: { email: email, code: this.mailCode },
                    })
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(
                                "check mailCode result:",
                                res.data.data
                            );
                            if (res.data.data != null && res.data.data) {
                                this.submitCertificationInfo();
                            } else {
                                this.$message.error(this.tips.emailCodeError);
                            }
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
                return;
            } else if (type == 1) {
                if (this.isBlank(this.certification.certUrl)) {
                    this.$message.error(this.tips.certUrlNull);
                    return;
                }
            } else if (type == 2) {
                if (this.isBlank(this.certification.ewxUrl)) {
                    this.$message.error(this.tips.ewxUrlNull);
                    return;
                }
            } else if (type == 3) {
                if (this.isBlank(this.certification.ddUrl)) {
                    this.$message.error(this.tips.ddUrlNull);
                    return;
                }
            } else if (type == 4) {
                if (this.isBlank(this.certification.cardUrl)) {
                    this.$message.error(this.tips.cardUrlNull);
                    return;
                }
            } else {
                if (this.isBlank(this.certification.otherUrl)) {
                    this.$message.error(this.tips.otherUrlNull);
                    return;
                }
            }
            this.submitCertificationInfo();
        },
        submitCertificationInfo() {
            this.$http
                .post(
                    this.requestUrl.companyManage.postCompanyAuth +
                        "/" +
                        this.companyId +
                        "?ip=" +
                        this.currentIp,
                    this.certification
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data) {
                            this.certificationDialog = false;
                            this.getUserCompany();
                            this.$refs.leftMenuObj.getAuditStatus();
                            this.$alert(
                                "认证结果会在1~2个工作日内以短信及邮件的方式通知您，请您耐心等待。",
                                "提交认证成功",
                                {
                                    confirmButtonText: "知道了",
                                }
                            );
                        } else {
                            this.$message.error(this.tips.saveCertError);
                        }
                    }
                })
                .catch((res) => {});
        },
        isBlank(value) {
            return value == null || value.length == 0;
        },
        getSystemModelList() {
            this.$http
                .get(this.requestUrl.systemModel.getSystemModel)
                .then((res) => {
                    console.log("modules:", res);
                    if (res.data && res.data.code === "0") {
                        this.systemModelList = res.data.data;
                        for (let i = 0; i < this.systemModelList.length; i++) {
                            if (this.systemModelList[i].code !== "IM") {
                                this.systemModelList[i].checked = true;
                            }
                        }
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        getServiceList() {
            let url = "/api-open/bind/common/skill-versions";
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.serviceModeList = res.data.data;
                }
            });
        },
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
        getUserCompany() {
            this.$http
                .get(this.requestUrl.companyManage.getUserCompany)
                .then((res) => {
                    console.log("user company:", res);
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            this.userCompany = res.data.data;
                            console.log(this.userCompany);
                            this.companyId = this.userCompany.id;
                            this.hasSkill =
                                (this.userCompany.skillVersion == null || this.userCompany.skillVersion == "")
                                    ? false
                                    : true;
                            if (this.hasSkill) {
                                this.selectStep = 2;
                                this.skillAndBaseinfoTitle = "请填写基本信息";
                            } else {
                                this.selectStep = 1;
                                this.skillAndBaseinfoTitle =
                                    "请选择您需要的服务";
                            }
                            if (this.userCompany.certification != null) {
                                this.certification =
                                    this.userCompany.certification;
                            }
                            let modules = this.userCompany.modules;
                            if (modules != null && modules.length > 0) {
                                modules.forEach((m) => {
                                    this.moduleShow[m.moduleCode] = true;
                                    if (m.expiresIn == 0) {
                                        this.serviceExpired = true;
                                    }
                                    m.checked = false;
                                    if (
                                        m.sysModule.name === "对话机器人" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_OPEN"
                                        ) !== -1
                                    ) {
                                        m.checked = true;
                                    } else if (
                                        m.sysModule.name === "IM协同" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_IM"
                                        ) !== -1
                                    ) {
                                        m.checked = true;
                                    } else if (
                                        m.sysModule.name === "工单系统" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_WORKORDER"
                                        ) !== -1
                                    ) {
                                        m.checked = true;
                                    }
                                });
                            } else if (this.userCompany.creator == -1) {
                                this.moduleShow.OPEN = true;
                                this.moduleShow.IM = true;
                                this.moduleShow.WORKORDER = true;
                            }
                        }
                        if (this.userCompany.status == 0) {
                            if (
                                this.userCompany.name == "" ||
                                this.userCompany.name == null
                            ) {
                                this.dialogFormVisible = true;
                                this.certificationDialog = false;
                                this.certificationVisible = true;
                            } else {
                                this.dialogFormVisible = false;
                                this.certificationDialog = true;
                                this.certificationVisible = true;
                            }
                        }
                        if (
                            this.userCompany.status == 2 ||
                            this.userCompany.status == 1
                        ) {
                            this.certificationVisible = false;
                            this.entranceStatus.OPEN = true;
                            this.entranceStatus.IM = true;
                            this.entranceStatus.WORKORDER = true;
                        }
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        openGetMoreEdition() {
            this.$alert("获取更多版本请联系159-1108-6419", "获取更多版本", {
                confirmButtonText: "关闭",
                callback: (action) => {},
            });
        },
        getCurrentIp() {
            let ipCheckUrl = "https://www.askbot.cn/ext-api/ip";
            this.$http.get(ipCheckUrl).then((res) => {
                console.log("============current ip:", res);
                if (res != null && res.data != null) {
                    this.currentIp = res.data.ip;
                }
            });
        },
        getArticleList() {
            // this.$http
            //     .get("/api-blog/article/page?page=1&size=3&categoryId=14")
            //     .then((res) => {
            //         this.articleList = res.data.list;
            //         console.log("res:" + res.data.list);
            //     });
        },
        blurCompanyEmailInput() {
            this.isPersonalEmailDomain = false;
            let that = this;
            let personalEmailDomail = [
                "163.com",
                "126.com",
                "qq.com",
                "foxmail.com",
                "aliyun.com",
                "139.com",
                "189.com",
                "sina.com",
                "tom.com",
                "88.com",
                "yeah.com",
                "outlook.com",
                "gmail.com",
            ];
            for (let index = 0; index < personalEmailDomail.length; index++) {
                if (
                    that.certification.companyEmail.indexOf(
                        personalEmailDomail[index]
                    ) != -1
                ) {
                    that.isPersonalEmailDomain = true;
                }
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.username = localStorage.getItem("username");
            this.realname = localStorage.getItem("realname");
            console.log(localStorage);
            this.getSystemModelList();
            this.getServiceList();
            this.getUserCompany();
        });
        this.getCurrentIp();
        this.getArticleList();
        this.rolePrivilegesList = this.getRolePrivileges();
    },
};
</script>

<style lang="less">
@import "./../../assets/less/home/home.less";
.cancel-bind {
    display: inline-block;
    border-radius: 16px;
    line-height: 32px;
    height: 32px;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    padding: 0 30px;
    margin-right: 30px;
}
.cancel-bind:hover {
    background-color: #ecf5ff;
    color: #366aff;
    border: 1px solid #366aff;
}
.dialogImg {
    img {
        width: 450px;
        object-fit: contain;
    }
}
#popup-assembly {
    width: 600px;
    .popup-tip {
        /* padding-top: 0!important;*/
    }
    .popup-header {
        padding-bottom: 24px !important;
    }
    .popup-name {
        /*font-size: 18px!important;*/
        color: #000000;
    }
    .system-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 40px;
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
        .dialog-left-header {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            span {
                font-size: 13px;
                color: #dddddd;
            }
        }
        .service-item {
            border: 1px solid #dae1f6;
            width: 100px;
            height: 100px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            i {
                font-size: 16px;
            }
            span {
                margin-top: 10px;
            }
            .check-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-top: 15px solid transparent;
                border-bottom: 15px solid #366aff;
                border-right: 15px solid #366aff;
                border-bottom-right-radius: 3px;
                i {
                    color: white;
                    position: absolute;
                    top: -2px;
                    font-size: 14px;
                }
            }
        }
        .click {
            border: #366aff 1px solid;
            color: #366aff;
            /* background: url('../../assets/images/u407.png') no-repeat bottom right;*/
            background-size: 30px 30px;
        }
    }
    .service-list {
        .header-name {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            padding-bottom: 20px;
        }
        .service-mode-list {
            padding-bottom: 40px;
            .service-item {
                padding: 16px 20px;
                border: 2px solid #dae1f6;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin-bottom: 38px;
                position: relative;
                .item-left {
                    margin-right: 18px;
                    /deep/img {
                        width: 85px;
                        height: 85px;
                        object-fit: contain;
                    }
                }
                .item-right {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    .version-name {
                        display: flex;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .version-detail {
                        display: flex;
                        font-size: 12px;
                        color: #a0a7b5;
                        margin-top: 14px;
                    }
                }
                .check-icon {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid #366aff;
                    border-right: 15px solid #366aff;
                    border-bottom-right-radius: 3px;
                    i {
                        color: white;
                        position: absolute;
                        top: -2px;
                        font-size: 14px;
                    }
                }
            }
            .service-item:last-child {
                margin-bottom: 0 !important;
            }
            .check-template {
                border: 2px solid #3676ff !important;
            }
        }
    }
    .certification-content {
        margin-top: 20px;
        .el-input__inner {
            border: 1px solid #e0e6f7;
        }
        .el-tabs__item {
            height: 90px;
            padding: 0px !important;
            .certification-type-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                color: #a9b3c6;
                i {
                    font-size: 32px;
                    border: 1px solid #dae1f6;
                    width: 55px;
                    height: 55px;
                    line-height: 55px;
                    border-radius: 50%;
                    text-align: center;
                    color: #a1b9ff;
                }
                .tabs-name {
                    margin-right: 10px;
                }
            }
        }
        .is-active {
            i {
                border: 1px solid #366aff !important;
                color: #366aff !important;
            }
            .tabs-name {
                color: #366aff !important;
            }
        }
        .certification-form-item {
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            .el-button {
                background: #366aff;
                border-radius: 5px;
            }
            .el-input__icon {
                font-size: 16px !important;
            }
        }
        .certification-post-button {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: row;
            .el-button {
                width: 100%;
                background: #366aff;
                border-radius: 19px;
            }
        }
        .upload {
            width: 100%;
            text-align: left;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            .img {
                flex: none;
                width: 100px;
                display: flex;
                flex-direction: row;
                margin-right: 15px;
                .el-upload--picture-card {
                    width: 95px;
                    height: 95px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fafbff;
                    i {
                        color: #87a9ff;
                        font-size: 22px;
                    }
                }
                .el-upload-list__item {
                    width: 70px;
                    height: 70px;
                }
            }
            .desc {
                display: flex;
                flex-direction: column;
                .des-item {
                    display: flex;
                    /* align-items: center;*/
                    color: #a9b3c6;
                    padding: 6px 0;
                }
                .link-span {
                    line-height: 30px;
                    cursor: pointer;
                    color: #366aff;
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 7px;
                    }
                }
                .number {
                    flex: none;
                    width: 20px;
                    height: 20px;
                    background: #a1b9ff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    margin-right: 4px;
                }
            }
        }
    }
    .service-dialog-footer {
        .el-button {
            background: #366aff;
            border-radius: 19px;
        }
    }
}
.el-form-item__content {
    text-align: left;
}
.el-input__icon {
    font-size: 20px;
}
.el-tabs__nav-wrap::after {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-tabs__active-bar {
    height: 0px;
    background-color: transparent !important;
}
.el-tabs__nav {
    float: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dialog-update-content {
    padding: 20px;
    height: 300px;
    line-height: 35px;
    div {
        text-align: left;
        color: #4a4a4a;
        text-indent: 2em;
    }
    .team {
        position: absolute;
        bottom: 80px;
        right: 40px;
        text-align: right;
    }
    .update-time {
        position: absolute;
        bottom: 40px;
        right: 40px;
        text-align: right;
    }
}
.el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
    .certification-container {
        padding: 20px;
        .certification-type {
            font-size: 16px;
            font-weight: 600;
        }
        .certification-content {
            margin-top: 20px;
            .el-tabs__item {
                height: 90px;
                padding: 0px !important;
                .certification-type-icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    color: #dddddd;
                    i {
                        font-size: 36px;
                        border: 1px solid #dddddd;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 50%;
                        text-align: center;
                    }
                    .tabs-name {
                        margin-right: 10px;
                    }
                }
            }
            .is-active {
                i {
                    border: 1px solid #366AFF !important;
                    color: #366AFF !important;
                }
                .tabs-name {
                    color: #366AFF !important;
                }
            }
            .certification-form-item {
                width: 100%;
                margin: 15px 0;
                display: flex;
                flex-direction: row;
            }
            .certification-post-button {
                width: 100%;
                margin: 15px 0;
                display: flex;
                flex-direction: row;
                .el-button {
                    width: 100%;
                }
            }
            .upload {
                width: 100%;
                text-align: left;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .img {
                    width: 35%;
                    display: flex;
                    flex-direction: row;
                    .el-upload--picture-card {
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                    }
                    .el-upload-list__item {
                        width: 70px;
                        height: 70px;
                    }
                }
                .desc {
                    display: flex;
                    flex-direction: column;
                    span {
                        line-height: 35px;
                    }
                    .link-span {
                        line-height: 35px;
                        cursor: pointer;
                        color: #366AFF;
                    }
                }
            }
        }
    }
}
.dialog-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .dialog-left {
        width: 50%;
        text-align: left;
        display: flex;
        flex-direction: row;
        //background-color: #f1f6f7;
        border-right: solid 5px #dddddd;
        align-items: center;
        justify-content: center;
        .dialog-left-header {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            span {
                font-size: 13px;
                color: #dddddd;
            }
        }
        .service-item {
            border: #dddddd 1px solid;
            width: 100px;
            height: 100px;
            text-align: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            border-radius: 5px;
            cursor: pointer;
            i {
                font-size: 42px;
            }
            span {
                margin-top: 10px;
            }
        }
        .click {
            border: #366AFF 1px solid;
            color: #366AFF;
            background: url("../../assets/images/u407.png") no-repeat bottom
                right;
            background-size: 30px 30px;
        }
    }
    .dialog-right {
        width: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .el-form {
            width: 90% !important;
            text-align: center;
            margin-left: 20px;
            .el-input {
                margin-bottom: 20px !important;
            }
        }
    }
}

.prev-step {
    display: flex;
    align-items: center;
    /* span {
        display: inline-block;
        text-align: center;
    };*/
    .prev-text {
        flex: none;
        color: #366aff;
        cursor: pointer;
        border: 1px solid #b7caff;
        border-radius: 14px;
        height: 26px;
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .q-tip {
        color: #000000;
        flex: none;
        width: 260px;
    }
}
.prev-step:after {
    content: "";
    display: inline-block;
    overflow: hidden;
    width: 100%;
}
.inputErrorText {
    margin-top: -24px;
    color: red;
    text-align: left;
    font-size: 12px;
    padding-bottom: 30px;
}
.inputErrorBorder {
    border: 1px solid red;
    border-radius: 5px;
    width: 99% !important;
    padding-bottom: 36px !important;
}
</style>
