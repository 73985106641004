<template>
    <div class="account-field-type">
        <div class="gr-content-container">
            <div class="dataTemplateHeader">
				<div class="headerLeft">
				<div class="backIcon" @click="goBack">
					<i class="iconfont guoran-tongyichicun-18-22-fanhui"></i>
				</div>
				<div class="headerTitle">{{$t('accountTemplate.title')}}</div>
				</div>
				<div class="headerCenter">
				<div class="headerTabItem" v-for="(item,index) in headerList" @click="headerTab(item.index)" :key="index"
					:class="item.isActive ?'active-header':''">
					<span class="header-name">
					<i class="iconfont" :class="item.iconfont"></i>
					<span>{{item.name}}</span>
					</span>
				</div>
				</div>
				<div class="headerRight">
				<el-button type="primary" size="small" @click="pulishClueField" icon="iconfont guoran-a-16-05">发布</el-button>
				</div>
			</div>
			<div class="dataTemplateContainer">
				<div class="containerBasics" v-show="vdomShow == 1">
				<el-form label-position="top">
					<el-form-item label="工单模板名称">
					<span slot="label">
						模板名称
						<span class="red">*</span>
						<div style="font-size:12px;color:#999999;">不展示在前端</div>
					</span>
					<el-input v-model="dialogTemplateName" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="模板适用角色">
						<div style="background:#F5F7FA;border-radius:4px;padding-left:15px;font-size:12px;">请前往角色管理关联角色对应模板</div>
						<!-- <el-select v-model="roleIds" disabled placeholder="请选择" clearable multiple filterable>
							<div class="select-label-box-option">
								<el-option
									v-for="item in roleData"
									:key="item.id"
									:class="[item.isChecked ? 'isChecked' : '']"
									:label="item.name"
									:value="item.id">
									<span v-if="item.isChecked" class="hidden-options">{{item.name}}</span>
									<span v-else>{{item.name}}</span>
								</el-option>
							</div>
						</el-select> -->
					</el-form-item>
				</el-form>
				</div>
				<div class="editClueFieldContent" v-show="vdomShow == 2">
					<div class="content-left">
						<div class="basic-control" v-for="item in workOrderTemConstrols" :key="item.text">
							<div class="title" v-if="item.list.length > 0">{{item.text}}</div>
							<div v-if="item.type === 'system' && item.list.length > 0" style="color: rgb(169, 179, 198); font-size: 12px; margin-top: 4px;">以下字段只在含有工单的角色中显示</div>
							<div :class="['control-style',item.type === 'system' ? 'system-control-style' : '']">
							<div 
								v-for="(field,index) in item.list" 
								:key="index" 
								:class="['control-item',item.type === 'system' ? 'system' : '']"
								:style=" {paddingRight:(field.type == 'TIME_PICKER_RANGE' || field.type ==  'REF_TEMPLATE') && 0 ,width:(field.type == 'TIME_PICKER_RANGE'|| field.type ==  'REF_TEMPLATE') && '91px'}"
								@click="controlCheck(field,item.type)">
								<span>
									<i class="iconfont"
									:style=" {paddingRight:(field.type == 'TIME_PICKER_RANGE' || field.type ==  'REF_TEMPLATE') && '5px' }"
									:class="fieldIcon(field.type)"></i>
								</span>
								<span>{{ field.name }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="center-class">
					<div class="content-center">
						<div class="content-center-content">
							<div id="custom-column-clue-tag">
								<div class="custom-column system-custom-column">
									<div v-for="(items,indexs) in systemFileldList" :key="items.id" :class="'all-fields-item'">
									<div class="field-name">
										<span>{{ items.name }}</span>
										<span class="red" v-if="items.required || items.required">*</span>
									</div>
									<div class="custom-field" :class="chooseFieldIndex===items.id?'chooseColor':''">
										<div @click="setField(items,index,indexs,$event)" class="field-info-2">
											<span class="field-info-right">
												<span 
													v-if="items.defaultValue&&items.defaultValue.length >0 "
													class="field-info-right-hasvalue">
													{{items | defaultValueFilter}}
												</span>
												<span v-else class="field-info-right-novalue">
													{{items.extInfo.placeholder?items.extInfo.placeholder:(items.type==='INPUT'||items.type==='TEXTAREA'||items.systemFieldKey==='description')?'请输入':'请选择'}}
												</span>
												<i 
													class="arsenal_icon arsenalangle-right-solid"
													v-if="items.type==='TIME_PICKER'|| items.type==='CASCADER'||
													items.type==='CHECKBOX'||items.type==='RADIO'">
												</i>
											</span>
										</div>
										<span 
											:class="items.extInfo.isDelete?'deleteRedIcon':'deleteGrayIcon'"
											@click="deleteField(index,indexs,items,$event)">
											<i class="iconfont guoran-a-16-09"></i>
										</span>
									</div>
									</div>
								</div>
								<div v-for="(item,index) in customColumn" :key="item.id" :data-id="JSON.stringify(item)">
									<div  class="custom-column">
										<div class="custom-column-field" :id="getId('custom-column-field',item.id)">
											<div v-for="(items,indexs) in item.allFields" :key="items.id"
												:data-id="JSON.stringify(items)"
												:class="'all-fields-item'">
												<!-- v-if="items.type !== 'EMAIL' && items.type !== 'THIRD_PARTY_ACCOUNT' && items.type !== 'USER_NAME' && items.type !== 'ROLE' && items.type !== 'ACCOUNT_GROUP' && items.type !== 'DATA_PERMISSIONS' && items.type !== 'TYPE_MATE' && items.type !== 'WORKING_TIME' && items.type !== 'MAX_NUM_OF_ORDERS_PROCESSED'" -->
												<div class="field-name have-sort">
													<span>{{ items.name }}</span>
													<span class="red" v-if="items.required || items.required">*</span>
												</div>
												<div class="custom-field" :class="chooseFieldIndex===items.id?'chooseColor':''">
													<span 
														class="sortIcon my-handle" 
														@mouseenter="resortCustomField($event,item)">
														<i class="iconfont guoran-a-16-10"></i>
													</span>
													<div @click="setField(items,index,indexs,$event)"
														:class="items.type!== 'TEXTAREA'?'field-info':'field-info-2'"
														v-if="items.type !== 'EXPLAIN'">
														<span class="field-info-right">
															<span 
																v-if="items.defaultValue&&items.defaultValue.length >0"
																class="field-info-right-hasvalue">
																{{items | defaultValueFilter}}
															</span>
															<span v-else class="field-info-right-novalue">
																{{items.extInfo.placeholder?items.extInfo.placeholder:(items.type==='INPUT'||items.type==='TEXTAREA'||items.systemFieldKey==='description')?'请输入':'请选择'}}
															</span>
															<i 
																class="arsenal_icon arsenalangle-right-solid"
																v-if="items.type==='TIME_PICKER'|| items.type==='CASCADER'||
																items.type==='CHECKBOX'||items.type==='RADIO'">
															</i>
														</span>
													</div>
													<div 
														v-if="items.type==='EXPLAIN'" class="field-info-explain"
														@click="setField(items,index,indexs,$event)">
														<span>
															<i class="el-icon-warning"></i>
														</span>
														<span>{{ items.extInfo.placeholder}}</span>
													</div>
													<span 
														:class="items.extInfo.isDelete?'deleteRedIcon':'deleteGrayIcon'"
														@click="deleteField(index,indexs,items,$event)">
														<i class="iconfont guoran-a-16-09"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
				<div class="content-right" v-if="JSON.stringify(fieldSet)!=='{}' && fieldSet.type !== 'COLUMN'">
					<fieldRightSetting 
					:fieldData="fieldSet"
					:activeObj="fieldSet" 
					:centerlist="customColumn" 
					:allColumn="customColumn"/>
				</div>
				<div class="content-right" v-else>
					<div class="content-right-content-c">
					<div class="content-right-content-c-text">点击模板字段进行配置</div>
					</div>
				</div>
				</div>
			</div>
        </div>
      <!-- <el-dialog :title="editTitle" :visible.sync="dialogVisibleEdit" width="30%" :close-on-click-modal="false">
        <div class="edit-options">
          <div style="margin-bottom: 10px">每行对应一个选项22222</div>
          <ckeditor :editor="ckeditor.editor" v-model="contentConent" :config="ckeditor.editorConfig"></ckeditor>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEdit = false">取 消</el-button>
          <el-button type="primary" @click="editOptions">确 定</el-button>
        </span>
      </el-dialog> -->
      <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
/* eslint-disable */
let that
import SideNav from "../../menu/SideNav.vue";
import Sortable from 'sortablejs'
import { templateDatas, allTemplateConstrols,requiredMessage,systemControls } from "./common";
import { v4 as uuidv4 } from 'uuid'
import CKEDITOR from 'ckeditor'
import popup from '../../../components/popup'
import fieldRightSetting from './components/fieldRightSetting.vue'
export default {
	name: 'editClueField',
	data () {
		return {
			workOrderTemConstrols: [],
			systemControl:systemControls,
			headerList: [
				{
				name: '模板信息设置',
				index: 1,
				isActive: true,
				iconfont: 'guoran-shuzi-1'
				},
				{
				name: '模板配置',
				index: 2,
				isActive: false,
				iconfont: 'guoran-shuzi-2'
				},
			],
			vdomShow: 1,
			ruleList: [],
			fieldSet: {},
			customColumn: [],
			dialogVisibleEdit: false,
			editTitle: '单选框-批量编辑',
			templateDes: '',
			priorityList: [
				{
				id: 'URGENT',
				name: '紧急'
				},
				{
				id: 'HIGH',
				name: '高'
				},
				{
				id: 'MIDDLE',
				name: '中'
				},
				{
				id: 'LOW',
				name: '低'
				}
			],
			optionName: '',
			addBtn: true,
			oprationBool: '',
			cascaderItem: [],
			cascaderItem2: [],
			cascaderIndex: 0,
			accountGroupList: [],
			defaultValueList: [],
			dialogTemplateName: '',
			customColumnList: [],
			defaultFieldList: [],
			workOrderFieldList: [],
			ckeditor: {
				editor: CKEDITOR.ClassicEditor,
				editorConfig: {
				toolbar: [],
				askPluginListener: [
					{
					event: 'PLACEHOLDER',
					process: (data, callback) => {
						console.debug('editor call back', data, callback)
					}
					}
				] // 注册监听
				}
			},
			contentConent: '',
			chooseFieldIndex: 0,
			chooseColumnIndex: -1,
			chooseColumnId: "",
			searchable: true,
			cascaderDefault: [],
			haveVersion: false,
			layoutStructure: 'BFC',
			fieldSetCopy: null,
			dialogMemberTree:false,
			clientValue:"",
			memberList:[],
			memberInfo4Client:{
				corpId: '',
				type: '',
				bindType: '',
				memberId: '',
				name: '',
				deptId: "",
				deptName: ""
			},
			staffCustomer:{
				id:"",
				name:""
			},
			staffType:"",
			clientOptions:[
				{
				label:"当前报单人",
				value:"CURRENT"
				}
			],
			accountDataTree: [],
			accountDataTreeCopy: [],
			scopedDataTree:[],
			roleData:[],
			roleIds:[],
			systemFileldList:[],
			templateInfo:{}
		}
	},
	components: {
		popup,
		SideNav,
		fieldRightSetting,
	},
	created(){
		let filterFieldType = ['RICHTEXT','IMAGE_SELECT','IMAGE','VIDEO','ATTACHMENT','ACCOUNT']
		this.workOrderTemConstrols = JSON.parse(JSON.stringify(allTemplateConstrols)).map((field) => {
			if (field.type == "senior") {
				let arr = ['引用字段', '关联数据模板',]
				field.list = field.list.filter(item => {
				return arr.includes(item.name) && item
				})
			} else {
				field.list = field.list.filter(item =>{
				return !filterFieldType.includes(item.type)
				})
			}
			return field
		})
		if (this.$route.query.id) {
			this.getTemDetail()
		} else {
			setTimeout(() => {
				this.getRoles();
				this.setDefaultValue()
			}, 500)
		}
	},
	beforeCreate () {
		that = this
	},
	computed:{
		isMENGNIU(){
            let bool = false;
            let mainId = localStorage.getItem('_mainId');
            let mainIdList = ['51778574f46346ce96478d7b7fc2ca1d','ab0f90737c8b4f2d85ba2157e4473110','31623ccfe9dd4957bbd59c5823878bbe','3e83057a1bd74b79b86859ad619fdbbb'];
            if (mainIdList.indexOf(mainId)!==-1){
                bool = true;
            } else {
                bool = false;
            }
            return bool
        },
	},
	filters: {
		defaultValueFilter (value) {
		let name = ''
		if (
			value.type === 'CHECKBOX'
		) {
			if (value.defaultValue && value.defaultValue.length > 0) {
			for (let i = 0; i < value.defaultValue.length; i++) {
				name +=
				i === 0 ? value.defaultValue[i] : ',' + value.defaultValue[i]
			}
			} else if (value && value.defaultValue && value.defaultValue.length > 0) {
			for (let i = 0; i < value.defaultValue.length; i++) {
				name +=
				i === 0 ? value.defaultValue[i] : ',' + value.defaultValue[i]
			}
			} else {
			name = value.extInfo.placeholder
				? value.extInfo.placeholder
				: '请选择'
			}
		} else if (value.type === 'CASCADER') {
			if (value.defaultValue && value.defaultValue.length > 0) {
			for (let i = 0; i < value.defaultValue.length; i++) {
				name +=
				i === 0 ? value.defaultValue[i] : ',' + value.defaultValue[i]
			}
			} else if (value && value.defaultValue && value.defaultValue.length > 0) {
			for (let i = 0; i < value.defaultValue.length; i++) {
				if (Array.isArray(value.defaultValue[i])) {
				name +=
					i === 0 ? value.defaultValue[i][value.defaultValue[i].length - 1] : ',' + value.defaultValue[i][value.defaultValue[i].length - 1]
				} else {
				name +=
					i === 0 ? value.defaultValue[i] : ',' + value.defaultValue[i]
				}
			}
			} else {
			name = value.extInfo.placeholder
				? value.extInfo.placeholder
				: '请选择'
			}
		} else if (value.type === 'TIME_PICKER') {
			name = value.extInfo.placeholder
			? value.extInfo.placeholder
			: '请选择'
		} else {
			console.log(value);
			if (value && value.defaultValue && value.defaultValue.length > 0) {
			if (Array.isArray(value.defaultValue)) {
				name = value.defaultValue.join()
			} else {
				name = value.defaultValue
			}
			} else if (value.defaultValue && value.defaultValue.length > 0) {
			if (Array.isArray(value.defaultValue)) {
				name = value.defaultValue.join()
			} else {
				name = value.defaultValue
			}
			} else if (Array.isArray(value.defaultValue) || Array.isArray(value.defaultValue)) {
			name = '请选择'
			} else {
			name = '请输入'
			}
			// name =
			//   value.defaultValue && value.defaultValue.length > 0
			//     ? value.defaultValue
			//     : (value && value.defaultValue && value.defaultValue.length > 0 ? value.defaultValue : '请选择') 
		}
		return name
		}
	},
	watch:{
		customColumn:{
			handler(n){
				console.log(n,'customColumn');
				// this.systemFileldList = [];
				// n.forEach(columnItem => {
				// 	columnItem.allFields.forEach(item => {
				// 		if(item.isSystemField){
				// 			this.systemFileldList.push(item);
				// 		}
				// 	})
				// })
				// console.log(this.systemFileldList,'systemFileldList');
			},
			deep:true
		},
		systemFileldList:{
			handler(n){
				console.log(n,'systemFileldList');
			},
			deep:true
		}
	},
	methods: {
		//获取所有角色
		getRoles() {
			let url = "api/role?excludeAssociatedTemplate=true";
			this.$http.get(url).then((res) => {
				if (res.data.code === "0") {
					res.data.data.forEach(item => {
						// 上下游负责人64    子主体超级管理员 -1
						if(item.id != 64 && item.id != -1){
							this.roleData.push(item);
						}
					})
					this.handlerSpecialRoleId();
				}
			});
		},
		// 如果模版中的角色id在extInfo找不到 , 则调用此接口进行赋值getRoleDetail
		handlerSpecialRoleId(){
			let specialRoleIds = [],havedRoleIds = [];
			this.roleData.forEach(roleItem => {
				havedRoleIds.push(roleItem.id);
			})
			this.roleIds.forEach(item=>{
				if(havedRoleIds.indexOf(item) === -1){
					specialRoleIds.push(item);
				}
			})
			specialRoleIds = Array.from(new Set(specialRoleIds))
			if(specialRoleIds.length > 0){
				specialRoleIds.forEach(item => {
					this.$http.get('api/role/detail/' + item).then(res => {
						if (res.data.code === '0') {
							this.roleData.push(res.data.data)
						}
					})
				})
			}
		},
		headerTab (i) {
		this.vdomShow = i
		for (let index = 0; index < this.headerList.length; index++) {
			this.headerList[index].isActive = i == this.headerList[index].index
		}
		
		},
		changeMaxNum (value) {
		const regex = /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
		console.debug(value)
		if (!regex.test(value) && value) {
			this.fieldSet.extInfo.maxNum = 0
		}
		},
		filterFieldList () {
		let arr = []
		this.defaultFieldList.forEach(item => {
			if (
			item.key !== 'assigneeGroupId' &&
			item.key !== 'themeName' &&
			item.key !== 'description' &&
			item.key !== 'themeDescribe'
			) {
			arr.push(item)
			}
		})
		return arr
		},
		loopCascade (options, childrenIds,type) {
		options.forEach(option => {
			if (option.optionStruct) {
			for (let k in option.optionStruct) {
				if (type === 'last'){
				if (childrenIds.indexOf(k) !== -1) {
					delete option.optionStruct[k]
				}
				}else {
				if (childrenIds.indexOf(k) === -1) {
					delete option.optionStruct[k]
				}
				}

			}
			}
		})
		if (options.children) {
			this.loopCascade(options.children, childrenIds)
		}
		},
		//新增工单时初始化设置值
		setDefaultValue (type) {
			let uid = uuidv4().toString().replaceAll('-', '')
			this.customColumn = [{
					id: uid,
					columnId:uid,
					field: {
						name: '',
						type: 'COLUMN',
						remark: {
							enable: false,
							text: ''
						},
						id:uid,
						backgroundColor: '#ECF1FF',
						required: false,
						isSystemField: false,
						disabled: false
					},
					visibleWithoutPermission: true,
					allFields: []
				}
			]
			let systemFileldList = [
				{
					columnId: '',
					name: '邮箱',
					type:"EMAIL",
					description: '请选择',
					defaultValue: '',
					required: true,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"邮箱：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: false,
						isDelete: false,
						placeholder: '请选择'
					},
					isSystemField: true,
					systemFieldKey: 'EMAIL',
				
				},
				{
					columnId: '',
					name: '绑定第三方账号',
					description: '请选择',
					type:"THIRD_PARTY_ACCOUNT",
					defaultValue: '',
					required: false,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"绑定第三方账号：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: false,
						isDelete: false,
						placeholder: '请选择'
					},
					isSystemField: true,
					systemFieldKey: 'THIRD_PARTY_ACCOUNT',
				},
				{
					columnId: '',
					name: '账号使用者姓名',
					description: '请选择',
					type:"USER_NAME",
					defaultValue: '',
					required: true,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"账号使用者姓名：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: false,
						isDelete: false,
						placeholder: '请选择'
					},
					isSystemField: true,
					systemFieldKey: 'USER_NAME',
				},
				{
					columnId: '',
					name: '账号类型',
					description: '请选择',
					type:"ROLE",
					defaultValue: '',
					required: true,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"账号类型：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: true,
						isDelete: false,
						placeholder: '请选择',
						clientType: ''
					},
					isSystemField: true,
					systemFieldKey: 'ROLE',
				},
				{
					columnId: '',
					name: '所属账号组',
					description: '请选择',
					type:"ACCOUNT_GROUP",
					defaultValue:"",
					required: true,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"所属账号组：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: false,
						isDelete: false,
						placeholder: '请选择'
					},
					isSystemField: true,
					systemFieldKey: 'ACCOUNT_GROUP',
				},
				// {
				// 	columnId: '',
			// 		name: '上级',
			// 		type:"superior",
			// 		description: '请选择',
			// 		defaultValue: [],
			// 		required: true,
					// visible: {
					// 	enabled: false,
					// 	extInfo: {
					// 		displayFormat:"上级：",
					// 	}
					// },
			// 		remark: {
			// 			enable: false,
			// 			text: "",
			// 		},
			// 		extInfo: {
			// 			
			// 			visibleWithoutPermission: false,
			// 			disabled: false,
			// 			isDelete: false,
			// 			placeholder: '请选择'
			// 		},
				// 	isSystemField: true,
				// 	systemFieldKey: 'SUPERIOR',
				// },
				{
					columnId: '',
					name: '数据权限',
					description: '请选择',
					type:"DATA_PERMISSIONS",
					defaultValue: [],
					required: true,
					visible: {
						enabled: false,
						extInfo: {
							displayFormat:"数据权限：",
						}
					},
					remark: {
						enable: false,
						text: "",
					},
					extInfo: {
						visibleWithoutPermission: false,
						disabled: false,
						isDelete: false,
						placeholder: '请选择'
					},
					isSystemField: true,
					systemFieldKey: 'DATA_PERMISSIONS',
				},
			] 
			if(this.isMENGNIU){
				systemFileldList = [...systemFileldList,...[{
								columnId: '',
								name: '模块/系统',
								description: '请选择',
								type:"MN_MODULE",
								defaultValue: [],
								required: false,
								visible: {
									enabled: false,
									extInfo: {
										displayFormat:"模块/系统：",
									}
								},
								remark: {
									enable: false,
									text: "",
								},
								extInfo: {
									visibleWithoutPermission: false,
									disabled: false,
									isDelete: false,
									placeholder: '请选择'
								},
								isSystemField: true,
								systemFieldKey: 'MN_MODULE',
							},
							{
								columnId: '',
								name: '公司',
								description: '请选择',
								type:"MN_COMPANY",
								defaultValue: [],
								required: false,
								visible: {
									enabled: false,
									extInfo: {
										displayFormat:"公司：",
									}
								},
								remark: {
									enable: false,
									text: "",
								},
								extInfo: {
									visibleWithoutPermission: false,
									disabled: false,
									isDelete: false,
									placeholder: '请选择'
								},
								isSystemField: true,
								systemFieldKey: 'MN_COMPANY',
							},
							{
								columnId: '',
								name: '工号',
								description: '请输入工号',
								type:"MN_WORK_NO",
								defaultValue: [],
								required: false,
								visible: {
									enabled: false,
									extInfo: {
										displayFormat:"工号：",
									}
								},
								remark: {
									enable: false,
									text: "",
								},
								extInfo: {
									visibleWithoutPermission: false,
									disabled: false,
									isDelete: false,
									placeholder: '请输入工号'
								},
								isSystemField: true,
								systemFieldKey: 'MN_WORK_NO',
							},
							{
								columnId: '',
								name: '电话',
								description: '请输入电话',
								type:"MN_TEL",
								defaultValue: [],
								required: false,
								visible: {
									enabled: false,
									extInfo: {
										displayFormat:"电话：",
									}
								},
								remark: {
									enable: false,
									text: "",
								},
								extInfo: {
									visibleWithoutPermission: false,
									disabled: false,
									isDelete: false,
									placeholder: '请输入电话'
								},
								isSystemField: true,
								systemFieldKey: 'MN_TEL',
							},
							{
								columnId: '',
								name: '对接人',
								description: '请选择',
								type:"MN_CONTACT_PERSON",
								defaultValue: [],
								required: false,
								visible: {
									enabled: false,
									extInfo: {
										displayFormat:"对接人：",
									}
								},
								remark: {
									enable: false,
									text: "",
								},
								extInfo: {
									visibleWithoutPermission: false,
									disabled: false,
									isDelete: false,
									placeholder: '请选择'
								},
								isSystemField: true,
								systemFieldKey: 'MN_CONTACT_PERSON',
							}]]
			}
			this.systemFileldList = systemFileldList;
			this.workOrderTemConstrols.forEach(item => {
				if(item.type === 'system'){
					// this.customColumn[0].allFields = [...this.customColumn[0].allFields,...item.list]
					this.systemFileldList = [...this.systemFileldList,...item.list];
				}
			})
			// // for (let i = 0; i < this.customColumn.length; i++) {
			// 	// let uid = uuidv4().toString().replaceAll('-', '')
			// 	// this.customColumn[i].id = uid
			// 	for (let j = 0; j < this.systemFileldList.length; j++) {
			// 		let fieldId = uuidv4().toString().replaceAll('-', '')
			// 		// this.systemFileldList[i].columnId = uid
			// 		if (this.systemFileldList[j].type === 'INPUT' || this.systemFileldList[j].type === 'TEXTAREA') {
			// 			let maxNum = 0
			// 			maxNum = !this.systemFileldList[j].extInfo.maxNum ? 100 :this.systemFileldList[j].extInfo.maxNum;
			// 			this.systemFileldList[j].extInfo.maxNum = maxNum
			// 		}
			// 		if (!this.systemFileldList[j].id) {
			// 			this.systemFileldList[j].id = fieldId;
			// 		}
			// 		this.systemFileldList[j].visible.extInfo.displayFormat = 
			// 			this.systemFileldList[j].name + `<span class="ask-component-placeholder" th:text="${this.systemFileldList[j].id}">${this.systemFileldList[j].name}</span>`
			// 	}
			// // }
			this.systemFileldList.forEach(item => {
				let fieldId = uuidv4().toString().replaceAll('-', '');
				if (item.type === 'INPUT' || item.type === 'TEXTAREA') {
					let maxNum = 0
					maxNum = !item.extInfo.maxNum ? 100 : item.extInfo.maxNum;
					item.extInfo.maxNum = maxNum
				}
				if (!item.id) {
					item.id = fieldId;
				}
				if(item.type === 'MAX_NUM_OF_ORDERS_PROCESSED'){
					item.visible.extInfo.displayFormat = `处理中:<span class='ask-component-placeholder ck-widget'>处理中工单数</span>/ 最大可处理:<span class='ask-component-placeholder ck-widget'>最大可处理工单数</span>`
				} else {
					item.visible.extInfo.displayFormat = item.name + `：<span class="ask-component-placeholder" th:text="${item.id}">${item.name}</span>`
				}
			})
			this.systemFileldList.forEach(customColumnItem => {
				this.workOrderTemConstrols.forEach((v,i) => {
					if(v.type === 'system'){
						v.list.forEach((systemItem,systemIndex) => {
							if(systemItem.systemFieldKey === customColumnItem.systemFieldKey){
								v.list.splice(systemIndex,1)
							}
						})
					}
				})
			})
			console.log(this.systemFileldList,'this.systemFileldList');
			// this.customColumnList = JSON.parse(JSON.stringify(this.customColumn))
			// this.fieldSet = {
			// 	field: this.customColumn[0] ? this.customColumn[0] : this.customColumn[0].fieldColumn
			// }
			this.fieldSet = {}
		},
		// 设置icon
		fieldIcon (type) {
			switch (type) {
				case 'COLUMN':
				return 'guoran-a-12-04'
				// 基础控件
				case 'INPUT':
				return 'guoran-a-12-02'
				case 'TEXTAREA':
				return 'guoran-a-12-03'
				case 'RICHTEXT':
				return 'guoran-tongyichicun-fuwenben'
				case 'RADIO':
				return 'guoran-a-12_01'
				case 'CHECKBOX':
				return 'guoran-a-12-08'
				case 'TIME_PICKER':
				return 'guoran-a-12-09'
				case 'TIME_PICKER_RANGE':
				return 'guoran-shijianfanwei'
				case 'SELECT':
				return 'guoran-a-12-07'
				case 'CASCADER':
				return 'guoran-tongyichicun-12-05-jilian'
				case 'IMAGE_SELECT':
				case 'IMAGE':
				return 'guoran-tongyichicun-tupian2'
				case 'VIDEO':
				return 'guoran-shipin1'
				case 'ATTACHMENT':
				return 'guoran-fujian'
				case 'EXPLAIN':
				return 'guoran-a-12-06'
				// 高级控件
				case 'FORMULA':
				return 'guoran-tongyichicun-jisuangongshi'
				case 'REF':
				return 'guoran-tongyichicun-yinyongziduan'
				case 'COPY':
				return 'guoran-tongyichicun-fuzhiziduan'
				case 'REF_TEMPLATE':
				return 'guoran-tongyichicun-guanlianshujumoban'
				case 'ORG':
				return 'guoran-tongyichicun-zuzhijiagou'
				case 'USER':
				return 'guoran-tongyichicun-yonghu'
				case 'ACCOUNT':
				return 'guoran-tongyichicun-xitongzhanghao'
				case 'PROVINCE':
				return 'guoran-tongyichicun-shengfen'
				case 'CITY':
				return 'guoran-tongyichicun-chengshi'
				// 系统控件
				case 'WORK_ORDER_STATUS':
				return 'guoran-tongyichicun-gongdanzhuangtai2'
				case 'PRIORITY':
				return 'guoran-tongyichicun-youxianji'
				case 'ASSIGNEE':
				return 'guoran-tongyichicun-shouliren'
				case 'FOLLOWER':
				return 'guoran-tongyichicun-guanzhuren'
				case 'REPORTER':
				return 'guoran-tongyichicun-baodanren'


				case 'TYPE_MATE':
				return 'guoran-shanchangchuligongdan'
				case 'WORKING_TIME':
				return 'guoran-gongzuoshijian'
				case ' MAX_NUM_OF_ORDERS_PROCESSED':
				return 'guoran-tongshichuligongdanshu'
			}
		},
		//获取模板详情
		getTemDetail () {
			// await this.getRoles();
			this.customColumn = []
			let url = '/api/account-template/find-one?id=' + this.$route.query.id
			this.$http.get(url).then(res => {
				if (res.data.code === '0') {
					this.templateInfo = res.data.data;
					this.roleIds = res.data.data.roleIds;
					this.roleData = [...this.roleData,...res.data.data.extInfo && res.data.data.extInfo.list ? res.data.data.extInfo.list : []];
					this.getRoles();
					this.defaultFieldList =
						res.data.data.defaultFieldList &&
						res.data.data.defaultFieldList.defaultFieldInfos
						? res.data.data.defaultFieldList.defaultFieldInfos
						: []
					this.workOrderFieldList =
						res.data.data.workOrderFieldList &&
						res.data.data.workOrderFieldList.fieldTwoDimensionalList &&
						res.data.data.workOrderFieldList.fieldTwoDimensionalList[0]
						? res.data.data.workOrderFieldList.fieldTwoDimensionalList[0]
						: []
					this.layoutStructure = res.data.data.layout ? res.data.data.layout.type : 'BFC'
					this.dialogTemplateName = res.data.data.name
					this.systemFileldList = [];
					// 检查工单版本号
					// if (res.data.data.version) {
					if (res.data.data.fieldColumns) {
						let fieldColumnsNew = [{
							id:res.data.data.fieldColumns[0].id,
							name:res.data.data.fieldColumns[0].name,
							visibleWithoutPermission:res.data.data.fieldColumns[0].visibleWithoutPermission,
							allFields:[]
						}]
						// 过滤蒙牛特殊字段
						res.data.data.fieldColumns[0].allFields.forEach(_item => {
							// if(_item.type!=='MN_MODULE' && _item.type!=='MN_COMPANY' && _item.type!=='MN_WORK_NO' && _item.type!=='MN_TEL' && _item.type!=='MN_CONTACT_PERSON'){
								if(_item.isSystemField){
									this.systemFileldList.push(_item)
								} else {
									fieldColumnsNew[0].allFields.push(_item);
								}
							// }
						})
						console.log(fieldColumnsNew,'fieldColumnsNew');
						let customColumn = fieldColumnsNew;
						let newCustomColumn = templateDatas(customColumn);
						console.log(newCustomColumn,'newCustomColumn');
						newCustomColumn.forEach(item => {
							this.customColumn.push(item);
						})
						// 循环处理 字段是否包含 空的分栏
						// systemFieldKey  为前端 添加的字段，代替id表示是系统字段，不可删除的字段
						this.customColumn.forEach(item => {
							if (!item.fieldColumn) {
								item.fieldColumn = {
									allFields: null,
									backgroundColor: '#EFF5FF',
									dataTemplateId: null,
									defaultValue: null,
									description: null,
									disabled: false,
									extInfo: null,
									height: null,
									id: null,
									isSystemField: false,
									left: null,
									name: item.name,
									refFieldId: null,
									refTemplateId: null,
									remark: { enable: false, text: '备注文本' },
									required: false,
									searchable: null,
									top: null,
									type: 'COLUMN',
									widgetId: null,
									width: null
								}
							}
							if (!item.allFields) {
								this.$set(item, 'allFields', [])
							} else {
								item.allFields.forEach(items => {
									if (items.type === 'CASCADER') {
										if (!items.extInfo.cascadeDown.options) {
											let options = items.extInfo.cascadeDown[0] ? JSON.parse(JSON.stringify(items.extInfo.cascadeDown[0].options)) : [];
											this.$set(items.extInfo, 'cascadeDown', {})
											this.$set(items.extInfo.cascadeDown, 'options', options)
										}
									}
								})
							}
						})
						// 循环处理字段属性
						this.customColumn[0].allFields.forEach((item,index) => {
							if(item.type === 'INPUT' || item.type === 'TEXTAREA'){
								let maxNum = 0
								maxNum = !item.extInfo.maxNum ? 100 :item.extInfo.maxNum
								item.extInfo.maxNum = maxNum
							}
							// this.workOrderTemConstrols.forEach((workOrderTemConstrolsItem,workOrderTemConstrolsIndex) => {
							// 	if(workOrderTemConstrolsItem.type === 'system'){
							// 		workOrderTemConstrolsItem.list.forEach((systemItem,systemIndex) => {
							// 			if(systemItem.type === item.type && systemItem.isSystemField){
							// 				workOrderTemConstrolsItem.list.splice(systemIndex,1)
							// 			}
							// 		})
							// 	}
							// })
						})
						this.systemFileldList.forEach((item,index) => {
							this.workOrderTemConstrols.forEach((workOrderTemConstrolsItem,workOrderTemConstrolsIndex) => {
								if(workOrderTemConstrolsItem.type === 'system'){
									workOrderTemConstrolsItem.list.forEach((systemItem,systemIndex) => {
										if(systemItem.type === item.type && systemItem.isSystemField){
											workOrderTemConstrolsItem.list.splice(systemIndex,1)
										}
									})
								}
							})
						})
						
						this.customColumnList = JSON.parse(JSON.stringify(this.customColumn))
						this.fieldSet = {
							field: this.customColumn[0].fieldColumn
						}
						this.fieldSet = this.customColumn[0].fieldColumn
					}
				}
			})
		},
		// 去重
		unique (arr) {
		for (var i = 0; i < arr.length; i++) {
			for (var j = i + 1; j < arr.length; j++) {
			if (arr[i] && arr[j]) {
				if (arr[i].id === arr[j].id) {
				//第一个等同于第二个，splice方法删除第二个
				arr.splice(j, 1)
				j--
				}
			}
			}
		}
		return arr
		},
		getAccountGroup () {
			let url = '/workerorder-api/account/group'
			this.$http.get(url).then(res => {
				let groupList = res.data.data
				console.log(groupList,'groupList');
				let accountGroupList = JSON.parse(JSON.stringify(groupList))
				accountGroupList.forEach(item => {
					this.$set(item, 'children', [])
					// this.accountPersonList.forEach(items => {
					// 	if (item.id === items.groupId) {
					// 	item.children.push(items)
					// 	}
					// })
				})
				this.accountGroupList = accountGroupList
			})
		},
		// 设置id
		getId (name, id) {
			return name + id
		},
		//设置字段属性
		setField (item, index, index2, e) {
			e.stopPropagation()
			this.cascaderItem2 = []
			this.cascaderItem = []
			this.chooseFieldIndex = item.id
			this.chooseColumnIndex = index;
			this.chooseColumnId = ""
			if (item.type === 'CASCADER') {
				item.defaultValue = item.defaultValue ? item.defaultValue : []
				let options = item.extInfo.cascadeDown.options ? item.extInfo.cascadeDown.options : item.extInfo.cascadeDown[0].options
				if (item.defaultValue[1]) {
					options.forEach(cas => {
					if (cas.value === item.defaultValue[0]) {
						this.cascaderItem.children = cas.children
					}
					})
				}
				if (item.defaultValue[2] && this.cascaderItem.children) {
					this.cascaderItem.children.forEach(cas => {
					if (cas.value === item.defaultValue[1]) {
						this.cascaderItem2 = cas
					}
					})
				}
				this.cascaderDefault = JSON.parse(JSON.stringify(item.defaultValue))
			}
			if (item.extInfo.exinfo) {
				this.$set(item.extInfo, 'options', item.extInfo.exinfo)
			}
			this.fieldSet = item
		},
		//删除字段
		deleteField (index, fieldIndex, item, e) {
			console.log(item,'item');
			e.stopPropagation()
			if (item.extInfo.isDelete) {
				if(item.isSystemField){
					this.systemFileldList.splice(fieldIndex,1);
				} else {
					this.customColumn[index].allFields.splice(fieldIndex, 1)
				}
				this.fieldSet = {}
			}
			if (item.extInfo.isDelete && item.isSystemField) {
				// this.systemControl.push(item)
				this.workOrderTemConstrols.forEach((controlItem,controlIndex) => {
					if(controlItem.type === 'system'){
						controlItem.list.push(item)
					}
				})
			}
			console.log(this.workOrderTemConstrols,'this.workOrderTemConstrols');
		},
		//选择组件事件
		controlCheck (item, type) {
			let fieldItem = JSON.parse(JSON.stringify(item))
			let fieldId = uuidv4().toString().replaceAll('-', '')
			fieldItem.columnId = this.customColumn[0].id
			fieldItem.id = fieldId
			fieldItem.widgetId = fieldId
			if (type == 'system') {
				this.systemFileldList.push(fieldItem);
				this.workOrderTemConstrols.forEach(constrolItem => {
					if(constrolItem.type == 'system'){
						constrolItem.list.forEach((v,i) => {
							if(v.type === fieldItem.type){
								constrolItem.list.splice(i,1);
							}
						})
					}
				})
			} else {
				fieldItem.visible.extInfo.displayFormat = fieldItem.name + `：<span class="ask-component-placeholder" th:text="${fieldItem.id}">${fieldItem.name}</span>`
				this.customColumn[0].allFields.push(fieldItem);
			}
		},
		addCascaderOption () {
		this.addBtn = false
		},
		addCascadeOption (item, index) {
		if (this.optionName) {
			if (index === 0) {
			item.options.push({
				value: this.optionName,
				label: this.optionName
			})
			} else if (index === 1) {
			item.push({
				value: this.optionName,
				label: this.optionName
			})
			} else if (index === 2) {
			item.push({
				value: this.optionName,
				label: this.optionName
			})
			}
		}
		this.optionName = ''
		},
		deleteCascader (index) {
		if (index === 0) {
			this.fieldSet.extInfo.cascadeDown[0].options = []
		} else if (index === 1) {
			this.cascaderItem = []
			this.cascaderItem2 = []
		} else if (index === 2) {
			this.cascaderItem2 = []
		}
		this.fieldSet.defaultValue = []
		this.cascaderDefault[index] = ''
		},
		addCascader () {
		if (!this.cascaderDefault[0]) {
			this.$message({
			message: '请选择级联下拉',
			duration: 2000,
			type: 'warning'
			})
			return
		}
		if (!this.cascaderDefault[1] && this.cascaderItem.children) {
			this.$message({
			message: '请选择级联下拉',
			duration: 2000,
			type: 'warning'
			})
			return
		}
		if (!this.cascaderItem.children) {
			this.$set(this.cascaderItem, 'children', [])
		}
		if (this.cascaderItem.children.length > 0) {
			this.$set(this.cascaderItem2, 'children', [])
		}
		},
		opration (type, index, item, cascaderIndex) {
		if (cascaderIndex === '0') {
			if (type === 'up') {
			let tempOption = item.options[index]
			this.$set(item.options, index, item.options[index - 1])
			this.$set(item.options, index - 1, tempOption)
			} else if (type === 'dowm') {
			let temp = item.options[index]
			this.$set(item.options, index, item.options[index + 1])
			this.$set(item.options, index + 1, temp)
			} else if (type === 'edit') {
			this.oprationBool = index
			this.addBtn = true
			} else if (type === 'delete') {
			item.options.splice(index, 1)
			}
		} else if (cascaderIndex !== '0') {
			if (type === 'up') {
			let tempOption = item[index]
			this.$set(item, index, item[index - 1])
			this.$set(item, index - 1, tempOption)
			} else if (type === 'dowm') {
			let temp = item[index]
			this.$set(item, index, item[index + 1])
			this.$set(item, index + 1, temp)
			} else if (type === 'edit') {
			this.oprationBool = index
			this.addBtn = true
			} else if (type === 'delete') {
			item.splice(index, 1)
			}
		}
		},
		confirmOptionItem (item) {
		item.label = item.value
		this.oprationBool = ''
		},
		visibleChange (value) {
		this.oprationBool = ''
		this.addBtn = true
		this.optionName = ''
		},
		cascaderChange (item, index) {
		if (index === 0) {
			this.cascaderItem2 = []
			this.cascaderDefault[1] = ''
			this.fieldSet.extInfo.cascadeDown[0].options.forEach(items => {
			if (items.value === item) {
				this.cascaderItem = items
			}
			})
		} else {
			this.cascaderItem.children.forEach(items => {
			if (items.value === item) {
				this.cascaderItem2 = items
			}
			})
		}
		if (index === 1) {
			this.cascaderDefault[2] = ''
		}
		},
		cacleInput () {
		this.addBtn = true
		},
		//删除单选复选中的选项
		deleteOption (index) {
		this.fieldSet.extInfo.options.splice(index, 1)
		},
		changeOptinsValue (value, item) {
		item.label = item.value
		},
		// 校验字段是否必填
		verification () {
		if (!this.dialogTemplateName) {
			this.$message({
			message: '模板名称是必填的',
			duration: 2000,
			type: 'warning'
			})
			return false
		}
		return true
		},
		//发布
		pulishClueField () {
			let flag = this.verification()
			if (!flag) return
			this.$confirm('确定发布?', '发布', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let customColumnLists = JSON.parse(JSON.stringify(this.customColumn))
					customColumnLists.forEach(item => {
						item.allFields = [...this.systemFileldList,...item.allFields]
						// item.allFields.forEach(allFieldsItem => {

						// })
					})
					let fieldTwoDimensionalList = []
					let defaultFieldList = []
					// let flag = this.checkRequired(customColumn)
					// if (!flag) return
					
					// requiredMessage(items,item.name)
					let url = '/api/account-template'
					let time = new Date().Format('yyyy-MM-dd')

					let paramer = {
						extInfo:{
							list:[]
						}, // 角色数据存储 , 主要用于前端回显
						description: '',
						roleIds:this.roleIds,
						name: this.dialogTemplateName,
						groupId:this.groupClassifyId,
						
						// 工单内容字段
						workOrderFieldList: {
							fieldTwoDimensionalList:
								fieldTwoDimensionalList.length > 0
								? [JSON.parse(JSON.stringify(fieldTwoDimensionalList))]
								: []
							},
						// 为系统字段 defaultFieldList
						defaultFieldList: {
							defaultFieldInfos: JSON.parse(JSON.stringify(defaultFieldList))
						},
			
						// 所有字段
						fieldColumns: JSON.parse(JSON.stringify(customColumnLists)),
						version: time,
						layout: {
							type: this.layoutStructure
						},
						defaulted:this.templateInfo.defaulted
					}
					this.roleData.forEach(item => {
						if(this.roleIds.indexOf(item.id) !== -1){
							paramer.extInfo.list.push({
								id:item.id,
								name:item.name,
								isChecked:true
							})
						}
					})
					if (this.$route.query.id) {
						url += '/' + this.$route.query.id
						paramer.id = this.$route.query.id;
						this.$http.put(url, paramer).then(res => {
						if (res.data.code === '0') {
							this.$message({
								message: '模板修改成功',
								duration: 2000,
								type: 'success'
							})
							this.$router.push({
								path: '/accountTemplate'
							})
						}
						})
					} else {
						this.$http.post(url, paramer).then(res => {
						if (res.data.code === '0') {
							this.$message({
								message: '模板发布成功',
								duration: 2000,
								type: 'success'
							})
							this.$router.push({
								path: '/accountTemplate'
							})
						}
						})
					}
				})
				.catch(() => {
				this.$message({
					type: 'info',
					message: '已取消发布'
				})
			})
		},
		editOptions () {
		this.fieldSet.extInfo.options = []
		// let asrc = new RegExp('/</?(p)[^>]*>', 'gi')
		let srcReg = '<p>.*?</p>'
		// let aArr = this.contentConent.match(srcReg)
		let content = document
			.getElementsByClassName('ck-editor')[0]
			.getElementsByTagName('p')
		if (content && content.length > 0) {
			for (let i = 0; i < content.length; i++) {
			if (content[i].innerHTML.search('<br') === -1) {
				if (this.fieldSet.extInfo.options[i]) {
				this.fieldSet.extInfo.options[i].value =
					content[i].innerHTML
				this.fieldSet.extInfo.options[i].label =
					content[i].innerHTML
				} else {
				this.fieldSet.extInfo.options.push({
					value: content[i].innerHTML,
					label: content[i].innerHTML,
					_default: 'false'
				})
				}
			}
			}
		}
		this.dialogVisibleEdit = false
		},
		//自定义字段排序
		resortCustomField (e, items) {
			e.stopPropagation()
			let _this = this
			let idname = 'custom-column-field' + items.id
			let el = document.getElementById(idname)
			var Sortables = new Sortable(el, {
				animation: 300,
				easing: 'cubic-bezier(1, 0, 0, 1)',
				handle: '.my-handle',
				group: 'shared',
				forceFallback: true,
				onUpdate: function (event) { },
				onEnd: function () {
					let arr = Sortables.toArray()
					let testArr = arr.length > 0 ? arr.map(item => { return JSON.parse(item)}) : []
					for (let i = 0; i < testArr.length; i++) {
						testArr[i].columnId = items.id
					}
					items.allFields = testArr
					items.allFields.forEach(field => {
						if (field.id === _this.chooseFieldIndex) {
						_this.fieldSet = field
						}
					})
				},

				onRemove: function () {
					let arr = Sortables.toArray()
					let testArr = arr.length > 0 ? arr.map(item => { return JSON.parse(item) }) : []
					for (let i = 0; i < testArr.length; i++) {
						testArr[i].columnId = items.id
					}
					items.allFields = testArr
				},
				onAdd: function (evt) {
					let arr = Sortables.toArray()
					let testArr = arr.length > 0 ? arr.map(item => { return JSON.parse(item)　}) : []
					for (let i = 0; i < testArr.length; i++) {
						testArr[i].columnId = items.id
					}
					items.allFields = testArr
				}
			})
		},
		//选项排序
		resortOptions () {
			let _this = this
			let el = document.getElementById('radioSort')
			var Sortables = new Sortable(el, {
				animation: 200,
				easing: 'cubic-bezier(1, 0, 0, 1)',
				handle: '.my-handle3',
				onUpdate: function (event) { },
				onEnd: function () {
				let arr = Sortables.toArray()
				let testArr = arr.length > 0 ? arr.map(item => {return JSON.parse(item)}) : []
				_this.fieldSet.extInfo.options = testArr
				}
			})
		},
		addCustomColumn (item) {
			let uid = uuidv4()
				.toString()
				.replaceAll('-', '')
			let fieldId = uuidv4()
				.toString()
				.replaceAll('-', '')
			let that = this
			item.id = uid;
			let info = {
				id: uid,
				// visibleWithoutPermission: true,
				allFields: [],
				field: JSON.parse(JSON.stringify(item))
			}
			that.customColumn.splice(that.chooseColumnIndex + 1, 0, info);
			/*that.customColumn.unshift(info)*/
			this.fieldSet = info;
			this.chooseColumnId = info.id
			/* let info2 = JSON.parse(JSON.stringify(info))
					that.customColumn.unshift(info2)*/
			},
		// customColumnCheck (item, index) {
		// 	console.log(item)
		// 	this.fieldSet = {
		// 		field: item ? item : item.fieldColumn
		// 	}
		// 	this.chooseColumnIndex = index
		// 	this.chooseColumnId = item.id
		// 	this.chooseFieldIndex = index
		// },
		goBack () {
			this.$router.push({
				name:"accountTemplate"
			})
		},
		//检查说明文字中的链接
		checkExplainUrl () {
		var reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])/
		if (!reg.test(this.fieldSet.extInfo.url)) {
			this.$message({
			message: '请输入合法链接地址',
			duration: 2000,
			type: 'warning'
			})
		}
		},
		// 校验是否必填
		checkRequired (fieldColumns) {
			let flag = true
			for (let index = 0; index < fieldColumns.length; index++) {
				const allFields = fieldColumns[index].allFields;
				if ((!fieldColumns[index] && fieldColumns[index].name) || (fieldColumns[index] && fieldColumns[index].name)) {
				for (let j = 0; j < allFields.length; j++) {
					const field = allFields[j];
					let message = requiredMessage(field, fieldColumns[index].name || fieldColumns[index].name)
					if (message !== true) {
					this.$message.error(message)
					return flag = false
					}
				}
				} 
			}
			return flag
		},
		changeLayout (layoutStructure) {
		this.layoutStructure = layoutStructure
		},
		changeDescription(value,type) {
			if(type == 'required') {
				if(!value) {
					this.fieldSet.checkText = false
					this.fieldSet.checkImage = false
					this.fieldSet.checkAttachment = false
				}
			} else  {
				if(value) {
					this.fieldSet.required = true
				}
			}
		},
		//选择报单人默认值
		openSelectClient(){
		this.dialogMemberTree = true;
		this.memberInfo4Client.bindType = this.fieldSet.extInfo.bindType ? JSON.parse(JSON.stringify(this.fieldSet.extInfo.bindType)) : "";
		this.memberInfo4Client.type = this.fieldSet.extInfo.type ? JSON.parse(JSON.stringify(this.fieldSet.extInfo.type)) : "";
		this.memberInfo4Client.memberId = this.fieldSet.defaultValue ? JSON.parse(JSON.stringify(this.fieldSet.defaultValue)) : "";
		this.memberInfo4Client.name = this.fieldSet.extInfo.name ? JSON.parse(JSON.stringify(this.fieldSet.extInfo.name)) : "";
		},
		//选择员工报单人
		onNodeClick (node, type, memberInfo, row) {
		this.staffType = type
		this.memberInfo4Client.corpId = ''
		this.memberInfo4Client.type = ''
		this.memberInfo4Client.bindType = ''
		this.memberInfo4Client.deptId = row && row.parent ? row.parent.data.id : '';
		this.memberInfo4Client.deptName = row && row.parent ? row.parent.data.label ? row.parent.data.label : row.parent.data.name : '';
		if (memberInfo && memberInfo.originData && memberInfo.originData.wechatBotDTO) {
			this.memberInfo4Client.corpId = memberInfo.originData.wechatBotDTO.corpId
			this.memberInfo4Client.type = memberInfo.originData.wechatBotDTO.wechatType
			this.memberInfo4Client.bindType = memberInfo.originData.wechatBotDTO.bindType
		}
		if (type === 'customer' || type === 'updown') {
			this.staffCustomer.name = node.name
			this.staffCustomer.id = node.id
		} else {
			this.staffCustomer.name = node.label
			this.staffCustomer.id = node.id
		}
		},
		//关闭选择报单人
		closeStaff () {
		this.staffCustomer = {
			id: "",
			name: ""
		};
		this.upDownStreamMemberInfo = {
			openId: '',
			memberId: '',
			name: '',
		}
		this.dialogMemberTree = false;
		},
		//保存员工或客户
		preserStaff () {
		let clientName = JSON.parse(JSON.stringify(this.staffCustomer.name))
		if (this.staffType === 'customer') {
			this.clientValue = JSON.parse(JSON.stringify(this.staffCustomer.id))
			this.memberInfo4Client.memberId = ''
			this.memberInfo4Client.name = ''
			this.fieldSet.extInfo.bindType = '';
			this.fieldSet.extInfo.type = '';
			this.fieldSet.extInfo.name = clientName;
			this.fieldSet.defaultValue = this.clientValue;
			this.fieldSet.extInfo.corpId = "";
		}
		else {
			this.clientValue = ''
			this.memberInfo4Client.memberId = JSON.parse(JSON.stringify(this.staffCustomer.id))
			this.memberInfo4Client.name = JSON.parse(JSON.stringify(this.staffCustomer.name))
			this.fieldSet.extInfo.bindType = this.memberInfo4Client.bindType;
			this.fieldSet.extInfo.type = this.memberInfo4Client.type;
			this.fieldSet.extInfo.name = this.memberInfo4Client.name
			this.fieldSet.defaultValue = this.staffCustomer.id;
			this.fieldSet.extInfo.corpId = this.memberInfo4Client.corpId;
		}
		this.dialogMemberTree = false;

		},
		//是否必填针对系统字段时更改field中的required
		changeRequired(val,field){
		if (field.isSystemField){
			field.required = val;
		}
		},
	}
}
</script>
<style lang="less">

.select-label-box-option{
	.isChecked{
		height: 0;
	}
}
</style>
<style scoped lang="less">
@import "./../../../assets/less/accountManageCustom/accountFieldType.less";

.radio-item {
  display: flex;
  justify-content: flex-end;
}
</style>




