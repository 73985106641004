<template>
    <div class="action_webhook">
        <div>
            <div class="webhook-tab">
                <div class="webhook-tab-header">
                    <span @click="activeWebhook = 1"
                        :class="activeWebhook == 1?'webhook-tab-header-cell-ac':'webhook-tab-header-cell'">
                        <span class="tab-num-pre">1</span> {{$t('flowComponent.webhook.step1')}}</span>
                    <span @click="activeWebhook = 2"
                        :class="activeWebhook == 2 ? 'webhook-tab-header-cell-ac' : 'webhook-tab-header-cell'">
                        <span class="tab-num-pre">2</span> {{$t('flowComponent.webhook.step2')}}</span>
                    <span @click="activeWebhook = 3"
                        :class="activeWebhook == 3 ? 'webhook-tab-header-cell-ac' : 'webhook-tab-header-cell'">
                        <span class="tab-num-pre">3</span> {{$t('flowComponent.webhook.step3')}}</span>
                </div>
                <div v-show="activeWebhook == 1" class="top-tip">
                    <div class="top-tip-title">
                      {{$t('flowComponent.webhook.configDes')}}
                    </div>
                  {{$t('flowComponent.webhook.step1Tip')}}
                </div>
                <div v-show="activeWebhook == 2" class="top-tip">
                    <div class="top-tip-title">
                      {{$t('flowComponent.webhook.configDes')}}
                    </div>
                  {{$t('flowComponent.webhook.step2Tip')}}
                </div>
                <div v-show="activeWebhook == 3" class="top-tip">
                    <div class="top-tip-title">
                      {{$t('flowComponent.webhook.configDes')}}
                    </div>
                    {{$t('flowComponent.webhook.step3Tip')}}
                </div>
                <div v-show="activeWebhook == 1">
                    <div class="region-name">
                        <div class="cell-title">
                            1.{{$t('flowComponent.webhook.selectParam')}}
                        </div>
                    </div>
                    <div class="cell-content">
                        <el-select v-model="unit.content.webHookId" size="small" @change="
                            showWebhookDetail(
                                unitIndex,
                                unit.content
                                    .webHookId
                            )
                        " :no-data-text="$t('flowComponent.webhook.noWebhook')" :placeholder="$t('common.selectPlaceholder')">
                            <el-option v-for="item in webhookApiList" :key="item.id" :label="item.label"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="region-name" v-if="
                        unit.content
                            .webHookId != ''
                    ">
                        <div class="cell-title">
                            2.{{$t('flowComponent.webhook.configParam')}}
                        </div>
                    </div>

                    <div class="param" v-if="
                        unit.content
                            .webHookId != ''
                    ">
                        <div class="param-list" v-if="
                            unit.content.params
                                .length > 0
                        ">
                            <div v-for="(paramCell, paramCellIndex) in unit.content.params" :key="paramCell.key"
                                class="param-cell">
                                <div class="first-line background-blue">
                                    <div class="title">
                                      {{$t('flowComponent.webhook.name')}}：
                                    </div>
                                    <div style="width: 136px;flex:none" class="value">
                                        {{ paramCell.key }}
                                    </div>
                                    <div style="width: 56px;flex:none" class="title">
                                      {{$t('flowComponent.webhook.defaultVal')}}：
                                    </div>
                                    <div style="white-space: nowrap;text-overflow: ellipsis;max-width: 380px;"
                                        class="value">
                                        {{ paramCell.defaultValue?paramCell.defaultValue:"--" }}
                                    </div>
                                </div>

                                <div class="first-line">
                                    <div class="title">
                                        {{$t('flowComponent.webhook.access')}}
                                    </div>
                                    <div class="value">
                                        <div class="param-top-content-cell">
                                            <div class="param-title-left">
                                                <el-select @change="paramSetWChange(
                                                    $event,
                                                    unitIndex,
                                                    paramCellIndex
                                                )" v-model="paramCell.type" size="small" placeholder="请选择">
                                                    <el-option v-for="item in [
                                                        {
                                                            label: '关联实体',
                                                            value: 'ENTITY',
                                                        },
                                                        {
                                                            label: 'WebHook返回值',
                                                            value: 'WEB_HOOK_RESPONSE',
                                                        },
                                                    ]" :key="item.value" :label="$t('flowComponent.webhook.' + item.value)" :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div v-if="paramCell.type === 'WEB_HOOK_RESPONSE'" class="param-title-mid">
                                                <el-cascader size="small" v-model="paramCell.webHookShowInfo"
                                                    :options="expBaseCascList" :props="{
                                                        expandTrigger:
                                                            'hover',
                                                        label: 'name',
                                                    }"></el-cascader>
                                            </div>
                                            <div v-if="paramCell.type === 'ENTITY'" class="param-title-mid">
                                                <el-cascader filterable :placeholder="$t('flowComponent.webhook.selectEntity')" size="small" v-if="isStartOrEnd"
                                                    v-model="paramCell.entities" :options="
                                                        entityOptionsBot
                                                    " @change="webhookHandleEntityChange" filterable></el-cascader>
                                                <el-cascader filterable :placeholder="$t('flowComponent.webhook.selectEntity')" v-if="
                                                    !isStartOrEnd
                                                " size="small" v-model="paramCell.entities" :options="entityOptions"
                                                    @change="webhookHandleEntityChange" filterable></el-cascader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="first-line">
                                    <div class="title">
                                      {{$t('flowComponent.webhook.notGet')}}
                                    </div>
                                    <div class="value">
                                        <span class="param-title-left">
                                            <el-select style="width: 150px;" v-if="paramCell.value != ''"
                                                v-model="paramCell.actionNoParam.type" size="small" placeholder="请选择"
                                                @change="
                                                    actionNoParamChange(
                                                        $event,
                                                        unitIndex,
                                                        paramCellIndex
                                                    )
                                                ">
                                                <el-option v-for="item in noGetParamOptions" :key="item.id"
                                                    :label="item.name" :value="
                                                        item.id
                                                    ">
                                                </el-option>
                                            </el-select>
                                            <el-select v-if="
                                                paramCell.value ==
                                                ''
                                            " v-model="paramCell.actionNoParam.type" size="small"
                                                style=" width: 150px;" :placeholder="$t('common.selectPlaceholder')" @change="
                                                    actionNoParamChange(
                                                        $event,
                                                        unitIndex,
                                                        paramCellIndex
                                                    )
                                                ">
                                                <el-option v-for="item in noGetParamOptionsRequire" :key="
                                                    item.id
                                                " :label="item.name" :value="item.id"></el-option>
                                            </el-select>
                                        </span>
                                    </div>
                                </div>
                                <div v-show="!!paramCell.actionNoParam.type && paramCell.actionNoParam.type != 'NONE'" class="sub-config">
                                    <div class="sub-config-title">
                                      {{$t('flowComponent.webhook.comConfig')}}
                                    </div>
                                    <div class="sub-config-content">
                                        <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'QUESTION'
                                        " class="in-aline">
                                            <div class="param-title-last-pre">
                                                <span class="param-title-right">{{$t('flowComponent.webhook.askNumber')}}</span>
                                            </div>
                                            <div class="param-title-last-aft">
                                                <span class="param-title-right">
                                                    <el-select v-model="
                                                        paramCell
                                                            .actionNoParam
                                                            .questionContent
                                                            .questionNum
                                                    " size="small" :placeholder="$t('common.selectPlaceholder')"
                                                        @change="questionNumChange($event, unitIndex, paramCellIndex)">
                                                        <el-option v-for="item in noGetParamQNumber" :key="
                                                            item.id
                                                        " :label="item.name" :value="item.id"></el-option>
                                                    </el-select>
                                                </span>
                                            </div>
                                        </div>

                                        <!-- <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'QUESTION'
                                        " class="cell-title">
                                            反问
                                        </div> -->
                                        <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'QUESTION'
                                        " class="webhook-ask">
                                            <div class="rhetorical-question-input">
                                                <div v-for="(textCell, textCellInd) in paramCell.actionNoParam.questionContent.questions"
                                                    :key="textCellInd" class="rhetorical-question-input-cell">
                                                    <el-input :autosize="{
                                                        minRows: 2,
                                                        maxRows: 20,
                                                    }" type="textarea"
                                                        v-model="unit.content.params[paramCellIndex].actionNoParam.questionContent.questions[textCellInd]"
                                                        maxlength="2000" :placeholder="$t('flowComponent。textAnswer')" @input="webhookQQtextCellChange($event, unitIndex, paramCellIndex, textCellInd)
                                                        "></el-input>
                                                    <span v-if="
                                                        textCellInd ==
                                                        0
                                                    " class="answer-text-cell-handle-icon-add"
                                                        @click="addWebhookQText(unitIndex, paramCellIndex, textCellInd)">
                                                        <i class="el-icon-plus"></i> </span>
                                                    <span v-if="
                                                        textCellInd !==
                                                        0
                                                    " class="answer-text-cell-handle-icon-delete"
                                                        @click="deleteWebhookQText(unitIndex, paramCellIndex, textCellInd)">
                                                        <i class="el-icon-close"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'RADIO'
                                        " class="cell-title">
                                            点选
                                        </div> -->
                                        <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'RADIO'
                                        " class="respose-text-content tal">
                                            <div class="cell-content">
                                                <el-input type="textarea" autosize :placeholder="$t('flowComponent。answerRadioPla')"
                                                    @change="
                                                        webhookactionNoParamDescriptionChange(
                                                            $event,
                                                            unitIndex,
                                                            paramCellIndex
                                                        )
                                                    " v-model="paramCell.actionNoParam.radioContent.description">
                                                </el-input>
                                            </div>
                                            <div class="cell-content  answer-radio-box">
                                                <div :id="`custom-column-awo${unitIndex}_${paramCellIndex}`"
                                                    class="btn-list-box">
                                                    <div :key="radioTextIndex"
                                                        v-for="(
                                                        tag, radioTextIndex
                                                    ) in unit.content.params[paramCellIndex].actionNoParam.radioContent.options"
                                                        class="btn-list-cell">
                                                        <span class="
                                                            btn-list-cell-text
                                                        ">{{ tag.name }}</span>
                                                        <span class="
                                                            sortIcon
                                                            my-handle-btn
                                                            iconfont
                                                            guoran-a-16-10
                                                        " @mouseenter="
                                                            sortClickBtn(
                                                                $event,
                                                                tag,
                                                                unitIndex,
                                                                paramCellIndex
                                                            )
                                                        ">
                                                        </span>
                                                        <span @click="
                                                            deleteAnswerRadio(
                                                                unitIndex,
                                                                paramCellIndex,
                                                                radioTextIndex
                                                            )
                                                        " class="
                                                            my-handle-close
                                                            iconfont
                                                            guoran-a-16-09
                                                        ">
                                                        </span>
                                                    </div>
                                                    <span @click="editRadioBtnList(unitIndex,
                                                    paramCellIndex)" class="cell-title-setting-inner"><i
                                                            class="el-icon-setting"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'GOTO'
                                        " class="cell-title">
                                            跳转
                                        </div> -->
                                        <div v-if="
                                            paramCell
                                                .actionNoParam
                                                .type ==
                                            'GOTO'
                                        " class="jump-cell">
                                            <el-select style="width: 150px;"
                                                v-model="paramCell.actionNoParam.gotoContent.type" @change="
                                                    webhookJumpTypeValueChange(
                                                        $event,
                                                        unitIndex,
                                                        paramCellIndex
                                                    )
                                                " size="small" :placeholder="$t('common.selectPlaceholder')">
                                                <el-option v-for="item in jumpTypeOptions" :key="
                                                    item.value
                                                " :label="$t('common.' + item.value)" :value="item.value"></el-option>
                                            </el-select>
                                            <div class="right-select">
                                                <!-- <el-select v-show="
                                                    paramCell
                                                        .actionNoParam
                                                        .gotoContent
                                                        .type ===
                                                    'NODE'
                                                " v-model="paramCell.actionNoParam.gotoContent.value"
                                                    @change="webhookJumpNodeSelectChange($event, unitIndex, paramCellIndex)"
                                                    :popper-append-to-body="false" filterable size="small"
                                                    placeholder="请输入选择名称">
                                                    <el-option v-for="item in nodeOptions" :popper-class="
                                                        'pop_my' +
                                                        item.classPop +
                                                        item.parentNum
                                                    " :key="item.id" :label="item.name" :value="item.id">
                                                    </el-option>
                                                </el-select> -->
                                                <SelectAddnode
                                                    v-if="paramCell.actionNoParam.gotoContent.type === 'NODE'"
                                                    :nodeOptions="nodeOptions"
                                                    :nodeId="paramCell.actionNoParam.gotoContent.value"
                                                    :defaultParentNodeId="nodeId"
                                                    :nodeSelectARef="`actionWebhookNoparamAddnode_${com_index}_${paramCellIndex}`"
                                                    :intentId="intentId"
                                                    @getNodeOptions="getNodeOptions"
                                                    @setAddNodeId="setAddNodeIdNoParam"
                                                    :index="paramCellIndex"
                                                ></SelectAddnode>
                                                <el-cascader
                                                    v-if="!isBotLink && paramCell.actionNoParam.gotoContent.type === 'INTENT'"
                                                    @change="webhookJumpIntentSelectChange($event, index, paramCellIndex)"
                                                    v-model="paramCell.actionNoParam.gotoContent.value" size="small"
                                                    :options="intentTree4Radio"
                                                    :props="{ emitPath: false, label: 'name', value: 'id', }">
                                                </el-cascader>
                                                <template v-if="isBotLink && paramCell.actionNoParam.gotoContent.type === 'INTENT'">
                                                    <el-popover
                                                        :ref="'webhookProver' + index + paramCellIndex"
                                                        placement="bottom"
                                                        width="300"
                                                        trigger="click"
                                                        popper-class="set-welcome-message-search-intent-poprver"
                                                        :visible-arrow="false"
                                                        @show="showPover2(paramCell.actionNoParam.gotoContent)">
                                                        <el-input
                                                            class="search-intent-input"
                                                            size="small"
                                                            :placeholder="$t('botIntent.searchOrSelectIntent')"
                                                            v-model="paramCell.actionNoParam.gotoContent.valueText"
                                                            slot="reference"
                                                            @input="inputFn2($event,paramCell.actionNoParam.gotoContent.valueText)">
                                                            <i slot="suffix" class="el-icon-arrow-down"></i>
                                                        </el-input>
                                                        <el-tree
                                                            class="choose-intent-tree"
                                                            style="width:300px;height:300px;overflow-x:hidden;overflow-y:auto;"
                                                            :data="intentTree4Radio"
                                                            :props="{
                                                                children: 'children',
                                                                label: 'name',
                                                                id:'id',
                                                                isLeaf: 'isLeafNode',
                                                            }"
                                                            :filter-node-method="filterNode2"
                                                            node-key="id"
                                                            ref="tree2">
                                                            <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick2(data,node,paramCell.actionNoParam.gotoContent,index,paramCellIndex)">
                                                                <span class="custom-tree-node-left">
                                                                    <span :class="['data-label']">{{ data.name }}</span>
                                                                </span>
                                                                <i class="el-icon-check" v-if="data.checked"></i>
                                                            </span>
                                                        </el-tree>
                                                    </el-popover>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activeWebhook == 2">
                    <div v-if="
                        unit.content
                            .webHookId != '' &&
                        unit.content.response &&
                        unit.content.response
                            .length
                    " class="split-line"></div>
                    <div v-if="
                        unit.content
                            .webHookId != '' &&
                        unit.content.response &&
                        unit.content.response
                            .length
                    " style="margin-top: 16px;margin-bottom: 16px;">
                        <div class="jump-cell">
                            <el-select v-model="
                                unit.content
                                    .failedAction
                                    .scope
                            " @change="webhookfailedActionTypeValueChange($event, index)" size="small"
                                :placeholder="$t('common.selectPlaceholder')">
                                <el-option v-for="item in jumpTypeOptions" :key="item.value" :label="$t('common.' + item.value)"
                                    :value="item.value"></el-option>
                            </el-select>
                            <div class="right-select">
                                <!-- <el-select v-show="
                                    unit.content
                                        .failedAction
                                        .scope ===
                                    'NODE'
                                " v-model="unit.content.failedAction.value"
                                    @change="webhookfailedActionNodeSelectChange($event, index)"
                                    :popper-append-to-body="false" filterable size="small" placeholder="请输入选择名称">
                                    <el-option v-for="item in nodeOptions" :popper-class="
                                        'pop_my' +
                                        item.classPop +
                                        item.parentNum
                                    " :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select> -->
                                <SelectAddnode
                                    :isBotLink="isBotLink"
                                    v-if="unit.content.failedAction.scope === 'NODE'"
                                    :nodeOptions="nodeOptions"
                                    :nodeId="unit.content.failedAction.value"
                                    :defaultParentNodeId="nodeId"
                                    :nodeSelectARef="`actionWebhookfailedActionAddnode_${com_index}`"
                                    :intentId="intentId"
                                    @getNodeOptions="getNodeOptions"
                                    @setAddNodeId="setAddNodeIdFailedAction"
                                    :index="com_index"
                                ></SelectAddnode>
                                <el-cascader
                                    v-if="!isBotLink"
                                    filterable
                                    v-show="
                                        unit.content
                                            .failedAction
                                            .scope ===
                                        'INTENT'
                                    " v-model="unit.content.failedAction.value" size="small" :options="intentTree4Radio"
                                    :props="{ emitPath: false, label: 'name', value: 'id', }">
                                </el-cascader>
                                <template v-else>
                                    <el-popover
                                        :ref="'webhookProver' + index"
                                        placement="bottom"
                                        width="300"
                                        trigger="click"
                                        popper-class="set-welcome-message-search-intent-poprver"
                                        :visible-arrow="false"
                                        @show="showPover(unit.content.failedAction)">
                                        <el-input
                                            class="search-intent-input"
                                            size="small"
                                            :placeholder="$t('botIntent.searchOrSelectIntent')"
                                            v-model="unit.content.failedAction.valueText"
                                            slot="reference"
                                            @input="inputFn($event,unit.content.failedAction.valueText)">
                                            <i slot="suffix" class="el-icon-arrow-down"></i>
                                        </el-input>
                                        <el-tree
                                            class="choose-intent-tree"
                                            style="width:300px;height:300px;overflow-x:hidden;overflow-y:auto;"
                                            :data="intentTree4Radio"
                                            :props="{
                                                children: 'children',
                                                label: 'name',
                                                id:'id',
                                                isLeaf: 'isLeafNode',
                                            }"
                                            :filter-node-method="filterNode"
                                            node-key="id"
                                            ref="tree">
                                            <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node,unit.content.failedAction,index)">
                                                <span class="custom-tree-node-left">
                                                    <span :class="['data-label']">{{ data.name }}</span>
                                                </span>
                                                <i class="el-icon-check" v-if="data.checked"></i>
                                            </span>
                                        </el-tree>
                                    </el-popover>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activeWebhook == 3">
                    <div v-if="
                        unit.content
                            .webHookId != '' &&
                        unit.content.response &&
                        unit.content.response
                            .length
                    " class="split-line"></div>
                    <div v-if="
                        unit.content
                            .webHookId != '' &&
                        unit.content.response &&
                        unit.content.response
                            .length
                    " class="webhook-response-list">
                        <div class="webhook-response-list-cell"
                            v-for="(respCell, respCellIndex ) in unit.content.response" key="respCellIndex"><span
                                class="webhook-response-list-cell-pre">{{$t('flowComponent.webhook.returnValue')}}：</span><span
                                class="webhook-response-list-cell-content">{{
                                        respCell.name
                                }}</span><span v-if="respCell.multivalued">{{$t('flowComponent.webhook.multivalued')}}</span>
                        </div>
                    </div>
                    <div v-if="unit.content.webHookId != '' && unit.content.response && unit.content.response.length"
                        class="split-line"></div>
                </div>
            </div>

        </div>
        <popup v-if="webhookAnswerConfigListVisible" :popWidth="800" @closeEvent="webhookAnswerConfigListVisible = false">
            <div slot="popup-name">{{$t('flowComponent.webhook.radioBtnTitle')}}</div>
            <div slot="popup-tip">{{$t('flowComponent.webhook.radioBtnTip')}}</div>
            <div slot="popup-con">
                <div id="radio-fonfig">
                    <div class="radio-btn-list-header">
                        <span class="radio-btn-list-header-name">{{$t('flowComponent.webhook.radioName')}}</span>
                        <span class="radio-btn-list-header-action">{{$t('flowComponent.webhook.radioClick')}}</span>
                    </div>
                    <div id="activelist___89" class="radio-btn-list">
                        <div v-for="(rCell, rCellIndex) in activeBtnList" :key="rCellIndex" class="radio-btn-list-cell">
                            <span class="select-cell-move icon-handle-radio-btn my-handle-btn-active">
                                <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
                            </span>
                            <div class="select-cell select-cell-name">
                                <el-input size="small" v-model="rCell.name" maxlength="40" :placeholder="$t('flowComponent.webhook.radioNamePla')">
                                </el-input>
                            </div>
                            <div class="select-cell select-cell-value">
                                <el-select style="width: 170px;" value="向机器人发送内容" disabled size="small" :placeholder="$t('common.selectPlaceholder')">
                                    <el-option v-for="item in [
                                        {
                                            label: '向机器人发送内容',
                                            value: '向机器人发送内容',
                                        },
                                    ]" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                                <el-input size="small" placeholder="请输入发送内容" v-model="rCell.content"></el-input>
                            </div>
                            <span v-if="rCellIndex === 0" @click="addRCell" class="answer-text-cell-handle-icon-add">
                                <i class="el-icon-plus"></i>
                            </span>
                            <span v-else @click="deleteRCell(rCellIndex)" class="answer-text-cell-handle-icon-delete">
                                <i class="el-icon-close"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelSaveAnswerRadioConfig" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="webhookConfirmSaveAnswerRadioConfig" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "../../../../components/popupNew.vue";
import SelectAddnode from "./../cell/SelectAddnode.vue";
import Sortable from "sortablejs";
export default {
    components: { SelectAddnode, popup },
    props: [
        "unit",
        "unitIndex",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "entityOptionsBot",
        "isStartOrEnd",
        "entityOptions",
        "webhookApiList",
        "expBaseCascList",
        "actionList",
        "intentId",
        "nodeId",
        "com_index",
        "isBotLink"
    ],
    data() {
        return {
            activeWebhook: 1,
            webhookAnswerRadioConfigVisible: false,
            webhookActiveRadionConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
            },
            noGetParamOptions: [
                { name: "执行反问", id: "QUESTION" },
                { name: "执行点选", id: "RADIO" },
                { name: "跳转", id: "GOTO" },
                { name: "不获取", id: "NONE" },
            ],
            noGetParamOptionsRequire: [
                { name: "执行反问", id: "QUESTION" },
                { name: "执行点选", id: "RADIO" },
                { name: "跳转", id: "GOTO" },
            ],
            noGetParamQNumber: [
                { name: 1, id: 1 },
                { name: 2, id: 2 },
                { name: 3, id: 3 },
                { name: 4, id: 4 },
                { name: 5, id: 5 },
                { name: "直到检测到实体", id: -1 },
            ],
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            webhookAnswerConfigListVisible: false,
            activeBtnList: [],
            activeBtnListIndex: 0,
        };
    },
    mounted(){
        if(this.isBotLink){
            this.jumpTypeOptions = [{
                value: "INTENT",
                label: "跳转到意图",
            }]

        }
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeIdNoParam(nodeId, index) {
            this.$set(this.unit.content.params[index].actionNoParam.gotoContent, "value", nodeId);
        },
        setAddNodeIdFailedAction(nodeId, index){
            this.$set(this.unit.content.failedAction, "value", nodeId);
        },
        sortActiveListClickBtn() {
            // e.stopPropagation();
            let _this = this;
            let idname = "activelist___89";
            let box = document.getElementById(idname);
            console.log(box);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn-active",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeBtnList.splice(
                        oldIndex,
                        1
                    );
                    _this.activeBtnList.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                },
            });
            this.activeBtnList = [..._this.activeBtnList];
        },
        deleteRCell(rCellIndex) {
            this.activeBtnList.splice(rCellIndex, 1);
        },
        addRCell() {
            this.activeBtnList.push({
                name: "",
                onclick: "SEND_MSG_TO_BOT",
                content: "",
            });
        },
        editRadioBtnList(uniIndex, paramCellIndex) {
            this.activeBtnList = JSON.parse(JSON.stringify(this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options));
            if (this.activeBtnList.length === 0) {
                this.activeBtnList = [...[
                    {
                        name: "",
                        onclick: "SEND_MSG_TO_BOT",
                        content: "",
                    }
                ]]
            }
            this.activeBtnListIndex = paramCellIndex;
            this.webhookAnswerConfigListVisible = true;
            this.$nextTick(() => {
                this.sortActiveListClickBtn();
            })
        },
        // 删除点选
        deleteAnswerRadio(index, paramCellIndex, radioTextIndex) {
            this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options.splice(radioTextIndex, 1);
            this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options = [...this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options];
        },
        sortClickBtn(e, items, index, paramCellIndex) {
            e.stopPropagation();
            let _this = this;
            let idname = "custom-column-awo" + index + '_' + paramCellIndex;
            let box = document.getElementById(idname);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options.splice(
                        oldIndex,
                        1
                    );
                    _this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                },
            });
            this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options = [..._this.unit.content.params[paramCellIndex].actionNoParam.radioContent.options];
        },
        // 取消保存点选
        cancelSaveAnswerRadioConfig() {
            this.webhookAnswerConfigListVisible = false;
        },
        showWebhookDetail(index, id) {
            this.FetchGet(this.requestUrl.webhook.webhookDetail + id).then(
                (res) => {
                    if (res.code === "0") {
                        this.unit.content.params =
                            res.data.setting.params;
                        this.unit.content.params.forEach(
                            (item) => {
                                item.description =
                                    item.description == null
                                        ? ""
                                        : item.description;
                                item.entityId =
                                    item.entityId == null ? "" : item.entityId;
                                item.defaultValue = item.value;
                                item.entities = item.entityName;
                                item.webHookResponseRequire = item.required;
                                item.type = "ENTITY";
                                this.$set(item, "actionNoParam", {
                                    type: "",
                                    radioContent: {
                                        description: "",
                                        options: [
                                            // {
                                            //     name: "点击编辑点选",
                                            //     onclick: "SEND_MSG_TO_BOT",
                                            //     content: "",
                                            // },
                                        ],
                                    },
                                    questionContent: {
                                        questions: [""],
                                        questionNum: -1,
                                    },
                                    gotoContent: {
                                        type: this.isBotLink ? 'INTENT' : "NODE",
                                        value: "",
                                    },
                                    content: {},
                                });
                            }
                        );
                        this.unit.content.response =
                            res.data.setting.response.config;
                        this.unit.content.responseCollapse = false;
                        this.unit.content.failedActionCollapse = false;
                        // this.actionList = [...this.actionList];
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        paramSetWChange(value, index, paramCellIndex) {
            this.unit.content.params[paramCellIndex].type = value;
            // this.actionList = [...this.actionList];
        },
        webhookHandleEntityChange(value) {
            console.log(value);
        },
        actionNoParamChange(value, index, paramCellIndex) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.type = value;
            // this.actionList = [...this.actionList];
            console.log( this.jumpTypeOptions,11111);
            console.log(this.unit.content.params);
            this.unit.content.params[paramCellIndex].actionNoParam.gotoContent.type = 'INTENT'
            // console.log(paramCell.actionNoParam.gotoContent.type);
        },
        questionNumChange(value, index, paramCellIndex) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questionNum = value;
            // this.actionList = [...this.actionList];
        },
        addWebhookQText(index, paramCellIndex, textCellInd) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions.push("");
            // this.actionList = [...this.actionList];
        },
        deleteWebhookQText(index, paramCellIndex, textCellInd) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions.splice(textCellInd, 1);
            // this.actionList = [...this.actionList];
        },
        webhookQQtextCellChange(value, index, paramCellIndex, textCellInd) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.questionContent.questions[textCellInd] = value;
            // this.actionList = [...this.actionList];
        },
        webhookactionNoParamDescriptionChange(e, index, indexCell) {
            console.log(e, index, indexCell);
        },
        // 点选类型配置
        // 添加点选
        webhookAddAnswerRadio(index, paramCellIndex) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.radioContent.options.push({
                name: "点击编辑点选",
                onclick: "SEND_MSG_TO_BOT",
                content: "",
            });

            // this.actionList = [...this.actionList];
        },
        // 删除点选
        webhookDeleteAnswerRadio(index, paramCellIndex, radioTextIndex) {
            this.unit.content.params[
                paramCellIndex
            ].actionNoParam.radioContent.options.splice(radioTextIndex, 1);
            // this.actionList = [...tampArr];
        },
        // 保存编辑点选
        webhookConfirmSaveAnswerRadioConfig() {
            let emptyName = false;
            let emptyContent = false;
            this.activeBtnList.forEach(item => {
                if (item.name == "") {
                    emptyName = true;
                }
                if (item.content == "") {
                    emptyContent = true;
                }
            })
            if (emptyName) {
                this.$message.error("点选按钮名称不能为空！");
            } else {
                if (emptyContent) {
                    this.$message.error("发送内容不能为空！");
                } else {
                    this.unit.content.params[this.activeBtnListIndex].actionNoParam.radioContent.options = [...this.activeBtnList];
                    this.webhookAnswerConfigListVisible = false;
                }
            }
        },
        // 取消保存点选
        webhookCancelSaveAnswerRadioConfig() {
            this.webhookAnswerRadioConfigVisible = false;
            this.webhookActiveRadionConfigObj = {
                name: "点击编辑点选",
                onclick: "SEND_MSG_TO_BOT",
                content: "",
            };
        },
        // 跳转类型切换
        webhookJumpTypeValueChange(value, index, paramCellIndex) {
            console.log(915);
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.type = value;
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = "";
            this.actionList = [...this.actionList];
        },
        webhookJumpNodeSelectChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = value;
            // this.actionList = [...this.actionList];
        },
        webhookJumpIntentSelectChange(value, index, paramCellIndex) {
            this.actionList[index].content.params[
                paramCellIndex
            ].actionNoParam.gotoContent.value = value;
            this.actionList = [...this.actionList];
        },
        // webhook
        // 失败跳转类型切换
        webhookfailedActionTypeValueChange(value, index) {
            this.actionList[index].content.failedAction.scope = value;
            this.actionList[index].content.failedAction.value = "";
            this.actionList = [...this.actionList];
        },
        webhookfailedActionNodeSelectChange(value, index) {
            this.actionList[index].content.failedAction.value = value;
            this.actionList = [...this.actionList];
        },
        webhookfailedActionIntentSelectChange(value, index) {
            this.actionList[index].content.failedAction.value = value;
            this.actionList = [...this.actionList];
        },
        inputFn(val,item){
            this.$refs.tree[0].filter(val);
            this.$forceUpdate()
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data,node,item,index){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item,'valueText',data.name);
            this.$set(item,'value',data.id)
            this.$set(data,'checked',true)
            this.$refs['webhookProver' + index][0].doClose()
        },
        showPover(data){
            let list = JSON.parse(JSON.stringify(this.intentTree4Radio))
            list.forEach(botItem => {
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(data.value  == intentItem.id){
                                intentItem.checked = true;
                            }
                        })
                    })
                })
            })
            this.intentTree4Radio =  JSON.parse(JSON.stringify(list))
        },

        inputFn2(val,item){
            this.$refs.tree2[0].filter(val);
            this.$forceUpdate()
        },
        filterNode2(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick2(data,node,item,index,paramCellIndex){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item,'valueText',data.name);
            this.$set(item,'value',data.id)
            this.$set(data,'checked',true)
            this.$refs['webhookProver' + index + paramCellIndex][0].doClose()
        },
        showPover2(data){
            let list = JSON.parse(JSON.stringify(this.intentTree4Radio))
            list.forEach(botItem => {
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(data.value  == intentItem.id){
                                intentItem.checked = true;
                            }
                        })
                    })
                })
            })
            this.intentTree4Radio =  JSON.parse(JSON.stringify(list))
        },
    },
};
</script>
<style lang="less" scoped>
#radio-fonfig {
    border-radius: 5px;

    .radio-btn-list-header {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 8px;

        .radio-btn-list-header-name {
            margin-left: 22px;
            width: 170px;
            flex: none;
        }

        .radio-btn-list-header-action {
            flex: auto;
        }
    }

    .radio-btn-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;

        .select-cell-move {
            color: #366aff;
            font-size: 20px;
            font-weight: 500;
            margin-right: 8px;
        }

        .select-cell-name {
            width: 155px;
            flex: none;
        }

        .answer-text-cell-handle-icon-delete {
            display: inline-block;
            margin-left: 14px;
            width: 27px;
            height: 27px;
            line-height: 27px;
            background: #ffffff;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            color: #366aff;
            cursor: pointer;

            i {
                font-size: 14px;
                font-weight: 800;
            }
        }

        .answer-text-cell-handle-icon-add {
            display: inline-block;
            color: white;
            width: 29px;
            height: 29px;
            line-height: 29px;
            background: #366aff;
            border-radius: 5px;
            margin-left: 14px;
            cursor: pointer;

            i {
                font-size: 14px;
                font-weight: 800;
            }
        }

        .select-cell-value {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 15px;



            .select-type {
                width: 120px;
            }

            /deep/.inline-input {
                width: 200px;
                padding-right: 0;
            }

            /deep/.el-cascader {
                width: 200px;
                padding-right: 0;
            }

            /deep/.el-input__inner {
                border: none;
                padding-right: 0;
            }

            /deep/.el-input__suffix {
                display: none;
            }
        }
    }
}

.action_webhook {
    .btn-list-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .cell-title-setting-inner {
            cursor: pointer;
            margin-top: 10px;
            display: inline-block;
            height: 28px;
            width: 28px;
            border-radius: 50%;
            border: solid 1px #366AFF;
            text-align: center;
            line-height: 30px;

            i {
                font-size: 14px;
                color: #366AFF;
                font-weight: 700;
            }
        }

        .btn-list-cell:hover {
            .my-handle-btn {
                display: inline-block;
            }

            .my-handle-close {
                display: inline-block;
            }
        }

        .btn-list-cell {
            height: 28px;
            line-height: 28px;
            border-radius: 14px;
            padding: 0;
            margin-right: 6px;
            margin-top: 10px;
            border: solid 1px #366aff;
            color: #366aff;
            cursor: pointer;
            position: relative;
            background-color: white;

            .btn-list-cell-text {
                display: inline-block;
                height: 28px;
                line-height: 28px;
                padding: 0 16px;
            }

            .my-handle-btn {
                display: none;
                position: absolute;
                top: -8px;
                right: 26px;
                height: 16px;
                line-height: 16px;
                width: 16px;
                border-radius: 8px;
                background-color: white;
            }

            .my-handle-close {
                display: none;
                position: absolute;
                top: -8px;
                right: 3px;
                height: 16px;
                line-height: 16px;
                width: 16px;
                border-radius: 8px;
                background-color: white;
            }
        }
    }

    .top-tip {
        background-color: #fff8f4;
        border-radius: 5px;
        padding: 8px;
        text-align: left;
        color: #ff9555;
        font-size: 12px;
        margin: 16px 0 0 0;

        .top-tip-title {
            margin-bottom: 6px;
            font-size: 13px;
            font-weight: 700;
        }
    }

    .webhook-tab {
        .webhook-tab-header {
            display: flex;
            justify-content: space-around;

            .webhook-tab-header-cell-ac {
                height: 40px;
                //line-height: 40px;
                flex: 1;
                color: #366AFF;
                font-weight: 500;
                border-bottom: solid 2px #366AFF;
                cursor: pointer;


                .tab-num-pre {
                    display: inline-block;
                    margin-right: 3px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    background: #366AFF;
                    border-radius: 50%;
                    color: white;
                    font-size: 11px;
                    text-align: center;
                }
            }

            .webhook-tab-header-cell {
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                flex: 1;
                color: #B2C2D8;
                font-weight: 500;
                border-bottom: solid 2px #B2C2D8;

                .tab-num-pre {
                    display: inline-block;
                    margin-right: 3px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    background: #B2C2D8;
                    border-radius: 50%;
                    color: white;
                    font-size: 11px;
                    text-align: center;
                }
            }
        }
    }

    .webhook-ask {
        .rhetorical-question-input {
            .rhetorical-question-input-cell {
                margin-bottom: 6px;
                display: flex;
                align-items: center;

                .answer-text-cell-handle-icon-delete {
                    display: inline-block;
                    margin-left: 14px;
                    width: 27px;
                    height: 27px;
                    line-height: 27px;
                    background: #ffffff;
                    border: 1px solid #e0e6f7;
                    border-radius: 5px;
                    color: #366aff;
                    cursor: pointer;

                    i {
                        font-size: 14px;
                        font-weight: 800;
                    }
                }

                .answer-text-cell-handle-icon-add {
                    display: inline-block;
                    color: white;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    background: #366aff;
                    border-radius: 5px;
                    margin-left: 14px;
                    cursor: pointer;

                    i {
                        font-size: 14px;
                        font-weight: 800;
                    }
                }
            }
        }
    }



    .region-name {
        padding: 5px 0px;
        margin: 2px 0;
        text-align: left;
    }

    .region-content {
        padding: 5px 15px;
    }

    .el-tabs--border-card>.el-tabs__content {
        //padding: 0px !important;
    }

    .cell-content {
        .el-select {
            width: 100% !important;
        }

        .el-select--small {
            width: 100% !important;
        }
    }

    .split-line {
        display: flex;
        justify-content: space-around;
        margin: 12px 0;

        .inner-split-line {
            flex: auto;
            border-top: dashed 1px gainsboro;
            margin-top: 5px;
        }

        .split-line-content {
            color: orange;
            width: 200px;
            text-align: center;
            font-size: 12px;
            height: 12px;
            line-height: 12px;

            .show-all {
                color: black;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .cursor-p {
            cursor: pointer;
        }
    }

    .webhook-response-list {
        margin-top: 16px;
        margin-bottom: 16px;
        color: #606266;

        .webhook-response-list-cell {
            margin-bottom: 8px;
            text-align: left;

            .webhook-response-list-cell-pre {
                line-height: 24px;
            }

            .webhook-response-list-cell-content {
                line-height: 24px;
            }
        }
    }

    .param {
        margin: 6px 0;

        .param-cell {
            margin-bottom: 8px;
            margin-top: 4px;
            border-left: none;
            border-radius: 5px;

            .first-line {
                display: flex;
                flex-direction: row;
                justify-content: start;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #E0E6F7;
                background: #FBFCFD;

                .title {
                    padding-left: 16px;
                    flex: none;
                    width: 70px;
                    //background-color: #dddddd;
                    color: #000000;
                    font-weight: 600;
                    text-align: left;
                }

                .value {
                    flex: auto;
                    text-align: left !important;
                    padding: 0 8px;
                    overflow: hidden;
                }
            }

            .background-blue {
                background-color: #F5F7FB;
            }

            .sub-config {
                background: #FBFCFD;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .sub-config-title {
                    color: #000000;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    width: 86px;
                    flex: none;
                    justify-content: center;
                }

                .sub-config-content {
                    padding: 8px 16px 8px 8px;
                    // width: 70%;
                    flex: auto;
                    // .el-input {
                    //     width: 100px;
                    // }

                    .in-aline {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .param-title-last-pre {
                            width: 150px;
                            flex: none;
                            margin-right: 8px;
                        }

                        .param-title-last-aft {
                            flex: auto;

                            .el-select {
                                width: 100%;
                            }
                        }
                    }
                }
            }

        }

        .param-title {
            display: flex;
            justify-content: space-around;

            .param-title-left {
                flex: 1;
                text-align: left;
            }

            .param-title-right {
                flex: 1;
                text-align: right;

                .el-cascader--small {
                    width: 100%;
                }
            }
        }

        .param-title-last {
            margin: 0px 0 0px;
            display: flex;
            justify-content: flex-start;

            .param-title-left {
                flex: none;
                width: 150px;
                text-align: left;
            }

            .param-title-mid {
                margin: 0 8px;
                flex: auto;
                text-align: left;
            }

            .param-title-right {
                flex: none;
                width: 150px;
                text-align: left;
            }

            .param-title-right {
                flex: none;
                width: 150px;
                text-align: left;
            }
        }

        .param-top-content-cell {
            display: flex;
            justify-content: space-around;

            .param-title-left {
                flex: none;
                width: 150px;
                text-align: left;
            }

            .param-title-mid {
                margin: 0 8px;
                flex: auto;
                text-align: left;

                .el-select {
                    width: 100% !important;
                }

                .el-select--small {
                    width: 100% !important;
                }

                .el-cascader--small {
                    width: 100%;
                }
            }

            .param-title-right {
                flex: none;
                width: 150px;
                text-align: left;

                .el-select {
                    width: 100% !important;
                }

                .el-select--small {
                    width: 100% !important;
                }

                .el-cascader--small {
                    width: 100%;
                }
            }

            .param-title-right {
                flex: none;
                width: 150px;
                text-align: left;

                .el-select {
                    width: 100% !important;
                }

                .el-select--small {
                    width: 100% !important;
                }

                .el-cascader--small {
                    width: 100%;
                }
            }

            .handle-icon {
                width: 40px;
                flex: none;
                margin-left: 8px;
                font-size: 24px;
                margin-top: 4px;
            }

            .handle-icon-delete {
                color: red;
            }
        }

        .add-param {
            color: #366AFF;
            text-align: center;
            margin: 8px 0;
            font-weight: bolder;
        }
    }
}
</style>
