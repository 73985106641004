<template>
    <el-dialog
        id="import-dia"
        title="批量导入"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="460px"
    >
        <div class="import-dia-content">
            <h4>说明：导入Excel表格行数上限为100000行，文件大小上限为5Mb。</h4>
            <br />
            <div class="des-upload">1. 根据模板要求填写模板文件</div>
            <div class="des-upload">
                2. 如企业组织架构未完善，请先完善组织架构信息
            </div>
            <div class="des-upload">3. 选择文件，开始导入</div>
            <br />
            <br />
            <el-upload
                class="el-upload-box"
                ref="upload"
                :action="importFileUrl"
                :multiple="false"
                :limit="1"
                :on-preview="handlePreview"
                :on-change="onChangeUpload"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :auto-upload="false"
            >
                <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                >

                <div slot="tip" class="el-upload__tip">
                    还没有模板？点击下载
                    <el-button @click="downLoadModle" type="text">
                        《批量导入员工模板》
                    </el-button>
                </div>
            </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="close">取 消</el-button>
            <el-button
                size="small"
                type="primary"
                icon="el-icon-upload"
                @click="submitUpload"
                >开始上传</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
export default {
    name: "import-dia",
    props: ["dialogVisible"],
    data() {
        return {
            name: "import-dia",
            fileList: [],
            importFileUrl: location.origin + "/api/member/import",
        };
    },
    methods: {
        submitUpload() {
            console.log(this.fileList.length)
            this.$refs.upload.submit();
        },
        onChangeUpload(file, fileList) {
            if (file.size > 5 * 1024 * 1000) {
                this.$message.error("excel文件最大为5MB！");
                this.fileList = [];
                return false;
            }
            if (
                file.name.split(".").pop() != "xls" &&
                file.name.split(".").pop() != "xlsx"
            ) {
                this.$message.error("请上传excel文件！");
                this.fileList = [];
                return false;
            }
        },
        onSuccess(response, file, fileList) {
            console.log(response);
            if (response.code === "0") {
                this.$message.success("上传成功！");
                this.$router.go(0);
                this.fileList = [];
                this.$emit("uploadSuccess");
            } else {
                this.fileList = [];
                this.$message.error(response.message);
            }
            
        },
        beforeUpload(file) {
            console.log(file);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        close() {
            this.$emit("uploadSuccess");
        },
        downLoadModle() {
            window.location.href = "https://static.guoranbot.com/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%91%98%E5%B7%A5%E6%A8%A1%E6%9D%BF.xlsx";
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
#import-dia {
    color: tomato;
    .import-dia-content {
        .des-upload {
            text-align: left;
        }
        .el-upload-box {
            text-align: left;
        }
    }
}
</style>