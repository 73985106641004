<template>
    <div class="set-leader">
        <popup @closeEvent="closeEvent" v-if="setLeaderVisible">
            <div slot="popup-name" class="popup-name">{{$t('setLeader.title')}}</div>
            <div slot="popup-tip">{{$t('setLeader.tip')}}</div>
            <div slot="popup-con">
            <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm">
                    <el-form-item label="设置负责人" prop="value">
                        <span slot="label">{{$t('setLeader.title')}}<span class="red">*</span></span>
                        <span slot="label" class="tips">{{$t('setLeader.tip2')}}</span>
                        <el-select v-model="ruleForm.value" :placeholder="$t('common.selectPlaceholder')" multiple style="width:100%">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                        </el-select>
                    </el-form-item>
            </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue'
export default {
    data(){
        return {
            headerName:'添加同级部门',
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }],
            ruleForm:{
                value:'',
            },
            oldCheckedList:[]
        }
    },
    components:{
        Popup
    },
   props:{
		setLeaderVisible:{
			default:false,
			type:Boolean
		},
        checkedTreeItem:{
            type:Object,
            default(){
                return{}
            }
        }
	},
    watch:{
        setLeaderVisible(n,o){
            if(n){
                this.oldCheckedList = [];
                this.getAllUser(); 
            }
        }
    },
    methods:{
        // 获取节点下的所有成员
        getAllUser(){
            this.$http.get(this.requestUrl.upAndDown.selectUpAndDownMemberByOrganizationId + "?organizationId=" + this.checkedTreeItem.id)
				.then((res) => {
					this.options = res.data.data || [];
                    this.options.forEach(item => {
                        if(item.principal){
                            this.ruleForm.value.push(item.id);
                            this.oldCheckedList.push(item);
                        }
                    })
				});
        },
        // 关闭弹框
        closeEvent(){
            this.$refs['ruleForm'].resetFields();
            // this.$emit('setLeaderSuccess','isUpdate');
            this.$emit('update:setLeaderVisible',false);
        },
        // 保存
        submitForm(formName){
            let flag = true;
            if(this.ruleForm.name === ''){
                flag = false;
                this.$message.error(this.$t('setLeader.nameRequired'))
				return false;
            }
             if(!this.ruleForm.value === '' || this.ruleForm.value.length === 0){
                flag = false;
                this.$message.error(this.$t('setLeader.leaderRequired'));
				return false;
            }
            if(flag){
                let members  = [];
                this.options.forEach(item => {
                    if(this.ruleForm.value.indexOf(item.id) !== -1){
                        item.principal = true;
                        members.push(item);
                    }
                })
                this.oldCheckedList.forEach(item => {
                    if(this.ruleForm.value.indexOf(item.id) === -1){
                        item.principal = false;
                        members.push(item);
                    }
                })
                this.$http.post(this.requestUrl.upAndDown.setUpPrincipal,members)
                    .then((res) => {
                        if(res.data.code == 0){
                            this.$refs[formName].resetFields();
                             this.$message.success(this.$t('setLeader.leaderSuccess'))
                            this.$emit('setLeaderSuccess');
                        } else {
                            this.$message.error(res.data.message)
                        }
                    });
            }
        }
    }

}
</script>fff

<style scoped lang="less">
    .set-leader{
        .el-button--primary{
            background-color: #366AFF;
            border-color: #366AFF;
        }
        ::v-deep .popup-container{
            overflow-y: inherit !important;
        }
        ::v-deep .el-form-item{
            .el-form-item__label{
                line-height: 20px !important;
                display: flex;
                justify-content: space-between;
            }
            .el-select{
                width: 100%;
            }
            .red {
                color: red;
                margin-left: 6px;
            }
            .tips{
                white-space: nowrap;
                color: #A9B3C6;
                font-size: 12px;
            }
        }
        ::v-deep #popup-assembly{
            width: 800px;
        }
         .el-button{
                width: 99px;
                height: 37px;
                padding: 0;
                // border: 2px solid #E0E6F7;
            }
       
    }
</style>