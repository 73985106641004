<template>
    <div id="notice-list-u" style="width: 100%">
        <div class="notice-list-content">
            <div
                v-for="(item, itemIndex) in tableData"
                :key="itemIndex"
                @click="rowClick(item)"
                :class="[
                    'notice-l-cell',
                    itemIndex % 2 === 0 ? 'notice-l-cell-o' : '',
                ]"
            >
                <div class="notice-l-cell-t">
                    <div class="notice-l-cell-t-l">
                        <span class="system-pic">
                            <i :class="item.systemPic"></i>
                        </span>
                        <span style="margin-left: 4px">
                            {{isE() ? item.systemName.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理') : item.systemName}}</span>
                    </div>
                    <div class="notice-l-cell-t-r">
                        <i
                            style="color: #d2d8e3; margin-left: 16px"
                            class="el-icon-time"
                        ></i>
                        <span style="margin-left: 4px">{{
                            new Date(item.createTime).Format("yyyy-MM-dd")
                        }}</span>
                    </div>
                </div>
                <div class="notice-l-cell-b">
                    <span style="margin-left: 4px">{{
                        item.updateNoticeTitle == null
                            ? ""
                            : item.updateNoticeTitle.split("*+@@+*")[1]
                    }}</span>
                </div>
            </div>
        </div>
        <popup v-if="noticeDialog" @closeEvent="noticeDialog = false">
            <div slot="popup-name" class="popup-name">
                <div class="notice-name-top">
                    {{
                        activeNotice.updateNoticeTitle == null
                            ? ""
                            : activeNotice.updateNoticeTitle.split("*+@@+*")[1]
                    }}
                </div>
            </div>
            <div slot="popup-con">
                <!-- <br />
                <h3>{{
                    activeNotice.updateNoticeTitle == null
                        ? ""
                        : activeNotice.updateNoticeTitle.split("*+@@+*")[1]
                }}</h3> -->
                <p v-html="activeNotice.updateNoticeDescription"></p>
            </div>
            <div slot="dialog-footer" class="notice-other">
                <p class="notice-other-aut">AskBot团队</p>
                <p class="notice-other-time">
                    {{ initTime(activeNotice.createTime) }}
                </p>
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
export default {
    props: ["pageNo", "pageSize", "editPageble"],
    components: {
        Popup,
    },
    data() {
        return {
            noticeDialog: false,
            tableData: [],
            activeNotice: {
                systemName: "",
                updateNoticeTitle: "",
                updateNoticeDescription: "",
                updateTime: "",
                deleted: false,
            },
        };
    },
    filters: {
        formatDateText(val) {
            var day = new Date();
            let text = val;
            if (val == day.Format("yyyy-MM-dd")) {
                text = "今天";
            }
            if (
                val ==
                new Date(new Date().setDate(new Date().getDate() - 1)).Format(
                    "yyyy-MM-dd"
                )
            ) {
                text = "昨天";
            }
            return text;
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        initTime(date) {
            var d = new Date(date);
            var a = [
                d.getFullYear(),
                d.getMonth() + 1,
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
            ];
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i] < 10) {
                    a[i] = "0" + a[i];
                }
            }
            let str2 =
                a[0] +
                "-" +
                a[1] +
                "-" +
                a[2] +
                " " +
                a[3] +
                ":" +
                a[4] +
                ":" +
                a[5];
            return str2;
        },

        rowClick(row) {
            let regP = new RegExp('<p', "g")
            let regLi = new RegExp('<li', "g")
            row.updateNoticeDescription = row.updateNoticeDescription.replace(regP, '<p style="margin-bottom:10px"')
            row.updateNoticeDescription = row.updateNoticeDescription.replace(regLi, '<li style="margin-bottom:10px"')
            row.updateNoticeDescription = this.replaceIframeWithVideo(row.updateNoticeDescription)
            this.activeNotice = row;
            this.noticeDialog = true;
        },
        replaceIframeWithVideo (htmlString) {
            // 解析 HTML 字符串为 DOM
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            // 查找所有的 iframe 元素
            const iframes = doc.querySelectorAll('iframe');

            iframes.forEach(iframe => {
                // 创建一个新的 video 元素
                const video = document.createElement('video');
                video.setAttribute('width', iframe.getAttribute('width'));
                video.setAttribute('height', iframe.getAttribute('310px'));
                video.setAttribute('controls', 'controls'); // 使 video 支持播放控制
                // 创建 source 元素并设置 src 属性
                const source = document.createElement('source');
                source.setAttribute('src', iframe.getAttribute('customaryurl'));
                source.setAttribute('type', 'video/mp4'); // 根据实际视频类型设置
                // 将 source 添加到 video 元素中
                video.appendChild(source);
                // 替换 iframe 为 video
                iframe.parentNode.replaceChild(video, iframe);
            });
            // 返回修改后的 HTML 字符串
            return doc.documentElement.outerHTML;
        },
        getList(pageNo, pageSize) {
            if (pageNo) {
                this.pageNo = pageNo;
            }
            if (pageSize) {
                this.pageSize = pageSize;
            }
            this.$http
                .get(
                    `${this.requestUrl.notice.selectAllNotice}?pageNo=${this.pageNo}&pageSize=${this.pageSize}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.tableData = [...res.data.data.list];
                        if (this.editPageble == "1") {
                            this.$emit("getTableTotal", {
                                total: res.data.data.total,
                            });
                        }
                    }
                });
        },
    },
};
</script>
<style lang="less">
.notice-name-top {
    font-size: 18px;
    font-weight: 700;
    max-width: 500px;
    text-align: center;
}
#notice-list-u {
    overflow-y: auto;
    .notice-l-cell-o {
        background: #fbfcfd;
    }
    .notice-l-cell {
        cursor: pointer;
        padding: 6px 24px;
        .notice-l-cell-t {
            line-height: 36px;
            height: 36px;
            display: flex;
            justify-content: space-between;
        }
        .notice-l-cell-b {
            margin-bottom: 10px;
        }
    }
    .notice-other {
        margin-top: 10px;
        width: 100%;
        text-align: right;
        padding-right: 24px;
        .notice-other-time {
            margin-top: 12px;
        }
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 168px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .el-table-column {
        text-align: left;
        .cell {
            text-align: left !important;
        }
    }
    td .cell {
        text-align: left !important;
    }

    .title-box {
        display: flex;
        align-items: center;
    }

    .circle-blue {
        width: 4px;
        height: 4px;
        min-width: 4px;
        border-radius: 2px;
        background-color: #366aff;
    }
    .system-pic {
        i {
            color: #366aff;
        }
    }
}
</style>