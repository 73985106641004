<template>
    <div id="wxAccount">
        <div v-loading="loading" class="content-container">
            <div class="header">
                <div class="leftInfo">
                    <span class="headerName">企业管理</span>
                </div>
                <div class="add">
                    <el-button
                        size="small"
                        v-if="isAuth"
                        type="primary"
                        @click="addAccount"
                        >新增账号</el-button
                    >
                </div>
            </div>
            <div class="accountManage-content">
                <leftSubMenu></leftSubMenu>
                <div class="content-box" style="overflow-y: auto;overflow-x: hidden;min-width: 450px;">
                    <div v-if="!isAuth" class="noAuth">
                        <div v-if="scrmAuthInfo.thirdWeChatKfAgentId == null">
                            <img
                                src="./../../../../src/assets/images/noauth.png"
                                alt=""
                                srcset=""
                            />
                            <span class="no-auth-tip">暂无企业授权</span>
                            <div class="no-auth-tip-long" style="width: 100vw;"
                                >若已启用SCRM系统,安装本应用应当与SCRM为同一个企业授权</div
                            >
                            <el-button @click="getAuth" type="primary" round
                                >点击获取企业授权</el-button
                            >
                        </div>


                    </div>
                    <el-table
                        v-if="isAuth"
                        :data="accountList"
                        style="width: 100%"
                        height="calc(100vh - 140px)"
                        max-height="100%"
                    >
                        <el-table-column
                            label="客服账号"
                            align="center"
                            width="180px"
                        >
                            <template slot-scope="scope">
                                <div class="table-item-account-wxkf">
                                    <div class="icon">
                                        <el-image
                                            style="width: 30px; height: 30px"
                                            :src="scope.row.avatar"
                                            fit="cover"
                                        ></el-image>
                                    </div>
                                    <span
                                        class="table-item-account-wxkf-name"
                                        >{{ scope.row.name }}</span
                                    >
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="接待人员"
                            align="center"
                            min-width="240px"
                        >
                            <template slot-scope="scope">
                                <div
                                    style="
                                        text-align: center;
                                        width: 100%;
                                        word-break: break-all;
                                    "
                                >
                                  <template v-for="(item,index) in scope.row.originSelect">

                                    <template v-if="index<=3">
                                      <template v-if="item.type==='MEMBER'">
                                        <ww-open-data type="userName" :openid="item.id"></ww-open-data>
                                      </template>
                                      <template v-else>
                                        <ww-open-data type="departmentName" :openid="item.id"></ww-open-data>
                                      </template>
                                    </template>
                                    <template v-if="index!=3 && index!=scope.row.originSelect.length-1">、</template>

                                  </template>

                                  <template v-if="scope.row.originSelect.length>3">......</template>

                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="专员服务"
                            align="center"
                            min-width="240px"
                        >
                            <template slot-scope="scope">
                                <div
                                    style="
                                        text-align: center;
                                        width: 100%;
                                        word-break: break-all;
                                    "
                                >
                                  <template v-for="(item,index) in scope.row.commissionerOriginSelect" >

                                    <template v-if="index<=3">
                                      <template v-if="item.type==='MEMBER'">
                                        <ww-open-data type="userName" :openid="item.id"></ww-open-data>
                                      </template>
                                      <template v-else>
                                        <ww-open-data type="departmentName" :openid="item.id"></ww-open-data>
                                      </template>
                                      <template v-if="index!=3 && index!=scope.row.commissionerOriginSelect.length-1">、</template>

                                    </template>

                                  </template>
                                  <template v-if="scope.row.commissionerOriginSelect.length>3">......</template>
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            label="客户群服务"
                            align="center"
                            min-width="100px"
                        >
                            <template slot-scope="scope">
                                <div style="text-align: center; width: 100%">
                                    {{ scope.row.groupName }}
                                </div>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="140"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    size="small"
                                    title="编辑账号"
                                    type="primary"
                                    @click="editAccount(scope.row)"
                                    icon="el-icon-edit"
                                    circle
                                ></el-button>
                                <el-button
                                    size="small"
                                    title="删除账号"
                                    type="danger"
                                    icon="el-icon-delete"
                                    @click="deleteAccount(scope.row.id)"
                                    circle
                                ></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分组管理 -->
                    <el-dialog
                        id="wxAccountHandle"
                        :title="diaglogTitle"
                        :visible.sync="dialogVisible"
                        :close-on-click-modal="false"
                        width="600px"
                    >
                        <div class="wxform">
                            <div class="handle-wx-ac-cell-name">
                                <div class="handle-wx-ac-cell-left">
                                    <div class="wx-ac-cell-title">
                                        <span class="wx-ac-cell-title-pre"
                                            >*</span
                                        >
                                        <span class="wx-ac-cell-title-text"
                                            >账号名称</span
                                        >
                                    </div>
                                    <div class="wx-ac-cell-des">
                                        用于对客户展示的名称
                                    </div>
                                    <div class="wx-ac-cell-content">
                                        <el-input
                                            size="small"
                                            v-model="activeAcc.name"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="handle-wx-ac-cell-right">
                                    <el-upload
                                        class="upload-demo"
                                        ref="upload"
                                        :action="uploadUrl"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :on-error="handleError"
                                        :before-upload="beforeAvatarUpload"
                                        :data="uploadAvatarParams"
                                        :with-credentials="false"
                                    >
                                        <div class="block">
                                            <el-avatar
                                                :size="70"
                                                :src="fileList"
                                                fit="cover"
                                            ></el-avatar>
                                        </div>
                                        <div
                                            class="edit-ava"
                                            style="padding-top: 2px"
                                        >
                                            修改头像
                                        </div>
                                    </el-upload>
                                </div>
                            </div>
                            <div class="handle-wx-ac-cell">
                                <div class="wx-ac-cell-title">
                                    <span class="wx-ac-cell-title-pre">*</span>
                                    <span class="wx-ac-cell-title-text"
                                        >请选择接待人员</span
                                    >
                                </div>
                                <div class="wx-ac-cell-des">
                                    接待人员将以客服帐号向客户提供服务，最多可配置200人
                                </div>
                                <div class="wx-ac-cell-content">
                                    <el-button
                                        size="mini"
                                        @click="openMemberDialog('cus')"
                                        type="primary"
                                        >选择接待人员</el-button
                                    >
                                    <div class="checkMemberTag authorize-title">
                                        <el-tag
                                            size="mini"
                                            v-for="(tag, tagIndex) in unique(
                                                activeAcc.customer
                                            )"
                                            :key="tag.id"
                                            closable
                                            :disable-transitions="false"
                                            @close="
                                                handleCloseTag(tag, tagIndex, 0)
                                            "
                                            >
                                          <template v-if="tag.type === 'MEMBER'">
                                            <ww-open-data type="userName" :openid="tag.id"></ww-open-data>
                                          </template>
                                          <template v-else>
                                            <ww-open-data type="departmentName" :openid="tag.id"></ww-open-data>
                                          </template>
                                        </el-tag>
                                    </div>
                                </div>
                            </div>
                            <div class="handle-wx-ac-cell">
                                <div class="wx-ac-cell-title">专员服务</div>
                                <div class="wx-ac-cell-des">
                                    向客户发送服务专员的名片，邀请客户添加服务专员为好友，享受专员固定的长期服务
                                </div>
                                <div class="wx-ac-cell-content">
                                    <el-button
                                        size="mini"
                                        @click="openMemberDialog('com')"
                                        type="primary"
                                        >选择专员</el-button
                                    >
                                    <div class="checkMemberTag authorize-title">
                                        <el-tag
                                            size="mini"
                                            v-for="(tag, tagIndex) in unique(
                                                activeAcc.commissioner
                                            )"
                                            :key="tag.id"
                                            closable
                                            :disable-transitions="false"
                                            @close="
                                                handleCloseTag(tag, tagIndex, 1)
                                            "
                                            >

                                          <template v-if="tag.type === 'MEMBER'">
                                            <ww-open-data type="userName" :openid="tag.id"></ww-open-data>
                                          </template>
                                          <template v-else>
                                            <ww-open-data type="departmentName" :openid="tag.id"></ww-open-data>
                                          </template>
<!--                                          {{ tag }}-->
                                        </el-tag>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="handle-wx-ac-cell">
                                <div class="wx-ac-cell-title">客户群服务</div>
                                <div class="wx-ac-cell-des">
                                    向客户发送客户群二维码，邀请客户加入客户群，享受群内更丰富多样的服务
                                </div>
                                <div class="wx-ac-cell-content">
                                    <el-button size="mini" type="primary"
                                        >选择客户群</el-button
                                    >
                                </div>
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button
                                size="small"
                                @click="dialogVisible = false"
                                >取 消</el-button
                            >
                            <el-button
                                size="small"
                                type="primary"
                                @click="saveAccount"
                                >保 存</el-button
                            >
                        </span>
                    </el-dialog>
                    <el-dialog
                        title="选择员工"
                        :visible.sync="memberdialogVisible"
                        :close-on-click-modal="false"
                        width="600px"
                        append-to-body
                        class="memberDialog"
                    >
                        <div v-if="memberdialogVisible">
                            <DepartmentTreeWithOpenData
                                :data="memberList"
                                @onNodeClick="onNodeClick"
                            >
                            </DepartmentTreeWithOpenData>
                        </div>
                        <div class="footer-show">
                            <div
                                slot="footer"
                                size="mini"
                                class="dialog-footer"
                            >
                                <el-button
                                    size="mini"
                                    @click="memberdialogVisible = false"
                                    >取 消</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="confirmMember"
                                    >确 定</el-button
                                >
                            </div>
                        </div>
                    </el-dialog>
                </div>
                <!-- <div class="pagination">
                    <el-pagination
                        v-if="pageshow"
                        :background="true"
                        layout="total, prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-sizes="[15]"
                        :page-size="15"
                        :current-page="currentPage"
                        :total="accountData.total"
                    >
                    </el-pagination>
                </div> -->
            </div>
        </div>

        <leftMenu></leftMenu>
    </div>
</template>
<script>
import leftMenu from "./../../menu/leftMenu.vue";
import leftSubMenu from "./../../menu/leftSubMenu.vue";
import DepartmentTree from "./components/DepartmentTree";
import agentConfig from "../../../common/wechat/agentConfig";
import DepartmentTreeWithOpenData from "./components/DepartmentTreeWithOpenData";

export default {
    name: "accountManage",
    components: {
        leftMenu,
        leftSubMenu,
        DepartmentTree,
      DepartmentTreeWithOpenData
    },
    data() {
        return {
            isAuth: true,
            wework3rdUrl: "",
            corpId: "",
            dialogVisible: false,
            diaglogTitle: "新增账号",
            accountList: [],
            currentPage: 1,
            accountData: { pages: "", total: "" },
            pageshow: true,
            loading: true,
            isEditAccount: false,
            accountId: "",
            memberdialogVisible: false,
            memberTags: [],
            uploadUrl: "/api-open/oss/public",
            defaUrl:
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2021/08/30/05/41/e64805ac-f15f-44b9-8517-b68861922118/avatar4.c4b5b964d6.png",
            uploadImg:
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2021/08/30/05/41/e64805ac-f15f-44b9-8517-b68861922118/avatar4.c4b5b964d6.png",
            uploadAvatarParams: {
                token: "8c98087dfd2d48f856d8c95c09115def",
            },
            fileList:
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2021/08/30/05/41/e64805ac-f15f-44b9-8517-b68861922118/avatar4.c4b5b964d6.png",
            activeAcc: {
                name: "",
                customer: [],
                commissioner: [],
            },
            selectCType: "cus",
            memberList: [],
            userTags: [],
            // employeeConfigTag: [],
            flatMember: {},
            scrmAuthInfo: {},
            hasAddressSecret: false,
        };
    },
    computed: {},
    methods: {
        // 标签展示去重
        unique(arr) {
            let uniqueIds = Array.from(new Set(arr.map((cell) => cell.id)));
            let tampIds = [];
            let uniqueMember = [];
            uniqueIds.forEach((item) => {
                arr.forEach((cell) => {
                    if (item == cell.id && tampIds.indexOf(item) == -1) {
                        tampIds.push(item);
                        uniqueMember.push(cell);
                    }
                });
            });
            return uniqueMember;
        },
        saveAddressSecret() {
            this.scrmAuthInfo.corpId =
                this.scrmAuthInfo.weChatKfPermanentCodeResponse.auth_corp_info.corpid;
            this.$http
                .put(
                    "/scrm-api/we-work-auth-info/" + this.scrmAuthInfo.id,
                    this.scrmAuthInfo
                )
                .then((res) => {
                    console.debug("update auth info res", res);
                });
            this.hasAddressSecret = true;
        },
        flatMemberList(list) {
            if (list == null || !(list instanceof Array)) {
                return;
            }
            list.forEach((cell) => {
                this.flatMember[cell.id] = cell.name;
                if (cell.children != null) {
                    this.flatMemberList(cell.children);
                }
            });
        },
        computedKFName(selectList) {
            let str = "";
            let ids = selectList.map((item) => item.id);
            ids = Array.from(new Set(ids));
            let len = ids.length;
            ids.forEach((cell, index) => {
                if (index != len - 1) {
                    str += this.flatMember[cell] + "、";
                } else {
                    str += this.flatMember[cell];
                }
            });
            if (str.length > 50) {
                str = str.substr(0, 50) + "......";
            }
            return str;
        },
        handleCloseTag(tag, tagIndex, type) {
            let tampArr = [];
            if (type == "0") {
                this.activeAcc.customer.forEach(item => {
                    if (tag.id != item.id) {
                        tampArr.push(item);
                    }
                })
                this.activeAcc.customer = [...tampArr];
            }
            if (type == "1") {
                this.activeAcc.commissioner.forEach(item => {
                    if (tag.id != item.id) {
                        tampArr.push(item);
                    }
                })
                this.activeAcc.commissioner = [...tampArr];
            }
        },
        confirmMember() {
            this.memberdialogVisible = false;
            if (this.selectCType == "cus") {
              let tags = JSON.parse(
                  JSON.stringify(this.userTags)
              );
                this.activeAcc.customer = tags.map((tag)=>{
                  return {type:tag.isMember?'MEMBER':'DEPARTMENT',id:tag.id}
                });
            }
            if (this.selectCType == "com") {
              let tags = JSON.parse(
                  JSON.stringify(this.userTags)
              );
                this.activeAcc.commissioner = tags.map((tag)=>{
                  return {type:tag.isMember?'MEMBER':'DEPARTMENT',id:tag.id}
                });
            }
        },
        onNodeClick(node) {
            console.log(node);
            if (node.checked) {
                this.userTags.push(node);
            } else {
                for (let i = 0; i < this.userTags.length; i++) {
                    if (this.userTags[i].id === node.id) {
                        this.userTags.splice(i, 1);
                    }
                }
            }
            this.userTags = [...this.userTags];
            this.$nextTick(()=>{
              WWOpenData.bind(this.$el);
            })
            console.log(this.userTags);
        },
        // loadMemberInfo(corpId) {
        //     this.memberList = [];
        //     this.$http
        //         .get(this.requestUrl.wechatKF.getWechatCustomerMenmber+'?corpId='+corpId)
        //         .then((res) => {
        //             if (res !== "") {
        //                 this.memberList = res.data;
        //                 this.memberList.forEach((item) => {
        //                     this.$set(item, "checked", false);
        //                     this.setChecked(item.children);
        //                 });
        //             }
        //         });
        // },
        //获取员工
        getWeWorkList() {
            let url = "/scrm-api/we-work/member";
            this.FetchGet(url).then((res) => {
                this.memberList = res;
                this.memberList.forEach((item) => {
                    this.$set(item, "checked", false);
                    this.setChecked(item.children);
                });
                this.getRoleTreeRootNode(this.memberList[0].id);
            });
        },
        // 获取树形结构默认展开节点
        getRoleTreeRootNode(provincialCenterId) {
            this.treeExpandData.push(provincialCenterId);
        },
        setChecked(value) {
            if (value !== null) {
                value.forEach((item) => {
                    this.$set(item, "checked", false);
                    this.setChecked(item.children);
                });
            } else return value;
        },

        // 上传头像
        handleAvatarSuccess(res, file) {
            if (res !== "") {
                this.uploadImg = res;
            }
            this.fileList = URL.createObjectURL(file.raw);
        },
        handleError() {
            this.$message.error("图片上传失败");
        },
        beforeAvatarUpload(file) {
            const isSupportedFormat =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/jpeg";
            const isLte2M = file.size / 1024 / 1024 <= 2;

            if (!isSupportedFormat) {
                this.$message.error(
                    "上传头像图片只能是 JPG 或 PNG 格式 或 JPEG 格式!"
                );
            }
            if (!isLte2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isSupportedFormat && isLte2M;
        },
        //点击选择员工
        openMemberDialog(type) {
            this.selectCType = type;
            this.memberdialogVisible = true;
            let ids = [];
            if (type == "cus") {
                ids = this.activeAcc.customer.map((item) => item.id);
            }
            if (type == "com") {
                ids = this.activeAcc.commissioner.map((item) => item.id);
            }
            this.userTags = [];
            this.setCheckedNew(ids, this.memberList);
        },
        setCheckedNew(ids, arr) {
            arr.forEach((item) => {
              let id = item.isMember?item.openUserid:item.id;
                if (ids.indexOf(id) != -1) {
                    this.$set(item, "checked", true);
                    this.userTags.push(item);
                    if (item.children != null) {
                        this.setCheckedNew(ids, item.children);
                    }
                } else {
                    this.$set(item, "checked", false);
                    if (item.children != null) {
                        this.setCheckedNew(ids, item.children);
                    }
                }
            });
        },
        getAccountList(pageNo, pageSize) {
            this.loading = true;
            this.accountList = [];
            this.$http
                .get(this.requestUrl.wechatKF.getWechatCustomer, {
                    params: {
                        pageNo: pageNo,
                        pageSize: pageSize,
                    },
                })
                .then((res) => {
                    this.accountList = res.data;
                    this.loading = false;
                });
        },
        addAccount() {
            this.isEditAccount = false;
            this.uploadImg = this.defaUrl;
            this.fileList = this.defaUrl;
            this.activeAcc = {
                name: "",
                customer: [],
                commissioner: [],
            };
            this.dialogVisible = true;
            this.diaglogTitle = "新增账号";
        },
        deleteAccount(id) {
            this.$confirm(
                "删除账号之后，该账号将不可恢复，是否删除?",
                "删除账号",
                {
                    confirmButtonText: "删除",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.$http
                        .delete(
                            this.requestUrl.wechatKF.deleteWechatCustomer +
                                "/" +
                                id
                        )
                        .then((res) => {
                            this.getAccountList(1, 100000);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        editAccount(item) {
          this.isEditAccount = true;
          this.diaglogTitle = "编辑账号";
          this.accountId = item.id;
          this.fileList = item.avatar;
          this.uploadImg = item.avatar;
          this.activeAcc = {
            name: item.name,
            customer: item.originSelect,
            commissioner: item.commissionerOriginSelect,
          };

            this.dialogVisible = true;

            this.$nextTick(()=>{
              WWOpenData.bind(this.$el);
            })
        },
        /*遍历人员信息*/
        seachSelectUserIds(value, ids, arr, type) {
            if (value !== null) {
                for (let i = 0; i < value.length; i++) {
                    if (ids.indexOf(value[i].id) !== -1) {
                        arr.push(value[i]);
                        this.seachSelectUserIds(
                            value[i].children,
                            ids,
                            arr,
                            type
                        );
                    } else {
                        this.seachSelectUserIds(
                            value[i].children,
                            ids,
                            arr,
                            type
                        );
                    }
                }
            }
            return arr;
        },
        // 保存账号
        saveAccount() {
            let param = {
                avatarUrl: this.fileList,
            };
            if (this.activeAcc.name.trim() == "") {
                this.$message.error("请填写账号名称!");
                return false;
            } else {
                param.customer = this.activeAcc.customer.map((item) => item.id);
            }
            if (this.activeAcc.customer.length == 0) {
                this.$message.error("请选择接待人员!");
                return false;
            } else {
                param.name = this.activeAcc.name.trim();
            }
            // POST 新建账号
            !this.isEditAccount &&
                this.$http
                    .post(
                        this.requestUrl.wechatKF.addWechatCustomer,
                        {
                            avatar: this.uploadImg,
                            commissionerOriginSelect:
                                this.activeAcc.commissioner,
                            corpId: this.corpId,
                            name: param.name,
                            originSelect: this.activeAcc.customer,
                        },
                        {
                            emulateJSON: true,
                        }
                    )
                    .then((res) => {
                        this.getAccountList(1, 100000);
                    });
            this.isEditAccount &&
                this.$http
                    .put(
                        this.requestUrl.wechatKF.updateWechatCustomer +
                            "/" +
                            this.accountId,
                        {
                            avatar: this.uploadImg,
                            commissionerOriginSelect:
                                this.activeAcc.commissioner,
                            corpId: this.corpId,
                            name: param.name,
                            originSelect: this.activeAcc.customer,
                        },
                        {
                            emulateJSON: true,
                        }
                    )
                    .then((res) => {
                        this.getAccountList(1, 100000);
                    });
            this.dialogVisible = false;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.pageshow = false; //让分页隐藏
            this.$nextTick(() => {
                //重新渲染分页
                this.pageshow = true;
            });
            this.getAccountList(val, 15);
        },
        // 获取安装应用链接
        gatWework3rd() {
            this.$http
                .get(this.requestUrl.authScrm.gatWework3rdUrl)
                .then((res) => {
                    this.wework3rdUrl = res.data;
                });
        },
        getAuth() {
            window.location.href = this.wework3rdUrl;
        },
        initWxAgentConfig(corpId){
          //agentConfig
          let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
          let url =  this.requestUrl.wechatKF.agentConfig + "?corpId="+corpId+ "&url="+currentUrl;
          this.$http.get(url).then(res=>{
            console.info('load init wx agent config ',corpId,res);
            if (res.status==200){
              agentConfig(corpId,res.data.data,(res)=>{
                console.info('agent auth config success',res);
                WWOpenData.bind(this.$el);
              });
            }
          })
        },
      initWechatCustomer(callback){
        this.loading = true;
        this.$http
            .get(this.requestUrl.wechatKF.initWechatCustomer)
            .then((res) => {
              console.debug("init wechat custom response:", res);
              this.loading = false;
              if (callback){
                callback();
              }
            });
      },
        loadMember(corpId) {
            this.$http
                .get(this.requestUrl.wechatKF.getWechatCustomerMenmber+'?corpId='+corpId)
                .then((res) => {
                    if (res !== "") {
                        this.memberList = res.data;
                        this.flatMemberList(res.data);
                        this.getAccountList(1, 100000);
                    }
                });
        },
        // 表格回显客服/专员
    },
    mounted() {
      //

        this.$http.get(this.requestUrl.authScrm.getScrmAuthInfo).then((res) => {
            console.debug("auth info", res);
            this.scrmAuthInfo = res.data;

            if (
                res.data.thirdWeChatKfAgentId != null &&
                res.data.thirdWeChatKfAgentId != ""
            ) {
                this.isAuth = true;
                // this.corpId = res.data.corpId;
            } else {
                this.isAuth = false;
                if (
                    res.data.thirdWeChatKfAgentId == null ||
                    res.data.thirdWeChatKfAgentId == ""
                ) {
                    this.gatWework3rd();
                }
                if (
                    res.data.addressSecret == null ||
                    res.data.addressSecret == ""
                ) {
                    this.hasAddressSecret == false;
                }
            }

          if (res.data.weChatKfPermanentCodeResponse!=null && res.data.weChatKfPermanentCodeResponse.auth_corp_info){
            let corpId = res.data.weChatKfPermanentCodeResponse.auth_corp_info.corpid;
            this.corpId = corpId;
            this.initWxAgentConfig(corpId);
            if (
                this.$route.query.corpId != undefined &&
                this.$route.query.mainId != undefined &&
                this.$route.query.reLoad == undefined
            ) {
              this.initWechatCustomer(()=>{
                this.loadMember(corpId);
              })
            } else {
              this.loadMember(corpId);
            }
          }else {
            this.loading = false;
          }

        });

    },
};
</script>

<style lang="less">
@import "./../../../assets/less/wxAccount/wxAccount.less";
.quanxian {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}
.node-content {
    text-align: left;
}
.node-right {
    width: 14px;
    color: #366AFF;
    font-weight: 800;
}
.table-item-account-wxkf {
    display: flex;
    justify-content: flex-start;
    .icon {
        flex: none;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        overflow: hidden;
    }
    .table-item-account-wxkf-name {
        text-align: left;
        flex: auto;
        line-height: 30px;
        margin-left: 8px;
    }
}
.noAuth {
    width: 300px;
    margin: 60px auto;
    text-align: center;
    .no-auth-tip {
        font-size: 14px;
        text-align: center;
        display: block;
        margin: 12px;
    }
    .no-auth-tip-long {
        width: 100vw;
        text-align: center;
        margin-left: calc(150px - 50vw);
        margin-bottom: 12px;
        .el-input {
            width: 400px;
        }
    }
    img {
        height: 300px;
        width: 300px;
    }
}
.memberDialog {
    .footer-show {
        .dialog-footer {
            margin-top: 12px;
            text-align: right;
        }
    }
}
</style>
