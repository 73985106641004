<template>
    <div id="roleTable">
        <el-table
            :data="roleData"
            style="width: 100%;"
            height="calc(100vh - 130px)"
        >
            <el-table-column
                prop="name"
                label="角色名称"
                width="240px"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        {{ scope.row.name }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                label="角色描述"
                align="left"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        <span
                            v-if="scope.row.remark !== ''"
                            style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            "
                            >{{ scope.row.remark }}</span
                        >
                        <span v-else>--</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="accountNum"
                label="角色数量"
                align="center"
                width="100"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="120"
            >
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: space-around">
                        <el-button
                            @click.native.prevent="
                                details(scope.$index, scope.row.id)
                            "
                            type="text"
                            size="medium"
                            v-if="scope.row.isDefault"
                        >
                            详情
                        </el-button>
                        <el-button
                            @click.native.prevent="
                                editRow(scope.$index, scope.row.id)
                            "
                            type="text"
                            size="medium"
                            v-if="!scope.row.isDefault"
                        >
                            <span
                                v-if="
                                    operableDeleteRole(
                                        'PORTAL_MANAGEMENT_ROLE_UPDATE'
                                    )
                                "
                                >编辑</span
                            >
                            <span v-else>详情</span>
                        </el-button>
                        <el-button
                            @click.native.prevent="
                                deleteRow(scope.row.id, scope.row.name)
                            "
                            type="text"
                            size="medium"
                            style="color: red"
                            v-if="
                                !scope.row.isDefault &&
                                operableDeleteRole(
                                    'PORTAL_MANAGEMENT_ROLE_DELETE'
                                ) &&
                                scope.row.accountNum === 0
                            "
                        >
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer-pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="roleTableTotal"
            >
            </el-pagination>
        </div>
        <el-dialog title="删除角色" :visible.sync="dialogVisible" width="300px">
            <span style="text-align: left"
                >是否删除
                <span style="padding-left: 4px; font-weight: 600">{{
                    roleName
                }}</span></span
            >
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="deleteRole">删 除</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { operable } from "../../js/operable";

export default {
    name: "roleTable",
    data() {
        return {
            roleData: [],
            dialogVisible: false,
            roleName: "",
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            roleId: 0,
            rolePrivilegesList: [],
        };
    },
    mounted() {
        this.getRoles();
        this.rolePrivilegesList = this.getRolePrivileges();
    },
    computed: {
        operableDeleteRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        //获取所有角色
        getRoles() {
            let url =
                "api/role/page?pageSize=" +
                this.pageSize +
                "&pageNo=" +
                this.currentPage;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    console.log("roles", res.data.data.list);
                    this.roleData = res.data.data.list;
                    this.roleTableTotal = res.data.data.total;
                }
            });
        },
        details(index, id) {
            this.$router.push({
                path: "/_addRole",
                query: {
                    type: "details",
                    id: id,
                },
            });
        },
        editRow(index, id) {
            this.$router.push({
                path: "/_addRole",
                query: {
                    type: "edit",
                    id: id,
                },
            });
        },
        deleteRow(id, name) {
            this.roleId = id;
            this.dialogVisible = true;
            this.roleName = name;
        },
        handleSizeChange(value) {
            console.log(value);
            this.pageSize = value;
            this.getRoles();
        },
        handleCurrentChange(value) {
            console.log(value);
            this.currentPage = value;
            this.getRoles();
        },
        deleteRole() {
            let url = "/api/role/" + this.roleId;
            this.$http.delete(url).then((res) => {
                if (res.data.code === "0") {
                    this.$message({
                        message: "删除角色成功",
                        type: "success",
                        duration: 2000,
                    });
                    this.getRoles();
                }
            });
            this.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="less">
#roleTable {
    width: calc(100vw - 240px);
    .footer-pagination {
        flex: none;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 16px;
    }
}
</style>