import axios from 'axios';

var ignore_auth_instance = axios.create();


ignore_auth_instance.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//axios response 拦截器
ignore_auth_instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 403) {
            // redirect2LoginPage();
        } else {
            return Promise.reject(error)
        }
    }
);

export default ignore_auth_instance;