<template>
    <div class="capacity-flow-manage">
        <SideNav></SideNav>
        <div class="gr-content-container">
           <top-header>
				<div slot="setup-header-left" class="setup-header-left">
					<span class="setup-header-left-icon">
						<i class="iconfont guoran-guanlirongliang"></i
					></span>
                    <span>{{$t('capacityFlowManage.title')}}</span>
				</div>
                <div class="setup-header-right-l" slot="setup-header-right-l">
                    <div class="sd-main-list">
                        <el-select
                            v-model="corpId"
                            size="small"
                            @change="changeCorpId"
                            :placeholder="$t('common.selectPlaceholder')">
                            <el-option
                                v-for="item in mainOptions"
                                :key="item.corpId"
                                :label="item.corpName"
                                :value="item.corpId">
                                <img
                                    height="12px"
                                    v-if="item.corpType == 0"
                                    src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png"
                                    alt=""
                                    srcset=""/>
                                <img
                                    height="12px"
                                    v-if="item.corpType == 3"
                                    src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg"
                                    alt=""
                                    srcset=""/>
                                <span style="display: inline-block;margin-left: 6px;color: #8492a6;font-size: 13px;">
                                    {{ item.corpName }}
                                </span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
		   </top-header>
            <div class="content">
				<div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                        <!-- :key="keyCode" -->
                    <setDetail
                        ref="setDetail"
                        :pageType="pageType"
                        :corpId="corpId"
                        :detailInfo.sync="detailInfo"
                        @saveSetting="saveSetting"
                        @updateInfo="getSelectInfo"
                    ></setDetail>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopHeader from "../../../components/top-header.vue";
import SideNav from "../../menu/SideNav.vue";
import ManangeNav from "../../menu/ManageNave.vue";
import setDetail from './components/setDetail.vue'
export default {
    components:{TopHeader,SideNav,ManangeNav,setDetail},
    data(){
        return {
            mainId: "", // 当前主体id
            corpId:"",
            corpType: "0",
            mainOptions: [], // 主体列表
            pageType:'', // capacity 容量   flow 流量
            flag:false,
            keyCode:1,
            detailInfo:{
                "memberUsedCapacity":0,
                "companyUsedCapacity":0,
                "canUsedCapacity":0,
                "totalCapacity":0,
                "companyPayAbility":{
                    "id":null,
                    "mainId":"",
                    "corpId":"",
                    "totalFlow":0,
                    "totalCapacity":0,
                    "companyKnowledgeCapacityLimit":-1,
                    "companyKnowledgeCapacityView":{
                        "custom":"-1",
                        "capacity":"-1",
                        "unit":"MB"
                    },
                    "companyUserCapacityLimit":-1,
                    "companyUserCapacityView":null,
                    "companyUserFlowLimit":-1,
                    "companyUserFlowView":null,
                    "companyUserFlowMonthRefresh":null,
                    "createTime":"2023-07-13T08:25:36.000+00:00",
                    "updateTime":"2023-07-13T08:25:36.000+00:00",
                    "companyUserView":{
                        "custom":"-1",
                        "capacity":"-1",
                        "unit":"MB"
                    }
                },
                "companySeparateConfiguration":{
                    id:null,
                    "mainId":"",
                    "tableData":[

                    ]
                },
                "hadUsed":"0",
                "memberUsedFlow":0,
                "totalFlow":0,
                "canUsedFlow":0,
                "memberUsedCapacityPercentage":0,
                "canUse":"0",
                "companyUsedCapacityPercentage":0,
                "totalValue":"0",
                "proportionBoo":false
            },
        }
    },
    watch:{
        $route(to, from) {
            if((to.name === 'capacity' && from.name === 'flow') || to.name === 'flow' && from.name === 'capacity' ){
                this.flag = false;
            }
            this.keyCode ++;
            this.detailInfo={
                "memberUsedCapacity":0,
                "companyUsedCapacity":0,
                "canUsedCapacity":0,
                "totalCapacity":0,
                "companyPayAbility":{
                    "id":null,
                    "mainId":"",
                    "corpId":"",
                    "totalFlow":0,
                    "totalCapacity":0,
                    "companyKnowledgeCapacityLimit":-1,
                    "companyKnowledgeCapacityView":{
                        "custom":"-1",
                        "capacity":"-1",
                        "unit":"MB"
                    },
                    "companyUserCapacityLimit":-1,
                    "companyUserCapacityView":null,
                    "companyUserFlowLimit":-1,
                    "companyUserFlowView":null,
                    "companyUserFlowMonthRefresh":null,
                    "createTime":"2023-07-13T08:25:36.000+00:00",
                    "updateTime":"2023-07-13T08:25:36.000+00:00",
                    "companyUserView":{
                        "custom":"-1",
                        "capacity":"-1",
                        "unit":"MB"
                    }
                },
                "companySeparateConfiguration":{
                    id:null,
                    "mainId":"",
                    "tableData":[

                    ]
                },
                "hadUsed":"0",
                "memberUsedFlow":0,
                "totalFlow":0,
                "canUsedFlow":0,
                "memberUsedCapacityPercentage":0,
                "canUse":"0",
                "companyUsedCapacityPercentage":0,
                "totalValue":"0",
                "proportionBoo":false

            }
            this.pageType = to.name;
        },
        pageType(n){
            if(!this.flag){
                this.getCorps4main();
            }
        }
    },
    mounted(){
        this.keyCode = 1;
        this.flag = true;
        this.pageType = this.$route.name;
        this.getCorps4main();
    },
    methods:{
        // 获取主体下的公司
        getCorps4main() {
            this.$http.get(this.requestUrl.accountManage.corps4main).then((res) => {
                if (res.data && res.data.code === "0") {
                    this.mainOptions = res.data.data;
                    if (this.mainOptions.length != 0) {
                        this.corpId = this.mainOptions[0].corpId;
                        sessionStorage.setItem('corpId',this.corpId)
                        this.mainId = localStorage.getItem('_mainId')
                        this.getSelectInfo()
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        changeCorpId(val) {
            this.corpId = val;
            sessionStorage.setItem('corpId',this.corpId)
            this.mainOptions.forEach((item) => {
                if (item.corpId == val) {
                    this.corpType = item.corpType;
                    if (this.corpType == 0) {
                        this.channel = "WE_WORK";
                    }
                    if (this.corpType == 3) {
                        this.channel = "DING_TALK";
                    }
                }
            });
        },
        // 获取设置
        getSelectInfo(){
            let url = ''
            if(this.pageType === 'capacity'){
                url = this.requestUrl.knowledge.selectCompanyUseCapacity
            } else {
                url = this.requestUrl.knowledge.selectCompanyUseFlow
            }
            this.$http.get(url + '?mainId=' + this.mainId + '&corpId=' + this.corpId).then((res) => {
                if(res.data.code == 0){
                    console.log(res.data,1111);
                    if(this.pageType === 'capacity'){
                        res.data.data.companyPayAbility.isDot = res.data.data.companyPayAbility.capacityApplyListNew;
                        res.data.data.hadUsed = res.data.data.memberUsedCapacity + res.data.data.companyUsedCapacity;

                        res.data.data.memberUsedCapacityPercentage = Number((res.data.data.memberUsedCapacity / res.data.data.totalCapacity) * 100) > 100 ? 100 : Number(((res.data.data.memberUsedCapacity / res.data.data.totalCapacity)* 100 ).toFixed(1))
                        res.data.data.memberUsedCapacityPercentage = res.data.data.memberUsedCapacityPercentage < 1 ? 1 : res.data.data.memberUsedCapacityPercentage
                        res.data.data.canUse = res.data.data.canUsedCapacity;

                        res.data.data.companyUsedCapacityPercentage = (res.data.data.companyUsedCapacity / res.data.data.totalCapacity) * 100 > 100 ? 100 :  Number(((res.data.data.companyUsedCapacity / res.data.data.totalCapacity) * 100 ).toFixed(1))
                        res.data.data.memberUsedCapacityPercentage = res.data.data.memberUsedCapacityPercentage < 1 ? 1 : res.data.data.memberUsedCapacityPercentage
                        res.data.data.totalValue = res.data.data.totalCapacity;
                        res.data.data.proportion = Number(((res.data.data.canUsedCapacity/res.data.data.totalCapacity) * 100) .toFixed(1));
                        res.data.data.proportionBoo = res.data.data.proportion < 0.1;
                         
                        //  res.data.data.memberUsedCapacityPercentage = 1
                        // res.data.data.companyUsedCapacityPercentage = 30
                        // res.data.data.proportion = 98
                        // res.data.data.proportionBoo = true;


                        if(!res.data.data.companyPayAbility.companyKnowledgeCapacityView){
                            res.data.data.companyPayAbility.companyKnowledgeCapacityView = {
                                custom:'-1',  
                                value:'',// 展示容量
                                unit:'' // 容量单位 
                            }
                        }
                        if(!res.data.data.companyPayAbility.companyUserCapacityView){
                            res.data.data.companyPayAbility.companyUserView = {
                                custom:'-1',  
                                value:'',// 展示容量
                                unit:'' // 容量单位 
                            }
                        } else {
                            res.data.data.companyPayAbility.companyUserView = res.data.data.companyPayAbility.companyUserCapacityView
                        }
                        // if(!res.data.data.companySeparateConfiguration || !res.data.data.companySeparateConfiguration.capacitySeparateConfiguration){
                        //     res.data.data.companySeparateConfiguration = {
                        //         id:null,
                        //         mainId:localStorage.getItem('_mainId'),
                        //         capacitySeparateConfiguration:'',
                        //         tableData:[]
                        //     }
                        // } else {
                        //     res.data.data.companySeparateConfiguration.tableData = JSON.parse(res.data.data.companySeparateConfiguration.capacitySeparateConfiguration)
                        // }

                        if(!res.data.data.companySeparateConfiguration){
                            res.data.data.companySeparateConfiguration = {
                                id:null,
                                mainId:localStorage.getItem('_mainId'),
                                capacitySeparateConfiguration:"[]",
                                tableData:[]
                            }
                        } else if(!res.data.data.companySeparateConfiguration.capacitySeparateConfiguration){
                            res.data.data.companySeparateConfiguration = {
                                id:res.data.data.companySeparateConfiguration.id,
                                mainId:localStorage.getItem('_mainId'),
                                tableData:[]
                            }
                        } else {
                            res.data.data.companySeparateConfiguration.tableData = JSON.parse(res.data.data.companySeparateConfiguration.capacitySeparateConfiguration)
                        }
                        
                    } else {
                        res.data.data.companyPayAbility.isDot = res.data.data.companyPayAbility.flowApplyListNew;
                        res.data.data.hadUsed = res.data.data.memberUsedFlow;
                        res.data.data.memberUsedFlowPercentage = ((res.data.data.memberUsedFlow / res.data.data.totalFlow) * 100) > 100 ? 100 : Number(((res.data.data.memberUsedFlow / res.data.data.totalFlow) * 100).toFixed(1))
                        res.data.data.memberUsedFlowPercentage = res.data.data.memberUsedFlowPercentage < 1 ? 1 : res.data.data.memberUsedFlowPercentage
                        res.data.data.canUse = res.data.data.canUsedFlow;
                        res.data.data.totalValue = res.data.data.totalFlow;
                        //  res.data.data.proportionBoo = true;
                        // res.data.data.canUseFlowProportion = (((res.data.data.totalFlow-res.data.data.canUsedFlow)/res.data.data.totalFlow)*100).toFixed(1);
                        res.data.data.proportion = Number(((res.data.data.canUsedFlow/res.data.data.totalFlow) * 100).toFixed(1));
                        //  res.data.data.proportion = res.data.data.proportion < 1 ? 1 : res.data.data.proportion
                        res.data.data.proportionBoo =  res.data.data.proportion < 0.1;
                        if(!res.data.data.companyPayAbility.companyUserFlowView){
                            res.data.data.companyPayAbility.companyUserView = {
                                custom:'-1',  
                                value:'',// 展示容量
                                unit:'' // 容量单位 
                            }
                        } else {
                            res.data.data.companyPayAbility.companyUserView = res.data.data.companyPayAbility.companyUserFlowView
                        }
                        if(!res.data.data.companySeparateConfiguration){
                            res.data.data.companySeparateConfiguration = {
                                id:null,
                                mainId:localStorage.getItem('_mainId'),
                                flowSeparateConfiguration:"[]",
                                tableData:[]
                            }
                        } else if(!res.data.data.companySeparateConfiguration.flowSeparateConfiguration){
                            res.data.data.companySeparateConfiguration = {
                                id:res.data.data.companySeparateConfiguration.id,
                                mainId:localStorage.getItem('_mainId'),
                                tableData:[]
                            }
                        } else {
                            res.data.data.companySeparateConfiguration.tableData = JSON.parse(res.data.data.companySeparateConfiguration.flowSeparateConfiguration)
                        }
                    }
                    this.detailInfo = res.data.data;
                    console.log(this.detailInfo,'this.detailInfo',process.env.VUE_APP_CONFIG_ENV);
                } else {
                    this.$message.error(res.data.message)
                }		
            }); 
        },
        // 处理单独设置数据
        handlerTableLimit(){
            // 无限制 -1     自定义 -2    不可用 -3
            // let value = this.pageType === 'capacity' ? 'capacityLimit' : 'flowLimit'
            this.detailInfo.companySeparateConfiguration.tableData.forEach(item => {
                if(this.pageType === 'capacity'){
                    if(item.view.custom == -1){
                        item.capacityLimit = -1;
                        item.view.unit = '';
                        item.view.value = '';
                    } else if(item.view.custom == -2){
                        item.capacityLimit = item.view.unit.indexOf('MB') !== -1 ? item.view.value*1024*1024 : item.view.value*1024*1024*1024;
                    } else {
                        item.capacityLimit = item.view.custom.indexOf('MB') !== -1 ?  Number(item.view.custom.replace('MB',''))*1024*1024 : Number(item.view.custom.replace('GB',''))*1024*1024*1024;
                        item.view.unit = '';
                        item.view.value = '';
                    }
                } else {
                    if(item.view.custom == -1 || item.view.custom == -3){
                        item.flowLimit = item.view.custom;
                        item.monthRefresh =  false;
                        item.view.unit = '';
                        item.view.value = '';
                    } else if(item.view.custom == -2){
                        item.flowLimit = item.view.value
                        item.monthRefresh =  item.view.unit.indexOf('次/月') !== -1
                    } else {
                        item.monthRefresh = item.view.custom.indexOf('次/月') !== -1;
                        item.flowLimit = item.view.custom.indexOf('次/月') !== -1 ?  Number(item.view.custom.replace('次/月','')) : Number(item.view.custom.replace('次',''));
                        item.view.unit = '';
                        item.view.value = '';
                    }
                }
            })
        },
        // 处理配置
        handlerLimit(){
            // 无限制 -1     自定义 -2    不可用 -3
            if(this.pageType === 'capacity'){
                console.log(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView,2222);
                let userLimit = 0,companyLimit = 0;
                // 企业知识可使用
                if(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom == -1){
                    this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit = -1;
                    this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.value = '';
                    this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.unit = '';
                } else if(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom == -2){
                    console.log(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView,1111);
                    companyLimit = this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.value;
                    if(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.unit.indexOf('MB') !== -1){
                        this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit = companyLimit*1024*1024;
                    } else {
                        this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit = companyLimit*1024*1024*1024;
                    }
                } else {
                    this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.value = '';
                    this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.unit = '';
                     // 0GB的处理
                    if(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom.indexOf('MB') !== -1){
                        companyLimit = Number(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom.replace('MB',''))
                        this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit = companyLimit*1024*1024;
                    } else {
                        companyLimit = Number(this.detailInfo.companyPayAbility.companyKnowledgeCapacityView.custom.replace('GB',''))
                        this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit = companyLimit*1024*1024*1024;
                    }
                }
                // /企业员工每人可使用
                if(this.detailInfo.companyPayAbility.companyUserView.custom == -1){
                    this.detailInfo.companyPayAbility.companyUserCapacityLimit = -1;
                    this.detailInfo.companyPayAbility.companyUserView.value = '';
                    this.detailInfo.companyPayAbility.companyUserView.unit = '';
                } else if(this.detailInfo.companyPayAbility.companyUserView.custom == -2){
                    userLimit = this.detailInfo.companyPayAbility.companyUserView.value;
                    if(this.detailInfo.companyPayAbility.companyUserView.unit.indexOf('MB') !== -1){
                        this.detailInfo.companyPayAbility.companyUserCapacityLimit = userLimit*1024*1024;
                    } else {
                        this.detailInfo.companyPayAbility.companyUserCapacityLimit = userLimit*1024*1024*1024;
                    }
                } else {
                     // 0GB的处理
                    this.detailInfo.companyPayAbility.companyUserView.value = '';
                    this.detailInfo.companyPayAbility.companyUserView.unit = '';
                    if(this.detailInfo.companyPayAbility.companyUserView.custom.indexOf('MB') !== -1){
                        userLimit = Number(this.detailInfo.companyPayAbility.companyUserView.custom.replace('MB',''))
                        this.detailInfo.companyPayAbility.companyUserCapacityLimit = userLimit*1024*1024;
                    } else {
                        userLimit = Number(this.detailInfo.companyPayAbility.companyUserView.custom.replace('GB',''))
                        this.detailInfo.companyPayAbility.companyUserCapacityLimit = userLimit*1024*1024*1024;
                    }
                }
            } else {
                if(this.detailInfo.companyPayAbility.companyUserView.custom == -1 || this.detailInfo.companyPayAbility.companyUserView.custom == -3){
                    this.detailInfo.companyPayAbility.companyUserFlowLimit = this.detailInfo.companyPayAbility.companyUserView.custom;
                    this.detailInfo.companyPayAbility.companyUserFlowMonthRefresh =  false;
                    this.detailInfo.companyPayAbility.companyUserFlowView = {
                        value:'',
                        unit:'',
                    }
                } else if(this.detailInfo.companyPayAbility.companyUserView.custom == -2){
                    this.detailInfo.companyPayAbility.companyUserFlowLimit = this.detailInfo.companyPayAbility.companyUserView.value
                    this.detailInfo.companyPayAbility.companyUserFlowMonthRefresh =  this.detailInfo.companyPayAbility.companyUserView.unit.indexOf('次/月') !== -1;
                } else {
                    this.detailInfo.companyPayAbility.companyUserFlowLimit = this.detailInfo.companyPayAbility.companyUserView.custom.indexOf('次/月') !== -1 ?  Number(this.detailInfo.companyPayAbility.companyUserView.custom.replace('次/月','')) : Number(this.detailInfo.companyPayAbility.companyUserView.custom.replace('次',''))
                    this.detailInfo.companyPayAbility.companyUserFlowMonthRefresh =  this.detailInfo.companyPayAbility.companyUserView.custom.indexOf('次/月') !== -1; 
                }
            }
        },
         // 保存设置
        saveSetting(){
            let url = '',params = {};
            this.handlerLimit();
            console.log(this.detailInfo,'---');
            // return false

            if(this.pageType === 'capacity'){
                url = this.requestUrl.knowledge.setCompanyCapacity;
                params = {
                    companyPayAbility: {
                        id: this.detailInfo.companyPayAbility.id,
                        mainId: this.detailInfo.companyPayAbility.mainId,
                        corpId: this.detailInfo.companyPayAbility.corpId,
                        totalCapacity: this.detailInfo.totalCapacity,
                        companyKnowledgeCapacityLimit: this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit,  // 企业知识可使用容量限制 -1为无限制，正常为b单位的大小
                        companyUserCapacityLimit: this.detailInfo.companyPayAbility.companyUserCapacityLimit , // 企业员工每人可使用容量限制 -1为无限制，正常为b单位的大小
                        companyKnowledgeCapacityView:this.detailInfo.companyPayAbility.companyKnowledgeCapacityView,
                        companyUserCapacityView:this.detailInfo.companyPayAbility.companyUserView,
                    },
                    updateSeparateConfiguration:false,// 是否有对单独配置做修改 true/false
                    companySeparateConfiguration: { // 单独配置
                        id:this.detailInfo.companySeparateConfiguration.id,
                        mainId:this.detailInfo.companySeparateConfiguration.mainId,
                        corpId:this.detailInfo.companyPayAbility.corpId,
                        capacitySeparateConfiguration:'[]', // capacitySeparateConfiguration对象数组JSON字符串
                    }
                }
                this.handlerTableLimit()
                params.companySeparateConfiguration.capacitySeparateConfiguration = JSON.stringify(this.detailInfo.companySeparateConfiguration.tableData);
                if(params.companySeparateConfiguration.capacitySeparateConfiguration !== this.detailInfo.companySeparateConfiguration.capacitySeparateConfiguration){
                    params.updateSeparateConfiguration = true;
                }

            } else {
                url = this.requestUrl.knowledge.setCompanyFlow;
                params = {
                    companyPayAbility: {
                        id: this.detailInfo.companyPayAbility.id,
                        mainId: this.detailInfo.companyPayAbility.mainId,
                        corpId: this.detailInfo.companyPayAbility.corpId,
                        totalFlow: this.detailInfo.companyPayAbility.totalFlow,
                        companyKnowledgeCapacityLimit:  this.detailInfo.companyPayAbility.companyKnowledgeCapacityLimit,
                        companyUserCapacityLimit: this.detailInfo.companyPayAbility.companyUserCapacityLimit,   
                        companyUserFlowLimit:  this.detailInfo.companyPayAbility.companyUserFlowLimit,  // 企业员工每人可使用流量，-1代表无限制 其余是正数次数
                        companyUserFlowMonthRefresh:  this.detailInfo.companyPayAbility.companyUserFlowMonthRefresh,
                        companyUserFlowView:this.detailInfo.companyPayAbility.companyUserView,
                    },
                    updateSeparateConfiguration:false,// 是否有对单独配置做修改 true/false
                    companySeparateConfiguration: { // 单独配置
                        id:this.detailInfo.companySeparateConfiguration.id,
                        mainId:this.detailInfo.companySeparateConfiguration.mainId,
                        corpId:this.detailInfo.companyPayAbility.corpId,
                        flowSeparateConfiguration:'[]', // flowSeparateConfiguration对象数组JSON字符串
                    }
                }
                
                this.handlerTableLimit();
                params.companySeparateConfiguration.flowSeparateConfiguration = JSON.stringify(this.detailInfo.companySeparateConfiguration.tableData);
                if(params.companySeparateConfiguration.flowSeparateConfiguration !== this.detailInfo.companySeparateConfiguration.capacitySeparateConfiguration){
                    params.updateSeparateConfiguration = true;
                }

            }
            
            console.log(params,'params');
            // return false;
            this.$http.post(url,params).then((res) => {
                if(res.data.code == 0){
                    console.log(res,'保存成功');
                    this.messageFn('success', this.$t('capacityFlowManage.saveSuccess'));
                } else {
                    this.$message.error(res.data.message)
                }		
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import "./../../../assets/less/capacityAndFlowManage/capacityFlowManage.less";
</style>