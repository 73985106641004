<template>
    <div id="edit-password">
        <popup v-if="editDialog" @closeEvent="editDialog = false">
            <div slot="popup-name" class="popup-name">{{ title }}</div>
            <div slot="popup-tip">{{ editPhoneTip }}</div>
            <div slot="popup-con">
                <div v-if="editType === 'edit'">
                    <div v-if="step == 1" class="form-item">
                        <div class="label">{{$t('personal.currentPhone')}}</div>
                        <input
                            v-number-only
                            disabled
                            id="inputPhone"
                            v-model="oldPhone"
                            @input="changeOldPhone"
                            :placeholder="$t('personal.currentPlaceholder')"
                        />
                        <div class="gr-tips">{{ oldPhoneTips }}</div>
                    </div>
                    <div v-if="step == 2" class="form-item">
                        <div class="label">{{$t('personal.newPhone')}}</div>
                        <input
                            v-number-only
                            id="inputPhone"
                            v-model="newPhone"
                            @input="changeNewPhone"
                            :placeholder="$t('personal.newPlaceholder')"
                        />
                        <div class="gr-tips">{{ newPhoneTips }}</div>
                    </div>
                    <div class="form-item">
                        <div class="label">{{$t('personal.code')}}</div>
                        <div class="form-input-cell">
                            <input
                                type="password"
                                id="inputPassword"
                                :placeholder="$t('personal.codePlaceholder')"
                                v-model="checkCode"
                                @input="changeCheckCode"
                            />
                            <span
                                @click="getCheckcode"
                                v-if="!verifyIng"
                                class="varify-btn"
                                >{{ verifyName }}</span
                            >
                            <span v-if="verifyIng" class="varify-time"
                                >{{ verifyTime }} S</span
                            >
                        </div>
                        <div class="gr-tips">{{ checkCodeTips }}</div>
                    </div>
                </div>
                <div v-if="editType === 'bind'">
                    <div class="form-item">
                        <div class="label">{{$t('personal.mobile')}}</div>
                        <input
                            v-number-only
                            id="inputPhone"
                            v-model="phone"
                            @input="changePhone"
                            :placeholder="$t('personal.mobilePlaceholder')"
                        />
                        <div class="gr-tips">{{ phoneTips }}</div>
                    </div>
                    <div class="form-item">
                        <div class="label">{{$t('personal.code')}}</div>
                        <div class="form-input-cell">
                            <input
                                type="password"
                                id="inputPassword"
                                :placeholder="$t('personal.codePlaceholder')"
                                v-model="checkCode"
                                @input="changeCheckCode"
                            />
                            <span
                                @click="getCheckcodePre"
                                v-if="!verifyIng"
                                class="varify-btn"
                                >{{ verifyName }}</span
                            >
                            <span v-if="verifyIng" class="varify-time"
                                >{{ verifyTime }} S</span
                            >
                        </div>
                        <div class="gr-tips">{{ checkCodeTips }}</div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="editDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    v-if="step == 1 && editType === 'edit'"
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="nextStep"
                    >{{$t('common.nextStep')}}</el-button
                >
                <el-button
                    v-if="step === 2 && editType === 'edit'"
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="submitEdit"
                    >{{$t('common.confirm')}}</el-button
                >
                <el-button
                    v-if="editType === 'bind'"
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="submitBind"
                    >{{$t('personal.bindNow')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
export default {
    data() {
        return {
            title: this.$t('personal.editMobile'),
            editPhoneTip: this.$t('personal.editMobileTip'),
            step: 1,
            editType: "edit",
            editDialog: false,
            verifyTime: 60,
            verifyName: this.$t('personal.getVerificationCode'),
            phone: "",
            phoneTips: "",
            oldPhone: "",
            oldPhoneTips: "",
            newPhone: "",
            newPhoneTips: "",
            checkCode: "",
            checkCodeTips: "",
            verifyIng: false,
        };
    },
    components: {
        Popup,
    },
    directives: {
        numberOnly: {
            bind(el) {
                el.handler = function () {
                    el.value = el.value.replace(/\D+/, "");
                    if (el.value.length > 11) {
                        el.value = el.value.substring(0, 11);
                    }
                };
                el.addEventListener("input", el.handler);
            },
            unbind(el) {
                el.removeEventListener("input", el.handler);
            },
        },
    },
    methods: {
        initShow(obj) {
            this.editType = obj.type;
            if (obj.type === "edit") {
                this.oldPhone = obj.phone;
                this.title = this.$t('personal.editMobile');
                this.editPhoneTip = this.$t('personal.editMobileTip');
                this.step = 1;
            } else {
                this.title = this.$t('personal.bindMobile');
                this.editPhoneTip = this.$t('personal.bindMobileTip');
            }
            this.editDialog = true;
        },
        changeOldPhone(val) {
            if (this.oldPhone.trim() !== "") {
                this.oldPhoneTips = "";
            }
        },
        changeNewPhone(val) {
            if (this.newPhone.trim() !== "") {
                this.newPhoneTips = "";
            }
        },
        changePhone(val) {
            if (this.phone.trim() !== "") {
                this.phoneTips = "";
            }
        },
        changeCheckCode() {
            if (this.checkCode.trim() !== "") {
                this.checkCodeTips = "";
            }
        },
        getCheckcodePre() {
            if (this.phone.trim() === "") {
                this.phoneTips = this.$t('personal.mobileNull');
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.phone.trim())) {
                    this.phoneTips = this.$t('personal.checkMobile');
                    return false;
                }
            }
            this.$http_ignore_auth
                .get("/api/register/check?phone=" + this.phone.trim())
                .then((res) => {
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.phoneTips = this.$t('personal.mobileExistence');
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = this.$t('personal.retrieveAgain');
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
        getCheckcode() {
            let phone = this.oldPhone;
            if (this.step == 2) {
                if (this.newPhone.trim() === "") {
                    this.newPhoneTips = this.$t('personal.mobileNull');
                    return false;
                } else {
                    let tel_reg =
                        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                    if (!tel_reg.test(this.newPhone.trim())) {
                        this.newPhoneTips = this.$t('personal.checkMobile');
                        return false;
                    }
                }
                phone = this.newPhone;
            }

            this.$http_ignore_auth
                .post("/api/code/sms/send?phone=" + phone.trim())
                .then((res) => {
                    if (res.data.code != 0) {
                        this.$message.error(res.data.message);
                    } else {
                        this.verifyIng = true;
                        this.verifyTime = 60;
                        let timer = setInterval(() => {
                            this.verifyTime = this.verifyTime - 1;
                            if (this.verifyTime == 0) {
                                this.verifyName = this.$t('personal.retrieveAgain');
                                this.verifyIng = false;
                                clearInterval(timer);
                            }
                        }, 1000);
                    }
                });
        },
        nextStep() {
            if (this.checkCode.trim() === "") {
                this.checkCodeTips = this.$t('personal.codeNull');
                return false;
            }

            this.$http
                .get(
                    `${
                        this.requestUrl.accountManage.checkBindPhone
                    }?checkCode=${this.checkCode.trim()}&phone=${this.oldPhone.trim()}`
                )
                .then((res) => {
                    if (res.data.code != 0) {
                        this.$message.error(res.data.message);
                    } else {
                        this.step = 2;
                        this.checkCode = "";
                        this.oldPhone = "";
                        this.verifyTime = 60;
                        this.verifyName = this.$t('personal.getVerificationCode');
                        this.verifyIng = false;
                    }
                });
        },
        submitBind() {
            if (this.phone.trim() === "") {
                this.newPhoneTips = this.$t('personal.mobileNull');
                return false;
            }
            if (this.checkCode.trim() === "") {
                this.checkCodeTips = this.$t('personal.codeNull');
                return false;
            }
            this.$http
                .put(
                    this.requestUrl.companyManage.onlyBindPhone +
                        "?phone=" +
                        this.phone +
                        "&checkCode=" +
                        this.checkCode
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message.success(this.$t('personal.bindMobileSuccess'));
                        this.$emit("getUserInfo");
                        this.editDialog = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        submitEdit() {
            if (this.newPhone.trim() === "") {
                this.newPhoneTips = this.$t('personal.newMobileNull');
                return false;
            }
            if (this.checkCode.trim() === "") {
                this.checkCodeTips = this.$t('personal.codeNull');
                return false;
            }

            this.editDialog = false;
            this.$http
                .get(
                    `${
                        this.requestUrl.accountManage.checkPhoneOnlyOne
                    }?phone=${this.newPhone.trim()}`
                )
                .then((res) => {
                    if (res.data.code != 0) {
                        this.$message.error(res.data.message);
                    } else {
                        this.$http
                            .put(
                                this.requestUrl.companyManage.onlyBindPhone +
                                    "?phone=" +
                                    this.newPhone +
                                    "&checkCode=" +
                                    this.checkCode
                            )
                            .then((res) => {
                                if (res.data && res.data.code === "0") {
                                    this.$message.success(this.$t('personal.bindMobileSuccess'));
                                    this.$emit("getUserInfo");
                                    this.step = 1;
                                    this.editDialog = false;
                                } else {
                                    this.$message.error(res.data.message);
                                }
                                this.checkCode = "";
                                this.newPhone = "";
                            })
                            .catch((res) => {
                                // location.hash = "/auth";
                            });
                    }
                });
        },
    },
    watch:{
        editDialog(value){
            this.$emit('changeDialog',value)
        }
    }
};
</script>
<style lang="less" scoped>
.form-item {
    width: 100%;
    height: 79px;
    .gr-tips {
        color: #f56c6c;
        text-align: right;
        font-size: 11px;
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}
.form-item input {
    width: calc(100% - 30px);
    outline-style: none;
    border: 1px solid #e0e6f7;
    border-radius: 5px;
    padding: 10px 14px;
    font-size: 14px;
}

.form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
}
</style>