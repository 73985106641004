<template>
  <div class="staff-customer">
    <div class="staff-customer-input">
      <el-input v-model="keyword" :placeholder="$t('common.search')" size="small" ref="serchInput"
        @keyup.enter.native="searchEmployee" @input="monitorSearchNull"></el-input>
    </div>
    <el-select v-model="corpId" :placeholder="$t('common.selectPlaceholder')" v-if="corpList.length > 1" size="small"
      style="width: 100%;margin-bottom: 10px">
      <el-option v-for="item in corpList" :key="item.originData.corpId" :label="item.label"
        :value="item.originData.corpId">
      </el-option>
    </el-select>
    <div v-for="item in treeData">
      <el-tree key="selectOrganizationTreeLazy" :data="[item]" id="selectOrganizationTreeLazy"
        :class="['filter-tree', item.id + 'TreeLazy']" node-key="id" :default-expanded-keys="defaultExpandedKeysMember"
        :expand-on-click-node="false" :ref="item.id + 'TreeLazy'">
        <span class="custom-tree-node" slot-scope="{node,data}" @click="onNodeClick(data, '', item, node)">
          <span class="custom-tree-node-left">
            <!-- // 员工树结构的数据 type=0是员工，type=1是部门 -->
            <i :class="['el-icon-caret-right', data.showChildren ? 'down' : 'right']" v-show="data.type == 1"
              @click.stop="nodeExpand(data, node, item)">
            </i>
            <span class="data-label" v-if="data.isWeWorkThirdData ||
              item.isWeWorkThirdData ||
              (item.originData &&
                item.originData.wechatBotDTO &&
                item.originData.wechatBotDTO.bindType === 0 &&
                item.originData.wechatBotDTO.wechatType === 0)">
              <open-data :type="data.type == 1 ? 'departmentName' : 'userName'" :openid="data.label"></open-data>
            </span>
            <span v-else :class="['data-label', data.dataType == 'hasNext' ? 'load-more-node' : '']">{{ data.label
            }}</span>
            <template v-if="data.originData && data.originData.departments && keyword">
              <span>&#8195</span>
              <div v-for="(cell, index) in data.originData.departments" :key="index">
                <el-tooltip placement="top" effect="light">
                  <div slot="content">
                    (
                    <span v-if="cell.source === 1 &&
                      (data.isWeWorkThirdData || item.isWeWorkThirdData)">
                      <open-data :type="'departmentName'" :openid="cell.fullName"></open-data>
                    </span>
                    <span v-else v-html="cell.fullName"></span>
                    )
                  </div>
                  <span v-if="cell.source === 1 &&
                    (data.isWeWorkThirdData || item.isWeWorkThirdData)">(
                    <open-data :type="'departmentName'" :openid="cell.name"></open-data>
                    )
                  </span>
                  <span v-else>({{ cell.name }})</span>
                </el-tooltip>
              </div>
            </template>
          </span>
          <span v-show="data.checked">
            <i :class="['el-icon-check']"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <span v-if="keyword && staffHasNext" class="hasNextPage" @click="loadStaffMore">
      {{ $t('common.loadMore') }}
    </span>
  </div>
</template>

<script>

import OpenData from "./openData";

export default {
  name: "staffCustomer",
  components: { OpenData },
  data () {
    return {
      activeName: "staff",
      defaultProps: {
        label: "label",
        children: "children",
        isLeaf: "leaf"
      },
      clientId: "",
      keyword: "",
      defaultExpand: false,
      checkMemberList: [],
      memberMapList: [],
      isLazy: false,
      defaultExpandedKeys: [],
      defaultExpandedKeysMember: [],
      treeData: [],
      updownDateTree: [],
      InternalTest: false,
      corpId: "",
      currentPage: 1,
      pageSize: 20,
      corpList: [],
      staffHasNext: false,
      updownNode: "",
      updownResolve: "",
      staffMemberInfo4Clients: {},
      isShowUpDown: true,
      userCorpId: '',
      newCurrentPage: 1,
      newPageSize: 20
    };
  },
  props: ["selectClient", "clientType", "memberList", "clientValue", "memberInfo4Client", "isMultiple", "hasClientNext", "companyConfig", "isCheckDept", "uploadDown", "source", "chooseType"],
  created () {
    let that = this;
    if (that.memberList && that.memberList[0] && that.memberList[0].total < 300) {
      that.defaultExpand = true;
    } else {
      that.defaultExpand = false;
    }
    this.InternalTest = [
      "86dc09da570948d2b4841122d732f373",
      "fb348d095c0b4fd7bbd37826563dac7d",
      "b633dfddeb1a424e939411384499c41b"
      // 'ab0f90737c8b4f2d85ba2157e4473110'
    ].some(_mainId => {
      return _mainId == localStorage.getItem("_mainId");
    }) || process.env.NODE_ENV === "development";
  },
  mounted () {
    console.log("mounted");
    this.$nextTick(() => {
      if (this.source === "ASKBOT_UP_DOWN_MEMBER") {
        this.staffMemberInfo4Clients = this.uploadDown;
      } else {
        this.staffMemberInfo4Clients = this.memberInfo4Client;
      }
    });
    console.debug("clientValue", this.clientValue);
    this.getData(null);
  },
  methods: {
    // 递归获取所有节点
    handlerUpDown (moduleDataList, data) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            // if(data.id === moduleDataList[i].id){
            // 	this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
            // } else {
            this.$set(moduleDataList[i], "checked", false);
            // }
            if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
              this.handlerUpDown(moduleDataList[i].children, data);
            }
          }
        }
        resolve(true);
      });
    },
    //懒加载上下游员工
    loadNode (node, resolve) {
      console.log("懒加载");
      let uid = localStorage.getItem("_uid");
      if (node.level === 0) {
        this.updownNode = node;
        this.updownResolve = resolve;
        let datas = [];
        let url = "/api/upAndDown/selectOrganizationById" + "?userId=" + uid;
        this.$http.get(url).then(res => {
          datas = res.data.data || [];
          this.isShowUpDown = datas.length > 0 ? true : false;
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            element.type = "updown";
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }
          });
          console.log(datas, "datasdatasdatasdatas");
          this.updownDateTree = datas;
          this.getAllNodeId(datas, "show");
          return resolve(datas);

        });
        /* return resolve([{ name: 'region' }]);*/
      }
      if (node.level >= 1) {
        let datas = [];
        let userDatas = [];
        let url = "/api/upAndDown/selectOrganizationById" + "?id=" + node.data.id + "&userId=" + uid;
        this.$http.get(url).then((res) => {
          datas = res.data.data || [];
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            element.type = "updown";
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }
          });
          let departUrl = "/api/upAndDown/selectUpAndDownMemberByOrganizationId?organizationId=" + node.data.id + "&userId=" + uid;
          this.$http.get(departUrl).then(res => {
            console.log(res, "resresres");
            userDatas = res.data.data;
            userDatas.forEach(element => {
              element.leaf = true;
              element.checked = false;
              element.type = "updown";
              if (element.organizationId) {
                element.selfType = 3;
              } else {
                element.selfType = 2;
              }
              // if(element.id == this.memberInfo4Client.memberId){
              // 	element.checked=true;
              // } else {
              // 	element.checked=false;

              // }
            });
            datas = datas.concat(userDatas);
            this.getAllNodeId(datas, "show");
            node.data.children = datas;
            return resolve(datas);
          });
        });
      }
    },
    handleClick () {

    },

    loadMore () {
      this.$emit("loadMore");
    },
    // 节点被展开
    nodeExpand (data, node, item) {
      data.showChildren = !data.showChildren;
      if (item.originData) {
        let corpId = item.originData.corpId;
        this.userCorpId = corpId;
      }
      if (data.showChildren) {
        // this.getData(data.id, data, node);
        this.getUserLazy(data.id, data, node);
      } else {
        this.newCurrentPage = 1;
        data.children = [];
      }
    },
    // 获取员工数据
    getData (departId, data, node) {
      let axiosDatas = [];
      let userId = localStorage.getItem("_uid");
      let corpId = sessionStorage.getItem('corpId')
      // portal-api/department/corpLazy
      let url = departId ? "/api/department/corpListLazy" + "?departId=" + departId + "&userId=" + userId : "/api/department/corpListLazy" + "?userId=" + userId;
      // let url = departId ? "/api/department/corpLazy" + "?departId=" + departId + "&userId=" + userId + '&corpId=' + corpId : "/api/department/corpLazy" + "?userId=" + userId + '&corpId=' + corpId;
      this.$http.get(url).then(res => {
        let memberId = [];
        if (this.isMultiple) {
          if (Array.isArray(this.staffMemberInfo4Clients.memberId)) {
            memberId = this.staffMemberInfo4Clients.memberId.map(member => {
              return Number(member);
            });
          } else {
            memberId = this.staffMemberInfo4Clients.clientInfo ? this.staffMemberInfo4Clients.clientInfo.map(item => {
              return item.memberId;
            }) : [];
          }
        } else {
          memberId = [Number(this.staffMemberInfo4Clients.memberId)];
        }
        let ids = memberId;
        console.debug("res", res);
        let _res = []
        // 员工树结构的数据 type=0是员工，type=1是部门
        if (this.chooseType === 'dept') {
          res.data.forEach((resItem, resIndex) => {
            if (resItem.type === 1) {
              _res.push(resItem)
            }
          })
        } else {
          _res = res.data;
        }
        axiosDatas = this.handlerCheckedSelf(_res || [], ids, departId ? departId : -1);
        // console.log(axiosDatas, "===");
        // this.getAllNodeId(axiosDatas, "show");
        if (departId) {
          data.children = [...data.children, ...axiosDatas];
          this.defaultExpandedKeysMember = [...this.defaultExpandedKeysMember, ...[departId]];
          data.showChildren = true;
        } else {
          this.treeData = [...axiosDatas];
          this.defaultExpandedKeysMember = [];
          this.corpList = JSON.parse(JSON.stringify(axiosDatas));
        }
        console.log(axiosDatas, departId, ids, 'this.treeData');
        if (this.clientId) {
          ids = [this.clientId]
        }
        axiosDatas.forEach(item => {
          if (!departId) {
            item.showChildren = false;
            item.children = [];
          }
          // 会出现两条选中数据
          this.$nextTick(() => {
            let ref = item.id + "TreeLazy";
            setTimeout(() => {
              this.getNodeChecked(ref, item, ids);

            }, 500);
          });
        });
        if (this.treeData.length > 0) {
          this.corpId = this.treeData[0].originData ? this.treeData[0].originData.corpId : "";
        }
      });
    },
    getUserLazy (departId, data, node, isLoadMore) {
      let axiosDatas = [];
      let userId = localStorage.getItem('_uid');
      let url = departId
        ? "/api/department/corpListLazy-v2" +
        "?departId=" +
        departId +
        "&userId=" +
        userId +
        "&corpId=" +
        this.userCorpId + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize
        : "/api/department/corpListLazy-v2" +
        "?userId=" +
        userId +
        "&corpId=" +
        this.userCorpId + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize;
      this.$http.get(url).then((res) => {
        let ids = [];
        let list = []
        if(this.chooseType === 'dept'){
          res.data.list.forEach((listItem,listIndex) => {
            if(listItem.type === 1){
              list.push(listItem)
            }
          })
        } else {
          list = res.data.list;
        }
        axiosDatas = this.handlerCheckedSelf(list || [],
          ids,
          departId ? departId : -1
        );
        this.getAllNodeId(axiosDatas, "show");
        if (departId) {
          if (res.data.hasNextPage) {
            let info = {
              label: "加载更多",
              dataType: 'hasNext',
              id: departId + 'hasNext',
              parentId: departId,
              currentPage: res.data.nextPage ? res.data.nextPage : 1
            }
            axiosDatas = axiosDatas.concat([info])
          }
          axiosDatas.forEach(element => {
            if (element.type === 0 || element.dataType === 'hasNext') {
              element.leaf = true
            }
          })
          if (isLoadMore) {
            let flagFind = true;
            let findParent = (list) => {
              if (flagFind) {
                list.forEach(item => {
                  if (item.id == departId) {
                    flagFind = false;
                    if (item.children[item.children.length - 1].dataType == 'hasNext') {
                      item.children.length = item.children.length - 1;
                    }
                    item.children = [...item.children, ...axiosDatas];
                    this.defaultExpandedKeysMember = [
                      ...this.defaultExpandedKeysMember,
                      ...[departId],
                    ];
                    item.showChildren = true;
                  } else {
                    findParent(item.children);
                  }
                })
              }

            }
            findParent(this.treeData);
          } else {
            data.children = [...data.children, ...axiosDatas];
            this.defaultExpandedKeysMember = [
              ...this.defaultExpandedKeysMember,
              ...[departId],
            ];
            data.showChildren = true;
          }
        } else {
          this.treeData = [...axiosDatas];
          this.defaultExpandedKeysMember = [];
        }
        this.treeData.forEach((item) => {
          if (!departId) {
            item.showChildren = false;
            item.children = [];
          }
        });
      });
    },
    getNodeChecked (ref, data, ids) {
      if (ids.indexOf(data.id) > -1) {
        console.log(ref, this.$refs[ref] && this.$refs[ref][0], 'ref');
        if (this.$refs[ref] && this.$refs[ref][0]) {
          this.$set(this.$refs[ref][0].getNode(data.id).data, "checked", true);
        } else {
          this.$set(data, "checked", true);
        }
      }
      if (data.children) {
        data.children.forEach(item => {
          this.getNodeChecked(ref, item, ids);
        });
      }
    },
    getAIIDeptAndMember (node, resolve) {
      console.debug("node", node);
      if (node.level === 0) {
        this.memberList = this.handlerCheckedSelf(this.memberList);
        return resolve(this.memberList);
      } else {
        let children = node.data.children;
        this.$http("/api/manager/corpListDynamicLazy", {
          query: {
            departId: node.data.id
          }
        }).then((res) => {
          if (res.data.code === "0") {
            let datas = this.handlerCheckedSelf(res.data.data);
            let arr = [...children, ...datas];
            arr.forEach(element => {
              if (element.type === 0) {
                element.leaf = true;
              }
            });
            resolve(arr);
            this.$forceUpdate();
          }
        });
      }
    },
    handlerCheckedSelf (datas, ids, parentId) {
      datas.forEach(item => {
        item.checked = false;
        item.showChildren = false;
        if (item.type === 1) {
          item.selfType = 0;
        } else if (item.type === 0) {
          item.selfType = 1;
        }
        if (ids && ids.indexOf(item.id) > -1) {
          let ref = item.id + "TreeLazy";
          // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

          this.handlerCheckedSelf(item.children || [], ids, item.id || null);
        } else {
          this.handlerCheckedSelf(item.children || [], ids, item.id || null);
        }

      });
      return datas;
    },
    //选择员工报单人
    onNodeClick (data, staffType, corpItem, row) {
      // selfType    0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
      console.log(data, staffType, 'staffType');
      if (data.dataType === "hasNext") {
        /* 点击加载更多 */
        this.newCurrentPage = data.currentPage;
        this.getUserLazy(data.parentId, {}, "", true);
      } else {
        let item = {};
        let type = "";
        if (!staffType) {
          console.debug("corpList", this.corpList);
          let selectCorp = this.corpList.filter(item => item.originData.corpId == this.corpId);
          item = this.keyword ? selectCorp[0] : corpItem;//搜索时取
          type = data.isWeWorkThirdData ||
            item.isWeWorkThirdData ||
            (item.originData &&
              item.originData.wechatBotDTO &&
              item.originData.wechatBotDTO.bindType === 0 &&
              item.originData.wechatBotDTO.wechatType === 0) ? "staff-t" : "staff";
        } else {
          type = staffType;
          item = corpItem;
        }
        if(this.chooseType === 'dept'){

        }
        if (data.type === 0 || this.isCheckDept) {
         // this.handlerUpDown(this.updownDateTree, data);

          // 旧代码在多选的情况下异常，未处理回显状态，不确定都哪里用到，只能在此做逻辑兼容
          if (this.isCheckDept) {
            if (!this.staffMemberInfo4Clients.clientInfo.some(client => { return client.memberId == data.id })) {
              this.staffMemberInfo4Clients.clientInfo.push({
                memberId: data.id,
                name: data.label,
                type: data.type
              })
            } else {
              this.staffMemberInfo4Clients.clientInfo = this.staffMemberInfo4Clients.clientInfo.filter(client => {
                return client.memberId != data.id
              })
            }
          }
          this.getAllNodeId(this.treeData, "click").then(res => {
            if (!this.isCheckDept) {
              this.$set(data, "checked", true);
            }
            this.clientId = data.id;
            this.$emit("onNodeClick", data, type, item, row);
          });
        }
      }
    },
    //搜索
    searchStaff () {
      if (this.activeName === "staff") {
        let url = "/api/manager/searchMember?keyWord=" + this.keyword;
        this.$http.get(url).then(res => {
          if (res.data.code === "0") {
            console.debug("searchMember", res.data.data);
          }

        });

      }
    },
    ergodicClient (type) {
      return new Promise((resolve) => {
        this.selectClient.forEach(client => {
          this.$set(client, "checked", false);
        });
        this.selectClient.forEach(client => {
          if (!type) {
            if (!this.isMultiple) {
              if (client.id === this.clientValue) {
                this.$set(client, "checked", true);
              }
            } else {
              this.clientValue.forEach(item => {
                if (client.id === item) {
                  this.$set(client, "checked", true);
                }
              });
            }
          }


        });
        resolve(true);
      });

    },
    // 递归获取所有节点
    getAllNodeId (moduleDataList, type) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (type) {
              if (!this.isMultiple) {
                if (moduleDataList[i].id == Number(this.staffMemberInfo4Clients.memberId)) {
                  this.$set(moduleDataList[i], "checked", true);
                }
              } else {
                this.staffMemberInfo4Clients.clientInfo.forEach(item => {
                  if (moduleDataList[i].id == item.memberId) {
                    this.$set(moduleDataList[i], "checked", true);
                  }
                });
              }
            }
            if (moduleDataList[i].children) {
              this.getAllNodeId(moduleDataList[i].children, type);
            }
          }
        }
        resolve(true);
      });

    },
    //多选遍历树节点
    ergodicMemberTree (moduleDataList, node) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (moduleDataList[i].children) {
              this.ergodicMemberTree(moduleDataList[i].children, node);
            }
          }
        }
        resolve(true);
      });
    },
    //在触发条件下遍历树节点
    ergodicMemberTree2 (moduleDataList) {
      if (moduleDataList) {
        for (let i = 0; i < moduleDataList.length; i++) {
          if (moduleDataList[i].checked) {
            this.checkMemberList.push(moduleDataList[i]);
          }
          if (moduleDataList[i].children) {
            this.ergodicMemberTree2(moduleDataList[i].children);
          }
        }
      }
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //搜索成员
    searchEmployee (type) {
      let departId = "";
      console.debug("updown11", this.activeName);
      if (this.activeName == "staff" && this.keyword) {
        if (type !== "load") {
          this.treeData = [];
          this.currentPage = 1;
        }
        let userId = localStorage.getItem("_uid");
        let url = "/api/department/corpListLazy-v2?" + "userId=" + userId + "&corpId=" + this.corpId + "&keyword=" + this.keyword + "&page=" + this.currentPage + "&size=" + this.pageSize;
        this.$http.get(url).then(res => {
          console.debug("get member", res, this.memberInfo4Client);
          let list = res.data && res.data.list ? res.data.list : [];
          this.staffHasNext = res.data.hasNextPage;
          let memberId = null;
          if (this.isMultiple) {
            if (Array.isArray(this.staffMemberInfo4Clients.memberId)) {
              memberId = this.staffMemberInfo4Clients.memberId.map(member => {
                return Number(member);
              });
            } else {
              memberId = this.staffMemberInfo4Clients.clientInfo ? this.staffMemberInfo4Clients.clientInfo.map(item => {
                return item.memberId;
              }) : [];
            }
          } else {
            memberId = [Number(this.staffMemberInfo4Clients.memberId)];
          }
          let _res = []
          // 员工树结构的数据 type=0是员工，type=1是部门
          if (this.chooseType === 'dept') {
            list.forEach((listItem, listIndex) => {
              if (listItem.type === 1) {
                _res.push(listItem)
              }
            })
          } else {
            _res = list;
          }
          let ids = memberId ? memberId : [];
          _res.forEach(item => {
            this.$set(item, "checked", false);
            if (ids.includes(item.id)) {
              this.$set(item, "checked", true);
            }
          });
          this.treeData = [...this.treeData, ...list];
        });
      } else if (this.activeName == "upDown" && this.keyword) {

        this.searchUpAndDown();
      }

    },
    //分页加载更多
    loadStaffMore () {
      this.currentPage += 1;
      this.searchEmployee("load");
    },
    //搜索上下游
    searchUpAndDown () {
      let userId = localStorage.getItem("_uid");
      let url = "/api/upAndDown/searchUpDownMemberOrDepart?userId=" + userId + "&keyword=" + this.keyword;
      this.$http.get(url).then(res => {
        console.debug("get updown", res);
        if (res.data.code == 0) {
          let list = res.data.data ? res.data.data : [];
          list.forEach(item => {
            let leaf = item.type == 1 ? false : true;
            this.$set(item, "leaf", leaf);
            item.type = "updown";
            this.$set(item, "checked", false);
            if (item.organizationId) {
              item.selfType = 3;
            } else {
              item.selfType = 2;
            }
          });
          this.updownDateTree = list;
          this.getAllNodeId(this.updownDateTree, "show");
        }
      });

    },
    //监听搜索框为空时
    monitorSearchNull () {
      if (this.activeName == "staff" && !this.keyword) {
        this.treeData = [];
        this.getData(null);
      } else if (this.activeName === "upDown" && !this.keyword) {
        this.updownDateTree = [];
        this.loadNode(this.updownNode, this.updownResolve);
      }
    }
  },
  watch: {
    keyword (val) {
      let tree = this.$refs.tree;
      if (tree) {
        tree.forEach(item => {
          item.filter(val);
        });
      }
    },
    memberInfo4Client: {
      handler (n) {
        console.log(n, "memberInfo4ClientmemberInfo4ClientmemberInfo4Client");
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less">
.staff-customer-input {
  height: 42px;
}

.member-contanier .staff-customer {
  min-height: 300px;

  .customer-list {
    .customer-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 0 0 0 4px;
      cursor: pointer;

      .el-icon-check {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .customer-item:hover {
      background-color: #F6F8FD;
    }
  }

  .load-more-client {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.disabled:hover {
      cursor: not-allowed;
    }

    .custom-tree-node-left {
      display: flex;
      align-items: center;
      width: 90%;
      justify-content: flex-start;

      i {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-right: 6px;
        color: #c0c4cc;
      }

      .data-label {
        /*  width: 100%;*/
        height: 28px;
        display: flex;
        align-items: center;
      }

      .load-more-node {
        color: #366aff;
        cursor: pointer;
      }
    }

    .el-icon-check {
      font-size: 14px;
      font-weight: 600;
    }

  }

  .el-tabs__nav-wrap::after {
    height: 0px !important;
  }

  ::v-deep.el-tree-node__content {
    &:hover {
      background-color: #F6F8FD;
    }
  }

  .el-tree-node:focus>.el-tree-node__content {
    background-color: white !important;
  }

  .el-tree-node__content {
    height: 30px !important;
  }

  .el-tree-node__content:hover {
    background-color: #F6F8FD !important;
  }

}

.staff-customer {
  .el-tree {

    .el-tree-node__expand-icon {
      display: none;
      font-size: 14px;
    }

    .el-icon-caret-right {
      &.down {
        transform: rotate(90deg);
      }

      &.right {
        transform: rotate(0)
      }
    }

    &.updown-tree {
      .el-tree-node__expand-icon {
        display: inline-block;
      }
    }
  }

  .hasNextPage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #366aff;
    margin-top: 10px;
    cursor: pointer;
  }
}
</style>