
<template>
    <div class="fieldSettingRight" :class="showSetting?'showRight':'hideRight'">
		<div class="config-right" v-if="activeObj.type">
			<div class="config-title">
				<div class="config-name">
					<span class="sign"></span>
					{{ fieldName(activeObj.type) }}
				</div>
			</div>
			<!-- v-if="activeObj.type!=='MN_MODULE' && activeObj.type!=='MN_COMPANY' && activeObj.type!=='MN_WORK_NO' && activeObj.type!=='MN_TEL' && activeObj.type!=='MN_CONTACT_PERSON'" -->
			<div class="config-content">
				<input-config
					v-if="activeObj.type === 'INPUT' || activeObj.type === 'TEXTAREA' || activeObj.type === 'RICHTEXT'"
					:fieldData="fieldData"
					:activeObj="activeObj"
					:centerlist="relationFieldList">
				</input-config>
				<radio-add-select
					v-else-if="activeObj.type === 'RADIO' || activeObj.type === 'CHECKBOX' || activeObj.type === 'SELECT' || activeObj.type === 'CASCADER' || activeObj.type === 'IMAGE_SELECT' || activeObj.type === 'ACCOUNT'"
					:fieldData="fieldData"
					:activeObj="activeObj"
					:centerlist="relationFieldList"
					:hasDesCenterlist="hasDesRelationieldList">
				</radio-add-select>
				<date-add-upload
					v-else-if="activeObj.type === 'TIME_PICKER' || activeObj.type === 'TIME_PICKER_RANGE' || activeObj.type === 'IMAGE' || activeObj.type === 'VIDEO' || activeObj.type === 'ATTACHMENT' || activeObj.type === 'EXPLAIN'"
					:fieldData="fieldData"
					:activeObj="activeObj">
				</date-add-upload>
				<bottomCommon 
					v-else-if="activeObj.type === 'EMAIL' || activeObj.type === 'USER_NAME' || activeObj.type === 'ROLE' || activeObj.type === 'ACCOUNT_GROUP' || activeObj.type === 'SUPERIOR' || activeObj.type === 'TYPE_MATE' || activeObj.type === 'MAX_NUM_OF_ORDERS_PROCESSED'"
					:activeObj="fieldData"
					:isSystemField="fieldData.isSystemField">
				</bottomCommon>
				<template v-if="activeObj.type!=='THIRD_PARTY_ACCOUNT' && activeObj.type!=='DATA_PERMISSIONS' && activeObj.type!=='WORKING_TIME' && activeObj.type !== 'EXPLAIN' && activeObj.type !== 'MN_MODULE' && activeObj.type !== 'MN_COMPANY' && activeObj.type !== 'MN_WORK_NO' && activeObj.type !== 'MN_TEL' && activeObj.type !== 'MN_CONTACT_PERSON'">
					<div class="set-field" >
						<div class="title">系统账号是否可查看</div>
						<el-switch
						v-model="activeObj.visible.enabled"
						:width="42"
						inactive-color="#E2E2E2"
						active-color="#366AFF"
						active-icon-class="el-icon-check"
						inactive-icon-class="el-icon-close"> 
						</el-switch>
					</div>
					<div style="color:#A9B3C6;font-size:12px;margin-top:4px;">系统账号可查看受理人详细情况</div>
					<div class="variable-field">
						<div class="title">显示格式</div>
						<div class="ckeditor-box">
							<ckeditor
								:disabled="activeObj.type === 'MAX_NUM_OF_ORDERS_PROCESSED'"
								:editor="ckeditor.editor"
								v-model="activeObj.visible.extInfo.displayFormat"
								:config="editorConfig"
								@focus="(zh,editor)=>currentEditor=editor"
								@ready="(editor)=>currentEditor=editor"
								@input="changeCkeditor">
							</ckeditor>
							<div :class="['variable-box',activeObj.extInfo.variableDisabled ? 'variable-disabled' : '']" @click="addvariable" v-if="activeObj.type !== 'MAX_NUM_OF_ORDERS_PROCESSED'"> <i class="iconfont guoran-tongyichicun-bianliang"></i> </div>
						</div>
					</div>
				</template>
				<div v-else class="THIRD_PARTY_ACCOUNT-box">
					<template v-if="activeObj.type !== 'EXPLAIN'">
						<img src="../../../../assets/images/null.png" alt="">
						<div>该字段无需配置</div>
					</template>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
import inputConfig from './rightConfig/inputConfig.vue'
import RadioAddSelect from './rightConfig/radioAddSelect.vue'
import DateAddUpload from './rightConfig/dateAddUpload.vue'
import bottomCommon from './common/bottomCommon.vue';
import CKEDITOR from "ckeditor";
import { MyCustomUploadAdapterPlugin } from "../../../../assets/js/ckeditor";
export default {
	props: ['activeObj', 'fieldData', 'centerlist','allColumn'],
	data() {
		return {
			showSetting: true,
			currentEditor:null,
            ckeditor: {
                editor: CKEDITOR.ClassicEditor,
            },
            editorConfig: {
                extraPlugins: [MyCustomUploadAdapterPlugin],
                toolbar: [],
                askPluginListener: [{
                    event: "PLACEHOLDER",
                    process: (data, callback) => {
                    console.debug('editor call back', data, callback)
                    },
                }], // 注册监听
            },
		}
	},
	components: {
		inputConfig,
		RadioAddSelect,
		DateAddUpload,
		bottomCommon
	},
	watch:{
		activeObjCopy:{
			handler(newValue,oldValue){
				if(oldValue && oldValue.id === newValue.id){
					this.activeObj.visible.extInfo.displayFormat = this.activeObj.visible.extInfo.displayFormat.replaceAll(oldValue.name,newValue.name)
				}
				this.changeCkeditor();
			},
			deep:true,
			immediate:true
		}
	},
	computed: {
		relationFieldList() {
			return this.centerlist.map(field => {
				return {
				field: field
				}
			})
		},
		hasDesRelationieldList(){
			let arr = [];
			if (this.allColumn){
				arr = this.allColumn.map(field=>{
				return {
					field: field
				}
				})
			}
			console.debug('hasDesRelationieldList ',arr)
			return arr
		},
		activeObjCopy(){
			return JSON.parse(JSON.stringify(this.activeObj))
		}
	},
	mounted() {
		console.log();
	},
	methods: {
		changeCkeditor(){
			if(this.activeObj.visible.extInfo.displayFormat.indexOf('ask-component-placeholder') === -1){
				this.activeObj.extInfo.variableDisabled = false;
			} else {
				this.activeObj.extInfo.variableDisabled = true;
			}
		},
		addvariable(){
			console.log(this.fieldData,'fieldData');
			if(this.activeObj.extInfo.variableDisabled){
				this.$message.warning('只能插入一个变量');
				return false;
			}
			// 光标移到最后
			// this.currentEditor.model.change((writer) => {
			// 	writer.setSelection( writer.createPositionAt(this.currentEditor.model.document.getRoot(), 'end' ) ); 
			// });
			let command = this.currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    // name: this.fieldData.isSystemField ? this.fieldName(this.activeObj.type) : '标题',
					name: this.activeObj.name,
                    data:this.activeObj.id,
                },
            });
		},
		fieldName(type) {
			switch (type) {
				case 'COLUMN':
				return '分栏'
				// 基础控件
				case 'INPUT':
				return '单行输入框'
				case 'TEXTAREA':
				return '多行输入框'
				case 'RICHTEXT':
				return '富文本'
				case 'RADIO':
				return '单选'
				case 'CHECKBOX':
				return '多选'
				case 'TIME_PICKER':
				return '时间日期'
				case 'TIME_PICKER_RANGE':
				return '时间日期范围'
				case 'SELECT':
				return '下拉'
				case 'CASCADER':
				return '级联'
				case 'IMAGE_SELECT':
				return '图片选择'
				case 'IMAGE':
				return '图片'
				case 'VIDEO':
				return '视频'
				case 'ATTACHMENT':
				return '附件'
				case 'EXPLAIN':
				return '说明文字'
				// 高级控件
				case 'FORMULA':
				return '公式'
				case 'REF':
				return '引用字段'
				case 'COPY':
				return '复制字段'
				case 'REF_TEMPLATE':
				return '关联数据模板'
				case 'ORG':
				return '组织架构'
				case 'USER':
				return '用户'
				case 'ACCOUNT':
				return '系统账号'
				case 'PROVINCE':
				return '省份'
				case 'CITY':
				return '城市'
				case 'INDUSTRY':
				return '行业'
				// 系统控件
				case 'WORK_ORDER_STATUS':
				return '工单状态'
				case 'PRIORITY':
				return '优先级'
				case 'ASSIGNEE':
				return '受理人'
				case 'FOLLOWER':
				return '关注人'
				case 'REPORTER':
				return '报单人'
				case 'EMAIL':
				return '邮箱'
				case 'THIRD_PARTY_ACCOUNT':
				return '绑定第三方账号'
				case 'USER_NAME':
				return '账号使用者姓名'
				case 'ROLE':
				return '账号类型'
				case 'ACCOUNT_GROUP':
				return '所属账号组'
				case 'SUPERIOR':
				return '上级'
				case 'DATA_PERMISSIONS':
				return '数据权限'
				case 'TYPE_MATE':
				return '擅长处理的工单类型'
				case 'WORKING_TIME':
				return '工作时间'
				case 'MAX_NUM_OF_ORDERS_PROCESSED':
				return '最大同时处理工单数'
				case 'MN_MODULE':
				return '模块/系统'
				case 'MN_COMPANY':
				return '公司'
				case 'MN_WORK_NO':
				return '工号'
				case 'MN_TEL':
				return '电话'
				case 'MN_CONTACT_PERSON':
				return '对接人'
			}
		}
	}
}
</script>
<style scoped lang="less">
.fieldSettingRight {
	.closeLeft {
		width: 46px;
		height: 46px;
		background: #f5f7fb;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #a4b2cf;
		cursor: pointer;

		i {
		font-size: 18px;
		padding-right: 0px;
		color: #366aff;
		}
	}
	.config-right {
		flex: none;
		height: calc(100vh - 70px);
		background-color: #ffffff;
		border-radius: 4px;
		box-shadow: 0px 0px 8px 0px rgba(29, 55, 129, 0.17);
		border: 1px solid #ebeef5;
		overflow-y: auto;
		overflow-x: hidden;

		.config-title {
			height: 48px;
			background: #f5f7fb;
			font-size: 16px;
			font-weight: 400;
			color: #000000;
			line-height: 38px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		.config-name {
			display: flex;
			align-items: center;
			width: calc(100% - 50px);
			justify-content: center;
		}
		.sign {
			display: inline-block;
			width: 6px;
			height: 6px;
			background: #366aff;
			border-radius: 50%;
			margin-right: 10px;
		}
		}

		.config-content {
			padding: 20px;
			
			.set-field {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color:#616161 ;
				height: 38px;
				background: #FBFCFD;
				line-height: 38px;
				::v-deep .el-switch {
					.el-switch__core:after {
						width: 14px;
						height: 14px;
						left: 5px;
						top: 2px;
					}
					.el-switch__label--left {
						i {
							color: #6e6b7b;
							font-weight: 800;
						}
						position: absolute;
						left: 25px;
						top: 0px;
						z-index: 2;
					}
					.el-icon-check {
						color: #fff;
						font-weight: 800;
						position: absolute;
					}

					&.is-checked {
						.el-switch__core:after {
							left: 40px;
							top: 2px;
						}
						.el-icon-check {
							position: absolute;
							left: 5px;
							top: 3px;
							z-index: 2;
							color: #fff;
							font-weight: 800;
						}
						.el-icon-close {
							color: #fff;
						}
					}
				}
			}
			.variable-field{
				margin-top: 20px;
				.ckeditor-box{
					margin-top: 10px;
					position: relative;
					/deep/.ck-editor{
						.ck-editor__top{
							display: none;
						}
						.ck-editor__editable {
							height: 30px;
							overflow: hidden;
							padding: 0 5px;
							p{
								margin: 0;
								line-height: 30px;
							}
							.ask-component-placeholder {
								background-color: #366aff;
								display: inline-block;
								// padding: 2px 5px;
								height: 24px;
								line-height: 24px;
								padding: 0 4px;
								border-radius: 3px;
								margin-right: 4px;
								// margin-left: 2px;
								margin-bottom: 8px;
								color: #fff;
								font-size: 12px;
							}
						}
						.ck-read-only{
							background-color: #f5f7fa;
							border-color: #e4e7ed;
						}
					}
					.variable-box{
						padding: 4px;
						background:#edf2ff;
						color: #366aff;
						width: 18px;
						height: 18px;
						border-radius: 5px;
						cursor: pointer;
						position: absolute;
						right: 4px;
						top: 4px;
						&.variable-disabled{
							background-color: #DCDFE6;
							color: #fff;
							cursor: not-allowed;
						}
					}
				}
			}
			.THIRD_PARTY_ACCOUNT-box{
				text-align: center;
				color: #A9B3C6;
				img{
					width: 300px;
					margin-bottom: 10px;
				}
			}
			/deep/.el-form--label-top{
                .el-form-item{
					margin-bottom: 20px;
                    .el-form-item__label{
                        line-height: 20px;
                        color: #616161;
						padding: 0 0 6px;
                    }
                }
            }
			
			
		}

		.content-right-content-c {
		height: calc(100vh - 70px);
		/* padding: 12px;*/
		background-color: #ffffff;
		border-radius: 4px;
		box-shadow: -5px 0 10px -5px #d9d9d9;
		border: 1px solid #ebeef5;
		overflow-y: scroll;
		overflow-x: hidden;
		background-image: url('../../../../assets/images/null.png');
		background-repeat: no-repeat;
		background-size: 300px 300px;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #aaaaaa;

		.content-right-content-c-text {
			margin-top: 300px;
		}
		}
	}
	.settingBar {
		position: absolute;
		right: 0;
		top: 40px;
		width: 21px;
		height: 53px;
		background: #ffffff;
		box-shadow: 0px 0px 8px 0px rgba(29, 55, 129, 0.17);
		border-radius: 10px 0 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		i {
		color: #366aff;
		font-size: 16px;
		font-weight: 600;
		}
	}
}
.showRight {
  width: 100%;
  transition: all 0.3s;
}
.hideRight {
  width: 0 !important;
  transition: all 0.3s;
}
</style>