<template>
    <div id="staffManage">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i
                        ></span>
                        <span>{{$t('common.enterpriseEdition')}} - </span>
                        <span>{{$t('staffManage.title')}}</span>
                        <div class="select-main"></div>
                    </div>
                    <div
                        class="setup-header-right-l"
                        slot="setup-header-right-l"
                    >
                        <div class="sd-main-list">
                            <el-select
                                v-model="mainValue"
                                size="small"
                                @change="changeMainValue"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in mainOptions"
                                    :key="item.corpId"
                                    :label="item.corpName"
                                    :value="item.corpId"
                                >
                                    <img
                                        height="12px"
                                        v-if="item.corpType == 0"
                                        src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png"
                                        alt=""
                                        srcset=""
                                    />
                                    <img
                                        height="12px"
                                        v-if="item.corpType == 3"
                                        src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg"
                                        alt=""
                                        srcset=""
                                    />
                                    <span
                                        style="
                                            display: inline-block;
                                            margin-left: 6px;
                                            color: #8492a6;
                                            font-size: 13px;
                                        "
                                        >{{ item.corpName }}</span
                                    >
                                </el-option>
                            </el-select>
                        </div>
                        <el-button
                            size="small"
                            type="text"
                            v-if="false"
                            @click="openUpdateConfig"
                            >{{$t('staffManage.synchronizationInfo')}}</el-button
                        >
                        <el-button
                            size="small"
                            @click="asynStaff"
                            type="primary"
                            :loading="asynStaffLoading"
                            v-if="
                                false &&
                                operableStaff('PORTAL_MANAGEMENT_MEMBER_SYNC')
                            "
                            >{{$t('staffManage.synchronizationInfo')}}
                        </el-button>
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <div class="content-box">
                        <div class="sd-list-header">
                            <div :style="{width: treeWidth + 'px'}" class="sd-list-header-tab">
                                <span
                                    @click="changeActiveTab(0)"
                                    :class="[
                                        'sd-list-header-tab-cell',
                                        activeTab == 0 &&
                                            'sd-list-header-tab-cell-active',
                                    ]"
                                >
                                    <i
                                        class="
                                            iconfont
                                            guoran-tongyichicun-geren
                                        "
                                    ></i
                                    >{{$t('staffManage.member')}}</span
                                ><span
                                    @click="changeActiveTab(1)"
                                    :class="[
                                        'sd-list-header-tab-cell',
                                        activeTab == 1 &&
                                            'sd-list-header-tab-cell-active',
                                    ]"
                                    ><i
                                        class="
                                            iconfont
                                            guoran-tongyichicun-guanlibumen
                                        "
                                    ></i
                                    >{{$t('staffManage.department')}}</span
                                >
                                <span
                                    @click="changeActiveTab(2)"
                                    :class="[
                                        'sd-list-header-tab-cell',
                                        activeTab == 2 &&
                                            'sd-list-header-tab-cell-active',
                                    ]"
                                    ><i
                                        class="
                                            iconfont
                                            guoran-tongyichicun-biaoqian
                                        "
                                    ></i
                                    >{{$t('staffManage.label')}}</span
                                >
                            </div>
                            <span class="sd-search-line">|</span>
                            <div class="sd-list-search">
                                <el-input
                                    v-model="keyword"
                                    :placeholder="placeholderText"
                                    prefix-icon="el-icon-search"
                                    @keyup.enter.native="searchOrder(keyword)"
                                    size="small"
                                    style="width: 70%"
                                ></el-input>
                            </div>
                            <div v-if="activeTab != 2 && showInvite" class="sd-set-role">
                                <el-button
                                    size="small"
                                    plain
                                    icon="iconfont guoran-shezhiquanxian"
                                    @click="batchSetTags"
                                >
                                    {{$t('staffManage.setLabel')}}
                                </el-button>
                                <div class="invite-enter-enterprise-btn" @click="goInviteEnter">
                                    <i class="iconfont guoran-tongyichicun-piliangyaoqing"></i>
                                    <span>{{$t('staffManage.inviteToJoin')}}</span>
                                    <el-tooltip class="item" effect="dark" :content="$t('staffManage.inviteToJoinTip')" placement="top">
                                        <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"></i>
                                    </el-tooltip>
                                </div>
                                <el-button
                                    size="small"
                                    plain
                                    icon="iconfont guoran-shezhiquanxian"
                                    @click="clickClassHandle"
                                >
                                    {{$t('staffManage.batchSetSystemPermissions')}}
                                </el-button>
                                <el-button
                                    @click="addMember"
                                    size="small"
                                    v-if="corpType == 21"
                                    type="primary"
                                    icon="iconfont guoran-tongyichicun-16-13-xinjian">
                                    {{$t('staffManage.addMembers')}}
                                </el-button>
                            </div>
                            <div v-else class="sd-set-role">
                                <div class="invite-enter-enterprise-btn" v-if="false" @click="tagSync">
                                    <i class="iconfont guoran-tongbu"></i>
                                    <span>{{$t('staffManage.synchronization')}}</span>
                                </div>
                                <el-button
                                    size="small"
                                    plain
                                    icon="iconfont guoran-tuichu1"
                                    @click="moveUserFromLable"
                                >
                                  {{$t('staffManage.remove')}}
                                </el-button>
                                <div class="invite-enter-enterprise-btn" @click="addUserToLable">
                                    <i class="iconfont guoran-tongyichicun-piliangyaoqing"></i>
                                    <span>{{$t('staffManage.addMemberOrDept')}}</span>
                                </div>
                             </div>
                        </div>
                        <div class="sd-list-content">
                            <staff-list
                                ref="staffList"
                                v-if="activeTab == 0"
                                :corpId="mainValue"
                                :corpType="corpType"
                                :tagsList="tagsList"
                                :authType="authType"
                                @changeWidth="changeWidth"
                                @switchDept="switchDept"
                                @editStaff="editStaff"
                                @updateDeptTree="updateDeptTree"
                                @openTagsDialog="openTagsDialog"
                            ></staff-list>
                            <depart-list
                                ref="departList"
                                :corpType="corpType"
                                :authType="authType"
                                v-if="activeTab == 1"
                                :tagsList="tagsList"
                                :corpId="mainValue"
                                @openTagsDialog="openTagsDialog"
                            ></depart-list>
                            <lable-manage
                                ref="labelManage"
                                v-if="activeTab == 2"
                                :corpId="mainValue"
                                :keyword="keyword"
                                :corpType="corpType"
                                :tagsList="tagsList"
                                :authType="authType"
                                @getTagList="getTagList"
                            >
                            </lable-manage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <SideNav ref="leftMenuObj"></SideNav>
        <addMember 
            ref="addMember"
            :activeDeptId="activeDeptId"
			:visible.sync="addMemberVisible"
            :memberData="memberData"
            :corpType="corpType"
            :treeData="treeData"
            :tagsList="tagsList"
            :corpId="mainValue"
            @addMemberSuccess="addMemberSuccess">
		</addMember>

        <popup v-if="setTagDialog" @closeEvent="resetbatchTag">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.batchLabelSetting')}}</div>
            <div slot="popup-tip">为{{ activeTab === 0 ? $t('staffManage.staff') : $t('staffManage.department') }} {{$t('staffManage.setLabel')}}</div>
            <div slot="popup-con">
                <div class="set-tags-content">
                    <div class="ssu-cell">
                        <div
                            v-if="JSON.parse(batchTag.selectedList).length === 1"
                            class="ssu-cell-title"
                        >
                            {{$t('staffManage.selected')}}{{ batchTag.handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department') }}:
                            <span
                                v-if="corpType == 0 && JSON.parse(batchTag.selectedList)[0].name.substr(0, 4) === 'woWe'"
                                class="selected-cell-tag">
                                <open-data
                                    :type="batchTag.handleType === 'staff' ? 'userName' : 'departmentName'"
                                    :openid="JSON.parse(batchTag.selectedList)[0].name || JSON.parse(batchTag.selectedList)[0].label">
                                </open-data>
                            </span>
                            <span v-else class="selected-cell-tag">{{
                                JSON.parse(batchTag.selectedList)[0].name || JSON.parse(batchTag.selectedList)[0].label
                            }}</span>
                        </div>
                        <div
                            v-if="JSON.parse(batchTag.selectedList).length > 1"
                            class="ssu-cell-title"
                        >
                            已选{{ batchTag.handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department') }}
                        </div>
                        <div
                            v-if="JSON.parse(batchTag.selectedList).length > 1"
                            class="ssu-cell-content ssu-cell-content-tag"
                        >
                            <span
                                v-for="(item, iIndex) in JSON.parse(batchTag.selectedList).slice(0,10)"
                                :key="iIndex"
                                class="selected-cell-tag">
                                <span
                                    v-if="corpType == 0 && (item.name && item.name.substr(0, 4) === 'woWe')"
                                    class="selected-cell-tag">
                                    <open-data
                                        :type="batchTag.handleType === 'staff' ? 'userName' : 'departmentName'"
                                        :openid="item.name || item.label">
                                    </open-data>
                                </span>
                                <span v-else class="selected-cell-tag">{{
                                    item.name || item.label
                                }}</span></span
                            >
                            <span>
                                <span v-if="JSON.parse(batchTag.selectedList).length > 10"> {{$t('staffManage.etc')}}</span
                                >{{ JSON.parse(batchTag.selectedList).length }}个{{
                                    batchTag.handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department')
                                }}</span
                            >
                        </div>
                    </div>
                    <p>{{$t('staffManage.setLabel')}}</p>
                    <el-select 
                        v-model="batchTag.tagsValue" 
                        multiple 
                        collapse-tags
                        size="smart"
                        style="width:100%"
                    >
						<el-option
							v-for="item in tagsList"
							:key="item.id"
							:label="item.label"
                            :disabled="item.source == 1"
							:value="~~item.id">
						</el-option>
					</el-select>
                    <p>
                        {{$t('staffManage.labelPresent')}}
                    </p>
                    <div class="set-tags-radio">
                        <el-radio-group v-model="batchTag.type">
                            <el-radio label="ADD">{{$t('staffManage.incrementalIncrease')}}</el-radio>
                            <el-radio label="COVER">{{$t('staffManage.overwriteExisting')}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="resetbatchTag"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="saveTagDialog"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
import SideNav from "./../../menu/SideNav.vue";
import TopHeader from "./../../../components/top-header.vue";
import DeleteDialog from "./../../../components/deleteDialogue.vue";
import pagination from "./../../../components/pagination.vue";
import ManangeNav from "./../../menu/ManageNave.vue";
import DepartList from "./components/DepartListU.vue";
import StaffList from "./components/StaffListU.vue";
import { operable } from "../js/operable";
import { redirectUri } from "../../../const/redirectUri";
import inviteEnterEnterprise from '../inviteEnterEnterprise/inviteEnterEnterprise.vue'
import addMember from './components/addMember.vue'
import { isTB } from './utils/customerMainId'
import OpenData from "@/components/openData";
import LableManage from './components/LabelManage.vue'
export default {
    name: "staffManage",
    components: {
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        pagination,
        ManangeNav,
        DepartList,
        StaffList,
        inviteEnterEnterprise,
        addMember,
        OpenData,
        LableManage
    },
    data() {
        return {
            treeWidth: 340,
            mainValue: "", // 当前主体id
            corpType: "0",
            mainOptions: [], // 主体列表
            // 同步
            asynStaffLoading: false,
            mainId: "",
            tableData: [],
            currentPage: 1,
            totalNumber: 15,
            // activeDeptId: "",
            channel: "WE_WORK",
            // update
            placeholderText: '',
            activeTab: 0, // 0 成员 1 部门

            keyword: "",
            isSeaching: false,
            activeDeptId:null,
            activeDeptName:'',
            isWechat:false,
            showInvite:false,
            addMemberVisible:false,
            memberData:null,
            treeData:[],
            authType:null,
            tagsList:[],
            batchTag: {
                memberIds:[],
                departIds:[],
                tips:'',
                tagsValue:[],
                type: 'ADD',
                selectedList:[],
                handleType:'',
            },
            setTagDialog:false,
            isTB:false
        };
    },
    methods: {
        tagSync(){
            this.$message.success(this.$t('staffManage.syncMsg'));
            this.$http.get(this.requestUrl.lable.tagSync + "1b2774c24aaa44de9616340c6efe4802").then(res =>{
                console.log(res);
            })
        },
        moveUserFromLable(){
            this.$refs.labelManage.moveUserFromLable();
        },
        addUserToLable(){
            this.$refs.labelManage.addUserToLable();
        },

        getTagList(callback) {
            this.$http.get(this.requestUrl.lable.getTagList + '?corpId=' + this.mainValue).then(res =>{
                console.log(427, res);
                if(res.data.code == 0) {
                    res.data.data.forEach(item => {
                        item.label = item.name;
                    })
                    this.tagsList = res.data.data;
                    if (callback) {
                        callback()
                    }
                }
            })
        },
        updateDeptTree(){
            if(this.activeTab == 1){
                this.treeData = this.$refs.departList.treeData;
            } else {
                this.treeData = this.$refs.staffList.treeData;
            }
        },
        addMember(){
            this.memberData={}
            this.addMemberVisible = true;
        },
        goInviteEnter(){
            this.$router.push({
                name:"inviteEnterEnterprise",
                query:{
                    corpId:this.mainValue,
                    departmentId:this.activeDeptId,
                    departmentName:this.activeDeptName,
                    isWechat:this.isWechat
                }
            })
        },
        switchDept(data){
            this.activeDeptId = data.id;
            this.activeDeptName = data.label;
            this.isWechat = data.isWechat
        },
        goInviteEnterEnterprise(){
            this.$http.get(this.requestUrl.invite.checkUserCanInvite)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if(res.data){
                            this.showInvite = true;
                        } else {
                            this.showInvite = false;
                            // this.$message.warning('拥有员工管理权限的管理员可以发起邀请');
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            
        },
        changeWidth(val){
            console.log(207, val)
            // this.treeWidth = val;
        },
        searchOrder() {
            if (this.keyword.trim() === "") {
                this.isSeaching = false;
            } else {
                this.isSeaching = true;
            }
            if (this.activeTab == 0) {
                this.$refs.staffList.search(this.keyword, this.mainValue);
            }
            if (this.activeTab == 1) {
                this.$refs.departList.search(this.keyword, this.mainValue);
            }
            if (this.activeTab == 2) {
                this.$refs.labelManage.searchLable(this.keyword, this.mainValue);
            }
        },
        clickClassHandle() {
            if (this.activeTab === 0) {
                this.$refs.staffList.setSysAcc(this.mainValue);
            }
            if (this.activeTab === 1) {
                this.$refs.departList.setSysAcc(this.mainValue);
            }
        },
        batchSetTags() {
            if (this.activeTab === 0) {
                this.$refs.staffList.batchSetTags(this.mainValue);
            }
            if (this.activeTab === 1) {
                this.$refs.departList.batchSetDeptTags(this.mainValue);
            }
        },
        openTagsDialog(list) {
            this.resetbatchTag()
            if (this.activeTab === 0) {
                this.batchTag.selectedList = JSON.stringify(list)
                this.batchTag.memberIds = list.map(item =>{ return item.id })
                this.setTagDialog = true
                this.batchTag.handleType = "staff"
            }
            if(this.activeTab === 1) {
                console.log(list,'listlistlistlist');
                this.batchTag.handleType = "department"
                if(list.length != 0) {
                    list.forEach((dept, index) =>{
                        this.batchTag.departIds.push(dept.id)
                    })
                    this.batchTag.selectedList = JSON.stringify(list)
                    this.setTagDialog = true
                } else {
                    this.$message.warning(this.$t('staffManage.selectDept'))
                  this.$t('staffManage.searchMember')
                }
            }
        },
        changeActiveTab(tabVal) {
            this.activeTab = tabVal;
            this.$nextTick(() =>{
                if (this.activeTab == 0 && this.$refs.staffList) {
                    this.$refs.staffList.getdepartmentTree(this.mainValue, false,true);
                    this.placeholderText = this.$t('staffManage.searchMember');
                    setTimeout(() => {
                        this.$refs.staffList.treeWidth = this.$refs.staffList.$refs.slleftreeoter.offsetWidth;
                    }, 50)
                } else if (this.activeTab == 1 && this.$refs.departList) {
                    // 在这里检查 this.$refs.departList 是否存在
                    // 然后再执行相应的逻辑
                    this.$refs.departList.getdepartmentTree(this.mainValue, false, true);
                    this.placeholderText = this.$t('staffManage.searchDept');
                } else {
                    this.placeholderText = this.$t('staffManage.labelSearch');
                }
            })
        },

        changeMainValue(val) {
            this.mainValue = val;
            sessionStorage.setItem('corpId',this.mainValue)
            this.mainOptions.forEach((item) => {
                if (item.corpId == val) {
                    this.corpType = item.corpType;
                    this.authType = item.authType;
                    if (this.corpType == 0) {
                        this.channel = "WE_WORK";
                    }
                    if (this.corpType == 3) {
                        this.channel = "DING_TALK";
                    }
                    this.$refs.addMember.editDisabled = this.corpType == 2 ? false : true;
                }
            });
            this.isSeaching = false;
            this.keyword = "";
            this.getTagList(() => {
                this.$nextTick(() => {
                    if (this.activeTab == 0) {
                        this.$refs.staffList.getdepartmentTree(val, true,true);
                    } else if(this.activeTab == 1) {
                        this.$refs.departList.getdepartmentTree(val, true,true);
                    } else {
                        this.$refs.labelManage.getLabelList("changeMainValue");
                    }
                });
            });

        },
        // 获取主体下的公司
        getCorps4main() {
            this.$http
                .get(this.requestUrl.accountManage.corps4main)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.mainOptions = res.data.data;
                        if (this.mainOptions.length != 0) {
                            
                            this.mainValue = this.mainOptions[0].corpId;
                            this.corpType = this.mainOptions[0].corpType;
                            this.authType = this.mainOptions[0].authType;
                            this.$refs.addMember.editDisabled = this.corpType == 21 ? false : true;
                            sessionStorage.setItem('corpId',this.mainValue)
                            // this.corpType = 21;
                            if (this.corpType == 0) {
                                this.channel = "WE_WORK";
                            }
                            if (this.corpType == 3) {
                                this.channel = "DING_TALK";
                            }
                            this.$nextTick(() => {
                                if (this.activeTab == 0) {
                                    this.$refs.staffList.getdepartmentTree(
                                        this.mainValue,
                                        null,
                                        true
                                    );
                                    this.$refs.staffList.showOuterLoading(true);
                                } else {
                                    this.$refs.departList.getdepartmentTree(
                                        this.mainValue,
                                        null,
                                        true
                                    );
                                }
                                this.getTagList()
                            });
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 同步员工信息
        asynStaff() {
            this.asynStaffLoading = true;
            this.$http
                .get(
                    this.requestUrl.department.initDepartment +
                        "?corpId=" +
                        this.mainValue
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.$message.success(
                            "已完成同步，请刷新浏览器查看最新员工信息"
                        );
                        this.asynStaffLoading = false;
                    } else {
                        this.asynStaffLoading = false;
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 分页改变数量
        handleCurrentChange(e) {
            this.currentPage = e;
            console.log(this.currentPage);
            this.getMemberListByDeptId({
                id: this.activeDeptId,
                currentPage: this.currentPage,
            });
        },
        getMemberListByDeptId(data) {
            if (data.id == null) {
                this.tableData = [];
                this.totalNumber = 0;
            } else {
                this.activeDeptId = data.id;
                this.$http
                    .get(
                        this.requestUrl.member.getMemberListByDeptId +
                            "/" +
                            data.id +
                            "?page=" +
                            data.currentPage +
                            "&size=15"
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.tableData = res.data.data.list;
                            this.totalNumber = res.data.data.total;
                            // WWOpenData.bind(this.$el);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        addMemberSuccess(){
            console.log(this.activeTab);
            // this.$refs.addMember.visible = false;
            this.addMemberVisible = false;
            if(this.activeTab == 0){
                if(this.isSeaching){
                    this.$refs.staffList.search( this.$refs.staffList.keyword,this.mainValue)
                } else {
                    this.$refs.staffList.getMemberListByDeptId({
                        id:this.$refs.staffList.activeDeptId,
                        currentPage:this.$refs.staffList.currentPage
                    });
                }
                
            } 
        },
        editStaff(data){
            this.memberData = data;
            this.addMemberVisible = true;
        },
        saveTagDialog() {
            if(this.activeTab === 0) {
                let param = {
                    memberIds:this.batchTag.memberIds,
                    tags: this.batchTag.tagsValue,
                    addType: this.batchTag.type
                }
                this.$http.post("/api/member/batchSetTags?corpId=" + this.mainValue, param).then(res =>{
                    if(res.data.code == '0') {
                        this.$message.success(this.$t('staffManage.saveSuccess'))
                        this.$refs.staffList.search(this.keyword, this.mainValue);
                        this.resetbatchTag()
                    }
                })
            } else {
                this.$http.post("/api/setDepartmentTag", {
                    departmentId: this.batchTag.departIds,
                    tags:this.batchTag.tagsValue,
                    mainId:localStorage.getItem("_mainId"),
                    corpId: this.mainValue,
                    addType: this.batchTag.type
                }).then(res =>{
                    if(res.data.code == "0") {
                        this.$message.success(this.$t('staffManage.setSuccess'))
                        this.$refs.departList.reloadData()
                        this.resetbatchTag()
                    }
                })
            }
        },
        resetbatchTag() {
            this.setTagDialog = false
            this.$set(this, 'batchTag', {
                memberIds:[],
                departIds:[],
                tips:'',
                tagsValue:[],
                type: 'ADD',
                selectedList:[],
                handleType:'',
            })
        }
    },
    watch:{
        activeTab:{
            handler(n){
                setTimeout(() => {
                    // // 0 成员 1 部门 2 标签
                    if(n == 1){
                        this.treeData = this.$refs.departList.treeData;
                    } else if(n == 0) {
                        this.treeData =  this.$refs.staffList.treeData;
                    }
                },1000)
            },
            immediate:true
        }
    },
    filters: {
        findNameById: function (id, list) {
            let name = "";

            function f(arr) {
                arr.forEach((item, index) => {
                    if (item.id == id) {
                        name = item.label;
                        return;
                    } else {
                        if (item.children && item.children.length > 0) {
                            f(item.children);
                        }
                    }
                });
            }

            f(list);
            return name;
        },
    },
    computed: {
        operableStaff() {
            return function (type) {
                return operable(type);
            };
        },
    },
    mounted() {
        console.log(isTB(),'1213123');
        this.rolePrivilegesList = this.getRolePrivileges();
        this.mainId = localStorage.getItem("_mainId");
        this.getCorps4main();
        this.goInviteEnterEnterprise();
        this.isTB = isTB()
        this.placeholderText = this.$t('staffManage.searchMember')
    },
};
</script>
<style lang="less">
@import "./../../../assets/less/staffManage/StaffDepartment.less";
#staffManage{
    .sd-list-content {
        // overflow-x: overlay;
        overflow-y: hidden;
    }
    .configuration {
        text-align: left;

        .el-form-item__label {
            line-height: 20px;
        }

        .el-form-item {
            margin-bottom: 14px;
        }
    }

    .configuration-title {
        text-align: left;
        margin: 15px 0;
    }

    .isDisabledTitle {
        margin-top: 40px;
    }

    .isCopyTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
            margin-left: 10px;
            width: 20px;
            flex: none;
            font-size: 18px;
            color: #366AFF;
            cursor: pointer;
        }
    }

    .steps-content {
        .desc {
            text-align: left;
            margin: 20px 0;
        }

        .apps {
            text-align: left;
            margin: 20px 0;

            .sub-title {
                margin: 10px 0;
            }
        }
    }

    .disable-color {
        color: tomato;
    }

    /deep/ .el-tabs__header {
        height: 60px !important;
        line-height: 60px !important;
    }

    /deep/ .el-tabs__item {
        height: 60px !important;
        line-height: 60px !important;
        font-size: 16px;
    }
    .dingTalkTip {
        .tip {
            padding: 4px 0;
        }
    }
    .set-tags-content {
        text-align: left;
        .ssu-cell {
            .ssu-cell-title {
                height: 38px;
                font-size: 14px;
                color: #616161;
                line-height: 38px;
                .selected-cell-tag {
                    margin-left: 12px;
                    display: inline-block;
                    height: 23px;
                    line-height: 23px;
                    padding: 0 8px;
                    text-align: center;
                    background: #ecf1ff;
                    color: #366aff;
                    font-weight: 600;
                    border-radius: 3px;
                }
            }
            .ssu-cell-content-tag {
                padding: 8px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                >.selected-cell-tag {
                    margin: 3px;
                }
                .selected-cell-tag {
                    margin-right: 8px;
                    display: inline-block;
                    height: 23px;
                    line-height: 23px;
                    padding: 0 8px;
                    text-align: center;
                    background: #ecf1ff;
                    color: #366aff;
                    font-weight: 600;
                    border-radius: 3px;
                    white-space: nowrap;
                }
            }
            .ssu-cell-content {
                .ssu-cell-content-sys-info {
                    margin: 16px 0;
                    .sccsi-active {
                        display: flex;
                        justify-content: flex-start;
                        .sccsi-active-pre {
                            flex: none;
                            width: 21px;
                            height: 21px;
                            line-height: 21px;
                            text-align: center;
                            font-size: 12px;
                            color: white;
                            border-radius: 50%;
                        }

                        .sccsi-active-pre-o {
                            background: #09ddd5;
                        }

                        .sccsi-active-pre-w {
                            background: #87a6ff;
                        }
                        .sccsi-active-name {
                            height: 21px;
                            line-height: 21px;
                            font-size: 13px;
                            font-weight: 400;
                            color: #a9b3c6;
                            margin-left: 8px;
                        }
                        .sccsi-active-num {
                            margin-left: 8px;
                            margin-right: 8px;
                        }

                        .sccsi-active-more {
                            height: 21px;
                            line-height: 21px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #fe5965;
                            background-color: #ffebec;
                            padding: 0 6px;
                        }
                    }
                    .sccsi-line {
                        height: 16px;
                        width: 9px;
                        border-right: dashed 1px #e0e6f7;
                    }
                }
                .ssu-cell-content-sys {
                    line-height: 40px;
                    height: 40px;
                    padding: 0 8px;
                    background: #fbfcfd;
                    border-radius: 5px;
                    display: flex;
                    // justify-content: space-between;
                    // justify-content: space-evenly;
                }
            }
        }
        p {
            height: 38px;
            font-size: 14px;
            color: #616161;
            line-height: 38px;  
        }
        .el-select {
            margin-bottom: 15px;
        }
        .set-tags-radio {
            height: 38px;
            font-size: 14px;
            display: flex;
            align-items: center;
            .el-radio__input.is-checked+.el-radio__label {
                color: #366AFF;
            }
            .is-checked {
                .el-radio__inner {
                    border-color: #366AFF;
                    background-color: #366AFF;
                }
            }
            
        }
    }
}


</style>