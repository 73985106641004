<template>
    <div id="addRole">
        <div class="gr-content-container">
            <div class="personalHeader">
                <detail-header @goBack="goBack">
                    <div slot="header-name" class="header-name">
                        <span class="template-name">{{$t('addRole.title')}}</span>
                    </div>
                    <div slot="header-des" class="header-des">
                        {{ templateDes }}
                    </div>
                    <div
                        slot="header-title-c-r-btn"
                        class="header-title-c-r-btn"
                        style="margin-right: 24px"
                    >
                        <el-button size="small" @click="closeAddRole"
                            >{{$t('common.close')}}</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="preserve"
                            v-if="preserveBtn"
                            ><span>{{$t('common.save')}}</span></el-button
                        >
                    </div>
                </detail-header>
            </div>
            <div class="content">
                <div class="content-section">
                    <div class="content-box">
                        <div class="addRole-container-container">
                            <div class="container">
                                <div class="roleName">
                                    <span class="spanItem"
                                        ><span style="color: red">*</span
                                        >{{$t('addRole.name')}}</span
                                    >
                                    <el-input
                                        v-model="roleName"
                                        :placeholder="$t('addRole.namePlaceholder')"
                                        style="width: 60%"
                                        :readonly="IsReadOnly"
                                    ></el-input>
                                </div>
                                <div class="roleDescribe">
                                    <span class="spanItem">{{$t('addRole.roleDes')}}</span>
                                    <el-input
                                        v-model="roleDescribe"
                                        :placeholder="$t('addRole.desPlaceholder')"
                                        style="width: 60%"
                                        :readonly="IsReadOnly"
                                    ></el-input>
                                </div>
                                <div class="roleName accountTemplate">
                                    <span class="spanItem"><span style="color: red">*</span>{{$t('addRole.selectTemplate')}}</span>
                                    <el-select v-model="templateId" :placeholder="$t('addRole.templatePlaceholder')" style="width: 60%">
                                        <el-option
                                            filterable
                                            v-for="item in accountTemplateList"
                                            :key="item.id"
                                            :label="item.name"
									        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="tabCheck">
                                    <el-tabs
                                        v-model="activeName"
                                        @tab-click="handleClick"
                                    >
                                        <!-- && item.id !== 'WORKORDER' -->
                                        <el-tab-pane
                                            :label="item.name"
                                            :name="item.id"
                                            v-for="(item, index) in systemList"
                                            :key="index"
                                            v-if="item.id !== 'IM'"
                                        >
                                            <ask-bot
                                                :privilegeTree="item"
                                                :handleClicked="handleClicked"
                                                :roleType="$route.query.type"
                                                :system="systemList"
                                            ></ask-bot>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import DetailHeader from "./../../../../components/detail-header";
import SideNav from "./../../../menu/SideNav.vue";
import AskBot from "./askBot";
import { operable } from "../../js/operable";

export default {
    name: "addRole",
    data() {
        return {
            addRoleTitle: "新增角色",
            roleDescribe: "",
            roleName: "",
            activeName: "PORTAL",
            roleId: "",
            systemList: [],
            rolesInfo: {},
            handleClicked: 0,
            rolePrivilegesList: [],
            openChecked: false,
            IMChecked: false,
            WorkOrderChecked: false,
            IsReadOnly: false,
            preserveBtn: true,
            accountTemplateList:[], // 账号模版
            templateId:''
        };
    },
    components: { DetailHeader, SideNav, AskBot },
    computed: {
        operableRoleDetail() {
            return function (type) {
                return operable(type);
            };
        },
    },
    watch: {},
    mounted() {
        this.roleId = "";
        if (this.$route.query.type === "add") {
            this.addRoleTitle = "新增角色";
            this.getPrivilegeTree();
        } else if (this.$route.query.type === "edit") {
            this.addRoleTitle = "编辑角色";
        } else this.addRoleTitle = "角色详情";
        if (this.$route.query.id !== undefined) {
            this.roleId = this.$route.query.id;
            this.getRRoleDetail();
        }
        this.rolePrivilegesList = this.getRolePrivileges();
        if (this.$route.query.type === "details") {
            this.IsReadOnly = true;
            this.preserveBtn = false;
        } else if (
            operable("PORTAL_MANAGEMENT_ROLE_UPDATE") &&
            this.$route.query.type === "edit"
        ) {
            this.IsReadOnly = false;
            this.preserveBtn = true;
        } else if (
            operable("PORTAL_MANAGEMENT_ROLE_ADD") &&
            this.$route.query.type === "add"
        ) {
            this.IsReadOnly = false;
            this.preserveBtn = true;
        } else {
            this.IsReadOnly = true;
            this.preserveBtn = false;
        }
        this.getAccountTemplateList();
    },
    methods: {
        getAccountTemplateList(){
            this.$http.get('/api/account-template?page=0&pageSize=100&name=').then(res=>{
                if (res.data.code === '0'){
                    res.data.data.content.forEach(item => {
                        if(item.name === '上下游负责人' && item.defaulted){

                        } else {
                            this.accountTemplateList.push(item)
                        }
                    })
                }
            })
        },
        //获取权限数
        getPrivilegeTree() {
            let url = "/api/privilege/tree";
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.systemList = res.data.data.map(item =>{
                        if(this.isE()) {
                            item.name = item.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                        }
                        if(item.id == 'WORKORDER') {
                            item.children = item.children.filter(child =>{
                                if(this.isE()) {
                                    child.name = child.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                }
                                if(child.children) {
                                    for (let index = 0; index < child.children.length; index++) {
                                        let ele = child.children[index];
                                        if(this.isE()) {
                                            ele.name = ele.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                        }
                                        if (ele.children == 'null' || ele.children === null) {
                                            ele.children = []
                                        }
                                    }
                                }
                                return child.id == 'WORK_ORDER_SYSTEM_NAV'
                            })
                        }
                        if(item.id == "PORTAL") {
                            item.children = item.children.filter(child =>{
                                if(this.isE()) {
                                    child.name = child.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                }
                                if(child.children) {
                                    for (let index = 0; index < child.children.length; index++) {
                                        let ele = child.children[index];
                                        if(this.isE()) {
                                            ele.name = ele.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                        }
                                    }
                                }
                                return child
                            })
                        }
                        return item
                    });
                }
            });
        },
        //获取角色详情
        getRRoleDetail() {
            let url = "api/role/detail/" + this.roleId;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.rolesInfo = res.data.data;
                    this.roleName = res.data.data.name;
                    this.roleDescribe = res.data.data.remark;
                    this.templateId = res.data.data.templateId;
                    if (res.data.data.privileges !== null) {
                        this.systemList = res.data.data.privileges.privileges.map(item =>{
                            console.log(item,'addRoleU');

                            if(this.isE()) {
                                item.name = item.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                            }
                            if(item.id == 'WORKORDER') {
                                item.children = item.children.filter(child =>{
                                    if(this.isE()) {
                                        child.name = child.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                    }
                                    if(child.children) {
                                        for (let index = 0; index < child.children.length; index++) {
                                            let ele = child.children[index];

                                            if(this.isE()) {
                                                ele.name = ele.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                            }
                                            if (ele.children == 'null' || ele.children === null) {
                                                ele.children = []
                                            }
                                        }
                                    }
                                    return child.id == 'WORK_ORDER_SYSTEM_NAV'
                                })
                            }
                            if(item.id == "PORTAL") {
                                item.children = item.children.filter(child =>{
                                    if(this.isE()) {
                                        child.name = child.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                    }
                                    if(child.children) {
                                        for (let index = 0; index < child.children.length; index++) {
                                            let ele = child.children[index];
                                            if(this.isE()) {
                                                ele.name = ele.name.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理')
                                            }
                                        }
                                    }
                                    return child
                                })
                            }
                            return item
                        });
                    }
                }
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        handleClick(value) {
            this.handleClicked += 1;
        },
        closeAddRole() {
            this.$router.go(-1);
        },
        choose(value) {
            let openChecked = false;
            let IMChecked = false;
            let WorkOrderChecked = false;
            value.forEach((item) => {
                if (item.id === "OPEN") {
                    if (item.checked) {
                        openChecked = true;
                    } else {
                        openChecked = false;
                    }
                }
                if (item.id === "IM") {
                    if (item.checked) {
                        IMChecked = true;
                    } else {
                        IMChecked = false;
                    }
                }
                if (item.id === "WORKORDER") {
                    if (item.checked) {
                        WorkOrderChecked = true;
                    } else {
                        WorkOrderChecked = false;
                    }
                }
            });
            value.forEach((item) => {
                if (item.id === "PORTAL") {
                    item.children.forEach((portalItem) => {
                        if (portalItem.id === "PORTAL_ENTRANCE") {
                            portalItem.children.forEach((portalItemItem) => {
                                if (
                                    portalItemItem.id === "PORTAL_ENTRANCE_OPEN"
                                ) {
                                    if (openChecked) {
                                        portalItemItem.checked = true;
                                    } else {
                                        portalItemItem.checked = false;
                                    }
                                }
                                if (
                                    portalItemItem.id === "PORTAL_ENTRANCE_IM"
                                ) {
                                    if (IMChecked) {
                                        portalItemItem.checked = true;
                                    } else {
                                        portalItemItem.checked = false;
                                    }
                                }
                                if (
                                    portalItemItem.id ===
                                    "PORTAL_ENTRANCE_WORKORDER"
                                ) {
                                    if (WorkOrderChecked) {
                                        portalItemItem.checked = true;
                                    } else {
                                        portalItemItem.checked = false;
                                    }
                                }
                            });
                        }
                    });
                }
            });
        },
        preserve() {
            let flag = 0;
            this.systemList.forEach((item) => {
                if (item.checked) {
                    flag += 1;
                }
            });
            if (flag === 0) {
                this.$message({
                    type: "warning",
                    message: "请至少选择一个权限",
                    duration: 2000,
                });
                return;
            }
            if (this.roleName === "") {
                this.$message({
                    message: "角色名称不能为空",
                    type: "error",
                    duration: 2000,
                });
                return;
            }
            if (this.templateId === "") {
                this.$message({
                    message: "请选择模版",
                    type: "warning",
                    duration: 2000,
                });
                return;
            }
            if (this.$route.query.type === "add") {
                let url = "/api/role";
                let role = {
                    name: this.roleName,
                    remark: this.roleDescribe,
                    privileges: {
                        privileges: this.systemList,
                    },
                    templateId:this.templateId
                };
                this.$http.post(url, role).then((res) => {
                    if (res.data.code === "0") {
                        this.$message({
                            message: "新增角色成功",
                            type: "success",
                            duration: 2000,
                        });
                        setTimeout(() => {
                            this.$router.push({
                                path: "/_roleManage",
                            });
                        }, 2000);
                    }
                });
            } else {
                let url = "/api/role/" + this.roleId;
                let role = {
                    name: this.roleName,
                    remark: this.roleDescribe,
                    privileges: {
                        privileges: this.systemList,
                    },
                    templateId:this.templateId
                };
                this.$http.put(url, role).then((res) => {
                    if (res.data.code === "0") {
                        this.$message({
                            message: "更新角色成功",
                            type: "success",
                            duration: 2000,
                        });
                        setTimeout(() => {
                            this.$router.push({
                                path: "/_roleManage",
                            });
                        }, 2000);
                    }
                });
            }
            
        },
    },
};
</script>

<style  lang="less">
#addRole {
    .gr-content-container {
        position: absolute;
        left: 64px;
        width: calc(100vw - 96px);
        height: calc(100vh - 24px);
        padding: 12px 16px;
        min-width: 1000px;
        overflow-x: auto;
        text-align: left;

        .content {
            width: 100%;
            height: calc(100% - 84px);
            margin-top: 16px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;

            .manage-nav-section {
                //width: 216px;
              width: 300px;
                flex: none;
                margin-right: 16px;
                padding-bottom: 16px;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;
            }

            .content-section {
                flex: auto;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;

                .content-box {
                    background-color: #ffffff;
                    border-radius: 5px;
                    height: calc(100vh - 240px);
                    margin: 15px 20px 0px 15px;
                    .addRole-container-container {
                        width: 100%;
                        height: 100%;
                        background-color: white;
                        display: flex;
                        justify-content: center;
                        .container {
                            margin: 0 auto;
                            width: 80%;
                            padding: 20px 0;
                            .roleName,
                            .roleDescribe,
                            .accountTemplate {
                                margin-bottom: 16px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .spanItem {
                                    width: 80px;
                                }
                            }
                            .tabCheck {
                                .el-tabs__nav {
                                    width: 200px;
                                }
                                .el-tabs__active-bar {
                                    background-color: #366aff !important;
                                    height: 2px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .notClick {
        pointer-events: auto;
    }
}
</style>