<template>
    <el-dialog
        id="staff-handle"
        :title="type == 'add' ? '添加员工' : '编辑员工'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        :before-close="close"
        width="460px"
        :close-on-press-escape="false"
    >
        <div class="staff-handle-content">
            <div class="info-cell">
                <div class="info-cell-title">
                    <span class="ne">*</span> 姓名
                </div>
                <div class="info-cell-input">
                    <el-input
                        maxlength="30"
                        size="small"
                        v-model="staffInfo.name"
                        placeholder="请输入姓名"
                    ></el-input>
                </div>
            </div>
            <div class="info-cell">
                <div class="info-cell-title"><span class="ne">*</span>部门</div>
                <div class="info-cell-input">
                    <el-cascader
                        size="small"
                        :options="options"
                        :props="props"
                        v-model="staffInfo.departmentIds"
                        clearable
                    ></el-cascader>
                </div>
            </div>
            <div class="info-cell">
                <div class="info-cell-title">
                    <span class="ne">*</span>手机号
                </div>
                <div class="info-cell-input">
                    <el-input
                        size="small"
                        v-model="staffInfo.mobile"
                        :disabled="type == 'edit'"
                        placeholder="请输入手机号"
                    ></el-input>
                </div>
            </div>
            <div class="info-cell">
                <div class="info-cell-title">邮箱</div>
                <div class="info-cell-input">
                    <el-input
                        size="small"
                        v-model="staffInfo.email"
                        placeholder="请输入邮箱"
                    ></el-input>
                </div>
            </div>
            <div class="info-cell">
                <div class="info-cell-title">生日</div>
                <div class="info-cell-input">
                    <el-date-picker
                            v-model="staffInfo.birthday"
                            type="date"
                            placeholder="选择日期"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="info-cell">
                <div class="info-cell-title">入职时间</div>
                <div class="info-cell-input">
                    <el-date-picker
                            v-model="staffInfo.staffIn"
                            type="date"
                            placeholder="选择日期"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div v-if="type == 'edit'" class="info-cell-relation">
                <div class="info-cell-title">
                    <span>已关联</span>
                    <span>操作</span>
                </div>
                <div class="info-cell-content">
                    <div
                        v-for="(item, index) in staffInfo.memberApps"
                        :key="index"
                        class="relation-cell"
                    >
                        <div class="relation-cell-name">
                            <img
                                v-if="item.type == 1"
                                style="height: 12px"
                                src="./../../../../../src/assets/images/u2012.png"
                                alt=""
                                srcset=""
                            />
                            <img
                                v-if="item.type == 0"
                                style="height: 12px"
                                src="./../../../../../src/assets/images/u2011.png"
                                alt=""
                                srcset=""
                            />
                            <span>{{ item.agentName }}</span>
                        </div>
                        <div class="relation-cell-handle">
                            <span
                                @click="changeStaffStatus(item)"
                                class="status1"
                                v-if="item.enable"
                                >禁用</span
                            >
                            <span
                                @click="changeStaffStatus(item)"
                                class="status0"
                                v-if="!item.enable"
                                >已禁用</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="close">取 消</el-button>
            <el-button size="small" type="primary" @click="save"
                >保 存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
export default {
    name: "staff-handle",
    props: ["type", "dialogVisible", "staffInfo", "options"],
    data() {
        return {
            props: {
                multiple: true,
                emitPath: false,
                value: "id",
                label: "label",
                checkStrictly: true,
            },
            name: "staff-handle",
        };
    },
    methods: {
        changeStaffStatus(item) {
            console.log(item.id);
            let url = "";
            if (item.enable) {
                url = this.requestUrl.member.forbidApp + "/" + item.id;
            } else {
                url = this.requestUrl.member.enableApp + "/" + item.id;
            }
            let statusStr = item.enable ? "禁用" : "启用";
            this.$confirm(
                "确定" + statusStr + item.agentName + "吗？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.$http.put(url).then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            item.enable = !item.enable;
                            this.$emit("changeSingStatus", {
                                id: this.staffInfo.id,
                                type: item.id,
                                enable: item.enable,
                            });
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        checkPhone(mobile) {
            let re = /^1[0-9]{10}$/;
            return re.test(mobile);
        },
        checkEmail(email) {
            // 验证邮箱正则
            var re = /^(([^()[\]\\.,;:\s@\"]+(\.[^()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        save() {
            console.log(this.staffInfo.departmentIds);
            if (this.staffInfo.name == "") {
                this.$message.error("员工姓名不能为空！");
                return;
            }
            if (
                this.staffInfo.departmentIds == undefined ||
                this.staffInfo.departmentIds.length == 0
            ) {
                this.$message.error("员工部门不能为空！");
                return;
            }
            if (this.staffInfo.mobile == "") {
                this.$message.error("员工手机号不能为空！");
                return;
            }
            if (!this.checkPhone(this.staffInfo.mobile)) {
                this.$message.error("请输入正确的手机号码！");
            } else {
                // if (!this.checkEmail(this.staffInfo.email)) {
                //     this.$message.error("请输入正确的邮箱号码！");
                // } else {

                // }
                this.$emit("saveHandleStaff", {
                    info: this.staffInfo,
                    type: this.type,
                });
            }
        },
        close() {
            this.$emit("closeHandleStaff");
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
#staff-handle {
    .staff-handle-content {
        .info-cell {
            .info-cell-title {
                text-align: left;
                margin: 12px 0 4px;
                .ne {
                    color: tomato;
                }
            }
            .info-cell-input {
                .el-cascader {
                    width: 100%;
                }
            }
        }
        .info-cell-relation {
            .info-cell-title {
                font-weight: 800;
                margin-top: 8px;
                height: 36px;
                line-height: 36px;
                display: flex;
                justify-content: space-between;
            }
            .info-cell-content {
                .relation-cell {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 6px;
                    .relation-cell-name {
                        text-align: left;
                        flex: auto;
                    }
                    .relation-cell-handle {
                        flex: none;
                        width: 50px;
                        text-align: right;
                        .status0 {
                            color: #366AFF;
                            cursor: pointer;
                        }
                        .status1 {
                            cursor: pointer;
                            color: tomato;
                        }
                    }
                }
            }
        }
    }
}
</style>