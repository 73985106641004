<template>
    <div id="version">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-gongdan"></i
                        ></span>
                        <span>{{$t('version.versionDetail')}}</span>
                    </div>
                </top-header>
            </div>
            <div class="version-content">
                <div class="vc-bn">
                    <div class="vc-bn-title">
                        <span class="vc-bn-title-pre">{{$t('version.versionPre')}}</span
                        ><span class="vc-bn-title-aft"> {{$t('version.versionAft')}}</span>
                    </div>
                    <div class="vc-bn-des">{{$t('version.versionDes')}}</div>
                </div>
                <div class="vc-tab">
                    <span
                        @click="switchSys('OPEN')"
                        :class="[
                            activeSysType === 'OPEN'
                                ? 'vc-tab-cell-active'
                                : 'vc-tab-cell',
                        ]"
                    >
                        <i
                            class="pre-icon iconfont guoran-askbotdandutubiao2"
                        ></i>
                        <i class="pre-text iconfont guoran-askbotwenzi2"></i>
                    </span>
                    <span
                        @click="switchSys('WORKORDER')"
                        :class="[
                            activeSysType === 'WORKORDER'
                                ? 'vc-tab-cell-active'
                                : 'vc-tab-cell',
                        ]"
                    >
                        <i
                            class="pre-icon iconfont guoran-askservice-logo03"
                        ></i>
                        <i
                            class="pre-text iconfont guoran-askservice-logo05"
                        ></i>
                    </span>
                </div>
                <div v-show="activeSysType === 'OPEN'" class="vc-info">
                    <div class="vc-info-content">
                        <div class="vc-info-content-inner">
                            <div class="vc-info-cc vc-info-cc-l-bgc">
                                <div class="vc-info-cc-inner">
                                    <div class="vc-info-cc-title">
                                        <span v-if="openActiveVersion == 2" class="active-version-tip"
                                            >{{$t('version.current')}}</span
                                        >
                                        <span class="vc-info-cc-title-t"
                                            >{{$t('version.major')}}</span
                                        ><span class="vc-info-cc-title-des">{{
                                            professional[0][0].description
                                        }}</span>
                                    </div>
                                    <div class="vc-info-cc-p">
                                        <div class="vc-info-cc-pc">
                                            <span
                                                class="
                                                    vc-info-cc-pc-num
                                                    vc-info-cc-pc-num-l
                                                "
                                                >{{
                                                    professional[0][0].price
                                                }}</span
                                            >
                                            <span class="vc-info-cc-pc-t"
                                                >{{$t('version.price')}}</span
                                            >
                                        </div>
                                        <span
                                            class="
                                                vc-info-cc-pc-b
                                                vc-info-cc-pc-b-b
                                            "
                                        ></span>
                                    </div>
                                    <div class="vc-info-cc-i">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in professional[0][0]
                                                .versionFunctionInfo"
                                            :key="index"
                                            class="vc-info-cc-i-cell"
                                        >
                                            <span
                                                class="
                                                    vc-info-cc-i-cell-icon
                                                    vc-info-cc-i-cell-icon-b
                                                "
                                            >
                                                <i
                                                    class="
                                                        iconfont
                                                        guoran-xuanzhong-
                                                    "
                                                ></i></span
                                            ><span
                                                class="vc-info-cc-i-cell-text"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    v-if="openActiveVersion == 1" 
                                    v-clipboard:copy="'4001-789-800'"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError" 
                                    class="vc-info-cc-tel vc-info-cc-tel-b">
                                    {{$t('version.phone')}}
                                </div>
                            </div>
                            <div class="vc-info-cc vc-info-cc-r-bgc">
                                <div class="vc-info-cc-inner">
                                    <div class="vc-info-cc-title">
                                        <span v-if="openActiveVersion == 3" class="active-version-tip"
                                            >{{$t('version.current')}}</span
                                        >
                                        <span class="vc-info-cc-title-t"
                                            >{{$t('version.enterpriseEdition')}}</span
                                        ><span class="vc-info-cc-title-des">{{
                                            professional[0][1].description
                                        }}</span>
                                    </div>
                                    <div class="vc-info-cc-p">
                                        <div class="vc-info-cc-pc">
                                            <span
                                                class="
                                                    vc-info-cc-pc-num
                                                    vc-info-cc-pc-num-r
                                                "
                                                >{{
                                                    professional[0][1].price
                                                }}</span
                                            >
                                            <span class="vc-info-cc-pc-t"
                                                >{{$t('version.price')}}</span
                                            >
                                        </div>
                                        <span
                                            class="
                                                vc-info-cc-pc-b
                                                vc-info-cc-pc-b-g
                                            "
                                        ></span>
                                    </div>
                                    <div class="vc-info-cc-cl">
                                      {{$t('version.versionInfo')}}：
                                    </div>
                                    <div class="vc-info-cc-i">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in professional[0][1]
                                                .versionFunctionInfo"
                                            :key="index"
                                            class="vc-info-cc-i-cell"
                                        >
                                            <span
                                                class="
                                                    vc-info-cc-i-cell-icon
                                                    vc-info-cc-i-cell-icon-g
                                                "
                                            >
                                                <i
                                                    class="
                                                        iconfont
                                                        guoran-xuanzhong-
                                                    "
                                                ></i></span
                                            ><span
                                                class="vc-info-cc-i-cell-text"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div  
                                    v-if="openActiveVersion == 1 || openActiveVersion == 2" 
                                    v-clipboard:copy="'4001-789-800'"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError" 
                                    class="vc-info-cc-tel vc-info-cc-tel-g">
                                    {{$t('version.phone')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activeSysType === 'WORKORDER'" class="vc-info">
                    <div class="vc-info-content">
                        <div class="vc-info-content-inner">
                            <div class="vc-info-cc vc-info-cc-l-bgc">
                                <div class="vc-info-cc-inner">
                                    <div class="vc-info-cc-title">
                                        <span v-if="workorderActiveVersion == 4" class="active-version-tip"
                                            >{{$t('version.current')}}</span
                                        >
                                        <span class="vc-info-cc-title-t"
                                            >{{$t('version.freeEdition')}}</span
                                        ><span class="vc-info-cc-title-des"><!--{{
                                            professional[1][1].description
                                        }}--></span>
                                    </div>
                                    <div class="vc-info-cc-p">
                                        <div class="vc-info-cc-pc">
                                            <span
                                                class="
                                                    vc-info-cc-pc-num
                                                    vc-info-cc-pc-num-l
                                                "
                                                >{{
                                                    professional[1][1].price
                                                }}</span
                                            >
                                            <span class="pre-vc-info-cc-pc-num">1500</span>
                                            <span class="vc-info-cc-pc-t"
                                                >{{$t('version.accountPrice')}}</span
                                            >
                                        </div>
                                        <span
                                            class="
                                                vc-info-cc-pc-b
                                                vc-info-cc-pc-b-b
                                            "
                                        ></span>
                                    </div>
                                    <div class="vc-info-cc-i">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in professional[1][1]
                                                .versionFunctionInfo"
                                            :key="index"
                                            class="vc-info-cc-i-cell"
                                        >
                                            <span
                                                class="
                                                    vc-info-cc-i-cell-icon
                                                    vc-info-cc-i-cell-icon-b
                                                "
                                            >
                                                <i
                                                    class="
                                                        iconfont
                                                        guoran-xuanzhong-
                                                    "
                                                ></i></span
                                            ><span
                                                class="vc-info-cc-i-cell-text"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="vc-info-cc vc-info-cc-l-bgc">
                                <div class="vc-info-cc-inner">
                                    <div class="vc-info-cc-title">
                                        <span v-if="workorderActiveVersion == 2" class="active-version-tip"
                                            >{{$t('version.current')}}</span
                                        >
                                        <span class="vc-info-cc-title-t"
                                            >{{$t('version.major')}}</span
                                        ><span class="vc-info-cc-title-des"><!--{{
                                            professional[1][0].description
                                        }}--></span>
                                    </div>
                                    <div class="vc-info-cc-p">
                                        <div class="vc-info-cc-pc">
                                            <span
                                                class="
                                                    vc-info-cc-pc-num
                                                    vc-info-cc-pc-num-l
                                                "
                                                >{{
                                                    professional[1][0].price
                                                }}</span
                                            >
                                            <span class="vc-info-cc-pc-t"
                                                >{{$t('version.accountPrice')}}</span
                                            >
                                        </div>
                                        <span
                                            class="
                                                vc-info-cc-pc-b
                                                vc-info-cc-pc-b-b
                                            "
                                        ></span>
                                    </div>
                                    <div class="vc-info-cc-cl-z">
                                      {{$t('version.freeVersion')}}：
                                    </div>
                                    <div class="vc-info-cc-i">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in professional[1][0]
                                                .versionFunctionInfo"
                                            :key="index"
                                            class="vc-info-cc-i-cell"
                                        >
                                            <span
                                                class="
                                                    vc-info-cc-i-cell-icon
                                                    vc-info-cc-i-cell-icon-b
                                                "
                                            >
                                                <i
                                                    class="
                                                        iconfont
                                                        guoran-xuanzhong-
                                                    "
                                                ></i></span
                                            ><span
                                                class="vc-info-cc-i-cell-text"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div 
                                    v-if="workorderActiveVersion == 1" 
                                    v-clipboard:copy="'4001-789-800'"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError" 
                                    class="vc-info-cc-tel vc-info-cc-tel-b">
                                  {{$t('version.phone')}}
                                </div>
                            </div>
                            <!-- <div class="vc-info-cc vc-info-cc-r-bgc">
                                <div class="vc-info-cc-inner">
                                    <div class="vc-info-cc-title">
                                        <span v-if="workorderActiveVersion == 2" class="active-version-tip"
                                            >当前</span
                                        >
                                        <span class="vc-info-cc-title-t"
                                            >企业版</span
                                        ><span class="vc-info-cc-title-des">{{
                                            professional[1][1].description
                                        }}</span>
                                    </div>
                                    <div class="vc-info-cc-p">
                                        <div class="vc-info-cc-pc">
                                            <span
                                                class="
                                                    vc-info-cc-pc-num
                                                    vc-info-cc-pc-num-r
                                                "
                                                >{{
                                                    professional[1][1].price
                                                }}</span
                                            >
                                            <span class="vc-info-cc-pc-t"
                                                >元/账号/年</span
                                            >
                                        </div>
                                        <span
                                            class="
                                                vc-info-cc-pc-b
                                                vc-info-cc-pc-b-g
                                            "
                                        ></span>
                                    </div>
                                    <div class="vc-info-cc-cl">
                                        包括专业版完整功能以及：
                                    </div>
                                    <div class="vc-info-cc-i">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in professional[1][1]
                                                .versionFunctionInfo"
                                            :key="index"
                                            class="vc-info-cc-i-cell"
                                        >
                                            <span
                                                class="
                                                    vc-info-cc-i-cell-icon
                                                    vc-info-cc-i-cell-icon-g
                                                "
                                            >
                                                <i
                                                    class="
                                                        iconfont
                                                        guoran-xuanzhong-
                                                    "
                                                ></i></span
                                            ><span
                                                class="vc-info-cc-i-cell-text"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div v-if="workorderActiveVersion == 1 || workorderActiveVersion == 2" class="vc-info-cc-tel vc-info-cc-tel-g">
                                    拨打 4001-789-800 升级
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div v-show="activeSysType === 'OPEN'" class="vc-version-d">
                    <div class="vc-version-d-inner">
                        <div class="vc-version-d-header">
                            <span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-l
                                "
                                >{{$t('version.standardProducts')}}</span
                            ><span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-m
                                "
                                >{{$t('version.major')}}</span
                            ><span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-r
                                "
                                >{{$t('version.enterpriseEdition')}}</span
                            >
                        </div>
                        <div class="vc-version-d-content">
                            <div
                                v-for="(
                                    item, index
                                ) in professionalVersionDetail"
                                :key="index"
                                class="vc-version-d-content-cell"
                            >
                                <span class="vc-version-dcc-name">
                                    <span
                                        :class="[
                                            'vc-version-dcc-name-i',
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                    >
                                        <span class="circ-i"></span>
                                        {{ item.name }}
                                    </span></span
                                ><span class="vc-version-dcc-professional">
                                    <span
                                        :class="[
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                    >
                                        <i
                                            v-if="
                                                !(
                                                    item.description !==
                                                        'null' &&
                                                    item.description
                                                ) && item.support
                                            "
                                            class="
                                                icon-checked
                                                iconfont
                                                guoran-a-16-17
                                            "
                                        ></i>
                                        <i
                                            v-if="
                                                !(
                                                    item.description !==
                                                        'null' &&
                                                    item.description
                                                ) && !item.support
                                            "
                                            class="
                                                icon-clocsed
                                                iconfont
                                                guoran-shanchu
                                            "
                                        ></i>
                                        {{
                                            item.description !== "null" &&
                                            item.description
                                                ? `${item.description} `
                                                : ""
                                        }}
                                    </span> </span
                                ><span class="vc-version-dcc-enterprise">
                                    <span
                                        :class="[
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                        ><i
                                            v-if="
                                                !(
                                                    item.companyDescription !==
                                                        'null' &&
                                                    item.companyDescription
                                                ) && item.companySupport
                                            "
                                            class="
                                                icon-checked
                                                iconfont
                                                guoran-a-16-17
                                            "
                                        ></i>
                                        <i
                                            v-if="
                                                !(
                                                    item.companyDescription !==
                                                        'null' &&
                                                    item.companyDescription
                                                ) && !item.companySupport
                                            "
                                            class="
                                                icon-clocsed
                                                iconfont
                                                guoran-shanchu
                                            "
                                        ></i>
                                        {{
                                            item.companyDescription !==
                                                "null" &&
                                            item.companyDescription
                                                ? `${item.companyDescription} `
                                                : ""
                                        }}</span
                                    ></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-show="activeSysType === 'WORKORDER'"
                    class="vc-version-d"
                >
                    <div class="vc-version-d-inner">
                        <div class="vc-version-d-header">
                            <span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-l
                                "
                                >{{$t('version.standardProducts')}}</span
                            ><span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-m
                                "
                                >{{$t('version.freeEdition')}}</span
                            ><span
                                class="
                                    vc-version-d-header-cell
                                    vc-version-d-header-cell-r-z
                                "
                                >{{$t('version.major')}}</span
                            >
                        </div>
                        <div class="vc-version-d-content">
                            <div
                                v-for="(item, index) in enterpriseVersionDetail"
                                :key="index"
                                class="vc-version-d-content-cell"
                            >
                                <span class="vc-version-dcc-name">
                                    <span
                                        :class="[
                                            'vc-version-dcc-name-i',
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                    >
                                        <span class="circ-i"></span>
                                        {{ item.name }}
                                    </span></span
                                ><span class="vc-version-dcc-professional">
                                    <span
                                        :class="[
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                    >
                                        <i
                                            v-if="
                                                !(
                                                    item.description !==
                                                        'null' &&
                                                    item.description
                                                ) && item.freeSupport
                                            "
                                            class="
                                                icon-checked
                                                iconfont
                                                guoran-a-16-17
                                            "
                                        ></i>
                                        <i
                                            v-if="
                                                !(
                                                    item.description !==
                                                        'null' &&
                                                    item.description
                                                ) && !item.freeSupport
                                            "
                                            class="
                                                icon-clocsed
                                                iconfont
                                                guoran-shanchu
                                            "
                                        ></i>
                                        {{
                                            item.description !== "null" &&
                                            item.description
                                                ? `${item.description}`
                                                : ""
                                        }}
                                    </span> </span
                                ><span class="vc-version-dcc-enterprise-z">
                                    <span
                                        :class="[
                                            index % 2 === 0
                                                ? 'opcity-cell'
                                                : '',
                                        ]"
                                        ><i
                                            v-if="
                                                !(
                                                    item.companyDescription !==
                                                        'null' &&
                                                    item.companyDescription
                                                ) && item.support
                                            "
                                            class="
                                                icon-checked
                                                iconfont
                                                guoran-a-16-17
                                            "
                                        ></i>
                                        <i
                                            v-if="
                                                !(
                                                    item.companyDescription !==
                                                        'null' &&
                                                    item.companyDescription
                                                ) && !item.support
                                            "
                                            class="
                                                icon-clocsed
                                                iconfont
                                                guoran-shanchu
                                            "
                                        ></i>
                                        {{
                                            item.companyDescription !==
                                                "null" &&
                                            item.companyDescription
                                                ? `${item.companyDescription}`
                                                : ""
                                        }}</span
                                    ></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import SideNav from "./../menu/SideNav.vue";
import TopHeader from "./../../components/top-header.vue";

export default {
    name: "version",
    data() {
        return {
            rolePrivilegesList: [],
            professional: [[], []],
            professionalVersionDetail: [],
            enterpriseVersionDetail: [],
            activeSysType: "OPEN",
            openActiveVersion: "",
            workorderActiveVersion: "",
        };
    },
    components: {
        SideNav,
        TopHeader,
    },
    mounted() {
        // 入口权限
        this.rolePrivilegesList = this.getRolePrivileges();
        this.getVersion();
        this.getVersionBoard();
    },
    filters: {},
    methods: {
         // Copy成功
        onCopy(e) {
            this.$message.success(this.$t('version.copySuccess'));
        },
        // Copy失败
        onError(e) {
            this.$message.error(this.$t('version.copyFail'));
        },
        switchSys(type) {
            this.activeSysType = type;
        },
        getVersion() {
            this.$http
                .get(this.requestUrl.version.selectPayVersion)
                .then((res) => {
                    if (res.data.code === "0") {
                        this.professionalVersionDetail =
                            res.data.data[0].versionFunctionInfo.versionFunctionNodes;
                        this.enterpriseVersionDetail =
                            res.data.data[1].versionFunctionInfo.versionFunctionNodes;
                    }
                });
        },
        getVersionBoard() {
            this.$http
                .get(this.requestUrl.version.selectPayVersionBoard)
                .then((res) => {
                    if (res.data.code === "0") {
                        res.data.data.payVersionBoards.forEach(
                            (item, index) => {
                                if (
                                    item.systemType === 1 &&
                                    item.versionType === 2
                                ) {
                                    this.professional[0].push(item);
                                }
                                if (
                                    item.systemType === 1 &&
                                    item.versionType === 3
                                ) {
                                    this.professional[0].push(item);
                                }
                                if (
                                    item.systemType === 3 &&
                                    item.versionType === 2
                                ) {
                                    this.professional[1].push(item);
                                }
                                if (
                                    item.systemType === 3 &&
                                    item.versionType === 4
                                ) {
                                    this.professional[1].push(item);
                                }
                            }
                        );
                        console.log(this.professional);
                        this.openActiveVersion = res.data.data.botVersionType;
                        this.workorderActiveVersion = res.data.data.workOrderVersionType;
                    }
                });
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/version/version.less";
</style>
