<!-- 
/**
pageName:单选/多选/下拉/级联
auther:liuyuli
time:2022-4-24
 */
 -->
<template>
    <div class="radio-add-select">
        <top-config :activeObj="activeObjs"></top-config>
        <div>
            <div class="item-title">
                <span>选项</span>
                <span class="red">*</span>
            </div>
            <div id="radioSorts">
                <template  v-if="activeObjs.type==='RADIO'||activeObjs.type==='CHECKBOX'||activeObjs.type==='SELECT'">
                    <div id="radioSorts-c">
                        <div
                            class="radio-item"
                            v-for="(item,index) in activeObjs.extInfo.options"
                            :data-id="JSON.stringify(item)"
                            :key="item.label + index" >
                            <span class="sortIcon my-handle1" @mouseenter="resortOptions(activeObjs.type)"><i
                                class="iconfont guoran-a-16-10"></i></span>
                            <el-input placeholder="请输入" @change="changeOptinsValue($event,item)"
                                      size="medium"
                                      v-model="item.value"
                                      style="width: 80%"
                                      :disabled="activeObjs.extInfo.isCommon  && !$route.query.type"
                            ></el-input>
                            <span @click="deleteOption(index)"><i class="iconfont guoran-a-16-09" ></i>
                            </span>
                        </div>
                    </div>

                    <div class="add-btn">
                        <div class="el-icon-plus" @click="addOptions"></div>
                        <span @click="addOptions" style="cursor: pointer">添加选项</span>
                    </div>
                </template>
                <template  v-if="activeObjs.type==='CASCADER'">
                    <div class="cascade-box">
                        <el-tree :data="activeObjs.extInfo.cascadeDown.options ? activeObjs.extInfo.cascadeDown.options : activeObjs.extInfo.cascadeDown[0].options" :default-expand-all="true">
                            <span class="el-tree-title" slot-scope="{ node, data }">
                                <span class="el-tree-title-content">
                                    <span class="el-tree-title-word">
                                    <i
                                        style="color: #a1b9ff;margin-right:5px"
                                        v-if="data.children && data.children.length > 0"
                                        class="
                                        iconfont
                                        guoran-wenjianjia-zhankaizhuangtai--folderOpen
                                        "
                                    ></i>
                                      <div v-else class="set-folder"></div>
                                    </span>
                                    <span  class="el-tree-title-word-name" >

                                      {{ data.label }}
                                    </span>
                                </span>
                            </span>
                        </el-tree>
                    </div>
                </template>
                    <template  v-if="activeObjs.type==='IMAGE_SELECT'">
                    <div class="image-box">
                        <div id="radioSorts-c">
                            <div
                                class="radio-item image-select"
                                v-for="(item,index) in activeObjs.extInfo.options"
                                :data-id="JSON.stringify(item)"
                                :key="item.label + index" >
                                <div class="upload-box">
                                    <span class="sortIcon my-handle2" @mouseenter="resortOptions(activeObjs.type)"><i class="iconfont guoran-a-16-10"></i></span>
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://jsonplaceholder.typicode.com/posts/"
                                        :http-request="aliyunOssRequest"
                                        accept=".jpg,.jpeg,.png,.PNG,.JPG"
                                        :limit="1"
                                        :before-upload="(file) => beforeUpload(file, item)"
                                        :on-success="(file) => onSuccess(file, item)"
                                        :show-file-list="false"
                                        :disabled="activeObjs.extInfo.isCommon  && !$route.query.type"
                                    >
                                        <img v-if="item.url && item.url !== ''" :src="item.url" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                
                                    <span @click="deleteOption(index)"><i class="iconfont guoran-a-16-09" ></i></span>
                                </div>
                                <div class="caption">
                                  <el-input placeholder="请输入图片说明" v-model="item.value"  size="medium" style="width: calc(100% - 36px)"></el-input>
                                </div>

                            </div>
                        </div>
                        <div class="btns">
                            <div class="add-btn">
                                <div class="el-icon-plus" @click="addOptions('IMAGE_SELECT')"></div>
                                <span @click="addOptions('IMAGE_SELECT')" style="cursor: pointer">添加选项</span>
                            </div>
                            <div class="radio-item-edit">
                                <div @click="openRelationControlPopup('IMAGE_SELECT')">关联控件</div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="radio-item-edit"  v-if="activeObjs.type !== 'IMAGE_SELECT'">
                    <div @click="bulkEdit(activeObjs.extInfo.options,activeObjs.type)">批量编辑</div>
                    <div @click="addOptionOther" v-if="activeObjs.type !== 'CASCADER' && false">添加其他</div>
                    <!-- <div @click="openRelationControlPopup">关联控件</div> -->
                </div>
            </div>
        </div>
        <div class="choose-style" v-if="activeObjs.type ==='SELECT' || activeObjs.type ==='IMAGE_SELECT' || activeObjs.type ==='CASCADER'">
            <div class="item-title"><span>选择方式</span></div>
            <el-radio-group v-model="activeObjs.extInfo.selectType" @change="changeSelectType" :disabled="activeObjs.extInfo.isCommon && !$route.query.type">
              <el-radio label="单选">单选</el-radio>
              <el-radio label="多选">多选</el-radio>
            </el-radio-group>
        </div>
        <!-- <aiDistinguish :centerlist="centerlist"  :activeObj="activeObjs"></aiDistinguish> -->
        <bottom-common :activeObj="activeObjs"></bottom-common>
        <batchEdit 
            :batchEditDialog.sync="batchEditDialog"
            :activeObj="activeObjs"></batchEdit>
        <popup @closeEvent="dialogVisibleEdit=false" v-if="dialogVisibleEdit">
                <div slot="popup-name" class="popup-name">{{editTitle}}</div>
                <div slot="popup-tip">每行对应一个选项</div>
                <div slot="popup-con" class="popup-con ckeditor-batch-edit">
                    <ckeditor
                        :editor="ckeditor.editor"
                        v-model="contentConent"
                        :config="ckeditor.editorConfig">
                    </ckeditor>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="dialogVisibleEdit=false" size="small" plain>取 消</el-button>
                    <el-button type="primary" @click="editOptions" size="small">确 定</el-button>
                </div>
            </popup>
    </div>
</template>
<script>
import Sortable from "sortablejs";
import topConfig from '../common/topCommon.vue';
import bottomCommon from '../common/bottomCommon.vue';
import aiDistinguish from '../aiDistinguish.vue';
import batchEdit from '../batchEdit.vue';
import CKEDITOR from "ckeditor";
import popup from '../../../../../components/popup'
// import mixins from './../../mixins';
import {ossConfig, upload} from "@/assets/js/AliyunIssUtil";
export default {
    // mixins:[mixins],
    components:{
        topConfig, 
        bottomCommon,
        aiDistinguish, // 智能识别
        batchEdit, // 批量编辑
        popup
    },
    data(){
        return {
            radio:'radio',
            labelPosition:'top',
            relationControlDialog:false, // 关联控件弹框显隐
            batchEditDialog:false, // 批量编辑弹框显隐
            editTitle:'', // 批量编辑弹框标题
            dialogVisibleEdit:false, // 批量编辑弹框显隐
            contentConent:'',
            ckeditor: {
                editor: CKEDITOR.ClassicEditor,
                editorConfig: {
                    toolbar: [],
                    askPluginListener: [
                        {
                            event: "PLACEHOLDER",
                            process: (data, callback) => {
                                console.debug('editor call back', data, callback)
                            },
                        }
                    ], // 注册监听
                },
            },
          activeObjCopy:null
        }
    },
    props:{
		activeObj:{
			type:Object,
			default(){
				return{}
			}
		},
        centerlist:{
            type:Array,
            default(){
                return []
            }
        },
      hasDesCenterlist:{
        type:Array,
        default(){
          return []
        }
      }
	},
	watch:{
		activeObjs:{
			handler(n,o){
				console.log(n,'单选组件的');
			},
			deep:true
		}
    },
	computed:{
        // activeObjs(){
        //     return this.activeObj;
        // },
        activeObjs:{
            get() {
                return this.activeObj;
            },
            set(v) {
                // sync  子组件点取消不保存值，重新赋值为深拷贝的extenfo
               this.activeObj.extInfo = v.extInfo
            }
        }
    },
    methods:{
        changeOptinsValue(value,item){
            item.label = item.value;
            let values = this.activeObjs.extInfo.options.map(item=>{
              return item.value
            })
            if (values.indexOf(this.activeObj.defaultValue)==-1){
              this.activeObj.defaultValue = ""
            }

        },
         //删除单选复选中的选项
        deleteOption(index) {
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            this.activeObjs.extInfo.options.splice(index, 1);
          this.activeObjs.defaultValue = ""
        },
         //选项排序
        resortOptions(type){
            let handle = ''
            if(type === 'IMAGE_SELECT'){
                handle = '.my-handle2'
            } else {
                handle = '.my-handle1'
            }
            let el = document.getElementById('radioSorts-c')
            let sortables = new Sortable(el, {
                animation: 200,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: handle,
                forceFallback: false,  
                onEnd:()=>{
                     console.log(sortables,'Sortables');
                    let arr = sortables.toArray()
                     console.log(arr,'arr');
                    let testArr = []
                    testArr = arr.length > 0 ? arr.map(item => {
                        return JSON.parse(item)
                    }) : []
                    this.activeObjs.extInfo.options = testArr
                }
            });
            console.log(sortables,'Sortables');

        },
         //增加其他选项
        addOptionOther() {
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            let boo = true;
            this.activeObjs.extInfo.options.forEach(item => {
                if(item.value === '其他'){
                    boo = false;
                }
             })
            if(boo){
                this.activeObjs.extInfo.options.push({value: '其他',label:'其他', _default: 'false'});
            } else {
                this.$message.error("只能添加一个'其他'选项");
            }
            
        },
       // 增加单复选下拉字段中的选项
        addOptions(type){
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            if(type !== 'IMAGE_SELECT'){
                this.activeObjs.extInfo.options.push({value: '',label:'', _default: 'false'})
            } else {
                this.activeObjs.extInfo.options.push({
                    label: '', 
                    value: '', 
                    url: '', 
                    _default: false, 
                    watermark: { 
                        enable: false, //是否添加水印, 
                        text: '' 
                    }, 
                    relationItems: [],
                })
            }
             
        },
        //批量编辑
        bulkEdit(options, type) {
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            if(type === 'CASCADER'){
                this.batchEditDialog = true;
            } else {
                this.contentConent = '';
                if (options.length > 0) {
                    options.forEach(item => {
                        if (item.value) {
                            this.contentConent += '<p>' + item.value + '</p>'
                        }
                    })
                }
                if (type === 'RADIO') {
                    this.editTitle = '单选框-批量编辑';
                } else if (type === 'CHECKBOX') {
                    this.editTitle = '复选框-批量编辑';
                } else if (type === 'SELECT') {
                    this.editTitle = '下拉-批量编辑';
                }
                this.dialogVisibleEdit = true;
            }
        },
        openRelationControlPopup(type){
          if (this.activeObjs.extInfo.isCommon && !this.$route.query.type){
            this.$message({
              message:"请前去公有字段页面进行配置，该页面不允许对公有字段进行测试",
              duration:2000,
              type:'warning'
            })
            return
          }
            this.relationControlDialog = true;
          this.activeObjCopy = JSON.parse(JSON.stringify(this.activeObjs))
            // IMAGE_SELECT
        },
        closerRelationControlDialog(){
            this.relationControlDialog = false;
        },
      closeEvent(){
          this.activeObjs = this.activeObjCopy;
        this.relationControlDialog = false;
      },
        // 批量编辑确定
        editOptions(){
          this.activeObjs.extInfo.options =[];
          let ck = document.querySelector('.ckeditor-batch-edit .ck-editor')
          let content =ck.getElementsByTagName('p')
          if (content&&content.length >0){
                for (let i=0;i<content.length;i++){
                    if (content[i].innerHTML.indexOf('<br') !== -1){
                        let lines = content[i].innerHTML.split("<br>");
                        if (lines){
                            lines = lines.filter(item => item !== '<br data-cke-filler="true">')
                            for (let j =0;j<lines.length;j++){
                                this.activeObjs.extInfo.options.push({value:lines[j],label:lines[j],_default:'false'})
                            }
                        }
                    } else if (content[i].innerText.indexOf('<br') !== -1){
                        let lines = content[i].innerText.split("<br>");
                        if (lines){
                            lines = lines.filter(item => item !== '<br data-cke-filler="true">')
                            for (let j =0;j<lines.length;j++){
                                this.activeObjs.extInfo.options.push({value:lines[j],label:lines[j],_default:'false'})
                            }
                        }
                    } else {
                        if (this.activeObjs.extInfo.options[i]){
                            this.activeObjs.extInfo.options[i].value =content[i].innerText
                            this.activeObjs.extInfo.options[i].label =content[i].innerText
                        }
                        else {
                            console.log(content[i].innerText);
                            this.activeObjs.extInfo.options.push({value:content[i].innerText,label:content[i].innerText,_default:'false'})
                        }
                    }
                }
            }
            this.dialogVisibleEdit = false;
        },
        // 获取文件后缀名
        getFileSuffix(url,arr) {
            let index1 = url.lastIndexOf(".")+1;
            let index2 = url.length;
            let postf = url.substring(index1, index2);//后缀名
            if (!arr || arr.indexOf(postf) == -1) {
                return false;
            } else {
                return true
            }
        },
        beforeUpload(file,item){
            console.log(file,'file');
            console.log(item,'item');
            if(file){
                if(this.getFileSuffix(file.name,['.jpg','.jpeg','.png','.PNG','.JPG'])){
                    this.$message.error('请上传png,jpg,jpeg格式的图片');
                    return false;
                }
                //判断上传的视频大小
                if(file.size / 1024 /1024 > 2) {
                    this.$message.error('文件大小不能超过2Mb');
                    return false;
                }
                item.label = file.name;
            }
           
        },
        aliyunOssRequest(data){
            let file = data.file;
            console.log('file',file)
            const fileName = file.name;
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            let size = file.size / 1024 / 1024 <= 10
            if (!size) {
                this.$message({
                    message: '上传的图片不能超过10M',
                    type: 'warning',
                    duration: 2000
                })
                this.imageList = []
                return
            }
            if (!(fileType === '.jpg' || fileType === '.jpeg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG')){
                this.$message({
                    message: '请选择.jpg,.png,.jpeg',
                    type: 'warning',
                    duration: 2000
                })
                return
            }
            let res = upload(ossConfig, file);
            return res;
        },
        onSuccess(file,item){
          item.url = file.url
            console.log(file,item,'onSuccess');
        },
        changeSelectType(value) {
            if(this.activeObjs.type == 'CASCADER') {
                this.activeObjs.defaultValue = []
                this.activeObjs.extInfo.fieldValue = []
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .radio-add-select{
        .item-title{
            margin-bottom: 10px;
            color: #616161;
            .red{
                // margin-left: 5px;
                color: red;
            }
        }
         #radioSorts {
            margin-bottom: 20px;
            .radio-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                .iconfont{
                    color: #366AFF;
                }
              .sortIcon{
                cursor:move ;
              }
              .guoran-a-16-09{
                cursor: pointer;
              }
            }
        }
        .radio-item-edit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #366AFF;
            div {
                cursor: pointer;
                width: 96px;
                height: 33px;
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                border-radius: 17px;
                font-size: 14px;
                color: #366AFF;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .add-btn{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #366AFF;
             margin-bottom: 20px;
          .el-icon-plus{
            cursor: pointer;
          }
            div{
                margin-right: 10px;
                width: 34px;
                height: 34px;
                background: #366AFF;
                border-radius: 5px;
                text-align: center;
                line-height: 34px;
                color: #fff;
                font-weight: 800;

            }
        }
        .cascade-box{
            height: 223px;
            background: #FBFCFD;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            overflow-y: auto;
            padding: 13px;
            margin-bottom: 11px;
           .el-tree-title-content{
             display: flex;
             align-items: center;
             .set-folder{
               width: 7px;
               height: 7px;
               background: #A1B9FF;
               border-radius: 1px;
               flex: none;
               margin-right: 6px;
             }
           }
            ::v-deep .el-tree{
                background: #FBFCFD;
                .el-tree-node{
                    margin-bottom: 5px;
                }
            }
        }
        .btns{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 34px;
            .add-btn{
                margin-bottom: 0;
            }
        }
        .image-box{
           /* height: 200px;*/
            background: #FBFCFD;
            border-radius: 5px;
            padding: 10px;
           /* overflow-y: scroll;*/
              /*定义滚动条轨道 内阴影+圆角*/
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px transparent;
                border-radius: 10px;
                background-color:  #E7ECF6;
            }

            /*定义滚动条高宽及背景*/
            &::-webkit-scrollbar {
                width: 5px;
            }

            /*定义滚动条滑块*/
            &::-webkit-scrollbar-thumb {
                background-color: #366AFF;
                border-radius: 3px;
            }
            .image-select{
                display: flex;
                justify-content: flex-start !important;
                flex-direction: column;
                .upload-box{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 10px;
                }
                .caption{
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                }
                .avatar-uploader{
                    width: 76px;
                    height: 76px;
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 76px;
                    color: #366AFF;
                    font-size: 22px;
                    margin: 0 20px;
                  /deep/img{
                    width: 76px;
                    height: 76px;
                    object-fit: cover;
                  }
                }
            }

        }   
        .choose-style{
            margin-bottom: 20px;
        }
    }
</style>>

