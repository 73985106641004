var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"config-header"},[_c('div',{staticClass:"header-wrapper"},[(_vm.isPart == 1 || _vm.isBotLink)?_c('div',{staticClass:"interaction-name",staticStyle:{"text-align":"left","padding":"0 15px"}},[_vm._v(" "+_vm._s(_vm.activeNodeName)+" ")]):_vm._e(),(_vm.isPart == 1)?_c('div',{staticStyle:{"text-align":"right","width":"100%","padding":"0 15px"}},[_c('span',{staticStyle:{"color":"#e6a23c"}},[_vm._v(_vm._s(_vm.$t('flowComponent.saveTip1')))]),_c('el-button',{attrs:{"slot":"reference","size":"small","plain":""},on:{"click":_vm.closePartDrewer},slot:"reference"},[_vm._v(_vm._s(_vm.$t('common.close')))])],1):_vm._e(),(_vm.isPart == 0 && !_vm.isBotLink)?_c('el-input',{ref:"elInputName",staticClass:"interaction-name",attrs:{"type":"text","spellcheck":"false","prefix-icon":"el-icon-edit","maxlength":"20"},model:{value:(_vm.activeNodeName),callback:function ($$v) {_vm.activeNodeName=$$v},expression:"activeNodeName"}}):_vm._e(),(_vm.isPart == 0)?_c('div',{staticClass:"interaction-save"},[_c('el-popover',{attrs:{"placement":"top","width":"160","trigger":"manual"},model:{value:(_vm.cancelSaveVisible),callback:function ($$v) {_vm.cancelSaveVisible=$$v},expression:"cancelSaveVisible"}},[_c('p',[_vm._v(_vm._s(_vm.$t('flowComponent.closeTip1')))]),_c('div',{staticStyle:{"text-align":"right","margin":"0","padding":"10px"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":_vm.changeCancelSaveVisible}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.cancelSaveConfirm}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1),_c('el-button',{attrs:{"slot":"reference","size":"small","plain":""},on:{"click":_vm.checkActionListChanged},slot:"reference"},[_vm._v(_vm._s(_vm.$t('common.close')))])],1),(
                    !(
                        (_vm.nodeId == 'start' &&
                            !_vm.RP_Visible('OPEN_PROCESS_START')) ||
                        (_vm.nodeId == 'end' &&
                            !_vm.RP_Visible('OPEN_PROCESS_END')) ||
                        (!_vm.isStartOrEnd &&
                            !_vm.RP_Visible('OPEN_PROCESS_OTHER') &&
                            _vm.isOtherSkill) ||
                        (!_vm.isStartOrEnd &&
                            !_vm.isOtherSkill &&
                            !_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE'))
                    )
                )?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.saveChatFlow}},[_vm._v(_vm._s(_vm.$t('common.save')))]):_vm._e()],1):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            (_vm.nodeId == 'start' && !_vm.RP_Visible('OPEN_PROCESS_START')) ||
            (_vm.nodeId == 'end' && !_vm.RP_Visible('OPEN_PROCESS_END')) ||
            (!_vm.isStartOrEnd &&
                !_vm.RP_Visible('OPEN_PROCESS_OTHER') &&
                _vm.isOtherSkill) ||
            (!_vm.isStartOrEnd &&
                !_vm.isOtherSkill &&
                !_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE'))
        ),expression:"\n            (nodeId == 'start' && !RP_Visible('OPEN_PROCESS_START')) ||\n            (nodeId == 'end' && !RP_Visible('OPEN_PROCESS_END')) ||\n            (!isStartOrEnd &&\n                !RP_Visible('OPEN_PROCESS_OTHER') &&\n                isOtherSkill) ||\n            (!isStartOrEnd &&\n                !isOtherSkill &&\n                !RP_Visible('OPEN_PROCESS_CUSTOMIZE'))\n        "}],staticClass:"node-rp-tips"},[_c('span',{staticClass:"el-icon-warning"}),_c('span',[_vm._v(_vm._s(_vm.$t('flowComponent.noEditTip')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }