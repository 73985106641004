<template>
    <div id="company-info">
        <div class="content-container">
            <div class="header">
                <div class="leftInfo">
                    <span class="headerName">企业管理</span>
                </div>
            </div>
            <div class="comnpanyInfo-content">
                <leftSubMenu></leftSubMenu>
                <div class="content-box">
                    <div class="base-info-title">基本信息</div>
                    <div class="base-info-content">
                        <el-form ref="form" :model="form" label-width="90px">
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="企业名称">
                                        北京果然智汇科技有限公司
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="企业管理员">
                                        吴明岩
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="管理员邮箱">
                                        wuminyan@guoranbot.com
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="管理员手机">
                                        13910000000
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="企业规模">
                                        <el-cascader
                                            v-model="value"
                                            :options="options"
                                            @change="handleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="所属行业">
                                        <el-cascader
                                            v-model="value"
                                            :options="options"
                                            @change="handleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="所属子行业">
                                        <el-cascader
                                            v-model="value"
                                            :options="options"
                                            @change="handleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="所在省份">
                                        <el-cascader
                                            v-model="value"
                                            :options="options"
                                            @change="handleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="所在地区">
                                        <el-cascader
                                            v-model="value"
                                            :options="options"
                                            @change="handleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <leftMenu></leftMenu>
    </div>
    
</template>
<script>
import leftMenu from './../../menu/leftMenu.vue'
import leftSubMenu from './../../menu/leftSubMenu.vue'
export default {
    name: 'companyInfo',
    components:{
        leftMenu,leftSubMenu
    },
    data(){
        return{
            formInline: {
                user: '',
                region: ''
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/companyInfo/companyInfo.less";
</style>