<template>
  <div class="identificationCondition">
    <el-dropdown
        trigger="click"
        id="el-dropdown-plugin"
        ref="messageDrop"
        @visible-change="elDropdownvisibleChange($event)"
        :hide-on-click="true"
        v-if="false"
    >
      <div :class="['el-dropdown-link']">
        <div class="staff-box">
          <template v-if="!pluginToolInfo.id">
            <span style="color: #dcdfe6">{{$t('common.selectPlaceholder')}}</span>
          </template>
          <template v-else>
            <span>{{ pluginToolInfo.name }}</span>
          </template>
          <em
              v-show="!showData"
              class="el-icon-arrow-down"
          ></em>
          <em
              v-show="showData"
              class="el-icon-arrow-up"
          ></em>
        </div>
      </div>
      <el-dropdown-menu
          id="plugin-dropdown"
          slot="dropdown"
      >
        <el-dropdown-item
            class="plugin-dropdown-item"
            :class="isAskBot ? 'askbot-dropdown-item' : 'popup-dropdown-item'"
        >
          <div class="pluginTree">
            <el-tree
                :data="pluginData"
                :props="{
                  label: 'name',
                  children: 'pluginTools',
                }"
                node-key="id"
                :default-expanded-keys="defaultExpanded"
                :expand-on-click-node="false"
                check-on-click-node
                @node-click="onhandleClick"
                ref="recommendTree">
              <div class="tree-node" slot-scope="{ data }">
                <div class="tree-node-left">
                  {{ data.name }}
                </div>
                <i class="el-icon-check" v-if="pluginToolInfo.id == data._id"></i>
              </div>
            </el-tree>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="pluginConditions">
      <div class="title">
        <span>{{$t('botLink.visibleRange')}}</span>
        <div class="addPluginCondition" @click="addCondition">{{$t('botLink.addCondition')}}</div>
      </div>
      <div class="right-consultation-scope">
        <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in pluginCondition"
             :key="conditionsIndex">
          <div class="right-consultation-scope-top-content">
            <div class="select-cell-attr select-cell-inner">
              <el-popover
                  :ref="'condition-popover'+conditionsIndex"
                  placement="bottom-start"
                  width="300"
                  v-model="conditionsItem.visible"
                  popper-class="choose-conditions-poprver"
                  :visible-arrow="false">
                <div
                    :class="['choose-user-attrible one-column-ellipsis']"
                    slot="reference">
                  <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                  <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                  <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">标签</span>
                </div>
                <el-tree
                    style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                    :data="conditionOptions"
                    :props="{
                      children: 'children',
                      label: 'label',
                      id:'value',
                      }"
                    default-expand-all
                    node-key="id"
                    @node-click="(data) => onNodeClickCondition(data,conditionsItem,conditionsIndex)"
                    ref="userAttributeTree">
                  <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']"
                        slot-scope="{node,data}">
                    <span class="custom-tree-node-left">
                      <span class="data-label">{{ $t('botLink.' + data.value) }}</span>
                    </span>
                  </span>
                </el-tree>
              </el-popover>
            </div>
            <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
              <el-select
                  size="small"
                  v-model="conditionsItem.relation"
                  :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                  @change="saveKnowledgeScope"
              >
                <el-option
                    v-for="item in attrRelations"
                    :key="item.value"
                    :label="$t('common.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <!-- 弹框选择组织架构 -->
            <div
                @click="openStaffPopup(conditionsItem,conditionsIndex)"
                class="select-cell-attrv select-cell-inner one-column-ellipsis"
                v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
              <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                  <span class="have-checked-intent" style="cursor: pointer;color:#606266;"
                        v-for="(tagCell, tagCellIndex) in conditionsItem.expect" :key="tagCellIndex">
                    <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'"
                               :openid="tagCell.name" v-if="isOpenData()"></open-data>
                    <span v-else>{{ tagCell.name }}</span>
                    <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                  </span>
                </template>
                <div class="placeholder-text" v-else style="cursor: pointer;color:#606266;">{{$t('common.selectPlaceholder')}}</div>
              </template>
              <!-- 下拉选择自定义字段的值 -->
              <template v-if="conditionsItem.type === 'CUSTOMIZE'">
                  <el-select
                      class="choose-tag-select"
                      size="small"
                      v-model="conditionsItem.expect"
                      filterable
                      multiple
                      collapse-tags
                      @change="saveKnowledgeScope"
                      placeholder="输入或选择值">
                      <el-option
                          v-for="item in tagsOptions"
                          :key="item.id"
                          :label="item.label"
                          :value="item.id">
                      </el-option>
                  </el-select>
              </template>
            </div>
          </div>
          <div :class="['del-ondition']">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"
               @click="delCondition(conditionsIndex)"></i>
          </div>
          <div class="and-box" v-if="conditionsIndex !== pluginCondition.length - 1">{{$t('botLink.or')}}</div>
        </div>
      </div>
    </div>
    <div class="knowledgeCondition">
      <data-set ref="dataSet" @saveKnowledgeScope="saveKnowledgeScope"></data-set>
    </div>
    <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
      <div slot="popup-name">{{$t('botLink.dialogMemberTitle')}}</div>
      <div slot="popup-con" class="member-contanier">

        <staffCustomer
            dataType="staff"
            :isMultiple="true"
            @onNodeClick="onNodeClick"
            :memberInfo4Client="memberInfo4Client"
            ref="staffCustomer"></staffCustomer>
      </div>
      <div slot="dialog-footer">
        <el-button @click="cancelChooseMember" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="preserStaff" class="confirm-btn">{{$t('common.confirm')}}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import DataSet from "./dataSet";
import OpenData from "./openData";
import Popup from "../components/popup";
import staffCustomer from "@/components/staffCustomer.vue";

export default {
  name: "identificationCondition",
  components: { Popup, OpenData, DataSet,staffCustomer },
  data(){
    return{
      tagsOptions:[],
      pluginName:'',
      showData:false,
      defaultExpanded:[],
      pluginData:[],
      pluginCondition:[
        {
          type:"ALL",
          relation:null,
          expect:[]
        }
      ],
      conditionOptions:[
        {
          value:"ALL",
          label:"所有人",
        },{
          value:"STAFF",
          label:"员工",
          children:[{
            value:"STAFF_OR_DEPARTMENT",
            label:"员工 / 部门"
          },{
            value:"CUSTOMIZE",
            label:"标签",
            key: 'label',
            type: "SELECT"
          }]
        }
      ],
      attrRelations:[
        { name: "为空", value: "IS_NULL" },
        { name: "不为空", value: "IS_NOT_NULL" },
        { name: "包含任意", value: "CONTAIN_ANY" },
        { name: "不包含任意", value: "NOT_CONTAIN_ANY" },
      ],
      dialogMemberTree:false,
      memberInfo4Client:{
        clientInfo: [] // 选中数据
      },
      activeItem:"",
      pluginToolInfo:{
        id:"",
        name:"",
        pluginId:""
      }
    }
  },
  props:{
    isAskBot:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    // 获取标签数据
    getTagsOptions(corpId, callBack){
        this.$http.get(this.requestUrl.bindV2.getTagsOptions + "?corpId=" + corpId).then((res) => {
            if (res.data.code == 0) {
                this.tagsOptions = res.data.data;
                if (callBack) {
                    let tagListIds = [];
                    if (this.tagsOptions && this.tagsOptions.length != 0) {
                        tagListIds = this.tagsOptions.map(item => item.id);
                    }
                    callBack(tagListIds);
                }
            }
        })
    },
    getPlugin(){
      let url = this.requestUrl.knowledgeBase.pluginList + '?mainId=' + localStorage.getItem('_mainId');
      this.$http.get(url).then(res => {
        console.log('res 218',res)
        if (res.data){
          this.pluginData = res.data;
          if (res.data.length > 0 && res.data[0].pluginTools.length > 0){
            this.pluginToolInfo.id = res.data[0].pluginTools[0]._id;
            this.pluginToolInfo.name = res.data[0].pluginTools[0].name;
            this.pluginToolInfo.pluginId = res.data[0].id
          }
        }
      })
    },
    elDropdownvisibleChange(value){
      this.showData = value;
    },
    onhandleClick(data){
      if (data.pluginId && data._id){
        this.pluginToolInfo.id = data._id;
        this.pluginToolInfo.name = data.name;
        this.pluginToolInfo.pluginId = data.pluginId;
        let messageDrop = this.$refs.messageDrop;
        if (messageDrop) {
          messageDrop.visible = false;
        }
      }
      this.$emit('saveKnowledgeScope')
    },
    changeVariable(){

    },
    removeCondition(){

    },
    addCondition(){
      this.pluginCondition.push({
        type:"ALL",
        relation:null,
        expect:[]
      })
      this.$emit('saveKnowledgeScope')
    },
    onNodeClickCondition(data,conditionsItem){
      conditionsItem.visible = false;
      if (data.value === "STAFF") {
        conditionsItem.visible = true;
        return false;
      };
      if (data.value === 'STAFF_OR_DEPARTMENT'){
        conditionsItem.relation = "CONTAIN_ANY";
      }
      conditionsItem.key = "";
      if (data.value === 'CUSTOMIZE'){
        conditionsItem.relation = "IS_NULL";
        conditionsItem.key = "label";
      }
      conditionsItem.type = data.value;

      conditionsItem.expect = [];
      this.$emit('saveKnowledgeScope')
    },
    openStaffPopup(conditionsItem, conditionsIndex) {
      console.log("conditionsIndex", conditionsItem, conditionsIndex);
      this.activeItem = conditionsItem;
      this.memberInfo4Client.clientInfo = JSON.parse(JSON.stringify(conditionsItem.expect));
      this.dialogMemberTree = true;
    },
    isOpenData(){

    },
    delCondition(index){
      this.pluginCondition.splice(index,1)
      this.$emit('saveKnowledgeScope')
    },
    onNodeClick(node){
      let obj = JSON.parse(JSON.stringify(node));
      obj.name = obj.label || obj.name;
      let AddObj = {
        id: obj.id,
        name: obj.label,
        type: obj.type
      };
      if (obj.checked) {
        this.memberInfo4Client.clientInfo.push(AddObj);
        this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo)
      } else {
        this.memberInfo4Client.clientInfo.forEach((v, i) => {
          if (obj.id == v.id) {
            this.memberInfo4Client.clientInfo.splice(i, 1)
          }
        })
        this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo)
      }
    },
    cancelChooseMember(){
      this.dialogMemberTree = false;
    },
    preserStaff(){
      this.activeItem.expect = JSON.parse(JSON.stringify(this.memberInfo4Client.clientInfo));
      this.dialogMemberTree = false;
      this.$emit('saveKnowledgeScope')
    },
    handlerArr(tempArr) {
      let result = [];
      let obj = {};
      for (let i = 0; i < tempArr.length; i++) {
        if (!obj[tempArr[i].id]) {
          result.push(tempArr[i]);
          obj[tempArr[i].id] = true;
        }
        ;
      }
      ;
      return result;
    },
    saveKnowledgeScope(){
      this.$emit('saveKnowledgeScope')
    }
  },
  mounted() {

  }
};
</script>

<style scoped lang="less">
.identificationCondition{
  text-align: left;
  margin-top: 14px;
  .title{
    padding-bottom: 10px;
    color: #616161;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .addPluginCondition{
      color: #366aff;
      cursor: pointer;
    }
  }
  .pluginConditions{
    margin-top: 10px;
    .right-consultation-scope {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;

      .right-consultation-scope-top {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        .right-consultation-scope-top-content {
          height: 38px;
          border: 1px solid #DCDFE6;
          border-radius: 5px;
          display: flex;
          align-items: center;
          flex: 1;
        }

        .del-ondition {
          margin-left: 10px;
          color: #366AFF;
          cursor: pointer;
        }
        .and-box{
          position: absolute;
          left: 0px;
          top: 36px;
          width: 18px;
          height: 18px;
          font-size: 12px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: none;
          color: #ffffff;
          z-index: 99;
        }
        .select-cell-et {
          flex: none;
          width: 200px;
        }

        .select-cell-eteq {
          flex: none;
          width: 100px;
        }

        .select-cell-etv {
          flex: auto;
        }

        .select-cell-attr {
          flex: none;
          width: 110px;
          display: flex;
          align-items: center;

          .choose-user-attrible {
            text-align: left;
            width: 100%;
            height: 100%;
            cursor: pointer;
            padding: 0 15px;
            color: #606266;

            &.empty-user-attrible {
              color: #d5d5d5;
            }
          }
        }

        .select-cell-attreq {
          flex: none;
          width: 100px;
          display: flex;
          align-items: center;

          .el-input {
            .el-input__inner {
              border: none;
              padding: 0 10px;
            }

            .el-select__caret {
              display: none;
            }
          }
        }

        .select-cell-attrv {
          flex: auto;
          // display: flex;
          // align-items: center;
          // padding: 6px;
          // width: 100%;
          line-height: 36px;
          height: 36px;
          overflow: hidden;
          .el-select {
            width: 100% !important;
            overflow: hidden;
          }

          .attr-value {
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          .select-cell-attrv-placeholder {
            color: #c0c4cc;
            cursor: pointer;
          }

          .choose-tag-select {
            .el-input__inner {
              border: none;
            }
          }
        }
      }

      .add-condition-bot-btn {
        display: flex;
        align-items: center;

        .el-button {
          width: 30px;
          height: 30px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          padding: 5px;

          i {
            font-size: 12px;
          }
        }

        span {
          cursor: pointer;
          color: #366AFF;
        }
      }
      /deep/.el-input {
        .el-input__inner {
          border: none;
          padding: 0 10px;
        }

        .el-select__caret {
          display: none;
        }
      }

    }
  }
  .staff-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 38px;
    width: calc(100% - 30px);
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    padding: 0 15px;
    cursor: pointer;
  }
  .knowledgeCondition{
    width: 100%;
    margin-top: 14px;
  }
}
#el-dropdown-plugin{
  width: 100%;
  .el-dropdown-link{
    width: 100%;
  }
}
.askbot-dropdown-item{
  width: 776px;
}
.popup-dropdown-item{
  width: 514px;
}
/deep/.plugin-dropdown-item{
  padding: 0!important;
  .pluginTree{
    max-height: 300px;
    overflow-y: scroll;
    .el-tree-node__content{
      height: 38px;
      line-height: 38px;
    }
    .tree-node{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      i{
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
.el-popper{
  z-index: 6666!important;
}
</style>