
const basicControls = [
    {
    id: "",
    widgetId: "",
    name: "单行输入框",
    type: "INPUT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"单行输入框：",
        }
    },
    extInfo: {
        isDelete:true,
        inputType: "TEXT",
        maxNum: 100,
        placeholder: "请输入",
        range: {
            start: "",
            end: "",
        },
        unit: "",
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "reg",
                exp: "",
                handler: "",
            },
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "多行输入框",
    type: "TEXTAREA",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"多行输入框：",
        }
    },
    extInfo: {
        isDelete:true,
        maxNum: 100,
        placeholder: "请输入",
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "富文本",
    type: "RICHTEXT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"富文本：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请输入",
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 3,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "单选",
    type: "RADIO",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"单选：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        options: [
            {
                label: "选项一",
                value: "选项一",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项二",
                value: "选项二",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项三",
                value: "选项三",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
        ],
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "dict",
                exp: "",
                handler: "",
            },
        },
        dependent: {},
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "多选",
    type: "CHECKBOX",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"多选：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        options: [
            {
                label: "选项一",
                value: "选项一",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项二",
                value: "选项二",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项三",
                value: "选项三",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
        ],
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "dict",
                exp: "",
                handler: "",
            },
        },
        dependent: {},
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "下拉",
    type: "SELECT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"下拉：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        selectType: "单选", // 单选/多选,
        options: [
            {
                label: "选项一",
                value: "选项一",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项二",
                value: "选项二",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
            {
                label: "选项三",
                value: "选项三",
                _default: false,
                relationItems: [],
                requiredItems: [],
            },
        ],
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "dict",
                exp: "",
                handler: "",
            },
        },
        dependent: {},
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "级联",
    type: "CASCADER",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"级联：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        selectType: "单选", // 单选/多选,
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "dict",
                exp: "",
                handler: "",
            },
        },
        cascadeDown: {
            options: [
                {
                    label: "默认",
                    value: "默认",
                    _default: false,
                    relationItems: [],
                    requiredItems: [],
                },
            ],
        },
        dependent: {},
    },
    autoInput: {
        enable: false,
        source: [],
        rule: {
            type: "",
            exp: "",
            handler: "",
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "图片选择",
    type: "IMAGE_SELECT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"图片选择：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        selectType: "单选", //多选",
        autoInput: {
            enable: false,
            source: [],
            rule: {
                type: "",
                exp: "",
                handler: "",
            },
        },
        options: [
            {
                label: '',
                value: '',
                url: '',
                _default: false,
                watermark: {
                    enable: false, //是否添加水印,
                    text: ''
                },
                relationItems: [],
            }
        ],
        dependent: {},
    },
    autoInput: {
        enable: false,
        source: [],
        rule: {
            type: "",
            exp: "",
            handler: "",
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    top: 0,
    left: 0,
    height: 3,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "时间日期",
    type: "TIME_PICKER",
    description: "",
    defaultValue: '',
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"时间日期：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        mold: "DATE", // 时间格式 年-月-日/其他等
        pattern: "", // 其他类型对应的格式化规则
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "时间日期范围",
    type: "TIME_PICKER_RANGE",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"时间日期范围：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        to: {
            name: "",
            placeholder: "请选择",
            defaultValue: "",
        },
        mold: "DATE", // 时间格式 年-月-日/其他等
        pattern: "", // 其他类型对应的格式化规则
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "图片",
    type: "IMAGE",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"图片：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        limit: "1",
        watermark: {
            enable: false,
            text: "",
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 3,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "视频",
    type: "VIDEO",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"视频：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        limit: "1",
        watermark: {
            enable: false,
            text: "",
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 3,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "附件",
    type: "ATTACHMENT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"附件：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        limit: "1",
        watermark: {
            enable: false,
            text: "",
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 3,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "说明文字",
    type: "EXPLAIN",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"说明文字：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请输入说明内容",
        url: "",
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    disabled: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
},
{
    id: "",
    widgetId: "",
    name: "系统账号",
    type: "ACCOUNT",
    description: "",
    defaultValue: "",
    visible: {
        enabled: false,
        extInfo: {
            displayFormat:"系统账号：",
        }
    },
    extInfo: {
        isDelete:true,
        placeholder: "请选择",
        selectType: "单选", // 单选/多选,
        ref: {
            scope: [],
            filter: {
                enable: false,
                config: [
                    {
                        type: "",
                        items: [
                            {
                                category: "", // 账号组/账号,
                                name: "",
                                value: "",
                            },
                        ],
                    },
                ],
            },
        },
    },
    required: false,
    isSystemField: false,
    remark: {
        enable: false,
        text: "",
    },
    searchable: false,
    top: 0,
    left: 0,
    height: 2,
    width: 6,
}
];
const seniorControls = [
  {
      id: "",
      widgetId: "",
      name: "公式",
      type: "FORMULA",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请输入",
          formula: {
              exp: "", //公式表达式(对应上面输入框富文本) html格式
              expText: "提示名称=字段标题+字段标题", // 公式表达式(对应上面输入框富文本) 纯文本格式
              var: [],
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "引用字段",
      type: "REF",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          ref: {
              source: "",
              sourceName: "",
              field: "",
              fieldName: "",
              type: "",
              vars: [],
          },
          chooseShow: [], //选择显示
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
          fieldDetail: {},
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "复制字段",
      type: "COPY",
      description: "",
      defaultValue: "",
      copy: {},
      extInfo: {
          placeholder: "请选择",
          ref: {
              source: "",
              sourceName: "",
              field: "",
              fieldName: "",
              type: "",
          },
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
          fieldDetail: {},
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "关联数据模板",
      type: "REF_TEMPLATE",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          ref: {
              source: "",
              sourceName: "",
              field: "",
              type: "",
              vars: [],
          },
          chooseShow: [], //选择显示
          selectType: "单选", // 单选/多选,
          supportAdd: false,
          addBtnName: "",
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "组织架构",
      type: "ORG",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "",
          selectType: "单选", // 单选/多选,
          ref: {
              scope: [],
              // {
              //     type: '',// 员工/上下游,
              //     name: '',
              //     field: '',//默认是name
              // }
              filter: {
                  enable: false,
                  config: [
                      {
                          type: "", // 员工/上下游,
                          items: [
                              {
                                  category: "", // 组织架构/用户,
                                  name: "",
                                  value: "",
                              },
                          ],
                      },
                  ],
              },
          },
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "用户",
      type: "USER",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          selectType: "单选", //  单选/多选,
          ref: {
              scope: [],
              filter: {
                  enable: false,
                  config: [
                      {
                          type: "",
                          items: [
                              {
                                  category: "", // 组织架构/用户,
                                  name: "",
                                  value: "",
                              },
                          ],
                      },
                  ],
              },
          },
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false, // true/false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      widgetId: "",
      name: "系统账号",
      type: "ACCOUNT",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          selectType: "单选", // 单选/多选,
          ref: {
              scope: [],
              filter: {
                  enable: false,
                  config: [
                      {
                          type: "",
                          items: [
                              {
                                  category: "", // 账号组/账号,
                                  name: "",
                                  value: "",
                              },
                          ],
                      },
                  ],
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      name: "省份",
      type: "PROVINCE",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          selectType: "单选", // 单选/多选,
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      name: "城市",
      type: "CITY",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          selectType: "单选", // 单选/多选,
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
  {
      id: "",
      name: "行业",
      type: "INDUSTRY",
      description: "",
      defaultValue: "",
      extInfo: {
          placeholder: "请选择",
          selectType: "单选", // 单选/多选,
          autoInput: {
              enable: false,
              source: [],
              rule: {
                  type: "",
                  exp: "",
                  handler: "",
              },
          },
      },
      required: false,
      isSystemField: false,
      remark: {
          enable: false,
          text: "",
      },
      searchable: false,
      top: 0,
      left: 0,
      height: 2,
      width: 6,
  },
];
const systemControls = [
    {
        id: "",
        widgetId: "",
        name: "擅长处理的工单类型",
        type: "TYPE_MATE",
        description: "",
        defaultValue: "",
        systemFieldKey: "TYPE_MATE",
        visible: {
            enabled: false,
            extInfo: {
                displayFormat:"擅长处理的工单类型：",
            }
        },
        extInfo: {
            isDelete:true,
            placeholder: "请选择",
            options: [
                {
                    label: "选项一",
                    value: "选项一",
                    _default: false,
                    relationItems: [],
                    requiredItems: [],
                },
                {
                    label: "选项二",
                    value: "选项二",
                    _default: false,
                    relationItems: [],
                    requiredItems: [],
                },
                {
                    label: "选项三",
                    value: "选项三",
                    _default: false,
                    relationItems: [],
                    requiredItems: [],
                },
            ],
            autoInput: {
                enable: false,
                source: [],
                rule: {
                    type: "",
                    exp: "",
                    handler: "",
                },
            },
        },
        required: false,
        isSystemField: true,
        remark: {
            enable: false,
            text: "",
        },
        searchable: false,
        disabled: false,
        top: 0,
        left: 0,
        height: 2,
        width: 6,
    },
    {
        id: "",
        widgetId: "",
        name: "工作时间",
        type: "WORKING_TIME",
        description: "",
        defaultValue: "",
        systemFieldKey: "WORKING_TIME",
        visible: {
            enabled: false,
            extInfo: {
                displayFormat:"工作时间：",
            }
        },
        extInfo: {
            isDelete:true,
            placeholder: "请选择",
            options: [],
            autoInput: {
                enable: false,
                source: [],
                rule: {
                    type: "",
                    exp: "",
                    handler: "",
                },
            },
        },
        required: true,
        isSystemField: true,
        remark: {
            enable: false,
            text: "",
        },
        searchable: false,
        disabled: false,
        top: 0,
        left: 0,
        height: 2,
        width: 6,
    },
    {
        id: "",
        widgetId: "",
        name: "最大同时处理工单数", // limitOrderNum
        type: "MAX_NUM_OF_ORDERS_PROCESSED",
        description: "",
        defaultValue: "",
        systemFieldKey: "MAX_NUM_OF_ORDERS_PROCESSED",
        visible: {
            enabled: true,
            extInfo: {
                displayFormat:"处理中:<span class='ask-component-placeholder ck-widget'>处理中工单数</span>/ 最大可处理:<span class='ask-component-placeholder ck-widget'>最大可处理工单数</span>",
            }
        },
        extInfo: {
            isDelete:true,
            placeholder: "请选择",
            options: [],
            autoInput: {
                enable: false,
                source: [],
                rule: {
                    type: "",
                    exp: "",
                    handler: "",
                },
            },
        },
        required: true,
        isSystemField: true,
        remark: {
            enable: false,
            text: "",
        },
        searchable: false,
        disabled: false,
        top: 0,
        left: 0,
        height: 2,
        width: 6,
    },
];
const allTemplateConstrols = [
  {
      type: "basic", // 基础控件
      text: "基础控件",
      list: basicControls,
  },
  // {
  //     type: "senior", // 高级控件
  //     text: "高级控件",
  //     list: seniorControls,
  // },
  {
      type:'system',// 系统控件
      text:'系统控件',
      list:systemControls
  }
];
let filterFieldType = ['RICHTEXT','IMAGE_SELECT','IMAGE','VIDEO','ATTACHMENT']
const workOrderTemConstrols = JSON.parse(JSON.stringify(allTemplateConstrols)).map((field) => {
  if (field.type == "senior") {
    let arr = ['引用字段', '关联数据模板',]
    field.list = field.list.filter(item => {
      return arr.includes(item.name) && item
    })
  } else {
    field.list = field.list.filter(item =>{
      return !filterFieldType.includes(item.type)
    })
  }
//   for (let index = 0; index < field.list.length; index++) {
//     if(field.list[index].type == 'CASCADE') {
//       field.list[index].type = 'CASCADER'
//     }
    // field.list[index] = {
    //   columnId: "",
    //   field: field.list[index],
    //   defaultValue: field.list[index].defaultValue,
    //   required: field.list[index].required,
    //   isSystemField: field.list[index].isSystemField
    // } 

//   }
    return field
})
function templateDatas(list) {
    console.log(list,111);
    let newDats = JSON.parse(JSON.stringify(list))
    newDats.forEach(item=>{
        item.allFields.forEach(items=>{
            if (items.type === 'INPUT' && !items.extInfo.inputType){
                items.extInfo.inputType = 'TEXT'
            }
            if ((items.type === 'CASCADER' || items.type == 'SELECT') && !items.extInfo.selectType){
                items.extInfo.selectType = '单选'
            }
            if (items.type === 'TIME_PICKER' && (!items.extInfo.mold ||(items.extInfo.mold ==='DATA_TIME') )){
                items.extInfo.mold = 'DATE_TIME'
            }
            if (items.type === 'TIME_PICKER' && (!items.extInfo.mold ||(items.extInfo.mold ==='DATA') )){
                items.extInfo.mold = 'DATE'
            }
            if (items.type === 'EXPLAIN' && items.defaultValue){
                items.extInfo.placeholder = items.defaultValue
            }
        })
    })
    console.log(newDats,'newDats');
    return newDats
}
function requiredMessage(field,columnName) {
  if(!field.name) {
      return  '请完善' + columnName + '下字段信息'
  }
  
  let typeList = ['SELECT','CHECKBOX',"RADIO","IMAGE_SELECT"]

  if(typeList.includes(field.type)) {
      if(!field.extInfo.options ||(field.extInfo.options && field.extInfo.options.length == 0)) {
          return '请完善' + columnName + '下' + field.name + '选项信息'
      }
      if (field.type == 'IMAGE_SELECT' && field.extInfo.options.length){
          for (let i=0;i<field.extInfo.options.length;i++){
              if (!field.extInfo.options[i].url || !field.extInfo.options[i].value){
                  return '请完善图片选择下选项'
              }
          }
      }
  }
  if(field.type == 'CASCADER') {
      if(!field.extInfo.cascadeDown.options ||(field.extInfo.cascadeDown.options && field.extInfo.cascadeDown.options.length == 0)) {
          return '请完善' + columnName + '下' + field.name + '选项信息'
      }
  }
  if(field.type == 'EXPLAIN') {
      if(!field.extInfo.placeholder) {
          return '请完善' + columnName + '下' + field.name + '说明信息' 
      }
  }

  if(field.type == 'COPY') {
      if(requiredMessage(field.extInfo.fieldDetail,columnName) != true) {
        return  '请完善' + columnName + '下' + field.name + '字段信息' 
      }
  }
  if(field.type == "REF") {
      if(!field.extInfo.ref.field) {
          return  '请完善' + columnName + '下' + field.name + '字段信息'  
      }
  }
  if(field.type == "REF_TEMPLATE") {
      if(!field.extInfo.ref.source) {
          return  '请完善' + columnName + '下' + field.name + '数据模版信息'  
      }
      if(!field.extInfo.ref.field) {
          return  '请完善' + columnName + '下' + field.name + '取值信息'  
      }
  }
  return true
}
function uniqueData(tempArr) {
    let result = [];
    let obj = {};
    if (!tempArr){
        return []
    }
    for (let i = 0; i < tempArr.length; i++) {
        if (!obj[tempArr[i].value]) {
            result.push(tempArr[i]);
            obj[tempArr[i].value] = true;
        };
    };
    return result;
}
function refTemplateName(list,vars){
    let templateList = [];
    templateList = list.map(res=>{
        let info = {}
        for (let key in res.fieldValues) {
            if (Array.isArray(vars)){
                let vals = ''
                let values = ''
                vars.forEach(val=>{
                    vals+=val
                    if (res.fieldValues[val]){
                        values+=res.fieldValues[val]
                    }
                })
                info[vals] = values
                info.id = res.id
                info.name = values
            }
            else {
                if (vars == key){
                    info[key] = res.fieldValues[key]
                    info.id = res.id
                    info.name = res.fieldValues[key]
                }
            }

        }
        return info
    })
    return templateList
}
export {
    workOrderTemConstrols,
    templateDatas,
    requiredMessage,
    allTemplateConstrols,
    systemControls,
    uniqueData,
    refTemplateName
}