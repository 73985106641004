// /***
//  * 自定义弹框事件 (处理多次弹出事件)
//  *  */
import Vue from 'vue'
class AlertFn {
    constructor(obj) {
        if (!obj) return;
        if (typeof obj == 'object') {
            this.alertdata(obj.type || 'info', obj.message)
        } else {
            this.alertdata('info', obj)
        }
    }

    error(str) {
        this.alertdata('error', str)
    }

    success(str) {
        this.alertdata('success', str)
    }

    warning(str) {
        this.alertdata('warning', str)
    }

    alertdata(type, str) {
        Vue.prototype.$message.closeAll();
        Vue.prototype.$message({
            type: type,
            message: str
        })
    }
}
new AlertFn()
function messageFn(type, message, duration=3000) {
    this.$message.closeAll()
    this.$message({
        type: type,
        message: message,
        duration:duration
    })
}

export default {
    messageFn,
}
