<template>
    <div :class="['login-page',pageType !== 'loading' ? 'bg' : '',pageType === 'tips' ? 'tips' : '']">
        <div 
            v-if="pageType === 'loading'"
            style=" background-color: white;height: 100vh;width:100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
            <div style="width: 5%;">
                <img src="../../../assets/images/new_loading.gif" style="width: 100%">
            </div>
        </div>
        <div :class="['login-box',loginType === 'logon' ? 'logon' : '']" v-else-if="pageType === 'login'">
            <div class="title">
                <span v-if="loginType === 'pwd'" class="text">邀请您加入</span>
                <div class="go-back" v-else @click="goBack">
                    <i class="iconfont guoran-tongyichicun-18-22-fanhui"></i>
                    <span>返回</span>
                </div>
                <i class="iconfont guoran-askbot1"></i>
            </div>
             <div class="dept-box">
                <div class="company">
                    <div class="iconfont guoran-gongsiicon"></div>
                    <open-data v-if="source === 1" :openid="companyName" :type="'departmentName'"></open-data>
                    <span v-else>{{companyName}}</span>
                </div>
                <div class="dept">
                    <div></div>
                    <span class="name">
                        <open-data v-if="source === 1" :openid="departmentName" :type="'departmentName'"></open-data>
                        <span v-else>{{departmentName}}</span>
                    </span>
                    <div></div>
                </div>
            </div>
            <div :class="['telphone',isFocus ? 'is-focus' : '',loginType === 'code' || loginType === 'logon' ? 'code-input': 'pwd-input']">
                <span>手机号</span>
               <div class="input-box">
                    <el-input placeholder="请输入" v-model.trim="telphone" @blur="changeTelphone" @focus="focusFn" >
                        <template slot="prepend">+86</template>
                    </el-input>
                    <template v-if="loginType === 'code' || loginType === 'logon'">
                        <div 
                            class="send"
                            v-if="!sendIng"
                            @click="sendCode">
                            {{sendCodeText}}
                        </div>
                        <div 
                            v-else
                            class="send sendIng"
                            @click="sendCode">
                            {{sendTimes }}s
                        </div>
                    </template>
               
               </div>
                <div class="gr-tips bgc-gr-tips" v-if="telphoneTips !== ''">
                    {{ telphoneTips }}
                </div>
            </div>
            <div :class="['pwd',telphoneTips == '' ? '' : 'have-tip']" v-if="loginType === 'pwd'">
                <span>密码</span>
                <el-input  
                    auto-complete='new-password'  
                    autocomplete='off'
                    v-model.trim="password" 
                    :type="[flag?'text':'password']"  
                    placeholder="请输入"
                    @blur="changePassword">
                    <i slot="suffix" :class="['iconfont',flag?'guoran-tongyichicun-chakan':'guoran-bukejian']"  autocomplete="auto" @click="flag=!flag" />
                </el-input>
                <div class="gr-tips bgc-gr-tips" v-if="passwordTips !== ''">
                    {{ passwordTips }}
                </div>
            </div>
            <div :class="['code-box',telphoneTips !== '' ? 'min' : '']" v-if="loginType === 'code' || loginType === 'logon'"> 
                <span>验证码</span>
                <codeInput @complete="complete" @blurEvent="blurEvent" :key="codeInputkey"></codeInput>
                <div class="gr-tips bgc-gr-tips" v-if="codeTips !== ''">
                    {{ codeTips }}
                </div>
            </div>
            <template v-if="loginType === 'pwd' || loginType === 'code'">
                <div :class="['code-forget',passwordTips === '' && codeTips === '' ? 'have-tips' : 'no-tips']">
                    <span class="code-login" @click="switchLoginType">{{loginType === 'pwd' ? '验证码登录' : '密码登录'}}</span>
                </div>
                <el-button type="primary" round @click="goLogin">登录</el-button>
                <div class="go-logon">还没有AskBot账号？ <span @click="gologon">立即注册</span></div>
            </template>
            <template v-else-if="loginType === 'logon'">
                <div :class="['pwd',codeTips == '' ? '' : 'have-tip']">
                    <span>密码</span>
                    <el-input  
                        auto-complete='new-password'  
                        autocomplete='off'
                        v-model.trim="logonPassword" 
                        :type="[logonFlag?'text':'password']"  
                        placeholder="请输入"
                        @blur="changePasswordNew">
                        <i slot="suffix" :class="['iconfont',logonFlag?'guoran-tongyichicun-chakan':'guoran-bukejian']"  autocomplete="auto" @click="logonFlag=!logonFlag" />
                    </el-input>
                    <div class="gr-tips bgc-gr-tips" v-if="passwordNewTips !== ''">
                        {{ passwordNewTips }}
                    </div>
                </div>
                <div :class="['pwd again-pwd',passwordNewTips === '' ? '' : 'have-tip',passwordAgainTips === '' ? '' : 'have-tip-bottom']">
                    <span>确认密码</span>
                    <el-input  
                        auto-complete='new-password' 
                        autocomplete='off'
                        v-model.trim="logonPasswordAgain" 
                        :type="[logonFlagAgain?'text':'password']"  
                        placeholder="请输入"
                        @blur="changePasswordAgain">
                        <i slot="suffix" :class="['iconfont',logonFlagAgain?'guoran-tongyichicun-chakan':'guoran-bukejian']"  autocomplete="auto" @click="logonFlagAgain=!logonFlagAgain" />
                    </el-input>
                    <div class="gr-tips bgc-gr-tips" v-if="passwordAgainTips !== ''">
                        {{ passwordAgainTips }}
                    </div>
                </div>
                <el-button type="primary" round @click="goLogonAndLogin">注册并登录</el-button>
            </template> 
        </div>
        <div class="scan-box" v-else-if="pageType === 'scan'">
            <div class="title">
                <div>
                    请使用
                    {{source === 1 ? '企业微信' : '钉钉'}}
                    <open-data v-if="source === 1" :openid="companyName" :type="'departmentName'"></open-data>
                    <span v-else>{{companyName}}</span>
                    扫码绑定您的第三方账号</div>
                <div class="tips">绑定账户后才能加入组织</div>
            </div>
            <div class="dept-box">
                <div class="company">
                    <div class="iconfont guoran-gongsiicon"></div>
                     <open-data v-if="source === 1" :openid="companyName" :type="'departmentName'"></open-data>
                    <span v-else>{{companyName}}</span>
                </div>
                <div class="dept">
                    <div></div>
                    <span class="name">
                        <open-data v-if="source === 1" :openid="departmentName" :type="'departmentName'"></open-data>
                        <span v-else>{{departmentName}}</span>
                    </span>
                    <div></div>
                </div>
            </div>
            <el-button type="primary" round @click="getScan">扫码绑定并加入组织</el-button>
        </div>
        <div class="scan-box" v-else-if="pageType === 'scanIng'">
            <iframe :src="scanData" frameborder="0"></iframe>
        </div>
        <situationAll 
            v-else 
            :situationAllType.sync="situationAllType" 
            :pageType="pageType"
            ></situationAll>
    </div>
</template>

<script>
import codeInput from './components/code-input.vue';
import situationAll from './components/situationAll.vue'
import {redirectUri} from "../../../const/redirectUri";
import OpenData from "@/components/openData";
import agentConfig from "@/common/wechat/agentConfig";
export default {
    data(){
        return {
            telphone:'',
            password:"",
            flag:false,
            telphoneTips:'',
            passwordTips:"",
            isFocus:false,
            loginType:"pwd", // pwd 密码登录   code 验证码登录
            sendCodeText:'发送',
            sendIng:false,
            sendTimes:60,
            codeValue:'',
            codeTips:'',
            logonFlag:false,
            logonFlagAgain:false,
            logonPassword:"",
            logonPasswordAgain:"",
            pageType:'loading', //loading  login   scan scanIng tips 
            inviteCode:"",
            userId:null,
            mainId:null,
            corpId:null,
            departmentId:null,
            expire:false, // 链接是否过期
            situationAllType:-1, // 1 邀请码过期  2 成功加入-无需审核  3 成功加入-需要审核   4 请使用本地浏览器进行访问  5 请在电脑端使用本地浏览器进行访问  6 拒绝申请
            departmentName:'',
            companyName:"",
            source:1, //  1 企微  2 钉钉
            passwordNewTips:"",
            passwordAgainTips:"",
            scanData:"",
            currentIp:"",
            codeInputkey:0,
        }
    },
    components:{codeInput,situationAll,OpenData},
    mounted(){
        this.codeInputkey = 0;
        this.codeTips = ''
        console.log(this.$route.query,'路由参数');
        if(this.$route.query.isScan){
            //-2扫码主体不一致   -1 用户信息有误  0 已通过审核 1  需要审核 2  无需审核  3 已拒绝
           if(localStorage.getItem('inviteScanStatus') == -1){
           } else if(localStorage.getItem('inviteScanStatus') == -2){
                this.inviteCode = localStorage.getItem('inviteCode');
                this.$message.warning('扫码主体不一致,请重新扫码');
                let inviteDeptData = JSON.parse(localStorage.getItem('inviteDeptData'))
                this.source = Number(inviteDeptData.source);
                this.departmentName = inviteDeptData.departmentName;
                this.companyName = inviteDeptData.companyName;
                this.pageType = 'scan';
           } else if(localStorage.getItem('inviteScanStatus') == 1){
               this.situationAllType = 3;
               this.pageType = 'tips';
           } else if(localStorage.getItem('inviteScanStatus') == 2 || localStorage.getItem('inviteScanStatus') == 0){
               this.situationAllType = 2;
               this.pageType = 'tips';
           } else if(localStorage.getItem('inviteScanStatus') == 3){
               this.situationAllType = 6;
               this.pageType = 'tips';
           }
        //    localStorage.removeItem('inviteScanStatus')
        } else if (this.$route.query.inviteCode){
            this.pageType = 'loading';
            this.getCurrentIp();
            this.inviteCode = this.$route.query.inviteCode;
            localStorage.setItem('inviteCode',this.inviteCode)
            this.substitution();
        } else {
            // this.pageType = 'login';
        }
    },
    methods:{
        resetData(){
            this.codeInputkey++;
            this.codeTips = '';
            // this.telphoneTips = ''
        },
        getCurrentIp() {
            this.$http_ignore_auth.get("https://www.askbot.cn/ext-api/ip").then((res) => {
                this.currentIp = res.data.ip;
            });
        },
        // 邀请码置换邀请链接
        substitution(){
            this.$http.get(this.requestUrl.invite.substitution + '?inviteCode=' + this.inviteCode)
                .then((res) => {
                    if (res.data) {
                       setTimeout(() => {
                        //    window.location.href = 'https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fportal.test.askbot.cn%2Fdingtalk-login-callback.html&response_type=code&scope=openid%20corpid&prompt=consent&client_id=suitedpsq6c9bqzcdwfcj&state=ab0f90737c8b4f2d85ba2157e4473110'
                            // this.userId = this.getQueryString(url,'userId');
                            this.mainId = res.data.data.mainId;
                            this.corpId = res.data.data.corpId;
                            this.departmentId = res.data.data.departmentId;
                            this.expire = res.data.data.expire;
                            this.pageType = 'tips';
                            // 是否需要审批   邀请人  要加入的部门/公司  类型判断(钉钉/企微)
                            localStorage.setItem('inviteData',JSON.stringify(res.data.data))
                            //  1 失效  2 成功加入-无需审核  3 成功加入-需要审核   4 请使用本地浏览器进行访问  5 请在电脑端使用本地浏览器进行访问
                            if(this.expire === 'true' || this.expire){
                                this.situationAllType = 1;
                            } else {
                                // 手机端
                                // if(this.isMobile()){
                                //     this.situationAllType = 5;
                                // } else if(navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1){
                                //     // 电脑端微信
                                //     this.situationAllType = 4;
                                // } else {
                                //     this.situationAllType = 0;
                                // }
                                if(this.isMobile()){
                                    this.situationAllType = 5;
                                } else {
                                    if (/MicroMessenger/.test(window.navigator.userAgent)) { 
                                        console.log('微信客户端'); 
                                        this.situationAllType = 4;
                                    } else if (/AlipayClient/.test(window.navigator.userAgent)) { 
                                        console.log('支付宝客户端');
                                        this.situationAllType = 4;
                                    } else {
                                        console.log('其他浏览器');
                                        this.situationAllType = 0;
                                    }
                                }
                            }
                           
                            if(this.situationAllType === 0){
                                this.getInviteDepartment();
                                this.pageType = 'login';
                                this.loginType = 'pwd';
                            }
                            // this.getScan()

                        },300)
                        
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
        },
        // 获取邀请部门信息
        getInviteDepartment(){
            this.$http.get(this.requestUrl.invite.getInviteDepartment + '?departmentId=' + this.departmentId)
            .then((res) => {
                if (res.data.code === "0") {
                    localStorage.setItem('inviteDeptData',JSON.stringify({
                        source:res.data.data.source,
                        departmentName:res.data.data.name,
                        companyName:res.data.data.companyName,
                    }))
                    this.source = res.data.data.source;
                    this.departmentName = res.data.data.name;
                    this.companyName = res.data.data.companyName;
                    this.$nextTick(() => {
                        if(this.source == 1){
                            this.initWxAgentConfig(this.corpId);
                        }
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        initWxAgentConfig(corpId){
            //agentConfig
            let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
            let url =  this.requestUrl.wechatKF.agentConfigNew + "?corpId="+corpId+ "&url="+currentUrl;
            this.$http.get(url).then(res=>{
                if (res.data&&res.data.data){
                    agentConfig(corpId,res.data.data.data,(res)=>{
                        WWOpenData.bind(this.$el);
                    });
                }
            })
        },
        getQueryString(url,name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
            var query = url.substr(url.lastIndexOf("?") + 1);
            var r = query.match(reg); //search,查询？后面的参数，并匹配正则
            if (r != null) return decodeURIComponent(r[2]);
            return null;

        },
        isMobile() {
            let flag =  navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        focusFn(){
            this.isFocus = !this.isFocus;
        },
        
        gologon(){
            this.loginType = 'logon';
            this.resetData()
        },
        // 切换登录方式
        switchLoginType(){
            this.resetData()
            this.loginType = this.loginType === 'code' ? 'pwd' : 'code';
        },
        goBack(){
           this.resetData()
            this.pageType = 'login';
            this.loginType = 'pwd'
        },
        complete(data){
            document.querySelector('.login-page').addEventListener('click',(e) => {
                console.log(e,'ee');
                console.log(e.target.className,'ee');
                if(e.target.className !== 'input-content'){
                    this.codeValue = data.join(',').replace(/,/g,'');
                    this.codeTips = this.codeValue.length === 6 ? '' : '请输入6位验证码！'
                    console.log(data,this.codeValue,'complete');
                }
            })
           

        },
        changeTelphone(){
            let reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
            if(!reg.test(this.telphone)) {
                this.telphoneTips = "手机号码格式不正确！";
            } else {
                this.telphoneTips = "";
            }
        },
        changePassword(){
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            if (this.password === '') {
                this.passwordTips = "请输入密码";
            } else {
                if(!reg.test(this.password)) {
                    this.passwordTips = "必需包含字母、数字且超过8位";
                } else {
                    this.passwordTips = '';
                }
            }
        },
        changePasswordNew(){
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            if (this.logonPassword === '') {
                this.passwordNewTips = "请输入密码";
            } else {
                if(!reg.test(this.logonPassword)) {
                    this.passwordNewTips = "必需包含字母、数字且超过8位";
                } else {
                    this.passwordNewTips = '';
                }
            }
        },
         changePasswordAgain(){
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            if (this.logonPasswordAgain === '') {
                this.passwordAgainTips = "请输入密码";
            } else {
                if(!reg.test(this.logonPasswordAgain)) {
                    this.passwordAgainTips = "必需包含字母、数字且超过8位";
                } else {
                    this.passwordAgainTips = '';
                }
            }
        },
        blurEvent(flag,data){
            if(data.length < 6 && flag){
                this.codeTips = '请输入6位验证码！'
            }
        },
        // 发送验证码
        sendCode(){
            // 111111qq
            if(this.telphoneTips === ''){
                this.$http_ignore_auth.get("/api/register/check?phone=" + this.telphone).then((res) => {
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.telphoneTips = "手机号已经存在";
                            return false;
                        } else {
                            this.$http_ignore_auth.post("/api/code/sms/send?phone=" + this.telphone).then((res) => {
                                if (res.data.code != 0) {
                                    this.$message.error(res.data.message);
                                } else {
                                    // 调用检验接口
                                    this.sendTimes = 60;
                                    this.sendIng = true;
                                    let timer = setInterval(() => {
                                        this.verifyTime =
                                            this.sendTimes --;
                                        if (this.sendTimes == 0) {
                                            this.sendCodeText = "重新获取";
                                            this.sendIng = false;
                                            clearInterval(timer);
                                        }
                                    }, 1000);
                                }
                            });
                        }
                    }
                })
            }
            
        },
        // 密码登录
        goLogin(){
            console.log(this.codeValue,'codeValue');
            let url = ''
            if(this.loginType === 'pwd'){
                url = this.requestUrl.invite.clientLogin+"?phone="+this.telphone+"&password="+this.password
            } else {
                url = this.requestUrl.invite.clientLogin+"?phone="+this.telphone+"&verificationCode="+this.codeValue
            }
            this.$http_ignore_auth.post(url).then((res) => {
                if (res.data.code === "0" && res.data.data) {
                    this.loginToken(res.data.data,'login')
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        
        // 注册并登录
        goLogonAndLogin(){
           this.$http_ignore_auth.post(this.requestUrl.invite.registerAndLogin+"?phone="+this.telphone+"&password="+this.logonPasswordAgain+"&ip="+this.currentIp+"&verificationCode="+this.codeValue)
                .then((res) => {
                    console.log(res,'res');
                    if (res.data.code === "0" && res.data.data) {
                        this.loginToken(res.data.data,'logon')
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 用token获取用户信息
        loginToken(token,type){
            this.$http_ignore_auth.post(this.requestUrl.invite.loginAuth+"?token="+token)
                .then((res) => {
                    if (res.data.code === "0" && res.data.data) {
                        // if(this.mainId !== res.data.data.mainId){
                            // this.$message.warning('登录主体与邀请主体不一致,请重新登录');
                            // return false;
                        // } else {
                            this.userId = res.data.data.userId;
                            localStorage.setItem('inviteUserId',this.userId)
                            this.$message.success(type === 'logon' ? '注册并登录成功' : '登录成功');
                            this.pageType = 'scan';
                        // }
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 获取二维码
        getScan(){
            // console.log(redirectUri[process.env.VUE_APP_NODE_ENV].callback);
            // console.log(encodeURIComponent(redirectUri[process.env.VUE_APP_NODE_ENV].callback),'地址');
            if(this.source === 1){
                // 企微
                let url="/scrm-api/we-work/sso/3rd-qr-connect?state="+this.inviteCode+
                        "&redirect_uri="+
                        encodeURIComponent( redirectUri[process.env.VUE_APP_NODE_ENV].callback+"/invite-qrcode-login-callback.html?inviteCode="+this.inviteCode);
                this.$http.get(url).then((res) => {
                        if (res.status === 200) {
                            this.scanData = res.data;
                            this.pageType = 'scanIng';
                            document.location.href = res.data;
                        }
                    })
                    .catch(() => {
                        this.$message.warning("重新操作");
                    });
            } else {
                // 钉钉
                let url= "/ding-talk-api/auth/askbot/url?state="+this.inviteCode+"&redirectUri="+
                         encodeURIComponent( redirectUri[process.env.VUE_APP_NODE_ENV].callback+"invite-dingtalk-login-callback.html?inviteCode="+this.inviteCode);
                    
                this.$http.get(url).then((res) => {
                    if (res.status === 200) {
                        this.scanData = res.data;
                        this.pageType = 'scanIng';
                        document.location.href = res.data;
                    }
                })
                .catch(() => {
                    this.$message.warning("重新操作");
                });
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import "./../../../assets/less/inviteEnterLogin/login.less";
</style>