<template>
  <div class="basicInfo">
    <el-form
        ref="bindForm"
        :rules="dingdingBindRules"
        :model="dingdingBindForm"
    >
      <el-form-item
          :label="$t('botLink.bindEnterpriseName')"
          prop="corpName"
      >
        <el-input
            v-model="dingdingBindForm.corpName"
            :placeholder="$t('botLink.enterpriseNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="corpId"
          :label="$t('botLink.bindDingCorpIdTip')"
      >
        <el-input
            v-model="dingdingBindForm.corpId"
            :placeholder="$t('botLink.enterpriseIDPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('botLink.appName')"
          prop="agentName"
      >
        <el-input
            v-model="dingdingBindForm.agentName"
            :placeholder="$t('botLink.appNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="agentId"
          :label="$t('botLink.dingAgentIdTip')"
      >
        <el-input
            v-model="dingdingBindForm.agentId"
            :placeholder="$t('botLink.agentIdPla')"
        ></el-input>
        <span
            v-if="warningVisible"
            style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
            class="warning-text"
        >*{{$t('botLink.agentIdTip')}}</span
        >
      </el-form-item>
      <el-form-item
          prop="appKey"
      >
        <el-input
            v-model="dingdingBindForm.appKey"
            :placeholder="$t('botLink.appKeyPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="secret"
      >
        <el-input
            v-model="dingdingBindForm.secret"
            :placeholder="$t('botLink.secretPla')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "dingBasicInfo",
  data(){
    return{
      dingdingBindForm: {
        agentId: "",
        corpId: "",
        corpName: "",
        secret: "",
        appKey: "",
        agentName: "",
      },
      dingdingBindRules: {
        agentName: [
          {
            required: true,
            message: this.$t('botLink.appNamePla'),
            trigger: "blur",
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" },
        ],
        corpName: [
          {
            required: true,
            message: this.$t('botLink.enterpriseNamePla'),
            trigger: "blur",
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" },
        ],
        corpId: [
          {
            required: true,
            message: this.$t('botLink.enterpriseIDPla'),
            trigger: "blur",
          },
          { max: 36, message: this.$t('channel.rule36Msg'), trigger: "blur" },
        ],
        agentId: [
          {
            required: true,
            message: this.$t('botLink.agentIdPla'),
            trigger: "blur",
          },
          { max: 32, message: this.$t('channel.rule32Msg'), trigger: "blur" },
        ],
        secret: [
          {
            required: true,
            message: this.$t('botLink.secretPla'),
            trigger: "blur",
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" },
        ],
        appKey: [
          {
            required: true,
            message: this.$t('botLink.appKeyPla'),
            trigger: "blur",
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" },
        ],
      },
      warningVisible:false,
    }
  }
};
</script>

<style scoped lang="less">

</style>