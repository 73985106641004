<template>
  <div id="personal">
    <div class="content-container">
      <div class="personalHeader">
        <div class="leftInfo">
          <span class="headerName">个人中心</span>
        </div>
        <div class="rightInfo">
          <el-button  @click="preserveClick" type="danger" v-if="formLabelAlign.userRole ==='人工客服'">保存</el-button>
          <el-button  @click="preserveClick" type="primary" v-if="formLabelAlign.userRole !=='人工客服'">保存</el-button>
        </div>
      </div>
      <div style="display: flex">
        <el-menu
                :default-active="active"
                class="el-menu-vertical-demo"
                style="width: 160px;text-align: center;"
                @select="handleSelect"
        >
          <el-menu-item index="personal" :class="{activeClass:active==='personal'}">
            <span slot="title">个人中心</span>
          </el-menu-item>
          <el-menu-item index="password" :class="{activeClass:active==='password'}" v-if="userSource!==2">
            <span slot="title">修改密码</span>
          </el-menu-item>
        </el-menu>
        <div class="personalContent">
          <div class="formInfo" v-if="active ==='personal'">
            <div style="display: flex;">
              <div style="flex: 0.85">
                <div class="petName">
                  <div class="formTitle">
                    <span style="color: red">*</span>
                    <label>昵称</label>
                  </div>
                  <el-input v-model="formLabelAlign.petName" style="width: 80%" placeholder="请输入昵称"></el-input>
                </div>
                <div class="realName">
                  <div class="formTitle">
                    <span style="color: red">*</span>
                    <label>姓名</label>
                  </div>
                  <el-input v-model="formLabelAlign.realName" style="width: 80%" placeholder="请输入姓名"></el-input>
                </div>
              </div>

              <div class="headImage" style="flex: 0.25">
                <el-upload
                        class="upload-demo"
                        ref="upload"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :on-error="handleError"
                        :before-upload="beforeAvatarUpload"
                        :data="uploadAvatarParams"
                        :with-credentials="false"
                >
                  <div class="block"><el-avatar :size="70" :src="fileList" fit="cover"></el-avatar></div>
                  <div style="padding-top: 10px" :class="{ImClassT:formLabelAlign.userRole ==='人工客服',otherClassT:formLabelAlign.userRole!=='人工客服'}">修改头像</div>
                </el-upload>
              </div>
            </div>
            <div class="userPhone">
              <div class="formTitle">
                <label>手机号码</label>
              </div>
              <el-input v-model="formLabelAlign.phone" style="width: 100%" placeholder="请输入手机号"></el-input>
            </div>
            <div class="accountNumber">
              <div class="formTitle">
                <label>账号</label>
              </div>
              <el-input v-model="formLabelAlign.accountNumber" style="width: 100%" disabled></el-input>
            </div>
            <div class="userRole">
              <div class="formTitle">
                <label>用户角色</label>
              </div>
              <el-input v-model="formLabelAlign.userRole" style="width: 100%" disabled></el-input>
            </div>
            <div v-if="haveWorkOrderPermission" class="workorderType">
              <div class="formTitle">
                <label>擅长处理哪些类型的工单</label>
              </div>
              <el-dropdown
                  trigger="click"
                  id="el-dropdown-work-order"
                  @visible-change="
                      elDropdownvisibleChange
                  "
                  :hide-on-click="false"
              >
                  <span
                      :class="[
                          'el-dropdown-link',
                          'el-dropdown-link-workorder',
                          workOrderSelectIng
                              ? 'active-el-dropdown-link'
                              : '',
                      ]"
                  >
                      <span
                          v-if="workOrderType.length != 0"
                          class="
                              el-dropdown-work-order-content
                          "
                      >
                          <el-tag
                              v-for="(
                                  workOrder,
                                  workOrderIndex
                              ) in workOrderType"
                              :key="workOrderIndex"
                              size="small"
                              :type="(!(formLabelAlign.admin || (!formLabelAlign.admin && !workOrder.adminAssign)))?'info':''"
                              @close="
                                  closeWorkOrder(
                                      workOrderIndex
                                  )
                              "
                              :disabled="!(formLabelAlign.admin || (!formLabelAlign.admin && !workOrder.adminAssign))"
                              :closable="formLabelAlign.admin || (!formLabelAlign.admin && !workOrder.adminAssign)"
                              >{{
                                  workOrder.workOrderTypeName
                              }}</el-tag
                          >
                      </span>
                      <span
                          v-if="workOrderType.length == 0"
                          class="el-dropdown-placeholder"
                          >请选择工单类别</span
                      >
                      <span class="el-icon">
                          <em
                              v-show="!workOrderSelectIng"
                              class="el-icon-arrow-down"
                          ></em>
                          <em
                              v-show="workOrderSelectIng"
                              class="el-icon-arrow-up"
                          ></em>
                      </span>
                  </span>
                  <el-dropdown-menu
                      id="work-order-dropdown-person"
                      slot="dropdown"
                  >
                      <el-dropdown-item
                          class="work-order-dropdown-item-person"
                      >   
                          <div style="height: 300px;overflow: auto;">
                            <div v-for="(
                                cellTree, cellTreeIndex
                              ) in workOrderTypeTree"
                              :key="cellTreeIndex">
                              <el-tree
                                  :data="cellTree"
                                  node-key="id"
                                  :ref="
                                      'ordertree_' +
                                      cellTreeIndex
                                  "
                                  default-expand-all
                                  :highlight-current="true"
                                  :expand-on-click-node="
                                      false
                                  "
                                  show-checkbox
                                  :default-checked-keys="
                                      workOrderDefaultCheckedKeys
                                  "
                                  :check-on-click-node="true"
                                  @check-change="
                                      (node, val, c_val) =>
                                          WorkOrderTypeHandleCheckChange(
                                              node,
                                              val,
                                              c_val,
                                              cellTreeIndex
                                          )
                                  "
                                  @node-click="treeNodeClick"
                                  check-strictly
                                  :props="
                                      typeTreeDefaultProps
                                  "
                              >
                              </el-tree>
                            </div>
                          </div>
                      </el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="accountBind">
              <div class="formTitle">
                <label>账号绑定</label>
              </div>
              <account-bind></account-bind>
            </div>
          </div>
          <div class="password" v-if="active === 'password'">
            <el-form :label-position="labelPosition" label-width="80px" :model="passWord">
              <el-form-item label="原始密码">
                <span slot="label"><span style="color: red">*</span>原始密码</span>
                <el-input v-model="passWord.oldPassword"  show-password  style="width: 80%;" placeholder="请输入原始密码" @blur="oldPasswordBlur"></el-input>
              </el-form-item>
              <el-form-item label="新密码">
                <span slot="label"><span style="color: red">*</span>新密码</span>
                <el-input v-model="passWord.newPassword"  show-password  style="width: 80%;" placeholder="请输入新密码" @blur="newPasswordBlur"></el-input>
              </el-form-item>
              <el-form-item label="确认密码">
                <span slot="label"><span style="color: red">*</span>确认密码</span>
                <el-input v-model="passWord.againPassword" show-password  style="width: 80%;" placeholder="请确认密码" @blur="againPasswordBlur"></el-input>
              </el-form-item>
            </el-form>
            <div style="padding: 8px 5px">密码需要满足以下条件</div>
            <div style="padding: 0px 5px;color: red">
              * 至少 6 个字符长度
            </div>
          </div>
        </div>
      </div>

    </div>

    <leftMenu></leftMenu>
  </div>
</template>

<script>
import leftMenu from "../menu/leftMenu";
import accountBind from "../../components/accountBind";
import {redirect2LoginPage} from "../../const/redirectUri";

export default {
name: "personal",
  data(){
  return{
    labelPosition: 'top',
    formLabelAlign:{
      petName:'小果',
      realName:'',
      phone:null,
      accountNumber:'',
      userRole:'',
      userDelGoodAtWorkOrders: [],
      admin: false
    },
    fileList:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    uploadUrl:'/api-open/oss/public',
    uploadAvatarParams: {
      token:'8c98087dfd2d48f856d8c95c09115def'
    },
    uploadImg:'',
    userInfo:{},
    uid:localStorage.getItem('_uid'),
    active:'personal',
    passWord:{
      oldPassword:'',
      newPassword:'',
      againPassword:''
    },
    OriginalPassword:'',//原始密码
    userSource:0,
    accountList:[],
    // 工单类别
    workOrderType: [],
    workOrderTypeTree: [],
    typeTreeDefaultProps: {
        children: "children",
        label: "workOrderTypeName",
    },
    workOrderSelectIng: false,
    isShowWorkOrder: true,
    workOrderDefaultCheckedKeys: [],
    haveWorkOrderPermission: false
  }
  },
  components:{leftMenu,accountBind},
  mounted() {
    this.getUserInfo()
    console.log('Uid:',localStorage.getItem('_uid'))
    console.log('roleId',localStorage.getItem('roleId'))
  },
  methods:{
    treeNodeClick(data, node, el){
      if (data.disabled) {
        this.$message.warning('您无法取消该擅长的类型，请联系管理员取消')
      }
    },
    elDropdownvisibleChange(val) {
        this.workOrderSelectIng = val;
    },
    WorkOrderTypeHandleCheckChange(node, val, c_val, index) {
        if (val) {
            node.treeIndex = index;
            node.adminAssign = this.formLabelAlign.admin;
            node.workOrderTypeId = node.id;
            node.userId = localStorage.getItem('_uid');
            this.workOrderType.push(node);
        } else {
            this.workOrderType.forEach((item, index) => {
                if (item.id == node.id) {
                    this.workOrderType.splice(index, 1);
                }
            });
        }
        this.workOrderType = [...this.workOrderType];
    },
    closeWorkOrder(index) {
        let refTree = "ordertree_" + this.workOrderType[index].treeIndex;
        let oldTreeSelect = this.$refs[refTree][0].getCheckedKeys();
        let currentTreeSelect = [];
        oldTreeSelect.forEach((item) => {
            if (item != this.workOrderType[index].id) {
                currentTreeSelect.push(item);
            }
        });
        this.$refs[refTree][0].setCheckedKeys(currentTreeSelect);
        this.workOrderType.splice(index, 1);
    },
    // 上传头像
    handleAvatarSuccess(res, file) {
        if(res!=='')
        {
            this.uploadImg=res
        }
      console.log(res)
      // this.form.avatar=res.data.data
      this.fileList = URL.createObjectURL(file.raw)
      console.log(this.fileList)
      console.log('上传图片成功')
    },
    handleError() {
      console.log('图片上传失败')
    },
    beforeAvatarUpload(file) {
      const isSupportedFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpeg'
      const isLte2M = file.size / 1024 / 1024 <= 2

      if (!isSupportedFormat) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式 或 JPEG 格式!')
      }
      if (!isLte2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isSupportedFormat && isLte2M
    },
    fltDisabled(list){
      let adminSetIds = this.workOrderType.map(item => {
        if (item.adminAssign) {
          return item.id
        }
      })
      // adminSetIds = ["61b1be60b88ea6121f6d6aae", "61b6bc3e33295a3d239ed3db"];
      let tampArr = list;
      const fltTree = (list) => {
        list.forEach(item => {
          if (adminSetIds.indexOf(item.id) == -1) {
            item.disabled = false;
          } else {
            if (!this.formLabelAlign.admin) {
              item.disabled = true;
            }
          }
          if (item.children != null && item.children.length != 0) {
            fltTree(item.children)
          }
        })
      }
      fltTree(tampArr);
      return tampArr;
    },
    //获取用户信息
    getUserInfo(){
        let uid=localStorage.getItem('_uid')
      this.$http.get('/api/account/appoint/'+uid).then(res=>{
        if(res.data.code==='0')
        {
          console.log('currentUserInfo:',res.data.data)
          this.userInfo=res.data.data
          //初始化姓名
          if(this.userInfo.realName!==null)
          {
            this.formLabelAlign.realName=this.userInfo.realName
          }
          //初始化账户
          this.formLabelAlign.accountNumber=this.userInfo.username
          //初始化昵称
          if(this.userInfo.nickName!==null)
          {
            this.formLabelAlign.petName=this.userInfo.nickName
          }
          //初始化电话
          if(this.userInfo.tel!==null)
          {
            this.formLabelAlign.phone=this.userInfo.tel
          }
          //初始化角色
          if(this.userInfo.roles[0]!==null)
          {
            this.formLabelAlign.userRole=this.userInfo.roles[0]
          }
          //初始化角色是否为管理员
          if(this.userInfo.admin != null)
          {
            this.formLabelAlign.admin = this.userInfo.admin;
          }
          // 判断是否展示工单类型选择
          if(this.userInfo.haveWorkOrderPermission != null)
          {
            this.haveWorkOrderPermission = this.userInfo.haveWorkOrderPermission;
          } else {
            this.haveWorkOrderPermission = false;
          }
          //初始化角色
          if(this.userInfo.userGoodAtWorkOrders != null)
          {
            this.workOrderType = this.userInfo.userGoodAtWorkOrders;
            this.workOrderDefaultCheckedKeys = this.workOrderType.map(item => item.id)
          }
          if (this.userInfo.workOrderTypes != null) {
            this.workOrderTypeTree = this.userInfo.workOrderTypes.map((item, index) => {
              item.treeIndex = index;
              return [item];
            });
            // this.workOrderTypeTree.unshift([
            //   {
            //       id: "all",
            //       workOrderTypeName: "根类别",
            //   },
            // ]);
            this.workOrderTypeTree.forEach(item => {
              item = this.fltDisabled(item);
            })
          }
          
          //初始化来源
          if(this.userInfo.source!==null)
          {
            this.userSource=this.userInfo.source
          } else {
            let roles=localStorage.getItem('roleId')
            let rolesName=''
            if(roles ==='0')
            {
                rolesName='系统测试'
            }
            if(roles ==='1')
            {
                rolesName='超级管理员'
            }
            if(roles ==='2')
            {
                rolesName='机器人管理员'
            }
            if(roles ==='3')
            {
                rolesName='机器人配置员'
            }
            if(roles ==='4')
            {
                rolesName='人工客服'
            }
            if(roles ==='5')
            {
                rolesName='工单管理员'
            }
            if(roles ==='6')
            {
                rolesName='工单成员'
            }
            this.formLabelAlign.userRole=rolesName
          }
          //初始化头像
          if(this.userInfo.profilePhoto!==null ||this.userInfo.profilePhoto!=='')
          {
            this.fileList=this.userInfo.profilePhoto
            this.uploadImg=this.userInfo.profilePhoto
          }
        }
      })
    },
    //保存用户信息
    preserveClick(){
      if(this.active==='personal'){
        this.$refs.upload.submit();
        if(this.formLabelAlign.petName==='')
        {
          this.$message.error('请完善昵称')
          return
        }
        if(this.formLabelAlign.realName==='')
        {
          this.$message.error('请完善姓名')
          return
        }
        if(this.formLabelAlign.phone!==null) {
          let test = !/^1[34578]\d{9}$/.test(this.formLabelAlign.phone)
          if (test) {
            this.$message({
              message: "请填写正确的手机号",
              type: "warning",
              duration: 3000
            });
            return
          }
        }
        console.log(this.userInfo.roles)
        let userInfo={
          nickName:this.formLabelAlign.petName,
          realName:this.formLabelAlign.realName,
          tel:this.formLabelAlign.phone,
          username:this.userInfo.username,
          profilePhoto:this.uploadImg,
          userGoodAtWorkOrders: this.workOrderType.map(item => {
            if (this.formLabelAlign.admin) {
              return item;
            } else {
              if (!item.adminAssign) {
                return item;
              }
            }
          }),
          admin: this.formLabelAlign.admin
        }
        console.log(userInfo)
        this.$http.put('/api/account/appoint/'+this.uid,userInfo).then(res=>{
          if (res.data.code==='0')
          {
            this.$message.success('保存成功')
          }
        })
      }
      else if(this.active === 'password')
      {
        if(this.passWord.oldPassword==='')
        {
          this.$message({
            type:'error',
            message:'原始密码不能为空',
            duration:2000
          })
          return;
        }
        if(this.passWord.oldPassword!=='')
        {
          let userInfo={username:this.userInfo.username,password:this.passWord.oldPassword}
          this.$http.post('/sso/login-r',{username:this.userInfo.username,password:this.passWord.oldPassword}).then(res=>{
            console.log('密码',res)
            if(res.data === 0)
            {
              this.$message({
                message:'密码错误',
                type:'error',
                duration:2000
              })
              return
            }
          })
        }
        if(this.passWord.newPassword==='')
        {
          this.$message({
            type:'error',
            message:'新密码不能为空',
            duration:2000
          })
          return;
        }
        if(this.passWord.newPassword!=='')
        {
          if(this.passWord.newPassword.length < 6)
          {
            this.$message({
              type:'error',
              message:'密码长度最少为6位',
              duration:2000
            })
            return;
          }
          if(this.passWord.newPassword===this.passWord.oldPassword)
          {
            this.$message({
              type:'error',
              message:'新密码不能与原始密码一样',
              duration:2000
            })
            return;
          }
        }
        if(this.passWord.againPassword==='')
        {
          this.$message({
            type:'error',
            message:'请输入确认密码',
            duration:2000
          })
          return;
        }
        if(this.passWord.againPassword!=='')
        {
          if(this.passWord.againPassword!==this.passWord.newPassword)
          {
            this.$message({
              type:'error',
              message:'密码不一致',
              duration:2000
            })
            return;
          }
        }
        let url='/sso/change-pass'
        this.$http.post(url,{username:this.formLabelAlign.accountNumber,oldPassword:this.passWord.oldPassword,newPassword:this.passWord.newPassword}).then(res=>{
          console.log(res)
          if(res.data=== 1)
          {
            this.$message({
              message:'密码修改成功',
              type:'success',
              duration:2000
            })
            this.passWord.oldPassword=''
            this.passWord.newPassword=''
            this.passWord.againPassword=''
            setTimeout(()=>{
              this.$http.delete("/api/sso/auth/sign-out").then(res => {
                if (res.status === 200) {
                  redirect2LoginPage();
                } else {
                  this.$message.error(res.message);
                }
              });
            },2000)
          }

        })

      }

    },
    //验证手机号码是否正确
    codePhone(){
      if(this.formLabelAlign.phone!=='')
      {
        if (!/^1[34578]\d{9}$/.test(this.formLabelAlign.phone)) {
          this.$message({
            message: "请填写正确的手机号",
            type: "warning",
            duration: 3000
          });
        }
      }
    },
    handleSelect(key,path){
      console.log(key,path)
      this.active=key
    },
    //原始密码框失去焦点时校验
    oldPasswordBlur(){
      if(this.passWord.oldPassword!=='')
      {
        this.$http.post('/sso/login-r',{username:this.userInfo.username,password:this.passWord.oldPassword}).then(res=>{
          console.log('密码',res)
          if(res.data === 0)
          {
            this.$message({
              message:'密码错误',
              type:'error',
              duration:2000
            })
            return
          }
        })
      }


    },
    //新密码输入失焦时校验
    newPasswordBlur(){
      /* if (/[^\w\.\/]/ig.test(this.passWord.newPassword))
       {
         this.$message({
           type:'error',
           message:'密码中不要含有中文',
           duration:2000
         })
         return;
       }*/
      if(this.passWord.newPassword!=='')
      {
        if(this.passWord.newPassword.length < 6)
        {
          this.$message({
            type:'error',
            message:'密码长度最少为6位',
            duration:2000
          })
          return;
        }
        if(this.passWord.newPassword===this.passWord.oldPassword)
        {
          this.$message({
            type:'error',
            message:'新密码不能与原始密码一样',
            duration:2000
          })
          return;
        }
      }
    },
    //重新输入密码时失去焦点校验
    againPasswordBlur(){
      /*  if (/[^\w\.\/]/ig.test(this.passWord.againPassword))
        {
          this.$message({
            type:'error',
            message:'密码中不要含有中文',
            duration:2000
          })
          return;
        }*/
      if(this.passWord.againPassword!=='')
      {
        if(this.passWord.againPassword!==this.passWord.newPassword)
        {
          this.$message({
            type:'error',
            message:'密码不一致',
            duration:2000
          })
          return;
        }
      }

    },
  }
}
</script>

<style lang="less">
@import "../../assets/less/personal/personal";


.ImClassT{
  color: red;
}


.otherClassT{
  color: #366AFF;
}
.activeClass{
  border-right: 2px solid #366AFF;
}
</style>
