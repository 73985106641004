<template>
  <div id="add-notice">
    <div class="gr-content-container">
      <div class="personalHeader">
        <top-header>
          <div slot="setup-header-left" class="setup-header-left">
            <span class="setup-header-left-icon">
              <i class="iconfont guoran-gengxingonggao"></i></span>
            <span>更新公告</span>
          </div>
          <div class="setup-header-right-l" slot="setup-header-right-l">
            <el-button size="small" type="primary" @click="addNotice">
              <i class="iconfont guoran-a-16-13"></i>
              发 布</el-button>
          </div>
        </top-header>
      </div>
      <div class="add-notice-content">
        <div class="add-notice-content-inner">
          <!-- <div class="add-notice-cell">
                        <span class="add-notice-cell-title">标题</span>
                        <div class="add-notice-cell-content">
                            <el-input
                                v-model="title"
                                placeholder="请输入标题"
                            ></el-input>
                        </div>
                    </div> -->
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">标题</span>
            <div class="add-notice-cell-content">
              <el-input placeholder="请输入标题" v-model="summary" maxlength="64">
              </el-input>
            </div>
          </div>
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">发布时间</span>
            <div class="add-notice-cell-content">
              <el-date-picker v-model="createTime" type="datetime" placeholder="选择发布时间">
              </el-date-picker>
            </div>
          </div>
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">系统</span>
            <div class="add-notice-cell-content">
              <el-select v-model="systemName" placeholder="请选择系统">
                <el-option v-for="item in sysOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <template v-if="systemName == 'APP'">
                <div class="switch-item">
                  <el-switch v-model="appUpgradeSetting.upgrade" active-color="#366aff"
                    @change="changeUpgrade('upgrade')">
                  </el-switch>
                  <span class="switch-title">是否更新安装包</span>
                </div>
              </template>
            </div>
          </div>
          <div class="add-notice-cell" v-if="appUpgradeSetting.upgrade">
            <div class="add-notice-cell-content">
              <div class="switch-item">
                <el-switch v-model="appUpgradeSetting.iosApp.upgrade" active-color="#366aff"
                  @change="changeUpgrade('IOS')">
                </el-switch>
                <span class="switch-title">IOS系统</span>
              </div>
            </div>
          </div>
          <div class="add-notice-cell" v-if="appUpgradeSetting.iosApp.upgrade">
            <span class="add-notice-cell-title">IOS版本号</span>
            <div class="add-notice-cell-content">
              <el-input v-model="appUpgradeSetting.iosApp.version" placeholder="请输入"></el-input>
            </div>
          </div>
          <div class="add-notice-cell" v-if="appUpgradeSetting.upgrade">
            <div class="add-notice-cell-content">
              <div class="switch-item">
                <el-switch v-model="appUpgradeSetting.androidApp.upgrade" active-color="#366aff"
                  @change="changeUpgrade('Android')">
                </el-switch>
                <span class="switch-title">Android系统</span>
              </div>
            </div>
          </div>
          <template v-if="appUpgradeSetting.androidApp.upgrade">
            <div class="add-notice-cell">
              <span class="add-notice-cell-title">Android版本号</span>
              <div class="add-notice-cell-content">
                <el-input v-model="appUpgradeSetting.androidApp.version" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="add-notice-cell">
              <span class="add-notice-cell-title">Android安装包</span>
              <div class="add-notice-cell-content">
                <el-upload class="upload-android-bag" :action="uploadUrl" :data="uploadAvatarParams"
                  :show-file-list="false" :on-success="handleAvatarSuccess">
                  <div class="upload-input">
                    <div class="upload-text">{{ appUpgradeSetting.androidApp.apkUrl ? appUpgradeSetting.androidApp.apkUrl
                      : '点击上传安装包' }}</div>
                    <i class="arsenal_icon arsenalshangchuan" style="color: #366aff"></i>
                  </div>
                </el-upload>
              </div>
            </div>
          </template>
          <div class="add-notice-cell" v-if="systemName == 'APP'">
            <div class="add-notice-cell-title">
              推送给
            </div>
            <div class="add-notice-cell-content push-channels">
              <el-checkbox-group v-model="pushChannels">
                <el-checkbox label="IOS">IOS</el-checkbox>
                <el-checkbox label="Android">Android</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">内容</span>
            <div class="add-notice-cell-content">
              <ckeditor :editor="ckeditor.editor" v-model="updateNoticeDescription" :config="editorConfig"
                @ready="ckeditorReady"></ckeditor>
            </div>
          </div>
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">强弹窗提醒
              <switchIcon :switchStatus="strongNotice" :needAutomatic="true" @changeswitch="changeSwitch">
              </switchIcon>
            </span>
          </div>
          <div class="add-notice-cell">
            <span class="add-notice-cell-title">在哪个系统强弹窗提醒</span>
            <div class="add-notice-cell-content">
              <el-select v-model="strongNoticeSystems" multiple placeholder="请选择">
                <el-option v-for="item in sysOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SideNav ref="leftMenuObj"></SideNav>
  </div>
</template>

<script>
import { requestUrl } from "./../../api/requestUrl";
import SideNav from "./../menu/SideNav.vue";
import TopHeader from "./../../components/top-header.vue";
import CKEDITOR from "ckeditor";
import { MyCustomUploadAdapterPlugin } from "../../assets/js/ckeditor";
import MyFileUpload from "../../assets/js/ckeditorfileUpload/plugin_file"
import switchIcon from "../../components/switch-icon.vue"
export default {
  name: "addnotice",
  data () {
    return {
      ckeditor: {
        editor: CKEDITOR.ClassicEditor,
      },
      editorConfig: {
        extraPlugins: [MyFileUpload, MyCustomUploadAdapterPlugin],
        toolbar: ["MyFileUpload"],
        askPluginListener: [{
          event: 'UPLOAD',
          process: this.uploadImageAndFile,
        }]
      },
      sysOptions: [
        { value: "OPEN", label: "AskBot对话机器人" },
        { value: "IM", label: "AskChat即时通讯IM" },
        { value: "WORKORDER", label: "AskService工单系统" },
        { value: "MANAGE", label: "管理系统" },
        { value: "KNOWLEDGE", label: "AskKMS知识管理系统" },
        { value: "AskAI", label: "AskAI大模型机器人" },
        { value: "QUESTION", label: "AskQNR问卷调研系统" },
        { value: "APP", label: "APP" },
      ],
      systemNameObj: {
        OPEN: {
          name: "AskBot对话机器人",
          icon: "iconfont guoran-askbotdandutubiao2",
        },
        IM: {
          name: "AskChat即时通讯IM",
          icon: "iconfont guoran-guanlixitong1",
        },
        WORKORDER: {
          name: "AskService工单系统",
          icon: "iconfont guoran-askservice-logo03",
        },
        MANAGE: {
          name: "管理系统",
          icon: "iconfont guoran-askbotdandutubiao2",
        },
        KNOWLEDGE: {
          name: "AskKMS知识管理系统",
          icon: "iconfont guoran-tongyichicun-zhishiku1",
        },
        AskAI: {
          name: "AskAI大模型机器人",
          icon: "iconfont guoran-AskAI-logo-dinggao",
        },
        QUESTION: {
          name: "AskQNR问卷调研系统",
          icon: "iconfont guoran-AskQNR-logo-wuwenzi-lk",
        },
        APP: {
          name: 'APP',
          icon: "iconfont guoran-askbotdandutubiao2"
        }
      },
      title: "",
      systemName: "OPEN",
      updateNoticeDescription: "",
      summary: "",
      createTime: "",
      isUpdate: true,
      isIos: true,
      isAndroid: true,
      appUpgradeSetting: {
        upgrade: false,
        androidApp: {
          upgrade: false,
          version: '',
          channel: 'Android',
          apkUrl: ''
        },
        iosApp: {
          upgrade: false,
          version: '',
          channel: 'IOS'
        },
        apps: [],
      },
      strongNotice: false,
      strongNoticeSystems: [],
      pushChannels: [],
      uploadUrl: "/api-open/oss/public",
      uploadAvatarParams: {
        token: "8c98087dfd2d48f856d8c95c09115def",
      },
      currentEditor: null,

    };
  },
  components: {
    SideNav,
    TopHeader,
    switchIcon
  },
  mounted () {
    // 入口权限
  },
  filters: {},
  methods: {
    ckeditorReady (editor) {
      this.currentEditor = editor;
    },
    addNotice () {

      if (this.summary.trim() === "") {
        this.$message({
          message:
            "产品同学，请认真填写更新标题，人生无‘常‘，人生苦'短'，长短适宜，才是标题",
          type: "warning",
          duration: 10000,
        });
        return false;
      }
      if (this.createTime === "") {
        this.$message({
          message:
            "产品同学，时光快些亦或时光慢些，终有一个点，点生一，一生二，二生三，三生万物",
          type: "warning",
          duration: 10000,
        });
        return false;
      }
      if (this.updateNoticeDescription.trim() === "") {
        this.$message({
          message:
            "产品同学，人生重在过程，公告重在内容，写点吧，空白的人生不值得公告哦",
          type: "warning",
          duration: 10000,
        });
        return false;
      }
      if (this.appUpgradeSetting.iosApp.upgrade && !this.appUpgradeSetting.iosApp.version) {
        this.$message({
          message: '请填写IOS版本号',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      if (this.appUpgradeSetting.androidApp.upgrade && !this.appUpgradeSetting.androidApp.apkUrl) {
        this.$message({
          message: '请上传Android安装包',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      if (this.systemName === 'APP' && !this.pushChannels.length) {
        this.$message({
          message: '请至少选择一个推送渠道',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      var oldhtml = this.currentEditor.getData();
      var description = oldhtml.replace(/<.*?>/ig, "");
      if (description.length > 1024) {
        this.$message({
          message: '内容只能输入1024个字,你都输入' + description.length + '个字了',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      let appUpgradeSetting = this.appUpgradeSetting;
      appUpgradeSetting.apps = [];
      appUpgradeSetting.apps.push(this.appUpgradeSetting.androidApp);
      appUpgradeSetting.apps.push(this.appUpgradeSetting.iosApp);
      let noticeInfo = {
        createTime: new Date(this.createTime).getTime(),
        systemName: this.systemNameObj[this.systemName].name,
        systemPic: this.systemNameObj[this.systemName].icon,
        updateNoticeTitle: `${this.title}*+@@+*${this.summary}`,
        updateNoticeDescription: this.updateNoticeDescription,
        type: this.appUpgradeSetting.iosApp.upgrade || this.appUpgradeSetting.androidApp.upgrade ? 'APP_UPGRADE' : 'ORDINARY',
        appUpgradeSetting: appUpgradeSetting,
        pushChannels: this.pushChannels,
        strongNotice: this.strongNotice,
        strongNoticeSystems: this.strongNoticeSystems.join(",")
      };
      this.$http
        .post(this.requestUrl.notice.publishNotice, noticeInfo)
        .then((res) => {
          if (res.data.code === "0") {
            this.$message({
              showClose: true,
              message:
                "产品同学，每一条更新公告，都是相关小伙伴的部分人生的结晶，产品如人生，让我们扬帆迭代，步步为营！",
              type: "success",
              duration: 10000,
            });
          }
        });
    },
    changeUpgrade (type) {
      if (type === 'upgrade') {
        this.appUpgradeSetting.androidApp = {
          upgrade: false,
          version: '',
          channel: 'Android',
          apkUrl: ''
        }
        this.appUpgradeSetting.iosApp = {
          upgrade: false,
          version: '',
          channel: 'IOS'
        }
      } else if (type === 'IOS') {
        this.appUpgradeSetting.iosApp.version = '';
        this.appUpgradeSetting.iosApp.channel = 'IOS'
      } else {
        this.appUpgradeSetting.androidApp.version = '';
        this.appUpgradeSetting.androidApp.apkUrl = '';
        this.appUpgradeSetting.androidApp.channel = 'Android';
      }
    },
    handleAvatarSuccess (res, file) {
      if (res) {
        this.appUpgradeSetting.androidApp.apkUrl = res;
      }
    },
    changeSwitch (value) {
      console.log(this.strongNotice);
      this.strongNotice = value
      this.$forceUpdate()
    },
    getViewUrl () {

    },
    uploadImageAndFile ({ param }) {
      // console.log(type, param);
      console.log(this.currentEditor);
      if (param.url) {
        let command = this.currentEditor.commands.get("insertAskComponent");
        let url = param.url
        this.$http.get("/api/btoa/encode?url=" + url).then(res => {
        console.log(res.data.data, 'param.urlparam.url');

          if (res.data.code == 0) {
            let kkviewUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' + res.data.data : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + res.data.data;
            command.execute({
              tag: "iframe",
              options: {
                width: "100%",
                height: '400px',
                customaryurl: param.url,
                src: kkviewUrl,
                filename: param.name
                // filesize: filed.size
              },
            });
          }
        })

      }

    }
  },
};
</script>

<style lang="less">
@import "../../assets/less/version/addnotice.less";

.ck-content {
  height: 300px;
}
</style>
