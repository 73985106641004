<template>
    <div class="feedback-setting">
        <div class="header">
            <div class="left">{{ $t("channel.feedback") }}</div>
            <div class="saveBtn" @click="save">
                <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
                {{ $t("channel.save") }}
            </div>
        </div>
        <div class="feedback-content-item">
            <div class="feedBackTitle">
                <div class="right">
                    <div :class="['evaluate-table-switch']">
                        <el-switch
                            @click.native.prevent="changefastFeedback('switch')"
                            :width="42"
                            v-model="
                                feedbackSeetingObj.unidentifiedFeedbackInApp
                            "
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                        >
                            >
                        </el-switch>
                        <span
                            class="switch-open-icon"
                            @click="changefastFeedback('open')"
                            v-if="feedbackSeetingObj.unidentifiedFeedbackInApp"
                            ><i class="iconfont guoran-a-16-17"></i
                        ></span>
                        <span
                            class="switch-close-icon"
                            @click="changefastFeedback('close')"
                            v-if="!feedbackSeetingObj.unidentifiedFeedbackInApp"
                            ><i class="arsenal_icon arsenalcuo1"></i
                        ></span>
                    </div>
                    <div class="item-title">
                        {{
                            $t("channel.feedbackSetting.FeedbackInApplication")
                        }}
                    </div>
                    <span class="des">{{
                        $t("channel.feedbackSetting.FeedbackInApplicationDes")
                    }}</span>
                </div>
            </div>
            <div class="itemContent">
                <div class="feedback-time-setting">
                    <el-input-number
                        v-model="feedbackSeetingObj.timeNum"
                        :min="1"
                    ></el-input-number>
                    <el-select
                        v-model="feedbackSeetingObj.timeUnit"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <span class="feedback-time-setting-des">{{
                        $t("channel.feedbackSetting.FeedbackInApplicationTime")
                    }}</span>
                </div>
                <div class="feedback-time-setting-title">
                    {{
                        $t("channel.feedbackSetting.FeedbackInApplicationGuide")
                    }}
                </div>
                <el-input
                    v-model="feedbackSeetingObj.guideText"
                    :placeholder="$t('common.placeholder')"
                ></el-input>
            </div>
        </div>
        <div v-if="channelType == 'feishu' || channelType == 'dingding' || channelType == 'wechat'" class="feedback-content-item">
            <div class="feedBackTitle">
                <div class="right">
                    <div :class="['evaluate-table-switch']">
                        <el-switch
                            @click.native.prevent="changeappInform('switch')"
                            :width="42"
                            v-model="feedbackSeetingObj.appInform"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2"
                        >
                            >
                        </el-switch>
                        <span
                            class="switch-open-icon"
                            @click="changeappInform('open')"
                            v-if="feedbackSeetingObj.appInform"
                            ><i class="iconfont guoran-a-16-17"></i
                        ></span>
                        <span
                            class="switch-close-icon"
                            @click="changeappInform('close')"
                            v-if="!feedbackSeetingObj.appInform"
                            ><i class="arsenal_icon arsenalcuo1"></i
                        ></span>
                    </div>
                    <div class="item-title">
                        {{
                            $t(
                                "channel.feedbackSetting.ApplicationNotification"
                            )
                        }}
                    </div>
                    <span class="des">{{
                        $t("channel.feedbackSetting.ApplicationNotificationTip")
                    }}</span>
                </div>
            </div>
            <div class="itemContent">
                <div class="feedback-time-setting">
                    <span class="feedback-time-setting-des">{{
                        $t("channel.feedbackSetting.Daily")
                    }}</span>
                    <el-time-picker
                        v-model="feedbackSeetingObj.informTime"
                        :placeholder="$t('common.selectPlaceholder')"
                    >
                    </el-time-picker>
                    <span class="feedback-time-setting-des">{{
                        $t("channel.feedbackSetting.Send")
                    }}</span>
                </div>
                <div class="feedback-time-setting-title">
                    {{ $t("channel.feedbackSetting.NotificationContent") }}
                </div>
                <el-input
                    v-model="feedbackSeetingObj.appInformMsg"
                    :placeholder="$t('common.placeholder')"
                ></el-input>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        channelType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            agentId: "",
            feedbackSeetingObj: {
                agentId: "1451608359", // 应用ID
                unidentifiedFeedbackInApp: true, // 应用内未识别反馈开关
                timeNum: 36, // 时间数字
                timeUnit: "HOURS", // 时间单位 HOURS：小时，MINUTES：分钟，SECONDS：秒
                guideText:
                    "以下问题我已经学会啦，感谢您提供的金点子，帮助我更好的成长：", // 未识别反馈引导
                appInform: true, // 应用通知开关
                informTime: 1726797600000, // 通知时间戳
                appInformMsg: "历史不识别问题已经学会啦，快来试试吧", // 通知内容
                createTime: "2024-09-20T02:34:46.000+00:00",
                updateTime: "2024-09-20T02:34:46.000+00:00",
                taskId: "145160835911",
            },
            timeOptions: [
                {
                    value: "HOURS",
                    label: this.$t("channel.feedbackSetting.HOURS"),
                },
                {
                    value: "MINUTES",
                    label: this.$t("channel.feedbackSetting.MINUTES"),
                },
                {
                    value: "SECONDS",
                    label: this.$t("channel.feedbackSetting.SECONDS"),
                },
            ],
        };
    },
    methods: {
        changefastFeedback(datas) {
            if (datas === "open") {
                this.feedbackSeetingObj.unidentifiedFeedbackInApp = false;
            } else if (datas === "close") {
                this.feedbackSeetingObj.unidentifiedFeedbackInApp = true;
            }
        },
        changeappInform(datas) {
            if (datas === "open") {
                this.feedbackSeetingObj.appInform = false;
            } else if (datas === "close") {
                this.feedbackSeetingObj.appInform = true;
            }
        },
        save() {
            let dateString = this.feedbackSeetingObj.informTime;
            if (!(typeof dateString === "number")) {
                dateString = Date.parse(dateString);
            }
            let param = {
                agentId: this.feedbackSeetingObj.agentId,
                unidentifiedFeedbackInApp:
                    this.feedbackSeetingObj.unidentifiedFeedbackInApp,
                timeNum: this.feedbackSeetingObj.timeNum,
                timeUnit: this.feedbackSeetingObj.timeUnit,
                guideText: this.feedbackSeetingObj.guideText,
                appInform: this.feedbackSeetingObj.appInform,
                informTime: dateString,
                appInformMsg: this.feedbackSeetingObj.appInformMsg,
                bindCode: this.$route.query.bindCode,
                taskId: this.feedbackSeetingObj.taskId,
            };
            if (this.feedbackSeetingObj.id) {
                param.id = this.feedbackSeetingObj.id;
            }
            this.$http
                .post(
                    this.requestUrl.FeedbackSetting.updateFeedbackSetting,
                    param
                )
                .then((res) => {
                    if (res.data.code === "0" && res.data.data) {
                        this.$message.success(
                            this.$t("staffManage.saveSuccess")
                        );
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
    mounted() {
        this.$http
            .get(
                this.requestUrl.FeedbackSetting.getFeedbackSetting +
                    "?bindCode=" +
                    this.$route.query.bindCode
            )
            .then((res) => {
                if (res.data.code === "0" && res.data.data) {
                    this.feedbackSeetingObj = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        console.log(
            "channelType", this.channelType
        );
        
    },
};
</script>
  
<style lang="less" scoped>
.feedback-setting {
    height: 100%;
    //padding-right: 20px;
    overflow-y: scroll;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .left {
            font-size: 16px;
            color: #000000;
            display: flex;
            align-items: center;
        }
    }
    .saveBtn {
        height: 26px;
        background-color: #366aff;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        font-size: 12px;
        border-radius: 17px;
        cursor: pointer;
        i {
            font-size: 12px;
            padding-right: 4px;
        }
    }

    .feedback-content-item {
        .feedBackTitle {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 20px;
            .right {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;
            }
            .item-title {
                margin-left: 8px;
                margin-right: 8px;
            }
            .des {
                text-align: left;
                color: #999999;
                font-size: 12px;
            }
        }
        .itemContent {
            display: flex;
            flex-direction: column;
            background: #fbfcfd;
            border-radius: 5px;
            padding: 20px;
            font-size: 14px;
            .feedback-time-setting {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 12px;
                .el-select {
                    width: 100px;
                    margin: 0 12px;
                }
                .feedback-time-setting-des {
                    font-size: 12px;
                }
                .el-date-editor {
                    width: 120px;
                    margin: 0 12px;
                }
            }
            .feedback-time-setting-title {
                margin-bottom: 10px;
            }
        }
    }
}
/deep/ .evaluate-table-switch {
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    text-align: center;
    align-items: center;

    .el-switch {
        text-align: center;
    }

    .switch-open-icon {
        position: absolute;
        // z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;

        i {
            color: white;
            font-size: 16px;
        }
    }

    .switch-close-icon {
        position: absolute;
        // z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 21px;
        i {
            color: #6e6b7b;
            font-size: 14px;
            font-weight: 600;
        }
    }

    i {
        color: #366aff;
        font-size: 18px;
    }
    .el-switch__core::after {
        width: 14px;
        height: 14px;
        top: 2px;
        left: 2px;
    }
    .is-checked .el-switch__core::after {
        left: 40px;
    }
    &.disabled {
        i,
        .el-switch__input,
        .el-switch__core {
            cursor: no-drop;
        }
    }
}
</style>