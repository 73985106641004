<template>
    <div class="ask-iframe-file-view">
        <iframe :src="fileUrl" frameborder="0" class="ask-iframe-file-view-iframe"></iframe>
        <div class="ask-iframe-file-view-name">
            <img v-if="fileType === 'pdf' || fileType === 'PDF'" src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/file-pdf.png" alt="">
            <img v-else-if="fileType === 'docx' || fileType === 'doc'" src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/file-word.png" alt="">
            <img v-else-if="fileType === 'txt'" src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/file-txt.png" alt="">
            <img v-else-if="fileType === 'xls' || fileType === 'xlsx'" src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/file-excl.png" alt="">
            <img v-else-if="fileType === 'ppt'" src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/file-ppt.png" alt="">
            <span>{{fileName}}</span>
        </div>
    </div>
</template>

<script >
export default{
    data(){
        return {
           fileUrl:"",
           fileName:"",
           fileType:""
        }
    },
    mounted(){
        this.fileUrl = this.$route.query.fileUrl;
        this.fileName = this.$route.query.fileName;
        this.fileType = this.getFileSuffix(this.fileName,[],true)
        console.log(this.fileUrl,this.fileName,this.fileType);
    },
    methods:{
        // 获取文件后缀名
        getFileSuffix(url,arr,isGetName) {
            let index1 = url.lastIndexOf(".")+1;
            let index2 = url.length;
            let postf = url.substring(index1, index2);//后缀名
            if (isGetName) {
                return postf;
            } else { 
                if (!arr || arr.indexOf(postf) == -1) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }
}

</script>

<style lang="less" scoped>
.ask-iframe-file-view{
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    .ask-iframe-file-view-iframe{
        height: 350px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .ask-iframe-file-view-name{
        height: 42px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        background:#fff;
        border-top: 1px solid #dee0e3;
        font-size: 14px;
        color: #000;
        span{
            margin-left: 6px;
        }
    }
    .my-photo{
        border: none;
    }
}
</style>