<template>
    <div id="roleTable">
        <el-table
            :data="roleData"
            style="width: 100%;"
            height="calc(100vh - 130px)"
        >
            <el-table-column
                prop="name"
                label="数据权限名称"
                width="240px"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        {{ scope.row.privilegesName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                label="数据权限描述"
                align="left"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        <span
                            v-if="scope.row.description !== ''"
                            style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            "
                            >{{ scope.row.description }}</span
                        >
                        <span v-else>--</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="useNum"
                label="使用人数"
                align="center"
                width="100"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="120"
            >
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: space-around">
                        <el-button
                            @click.native.prevent="
                                editRow(scope.$index, scope.row.id)
                            "
                            type="text"
                            :disabled="scope.row.id == 0 || scope.row.id == -1"
                            size="medium"
                        >
                            <span>编辑</span>
                        </el-button>
                        <el-button
                            @click.native.prevent="
                                deleteRow(
                                    scope.row.id,
                                    scope.row.privilegesName,
                                    scope.row.useNum
                                )
                            "
                            v-if="scope.row.id != 0 && scope.row.id != -1"
                            type="text"
                            size="medium"
                            style="color: tomato"
                        >
                            删除
                        </el-button>
                        <el-button
                            @click.native.prevent="
                                deleteRow(
                                    scope.row.id,
                                    scope.row.privilegesName,
                                    scope.row.useNum
                                )
                            "
                            v-if="scope.row.id == 0 || scope.row.id == -1"
                            :disabled="scope.row.id == 0 || scope.row.id == -1"
                            type="text"
                            size="medium"
                        >
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer-pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="roleTableTotal"
            >
            </el-pagination>
        </div>
        <el-dialog
            title="删除数据权限"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            width="300px"
        >
            <span style="text-align: left; padding-left: 14px"
                >是否删除数据权限
                <span style="padding-left: 4px; font-weight: 600">{{
                    roleName
                }}</span></span
            >
            <p
                style="
                    text-align: left;
                    padding-left: 14px;
                    margin-top: 12px;
                    color: #909399;
                "
                v-if="deleteUseNum != 0"
            >
                <span
                    >该数据权限下有账号正在使用，删除后账号下将没有该数据权限，如需继续删除，请完整输入您要删除的数据权限名称。</span
                >
                <br />
                <br />
                <el-input
                    size="small"
                    v-model="checkName"
                    placeholder="请输入数据权限名称"
                ></el-input>
            </p>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false"
                    >取 消</el-button
                >
                <el-button size="small" type="danger" @click="deleteRole"
                    >删 除</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { operable } from "../../js/operable";

export default {
    name: "roleTable",
    data() {
        return {
            roleData: [],
            dialogVisible: false,
            roleName: "",
            checkName: "",
            deleteUseNum: 0,
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            roleId: 0,
            rolePrivilegesList: [],
        };
    },
    mounted() {
        this.getRoles();
        this.rolePrivilegesList = this.getRolePrivileges();
    },
    computed: {
        operableDeleteRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        //获取所有角色
        getRoles() {
            let url =
                this.requestUrl.userDataPrivilege.getUserDataPrivilegesList +
                "?pageSize=" +
                this.pageSize +
                "&pageNo=" +
                this.currentPage;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.roleData = res.data.data.list;
                    this.roleTableTotal = res.data.data.total;
                }
            });
        },
        details(index, id) {
            this.$router.push({
                path: "/addRole",
                query: {
                    type: "details",
                    id: id,
                },
            });
        },
        editRow(index, id) {
            this.$emit("c_editDataRole", JSON.stringify(this.roleData[index]));
        },
        deleteRow(id, name, useNum) {
            this.roleId = id;
            this.roleName = name;
            this.deleteUseNum = useNum;
            this.dialogVisible = true;
        },
        handleSizeChange(value) {
            this.pageSize = value;
            this.getRoles();
        },
        handleCurrentChange(value) {
            this.currentPage = value;
            this.getRoles();
        },
        deleteRole() {
            let flag = false;
            let url =
                this.requestUrl.userDataPrivilege.deleteUserDataPrivileges +
                this.roleId;
            if (this.deleteUseNum != 0) {
                if (this.checkName.trim() == "") {
                    this.$message.error("请输入数据权限名称");
                } else if (this.checkName.trim() != this.roleName) {
                    this.$message.error("数据权限名称输入错误");
                } else {
                    flag = true;
                }
            } else {
                flag = true;
            }
            flag &&
                this.$http.delete(url).then((res) => {
                    if (res.data.code === "0") {
                        this.$message({
                            message: "删除数据权限成功",
                            type: "success",
                            duration: 2000,
                        });
                        this.getRoles();
                        this.dialogVisible = false;
                    }
                });
        },
    },
};
</script>

<style scoped lang="less">
#roleTable {
    width: calc(100vw - 240px);
    .footer-pagination {
        flex: none;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 16px;
    }
}
</style>