<template>
    <div id="left-tree">
        <div class="left-tree-search">
            <el-input
                size="small"
                placeholder="请输入内容"
                v-model="inputSearch"
                class="input-with-select"
                @keyup.enter.native="search"
            >
                <el-button
                    slot="append"
                    @click="search"
                    icon="el-icon-search"
                ></el-button>
            </el-input>
        </div>
        <div
            v-loading="treeLoading"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.9)"
            class="left-tree-box"
        >
            <div v-if="isSeaching" class="search-res">
                <div
                    v-for="(item, index) in searchResList.departments"
                    :class="[
                        'search-res-cell',
                        activeIndex == index ? 'search-res-cell-active' : '',
                    ]"
                    :key="index"
                    @click="setActiveCell(item, index, 'dept')"
                >
                    <span
                        class="colorBlue el-icon-folder"
                        v-if="item.type == 2"
                    ></span>
                    <span class="name" v-if="item.source===1&&isNumber(item.name)">
                      <open-data :type="'departmentName'" :openid="item.name"></open-data>
<!--                      <ww-open-data type="departmentName" :openid="item.name"></ww-open-data>-->
                    </span>
                    <span v-else class="name">{{ item.name }}</span>
                </div>
                <div
                    v-for="(item, index) in searchResList.members"
                    :class="[
                        'search-res-cell',
                        activeIndex == index ? 'search-res-cell-active' : '',
                    ]"
                    :key="index"
                    @click="setActiveCell(item, index, 'member')"
                >
                    <span
                        class="colorBlue el-icon-folder"
                        v-if="item.type == 2"
                    ></span>
                    <span class="name" v-if="item.source===0&&item.name.substr(0,4)==='woWe'">
                      <open-data :type="'userName'" :openid="item.name"></open-data>
<!--                      <ww-open-data type="userName" :openid="item.name"></ww-open-data>-->
                    </span>
                    <span v-else class="name">{{ item.name }}</span>
                </div>
            </div>
            <el-tree
                ref="lefttree"
                v-if="!isSeaching"
                :data="dataTree"
                node-key="id"
                :props="treeProps"
                highlight-current
                @node-click="nodeClick"
                @node-expand="nodeExpand"
                @node-collapse="nodeCollapse"
                :default-expanded-keys="expandedList"
                :default-expand-all="defaultExpandAll"
                :expand-on-click-node="false"
            >
                <span
                    @mouseover="nodeMouseover('handele' + node.id)"
                    @mouseleave="nodeMouseleave('handele' + node.id)"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                >
                    <div
                        :style="{
                            maxWidth: 260 - 24 * (node.level - 1) + 'px',
                        }"
                        class="custom-tree-node-name"
                    >
                      <span v-if="data.type===1&&isNumber(data.label)">
                        <open-data :type="'departmentName'" :openid="data.label"></open-data>
<!--                        <ww-open-data type="departmentName" :openid="data.label"></ww-open-data>-->
                      </span>
                      <span v-else>
                        {{ node.label.split("+++___+++")[0] }}
                      </span>
                    </div>
                    <span
                        :id="'handele' + node.id"
                        class="custom-tree-node-handle"
                        v-if="
                            (operableDepart(
                                'PORTAL_MANAGEMENT_MEMBER_DEPT_UPDATE'
                            ) ||
                                operableDepart(
                                    'PORTAL_MANAGEMENT_MEMBER_DEPT_ADD'
                                ) ||
                                (node.label.split('+++___+++')[1] != 0 &&
                                    node.label.split('+++___+++')[2] != 1 &&
                                    operableDepart(
                                        'PORTAL_MANAGEMENT_MEMBER_DEPT_SORT'
                                    )) ||
                                (node.label.split('+++___+++')[1] !=
                                    node.label.split('+++___+++')[2] - 1 &&
                                    node.label.split('+++___+++')[2] != 1 &&
                                    operableDepart(
                                        'PORTAL_MANAGEMENT_MEMBER_DEPT_SORT'
                                    )) ||
                                (operableDepart(
                                    'PORTAL_MANAGEMENT_MEMBER_DEPT_DELETE'
                                ) &&
                                    node.level != 1)) &&
                            false
                        "
                    >
                        <el-popover
                            placement="bottom"
                            width="60"
                            trigger="manual"
                            v-model="node.data.id == activePopoverId"
                        >
                            <div class="handle-cell-box">
                                <span
                                    @click="handleTree('append', node)"
                                    class="handle-cell"
                                    v-if="
                                        operableDepart(
                                            'PORTAL_MANAGEMENT_MEMBER_DEPT_ADD'
                                        )
                                    "
                                    >添加子部门</span
                                >
                                <span
                                    @click="handleTree('rename', node)"
                                    class="handle-cell"
                                    v-if="
                                        operableDepart(
                                            'PORTAL_MANAGEMENT_MEMBER_DEPT_UPDATE'
                                        )
                                    "
                                    >重命名</span
                                >
                                <span
                                    v-if="
                                        node.level != 1 &&
                                        operableDepart(
                                            'PORTAL_MANAGEMENT_MEMBER_DEPT_DELETE'
                                        )
                                    "
                                    @click="handleTree('delete', node)"
                                    class="handle-cell"
                                    >删除</span
                                >
                                <span
                                    @click="handleTree('up', node)"
                                    v-if="
                                        node.label.split('+++___+++')[1] != 0 &&
                                        node.label.split('+++___+++')[2] != 1 &&
                                        operableDepart(
                                            'PORTAL_MANAGEMENT_MEMBER_DEPT_SORT'
                                        )
                                    "
                                    class="handle-cell"
                                    >上移</span
                                >
                                <span
                                    @click="handleTree('down', node)"
                                    v-if="
                                        node.label.split('+++___+++')[1] !=
                                            node.label.split('+++___+++')[2] -
                                                1 &&
                                        node.label.split('+++___+++')[2] != 1 &&
                                        operableDepart(
                                            'PORTAL_MANAGEMENT_MEMBER_DEPT_SORT'
                                        )
                                    "
                                    class="handle-cell"
                                    >下移</span
                                >
                            </div>
                            <span
                                slot="reference"
                                @click.stop="handleVisiblePover(node.data.id)"
                                class="el-icon-more"
                            ></span>
                        </el-popover>
                    </span>
                </span>
            </el-tree>
        </div>
        <el-dialog
            :title="departmentEditTitle"
            :visible.sync="departmentEditDialogVisible"
            width="400px"
        >
            <div class="department-name-content">
                <h6>部门名称</h6>
                <div class="department-name">
                    <el-input
                        size="small"
                        maxlength="20"
                        v-model="activeDepartmentName"
                        placeholder="请输入部门名称"
                    ></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeDepartmentEditDialog"
                    >取 消</el-button
                >
                <el-button
                    size="small"
                    v-if="departmentTreeHandleType == 'append'"
                    type="primary"
                    @click="submitAddDepartmentEdit"
                    >确 定</el-button
                >
                <el-button
                    size="small"
                    v-if="departmentTreeHandleType == 'rename'"
                    type="primary"
                    @click="submitRenameDepartmentEdit"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="删除部门"
            :visible.sync="departmentdeleteDialogVisible"
            width="400px"
            :before-close="closeDepartmentdeleteDialogVisible"
            id="delete-confirm-dia"
        >
            <div class="department-name-content">
                <div class="delete-confirm-dia-des">部门下存在成员，将成员</div>
                <div v-if="deleteHandleType == 1" class="info-cell">
                    <div class="info-cell-title">移动至</div>
                    <div class="info-cell-input">
                        <el-cascader
                            size="small"
                            :options="filterDataTree(activeDepartmentId[0])"
                            :props="props"
                            v-model="departmentIds"
                            clearable
                        ></el-cascader>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    size="small"
                    @click="closeDepartmentdeleteDialogVisible"
                    >取 消</el-button
                >
                <el-button
                    size="small"
                    type="primary"
                    @click="submitDepartmentdelete"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { formatTree } from "./../utils/formatTree";
import { operable } from "../../js/operable";
import OpenData from "@/components/openData";

export default {
    name: "staff-left-tree",
  components: {OpenData},
  props: ["data", "mainValue"],
    data() {
        return {
            name: "staff-left-tree",
            activePopoverId: "",
            dataTree: [],
            treeLoading: false,
            defaultExpandAll: false,
            treeProps: {
                label: "labelStr",
            },
            expandedList: [],
            defaultCheckedKeys: [], // 默认选中第一个节点
            inputSearch: "", // 搜索值
            searchResList: { departments: [], members: [] }, // 搜索结果
            activeIndex: 0, // 当前搜索结果展示index
            isSeaching: false, // 搜索状态
            departmentEditDialogVisible: false,
            departmentEditTitle: "新建部门",
            activeDepartmentName: "",
            activeDepartmentId: [],
            departmentTreeHandleType: "append",
            renameDepartInfo: {},
            departmentdeleteDialogVisible: false,
            deleteHandleType: 1,
            departmentIds: "",
            props: {
                multiple: false,
                emitPath: false,
                value: "id",
                label: "label",
                checkStrictly: true,
            },
            activeNodeId: "",
            rolePrivilegesList: [],
        };
    },
    methods: {
        //部门是否是纯数字
        isNumber(value){
          const res = /^\d+$/;
          return res.test(value)
        },
        handleVisiblePover(id) {
            if (this.activePopoverId == id) {
                this.activePopoverId = "";
            } else {
                this.activePopoverId = id;
            }
        },
        filterDataTree: function (id) {
            let filterTree = JSON.parse(JSON.stringify(this.dataTree));
            let flag = true;
            function fuc(arr) {
                arr.forEach((item, index) => {
                    if (flag) {
                        if (item.id == id) {
                            arr.splice(index, 1);
                            flag = false;
                        } else {
                            if (item.children && item.children.length > 0) {
                                fuc(item.children);
                            }
                        }
                    }
                });
            }
            fuc(filterTree);
            return filterTree;
        },
        treeSetCurrentKey(id) {
            console.log("++++++++++", id);
            this.$nextTick(function () {
                this.$refs.lefttree.setCurrentKey(id);
            });
        },
        closeDepartmentdeleteDialogVisible() {
            this.departmentdeleteDialogVisible = false;
            this.departmentIds = "";
        },
        search() {
            this.$http
                .get(
                    this.requestUrl.department.searchDepartmentOrUser +
                        "?keyword=" +
                        this.inputSearch +
                        "&corpId=" +
                        this.mainValue
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.searchResList = res.data.data;
                        if (res.data.data.departments.length > 0) {
                            this.$emit("getMemberListByDeptId", {
                                id: res.data.data.departments[0].id,
                                currentPage: 1,
                            });
                        }
                        /*WWOpenData.bind(this.$el)*/
                        this.isSeaching = true;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        setActiveCell(item, index, type) {
            console.log(item, index);
            this.activeIndex = index;
            if (type == "dept") {
                this.$emit("getMemberListByDeptId", {
                    id: item.id,
                    currentPage: 1,
                });
            } else {
                this.$emit("getMemberById", {
                    id: item.id,
                    currentPage: 1,
                });
            }
        },
        nodeExpand(data) {
            this.expandedList.push(data.id); // 在节点展开是添加到默认展开数组
        },
        nodeCollapse(data) {
            this.expandedList.splice(this.expandedList.indexOf(data.id), 1); // 收起时删除数组里对应选项
        },
        nodeClick(dataObj, node, data) {
            let spans = document.getElementsByClassName(
                "custom-tree-node-handle"
            );
            console.log(spans);
            if (spans.length > 0) {
                for (let i = 0; i < spans.length; i++) {
                    spans[i].style.display = "none";
                }
                let span = document.getElementById("handele" + node.id);
                span.style.display = "block";
            }

            this.$nextTick(() => {
                this.activePopoverId = "";
                this.activeNodeId = node.id;
                this.$emit("getMemberListByDeptId", {
                    id: dataObj.id,
                    currentPage: 1,
                });
            });
        },
        customTreeNodeHover(node) {
            this.activeIndex = node.data.id;
        },
        /**
         * 操作树节点
         * @method handleTree
         * @param {String} handleType 操作类型 append rename delete up down
         * @param { Object } node 节点信息
         * @return { type: handleType, ids: [] }
         */
        handleTree(handleType, node) {
            console.log(handleType, node);
            this.departmentTreeHandleType = handleType;
            let obj = { type: handleType, ids: [], nodeInfo: node.data };
            let _this = this;
            switch (handleType) {
                case "append":
                case "rename":
                case "delete":
                    obj.ids = [node.data.id];
                    break;

                case "up":
                case "down":
                    let flag = true;
                    function fuc(arr) {
                        arr.forEach((item, index) => {
                            if (flag) {
                                if (item.id == node.data.id) {
                                    if (handleType == "up") {
                                        obj.ids = [item.id, arr[index - 1].id];

                                        // 移动
                                        let tamp = arr[index];
                                        arr[index] = arr[index - 1];
                                        arr[index - 1] = tamp;
                                        _this.dataTree = [
                                            ...formatTree(_this.dataTree),
                                        ];
                                        flag = false;
                                        return false;
                                    }
                                    if (handleType == "down") {
                                        obj.ids = [arr[index + 1].id, item.id];
                                        console.log([
                                            arr[index + 1].id,
                                            item.id,
                                        ]);
                                        // 移动
                                        let tamp = arr[index + 1];
                                        arr[index + 1] = arr[index];
                                        arr[index] = tamp;
                                        _this.dataTree = [
                                            ...formatTree(_this.dataTree),
                                        ];
                                        flag = false;
                                        return false;
                                    }
                                } else {
                                    if (
                                        item.children &&
                                        item.children.length > 0
                                    ) {
                                        fuc(item.children);
                                    }
                                }
                            }
                        });
                    }
                    fuc(this.dataTree);
                    break;
                default:
                    break;
            }
            this.activePopoverId = "";

            this.handleDepartment(obj);
        },
        handleDepartment(data) {
            console.log(data);
            if (data.type == "append") {
                this.activeDepartmentId = data.ids;
                this.departmentEditDialogVisible = true;
            }
            if (data.type == "rename") {
                this.activeDepartmentId = data.ids;
                this.activeDepartmentName = data.nodeInfo.label;
                this.departmentEditDialogVisible = true;
                this.renameDepartInfo = {
                    id: data.nodeInfo.id,
                    name: data.nodeInfo.label,
                    order: data.nodeInfo.order,
                    parentId: data.nodeInfo.parentId,
                };
            }
            if (data.type == "delete") {
                this.activeDepartmentId = data.ids;
                this.$http
                    .get(
                        this.requestUrl.member.getMemberListByDeptId +
                            "/" +
                            this.activeDepartmentId +
                            "?page=15&size=15"
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            if (res.data.data.total == 0) {
                                this.$confirm(
                                    "是否删除 " +
                                        data.nodeInfo.label +
                                        " 部门?",
                                    "删除部门",
                                    {
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                        type: "warning",
                                    }
                                )
                                    .then(() => {
                                        this.deleteDepartment(false);
                                    })
                                    .catch(() => {
                                        this.$message({
                                            type: "info",
                                            message: "已取消删除",
                                        });
                                    });
                            } else {
                                this.departmentdeleteDialogVisible = true;
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
            if (data.type == "up" || data.type == "down") {
                this.activeDepartmentId = data.ids;
                this.moveDepartment();
            }
        },
        submitDepartmentdelete() {
            console.log(this.departmentIds);
            if (this.departmentIds == "") {
                this.$message({
                    type: "warning",
                    message: "请选择将成员移动至部门！",
                });
            } else {
                this.deleteDepartment(true);
            }
        },
        consoleLog(node) {
            console.log(node);
        },
        getdepartmentTree(val) {
            console.log("++++++++++++++++++________+++++++++++++")
            this.treeLoading = true;
            this.isSeaching = true;
            this.$http
                .get(
                    this.requestUrl.department.getDepartmentTree +
                        "?corpId=" +
                        val
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            if (res.data.data.length > 0) {
                                console.log(res.data.data[0].total);
                                if (res.data.data[0].total > 300) {
                                    this.defaultExpandAll = false;
                                } else {
                                    this.defaultExpandAll = true;
                                }
                                this.isSeaching = false;
                                this.defaultCheckedKeys = [res.data.data[0].id];
                                this.$emit("getMemberListByDeptId", {
                                    id: res.data.data[0].id,
                                    currentPage: 1,
                                });
                                this.dataTree = [...formatTree(res.data.data)];
                                /*WWOpenData.bind(this.$el)*/
                                this.$nextTick(function () {
                                    this.$refs.lefttree.setCurrentKey(
                                        res.data.data[0].id
                                    );
                                    this.activeNodeId = 1;
                                    // let span =
                                    //     document.getElementById("handele1");
                                    // span.style.display = "block";
                                });
                            } else {
                                this.$emit("getMemberListByDeptId", {
                                    id: null,
                                    currentPage: 1,
                                });
                            }
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.treeLoading = false;
                });
        },

        closeDepartmentEditDialog() {
            this.departmentEditDialogVisible = false;
        },
        moveDepartment() {
            this.$http
                .post(
                    this.requestUrl.department.moveDepartment,
                    {
                        siblingDeptId: this.activeDepartmentId[1],
                        targetDeptId: this.activeDepartmentId[0],
                    },
                    {
                        emulateJSON: true,
                    }
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.$message({
                            type: "success",
                            message: "移动成功!",
                        });
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        deleteDepartment(moveTo) {
            if (moveTo) {
                this.$http
                    .delete(
                        this.requestUrl.department.deleteDepartmentById +
                            "/" +
                            this.activeDepartmentId[0] +
                            "?moveDeptId=" +
                            this.departmentIds
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.$router.go(0);
                            // let flag = true;
                            // function fuc(arr) {
                            //     arr.forEach((item, index) => {
                            //         if (flag) {
                            //             if (item.id == res.data.data) {
                            //                 arr.splice(index, 1);
                            //                 flag = false;
                            //             } else {
                            //                 if (
                            //                     item.children &&
                            //                     item.children.length > 0
                            //                 ) {
                            //                     fuc(item.children);
                            //                 }
                            //             }
                            //         }
                            //     });
                            // }
                            // fuc(this.dataTree);
                            // this.departmentdeleteDialogVisible = false;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            } else {
                this.$http
                    .delete(
                        this.requestUrl.department.deleteDepartmentById +
                            "/" +
                            this.activeDepartmentId[0]
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.$router.go(0);
                            // let flag = true;
                            // function fuc(arr) {
                            //     arr.forEach((item, index) => {
                            //         if (flag) {
                            //             if (item.id == res.data.data) {
                            //                 arr.splice(index, 1);
                            //                 flag = false;
                            //             } else {
                            //                 if (
                            //                     item.children &&
                            //                     item.children.length > 0
                            //                 ) {
                            //                     fuc(item.children);
                            //                 }
                            //             }
                            //         }
                            //     });
                            // }
                            // fuc(this.dataTree);
                            // this.departmentdeleteDialogVisible = false;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        submitRenameDepartmentEdit() {
            if (this.activeDepartmentName.trim() == "") {
                this.$message.warning("部门名称不能为空");
            } else {
                let departmentForm = this.renameDepartInfo;
                departmentForm.name = this.activeDepartmentName;
                let _this = this;
                this.$http
                    .put(
                        this.requestUrl.department.editDepartmentById +
                            "/" +
                            departmentForm.id,
                        departmentForm
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            let flag = true;
                            function fuc(arr) {
                                arr.forEach((item, index) => {
                                    if (flag) {
                                        if (item.id == departmentForm.id) {
                                            item.label = departmentForm.name;
                                            item.labelStr =
                                                departmentForm.name +
                                                "+++___+++" +
                                                index +
                                                "+++___+++" +
                                                arr.length;
                                            _this.activeDepartmentName = "";
                                            _this.departmentEditDialogVisible = false;
                                            _this.$message.success("修改成功");
                                            flag = false;
                                        } else {
                                            if (
                                                item.children &&
                                                item.children.length > 0
                                            ) {
                                                fuc(item.children);
                                            }
                                        }
                                    }
                                });
                            }
                            fuc(this.dataTree);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        submitAddDepartmentEdit() {
            if (this.activeDepartmentName.trim() == "") {
                this.$message.warning("部门名称不能为空");
            } else {
                let parentId = this.activeDepartmentId[0],
                    name = this.activeDepartmentName,
                    _this = this;
                this.$http
                    .post(
                        this.requestUrl.department.addDepartment,
                        {
                            parentId: parentId,
                            name: name,
                        },
                        {
                            emulateJSON: true,
                        }
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data.data);
                            let flag = true;
                            function fuc(arr) {
                                arr.forEach((item, index) => {
                                    if (flag) {
                                        if (item.id == parentId) {
                                            if (
                                                item.children == null ||
                                                item.children == undefined
                                            ) {
                                                item.children = [];
                                            }
                                            item.children.push({
                                                id: res.data.data,
                                                label: name,
                                                labelStr:
                                                    name +
                                                    "+++___+++" +
                                                    (item.children.length - 1) +
                                                    "+++___+++" +
                                                    item.children.length,
                                            });
                                            _this.activeDepartmentName = "";
                                            _this.departmentEditDialogVisible = false;
                                            _this.$message.success("添加成功");
                                            flag = false;
                                        } else {
                                            if (
                                                item.children &&
                                                item.children.length > 0
                                            ) {
                                                fuc(item.children);
                                            }
                                        }
                                    }
                                });
                            }
                            fuc(this.dataTree);
                            this.dataTree = [...formatTree(this.dataTree)];
                           /* WWOpenData.bind(this.$el)*/
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        nodeMouseover(id) {
            let span = document.getElementById(id);
            if (span !== null) {
                span.style.display = "block";
            }
        },
        nodeMouseleave(id) {
            if (id != "handele" + this.activeNodeId) {
                let span = document.getElementById(id);
                if (span !== null) {
                    span.style.display = "none";
                }
            }
        },
    },
    computed: {
        operableDepart() {
            return function (type) {
                return operable(type);
            };
        },
    },
    mounted() {
        window.addEventListener("click", () => {
            this.activePopoverId = "";
        });
        // this.getdepartmentTree(this.mainValue);
        this.rolePrivilegesList = this.getRolePrivileges();
        let test = operable("PORTAL_MANAGEMENT_MEMBER_DEPT_ADD");
        console.log("operable", test);
    },
    filters: {},
    watch: {
        inputSearch: function (newVal, oldVal) {
            if (newVal == "" && oldVal != "") {
                this.isSeaching = false;
                this.$nextTick(function () {
                    this.$refs.lefttree.setCurrentKey(this.dataTree[0].id);
                    this.$emit("getMemberListByDeptId", {
                        id: this.dataTree[0].id,
                        currentPage: 1,
                    });
                });
            }
        },
    },
};
</script>
<style lang="less">
.el-loading-spinner i {
    font-size: 40px;
}
/deep/.el-popper {
    padding: 8px 0 !important;
    min-width: 80px !important;
}
/deep/.el-popover {
    min-width: 80px !important;
}
.department-name-content {
    padding: 0 8px;
}
#delete-confirm-dia {
    text-align: left;
    .delete-confirm-dia-des {
        text-align: left;
        margin-bottom: 16px;
    }
    .delete-confirm-dia-radio {
        text-align: left;
    }
    .info-cell {
        display: flex;
        margin-top: 16px;
        .info-cell-title {
            text-align: left;
            line-height: 36px;
            height: 36px;
            margin-right: 16px;
        }
        .info-cell-input {
            .el-cascader {
                width: 100%;
            }
        }
    }
}
.handle-cell-box {
    display: flex;
    flex-direction: column;
    .handle-cell {
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: black;
    }
    .handle-cell:hover {
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #366AFF;
        cursor: pointer;
    }
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .label-name {
        margin-left: 6px !important;
    }
}


#left-tree {
    padding: 8px;
    flex: none;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #ebeef5;
    .el-tree-node.is-current > .el-tree-node__content {
        background-color: #e4e7ed !important;
    }
    .left-tree-search {
        flex: none;
        height: 52px;
        line-height: 52px;
        margin-bottom: 8px;
    }

    .left-tree-box {
        flex: auto;
        overflow: auto;
        /deep/.el-tree-node__content {
            height: 30px;
            line-height: 30px;
        }
        .search-res {
            .colorBlue {
                color: #366AFF;
            }
            .search-res-cell {
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                padding: 0 8px;
                color: #606266;
                cursor: pointer;
            }
            .search-res-cell:hover {
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                color: #606266;
                padding: 0 8px;
                background-color: #f2f6fc;
                cursor: pointer;
            }
            .search-res-cell-active {
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                padding: 0 8px;
                color: #606266;
                background-color: #ebeef5;
                cursor: pointer;
            }
        }
        .custom-tree-node {
            display: flex;
            justify-content: space-between;
            .custom-tree-node-name {
                flex: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // max-width: 100px;
            }
            .custom-tree-node-handle {
                flex: none;
                display: none;
                width: 15px;
                .el-icon-more {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
</style>