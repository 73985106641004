<template>
    <div class="answer-text">
        <div class="answer-text-tip">{{$t('flowComponent.answerTextTip')}}</div>
        <div v-if="unit.content.version == 1" class="">
            <div v-for="(textCell, textCellInd) in unit.content.list" :key="textCellInd" class="answer-text-cell">
                <!-- <ckeditor :ref="'auCkeditor_' + textCellInd" class="gr-answer-text-ckeditor" :editor="editorInline"
                    :config="editorConfig" v-model="unit.content.list[textCellInd]" @focus="onEditorFocus">
                </ckeditor>
                <span @click="templateConfigVisibleHandle('usual-text-placeholder', textCellInd)" class="insert-var">
                    <i class="iconfont guoran-tongyichicun-bianliang"></i>
                </span> -->
                <input-insertentity class="answer-text-cell-input-insertentity" :placeholderType="placeholderType" :ref="'inputInsertaut' + textCellInd" :unit="unit" :unitIndex="unitIndex" :textCellInd="textCellInd"
                    @templateConfigVisibleHandle="templateConfigVisibleHandle"></input-insertentity>
                <span v-if="textCellInd == 0" class="answer-text-cell-handle-icon-add" @click="addText">
                    <i class="el-icon-plus"></i>
                </span>
                <span v-if="textCellInd !== 0" class="answer-text-cell-handle-icon-delete" @click="deleteText(textCellInd)">
                    <i class="el-icon-close"></i></span>
            </div>
        </div>
        <div v-else>
            <div v-for="(textCell, textCellInd) in unit.content.list" :key="textCellInd" class="answer-text-cell">
                <el-input autosize type="textarea" :ref="`ata_${unitIndex}_${textCellInd}`"
                    v-model="unit.content.list[textCellInd]" maxlength="2000" :placeholder="$t('flowComponent.textAnswer')"></el-input>
                <span v-if="textCellInd == 0" class="answer-text-cell-handle-icon-add" @click="addText">
                    <i class="el-icon-plus"></i>
                </span>
                <span v-if="textCellInd !== 0" class="answer-text-cell-handle-icon-delete" @click="deleteText(textCellInd)">
                    <i class="el-icon-close"></i></span>
            </div>
        </div>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
import InputInsertentity from "./../other/InputInsertentity.vue";
export default {
    props: ["unit", "unitIndex"],
    components: { InputInsertentity },
    data() {
        return {
            editorDataInline: "",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
            currentEditorId: "",
            currentEditorPosition: "",
            placeholderType: 'usual-text-placeholder',
        };
    },
    methods: {
        focusFirst() {
            let name = `ata_${this.unitIndex}_0`;
            this.$refs[name][0].focus();
        },
        // 添加文本答案输入框
        addText() {
            this.unit.content.list.push("");
            this.unit.content.extInfos.push({
                expectedIntention: "",
                notAsExpectedReply: "",
            });
            this.$nextTick(() => {
                if (this.unit.content.version != 1) {
                    let name = `ata_${this.unitIndex}_${this.unit.content.list.length - 1}`;
                    this.$refs[name][0].focus();
                } else {
                    setTimeout(() => {
                        this.$refs['auCkeditor_' + (this.unit.content.list.length - 1)][0].$el.focus();
                    }, 500);
                }
            })
        },
        // 删除文本答案输入框
        deleteText(textCellInd) {
            this.unit.content.list.splice(textCellInd, 1);
            this.unit.content.extInfos.splice(textCellInd, 1);
        },

        templateConfigVisibleHandle(data) {
            console.log(data.type, data.cellIndex);
            let param = { type: data.type };
            param.unitIndex = this.unitIndex;
            param.lastEditor = lastEditor;
            param.multiple = false;
            param.webhookId_key = [""];
            param.cellIndex = data.cellIndex;
            this.$emit("templateConfigVisibleHandle", param);
            // // 获取当前editor光标位置
            // this.currentEditorPosition = currentEditor.model.document.selection.getFirstPosition();
            // // 1 聚焦当前编辑文本答案文本框 变更currentEditor
            // this.$refs['auCkeditor_' +  data.cellIndex][0].$el.focus();
            // this.$refs['auCkeditor_' +  data.cellIndex][0].$el.blur();
            // setTimeout(() => {
            //     // 如果当前 currentEditor 也是上一次编辑 currentEditor 设置因为聚焦改变的光标位置为已记录的最后光标位置
            //     if (currentEditor.id === this.currentEditorId) {
            //         currentEditor.model.change(writer => writer.setSelection(this.currentEditorPosition))
            //     } else {
            //         // 如果当前 currentEditor 不是上一次编辑的 currentEditor 将光标定位至最后
            //         currentEditor.model.change((writer) => {
            //             writer.setSelection(writer.createPositionAt(currentEditor.model.document.getRoot(), 'end'))
            //         })
            //         this.currentEditorId = currentEditor.id;
            //     }

            //     let param = { type: data.type };
            //     param.unitIndex = this.unitIndex;
            //     param.lastEditor = lastEditor;
            //     param.multiple = false;
            //     param.webhookId_key = [""];
            //     param.cellIndex = data.cellIndex;
            //     this.$emit("templateConfigVisibleHandle", param);
            // }, 500);
        },

        // templateConfigVisibleHandle(type, cellIndex) {
        //     console.log(type, cellIndex);
        //     // 获取当前editor光标位置
        //     this.currentEditorPosition = currentEditor.model.document.selection.getFirstPosition();
        //     // 1 聚焦当前编辑文本答案文本框 变更currentEditor
        //     this.$refs['auCkeditor_' + cellIndex][0].$el.focus();
        //     this.$refs['auCkeditor_' + cellIndex][0].$el.blur();
        //     setTimeout(() => {
        //         // 如果当前 currentEditor 也是上一次编辑 currentEditor 设置因为聚焦改变的光标位置为已记录的最后光标位置
        //         if (currentEditor.id === this.currentEditorId) {
        //             currentEditor.model.change(writer => writer.setSelection(this.currentEditorPosition))
        //         } else {
        //             // 如果当前 currentEditor 不是上一次编辑的 currentEditor 将光标定位至最后
        //             currentEditor.model.change((writer) => {
        //                 writer.setSelection(writer.createPositionAt(currentEditor.model.document.getRoot(), 'end'))
        //             })
        //             this.currentEditorId = currentEditor.id;
        //         }

        //         let param = { type: type };
        //         param.unitIndex = this.unitIndex;
        //         param.lastEditor = lastEditor;
        //         param.multiple = false;
        //         param.webhookId_key = [""];
        //         param.cellIndex = cellIndex;
        //         this.$emit("templateConfigVisibleHandle", param);
        //     }, 500);
        // },
        onEditorFocus(zh, editor) {
            changeEditor(editor);
        },
        // addConfirmDataResponse() {
        //     let command = currentEditor.commands.get("insertAskComponent");
        //     command.execute({
        //         tag: "span-editable",
        //         options: {
        //             name: "单个webhook返回值",
        //             editable: false,
        //             data: webHookDataHolder(51, "data.name"),
        //         },
        //     });
        // },

        // addWebHookSinglePlace(data, webhookId) {
        //     let command = currentEditor.commands.get("insertAskComponent");
        //     command.execute({
        //         tag: "span",
        //         options: {
        //             name: data.name,
        //             data: webHookDataHolder(webhookId, data.value),
        //         },
        //     });
        // },

        // addConfirmDataEntity(data, editable) {
        //     let command = currentEditor.commands.get("insertAskComponent");
        //     command.execute({
        //         tag: "span",
        //         options: {
        //             name: data.name,
        //             data: entityDataHolder(data.name),
        //         },
        //     });
        // },

        addWebHookSinglePlace(data, webhookId, cellIndex) {
            this.$refs['inputInsertaut' + cellIndex][0].addWebHookSinglePlace(data, webhookId);
            // let command = currentEditor.commands.get("insertAskComponent");
            // command.execute({
            //     tag: "span",
            //     options: {
            //         name: data.name,
            //         data: webHookDataHolder(webhookId, data.value),
            //     },
            // });
        },

        addConfirmDataEntity(data, editable, cellIndex) {
            this.$refs['inputInsertaut' + cellIndex][0].addConfirmDataEntity(data, editable);
            // let command = currentEditor.commands.get("insertAskComponent");
            // command.execute({
            //     tag: "span",
            //     options: {
            //         name: data.name,
            //         data: entityDataHolder(data.name),
            //     },
            // });
        },
    },
    mounted() {
        // setTimeout(() => {
        //     this.$refs['auCkeditor_' + 0][0].$el.focus();
        //     this.currentEditorId = currentEditor.id;
        //     this.currentEditorPosition = "end";
        //     // this.$refs['auCkeditor_' + 0][0].$el.blur();
        // }, 300);
    }
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";

.answer-text {
    margin-top: 20px;

    .answer-text-tip {
        background: #fff8f4;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        color: #ff9555;
        line-height: 32px;
        text-align: left;
        padding: 0 12px;
        margin-bottom: 12px;
    }



    .answer-text-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;

        .answer-text-cell-input-insertentity{
            width: calc(100% - 40px);
        }

        .el-select--small {
            flex: none;
            width: 100% !important;
        }

        .el-textarea {
            width: 100%;
        }

        .answer-text-cell-handle-icon-add,
        .answer-text-cell-handle-icon-delete {
            width: 29px;
            flex: none;
        }

        .gr-answer-text-ckeditor {
            width: 100%;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            padding-right: 38px;
            height: auto;
        }

        .ck-focused {
            border: 1px solid #366aff;
        }

        .insert-var {
            color: black;
            display: block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            background-color: #DCDFE6;
            border-radius: 4px;
            cursor: pointer;
            margin-left: -32px;
            margin-right: 6px;

        }
    }
}
</style>