var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-audio"},[_c('div',{staticClass:"respose-video"},[_c('div',{staticClass:"album albumvideo"},[(_vm.unit.content.url == '')?_c('div',{staticClass:"pic_img"},[_c('div',{staticClass:"pic_img_box"},[_c('el-upload',{staticClass:"upload-ask",attrs:{"show-file-list":false,"http-request":((data) => {
                        return _vm.aliyunOssRequest(data, 'ask')
                    }),"before-upload":(file) => _vm.beforeUploadVideo(file, _vm.index),"on-exceed":_vm.uploadExceed,"on-success":(res, file) =>
                                _vm.handleVideoSuccess(res, file, _vm.index),"on-remove":_vm.handleRemove,"auto-upload":true,"accept":".mp3,.wav","multiple":""}},[(_vm.unit.content.extInfos[0].videoFlag == true)?_c('el-progress',{staticClass:"aa-loading",staticStyle:{"margin-top":"7px"},attrs:{"width":"50","type":"circle","percentage":_vm.unit.content.extInfos[0].videoUploadPercent}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})],1)],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit.content.url != ''),expression:"unit.content.url != ''"}],staticClass:"ask-audio"},[_c('span',{staticClass:"delete-answer-audio",on:{"click":_vm.deleteVoice}},[_c('i',{staticClass:"iconfont guoran-tongyichicun-16-09-shanchu2"})]),_c('div',{staticClass:"ask-audio-title"},[_vm._v(_vm._s(_vm.unit.content.filenName))]),_c('div',{staticClass:"ask-audio-content"},[_c('audio',{attrs:{"id":'audioTag' + this.unit.content.extInfos[0].avCurrentTime,"src":_vm.unit.content.url}}),_c('div',{staticClass:"audiocon"},[_c('div',{staticClass:"leftBtn"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPlay),expression:"!isPlay"}],on:{"click":_vm.bofang}},[_c('i',{staticClass:"iconfont guoran-bofang"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlay),expression:"isPlay"}],on:{"click":_vm.bofang}},[_c('i',{staticClass:"iconfont guoran-zanting"})])]),_c('div',{staticClass:"ask_adcon"},[_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.time))])]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"activeLine",on:{"click":_vm.seekTime}},[_c('div',{staticClass:"dot",style:({ width: _vm.currentPosi + '%' })})])]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.allTime))])])])])]),_c('el-upload',{staticClass:"upload-ask",attrs:{"show-file-list":false,"http-request":((data) => {
                    return _vm.aliyunOssRequest(data, 'ask')
                }),"before-upload":(file) => _vm.beforeUploadVideo(file, _vm.index),"on-exceed":_vm.uploadExceed,"on-success":(res, file) =>
                            _vm.handleVideoSuccess(res, file, _vm.index),"on-remove":_vm.handleRemove,"auto-upload":true,"accept":".mp3,.wav","multiple":""}},[_c('span',{staticClass:"reupload-answer-audio"},[_c('i',{staticClass:"iconfont guoran-youxuan"})])])],1),_c('p',{staticClass:"Upload_pictures"},[_c('span'),_c('span',[_vm._v(_vm._s(_vm.$t('flowComponent.uploadVoiceTip')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }