<template>
    <div id="change_language">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i></span>
                        <span>{{ $t('common.enterpriseEdition') }} - </span>
                        <span>{{ $t('setLanguage.title') }}</span>
                    </div>
                    <div class="setup-header-right-l" slot="setup-header-right-l">
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <div class="language_label">
                        <section class="language_left">
                            <p class="label">{{ $t('setLanguage.label') }}</p>
                            <p class="tip">{{ $t('setLanguage.tip') }}</p>
                        </section>
                        <section class="language_right">
                            <el-dropdown @command="changeLanguage">
                                <div class="el-dropdown-link">
                                    {{ filterLabel() }} <span class="icon_view"><i
                                            class="el-icon-arrow-down el-icon--right"></i></span>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in options" :key="item.value" :command="item.value">{{
                                        item.label
                                    }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </section>
                    </div>
                    <div class="languaage_ls" v-for="(l, index) in languageList" :key="index">
                        <div class="l_name_config">
                            <div class="l_name">
                                <span>{{ filterCode(l.languageCode) }}</span>
                                <div class="l_btn" @click="openMember(l)">
                                  {{ $t('setLanguage.management') }}
                                </div>
                            </div>
                            <div class="l_tips">
                              {{ $t('setLanguage.setLa')}}{{ filterCode(l.languageCode) }}
                            </div>
                            <div class="l_config" v-if="l.extInfo && l.extInfo.length != 0">
                                <div class="account_view" v-for="account in l.extInfo" :key="account.id">
                                    <div class="account_name" :style="{
                                        background: account.type == 1 ? '#E6E5FF' : '#D9E3FF'
                                    }">
                                        <div class="account_type" :style="{
                                            background: account.type == 1 ? '#918EFF' : '#366AFF'
                                        }">
                                            <i v-if="account.type == 1" class="iconfont guoran-tongyichicun-bumen"></i>
                                            <span v-if="account.type == 0">{{ account.name[0] }}</span>
                                        </div>
                                        {{ account.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="icon_view"><i class="el-icon-arrow-down el-icon--right"></i></section>
                    </div>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
        <Popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name" class="popup-name">{{$t('accountManage.memberTitle')}}</div>
            <div slot="popup-con" class="member-contanier">
                <staffCustomer :isShowUpDown="false" :clientType="'ASKBOT_MEMBER'" @onNodeClick="onNodeClick"
                    :isMultiple="true" :isCheckDept="true" :memberInfo4Client="memberInfo4Client" ref="staffCustomer">
                </staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeStaff" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </Popup>

    </div>
</template>

<script>
import Popup from "@/components/popup";
import SideNav from "./../../menu/SideNav.vue";
import TopHeader from "./../../../components/top-header.vue";
import DeleteDialog from "./../../../components/deleteDialogue.vue";
import ManangeNav from "./../../menu/ManageNave.vue";
import staffCustomer from "../../../components/staffCustomer.vue";
export default {
    name: "change_language",
    data () {
        return {
            languageValue: 'cn',
            options: [
                {
                    value: 'cn',
                    label: '简体中文'
                },
                {
                    value: 'en',
                    label: 'English'
                },
            ],
            languageList: [
                {
                    languageCode: 'cn',
                    languageName: '简体中文',
                    extInfo: []
                },
                {
                    languageCode: 'en',
                    languageName: 'Enligsh',
                    extInfo: []
                },
            ],
            checkedList: [],
            dialogMemberTree: false,
            memberInfo4Client: {
                clientInfo: []
            },
            currentLanguageItem: {},
            currentId: ''
        };
    },
    components: {
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        ManangeNav,
        staffCustomer
    },
    methods: {
        filterCode (code) {
            switch (code) {
                case 'cn':
                    return this.$t('setLanguage.cnLa')
                case 'en':
                    return 'English'
            }
        },
        filterLabel () {
            if (this.options.find(item => { return item.value == this.languageValue })) {
                return this.options.find(item => { return item.value == this.languageValue }).label
            } else {
                return ''
            }
        },
        changeLanguage (value) {
            this.$http.put("/api/company/language/" + this.currentId, {
                systemLanguage: value
            }).then(res => {
                console.log(res, 'resresres');
                this.$message({
                    message: this.$t('staffManage.setSuccess'),
                    type: "success"
                })
                this.languageValue = value;
                let noLanguageCode = sessionStorage.getItem('noLanguageCode');
                if (noLanguageCode){
                    sessionStorage.setItem('systemLanguage', this.languageValue)
                    this.$i18n.locale = this.languageValue;
                }
                this.filterLabel()
            })
        },
        getLanguageList () {
            this.$http.get("/api/language/").then(res => {
                if (res.data.code == "0") {
                    console.log(res.data);
                    let data = res.data.data
                    if (data.length != 0) {
                        for (let index = 0; index < this.languageList.length; index++) {
                            let element = this.languageList[index]
                            data.forEach(item => {
                                console.log(this.languageList, 'this.languageList');
                                try {
                                    item.extInfo = JSON.parse(item.extInfo)
                                } catch {

                                }
                                if (element.languageCode == item.languageCode) {
                                    this.$set(this.languageList, index, item)
                                }
                            })
                        }
                    } else {
                        // this.$set(this, 'languageList', )
                    }
                }
            })
        },
        openMember (l) {
            this.currentLanguageItem = JSON.parse(JSON.stringify(l))
            if (l.extInfo) {
                let accountList = JSON.parse(JSON.stringify(l.extInfo))
                this.$set(this.memberInfo4Client, 'clientInfo', accountList)
            } else {
                this.$set(this.memberInfo4Client, 'clientInfo', [])
            }
            this.dialogMemberTree = true
        },
        onNodeClick (data, type, memberInfo, row) {
            this.isCheckData = data;
            let list = JSON.parse(JSON.stringify(this.checkedList))
            if (data.checked) {
                list.push(data);
            } else {
                list.forEach((v, i) => {
                    if (data.id == v.id) {
                        list.splice(i, 1)
                    }
                })
            }
            this.checkedList = this.handlerArr(list);
        },
        handlerArr (tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        closeStaff () {
            this.dialogMemberTree = false
            this.memberInfo4Client.clientInfo = []
        },
        preserStaff () {
            this.currentLanguageItem.extInfo = JSON.stringify(this.memberInfo4Client.clientInfo.map(client => {
                client.isWWOpenData = false
                return client
            }))
            this.currentLanguageItem.permissionKey = this.memberInfo4Client.clientInfo.map(client => { return client.memberId })
            this.$http.post("/api/language/save", this.currentLanguageItem).then(res => {
                if (res.data.code == '0') {
                    this.$message.success("保存成功，重新进入应用后生效")
                    this.dialogMemberTree = false
                    this.getLanguageList()
                }
            })
        },
        getUserCompany(){
            this.$http.get(this.requestUrl.companyManage.getUserCompany).then(res => {
                if (res.data.code == 0 && res.data.data){
                    this.languageValue = res.data.data.systemLanguage
                    this.currentId = res.data.data.id
                }
            })
        }
    },
    mounted () {
        this.getUserCompany();
        this.getLanguageList()
    }
};
</script>

<style scoped lang="less">
#change_language {
    .gr-content-container {
        position: absolute;
        left: 64px;
        width: calc(100vw - 96px);
        height: calc(100vh - 24px);
        padding: 12px 16px;
        min-width: 1000px;
        overflow-x: auto;
        text-align: left;

        .content {
            width: 100%;
            height: calc(100% - 84px);
            margin-top: 16px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;

            .manage-nav-section {
                //width: 216px;
                width: 300px;
                flex: none;
                margin-right: 16px;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;
            }

            .content-section {
                flex: auto;
                overflow: hidden;
                box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
                border-radius: 5px;
                background-color: white;
            }
        }
    }

    .language_label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #E0E6F7;
        padding: 16px;
        box-sizing: border-box;

        .language_left {
            .label {
                font-size: 16px;
                color: #000000;
                margin-bottom: 8px;
                font-weight: 500;
            }

            .tip {
                font-size: 12px;
                color: #A9B3C6;
            }
        }
    }

    .languaage_ls {
        width: 100%;
        height: auto;
        min-height: 50px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        border-bottom: 1px solid #E0E6F7;
        padding: 16px;
        box-sizing: border-box;

        .l_name_config {
            width: calc(100% - 20px);

            .l_name {
                display: flex;
                align-items: center;

                span {
                    font-size: 16px;
                    font-weight: 500;
                    margin-right: 25px;
                }

                .l_btn {
                    //width: 53px;
                    padding: 0 6px;
                    height: 26px;
                    background: #366AFF;
                    border-radius: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }

            .l_tips {
                font-size: 12px;
                font-weight: 400;
                color: #A9B3C6;
                margin: 12px 0;
            }

            .l_config {
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;

                .account_name {
                    display: flex;
                    align-items: center;
                    padding: 2px 12px 2px 40px;
                    margin: 8px 8px 0 0;
                    border-radius: 25px;
                    position: relative;
                    font-size: 14px;
                    font-weight: 500;

                    .account_type {
                        position: absolute;
                        left: 0;
                        width: 31px;
                        height: 31px;
                        background: #366AFF;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFFFFF;
                        i {
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

    .el-dropdown-link {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .icon_view {
        width: 20px;
        height: 20px;
        background: #F3F5FB;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        cursor: pointer;

        i {
            margin-left: 0;
            font-size: 12px;
            color: #606266;
        }
    }
}
</style>