<template>
    <div class="situation-all">
        <div class="success-login" v-if="situationAllType === 2 || situationAllType === 3 || situationAllType === 6">
            <img src="../../../../assets/images/success-join.png" alt="">
            <div class="text">
                <span v-if="situationAllType === 2">已成功加入</span>
                <span v-if="situationAllType === 3">已申请加入组织，请等待组织审核</span>
                <span v-if="situationAllType === 6">该组织已拒绝加入,请联系阻止管理员</span>
            </div>
            <div class="dept-box">
                <div class="top">
                    <div class="iconfont guoran-gongsiicon"></div>
                    <open-data v-if="inviteDeptData.source == 1" :openid="inviteDeptData.companyName" :type="'departmentName'"></open-data>
                    <span v-else>{{inviteDeptData.companyName}}</span>
                </div>
                 <div class="bottom">
                    <div></div>
                    <span class="name">
                        <open-data v-if="inviteDeptData.source == 1" :openid="inviteDeptData.departmentName" :type="'departmentName'"></open-data>
                        <span v-else>{{inviteDeptData.departmentName}}</span>
                    </span>
                    <div></div>
                </div>
                <el-button v-if="situationAllType === 6" type="primary" round @click="reJoin">重新加入</el-button>
            </div>
        </div>
         <div class="load-text" v-if="situationAllType === 2 || situationAllType === 3 || situationAllType === 6">
            <span>还没有客户端？</span>
            <span class="loading" @click="openNewWindow">立即下载客户端</span>
        </div>
        <div class="success-login invalid" v-else-if="situationAllType === 1 || situationAllType === 4">
            <img src="../../../../assets/images/invalid.png" alt="">
            <div class="text">{{situationAllType === 1 ? '邀请链接已失效，请咨询邀请者重新获取邀请链接' : '请使用本地浏览器进行访问'}}</div>
        </div>
        <div class="success-login mobile-box" v-else-if="situationAllType === 5">
            <!-- <div class="title">AskBot</div> -->
            <!-- <div class="content"> -->
                <img src="../../../../assets/images/invalid-mobile.png" alt="">
                <div class="text">请在电脑端使用本地浏览器进行访问</div>
            <!-- </div> -->
        </div>
        
    </div>
</template>

<script>
import agentConfig from "@/common/wechat/agentConfig";
import OpenData from "@/components/openData";
export default {
    data(){
        return {
            // mt:0
            inviteDeptData:{}
        }
    },
    components:{OpenData},
    props:{
        // //   1 邀请码过期  2 成功加入-无需审核  3 成功加入-需要审核   4 请使用本地浏览器进行访问  5 请在电脑端使用本地浏览器进行访问  6 拒绝申请
        situationAllType:{
            type:Number,
            default:0
        },
        pageType:{
            type:String,
            default:""
        }

    },
    watch:{
        pageType:{
            handler(n){
                this.inviteDeptData = JSON.parse(localStorage.getItem('inviteDeptData'))
                console.log(n,'nnnn');
                if(n === 'tips'){
                    this.$nextTick(() => {
                        let corpId = JSON.parse(localStorage.getItem('inviteData')).corpId;
                        let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
                        let url =  this.requestUrl.wechatKF.agentConfigNew + "?corpId="+corpId+ "&url="+currentUrl;
                        this.$http.get(url).then(res=>{
                            if (res.data&&res.data.data){
                                agentConfig(corpId,res.data.data.data,(res)=>{
                                    WWOpenData.bind(this.$el);
                                });
                            }
                        })
                    })
                }
            },
            immediate:true
        }
    },
    methods:{
        // 重新加入
       reJoin(){
           let departmentId = JSON.parse(localStorage.getItem('inviteData')).departmentId
           let clientUserId = Number(localStorage.getItem('inviteUserId'))
           this.$http.get(this.requestUrl.invite.joinAgain + '?departmentId=' + departmentId+"&clientUserId="+clientUserId).then((res) => {
                if (res.data.code === "0") {
                        // 1 等待审核 4 已经加入
                    console.log(res,'重新加入');
                    this.$emit('update:situationAllType',res.data.data === 1 ? 3 : 2)
                } else {
                    this.$message.error(res.data.message);
                }
            })
       },
       openNewWindow(){
           window.open('https://www.baidu.com/')
       }

    }
}
</script>

<style lang="less" scoped>
.situation-all{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Microsoft YaHei;
    position: relative;
    .success-login{
        display: flex;
        flex-direction: column;
        justify-content: center;
        img{
            margin-bottom: 28px;
        }
        .text{
            font-size: 24px;
            font-weight: bold;
            color: #616161;
            margin-bottom: 28px;
        }
        .dept-box{
            .top{
                margin-bottom: 12px;
                font-size: 17px;
                color: #000000;
                display: flex;
                justify-content: center;
                .iconfont{
                    background-color: #366AFF;
                    width: 29px;
                    height: 29px;
                    text-align: center;
                    line-height: 29px;
                    margin-right: 6px;
                    color: #fff;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 29px;
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                justify-content: center;
                div{
                    width: 16px;
                    height: 1px;
                    background-color: #CFCFCF;
                    color: #8D8D8D;
                }
                .name{
                    color: #8D8D8D;
                    font-size: 14px;
                    margin:  0 10px;
                }
            }
            .el-button{
                background-color: #366AFF;
                border-color: #366AFF;
                width: 100%;
                margin-top: 40px;
                &:hover{
                    background-color: #366AFF;
                    border-color: #366AFF;
                }
            }
        }
       
    }
    .load-text{
        color: #616161;
        position: absolute;
        bottom: 50px;
        .loading{
            color: #366AFF;
            cursor: pointer;
        }
    }
    .mobile-box{
        display: flex;
        align-items: center;
        justify-content: center;
       img{
            width: 252px;
            // height: 258px; 
       }
       .text{
           font-size: 18px;
           color: #010101;
           font-weight: bold;
       }
    }
   
}

</style>