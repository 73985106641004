<template>
    <div>
        <DepartmentTreeNodeWithOpenData
            v-for="(node, index) in data"
            :node="node"
            :key="'str' + index"
            @onNodeClick="onNodeClick"
        >
            {{ node }}
        </DepartmentTreeNodeWithOpenData>
    </div>
</template>
<script>
import DepartmentTreeNodeWithOpenData from "./DepartmentTreeNodeWithOpenData";

export default {
    name: "DepartmentTree",
    components: { DepartmentTreeNodeWithOpenData },
    data() {
        return {};
    },
    props: {
        data: Array,
    },
    methods: {
        onNodeClick(node) {
            this.$emit("onNodeClick", node);
        },
    },
};
</script>