var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"roleTable"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.roleData,"height":"calc(100vh - 130px)"}},[_c('el-table-column',{attrs:{"prop":"name","label":"数据权限名称","width":"240px","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[_vm._v(" "+_vm._s(scope.row.privilegesName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"数据权限描述","align":"left","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[(scope.row.description !== '')?_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(_vm._s(scope.row.description))]):_c('span',[_vm._v("--")])])]}}])}),_c('el-table-column',{attrs:{"prop":"useNum","label":"使用人数","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('el-button',{attrs:{"type":"text","disabled":scope.row.id == 0 || scope.row.id == -1,"size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.editRow(scope.$index, scope.row.id)}}},[_c('span',[_vm._v("编辑")])]),(scope.row.id != 0 && scope.row.id != -1)?_c('el-button',{staticStyle:{"color":"tomato"},attrs:{"type":"text","size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(
                                scope.row.id,
                                scope.row.privilegesName,
                                scope.row.useNum
                            )}}},[_vm._v(" 删除 ")]):_vm._e(),(scope.row.id == 0 || scope.row.id == -1)?_c('el-button',{attrs:{"disabled":scope.row.id == 0 || scope.row.id == -1,"type":"text","size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(
                                scope.row.id,
                                scope.row.privilegesName,
                                scope.row.useNum
                            )}}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"footer-pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.roleTableTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"title":"删除数据权限","visible":_vm.dialogVisible,"close-on-click-modal":false,"width":"300px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticStyle:{"text-align":"left","padding-left":"14px"}},[_vm._v("是否删除数据权限 "),_c('span',{staticStyle:{"padding-left":"4px","font-weight":"600"}},[_vm._v(_vm._s(_vm.roleName))])]),(_vm.deleteUseNum != 0)?_c('p',{staticStyle:{"text-align":"left","padding-left":"14px","margin-top":"12px","color":"#909399"}},[_c('span',[_vm._v("该数据权限下有账号正在使用，删除后账号下将没有该数据权限，如需继续删除，请完整输入您要删除的数据权限名称。")]),_c('br'),_c('br'),_c('el-input',{attrs:{"size":"small","placeholder":"请输入数据权限名称"},model:{value:(_vm.checkName),callback:function ($$v) {_vm.checkName=$$v},expression:"checkName"}})],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":_vm.deleteRole}},[_vm._v("删 除")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }