<template>
    <div class="label-manage">
        <div class="label-manage-labellist">
            <div v-if="labelList.length != 0" class="label-manage-labellist-content">
                <div
                    v-for="(labelItem, labelIndex) in labelList"
                    :key="labelIndex"
                    :class="[
                        'lmll-cell',
                        activeIndex == labelIndex
                            ? 'lmll-cell-active'
                            : 'lmll-cell-other',
                    ]"
                    @click="selectLable(labelItem, labelIndex)"
                >
                    <span class="lmll-cell-icon">
                        <svg
                            t="1713844342165"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="39082"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M951.90495 457.808058l-173.915911 490.545171c-20.972213 59.336016-85.93492 90.027059-145.270937 69.054846l-409.725424-147.317006c-65.474225-23.5298-94.630716-99.74589-61.382086-161.127976l42.455943-78.262159 309.468017 146.805489c0.511517-0.511517 438.370398-319.698365 438.370398-319.698365z"
                                fill="#7A7AF9"
                                p-id="39083"
                            ></path>
                            <path
                                d="M73.629602 442.974054L489.493235 31.714078C512 9.71883 542.691043-1.534552 573.382086 1.534552l301.283739 28.644974c50.640221 4.603656 90.538577 45.525047 94.119198 96.676785l18.926144 288.495804c2.04607 30.179526-9.207313 59.847534-30.691043 81.331264l-414.329081 412.794529c-44.502012 44.502012-117.137481 44.502012-161.639493 0.511517l-307.421948-303.329808c-45.525047-45.01353-45.525047-118.672033 0-163.685563z"
                                fill="#CCDAFF"
                                p-id="39084"
                            ></path>
                            <path
                                d="M728.371853 194.888123c0 36.829252 29.668008 67.008777 66.49726 67.008777s67.008777-29.668008 67.008777-66.49726-29.668008-67.008777-66.49726-67.008777c-37.340769 0-67.008777 29.668008-67.008777 66.49726zM481.308957 670.087772L312.50822 501.287036M481.308957 711.009163c-10.230348 0-20.972213-4.092139-29.156491-11.7649l-168.800736-168.800736c-15.857039-15.857039-15.857039-41.944425 0-57.801465 15.857039-15.857039 41.944425-15.857039 57.801464 0l168.800736 168.800737c15.857039 15.857039 15.857039 41.944425 0 57.801464-7.672761 7.672761-18.414626 11.7649-28.644973 11.7649z"
                                fill="#7A7AF9"
                                p-id="39085"
                            ></path>
                        </svg>
                    </span>
                    <span class="lmll-cell-name">{{ labelItem.name }}</span
                    ><span class="lmll-cell-num">{{
                        labelItem.numberOfAssociatedMembers
                    }}</span>
                    <el-dropdown
                        v-if="labelItem.source == 0"
                        @command="handleCommand($event, labelItem)"
                    >
                        <span class="lmll-cell-handle">
                            <i class="arsenal_icon arsenalgengduo"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="edit">
                                <i
                                    style="font-size: 12px"
                                    class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                                ></i>{{$t('staffManage.rename')}}
                            </el-dropdown-item>
                            <el-dropdown-item command="delete">
                                <i
                                    style="font-size: 12px"
                                    class="iconfont guoran-tongyichicun-18-13-shanchu"
                                ></i
                                >{{$t('common.delete')}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span v-else style="opacity:0" class="lmll-cell-handle">
                        <i class="arsenal_icon arsenalgengduo"></i>
                    </span>
                </div>
            </div>
            <div v-else class="label-manage-labellist-content">
                <DefaultImage></DefaultImage>
            </div>
            <div class="label-manage-labellist-add">
                <span class="lmla" @click="addLabel">
                    <i class="lmla-icon iconfont guoran-a-16-13"></i>
                    <span class="lmla-text">{{$t('staffManage.addLabel')}}</span>
                </span>
            </div>
        </div>
        <div class="label-manage-orglist">
            <div class="staff-table">
                <el-table
                    :data="tableData"
                    height="calc(100vh - 218px)"
                    :row-key="bridgecode"
                    :header-cell-style="{ textAlign: 'left' }"
                    ref="multipleTable"
                    v-loading="staffLoading"
                    @selection-change="handleSelectionChange"
                >
                    <template slot="empty">
                        <DefaultImage></DefaultImage>
                    </template>
                    <el-table-column type="selection" :reserve-selection="true">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        :label="$t('staffManage.tableName')"
                        width="300"
                        align="left"
                    >
                        <template slot-scope="scope">
                            <div class="user-dept-box">
                                <template
                                    v-if="
                                        scope.row.itemName.substr(0, 4) ===
                                            'woWe' ||
                                        isStrings(scope.row.itemName)
                                    "
                                >
                                    <span class="sl-name-pre">
                                        <span class="order-reporter-avatar">
                                            <i
                                                class="iconfont guoran-a-18-32"
                                            ></i>
                                        </span>
                                    </span>
                                    <div class="dept-box">
                                        <span
                                            v-if="scope.row.type == 'MEMBER'"
                                            class="userName"
                                        >
                                            <open-data
                                                :type="'userName'"
                                                :openid="scope.row.itemName"
                                            ></open-data>
                                        </span>
                                        <span v-else class="userName">
                                            <open-data
                                                :type="'departmentName'"
                                                :openid="scope.row.itemName"
                                            ></open-data>
                                        </span>
                                    </div>
                                </template>
                                <template v-else>
                                    <span class="sl-name-pre">{{
                                        scope.row.itemName.substr(0, 1)
                                    }}</span>
                                    <div style="display: inline-block">
                                        <span>{{ scope.row.itemName }}</span>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="departments"
                        :label="$t('staffManage.department')"
                        align="left"
                    >
                        <template slot-scope="scope">
                            <div class="user-dept-box">
                                <template
                                    v-if="
                                        scope.row.itemName.substr(0, 4) ===
                                            'woWe' ||
                                        isStrings(scope.row.itemName)
                                    "
                                >
                                    <div class="dept-box" v-if="scope.row.fullNames.length != 0">
                                        <div
                                            v-for="(item, index) in scope.row
                                                .fullNames"
                                            :key="index"
                                            class="mult-dept"
                                        >
                                            <div v-for="(cell, cellIndex) in item.split('/')" :key="cellIndex" class="mult-dept-cell">
                                                <span v-show="cellIndex != 0"> / </span>
                                                <open-data
                                                    :type="'departmentName'"
                                                    :openid="cell"
                                                ></open-data>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        <div class="dept-box dept-boxs">
                                            <div
                                                v-for="(cell, index) in scope
                                                    .row.fullNames"
                                                :key="index"
                                            >
                                                <span v-html="cell"></span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination
                    :pageSize="pageSize"
                    :currentPage="currentPage"
                    :total="searchTotal"
                    @currentChange="searchCurrentChange"
                ></pagination>
            </div>
        </div>
        <popup v-if="addLableVisible" @closeEvent="cancelAdd">
            <div slot="popup-name" class="popup-name">
                {{ handleType == "edit" ? $t('staffManage.edit') : $t('staffManage.add') }} {{$t('staffManage.label')}}
            </div>

            <div slot="popup-con">
                <div class="add-label-form-cell">
                    <span class="add-label-form-cell-name"
                        >{{$t('staffManage.labelName')}}<em>*</em></span
                    >
                    <el-input
                        v-model="labelName"
                        size="small"
                        maxlength="30"
                        show-word-limit
                        :placeholder="$t('staffManage.labelNamePla')"
                    ></el-input>
                </div>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <span style="" class="cancel-bind" @click="cancelAdd"
                    >{{$t('common.cancel')}}</span
                >
                <el-button type="primary" @click="confirmAdd">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <delete-dialog @closeEvent="dialogVisible = false" v-if="dialogVisible">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('staffManage.deleteLabel')}}</div>
                <div>
                    <span style="text-align: left">{{$t('staffManage.deleteLabelTip')}}</span>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogVisible = false" size="small" plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteLabel"
                    style="background-color: #fe5965; color: white"
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
        <popup
            v-if="selectDepMumVisible"
            @closeEvent="cancelSelectDepMumVisible"
        >
            <div slot="popup-name" class="popup-name">{{$t('accountManage.memberTitle')}}</div>

            <div slot="popup-con">
                <staff-customer
                    :memberInfo4Client="memberInfo4Client"
                    :checkedList="memberInfo4Client.clientInfo"
                    @onNodeClick="onNodeClick"
                    :isMultiple="true"
                    ref="staffCustomerIm"
                    dataType="staff"
                    :parentCorpId="corpId"
                    :filterByCorpId="true"
                    :insertIm="false"
                ></staff-customer>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <span
                    style=""
                    class="cancel-bind"
                    @click="cancelSelectDepMumVisible"
                    >{{$t('common.cancel')}}</span
                >
                <el-button type="primary" @click="confirmSelectDepMum"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from "@/components/popup";
import DeleteDialog from "@/components/deleteDialogue.vue";
import DefaultImage from "@/components/DefaultImage.vue";
import pagination from "@/components/pagination.vue";
import staffCustomer from "./staffcustomerNew.vue";
import OpenData from "@/components/openData";
import axios from "axios";
export default {
    data() {
        return {
            labelList: [],
            activeIndex: "",
            activeItem: "",
            addLableVisible: false,
            labelName: "",
            handleType: "add",
            editLabelObj: { name: "" },
            dialogVisible: false,

            // 列表相关
            tableData: [],
            staffLoading: false,
            staffChecked: false,
            staffCheckedIds: [],

            pageSize: 20,
            currentPage: 1,
            searchTotal: 0,

            isSeaching: false,
            activateStatus: null,

            selectDepMumVisible: false,
            memberInfo4Client: {
                clientInfo: [],
            },
            isCheckData: [],
        };
    },
    props: ["corpId", "keyword"],
    components: {
        Popup,
        DeleteDialog,
        DefaultImage,
        pagination,
        staffCustomer,
        OpenData,
    },
    watch: {
        staffCheckedIds: {
            handler(staffCheckedIds) {
                const table = this.$refs.multipleTable;
                const tableHeader = table.$refs.tableHeader.$el;
                const selectAllCheckbox = tableHeader.querySelector(
                    ".el-checkbox__original"
                );

                if (selectAllCheckbox) {
                    selectAllCheckbox.checked =
                        staffCheckedIds.length === this.tableData.length;
                }
            },
            deep: true,
        },
    },
    methods: {
        isStrings(value) {
            const res = new RegExp("[\u4E00-\u9FA5]+");
            return !res.test(value);
        },
        onNodeClick(node, type, memberInfo, row) {
            let obj = JSON.parse(JSON.stringify(node));
            obj.name = obj.label || obj.name;
            let AddObj = {
                id: obj.id,
                name: obj.label,
                type: obj.type,
                isWeWorkThirdData: obj.isWeWorkThirdData,
            };
            if (obj.checked) {
                obj.ewechat = obj.staffType === "staff-t" ? true : false;
                this.memberInfo4Client.clientInfo.push(AddObj);
                this.memberInfo4Client.clientInfo = this.handlerArr(
                    this.memberInfo4Client.clientInfo
                );
            } else {
                this.memberInfo4Client.clientInfo.forEach((v, i) => {
                    if (obj.id == v.id) {
                        this.memberInfo4Client.clientInfo.splice(i, 1);
                    }
                });
                this.memberInfo4Client.clientInfo = this.handlerArr(
                    this.memberInfo4Client.clientInfo
                );
            }
            console.log(this.memberInfo4Client.clientInfo);
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                }
            }
            return result;
        },
        cancelSelectDepMumVisible() {
            this.selectDepMumVisible = false;
        },
        confirmSelectDepMum() {
            if (this.activeItem.source == 1) {
                this.$message.warning(
                    this.$t('staffManage.noSupportAdd')
                );
                return;
            }
            if (this.memberInfo4Client.clientInfo.length == 0) {
                this.$message.warning(this.$t('staffManage.selectMemberOrDept'));
            }
            let tamparr = this.memberInfo4Client.clientInfo.map((item) => ({
                id: item.id,
                type: item.type == 0 ? "MEMBER" : "DEPARTMENT",
            }));
            let url =
                this.requestUrl.lable.linkMem +
                this.activeItem.id +
                "?corpId=" +
                this.corpId;
            axios
                .put(url, tamparr, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    if (res.data && res.data.code == 0) {
                        this.getLabelList(() => {}, this.activeIndex);
                        this.searchLable();
                        this.selectDepMumVisible = false;
                        this.$message.success(this.$t('staffManage.addMemberSuccess'));
                        this.memberInfo4Client = {
                            clientInfo: [],
                        };
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        moveUserFromLable() {
            if (this.activeItem.source == 1) {
                this.$message.warning(
                    this.$t('staffManage.noSupportRemove')
                );
                return;
            }
            if (this.staffCheckedIds.length == 0) {
                this.$message.warning(this.$t('staffManage.selectRemoveMember'));
            } else {
                console.log(this.staffCheckedIds);
                let tamparr = this.staffChecked.map((item) => ({
                    id: item.itemId,
                    type: item.type,
                }));
                let url =
                    this.requestUrl.lable.unlinkMem +
                    this.activeItem.id +
                    "?corpId=" +
                    this.corpId;
                axios
                    .put(url, tamparr, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        if (res.data && res.data.code == 0) {
                            this.currentPage = 1;
                            this.staffChecked = [];
                            this.staffCheckedIds = [];
                            this.getLabelList(() => {}, this.activeIndex);
                            this.searchLable();
                            this.$message.success(this.$t('staffManage.removeSuccess'));
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
            }
        },
        addUserToLable() {
            this.selectDepMumVisible = true;
        },

        bridgecode(row) {
            return row.itemId;
        },
        getLabelList(callback, activeIndex) {
            let url =
                this.requestUrl.lable.getTagList + "?corpId=" + this.corpId;
            this.$http.get(url).then((res) => {
                if (res.data && res.data.code == 0) {
                    this.labelList = res.data.data;
                    if (this.labelList.length > 0) {
                        if (activeIndex) {
                            this.activeItem = this.labelList[activeIndex];
                            this.activeIndex = activeIndex;
                        } else {
                            this.activeItem = this.labelList[0];
                            this.activeIndex = 0;
                        }

                        if (callback) {
                            if (callback == "changeMainValue") {
                                this.searchLable();
                            } else {
                                callback();
                            }
                        }
                    } else {
                        this.tableData = [];
                        this.searchTotal = 0;
                    }
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
        selectLable(labelItem, labelIndex) {
            this.activeItem = labelItem;
            this.activeIndex = labelIndex;
            this.currentPage = 1;
            let url = `${this.requestUrl.lable.getLableMumber}?corpId=${
                this.corpId
            }&page=${this.currentPage - 1}&size=${this.pageSize}&keyword=${
                this.keyword
            }&tagId=${this.activeItem.id}`;
            this.$http.get(url).then((res) => {
                console.log(res.data);
                if (res.data && res.data.code == 0) {
                    this.tableData = res.data.data.list;
                    this.searchTotal = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        searchLable(keyword, mainValue, currentPage) {
            console.log(keyword, mainValue, currentPage);
            if (currentPage != null || currentPage != undefined) {
                this.currentPage = currentPage;
            } else {
                this.currentPage = 1;
            }

            let url = `${this.requestUrl.lable.getLableMumber}?corpId=${
                this.corpId
            }&page=${this.currentPage - 1}&size=${this.pageSize}&keyword=${
                this.keyword
            }&tagId=${this.activeItem.id}`;
            this.$http.get(url).then((res) => {
                if (res.data && res.data.code == 0) {
                    this.tableData = res.data.data.list;
                    this.searchTotal = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        addLabel() {
            this.addLableVisible = true;
            this.handleType = "add";
        },
        cancelAdd() {
            this.labelName = "";
            this.addLableVisible = false;
        },
        confirmAdd() {
            if (this.labelName.trim() == "") {
                this.$message.warning(this.$t('staffManage.labelNameRequired'));
                return;
            }
            console.log(this.editLabelObj);
            if (this.handleType == "add") {
                let url = this.requestUrl.lable.addTag + "" + this.corpId;
                this.$http
                    .post(url, {
                        corpId: this.corpId,
                        name: this.labelName.trim(),
                    })
                    .then((res) => {
                        if (res.data && res.data.code == 0) {
                            this.labelName = "";
                            this.addLableVisible = false;
                            this.getLabelList(() => {}, this.activeIndex);
                            this.$emit("getTagList");
                            this.$message.success(this.$t('staffManage.labelSuccess'));
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
            } else {
                let url =
                    this.requestUrl.lable.editTagName + this.editLabelObj.id;
                axios
                    .put(url, this.labelName.trim(), {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        if (res.data && res.data.code == 0) {
                            this.labelName = "";
                            this.addLableVisible = false;
                            this.getLabelList(() => {}, this.activeIndex);
                            this.$emit("getTagList");
                            this.$message.success(this.$t('staffManage.updateLabelSuccess'));
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
            }
        },
        editLabel() {
            this.handleType = "edit";
            this.labelName = this.editLabelObj.name;
            this.addLableVisible = true;
        },
        handleCommand(command, item) {
            this.handleType = command;
            this.editLabelObj = item;
            console.log(this.editLabelObj);
            switch (command) {
                case "edit":
                    this.editLabel();
                    break;
                case "delete":
                    this.dialogVisible = true;
                    break;
            }
        },
        deleteLabel() {
            let url = this.requestUrl.lable.deleteTag + this.editLabelObj.id;
            axios.delete(url).then((res) => {
                console.log(res.data);
                if (res.data && res.data.code == 0) {
                    this.addLableVisible = false;
                    this.dialogVisible = false;

                    if (this.editLabelObj.id !== this.activeItem.id) {
                        this.getLabelList(() => {}, this.activeIndex);
                    } else {
                        this.getLabelList(() => {
                            this.searchLable();
                        });
                    }
                    this.$emit("getTagList");
                    this.$message.success(this.$t('staffManage.deleteLabelSuccess'));
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },

        handleSelectionChange(value) {
            this.staffChecked = value;
            let orderIds = [];
            for (let i = 0; i < value.length; i++) {
                orderIds.push(value[i].itemId);
            }
            this.staffCheckedIds = orderIds;
        },
        //搜索出的员工分页加载
        getAllMemberList(id, currentPage) {
            console.log(id, currentPage);
        },
        searchCurrentChange(value) {
            console.log(value);
            this.searchLable("", "", value);
        },
    },
    mounted() {
        this.getLabelList(() => {
            this.searchLable();
        });
    },
};
</script>

<style lang="less" scoped>
.mult-dept {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.add-label-form-cell {
    display: flex;
    flex-direction: column;
    .add-label-form-cell-name {
        margin-bottom: 10px;
        em {
            color: red;
        }
    }
}
.label-manage {
    height: calc(100vh - 165px);
    background-color: white;
    border-top: solid 3px #e0e6f7;
    margin-top: -2px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    .label-manage-labellist {
        width: 306px;
        flex: none;
        border-right: solid 1px #e0e6f7;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .label-manage-labellist-content {
            flex: auto;
            overflow-y: auto;
            overflow-x: hidden;
            .lmll-cell {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 34px;
                line-height: 34px;
                font-size: 13px;
                cursor: pointer;
                .lmll-cell-icon {
                    margin: 3px 6px 0 12px;
                }
                .lmll-cell-name {
                    flex: auto;
                    white-space: nowrap; /* 防止换行 */
                    overflow: hidden; /* 隐藏溢出的部分 */
                    text-overflow: ellipsis; /* 显示省略号 */
                }
                .lmll-cell-num {
                    height: 20px;
                    line-height: 20px;
                    padding: 0 6px;
                    border-radius: 10px;
                    background: #f6f8fd;
                    color: #a9b3c6;
                    font-size: 12px;
                    margin-right: 6px;
                }
                .lmll-cell-handle {
                    // display: none;
                    color: #366aff;
                }
            }
            .lmll-cell-other:hover {
                background-color: #f6f8fd;
                .lmll-cell-handle {
                    display: inline-block;
                    color: #366aff;
                }
            }
            .lmll-cell-active {
                background-color: #366aff;
                .lmll-cell-name {
                    color: white;
                }
                .lmll-cell-num {
                    background-color: #6698ff;
                    color: white;
                }
                .lmll-cell-handle {
                    display: inline-block;
                    color: white;
                }
            }
        }
        .label-manage-labellist-add {
            margin: 12px auto;
            width: 129px;
            height: 32px;
            line-height: 30px;
            background: #ffffff;
            border-radius: 18px;
            border: 1px solid #a1b9ff;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            .lmla {
                width: 80px;
                color: #366aff;
                font-weight: 700;
                .lmla-icon {
                }
                .lmla-text {
                }
            }
        }
    }
    .label-manage-orglist {
        flex: auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-left: solid 1px #e0e6f7;
        /deep/.el-table__fixed-right {
            height: auto !important;
            bottom: 16px !important;
        }
        .staff-table,
        .staff-table-search {
            // width: calc(100vw - 680px);
            overflow-y: hidden;
            flex: auto;
            /deep/ td .cell {
                text-align: left;
            }
            .member_tags {
                display: flex;
                flex-wrap: wrap;
                .el-dropdown-link {
                    cursor: pointer;
                }
                .member_tags_content {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .member_tags_label {
                display: flex;
                flex: none;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 4px;
                background: #e0e8ff;
                color: #366aff;
                margin-right: 6px;
                margin-bottom: 4px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                white-space: nowrap;
                display: inline-block;
            }
            // .cell > div {
            //     text-align: left !important;
            // }
            .user-dept-box {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .userName {
                    ww-open-data {
                        font-size: 14px;
                        color: #000;
                    }
                }
                .dept-box {
                    line-height: 18px;
                    display: inline-block;
                }
                .dept-boxs {
                    display: block;
                }
            }
            /deep/ .sl-name-pre {
                height: 30px;
                line-height: 30px;
                width: 30px;
                min-width: 30px;
                margin-right: 6px;
                text-align: center;
                display: inline-block;
                background-color: #366aff;
                border-radius: 15px;
                color: white;
            }
        }
        .staff-table-search {
            width: 100%;
            overflow-y: hidden;
            flex: auto;
            // .cell {
            //     text-align: left !important;
            // }
            // .cell > div {
            //     text-align: left !important;
            // }
            /deep/ .sl-name-pre {
                height: 30px;
                line-height: 30px;
                width: 30px;
                margin-right: 6px;
                text-align: center;
                display: inline-block;
                background-color: #366aff;
                border-radius: 15px;
                color: white;
            }
        }
        /deep/ .activate-status {
            text-align: left;
            .cell {
                text-align: left;
            }
        }

        .pagination {
            // flex: none;
            height: 50px;
        }
    }
}
</style>
