<template>
    <div class="set-system">
         <popup @closeEvent="closeEvent" v-if="setSystemPopup">
           <div slot="popup-name" class="popup-name">{{isBatchSystem ? $t('setSystem.batch') : ''}}{{$t('setSystem.setSysAccTitle')}}</div>
              <div slot="popup-tip">{{$t('setSystem.setSysAccTip')}}</div>
            <div slot="popup-con">
                <el-form ref="ruleForm" label-position="top" label-width="80px" class="demo-ruleForm">
					<el-form-item class="batch-edit" :label="type === 'member' && isBatchSystem ? $t('setSystem.selectedMembers') : $t('setSystem.selectedDepartment')">
                        <div class="staff-input" v-if="isBatchSystem" >
                            <!-- <span class="placeholder-text" v-if="checkedDeptPopup.length === 0">请选择</span> -->
                            <div class="client-value">
                                <div v-for="item in checkedDeptPopup" :key="item.id" class="item">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="staff-input"  v-else>
                            <div class="client-value">
                                <div v-for="item in checkedDeptPopup" :key="item.id" class="item">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
					</el-form-item>
                    <el-form-item label="设置系统权限">
                         <span slot="label">{{$t('setSystem.setSystemPermissions')}}<span class="red">*</span></span>
                        <el-checkbox v-model="botAuth" :label="$t('setSystem.botPermission')" @change="botAuthChange"  :disabled="botAuthDisabled"></el-checkbox>
                        <el-checkbox v-model="workOrderAuth" :label="$t('setSystem.workOrderPermission')" :disabled="workOrderAuthDisabled" ></el-checkbox>
                        <el-checkbox v-model="authFollowDepart" :label="$t('setSystem.followDepart')" @change="authFollowDepartChange" v-if="type === 'member' && isBatchSystem"></el-checkbox>
					</el-form-item>
                    <el-form-item >
                       <div class="bot-num">
                           <div class="icon">{{ $t('setSystem.capital') }}</div>
                           <div class="type">{{ $t('setSystem.numberOfConsultationBot') }}</div>
                           <span>{{totalUseBotPeopleNum === -1 ? $t('setSystem.unrestricted') : totalUseBotPeopleNum + $t('setSystem.person')}}</span>
                       </div>
                       <div class="choose-num">
                           <div class="icon choose">{{$t('setSystem.choose')}}</div>
                           <div class="type">{{$t('setSystem.selectedNumberOfPeople')}}</div>
                           <span>{{existUseBotPeopleNum}}{{$t('setSystem.person')}}</span>
                           <template v-if="existUseBotPeopleNum > totalUseBotPeopleNum && totalUseBotPeopleNum !== -1">
                              
                                <div class="go-beyond" v-if="promoteSurplusDay < 0">{{$t('setSystem.exceeded')}}</div>
                           </template>
                       </div>
					</el-form-item>
                </el-form>
				
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveSystem" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
export default {
    components:{
		Popup,
	},
    props:{
		setSystemPopup:{
			default:false,
			type:Boolean
		},
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
        isBatchSystem:{
			default:false,
			type:Boolean
		},
        activeTreeDept:{
             type:Object,
            default(){
                return {}
            }
        },
        checkedTreeItem:{
            type:Object,
            default(){
                return{}
            }
        },
        type:{
            type:String,
            default:'dept'
        },
        checkedList:{
            type:Array,
            default(){
                return []
            }
        },
        promoteSurplusDay:{
            type:Number,
            default:-1
        }
            
       
	},
    data(){
        return {
            checkedDeptPopup:[],
            botAuth:false,
            workOrderAuth:false,
            existUseBotPeopleNum:0,
            totalUseBotPeopleNum:0,
            authFollowDepart:true,
            botAuthDisabled:true,
			workOrderAuthDisabled:true,
            promoteSurplusDay:-1
        }
    },
    watch:{
         checkedList:{
            handler(n){
                this.checkedDeptPopup = [...[],...n]
                console.log(this.checkedDeptPopup,'勾选的数据');
            },
            deep:true
        },
        setSystemPopup(n,o){
            if(n){
                this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
                if(!this.isBatchSystem){
                    this.botAuthDisabled = false;
                    this.workOrderAuthDisabled = false;
                    this.botAuth = this.checkedTreeItem.botAuth ? this.checkedTreeItem.botAuth : false;
                    this.workOrderAuth = this.checkedTreeItem.workOrderAuth ? this.checkedTreeItem.workOrderAuth : false;
                } else {
                    if(this.type === 'member'){
                        this.botAuthDisabled = true;
                        this.workOrderAuthDisabled = true;
                        this.botAuth = false;
                        this.workOrderAuth = false;
                        this.authFollowDepart = true;
                    } else {
                        this.botAuthDisabled = false;
                        this.workOrderAuthDisabled = false;
                        this.botAuth = true;
                        this.workOrderAuth = false;
                        this.authFollowDepart = false;
                    }
                }
                 this.queryPeopleAuthNum(); 
            }
        },
        activeTreeDept:{
            handler(n){
                if(n && n.id){
                    this.checkedDeptPopup = [];
                    this.checkedDeptPopup.push(n)
                }
            },
            deep:true
        },
       
        
        

    },
    methods:{
        // 跟随部门true:工单/机器人 禁用并且清除选中
		authFollowDepartChange(){
			if(this.authFollowDepart){
				this.botAuth = false;
				this.workOrderAuth = false;
				this.botAuthDisabled = true;
				this.workOrderAuthDisabled = true;
			} else {
				this.botAuthDisabled = false;
				this.workOrderAuthDisabled = false;
			}
		},
        botAuthChange(){
           this.queryPeopleAuthNum(); 
        },
        // 查询主体下可咨询机器人的总数及已有可咨询机器人人数
        queryPeopleAuthNum(){
            let  type = 0,ids = [];
            // 0部门,1员工,2上下游部门,3上下游员工
            if(this.isBatchSystem){
                type = this.type === 'member' ? 3 : 2;
                this.checkedDeptPopup.forEach(item => {
                    ids.push(item.id)
                })
            } else {
                type = 2;
                ids = [this.checkedTreeItem.id] 
            }
            console.log(ids,'1111111111111111111');
            this.$http.post(this.requestUrl.upAndDown.queryPeopleAuthNum + '?type=' + type + '&authBot=' + this.botAuth,ids)
                .then((res) => {
                    if(res.data.code == 0){
                        this.existUseBotPeopleNum = res.data.data.existUseBotPeopleNum;
                        this.totalUseBotPeopleNum = res.data.data.totalUseBotPeopleNum;
                    } else {
                        this.$message.error(res.data.message)
                    }
                });
        },
        closeEvent(){
            this.$emit('update:setSystemPopup',false);
        },
        resetSForm(){
            this.botAuth = true;
            this.workOrderAuth = false;
            this.checkedDeptPopup = [];
        },
        saveSystem(){
            let flag = true;
            if(this.type === 'member' && this.isBatchSystem){
                if(!this.botAuth && !this.workOrderAuth && !this.authFollowDepart){
                    flag = false;
                    this.$message.error(this.$t('setSystem.choosePermission'))
                    return false;
                }
            } else {
                if(!this.botAuth && !this.workOrderAuth){
                    flag = false;
                    this.$message.error(this.$t('setSystem.choosePermission'))
                    return false;
                }
            }
            if(!flag)return;
            let deptList = [],memberIds=[];
            if(this.type === 'member' && this.isBatchSystem){
                this.checkedDeptPopup.forEach(item => {
                    memberIds.push(item.id);
                })
                this.$http.put(this.requestUrl.upAndDown.updateUpAndDownMemberByIds,{
                    memberIds: memberIds,
                    upAndDownMember:{
                    oldOrganizationIds: null,
                    organizationIds: [],
                    staffIn: null,
                    status: null,
                    botAuth: this.botAuth,
                    workOrderAuth: this.workOrderAuth,
                    authFollowDepart: this.authFollowDepart,
                    }
                })
                    .then((res) => {
                        if(res.data.code == 0){
                            this.resetSForm();
                            this.$message.success(this.isBatchSystem ? this.$t('setSystem.batchSetSuccess') : this.$t('setSystem.setSuccess'))
                            this.$emit('setSystemSuccess');
                        } else {
                            this.$message.error(res.data.message)
                        }
                });
            } else {
                this.checkedDeptPopup.forEach(item => {
                    deptList.push(item.id);
                })
                this.$http.post(this.requestUrl.upAndDown.sysPermissionsSet,{
                    departmentId: deptList,
                    memberId: [],
                    type: 2,
                    botAuth: this.botAuth,
                    workOrderAuth: this.workOrderAuth,
                    authFollowDepart:this.type === 'member' && this.isBatchSystem ? this.authFollowDepart : false
                })
                    .then((res) => {
                        if(res.data.code == 0){
                            this.resetSForm();
                            this.$message.success(this.isBatchSystem ? this.$t('setSystem.batchSetSuccess') : this.$t('setSystem.setSuccess'))
                            this.$emit('setSystemSuccess');
                        } else {
                            this.$message.error(res.data.message)
                        }
                    });
            } 
        },
    }
}
</script>

<style scoped lang="less">
.set-system{
    ::v-deep .el-form{
        .el-form-item{
            display: flex;
            flex-direction: column;
        }
        .el-form-item__label{
			line-height: 20px;
            float: left !important;
			&.after{
				clear: both;
			}
		}
        .red {
			color: red;
			margin-left: 6px;
		}
        .staff-input{
            // height: 36px;
            width:470px;
            border-radius: 5px;
            border:1px solid #E0E6F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            .placeholder-text{
                padding-left: 15px;
                color: #A9B3C6;
            }
            .client-value{
                padding: 5px;
                color: #606266;
                display: flex;
                // justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                line-height: 30px;
                width: 100%;
                .item{
                    border:1px solid hsl(240, 5%, 92%);
                    height: 24px;
                    line-height: 24px;
                    padding: 0 8px;
                    line-height: 22px;
                    position: relative;
                    background-color: #ECF1FF;
                    color: #366AFF;
                    border-radius: 3px;
                    font-size: 12px;
                    margin: 2px 0 2px 6px;
                    .el-icon-close{
                        display: inline-block;
                        border-radius: 50%;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        font-size: 12px;
                        height: 14px;
                        width: 14px;
                        line-height: 14px;
                        background-color: #c0c4cc;
                        color: #909399;
                        cursor: pointer;
                    }
                }
                

            }
            
        }
        .bot-num{
            margin-bottom: 30px;
        }
        .bot-num,.choose-num{
            height: 21px;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            color: #616161;
            .icon{
                width: 21px;
                height: 21px;
                background: #09DDD5;
                border-radius: 50%;
                text-align: center;
                line-height: 21px;
                color: #fff;
                
                &.choose{
                    background-color: #87A6FF;
                }
            }
            .type{
                margin: 0 15px 0 10px;
                width: 122px;
                text-align: left;
                color: #A9B3C6;
            }
            .go-beyond{
                // width: 92px;
                padding: 0 6px;
                height: 20px;
                font-size: 12px;
                background: #FFEBEC;
                border-radius: 3px;
                color: #FE5965;
                text-align: center;
                line-height: 20px;
                margin-left: 5px;
            }

        }
    }
  
}
</style>