var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-node-list"},[_vm._l((JSON.parse(_vm.list)),function(childNodeItem,childNodeIndex){return _c('div',{key:childNodeIndex,class:[
            'node-node',
            childNodeItem.children.length == 1
                ? 'node-node-border-l-empty'
                : 'node-node-border-l',
        ]},[(childNodeItem.children.length == 1)?_c('div',{staticClass:"intent-node-node-pre-line"}):_vm._e(),(childNodeItem.isLastAndHasChi)?_c('div',{staticClass:"intent-node-node-pre-line-2"}):_vm._e(),_c('div',{staticClass:"node-node-inner-top"},[_c('div',{class:[
                    'node-pre-i',
                    childNodeIndex + 1 != JSON.parse(_vm.list).length
                        ? 'node-pre-i-border-left'
                        : 'node-pre-i-border-left-none',
                ]},[_c('div',{staticClass:"node-pre-i-inner"})]),_c('div',{staticClass:"node-node-name-inner",on:{"click":function($event){return _vm.configAction(
                        _vm.intentId,
                        childNodeItem.id,
                        childNodeItem.name
                    )}}},[_c('span',{staticClass:"node-name-c"},[_vm._v(_vm._s(_vm.$t('trainList.questionList.node')))]),_c('div',{staticClass:"name-text csp"},[_vm._v(" "+_vm._s(childNodeItem.name)+" ")])]),_c('div',{staticClass:"node-node-inner-handle"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                        !childNodeItem.isAddEditing &&
                        ((_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !_vm.isOtherSkill) ||
                            (_vm.isOtherSkill &&
                                _vm.RP_Visible('OPEN_PROCESS_OTHER')))
                    ),expression:"\n                        !childNodeItem.isAddEditing &&\n                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                            !isOtherSkill) ||\n                            (isOtherSkill &&\n                                RP_Visible('OPEN_PROCESS_OTHER')))\n                    "}],staticClass:"first-node-handle-cell add-text",on:{"click":function($event){return _vm.addNodeHandle(childNodeItem.id)}}},[_c('el-tooltip',{attrs:{"content":_vm.$t('flowComponent.addChildNode'),"effect":"light","placement":"top"}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                        !(
                            (_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !_vm.isOtherSkill) ||
                            (_vm.isOtherSkill &&
                                _vm.RP_Visible('OPEN_PROCESS_OTHER'))
                        )
                    ),expression:"\n                        !(\n                            (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                                !isOtherSkill) ||\n                            (isOtherSkill &&\n                                RP_Visible('OPEN_PROCESS_OTHER'))\n                        )\n                    "}],staticClass:"first-node-handle-cell add-text-forb"},[_c('i',{staticClass:"el-icon-plus"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(childNodeItem.isAddEditing),expression:"childNodeItem.isAddEditing"}],staticClass:"add-input-last"},[_c('el-input',{ref:'add_input_' + childNodeItem.id,refInFor:true,staticStyle:{"border":"none"},attrs:{"maxlength":"20","size":"small","placeholder":"请输入子节点名称"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmAdd(childNodeItem.id)}},model:{value:(_vm.nodeNameValue),callback:function ($$v) {_vm.nodeNameValue=$$v},expression:"nodeNameValue"}}),_c('span',{staticClass:"el-icon-success add-icon",on:{"click":function($event){return _vm.confirmAdd(childNodeItem.id)}}}),_c('span',{staticClass:"el-icon-error delete-icon",on:{"click":function($event){return _vm.concelAddNodeHandle(childNodeItem.id)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        ((_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !_vm.isOtherSkill) ||
                            (_vm.isOtherSkill &&
                                _vm.RP_Visible('OPEN_PROCESS_OTHER'))) &&
                        _vm.isPart != 1
                    ),expression:"\n                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                            !isOtherSkill) ||\n                            (isOtherSkill &&\n                                RP_Visible('OPEN_PROCESS_OTHER'))) &&\n                        isPart != 1\n                    "}],staticClass:"first-node-handle-cell first-node-handle-cell-h",on:{"click":function($event){return _vm.deleteNodeHandle(
                            childNodeItem.id,
                            childNodeItem.name
                        )}}},[_c('el-tooltip',{attrs:{"content":"删除","effect":"light","placement":"top"}},[_c('i',{staticClass:"el-icon-delete delete-icon-node"})])],1),_c('span',{staticClass:"first-node-handle-cell-h first-node-handle-cell add-text",on:{"click":function($event){return _vm.getNodeListByIntent(childNodeItem.id)}}},[_c('el-tooltip',{attrs:{"content":"变更节点关系","effect":"light","placement":"top"}},[_c('i',{staticClass:"el-icon-sort"})])],1)])]),(_vm.hasChildren(childNodeItem.children))?_c('node-item',{attrs:{"list":JSON.stringify(childNodeItem.children),"intentId":_vm.intentId,"skillId":_vm.skillId,"indexArr":_vm.indexArr,"nodeTree":_vm.nodeTree,"activeBot":_vm.activeBot,"isOtherSkill":_vm.isOtherSkill}}):_vm._e()],1)}),(_vm.changeNodeDialog)?_c('popup',{on:{"closeEvent":function($event){_vm.changeNodeDialog = false}}},[_c('div',{staticClass:"popup-name",attrs:{"slot":"popup-name"},slot:"popup-name"},[_vm._v("变更节点关系")]),_c('div',{attrs:{"slot":"popup-con"},slot:"popup-con"},[_c('div',{staticClass:"sa-answer-radio-cell"},[_c('div',{staticClass:"select-cell"},[_c('span',{staticClass:"select-cell-title"},[_vm._v("请选择父节点")]),_c('el-select',{attrs:{"popper-append-to-body":false,"filterable":"","size":"small","placeholder":"请输入选择名称"},model:{value:(_vm.activeMoveToNode),callback:function ($$v) {_vm.activeMoveToNode=$$v},expression:"activeMoveToNode"}},_vm._l((_vm.moveToOptions),function(item){return _c('el-option',{key:item.id,attrs:{"popper-class":'pop_my' + item.classPop + item.parentNum,"label":item.name,"value":item.id}})}),1)],1)])]),_c('div',{attrs:{"slot":"dialog-footer"},slot:"dialog-footer"},[_c('el-button',{attrs:{"size":"small","plain":""},on:{"click":function($event){_vm.changeNodeDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.confirmchangeNoderelation}},[_vm._v("保 存")])],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }