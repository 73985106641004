<!-- 新版本-->
<template>
    <div id="upDownManage" v-cloak >
        <SideNav></SideNav>
        <div class=" gr-content-container">
           <top-header>
				<div slot="setup-header-left" class="setup-header-left">
					<span class="setup-header-left-icon">
						<i class="iconfont guoran-a-14-03"></i
					></span>
					<span>{{$t('common.enterpriseEdition')}} - </span>
                    <span>{{$t('upDownManage.title')}}</span>
				</div>
		   </top-header>
            <div class="content">
				<div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
					<div class=" content-section"  v-loading="loading">
						<div class="upDownManage-content-header" v-if="(treeData.length > 0 && activeType === 'member') ||activeType === 'dept'">
							<div class="left-switch">
								<div @click="switchType('member')" :class="[activeType === 'member' ? 'active' : '']"> <i class="iconfont guoran-tongyichicun-geren"></i> {{$t('upDownManage.member')}}</div>
								<div @click="switchType('dept')" :class="[activeType === 'dept' ? 'active' : '']">  <i class="iconfont guoran-tongyichicun-guanlibumen" ></i> {{$t('upDownManage.department')}}</div>
							</div>
							<div class="upDownManage-header-right"  v-if="(treeData.length > 0 && activeType === 'member') ||activeType === 'dept'">
								<div class="work-order-header-l">
									<el-tooltip class="item" effect="dark" :content="$t('upDownManage.edit')" placement="top-start" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_UPDATE') !== -1 && activeType === 'member'">
										<span @click="batchEditUser"><i class="iconfont guoran-piliangbianjiiocn"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('upDownManage.delete')" placement="top-start" v-if="(activeType === 'member' && rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_DELETE') !== -1) || (activeType === 'dept' && rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_DELETE') !== -1)">
										<span @click="deleteUser"><i class="iconfont guoran-a-18-13"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('upDownManage.export')" placement="top-start" v-if="activeType === 'member'">
										<span @click="exportFile"><i class="iconfont guoran-a-18-19"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('upDownManage.import')" placement="top-start" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_IMPORT') !== -1 && activeType === 'member'">
										<span @click="importFile"><i class="iconfont guoran-a-18-20"></i></span>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" :content="$t('upDownManage.screen')" placement="top-start" v-if="activeType === 'member'">
										<span @click="searchClick"><i class="iconfont guoran-gaojishaixuan"></i></span>
									</el-tooltip>
									<el-input v-model="keyword" clearable :placeholder="activeType === 'member' ? $t('upDownManage.placeholderName') : $t('upDownManage.placeholderOrganizational')" prefix-icon="el-icon-search" @keyup.enter.native="searchFn" size="small" style="width: 70%" @change="changeSearch"></el-input>
								</div>
								<seniorSearch
									ref="seniorSearch"
									:checkedTreeItem="checkedTreeItem"
									@screenCondition="screenCondition"
								></seniorSearch>
								<div class="work-order-header-r">
									<el-button  size="small" plain icon="iconfont guoran-shezhiquanxian" @click="batchSetSystem">{{$t('upDownManage.batchSetSystemPermissions')}}</el-button>
									<el-button  size="small" type="primary"  icon="iconfont guoran-a-16-13" @click="addDeptSystem" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1 && activeType === 'dept'">{{$t('upDownManage.addDept')}}</el-button>
									<el-button  size="small" type="primary"  icon="iconfont guoran-a-16-13" @click="addMember" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_MEMBER_ADD') !== -1 && activeType === 'member'">{{$t('upDownManage.addMember')}}</el-button>
								</div>
								<div class="search-tag" v-if="dynamicTags.length > 0">
									<div class="showTag">
										<el-tag
												closable
												v-for="(tag,tagIndex) in dynamicTags"
												:key="tagIndex"
												:disable-transitions="false"
												@close="handleClose(tag,tagIndex)"
												style="margin-right: 8px;"
												size="small"
										>
											<span>{{tag.name}}:
												<template v-if=" Array.isArray(tag.value)">
													<span v-for="(item,index) in tag.value" :key="index">
														<span>{{item.name}}</span>
														<span v-if="index < tag.value.length -1">,</span>
													</span>
												</template>
												<span v-else>
													<span>{{tag.value}}</span>
												</span>
											</span>
										</el-tag>
										<el-tooltip class="item" effect="dark" :content="$t('upDownManage.clearAll')" placement="top-start">
											<span class="delete-all" @click="deleteAllTag('deleteAllTag')">
												<i class="iconfont guoran-a-16_01"></i>
											</span>
										</el-tooltip>

									</div>
								</div>
							</div>
						</div>
						<div :class="['upDownManage-content',activeType ==='dept' ? 'dept' : '']">
							<div class="left-tree" v-if="activeType === 'member'">
								<div class="left-tree-box">
									<div class="default-tip" v-if="!treeData || treeData.length === 0">
										<img src="../../../assets/images/no-data.png">
										<el-button type="primary" @click="addDept" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">添加上下游</el-button>
									</div>
									<el-tree
										key="selectOrganizationTree"
										id="selectOrganizationTree"
										v-else
										:data="treeData"
										highlight-current
										node-key="id"
										:current-node-key="currentId"
										:default-expanded-keys="defaultCheckedKeys"
										:default-checked-keys="defaultCheckedKeys"
										@node-click="handleNodeClick"
										ref="elTree"
										>
										<span class="el-tree-title" slot-scope="{ node, data }">
											<span>
												<span class="el-tree-title-word" >
												<i
													style="color: #a1b9ff;margin-right:5px"
													class="
													iconfont
													guoran-wenjianjia-zhankaizhuangtai--folderOpen
													"
												></i>
												</span>
												<span  class="el-tree-title-word-name" >{{ data.name }}</span>
											</span>
											<span >
												<span class="el-tree-title-num" v-if="data.peopleLimitType === 0 ">{{data.peopleNum ? data.peopleNum : 0}}/{{data.peopleLimit}}</span>
												<el-dropdown @command="handleCommand($event,node,data)" trigger="click">
													<span class="el-dropdown-link">
														<i class="arsenal_icon arsenalgengduo"></i>
													</span>
													<el-dropdown-menu slot="dropdown">
														<el-dropdown-item 
															:command="'addSameDept'" 
															:disabled="source === 5 && node.level === 1"
															v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">
															{{$t('upDownManage.addSameDepart')}}</el-dropdown-item>
														<el-dropdown-item :command="'setLeader'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">{{$t('upDownManage.setUpResponsiblePerson')}}</el-dropdown-item>
														<el-dropdown-item :command="'setSystem'">{{$t('upDownManage.setSystemPermissions')}}</el-dropdown-item>
														<el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">{{$t('upDownManage.addChildrenDepart')}}</el-dropdown-item>
														<el-dropdown-item :command="'up'" :disabled="data.orderNum < 2">{{$t('upDownManage.up')}}</el-dropdown-item>
														<el-dropdown-item :command="'down'" :disabled="data.orderNum === node.parent.childNodes.length">{{$t('upDownManage.down')}}</el-dropdown-item>
														<el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">{{$t('upDownManage.edit')}}</el-dropdown-item>
														<el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_DELETE') !== -1">{{$t('upDownManage.delete')}}</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</span>
										</span>
									</el-tree>
								
								</div>
								<el-button icon="guoran-tongyichicun-guanlibumen iconfont" @click="switchType('dept')" v-if="treeData.length > 0">{{$t('upDownManage.managementDepartment')}}</el-button>
							</div>
							<div class="right-table" v-if="activeType === 'member'">
								<div class="default-tip" v-if="tableData.length === 0">
									<img src="../../../assets/images/no-data.png">
									<span class="default-tip-text">{{treeData.length === 0 ? $t('upDownManage.addUpDown') : $t('common.emptyData')}}</span>
								</div>
								<div v-show="tableData.length > 0" class="tabel-box-div">
									<div class="tabel-box">
										<el-table
										   height="calc(100vh - 210px)"
											v-show="tableData.length > 0"
											ref="multipleTable"
											:header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
											:data="tableData"
											style="width: 100%"
											row-key="id"
											:empty-text='$t("common.emptyData")'
											@row-click="getDetails"
											@selection-change="handleSelectionChange">
											<el-table-column
												:reserve-selection="true"
												type="selection"
												align="left"
												width="55">
											</el-table-column>
											<el-table-column
												prop="name"
												align="left"
												min-width="150px"
												:label="$t('upDownManage.seniorSearch.name')">
											</el-table-column>
											<el-table-column
												prop="telephone"
												align="left"
												min-width="150px"
												:label="$t('upDownManage.seniorSearch.telephone')">
											</el-table-column>
											<el-table-column
												prop="organizationName"
												align="left"
												min-width="200px"
												class-name="organizationName"
												:label="$t('upDownManage.seniorSearch.organizationName')">
												<template slot-scope="scope">
													<el-tooltip class="item" effect="dark" :content="scope.row.organizationName" placement="bottom-end">
														<span>{{scope.row.organizationName}}</span>
													</el-tooltip>
												</template>
											</el-table-column>
											<el-table-column
												prop="activateStatus"
												align="left"
												min-width="300px"
												:label="$t('upDownManage.seniorSearch.botActivationStatus')">
												<template slot-scope="scope">
													<!-- 0 未激活 1 激活 2激活失败 -->
													<span v-if="scope.row.activateStatus === 0">{{$t('upDownManage.seniorSearch.notActive')}}</span>
													<span v-else-if="scope.row.activateStatus === 1">{{$t('upDownManage.seniorSearch.activationSuccess')}}</span>
													<span v-else>{{$t('upDownManage.seniorSearch.activationFail')}}</span>
													<span class="go-beyond" v-if="promoteSurplusDay < 0 && scope.row.botAuthBeyond && scope.row.activateStatus === 2">{{$t('upDownManage.unactivable')}}</span>
												</template>
											</el-table-column>
											<el-table-column
												prop="email"
												align="left"
												min-width="180px"
												:label="$t('upDownManage.email')">
												<template slot-scope="scope">
													<div>{{scope.row.email ? scope.row.email : '--'}}</div>
												</template>
											</el-table-column>
											<el-table-column
												prop="birthdayStr"
												align="left"
												min-width="150px"
												:label="$t('upDownManage.seniorSearch.birthday')">
												<template slot-scope="scope">
													<div>{{scope.row.birthdayStr ? scope.row.birthdayStr : '--'}}</div>
												</template>
											</el-table-column>
											<el-table-column
												prop="staffInStr"
												align="left"
												min-width="150px"
												:label="$t('upDownManage.seniorSearch.staffIn')">
												<template slot-scope="scope">
													<div>{{scope.row.staffInStr ? scope.row.staffInStr : '--'}}</div>
												</template>
											</el-table-column>
											<el-table-column
												class-name="status-table-item"
												prop="status"
												align="left"
												width="100"										
												:label="$t('upDownManage.seniorSearch.status')">
												<template slot-scope="scope">
													<!-- true 启用，false禁用 -->
													<div :class="['status-btn' ,scope.row.status ? 'used' : 'disabled']">{{scope.row.status ? '使用中' : '已禁用'}}</div>
												</template>
											</el-table-column>
											<el-table-column
												class-name="system-table-item"
												prop="status"
												align="left"
												min-width="260px"
												:label="$t('upDownManage.systemPermissions')">
												<template slot-scope="scope">
													<div v-if="scope.row.botAuth">{{$t('upDownManage.consultationBot')}}</div>
													<!-- <div class="go-beyond" v-if="promoteSurplusDay < 0 && scope.row.botAuthBeyond">超出人数，不可咨询</div> -->
													<div v-if="scope.row.botAuth && scope.row.workOrderAuth">,</div>
													<div v-if="scope.row.workOrderAuth">{{$t('upDownManage.useWorkOrder')}}</div>
												</template>
											</el-table-column>
										</el-table>
										<pagination 
											v-if="tableData.length > 0"
											:pageSize="pageSize" 
											:currentPage="currentPage" 
											:total="total"
											@handleSizeChange="handleSizeChange" 
											@currentChange="handleCurrentChange">
										</pagination>
										<div class="default-image" v-if="tableData.length === 0">
											<div class="default-tip">
												<img src="../../../assets/images/default.png">
												<span class="default-tip-text">{{$t('upDownManage.noRecord')}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>   
							<div class="upDownManage-dept-content" v-if="activeType ==='dept'">
								<div class="tabel-box">
									<el-table
										:indent="30"
										height="calc(100vh - 160px)"
										ref="deptTreeTable"
										:header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
										:data="treeData"
										style="width: 100%;margin-bottom: 20px;"
										row-key="id"
										:empty-text="$t('common.emptyData')"
										:tree-props="{children: 'children'}"
										@select="select" 
										@select-all="selectAll" 
										@selection-change="handleSelectionChange"
										>
										<el-table-column
											:reserve-selection="true"
											type="selection"
											align="center"
											width="55">
										</el-table-column>
										<el-table-column
											align="left"
											prop="name"
											:label="$t('upDownManage.dept')"
											show-overflow-tooltip
											class-name="dept-name-box">
											<template slot-scope="scope">
												<div class="dept-box-content">
													<div class="icon-box">
														<div class="icon-div">{{scope.row.name.slice(0,1)}}</div>
														<div class="text">{{scope.row.name}}</div>
													</div>
												</div>
											</template>
										</el-table-column>
										<el-table-column
											align="center"
											prop="peopleNum"
											:label="$t('upDownManage.deptNumber')">
										</el-table-column>
										<el-table-column
											align="left"
											class-name="dept-name-box"
											:label="$t('upDownManage.departmentHead')">
											<template slot-scope="scope">
												<div class="handler-box">
													<div class="ellipsis-box">
														<div :class="['icon-box','img-icon']" v-for="(item,index) in scope.row.principals" :key="item.principalId">
															<img :class="[index > 0 ? 'position' : '',index+1 ===  scope.row.principals.length ? 'last' : '']" :src="item.principalUserPhoto" alt="" v-if="item.principalUserPhoto && item.principalUserPhoto !== ''">
															<div class="icon-div" v-else :class="[index > 0 ? 'position' : '',index+1 ===  scope.row.principals.length ? 'last' : '']">{{item.principalName.slice(0,1)}}</div>
														</div>
														<div v-for="(item,index) in scope.row.principals" :key="item.principalId">{{item.principalName}} {{index+1 === scope.row.principals.length ? '' : ','}}</div>
													</div>
												</div>
											</template>
										</el-table-column>
										<el-table-column
												class-name="system-table-item"
												prop="status"
												align="left"
												:label="$t('upDownManage.systemPermissions')">
												<template slot-scope="scope">
													<div v-if="scope.row.botAuth">{{$t('upDownManage.consultationBot')}}</div>
													<!-- <div class="go-beyond" v-if="scope.row.peopleNum > scope.row.peopleLimit">超出人数，不可咨询</div> -->
													<div v-if="scope.row.botAuth && scope.row.workOrderAuth">,</div>
													<div v-if="scope.row.workOrderAuth">{{$t('upDownManage.useWorkOrder')}}</div>
												</template>
										</el-table-column>
										<el-table-column
											prop="status"
											width="100"
											align="left"
											label=" ">
											<template slot-scope="scope">
												<el-dropdown @command="handleCommand($event,'deptTable',scope.row)" trigger="click">
													<span class="el-dropdown-link">
														<i class="arsenal_icon arsenalgengduo"></i>
													</span>
													<el-dropdown-menu slot="dropdown">
														<el-dropdown-item 
															:command="'addSameDept'" 
															:disabled="source === 5 && scope.row.isFirstNode"
															v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">
                              {{$t('upDownManage.addSameDepart')}}</el-dropdown-item>
														<el-dropdown-item :command="'setLeader'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">{{$t('upDownManage.setUpResponsiblePerson')}}</el-dropdown-item>
														<el-dropdown-item :command="'setSystem'">{{$t('upDownManage.setSystemPermissions')}}</el-dropdown-item>
														<el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_ADD') !== -1">{{$t('upDownManage.addChildrenDepart')}}</el-dropdown-item>
														<el-dropdown-item :command="'up'" :disabled="scope.row.isTop" v-if="!isSearch">{{$t('upDownManage.up')}}</el-dropdown-item>
														<el-dropdown-item :command="'down'" :disabled="scope.row.isBottom" v-if="!isSearch">{{$t('upDownManage.down')}}</el-dropdown-item>
														<el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_UPDATE') !== -1">{{$t('upDownManage.edit')}}</el-dropdown-item>
														<el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_UPANDDOWN_DELETE') !== -1">{{$t('upDownManage.delete')}}</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</template>
										</el-table-column>
									</el-table>
								</div>
								
							</div>
						</div>
					</div>
				<!-- </div> -->
			</div>
   		</div>
		<addMember 
			:promoteSurplusDay="promoteSurplusDay"
			:treeData="treeData"
			:checkedTreeItem="checkedTreeItem"
			:rolePrivilegesList="rolePrivilegesList"
			:visible.sync="addMemberVisible" 
			:userDetail="userDetail" 
			:checkedUserList="checkedUserList"
			@addMemberSuccess="addMemberSuccess">
		</addMember>
		<addDept 
			:chooseUpDept="chooseUpDept"
			:activeType="activeType"
			:treeData="treeData"
			:source="source"
			:addDeptVisible.sync="addDeptVisible" 
			:type="addDeptType" 
			:checkedTreeItem="checkedTreeItem"
			:checkedTreeItemNode="checkedTreeItemNode"
			@addDeptSuccess="addDeptSuccess" 
			>
		</addDept>
		<importFile :importFileVisible.sync="importFileVisible" @uploadFileSuccess="uploadFileSuccess"></importFile>
		<setLeader 
			:setLeaderVisible.sync="setLeaderVisible" 
			:checkedTreeItem="checkedTreeItem"
			@setLeaderSuccess="setLeaderSuccess">
		</setLeader>
		<setSystem 
			:promoteSurplusDay="promoteSurplusDay"
			:checkedList="checkedList"
			:type="activeType"
			:checkedTreeItem="checkedTreeItem"
			:setSystemPopup.sync="setSystemPopup" 
			:treeData="treeData" 
			:isBatchSystem="isBatchSystem" 
			:activeTreeDept="activeTreeDept"
			@setSystemSuccess="setSystemSuccess"></setSystem>
			<delDeptHasMember
				:treeData="treeData" 
				:isBatchDel="isBatchDel"
				:delDeptHasMemberList="delDeptHasMemberList"
				:delDeptHasMemberPopup.sync="delDeptHasMemberPopup"
				@delDeptSuccess="delDeptSuccess"
			></delDeptHasMember>
	</div>
</template>
<script>
import TopHeader from "./../../../components/top-header.vue";
import SideNav from "./../../menu/SideNav.vue";
import pagination from "../../../components/pagination";
import addMember from "./components/addMember";
import addDept from './components/addDept.vue';
import importFile from './components/importFile.vue';
import seniorSearch from './components/seniorSearch.vue';
import setLeader from './components/setLeader.vue';
import setSystem from './components/setSystem.vue';
import delDeptHasMember from './components/delDeptHasMember.vue';
import ManangeNav from "./../../menu/ManageNave.vue";
import $ from 'jquery';
export default {
    name: "upDownManage",
    components: {
        // leftMenu,
        // leftSubMenu,
		pagination,
		addMember,
		addDept,
		importFile,
		seniorSearch,
		setLeader,
        SideNav,
		setSystem,
		delDeptHasMember,
		ManangeNav,
		TopHeader
    },
	data(){
		return{
			tableData:[], // 表格数据
			treeData: [], // tree结果数据
			pageSize:20,
			currentPage:1,
			total:null,
			addMemberVisible:false,
			addDeptVisible:false,
			importFileVisible:false,
			setLeaderVisible:false,
			addDeptType:'', // tree结构操作的类型
			checkedTreeItem:{}, // 选中的treeItem
			defaultCheckedKeys:[], // tree机构默认选中节点
			userDetail:{},
			checkedUserList:[],
			checkedTreeItemNode:{}, //tree结构node节点
			keyword:'',
			rolePrivilegesList:[],
			screenConditionObj:{}, // 高级检索的筛选条件
			currentId:null,
			source:null, // 5：负责人账号，
			dynamicTags:[],
			activeType:'member',
			isBatchSystem:false, // 是否是批量设置系统权限
			setSystemPopup:false,
			chooseUpDept:false,
			checkedList:[],
			loading:true,
			isBatchDel:false,
			delDeptHasMemberPopup:false,
			delDeptHasMemberList:[],
			isSearch:false,
			promoteSurplusDay:-1
		}
	},
	mounted(){
		this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
		this.loading = true;
		this.rolePrivilegesList = [];
		let list = JSON.parse(localStorage.getItem('rolePrivileges'));
		list.forEach(item=>{
			if(item.checked){
				this.rolePrivilegesList.push(item.id)
			}
		})
		this.getTree(true);
		this.source = JSON.parse(localStorage.getItem('userInfo')).source;
		console.log(JSON.parse(localStorage.getItem('userInfo')).source,'sourcesource');
	},
	methods: {
		switchType(type){
			this.activeType = type;
			this.keyword = '';
			if(this.activeType === 'dept'){
				this.treeData = this.judgeTree(this.treeData,this.treeData.length,true,this.treeData,{});
			} else {
				this.getTree(true);
			}
		},
		// 批量设置系统权限
		batchSetSystem(){
			this.activeTreeDept = {};
			this.isBatchSystem = true;
			if(this.activeType === 'member'){
				if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
					this.checkedList = [...[],...this.$refs.multipleTable.selection];
					this.setSystemPopup = true;
				} else {
					this.$message.warning(this.$t('upDownManage.selectSetData'))
				}
			} else {
				if(this.$refs.deptTreeTable.selection && this.$refs.deptTreeTable.selection.length > 0){
					this.checkedList = [...[],...this.$refs.deptTreeTable.selection];
					this.setSystemPopup = true;
				} else {
					this.$message.warning(this.$t('upDownManage.selectSetData'))
				}
			}	
		},
		addDeptSystem(){
			this.chooseUpDept = true;
			this.addDeptType = 'addDept';
			this.addDeptVisible = true;
		},
		// 处理树结构中的chuldren
		judgeTree(data,length,isFirstNode,copyList,parentObjCan) {
			let parentObjs = JSON.parse(JSON.stringify(parentObjCan))
			data.forEach((item,index) => {
				item.isTop = index === 0 ? true :false;
				item.isBottom = index === length-1 ? true :false;
				item.length = length;
				item.isFirstNode = isFirstNode;
				item.copyList = JSON.parse(JSON.stringify(copyList));
				// item.isSelect = false;
				if(isFirstNode){
					item.parentObj = {}; 
				} else {
					delete parentObjs.copyList;
					delete parentObjs.children;
					item.parentObj = JSON.parse(JSON.stringify(parentObjs)); 
				}
				if (item.children && item.children.length > 0) {
					this.judgeTree(item.children,item.children.length,false,item.children || [],item);
				} else {
					
				}
			});
			return data
		},
		getTreeSearch(){
			this.$http.get(this.requestUrl.upAndDown.getTreeDataSearch + '?input=' + this.keyword) 
				.then((res) => {
					if(res.data.code == 0){
						this.loading = false;
						this.treeData = res.data.data || [];
						if(this.treeData.length > 0){
							
							this.$nextTick(()=>{
								if(this.activeType === 'member'){
									this.currentId = this.checkedTreeItem.id;
									this.$refs.elTree.setCurrentKey(this.checkedTreeItem.id);
								}
							
							})
							this.currentPage = 1;
							if(this.activeType === 'dept'){
								this.treeData = this.judgeTree(this.treeData,this.treeData.length,true,this.treeData,{});
							}
							if(this.keyword === ''){
								this.isSearch = false;
							} else {
								this.isSearch = true;
							}
							this.$forceUpdate();
						}
					} else {
						this.$message.error(res.data.message)
					}		
				}); 
		},
		// 获取tree结构
		getTree(isReload){
			this.$http.get(this.requestUrl.upAndDown.getTreeData + '?input=' + this.keyword) 
				.then((res) => {
					if(res.data.code == 0){
						this.loading = false;
						this.treeData = res.data.data || [];
						if(this.treeData.length > 0){
							if(isReload){
								this.defaultCheckedKeys = [this.treeData[0].id];
								this.checkedTreeItem = JSON.parse(JSON.stringify(this.treeData[0]));
								this.$nextTick(()=>{
									setTimeout(()=>{
										if(this.activeType === 'member'){
											let dom = document.getElementById('selectOrganizationTree').firstChild;
											dom.className += " is-current";
										}
									},10)
								})
							} else {
								this.$nextTick(()=>{
									if(this.activeType === 'member'){
										this.currentId = this.checkedTreeItem.id;
										this.$refs.elTree.setCurrentKey(this.checkedTreeItem.id);
									}
								
								})
							}
							this.currentPage = 1;
							if(this.activeType === 'dept'){
								// this.treeData = this.judgeTree(this.treeData,this.treeData.length,true,this.treeData,{});
							}
							if(this.activeType === 'member'){
								this.screenConditionObj.input = this.keyword;
								this.getUserTable(this.screenConditionObj);
							}
							
							if(this.keyword === ''){
								this.isSearch = false;
							} else {
								this.isSearch = true;
							}
							this.$forceUpdate();
						} else {
							if(this.activeType === 'member'){
								this.screenConditionObj.input = this.keyword;
								this.tableData = [];
								// this.getUserTable(this.screenConditionObj);
							}
						}
					} else {
						this.$message.error(res.data.message)
					}		
				}); 
		},
		// 无组织架构时 添加部门
		addDept(){
			this.addDeptType = 'one';
			this.addDeptVisible = true;
			this.chooseUpDept = false;
		},
		// 添加组织架构成功时
		addDeptSuccess(isUpdate,isReload){
			this.addDeptVisible = false;
			this.chooseUpDept = false;
			this.addDeptType = '';
			if(this.activeType === 'dept'){
				if(this.keyword !== ''){
					this.getTreeSearch();
				} else {
					this.getTree(isReload && isReload === 'reload' ? true : false);
				}
			} else {
				if(isUpdate){
					this.getTree(isReload && isReload === 'reload' ? true : false);
				}
			}
			
		},
		// 添加/编辑 成员成功
		addMemberSuccess(type,isUpdate){
			if(!type || type !== 'contince'){
				this.addMemberVisible = false;
			}
			this.userDetail = {};
			this.$refs.multipleTable.clearSelection();
			// this.getTree(false);
			this.currentPage = 1;
		
			if(this.keyword !== ''){
				this.getTreeSearch();
			} else {
				this.getTree(false);
			}
			
		},
		uploadFileSuccess(){
			this.importFileVisible = false;
			this.$refs.multipleTable.clearSelection();
			this.getTree(true);
		},
		// 设置负责人成功
		setLeaderSuccess(isUpdate){
			this.setLeaderVisible = false;
			if(this.activeType === 'dept'){
				if(this.keyword !== ''){
					this.getTreeSearch();
				} else {
					this.getTree(false);
				}
			} else {
				this.getTree(false);
			}
		},
		// 设置权限成功
		setSystemSuccess(){
			this.setSystemPopup = false;
			if(this.activeType === 'dept'){
				this.$refs.deptTreeTable.clearSelection();
				if(this.keyword !== ''){
					this.getTreeSearch();
				} else {
					this.getTree(false);
				}
			} else {
				this.$refs.multipleTable.clearSelection();
			}
			this.getTree(false);
		},
		// 删除部门节点成功
		delDeptSuccess(){
			this.delDeptHasMemberPopup = false;
			if(this.activeType === 'dept'){
				this.$refs.deptTreeTable.clearSelection();
				if(this.keyword !== ''){
					this.getTreeSearch();
				} else {
					this.getTree(false);
				}
			} else {
				this.$refs.multipleTable.clearSelection();
				if(this.treeData.length === 0){
					
				} else {
					this.checkedTreeItem = this.treeData[0];
				}
				this.getTree(false);
			}
			this.getTree(false);
		},
		// 点击tree
		handleNodeClick(data,node,el) {
			this.dynamicTags = [];
			this.deleteAllTag();
			this.defaultCheckedKeys = [data.id];
			this.$refs.multipleTable.clearSelection();
			document.getElementById('selectOrganizationTree').firstChild.classList.remove('is-current')
			console.log(data,'data');
			console.log(node,'node');
			console.log(el,'el');
			this.checkedTreeItemNode = node;
			this.checkedTreeItem = JSON.parse(JSON.stringify(data));
			this.currentPage = 1;
			this.screenConditionObj.input = this.keyword;
			this.getUserTable(this.screenConditionObj);
		},
		// 点击下拉操作列表
		handleCommand(type,node,data){
			console.log(type);
			console.log(type,node,data);
			this.addDeptType = type;
			if(node === 'deptTable'){
				this.$refs.deptTreeTable.clearSelection();
				this.checkedTreeItem = JSON.parse(JSON.stringify(data));
				if (type === 'up' || type === 'down' ){
					let currentData = JSON.parse(JSON.stringify(data));
					let params = [],list = JSON.parse(JSON.stringify(data.copyList));
					list.forEach((item,index) => {
						if(currentData.id === item.id){
							if(type === 'up'){
								currentData.orderNum  = currentData.orderNum - 1;
								list[index-1].orderNum += 1;
								params = [...[currentData],...[list[index-1]]];
							} else {
								currentData.orderNum += 1;
								list[index+1].orderNum -= 1;
								params = [...[currentData],...[list[index+1]]];
							}
						}
					})
					console.log(params,type === 'up' ? this.$t('upDownManage.up') : this.$t('upDownManage.down'));
					this.$http.put(this.requestUrl.upAndDown.updateUpAndDownStreamOrderNum,params)
						.then((res) => {
							if(res.data.code == 0){
								this.$message.success(type === 'up' ? this.$t('upDownManage.upSuccess') : this.$t('upDownManage.downSuccess'))
								this.getTree(true);
							} else {
								this.$message.error(res.data.message)
							}
						});
				}
			} else {
				this.$refs.multipleTable.clearSelection();
				this.checkedTreeItemNode = node;
				this.checkedTreeItem = JSON.parse(JSON.stringify(data));
				
				let list = node.parent.childNodes;
				if (type === 'up' || type === 'down' ){
					let currentData = JSON.parse(JSON.stringify(data));
					let params = [];
					list.forEach((item,index) => {
						if(currentData.id === item.data.id){
							if(type === 'up'){
								currentData.orderNum  = currentData.orderNum - 1;
								list[index-1].data.orderNum += 1;
								params = [...[currentData],...[list[index-1].data]];
							} else {
								currentData.orderNum += 1;
								list[index+1].data.orderNum -= 1;
								params = [...[currentData],...[list[index+1].data]];
							}
						}
					})
					console.log(params,type === 'up' ? this.$t('upDownManage.up') : this.$t('upDownManage.down'));
					this.$http.put(this.requestUrl.upAndDown.updateUpAndDownStreamOrderNum,params)
						.then((res) => {
							if(res.data.code == 0){
								this.$message.success(type === 'up' ? this.$t('upDownManage.upSuccess') : this.$t('upDownManage.downSuccess'))
								this.getTree(true);
							} else {
								this.$message.error(res.data.message)
							}
						});
				} 
					
			}
			 if(type === 'setLeader'){
					this.setLeaderVisible = true;
				} else if(type === 'addSameDept' || type === 'addChildDept' || type === 'edit')  {
					this.addDeptVisible = true;
					this.chooseUpDept = false;
				} else if (type === 'del'){
					this.isBatchDel = false; 
					this.delDeptHasMemberList = [data];
					this.delDeptFn();
				} else if (type === 'setSystem'){
					this.activeTreeDept = data;
					this.isBatchSystem = false;
					this.setSystemPopup = true;
				}
		},
		// 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times); // 13位时间戳
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			let D = getBYT(date.getDate() )+ ' ';
			return Y+M+D;
		},
		//点击高级检索显示
		searchClick() {
			setTimeout(() => {
				$('#senior-search').slideToggle(300);
			}, 300)
		},
		searchFn(){
			if(this.activeType === 'dept'){
				if(this.keyword !== ''){
					this.getTreeSearch();
				} else {
					this.getTree(false);
				}
			} else {
				this.screenConditionObj.input = this.keyword;
				this.getUserTable(this.screenConditionObj,'false');
			}
			
		},
		// 
		changeSearch(){
			if(this.keyword === ''){
				this.isSearch = false;
				this.getTree(true);
			} 
		},
		// 高级检索--进行搜索
		screenCondition(datas){
			console.log(datas,'-------');
			this.currentPage = 1;
			datas.input = this.keyword;
			this.screenConditionObj = JSON.parse(JSON.stringify(datas));
			// this.screenConditionObj.departmentIds = [];
			// datas.departmentIds.length > 0 && datas.departmentIds.forEach(item => {
			// 	this.screenConditionObj.departmentIds.push(item.id);
			// })
			// this.$refs.seniorSearch.resetSeniorSearch();
			this.getUserTable(this.screenConditionObj,'is');
			this.dynamicTags = [];
			if(datas.birthdayRange.length > 0){
				this.dynamicTags.push({
					name:this.$t('upDownManage.seniorSearch.birthday'),
					key:"birthdayRange",
					value:this.getDataTime(datas.birthdayRange[0]) + '至 ' + this.getDataTime(datas.birthdayRange[1])
				})
			}
			if(datas.staffInRange.length > 0){
				this.dynamicTags.push({
					name:this.$t('upDownManage.seniorSearch.staffIn'),
					key:"staffInRange",
					value:this.getDataTime(datas.staffInRange[0]) + '至 ' + this.getDataTime(datas.staffInRange[1])
				})
			}
			// if((datas.activateStatus && datas.activateStatus > -1) || datas.activateStatus === 0){
			// 	this.dynamicTags.push({
			// 		name:"激活状态",
			// 		key:"activateStatus",
			// 		value:this.getDataTime(datas.staffInRange[0]) + '至 ' + this.getDataTime(datas.staffInRange[1])
			// 	})
			// }
			// if(datas.departmentIds.length > 0){
			// 	this.dynamicTags.push({
			// 		name:"组织架构",
			// 		key:"departmentIds",
			// 		value:datas.departmentIds
			// 	})
			// }
			this.dynamicTags.push({
				name:this.$t('upDownManage.seniorSearch.status'),
				key:"status",
				value:datas.status === null  ? this.$t('upDownManage.seniorSearch.all') : datas.status ? this.$t('upDownManage.seniorSearch.normal') : this.$t('upDownManage.seniorSearch.disabled')
			})
			let text = this.$t('upDownManage.seniorSearch.all')
			if(datas.activateStatus === null){
				text = this.$t('upDownManage.seniorSearch.all');
			} else if(datas.activateStatus === 0){
				text = this.$t('upDownManage.seniorSearch.notActive');
			}else if(datas.activateStatus === 1){
				text = this.$t('upDownManage.seniorSearch.activationSuccess');
			}else if(datas.activateStatus === 2){
				text = this.$t('upDownManage.seniorSearch.activationFail');
			}
			this.dynamicTags.push({
				name:this.$t('upDownManage.seniorSearch.activationStatus'),
				key:"status",
				value:text
			})
			console.log(this.dynamicTags,'dynamicTagdynamicTag');
			
		},
		handleClose(item,indx){
			this.dynamicTags.splice(indx, 1);
			if(item.key === 'birthdayRange'){
				this.screenConditionObj.birthdayRange = [];
			} else if(item.key === 'staffInRange'){
				this.screenConditionObj.staffInRange = [];
			}
			//  else if(item.key === 'departmentIds'){
			// 	this.screenConditionObj.departmentIds = [];
			// }
			 else if(item.key === 'status'){
				this.screenConditionObj.status = null;
			}
			this.$refs.seniorSearch.resetSeniorSearch(item);
			this.getUserTable(this.screenConditionObj,'is');
		},
		deleteAllTag(type){
			this.screenConditionObj = {
				birthdayRange:[],
				staffInRange:[],
				// departmentIds:[],
				status:null
			}
			this.dynamicTags = [];
			this.$refs.seniorSearch.resetSeniorSearch({},'all');
			if(type === 'deleteAllTag'){
				this.getUserTable(this.screenConditionObj,'is');
			}
		},
		// 获取表格数据
		getUserTable(datas,type){
			this.$http.post(this.requestUrl.upAndDown.getUserData + "?organizationId=" + this.checkedTreeItem.id + '&page=' + this.currentPage + '&size=' + this.pageSize,datas || {})
				.then((res) => {
					if(res.data.code == 0){
						this.tableData = res.data.data.list || [];
						this.total = res.data.data.total || null;
						if(type === 'is'){
							$('#senior-search').slideUp(300);
						}
					} else {
						this.$message.error(res.data.message)
					}
				});
		},
		// 表格用户详情
		getDetails(datas){
			this.checkedUserList = [];
			this.userDetail = datas;
			this.addMemberVisible = true;
		},
		// 表格多选
		handleSelectionChange(data){
			console.log(data);
			this.checkedUserList = [...[],...data];
		},
		// 分页器切换每页条数
		handleSizeChange(data){
			this.pageSize = data;
			this.getUserTable();
		},
		// 分页器切换页码
		handleCurrentChange(data){
			this.currentPage = data;
			this.getUserTable();
		},
		// 添加成员
		addMember(){
			this.userDetail = {};
			this.checkedUserList = [];
			this.addMemberVisible = true;
			// this.getUserTable();
		},
		// 批量编辑成员
		batchEditUser(){
			if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
				this.checkedUserList = [...[],...this.$refs.multipleTable.selection];
				this.addMemberVisible = true;
				console.log(this.checkedUserList,'checkedUserList');
			} else {
				this.$message.warning(this.$t('upDownManage.selectEditMember'))
			}
		},
		delDeptFn(){
			let ids = [];
			this.delDeptHasMemberList.forEach(item => {
				ids.push(item.id)
			})
			this.$http.post(this.requestUrl.upAndDown.selectOrganizationHasMember,ids)
				.then(res=>{
					if (res.data.code === '0'){
						if(res.data.data){
							this.$confirm(this.isBatchDel  ? this.$t('upDownManage.deleteOrganizationTip') : this.$t('upDownManage.deleteOrganizationTip1'), this.$t('upDownManage.tip'), {
								confirmButtonText: this.$t('common.confirm'),
								cancelButtonText: this.$t('common.cancel'),
								type: 'warning'
							}).then(() => {
								this.$http.delete(this.requestUrl.upAndDown.deleteUpAndDownStreamById,{
									data: this.delDeptHasMemberList
								})
									.then(res=>{
										if (res.data.code === '0'){
											this.$message({
												message:this.$t('common.deleteSuccess'),
												type:'success',
												duration:1000
											})
											this.getTree(true);
										} else {
											this.$message.error(res.data.message)
										}
									})
								}).catch(() => {
								this.$message({
									type: 'info',
									message: this.$t('common.cancelDelete')
								});          
							});
						} else {
							this.delDeptHasMemberPopup = true;
						}
					} else {
						this.$message.error(res.data.message)
					}
				})
				
			
		},
		// 删除用户
		deleteUser(){
			if(this.activeType === 'member'){
				if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
					let ids = [];
					this.checkedUserList.forEach((item,index) => {
						ids.push(item.id)
					})
					this.$http.post(this.requestUrl.upAndDown.confirmPrincipal ,ids)
						.then((res) => {
							if (res.data.code === '0'){
								console.log(res.data.data);
								let str = res.data.data.length > 0 ? this.$t('upDownManage.confirmPrincipalTip1')+res.data.data.join(',')+this.$t('upDownManage.confirmPrincipalTip2') : this.$t('upDownManage.confirmPrincipalTip3')
								this.$confirm(str, this.$t('upDownManage.tip'), {
									confirmButtonText: this.$t('common.confirm'),
									cancelButtonText: this.$t('common.cancel'),
									type: 'warning'
								}).then(() => {
									
									this.$http.delete(this.requestUrl.upAndDown.deleteUpAndDownMemberById,{
										data: ids
									}).then(res=>{
											if (res.data.code === '0'){
												this.getTree(false);
												// this.getUserTable();
												this.$refs.multipleTable.clearSelection();
												this.$message({
													message:this.$t('upDownManage.deleteSuccess'),
													type:'success',
													duration:1000
												})
												
											} else {
												this.$message.error(res.data.message)
											}
										})
								}).catch(() => {
									this.$message({
										type: 'info',
										message: this.$t('upDownManage.cancelDelete')
									});          
								});
									
								

							} else {
								this.$message({
									type: 'error',
									message: '获取成员身份信息出错'
								});
							}
						})
				} else {
					this.$message.warning(this.$t('upDownManage.selectDeleteData'))
				}
			} else {
				if(this.$refs.deptTreeTable.selection && this.$refs.deptTreeTable.selection.length > 0){
					this.isBatchDel = true;
					// this.delDeptHasMemberPopup = true;
					this.delDeptHasMemberList = this.$refs.deptTreeTable.selection;
					this.delDeptFn();
				} else {
					this.$message.warning(this.$t('upDownManage.selectDeleteData'))
				}
			}
		},
		// 导入弹框显隐
		importFile(){
			this.importFileVisible = true;
		},
		// 导出
		exportFile(){
			console.log(this.$refs.multipleTable.selection);
			if(this.$refs.multipleTable.selection && this.$refs.multipleTable.selection.length > 0){
				this.$http.post(this.requestUrl.upAndDown.memberExport,this.$refs.multipleTable.selection)
					.then(res=>{
						if (res.data.code === '0'){
							window.location.href = res.data.data;
							this.$message({
								message:this.$t('upDownManage.exportSuccess'),
								type:'success',
								duration:1000
							})
							this.$refs.multipleTable.clearSelection();
						} else {
							this.$message.error(res.data.message)
						}
					})
			} else {
				this.$message.warning(this.$t('upDownManage.selectExportData'))
			}
		},
		
		





		setChildren(children, type) {
			// 编辑多个子层级
			children.map((j) => {
				this.toggleSelection(j, type)
				if (j.children) {
				this.setChildren(j.children, type)
				}
			})
		},
		// 选中父节点时，子节点一起选中取消
		select(selection, row) {
			if (selection.some((el) => {return row.id === el.id})) {
				if (row.children && row.children.length > 0) {
					console.log(row,1111);
					// 解决子组件没有被勾选到
					this.setChildren(row.children, true)
				} else {
					this.toggleSelection(row, true)
				}
			} else {
				if (row.children) {
					this.setChildren(row.children, false)
				}
			}
		},
		toggleSelection(row, select) {
			if (row) {
				this.$nextTick(() => {
				this.$refs.deptTreeTable && this.$refs.deptTreeTable.toggleRowSelection(row, select)
				})
			}
		},
		// 选择全部
		selectAll(selection) {
			// tabledata第一层只要有在selection里面就是全选
			const isSelect = selection.some((el) => {
				const tableDataIds = this.treeData.map((j) => j.id)
				return tableDataIds.includes(el.id)
			})
			// tableDate第一层只要有不在selection里面就是全不选
			const isCancel = !this.treeData.every((el) => {
				const selectIds = selection.map((j) => j.id)
				return selectIds.includes(el.id)
			})
			console.log(isSelect, 'isSelect')
			if (isSelect) {
				selection.map((el) => {
				if (el.children) {
					// 解决子组件没有被勾选到
					this.setChildren(el.children, true)
				}
				})
			}
			if (isCancel) {
				this.treeData.map((el) => {
				if (el.children) {
					// 解决子组件没有被勾选到
					this.setChildren(el.children, false)
				}
				})
			}
			console.log(this.treeData,'-----------');
			this.$emit('handleSelect', this.treeData)
			}

		}
};
</script>
<style lang="less" scoped>
@import "./../../../assets/less/upDownManage/upDownManage2.less";
[v-cloak]{
	display: none;
}
</style>