var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"roleTable"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.roleData,"height":"calc(100vh - 130px)"}},[_c('el-table-column',{attrs:{"prop":"name","label":"角色名称","width":"240px","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[_vm._v(" "+_vm._s(scope.row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"角色描述","align":"left","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[(scope.row.remark !== '')?_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(_vm._s(scope.row.remark))]):_c('span',[_vm._v("--")])])]}}])}),_c('el-table-column',{attrs:{"prop":"accountNum","label":"角色数量","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[(scope.row.isDefault)?_c('el-button',{attrs:{"type":"text","size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.details(scope.$index, scope.row.id)}}},[_vm._v(" 详情 ")]):_vm._e(),(!scope.row.isDefault)?_c('el-button',{attrs:{"type":"text","size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.editRow(scope.$index, scope.row.id)}}},[(
                                _vm.operableDeleteRole(
                                    'PORTAL_MANAGEMENT_ROLE_UPDATE'
                                )
                            )?_c('span',[_vm._v("编辑")]):_c('span',[_vm._v("详情")])]):_vm._e(),(
                            !scope.row.isDefault &&
                            _vm.operableDeleteRole(
                                'PORTAL_MANAGEMENT_ROLE_DELETE'
                            ) &&
                            scope.row.accountNum === 0
                        )?_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","size":"medium"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(scope.row.id, scope.row.name)}}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"footer-pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.roleTableTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"title":"删除角色","visible":_vm.dialogVisible,"width":"300px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticStyle:{"text-align":"left"}},[_vm._v("是否删除 "),_c('span',{staticStyle:{"padding-left":"4px","font-weight":"600"}},[_vm._v(_vm._s(_vm.roleName))])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.deleteRole}},[_vm._v("删 除")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }