<template>
    <div class="gr-appointment">
        <div class="appointment">
            <div class="gr-appointment-left"></div>
            <div class="gr-appointment-right">
                <div class="gr-appointment-right-c">
                    <div class="gr-appointment-logo"></div>
                    <div class="gr-appointment-title">
                        欢迎您预约AskBot演示服务
                    </div>
                    <div class="gr-appointment-form" id="form">
                        <div class="form-item">
                            <div class="label">公司名称</div>
                            <input
                                id="inputCompName"
                                @input="changeCompName"
                                v-model="compName"
                                placeholder="请输入您的公司名称"
                            />
                            <div class="gr-tips">{{ compNameTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">姓名</div>
                            <input
                                id="inputName"
                                @input="changeName"
                                v-model="name"
                                placeholder="请输入您的姓名"
                            />
                            <div class="gr-tips">{{ nameTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">手机号码</div>
                            <input
                                v-number-only
                                id="inputPhone"
                                v-model="phone"
                                @input="changephone"
                                placeholder="请输入您的手机号"
                            />
                            <div class="gr-tips">{{ phoneTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">验证码</div>
                            <div class="form-input-cell">
                                <input
                                    id="inputPassword"
                                    placeholder="请输入验证码"
                                    v-model="checkCode"
                                    autocomplete="off"
                                    @input="changeCheckCode"
                                />
                                <span
                                    @click="getCheckcode"
                                    v-if="!verifyIng"
                                    class="varify-btn"
                                    >{{ verifyName }}</span
                                >
                                <span v-if="verifyIng" class="varify-time"
                                    >{{ verifyTime }} S</span
                                >
                            </div>
                            <div class="gr-tips">{{ checkCodeTips }}</div>
                        </div>
                        <div class="form-item gr-appointment-btn">
                            <button  :class="[disabledRegister?'disabled-btn':'']" @click="appointment" id="getBackNow">
                                立即预约
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";
console.log(redirectUri);
export default {
    data() {
        return {
            disabledRegister: false,
            verifyTime: 60,
            verifyName: "获取验证码",
            compName: "",
            compNameTips: "",
            name: "",
            nameTips: "",
            phone: "",
            phoneTips: "",
            checkCode: "",
            checkCodeTips: "",
            verifyIng: false,
            currentIp: "",
            loginUrl: redirectUri.sso,
        };
    },
    computed: {},
    mounted() {
        this.getCurrentIp();
    },
    directives: {
        numberOnly: {
            bind(el) {
                el.handler = function () {
                    el.value = el.value.replace(/\D+/, "");
                    if (el.value.length > 11) {
                        el.value = el.value.substring(0, 11);
                    }
                };
                el.addEventListener("input", el.handler);
            },
            unbind(el) {
                el.removeEventListener("input", el.handler);
            },
        },
    },
    methods: {
        changephone() {
            if (this.phone.trim() !== "") {
                this.phoneTips = "";
            }
        },

        changeCompName() {
            if (this.compName.trim() !== "") {
                this.compNameTips = "";
            }
        },

        changeName() {
            if (this.name.trim() !== "") {
                this.nameTips = "";
            }
        },

        changeCheckCode() {
            if (this.checkCode.trim() !== "") {
                this.checkCodeTips = "";
            }
        },

        getCurrentIp() {
            this.$http_ignore_auth
                .get("https://www.askbot.cn/ext-api/ip")
                .then((res) => {
                    this.currentIp = res.data.ip;
                });
        },
        appointment() {
            if(this.disabledRegister) {
                return false;
            }
            if (this.compName.trim() === "") {
                this.compNameTips = "请输入您的公司名称！";
                return false;
            }
            if (this.name.trim() === "") {
                this.nameTips = "请输入您的姓名！";
                return false;
            }
            if (this.phone.trim() === "") {
                this.phoneTips = "请输入您的手机号，方便我们联系您！";
                return false;
            }
            if (
                this.checkCode.trim() === "" ||
                this.checkCode.trim().length !== 6
            ) {
                this.checkCodeTips = "请输入6位验证码！";
                return false;
            }

            this.disabledRegister = true;

            this.$http_ignore_auth
                .post(
                    "/api/code/sms/check?phone=" +
                        this.phone.trim() +
                        "&code=" +
                        this.checkCode
                )
                .then((res) => {
                    if (res.data.code === "0" && res.data.data) {
                        this.$http_ignore_auth
                            .post(
                                "/bearing-api//internal-work-order/base/clue/14676a0cb2ce11ea8f0900163e0964d5?updateSort=true",
                                {
                                    timestamp: 1648638326953,
                                    token: "B186BDFF97B6A39115785229D60CB6D0D1EF2E9EAA7C7B7AE82360119343F744D27AD3496DA418A210CEE7CB5C656AE2AE86182033742649A629D184C4752EEC4296187A84D91045AD860C4B847EAD681243F7555DDBF8FE00B3D23E0D6F9176938A4F39865EA18439AD92059EF42235",
                                    mobile: this.phone,
                                    name: this.name,
                                    companyName: this.compName,
                                    description: "askbot官网预约",
                                    ip: this.currentIp,
                                    source: "官网预约演示",
                                }
                            )
                            .then((res) => {
                                this.$message.success(
                                    "您已经成功预约演示，我们将会尽快联系您！"
                                );
                                setTimeout(() => {
                                    window.open("https://www.askbot.cn/");
                                }, 3000);
                            });
                    } else {
                        this.checkCodeTips = "验证码输入有误，请确认后重试！";
                        this.disabledRegister = false;
                        return false;
                    }
                });
        },
        getCheckcode() {
            if (this.phone.trim() === "") {
                this.phoneTips = "手机号不能为空！";
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.phone.trim())) {
                    this.phoneTips = "请检查手机号码是否正确";
                    return false;
                }
            }
            this.$http_ignore_auth
                .post("/api/code/sms/send?phone=" + this.phone.trim())
                .then((res) => {
                    if (res.data.code != 0) {
                        this.$message.error(res.data.message);
                    } else {
                        this.verifyIng = true;
                        this.verifyTime = 60;
                        let timer = setInterval(() => {
                            this.verifyTime = this.verifyTime - 1;
                            if (this.verifyTime == 0) {
                                this.verifyName = "重新获取";
                                this.verifyIng = false;
                                clearInterval(timer);
                            }
                        }, 1000);
                    }
                });
        },
    },
};
</script>
<style lang="less">
.appointment {
    width: 100%;
    display: flex;
    height: 100vh;
    background-color: white;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

.exit-acc {
    margin-top: -32px;
    text-align: left;
    a {
        color: #3682ff;
    }
}

.gr-tips {
    color: #f56c6c;
    text-align: right;
    font-size: 11px;
}

@media screen and (max-width: 960px) {
    .gr-appointment-left {
        display: none;
    }

    .appointment {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.gr-appointment-left {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-appointment-right {
    width: 38vw;
    min-width: 556px;
    height: 370px;
    margin: auto 0;
}

.gr-appointment-right-c {
    margin-top: -100px;
}

.gr-appointment-logo {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-appointment-title {
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
    height: 14px;
}

.gr-appointment-form {
    max-width: 60%;
    min-width: 342px;
    margin: 30px auto 0;
}

.gr-appointment-form .form-item {
    width: 100%;
    height: 79px;
    .el-select {
        width: 100%;
        input {
            width: 100%;
        }
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}

.gr-appointment-form .form-item input {
    width: calc(100% - 30px);
    outline-style: none;
    border: 1px solid #e0e6f7;
    border-radius: 5px;
    padding: 10px 14px;
    font-size: 14px;
}

.gr-appointment-form .form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
}

input::-webkit-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input::-moz-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:-ms-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:focus {
    border-color: #366aff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
}

.gr-appointment-btn {
    padding-top: 18px;
}

.gr-appointment-btn button {
    width: 100%;
    height: 38px;
    background: #3682ff;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.gr-appointment-btn button:hover {
    background-color: #366aff;
}

.gr-appointment-login {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}
</style>