<template>
    <div></div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";

export default {    
    beforeCreate() {
        this.$http.get("/api/sso/auth/logged")
            .then(() => {
                console.log('logged-success');
                this.goLogin();
            })
            .catch(() => {
                console.log('logged-error');
                this.goLogin();
            });
    },
    methods:{
        goLogin(){
            let redirect = window.location.origin + "/#/auth";
            console.log("#######redirect:", redirect);
            if(window.location.origin == 'https://portal.ebot.isheely.com') {
                window.location = 'https://signin.ebot.isheely.com'
            } else {
                window.location = redirectUri.sso;
            }
        }
    }
};
</script>
