import { render, staticRenderFns } from "./AccountBind.vue?vue&type=template&id=4ceef512"
import script from "./AccountBind.vue?vue&type=script&lang=js"
export * from "./AccountBind.vue?vue&type=script&lang=js"
import style0 from "./AccountBind.vue?vue&type=style&index=0&id=4ceef512&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports