<template>
    <div class="apply-list">
        <el-drawer
            class="apply-list-drawer"
            :title="title"
            :size="'684px'"
            show-close
            direction="rtl"
            :visible.sync="applyListDrawer"
            :close-on-press-escape="false"
            :destroy-on-close="true"
            :wrapperClosable="false"
            :with-header="true">
            <!-- 任务构建中 -->
                <div class="notice-drewer-header">
                    <span>申请列表</span>
                   <div class="apply-status-box">
                       <span class="label-text">申请状态：</span>
                        <el-select v-model="applyStatus" placeholder="请选择" @change="resetParamsSearch" size="small">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <el-checkbox v-model="onlyShowMe" @change="resetParamsSearch">仅展示我邀请的</el-checkbox>
                    <div class="close">
                        <span @click="handleClose" class="el-icon-close"></span>
                    </div>
                </div>
                <div class="apply-content">
                    <el-table
                        :load-more-disable="false"
                        :element-loading-text="loadingtext"
                        height="calc(100vh - 95px)"
                        class="statistics-table"
                        :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:600}"
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            show-overflow-tooltip
                            prop="operateUserName"
                            align="center"
                            label="申请人">
                            <template slot-scope="scope">
                                <open-data v-if="scope.row.departmentSource === 1" :openid="scope.row.memberName" :type="'userName'"></open-data>
                                <span v-else>{{scope.row.memberName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="tel"
                            align="center"
                            label="手机号码">
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            prop="joinDepartName"
                            align="center"
                            label="部门">
                            <template slot-scope="scope">
                                <open-data v-if="scope.row.departmentSource === 1" :openid="scope.row.joinDepartName" :type="'departmentName'"></open-data>
                                <span v-else>{{scope.row.joinDepartName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            prop="inviteUserName"
                            align="center"
                            label="邀请人">
                            <template slot-scope="scope">
                                <open-data v-if="scope.row.departmentSource === 1" :openid="scope.row.inviteUserName" :type="'userName'"></open-data>
                                <span v-else>{{scope.row.inviteUserName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="status"
                            width="140px"
                            class-name="operation-cell"
                            label="操作">
                            <template slot-scope="scope">
                                <!-- (1, "待审核"), (2, "已通过"), (3, "已拒绝"), (4, "已加入"); -->
                                <template v-if="scope.row.status === 1">
                                    <el-tooltip class="item" effect="dark" content="通过" placement="top-start">
                                        <i :class="['iconfont guoran-tongyichicun-kaiqi1',!presentUserCanApprove ? 'disabled' : '']" @click="changeStatus(scope.row,'pass')"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="拒绝" placement="top-start">
                                        <i :class="['iconfont guoran-tongyichicun-guanbi',!presentUserCanApprove ? 'disabled' : '']" @click="changeStatus(scope.row,'prefuseass')"></i>
                                    </el-tooltip>
                                </template>
                                <div class="pass" v-else-if="scope.row.status === 2"><span>由 {{scope.row.operateUserName}} 通过申请</span></div>
                                <div class="refuse"  v-else-if="scope.row.status === 3"><span>由 {{scope.row.operateUserName}} 拒绝申请</span></div>
                                <div class="invite"  v-else><span>由 {{scope.row.operateUserName}} 邀请加入</span> </div>
                            </template>
                        </el-table-column>
                        <div slot="empty">
                            <p>
                                <img src="../../../../assets/images/emptyimg.png">
                            </p>
                            <p><span>暂无申请记录</span></p>
                        </div>
                    </el-table>
                </div>
        </el-drawer>
        <delete-dialog
            @closeEvent="deleteAutomated = false"
            v-if="deleteAutomated">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{operationType === 'pass' ? '通过申请' : '拒绝申请'}}</div>
                <div> {{operationType === 'pass' ? '通过申请' : '拒绝申请'}}之后，该操作将不可恢复，是否{{operationType === 'pass' ? '通过申请' : '拒绝申请'}}？</div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="deleteAutomated = false"
                    size="small"
                    plain
                    >取 消</el-button>
                <el-button
                    @click="delAutomated"
                    style="
                        background-color: #fe5965;
                        color: white;
                    "
                    size="small" >
                    确 认</el-button>
            </div>
        </delete-dialog>
    </div>
</template>

<script>
import OpenData from "@/components/openData";
import DeleteDialog from "@/components/deleteDialogue.vue";

export default {
    data(){
        return{
            applyStatus:1,
            options:[{
                label: '全部',
                value: 99
            },{
                label: '待审核',
                value: 1
            },{
                label: '已通过',
                value: 2
            },{
                label: '已拒绝',
                value: 3
            },{
                label: '已加入',
                value: 4
            }],
            onlyShowMe:false,
            tableData:[],
            page:1,
            size:20,
            loadingtext:"正在加载...",
            loadStatus:"loading",
            deleteAutomated:false,
            operationType:"pass", // 
            operationItem:{}
        }
    },
    props:{
        applyListDrawer:{
            type:Boolean,
            default:false
        },
        isWechat:{
            type:Boolean,
            default:false
        },
        presentUserCanApprove:{
            type:Boolean,
            default:true
        },
        departmentId:{
            type:Number,
            default:null
        }
    },
    components:{OpenData,DeleteDialog},
    watch:{
        applyListDrawer(n){
            if(n){
                this.gettabledata('isStart');
                this.judgeScrolls();
            }
        }
    },
    methods:{
        handleClose(){
            this.$emit('update:applyListDrawer',false)
        },
        resetParamsSearch(){
            this.page = 1;
            this.gettabledata();
        },
        gettabledata(){
            let url = ''
            if( this.applyStatus === 99){
                //  + '&departmentId='+this.departmentId
                url = this.requestUrl.invite.selectApplyListByMainId + '?page=' + this.page + '&size=' + this.size + '&onlyMeInvite=' + this.onlyShowMe
            } else {
                url = this.requestUrl.invite.selectApplyListByMainId + '?page=' + this.page + '&size=' + this.size + '&status=' + this.applyStatus + '&onlyMeInvite=' + this.onlyShowMe 
            }
            this.$http.get(url)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res);
                       if(res.data.total === 0 ){
                            this.loadStatus = 'noData';
                            if(this.page === 1){
                                this.tableData = [];
                            } else {
                                this.tableData = [...this.tableData,...res.data.data.list];
                            }
                        } else {
                            if(this.page === 1){
                                this.tableData = res.data.data.list;
                            } else {
                                this.tableData = [...this.intentList,...res.data.data.list];
                            }
                            if(this.tableData.length < res.data.data.total){
                                this.loadStatus = 'loading';
                            
                            } else if(this.tableData.length === res.data.data.total){
                                this.loadStatus = 'noMore'
                            } 
                            if((this.tableData.length < res.data.data.total || this.tableData.length === res.data.data.total) && this.page === 1){
                                this.loadStatus = 'noData';
                            }
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
        },
        //判断滚动条是否滚到底部
        judgeScrolls(){
            this.$nextTick(() => {
                let dom = document.querySelector('.apply-list .apply-content .el-table__body-wrapper')
                dom.addEventListener('scroll', () => {
                    const clientHeight = dom.clientHeight;
                    const scrollTop = dom.scrollTop;
                    const scrollHeight = dom.scrollHeight;
                    if (clientHeight + scrollTop === scrollHeight) {
                        console.log('竖向滚动条已经滚动到底部')
                        if(this.tableData.length === 0){
                            // this.loadStatus = 'noMore'; 
                        } else {
                            if(this.loadStatus === 'noMore'){

                            } else {
                                console.log(this.intentList.length ,  this.total,this.isScrolls,'---');
                                if(this.intentList.length <  this.total && !this.isScrolls){
                                    this.loadStatus = 'loading';
                                    this.SeniorFilterParams.pageNo++;
                                    // console.log(666666);
                                    this.gettabledata();
                                } else {
                                    this.loadStatus === 'noMore'
                                }
                            }
                        }
                    }
                })
            })
        },
        // 通过/拒绝
        changeStatus(item,type){
            this.operationType = type;
            this.operationItem = JSON.parse(JSON.stringify(item));
            this.deleteAutomated = true;
        },
        delAutomated(){
            this.$http.put(this.requestUrl.invite.applyOperate,Object.assign(this.operationItem,{status:this.operationType === 'pass' ? 2 : 3}))
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                       this.page = 1; 
                       this.deleteAutomated = false;
                       this.gettabledata();
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        }
    }
}
</script>

<style lang="less" scoped>
.apply-list{
    /deep/ .el-drawer {
        .el-drawer__header{
            height: 0;
            padding: 0;
            margin: 0;
            overflow: hidden; 
        }
        .notice-drewer-header {
            display: flex;
            align-items: center;
            flex: none;
            height: 45px;
            background: #F6F8FD;
            padding: 0 20px;
            .close {
                flex: auto;
                text-align: right;
                font-size: 14px;
                cursor: pointer;
            }
            .apply-status-box{
                position: relative;
                margin: 0 16px;
                width: 155px;
                display: flex;
                align-items: center;
                .label-text{
                    position: absolute;
                    z-index: 2;
                    left: 6px;
                    color: #616161;
                    font-size: 14px !important;
                }
                .el-select{
                    .el-input__inner{
                        width: 155px;
                        // height: 28px;
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        padding-left: 70px;
                        color: #000;
                        font-size: 14px !important;
                    }
                    .el-select__caret{
                        // margin-top: 2px;
                        color: #717B90;
                    }
                }
            }
            .el-checkbox{
                .el-checkbox__label{
                    color: #000;
                }
            }
        }
        .apply-content{
            margin: 22px 20px 28px 20px;
            .el-table{
                .cell{
                    padding-left: 10px !important;
                }
                .operation-cell{

                    .guoran-tongyichicun-kaiqi1{
                        color: #366AFF;
                        margin-right: 20px;
                        cursor: pointer;
                        &.disabled{
                            cursor:no-drop;
                            color: #999999;
                        }
                    }
                    .guoran-tongyichicun-guanbi{
                        color: #FE5965;
                        cursor: pointer;
                        &.disabled{
                            cursor:no-drop;
                            color: #999999;
                        }
                    }
                    .pass,.refuse,.invite{
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        background: #ECF1FF;
                        border-radius: 3px;
                        display: flex;
                        justify-content: center;
                        flex-wrap: nowrap;
                        padding: 0 6px;
                        font-size: 12px;
                        span{
                            // margin: 0 3px;
                            white-space: nowrap;
                        }
                    }
                    .pass{
                        background: #ECF1FF;
                        color: #366AFF;
                    }
                    .refuse{
                        background: #FFEBEC;
                        color: #FE5965;
                    }
                    .invite{
                        background: #E5F8ED;
                        color: #04B051;
                    }
                    
                }
                &::before{
                    height: 0;
                }
                .el-table__empty-text{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 300px;
                        height: 200px;
                    }
                    p{
                        color: #616161;
                        font-size: 16px;
                    }
                }
            }
            .bottom-close{
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                margin-top: 10px;
                .el-button{
                    width: 99px;
                    height: 37px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #FFFFFF;
                    border: 2px solid #E0E6F7;
                    border-radius: 19px;
                    color: #000000;
                }
            }
            
        }
    }
    #delete-dialog{
        // z-index: 2100;
    }
}
</style>