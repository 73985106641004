<!-- 
/**
pageName:批量编辑弹框组件
auther:liuyuli
time:2022-4-22
 */
 -->
<template>
  <div class="batch-edit">
        <popup @closeEvent="closeEvent" v-if="batchEditDialog">
            <div slot="popup-name">批量编辑</div>
            <div slot="popup-tip">管理{{getPopupTips(activeObjs.type)}}选项值</div>
            <div slot="popup-con" class="popup-con">
                <div class="content-tree" >
                   <div class="left">
                       <el-tree :data="activeObjs.extInfo.cascadeDown.options"
                                :default-expand-all="true"
                                :expand-on-click-node="false"
                                class="tree-line"
                                :indent="0"
                       >
                            <span class="el-tree-title" slot-scope="{ node, data }">
                                <el-input v-model="data.label" placeholder="请输入内容"
                                          @change="changeInput($event,data)"
                                          size="medium"
                                          style="min-width: 100px"
                                ></el-input>
                              <span class="right-icon-operation">
                                <i class="iconfont guoran-tongyichicun-tianjiatongji" @click="addSameLevel(data,node)"></i>
                                <i class="iconfont guoran-tongyichicun-tianjiaxiaji" @click="addChildrenLevel(data,node)"></i>
                                <i class="iconfont guoran-a-16-09" @click="deleteLevel(data,node)"
                                   v-if="!(activeObjs.extInfo.cascadeDown.options.length===1&&
                                      (!activeObjs.extInfo.cascadeDown.options[0].children||
                                      activeObjs.extInfo.cascadeDown.options[0].children.length===0))"></i>
                              </span>
                            </span>
                        </el-tree>
                   </div>
                   <div class="right">
                       <el-tree :data="activeObjs.extInfo.cascadeDown.options" :default-expand-all="true"></el-tree>
                   </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveBtn" size="small">保 存</el-button>
            </div>
        </popup>
  </div>
</template>

<script>
import Popup from "../../../../components/popup";
export default {
  components: {
    Popup
  },
  props: {
    batchEditDialog: {
      type: Boolean,
      default: false
    },
    activeObj: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    batchEditDialog: {
      handler (newVal) {
        if(newVal) {
          this.optionCopy = JSON.parse(JSON.stringify(this.activeObj.extInfo.cascadeDown.options))
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    activeObjs () {
      return this.activeObj;
    }
  },
  data () {
    return {
      data: [{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1',
          children: [{
            id: 9,
            label: '三级 1-1-1'
          }, {
            id: 10,
            label: '三级 1-1-2'
          }]
        }]
      }, {
        id: 2,
        label: '一级 2',
        children: [{
          id: 5,
          label: '二级 2-1'
        }, {
          id: 6,
          label: '二级 2-2'
        }]
      }],
      optionCopy:[]
    }
  },
  methods: {
    getPopupTips () {
      let name = ''
      switch (this.activeObjs.type) {
        case 'RADIO':
          name = '单选';
          break;
        case 'CHECKBOX':
          name = '多选';
          break;
        case 'SELECT':
          name = '下拉';
          break;
        case 'CASCADER':
          name = '级联';
          break;
      }
      return name;
    },
    addOPtionOther () {
      let boo = true;
      this.activeObjs.extInfo.options.forEach(item => {
        if (item.value === '其他') {
          boo = false;
        }
      })
      if (boo) {
        this.activeObjs.extInfo.options.push({ value: '其他', label: '其他', _default: 'false' });
      } else {
        this.$message.error("只能添加一个'其他'选项");
      }
    },
    closeEvent () {
      this.activeObjs.extInfo.cascadeDown.options = this.optionCopy;
      this.$emit('update:batchEditDialog', false);
    },
    saveBtn () {
      let options = JSON.parse(JSON.stringify(this.activeObjs.extInfo.cascadeDown.options))
      options = options.filter(item => item.value)
      options.forEach(item => {
        this.ergodicCascade(item);
      })
      this.activeObjs.extInfo.cascadeDown.options = options;
      this.$emit('update:batchEditDialog', false);
    },
    //编辑级联，删除空数据
    ergodicCascade (item) {
      if (item.children) {
        item.children = item.children.filter(items => items.value)
        item.children.forEach(items => {
          this.ergodicCascade(items)
        })
      }
      if (item.children && item.children.length == 0) {
        delete item.children
      }
    },
    changeInput (label, data) {
      data.value = label
      console.debug('data', data)
    },
    addSameLevel (data, node) {
      console.debug('deleteLevel', data, node)
      let parent = node.parent
      if (parent) {
        let val = {
          label: '',
          value: '',
          _default: false,
          relationItems: [],
        }
        if (parent.data.children) {
          parent.data.children.push(val)
        }
        else {
          parent.data.push(val)
        }
      }
    },
    addChildrenLevel (data, node) {
      if (!data.value) {
        this.$message({
          message: "请先完善该级数据",
          type: 'warning',
          duration: 2000
        })
        return
      }
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      let val = {
        label: '',
        value: '',
        _default: false,
        relationItems: [],
      }
      data.children.push(val)
    },
    deleteLevel (data, node) {
      if (this.activeObjs.extInfo.cascadeDown.options.length === 1 && JSON.stringify(data) === JSON.stringify(this.activeObjs.extInfo.cascadeDown.options[0])) {
        data.children = []
        return
      }
      let parent = node.parent
      let delIndex = 0

      if (parent.data) {
        if (!parent.parent) {
          parent.data.forEach((item, index) => {
            if (item.value === data.value) {
              delIndex = index
            }
          })
          parent.data.splice(delIndex, 1)
        }
        else {
          parent.data.children.forEach((item, index) => {
            if (item.value === data.value) {
              delIndex = index
            }
          })
          parent.data.children.splice(delIndex, 1)
        }
      }
      console.debug('data', parent.data, data)
    }
  },
  mounted() {
    console.log('hhhhhh');
  }
}
</script>

<style scoped lang="less">
.batch-edit{
    ::v-deep #popup-assembly{
        width: 1000px !important;
      .popup-container{
        height: calc(82vh - 180px);
      }
        .content-tree{
            border-radius: 5px;
            padding: 10px;
            min-height: 460px;
            display: flex;
            background-color: #FBFCFD;
            border: 1px solid #e0e6f7;
            .el-tree{
              background-color: #FBFCFD;
            }
            /*定义滚动条轨道 内阴影+圆角*/
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px transparent;
                border-radius: 10px;
                background-color:  #E7ECF6;
            }

            /*定义滚动条高宽及背景*/
            &::-webkit-scrollbar {
                width: 5px;
            }

            /*定义滚动条滑块*/
            &::-webkit-scrollbar-thumb {
                background-color: #366AFF;
                border-radius: 3px;
            }
            .left{
              border-right: 1px solid #e0e6f7;
              margin-right: 20px;
              width: 600px;
              overflow: scroll;
              flex: none;
                .el-tree{
                    .el-tree-node,.el-tree-node__content,.el-tree-node__content:hover, .is-focusable,.is-expanded,.is-current{
                        background-color: #FBFCFD;
                    }
                   .el-input{
                       width: 100%;
                   }
                    .el-tree-node__expand-icon{
                        display: none;
                    }
                    .el-tree-node__content{
                        height: auto;
                        margin-bottom: 10px;
                    }
                    .el-tree-title{
                      color:#366AFF;
                      width: 100%;
                      display: flex;
                      align-items: center;
                        .iconfont{
                            margin: 0 6px;
                        }
                      &:hover{
                        .right-icon-operation{
                          opacity: 1;
                        }
                      }
                    }
                    .right-icon-operation {
                      display: block;
                      color: #366AFF;
                      justify-content: space-around;
                      cursor: pointer;
                      opacity: 0;
                      width: 90px;
                      flex: none;
                    }
                }
            }
          .right{
            width: calc(100% - 600px);
            overflow: hidden;
          }
          .tree-line{
            .el-tree-node {
              position: relative;
              padding-left: 22px; // 缩进量
            }
            .el-tree-node__children {
              padding-left: 22px; // 缩进量
            }

            // 竖线
            .el-tree-node::before {
              content: "";
              height: 100%;
              width: 1px;
              position: absolute;
              left: -3px;
              top: -26px;
              border-width: 1px;
              border-left: 1px dashed #a0a9bd;
            }
            // 当前层最后一个节点的竖线高度固定
            .el-tree-node:last-child::before {
              height: 44px; // 可以自己调节到合适数值
            }

            // 横线
            .el-tree-node::after {
              content: "";
              width: 24px;
              height: 20px;
              position: absolute;
              left: -3px;
              top:18px;
              border-width: 1px;
              border-top: 1px dashed #a0a9bd;
            }

            // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
            & > .el-tree-node::after {
              border-top: none;
            }
            & > .el-tree-node::before {
              border-left: none;
            }

            // 展开关闭的icon
            .el-tree-node__expand-icon{
              font-size: 16px;
              // 叶子节点（无子节点）
              &.is-leaf{
                color: transparent;
                // display: none; // 也可以去掉
              }
            }
          }
        }

    }

}
</style>