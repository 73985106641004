import { render, staticRenderFns } from "./accountFieldType.vue?vue&type=template&id=4fd6d7bf&scoped=true"
import script from "./accountFieldType.vue?vue&type=script&lang=js"
export * from "./accountFieldType.vue?vue&type=script&lang=js"
import style0 from "./accountFieldType.vue?vue&type=style&index=0&id=4fd6d7bf&prod&lang=less"
import style1 from "./accountFieldType.vue?vue&type=style&index=1&id=4fd6d7bf&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd6d7bf",
  null
  
)

export default component.exports