<template>
    <div id="edit-password">
        <popup v-if="editDialog" @closeEvent="editDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('personal.editPassword')}}</div>
            <div slot="popup-tip">{{$t('personal.passwordTip')}}</div>
            <div slot="popup-con">
                <div class="form-item">
                    <div class="label">{{$t('personal.originalPassword')}}</div>
                    <el-input
                        type="password"
                        id="inputPassword"
                        v-model="oldPassword"
                        :onkeyup="
                            (oldPassword = oldPassword.replace(/\s+/g, ''))
                        "
                        @input="changeOldPassword"
                        :placeholder="$t('personal.originalPlaceholder')"
                    />
                    <div class="gr-tips bgc-gr-tips" v-if="oldPasswordTips !== ''">{{ oldPasswordTips }}</div>
                </div>
                <div class="form-item">
                    <div class="label">
                        <span>{{$t('personal.newPassword')}}</span>
                        <span class="tips"> 
                            <i class="guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe iconfont"></i>
                            {{isMengNiu ? $t('personal.passwordTip1') : $t('personal.passwordTip2')}}
                        </span>
                    </div>
                    <el-input
                        type="password"
                        id="inputPassword"
                        v-model.trim="newPassword"
                        :onkeyup="
                            (newPassword = newPassword.replace(/\s+/g, ''))
                        "
                        @blur="changeNewPassword"
                        :placeholder="$t('personal.newPasswordPla')"
                    />
                    <div class="gr-tips bgc-gr-tips" v-if="newPasswordTips !== ''">{{ newPasswordTips }}</div>
                </div>
                <div class="form-item">
                    <div class="label">{{$t('personal.confirmPassword')}}</div>
                    <el-input
                        type="password"
                        v-model.trim="repeatNewPassword"
                        id="inputpasswordAgain"
                        :onkeyup="
                            (repeatNewPassword = repeatNewPassword.replace(
                                /\s+/g,
                                ''
                            ))
                        "
                        @blur="changeRepeatNewPassword"
                        :placeholder="$t('personal.againPlaceholder')"
                    />
                    <div class="gr-tips bgc-gr-tips" v-if="repeatNewPasswordTips !== ''">
                        {{ repeatNewPasswordTips }}
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="editDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="submitEdit"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
export default {
    data() {
        return {
            editDialog: false,
            oldPassword: "",
            oldPasswordTips: "",
            newPassword: "",
            newPasswordTips: "",
            repeatNewPassword: "",
            repeatNewPasswordTips: "",
            isMengNiu:false, // 是否是蒙牛
        };
    },
    components: {
        Popup,
    },
    mounted(){
        this.isMengNiu = localStorage.getItem('_mainId') === '3e83057a1bd74b79b86859ad619fdbbb' ? true : false;
    },
    methods: {
        initShow() {
            this.editDialog = true;
            this.oldPassword = "";
            this.oldPasswordTips = "";
            this.newPassword = "";
            this.newPasswordTips = "";
            this.repeatNewPassword = "";
            this.repeatNewPasswordTips = "";
        },
        changeOldPassword() {
            if (this.oldPassword.trim() !== "") {
                this.oldPasswordTips = "";
            }
        },

        changeNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            if(this.isMengNiu){
                reg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/
            } else {
                reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            }
            if (this.newPassword === '') {
                this.newPasswordTips = "请输入密码";
            } else {
                if (this.repeatNewPassword !== '') {
                    this.newPasswordTips = ''
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = "两次输入的密码不一致";
                    } else {
                        // 校验通过
                        if(reg.test(this.newPassword)){
                            this.newPasswordTips = '';
                        } else {
                            this.newPasswordTips = this.isMengNiu ? "密码必需包含大小写字母、数字、特殊符号" : "必需包含字母、数字且超过8位";
                        }
                        this.repeatNewPasswordTips = '';
                    }
                } else {
                    if(this.newPassword.length < 8){
                        this.newPasswordTips = "密码必需超过8位";
                    } else {
                            // 校验通过
                        if(reg.test(this.newPassword)){
                            this.newPasswordTips = ''
                            
                        }else{
                            // 校验不通过
                            this.newPasswordTips = this.isMengNiu ? "密码必需包含大小写字母、数字、特殊符号" : "必需包含字母、数字且超过8位";
                        }
                    }
                }
            }
        },

        changeRepeatNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            if(this.isMengNiu){
                reg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/
            } else {
                reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            }
            if (this.repeatNewPassword === '') {
                this.repeatNewPasswordTips = "请再次输入密码！";
            } else {
                if (this.newPassword !== '') {
                    this.newPasswordTips = ''
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = "两次输入的密码不一致";
                    } else {
                         // 校验通过
                        if(reg.test(this.repeatNewPassword)){
                            this.repeatNewPasswordTips = '';
                        } else {
                            this.repeatNewPasswordTips = this.isMengNiu ? "密码必需包含大小写字母、数字、特殊符号" : "必需包含字母、数字且超过8位";
                        }
                        this.newPasswordTips = '';
                    }
                } else{
                    if(this.repeatNewPassword.length < 8){
                        this.repeatNewPasswordTips = "密码必需超过8位";
                    } else {
                        // 校验通过
                        if(reg.test(this.repeatNewPassword)){
                            this.repeatNewPasswordTips = ''
                        } else{
                            // 校验不通过
                            this.repeatNewPasswordTips = this.isMengNiu ? "密码必需包含大小写字母、数字、特殊符号" : "必需包含字母、数字且超过8位";
                        }
                    }
                }
            }
        },
        submitEdit() {
            if (this.oldPassword.trim() === "") {
                this.oldPasswordTips = "请输入原密码！";
                return false;
            }
            if (this.newPassword.trim() === "") {
                this.newPasswordTips = "请输入新密码！";
                return false;
            }
            if (this.repeatNewPassword.trim() === "") {
                this.repeatNewPasswordTips = "请再次输入密码！";
                return false;
            }
            if (this.newPassword.trim() !== this.repeatNewPassword.trim()) {
                this.repeatNewPasswordTips = "确认密码和新密码输入不一致！";
                return false;
            }
            if(this.newPasswordTips !== '' || this.repeatNewPasswordTips !== ''){
                return false;
            }
            let url = `${this.requestUrl.accountManage.passWordChange}?&oldPassword=${this.oldPassword}&newPassword=${this.newPassword}&repeatNewPassword=${this.repeatNewPassword}`;
            this.$http
                .get(url)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message.success("密码修改成功！");
                        this.editDialog = false;
                    } else {
                        if (res.data.code === "-1") {
                            this.oldPasswordTips = res.data.message;
                        } else {
                            this.$message.console.error(res.data.message);
                        }
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
    },
    watch:{
        editDialog(value){
            this.$emit('changeDialog',value)
        }
    }
};
</script>
<style lang="less" scoped>
.form-item {
    width: 100%;
    height: 82px;
    .gr-tips {
        color: #f56c6c;
        text-align: right;
        font-size: 11px;
    }
    .bgc-gr-tips{
        color: #FE5965;
        font-size: 12px;
        margin-top: 2px;
        margin-bottom: 3px;
        height: 18px;
        background: #FFEBEC;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}
// .form-item input {
//     width: calc(100% - 30px);
//     outline-style: none;
//     border: 1px solid #e0e6f7;
//     border-radius: 5px;
//     padding: 10px 14px;
//     font-size: 14px;
// }

.form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .tips,.iconfont{
        font-size: 12px;
        color: #A9B3C6;
    }
}
 /deep/ #edit-password .form-item .el-input__inner{
    outline-style: none;
    font-size: 14px;
    height: 38px;
    border-radius: 4px !important;
 }
     


</style>