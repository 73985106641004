<template>
    <div id="index-notice">
        <NoticeList :editPageble="0" :pageNo="1" :pageSize="20" />
    </div>
</template>
<script>
import Popup from "@/components/popup";
import NoticeList from "./IndexnoticeList.vue";
export default {
    components: {
        Popup,
        NoticeList,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        moreNotice(){
             this.$router.push({ name: "notice" });
        }
    },
};
</script>
<style lang="less">
#index-notice {
    height: 100px;
    .cursor-row {
        cursor: pointer;
    }
    
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 168px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
}
</style>