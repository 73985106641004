<template>
    <div class="answer-image">
        <div
            v-if="showPreview"
            style="
                width: 100vw;
                height: 100vh;
                z-index: 10000;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
            "
        >
            <div
                @click="showPreview = false"
                style="
                    color: white;
                    font-size: 30px;
                    padding: 30px 30px 15vh;
                    text-align: right;
                    cursor: pointer;
                "
            >
                <span class="el-icon-close"></span>
            </div>
            <img
                style="max-height: 70vh; max-width: 70vw"
                :src="previewImageUrl"
                alt
                srcset
            />
        </div>
        <div class="answer-image-el-upload">
            <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF"
                :on-success="
                    (response, file, fileList) =>
                        handlePictureUploadSuccess(
                            response,
                            file,
                            fileList,
                            unit.content
                        )
                "
                :before-upload="beforeImageMUpload"
                :http-request="((data) => {
                  return aliyunOssRequest(data)
                })"
            >
                <div class="avatar-box" v-if="unit.content.url">
                    <img :src="unit.content.url" class="avatar" />
                    <div class="handle-box">
                        <!-- <el-tooltip
                            content="查看"
                            placement="top"
                            effect="light"
                        >
                            
                        </el-tooltip> -->
                        <i @click.stop="viewImage(unit.content.url)" class="el-icon-search"></i>
                        <!-- <el-tooltip
                            content="重新上传"
                            placement="top"
                            effect="light"
                        >
                            <i slot="trigger" class="el-icon-refresh-right"></i>
                        </el-tooltip> -->
                        <!-- <i @click.stop="alert(100)" class="el-icon-search"></i> -->
                        <i slot="trigger" class="el-icon-refresh-right"></i>
                    </div>
                </div>

                <div slot="trigger" class="no-url-upload" v-else>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
            </el-upload>
        </div>
        <div class="answer-image-limit">
            {{$t('flowComponent.uploadImageTip')}}
        </div>
    </div>
</template>
<script>
import { multipartUpload, ossConfig } from "./js/AliyunIssUtilWithProcess";

export default {
    props: ["unit"],
    data() {
        return {
            importFileUrl: location.origin + "/api/oss",
            showPreview: false,
            previewImageUrl: "",
            loadFileList:[],
            fileItem: null
        };
    },
    methods: {
        viewImage(url){
            this.previewImageUrl = url;
            this.showPreview = true;
            
        },
        handlePictureCardPreview() {
        },
        beforeImageMUpload(file) {
            console.log(file);
            const isJPG =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            if (!isLt2M) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            return isJPG && isLt2M;
        },

       aliyunOssRequest(data) {
        let file = data.file;
        let res = multipartUpload(ossConfig, file, (process) => {
          // console.log(168, process, file);
        });
        // console.debug('oss upload res', data, res);
        return res;
      },
        // 图片上传成功
        handlePictureUploadSuccess(response, file, fileList, content) {
            console.log(this.loadFileList,response);
            console.log(response, file, fileList, content);
            let url = response.res.requestUrls[0];
            if (url.indexOf('uploadId') !== -1){
              url = url.split('?')[0]
            }
            content.url = url;
        },
        handleUpload(file) {
            let formData = new FormData()
            formData.append('file', file);
            this.$http.post(this.importFileUrl, formData).then(res =>{
                console.log(res);
                if(res.data.code == 0) {
                    this.unit.content.url = res.data.data
                }
            })
        }
    },
};
</script>
<style lang="less" scoped>

.answer-image {
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    .answer-image-el-upload:hover {
        .handle-box {
            display: flex;
        }
    }
    .answer-image-el-upload {
        margin: 0 auto;
        position: relative;
        width: 107px;
        border-radius: 5px;
        .handle-box {
            top: 0px;
            position: absolute;
            height: 107px;
            width: 107px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.5);
            display: none;
            justify-content: space-evenly;
            align-items: center;
            i {
                font-size: 22px;
                color: white;
            }
        }
    }
    .avatar-box {
        background: #fafbff;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        overflow: hidden;
        height: 105px;
        width: 105px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .avatar {
        height: 105px;
        width: 105px;
        border-radius: 5px;
    }
    .answer-image-limit {
        margin-top: 18px;
        color: #a9b3c6;
        font-size: 13px;
    }
    .no-url-upload {
        width: 105px;
        height: 105px;
        background: #fafbff;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        text-align: center;
        line-height: 105px;
        font-size: 22px;
        color: #87a9ff;
    }
}
</style>