<template>
    <div id="roleManage">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i
                        ></span>
                        <span>{{$t('common.enterpriseEdition')}} - </span>
                        <span>{{$t('roleManage.title')}}</span>
                    </div>
                    <div
                        class="setup-header-right-l"
                        slot="setup-header-right-l"
                    >
                        <el-button
                            size="small"
                            type="primary"
                            @click="addRole"
                            v-if="operableRole('PORTAL_MANAGEMENT_ROLE_ADD')"
                        >
                            <i class="iconfont guoran-a-16-13"></i>
                            {{$t('roleManage.addRoleText')}}</el-button
                        >
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <div class="content-box">
                        <role-table></role-table>
                    </div>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import Popup from "@/components/popup";
import SideNav from "./../../menu/SideNav.vue";
import TopHeader from "./../../../components/top-header.vue";
import DeleteDialog from "./../../../components/deleteDialogue.vue";
import pagination from "./../../../components/pagination.vue";
import ManangeNav from "./../../menu/ManageNave.vue";
import RoleTable from "./components/roleTableU";
import { operable } from "../js/operable";

export default {
    name: "roleManage",
    data() {
        return {
            roleTableTotal: 20,
            currentPage: 1,
            total: 0,
            pageSize: 20,
            rolePrivilegesList: [],
        };
    },
    components: {
        RoleTable,
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        pagination,
        ManangeNav,
    },
    mounted() {
        this.rolePrivilegesList = this.getRolePrivileges();
    },
    computed: {
        operableRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        addRole() {
            this.$router.push({
                path: "/_addRole",
                query: {
                    type: "add",
                },
            });
        },
        handleSizeChange() {},
        handleCurrentChange() {},
    },
};
</script>

<style scoped lang="less">
@import "../../../assets/less/roleManage/roleManageU";
</style>