<template>
    <div style="height: 100%" id="help">
        <!-- <div class="content-header">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
      </div>
    </div> -->
        <div id="gr-content-container">
            <!-- <iframe width="100%" frameborder="no" border="0" height="100%" src="http://askbot.baklib-free.com/"></iframe> -->
            <iframe
                width="100%"
                frameborder="no"
                border="0"
                height="100%"
                :src="helpSrc"
            ></iframe>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import SideNav from "./../menu/SideNav.vue";
export default {
    name: "help",
    data() {
        return {
            activePageInfo: {
                headerName: "使用帮助",
            },
            helpSrc: "https://showdoc.askbot.cn/web/#/readonly/6?page_id=43",
        };
    },
    components: {
        SideNav,
    },
    mounted() {
        this.helpSrc = this.$route.params.helpSrc;
    },
    methods: {
        //点击li增加样式切换锚点
        changeClass(index, anchor) {
            this.activeClass = index;
            this.anchor = anchor;
            document.querySelector(anchor).scrollIntoView(true);
        },
    },
};
</script>

<style lang="less" scoped>
#gr-content-container {
    position: absolute;
    left: 64px;
    top: 0px;
    width: calc(100vw - 36px);
    height: calc(100vh);
    overflow-x: auto;
    text-align: left;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 10px 0;
        line-height: 30px;
    }
    .help-nav {
        width: 23%;
        max-height: 400px;
        display: flex;
        background-color: #ffffff;
        border-radius: 5px;
        text-align: left;
        .docs-nav {
            list-style: none;
            width: 100%;
            padding: 10px 0px;
            li {
                height: 20px;
                line-height: 20px;
                padding: 8px 20px;
                color: #26abe2;
                text-decoration: none;
                font-size: 16px;
                cursor: pointer;
            }
            .strong {
                font-weight: 700;
                font-size: 16px;
                padding: 15px 0px 15px 10px !important;
                color: #444444;
            }
            .active {
                background-color: #fafcff;
                border-right: 2px solid #366aff;
            }
        }
    }
    .help-content {
        background-color: #ffffff;
        width: 72%;
        height: calc(100vh - 130px);
        border-radius: 5px;
        padding: 20px;
        display: block;
        line-height: 30px;
        font-size: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        float: left;
        h2 {
            font-size: 20px;
            margin: 10px 0px;
            color: #366aff;
            font-weight: bold;
        }
        h3 {
            font-size: 18px;
            margin: 10px 5px;
            color: #366aff;
        }
        h4 {
            font-size: 16px;
            margin: 10px 10px;
            color: #366aff;
        }
        p {
            font-size: 14px;
            line-height: 30px;
            color: #444444;
            margin: 15px 15px;
            text-align: left;
        }
        ul li {
            font-size: 16px !important;
            list-style-type: disc;
            margin: 10px 10px 10px 40px;
            color: #444444;
            text-align: left;
        }
        span {
            color: #999797;
            font-size: 12px;
        }
        .el-image {
            text-align: center;
            padding: 20px 0px;
            display: block;
            margin: 0 auto;
            max-width: 700px;
            min-width: 400px;
        }
        video {
            text-align: center;
            padding: 20px 0px;
            margin: 0 auto;
            width: 800px;
            height: 400px;
        }
    }
}
</style>