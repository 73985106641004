<template>
  <div id="personal-content">
    <div class="content">
      <div class="person-act">
        <div class="person-act-l inner-title">
          <div class="inner-title-content">{{$t('personal.accountInformation')}}</div>
          <div class="person-act-l-content">
            <el-upload class="upload-demo" ref="upload" :action="uploadUrl" :show-file-list="false"
              :on-success="handleAvatarSuccess" :on-error="handleError" :before-upload="beforeAvatarUpload"
              :data="uploadAvatarParams" :with-credentials="false">
              <div class="block avt">
                <el-avatar :size="76" :src="userImage" fit="cover"></el-avatar>
                <span class="camera-m el-icon-camera-solid"></span>
              </div>
            </el-upload>
            <div class="personal-act-lc-cell s-bg-colotr">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.accountRealName')}}</span><span class="personal-act-lc-cell-content">{{
                    formLabelAlign.realName
                }}</span>
              </div>
            </div>
            <div class="personal-act-lc-cell">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.accountName')}}</span><span class="personal-act-lc-cell-content">{{
                    formLabelAlign.accountNumber
                }}</span>
              </div>
            </div>
            <div class="personal-act-lc-cell s-bg-colotr">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.accountRole')}}</span><span class="personal-act-lc-cell-content">{{
                    formLabelAlign.userRole
                }}</span>
              </div>
            </div>
            <div class="personal-act-lc-cell">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.accountPassword')}}</span><span class="
                                            personal-act-lc-cell-content
                                            personal-act-lc-cell-content-pw
                                        ">
                  <span class="pasword-p">······</span>
                  <span @click="editPassword" class="edit-p"><i class="iconfont guoran-a-16-15"></i></span>
                </span>
              </div>
            </div>
            <div class="personal-act-lc-cell s-bg-colotr">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.phone')}}</span><span v-if="
                  !(
                    formLabelAlign.phone == null ||
                    formLabelAlign.phone == ''
                  )
                " class="
                                            personal-act-lc-cell-content
                                            personal-act-lc-cell-content-pw
                                        ">
                  <span class="phone-p">
                    {{ formLabelAlign.phone }}</span><span @click="
                      editPhone(formLabelAlign.phone)
                    " class="edit-p"><i class="iconfont guoran-a-16-15"></i></span></span>

                <span @click="bindPhone" v-if="
                  formLabelAlign.phone == null ||
                  formLabelAlign.phone == ''
                " class="personal-act-lc-cell-content"><span class="phone-bind">{{$t('personal.bindNow')}}</span>
                </span>
              </div>
            </div>
            <div v-if="haveWorkOrderPermission" class="personal-act-lc-cell">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.orderType')}}</span><span class="
                                            personal-act-lc-cell-content
                                            personal-act-lc-cell-content-pw
                                        " v-if="workOrderTypesStr.length != 0">
                  <span class="phone-p-w">
                    <span class="workorder-text">{{
                        workOrderTypesStr
                    }}</span></span><span @click="editWorkorder" class="edit-p"><i
                      class="iconfont guoran-a-16-15"></i></span></span>

                <span @click="editWorkorder" v-if="workOrderTypesStr.length == 0"
                  class="personal-act-lc-cell-content"><span class="phone-bind">{{$t('personal.setNow')}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="
          rolePrivilegesList.indexOf('PORTAL_ENTRANCE_IM') !==
          -1
        " class="person-act-r inner-title">
          <div class="inner-title-content">
            {{ isE() ? 'eChat' : 'AskChat' }}
            {{$t('personal.IM')}}
          </div>
          <div class="person-act-l-content">
            <el-upload class="upload-demo" ref="upload" :action="uploadUrl" :show-file-list="false"
              :on-success="handleAvatarSuccess" :on-error="handleError" :before-upload="beforeAvatarUpload"
              :data="uploadAvatarParams" :with-credentials="false">
              <div class="block avt">
                <el-avatar :size="76" :src="fileList" fit="cover"></el-avatar>
                <span class="camera-m el-icon-camera-solid"></span>
              </div>
            </el-upload>
            <div class="personal-act-lc-cell s-bg-colotr">
              <div class="personal-act-lc-cell-inner">
                <span class="personal-act-lc-cell-name">{{$t('personal.nickname')}}</span><span class="
                                            personal-act-lc-cell-content
                                            personal-act-lc-cell-content-pw
                                        ">
                  <span>{{
                      formLabelAlign.petName
                  }}</span>
                  <span @click="editNickname" class="edit-p"><i class="iconfont guoran-a-16-15"></i></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="person-act-bind inner-title">
        <div class="inner-title-content">{{$t('personal.accountBinding')}}</div>
        <account-bind @changeDialog="changeDialog"></account-bind>
      </div>
    </div>
    <EditPhone @getUserInfo="getUserInfo" ref="editPhone" @changeDialog="changeDialog"></EditPhone>
    <EditPassword ref="editPassword" @changeDialog="changeDialog"></EditPassword>
    <EditNick @saveCustomerInfo="saveCustomerInfo" ref="editNick" @changeDialog="changeDialog"></EditNick>
    <popup v-if="editWorkDialog" @closeEvent="editWorkDialog = false">
      <div slot="popup-name" class="popup-name">{{$t('personal.proficientInWorkOrderTypes')}}</div>
      <div slot="popup-con">
        <div v-for="(cellTree, cellTreeIndex) in workOrderTypeTree" :key="cellTreeIndex">
          <el-tree :data="cellTree" node-key="id" :ref="'ordertree_' + cellTreeIndex" default-expand-all
            :highlight-current="true" :expand-on-click-node="false" show-checkbox
            :default-checked-keys="workOrderDefaultCheckedKeys" :check-on-click-node="true" @check-change="
              (node, val, c_val) =>
                WorkOrderTypeHandleCheckChange(
                  node,
                  val,
                  c_val,
                  cellTreeIndex
                )
            " @node-click="treeNodeClick" check-strictly :props="typeTreeDefaultProps">
          </el-tree>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button class="btn" @click="editWorkDialog = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" class="btn" style="color: #ffffff" @click="saveUserGoodAtWorkOrders">{{$t('common.confirm')}}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import Popup from "@/components/popup";
import accountBind from "./AccountBind.vue";
import EditPassword from "./EditPassword.vue";
import EditPhone from "./EditPhone.vue";
import EditNick from "./EditNick.vue";

export default {
  components: {
    Popup,
    accountBind,
    EditPassword,
    EditPhone,
    EditNick,
  },
  data() {
    return {
      rolePrivilegesList: [],
      labelPosition: "top",
      formLabelAlign: {
        petName: "",
        realName: "",
        phone: null,
        accountNumber: "",
        userRole: "",
        userDelGoodAtWorkOrders: [],
        admin: false,
      },
      fileList: "",
      userImage:'',
      uploadUrl: "/api-open/oss/public",
      uploadAvatarParams: {
        token: "8c98087dfd2d48f856d8c95c09115def",
      },
      uploadImg: "",
      userInfo: {},
      uid: localStorage.getItem("_uid"),
      active: "personal",
      passWord: {
        oldPassword: "",
        newPassword: "",
        againPassword: "",
      },
      OriginalPassword: "", //原始密码
      userSource: 0,
      accountList: [],
      // 擅长工单
      editWorkDialog: false,
      workOrderTypesStr: "",
      // 工单类别
      workOrderType: [],
      workOrderTypeTree: [],
      typeTreeDefaultProps: {
        children: "children",
        label: "workOrderTypeName",
      },
      workOrderSelectIng: false,
      isShowWorkOrder: true,
      workOrderDefaultCheckedKeys: [],
      haveWorkOrderPermission: false,
    };
  },
  mounted() {
    this.getUserInfo();
    // 入口权限
    this.rolePrivilegesList = this.getRolePrivileges();
  },
  filters: {
    realNameAvt: function (value) {
      if (value != null && String(value).length > 0) {
        return value.substring(0, 1);
      }
    },
  },
  methods: {
    editWorkorder() {
      this.editWorkDialog = true;
    },
    saveUserGoodAtWorkOrders() {
      this.saveCustomerInfo("workorder");
    },
    editPassword() {
      this.$refs.editPassword.initShow();
    },
    editPhone(phone) {
      this.$refs.editPhone.initShow({ type: "edit", phone: phone });
    },
    editNickname() {
      this.$refs.editNick.initShow(this.formLabelAlign.petName);
    },
    bindPhone() {
      this.$refs.editPhone.initShow({ type: "bind" });
    },
    saveCustomerInfo(type, val) {
      let userInfo = {
        nickName: this.formLabelAlign.petName,
        realName: this.formLabelAlign.realName,
      };
      if (type === "avt") {
        userInfo.profilePhoto = val;
      }
      if (type === "nickName") {
        userInfo.nickName = val;
      }
      if (type === "workorder") {
        userInfo.admin = this.formLabelAlign.admin;
        userInfo.userGoodAtWorkOrders = this.workOrderType.map(
          (item) => {
            if (this.formLabelAlign.admin) {
              return item;
            } else {
              if (!item.adminAssign) {
                return item;
              }
            }
          }
        );
      }
      this.$http
        .put("/api/account/appoint/" + this.uid, userInfo)
        .then((res) => {
          if (res.data.code === "0") {
            this.$message.success("修改成功！");
            if (type === "nickName" || type === "workorder" ||  type === "avt") {
              this.getUserInfo();
              this.$emit('reload')
              window.top.postMessage({
                data: "reload"
              },"*");
              // window.location.reload()
              // window.parent.location.reload()
              if (type === "workorder") {
                this.editWorkDialog = false;
              }
            }
          }
        });
    },
    treeNodeClick(data, node, el) {
      if (data.disabled) {
        this.$message.warning(
          this.$t('personal.noEditType')
        );
      }
    },
    elDropdownvisibleChange(val) {
      this.workOrderSelectIng = val;
    },
    WorkOrderTypeHandleCheckChange(node, val, c_val, index) {
      if (val) {
        node.treeIndex = index;
        node.adminAssign = this.formLabelAlign.admin;
        node.workOrderTypeId = node.id;
        node.userId = localStorage.getItem("_uid");
        this.workOrderType.push(node);
      } else {
        this.workOrderType.forEach((item, index) => {
          if (item.id == node.id) {
            this.workOrderType.splice(index, 1);
          }
        });
      }
      this.workOrderType = [...this.workOrderType];
    },
    // 上传头像
    handleAvatarSuccess(res, file) {
      if (res !== "") {
        this.uploadImg = res;
      }
      this.fileList = URL.createObjectURL(file.raw);
      this.saveCustomerInfo("avt", res);
    },
    handleError() {
      console.log("图片上传失败");
    },
    beforeAvatarUpload(file) {
      const isSupportedFormat =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg";
      const isLte2M = file.size / 1024 / 1024 <= 2;

      if (!isSupportedFormat) {
        this.$message.error(
            this.$t('personal.avatarType')
        );
      }
      if (!isLte2M) {
        this.$message.error(this.$t('personal.avatarSize'));
      }
      return isSupportedFormat && isLte2M;
    },
    fltDisabled(list) {
      let adminSetIds = this.workOrderType.map((item) => {
        if (item.adminAssign) {
          return item.id;
        }
      });
      let tampArr = list;
      const fltTree = (list) => {
        list.forEach((item) => {
          if (adminSetIds.indexOf(item.id) == -1) {
            item.disabled = false;
          } else {
            if (!this.formLabelAlign.admin) {
              item.disabled = true;
            }
          }
          if (item.children != null && item.children.length != 0) {
            fltTree(item.children);
          }
        });
      };
      fltTree(tampArr);
      return tampArr;
    },
    //获取用户信息
    getUserInfo() {
      let uid = localStorage.getItem("_uid");
      this.$http.get("/api/account/appoint/" + uid).then((res) => {
        if (res.data.code === "0") {
          this.userInfo = res.data.data;
          //初始化姓名
          if (this.userInfo.realName !== null) {
            this.formLabelAlign.realName = this.userInfo.realName;
          }
          //初始化账户
          this.formLabelAlign.accountNumber = this.userInfo.username;
          //初始化昵称
          if (this.userInfo.nickName !== null) {
            this.formLabelAlign.petName = this.userInfo.nickName;
          }
          //初始化电话
          if (this.userInfo.tel !== null) {
            this.formLabelAlign.phone = this.userInfo.tel;
          }
          //初始化角色
          if (this.userInfo.roles[0] !== null) {
            this.formLabelAlign.userRole = this.userInfo.roles[0];
          }
          //初始化角色是否为管理员
          if (this.userInfo.admin != null) {
            this.formLabelAlign.admin = this.userInfo.admin;
          }
          // 判断是否展示工单类型选择
          if (this.userInfo.haveWorkOrderPermission != null) {
            this.haveWorkOrderPermission =
              this.userInfo.haveWorkOrderPermission;
          } else {
            this.haveWorkOrderPermission = false;
          }
          //初始化角色
          if (this.userInfo.userGoodAtWorkOrders != null) {
            this.workOrderType = this.userInfo.userGoodAtWorkOrders;
            this.workOrderDefaultCheckedKeys =
              this.workOrderType.map((item) => item.id);

            this.workOrderTypesStr = "";
            this.userInfo.userGoodAtWorkOrders.forEach(
              (item, index) => {
                if (index === 0) {
                  this.workOrderTypesStr +=
                    item.workOrderTypeName;
                } else {
                  this.workOrderTypesStr +=
                    " / " + item.workOrderTypeName;
                }
              }
            );
          }
          if (this.userInfo.workOrderTypes != null) {
            this.workOrderTypeTree =
              this.userInfo.workOrderTypes.map((item, index) => {
                item.treeIndex = index;
                return [item];
              });
            this.workOrderTypeTree.forEach((item) => {
              item = this.fltDisabled(item);
            });
          }

          //初始化来源
          if (this.userInfo.source !== null) {
            this.userSource = this.userInfo.source;
          } else {
            let roles = localStorage.getItem("roleId");
            let rolesName = "";
            if (roles === "0") {
              rolesName = this.$t('personal.systemTest');
            }
            if (roles === "1") {
              rolesName = this.$t('personal.superAdministrator');
            }
            if (roles === "2") {
              rolesName = this.$t('personal.botAdministrator');
            }
            if (roles === "3") {
              rolesName = this.$t('personal.botConfigurator');
            }
            if (roles === "4") {
              rolesName = this.$t('personal.customerService');
            }
            if (roles === "5") {
              rolesName = this.$t('personal.orderAdministrator');
            }
            if (roles === "6") {
              rolesName = this.$t('personal.orderMembers');
            }
            this.formLabelAlign.userRole = rolesName;
          }
          //初始化头像
          this.fileList = this.userInfo.profilePhoto
            ? this.userInfo.profilePhoto
            : "https://static.guoranbot.com/cdn-office-website/images/default_a_avt.png";
          this.uploadImg = this.userInfo.profilePhoto
            ? this.userInfo.profilePhoto
            : "https://static.guoranbot.com/cdn-office-website/images/default_a_avt.png";
          this.userImage = this.userInfo.profilePhoto
            ? this.userInfo.profilePhoto 
            : 'https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png'

        }
      });
    },
    changeDialog(flag){
      window.top.postMessage({
        data:"MASK"
      },"*");
    }
  },
  watch:{
    editWorkDialog(value){
      this.changeDialog(value)
    }
  }
}
</script>

<style lang="less" scoped>
#personal-content {
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .inner-title-content {
      padding: 23px 25px;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
    }

    .person-act {
      height: 432px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .person-act-l {
        margin-bottom: 20px;
        flex: 1;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;

        .person-act-l-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .avt {
            width: 76px;
            height: 76px;
            line-height: 76px;
            text-align: center;
            background: #366AFF;
            color: white;
            font-size: 33px;
            border-radius: 50%;
            margin-bottom: 10px;
          }

          .personal-act-lc-cell {
            height: 42px;
            line-height: 42px;
            width: calc(100% - 32px);
            display: flex;

            .personal-act-lc-cell-inner {
              margin: 0 auto;
              width: 400px;
              display: flex;
              justify-content: flex-start;

              .personal-act-lc-cell-name {
                width: 150px;
                flex: none;
                font-weight: 400;
                text-align: left;
              }

              .personal-act-lc-cell-content {
                flex: auto;
                overflow: hidden;
                font-weight: bold;
                text-align: left;
                .phone-bind {
                  color: #366AFF;
                  font-weight: 400;
                  cursor: pointer;
                }
              }

              .personal-act-lc-cell-content-pw {
                display: flex;
                align-items: center;

                .pasword-p {
                  width: 100px;
                  font-size: 24px;
                  letter-spacing: 2px;
                }

                .phone-p {
                  width: 100px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;

                  .workorder-text {
                    min-width: 200px;
                    display: inline-block;
                    line-height: 45px;
                    height: 45px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .phone-p-w {
                  width: 200px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;

                  .workorder-text {
                    min-width: 160px;
                    display: inline-block;
                    line-height: 45px;
                    height: 45px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .edit-p {
                  margin-left: 28px;
                  min-width: 28px;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  color: white;
                  text-align: center;
                  background: #366AFF;
                  border-radius: 50%;
                  font-weight: 300;
                  cursor: pointer;

                  i {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .s-bg-colotr {
            background: #FBFCFD;
          }
        }
      }

      .person-act-r {
        flex: 1;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        margin-left: 16px;
        background-image: url('https://static.guoranbot.com/cdn-office-website/images/gl_top_r_bg.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left bottom;

        .person-act-l-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .avt {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            margin-bottom: 10px;
          }

          .personal-act-lc-cell {
            height: 42px;
            line-height: 42px;
            width: calc(100% - 32px);
            display: flex;

            .personal-act-lc-cell-inner {
              margin: 0 auto;
              width: 360px;
              display: flex;
              justify-content: flex-start;
              overflow: hidden;

              .personal-act-lc-cell-name {
                width: 90px;
                flex: none;
                font-weight: 400;
                text-align: left;
              }

              .personal-act-lc-cell-content {
                flex: auto;
                overflow: hidden;
                font-weight: bold;

                .phone-bind {
                  color: #366AFF;
                  font-weight: 400;
                  cursor: pointer;
                }
              }

              .personal-act-lc-cell-content-pw {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .edit-p {
                  margin-left: 28px;
                  min-width: 28px;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  color: white;
                  text-align: center;
                  background: #366AFF;
                  border-radius: 50%;
                  font-weight: 300;
                  cursor: pointer;

                  i {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .s-bg-colotr {
            background: #FBFCFD;
          }
        }
      }
    }

    .person-act-bind {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
      border-radius: 5px;
      margin-top: 16px;
      min-height: 200px;
    }
  }
  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    z-index:999;
  }
}
</style>