<template>
    <div id="notice">
        <div class="gr-content-container">
            <div class="personalHeader">
                <detail-header @goBack="goBack">
                    <div slot="header-name" class="header-name">
                        <span class="template-name">{{$t('notice.back')}}</span>
                    </div>
                </detail-header>
            </div>
            <div class="add-notice-content">
                <div class="add-notice-content-c">
                    <NoticeList
                        ref="noticeList"
                        @getTableTotal="getTableTotal"
                        :editPageble="1"
                        :pageNo="currentPage"
                        :pageSize="pageSize"
                    />
                </div>

                <div class="footer-pagination">
                    <pagination
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :total="tableTotal"
                        @currentChange="handleCurrentChange"
                    ></pagination>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
import SideNav from "./../menu/SideNav.vue";
import DetailHeader from "./../../components/detail-header";
import pagination from "@/components/pagination.vue";
import NoticeList from "@/views/index/components/NoticeList";

export default {
    name: "addnotice",
    data() {
        return {
            pageSize: 20,
            currentPage: 1,
            tableTotal: 0,
            hideOnSinglePage: false
        };
    },
    components: {
        SideNav,
        NoticeList,
        pagination,
        DetailHeader,
    },
    mounted() {
        // 入口权限
    },
    filters: {},
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getTableTotal(param) {
            this.tableTotal = param.total;
        },
        handleCurrentChange(val) {
            console.log(val);
            this.currentPage = val;
            this.$refs.noticeList.getList(this.currentPage, this.pageSize);
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/version/notice.less";
</style>