<template>
    <div class="forget-password">
        <div class="register">
            <div :class="[isE()?'gr-register-left-ebot':'gr-register-left']"></div>
            <div class="gr-register-right">
                <div class="gr-register-right-c">
                    <div :class="[isE()?'gr-register-logo-ebot':'gr-register-logo']"></div>
                    <div class="gr-register-title">{{isE()?'欢迎注册eBot':'欢迎注册AskBot'}}</div>
                    <div class="gr-register-forms" id="form">
                        <div class="form-item">
                            <div class="label">手机号码</div>
                            <el-input
                                v-number-only
                                id="inputPhone"
                                v-model="phone"
                                @input="changephone"
                                placeholder="请输入您的手机号"
                            />
                            <div class="gr-tips bgc-gr-tips" v-if="phoneTips !== ''">{{ phoneTips }}</div>
                        </div>
                        <!-- <div class="form-item">
                            <div class="label">验证方式</div>
                            <el-select
                                @change="changeVerifyType"
                                v-model="verify"
                                placeholder="请选择验证方式"
                            >
                                <el-option
                                    v-for="item in verifyOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <div class="gr-tips">{{ verifyTips }}</div>
                        </div> -->
                        <div class="form-item mark">
                            <div class="label">验证码</div>
                            <div class="form-input-cell mark">
                                <el-input
                                    id="inputCheckCode"
                                    type="text"
                                    placeholder="请输入验证码"
                                    v-model="checkCode"
                                    autocomplete="new-password"
                                    :readonly="readonlyStatus ? false : 'readonly'"
                                    @input="changeCheckCode"
                                />
                                <span
                                    @click="getCheckcode"
                                    v-if="!verifyIng"
                                    class="varify-btn"
                                    >{{ verifyName }}</span
                                >
                                <span v-if="verifyIng" class="varify-time"
                                    >{{ verifyTime }} S</span
                                >
                            </div>
                            <div class="gr-tips bgc-gr-tips" v-if="checkCodeTips !== ''">{{ checkCodeTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <span>密码</span>
                                <span class="tips"> <i class="guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe iconfont"></i> 必需包含字母、数字且超过8位</span>
                            </div>
                            <el-input
                                type="password"
                                id="inputPassword"
                                v-model.trim="newPassword"
                                :onkeyup="
                                    (newPassword = newPassword.replace(
                                        /\s+/g,
                                        ''
                                    ))
                                "
                                @blur="changeNewPassword"
                                placeholder="请输入密码"
                            />
                            <div class="gr-tips bgc-gr-tips" v-if="newPasswordTips !== ''">{{ newPasswordTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">确认密码</div>
                            <el-input
                                type="password"
                                v-model.trim="repeatNewPassword"
                                :onkeyup="
                                    (repeatNewPassword =
                                        repeatNewPassword.replace(/\s+/g, ''))
                                "
                                @blur="changeRepeatNewPassword"
                                id="inputpasswordAgain"
                                placeholder="请再次输入密码"
                            />
                            <div class="gr-tips bgc-gr-tips" v-if="repeatNewPasswordTips !== ''">
                                {{ repeatNewPasswordTips }}
                            </div>
                        </div>
                        <div class="form-item gr-register-btn">
                            <button :class="[disabledRegister?'disabled-btn':'']" :disabled="disabledRegister" @click="register" id="getBackNow">
                                注册并登录
                            </button>
                        </div>
                        <p class="exit-acc">
                            已有账号，去
                            <a
                                :href="loginUrl + '/login.html'"
                                class="loginA"
                                style="text-decoration: none"
                                >登录</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";
console.log(redirectUri);
export default {
    data() {
        return {
            disabledRegister: false,
            readonlyStatus: false,
            verifyTime: 60,
            verifyName: "获取验证码",
            phone: "",
            phoneTips: "",
            checkCode: "",
            checkCodeTips: "",
            newPassword: "",
            newPasswordTips: "",
            repeatNewPassword: "",
            repeatNewPasswordTips: "",
            verifyIng: false,
            currentIp: "",
            loginUrl: redirectUri.sso,
            
        };
    },
    computed: {},
    mounted() {
        this.getCurrentIp();
        let mainId = "14676a0cb2ce11ea8f0900163e0964d5";
        if (window.location.host.indexOf("test") != -1) {
            mainId = "6cdfcac6a7d611eabdcd506b4b2f3acc";
        }
        localStorage.removeItem("has_companyName");

        if (window.location.hostname.includes('ebot.isheely.com')) {
            this.loginUrl = 'https://signin.ebot.isheely.com'
        }
        // if (
        //     this.$route.query.source === "official_website" &&
        //     this.$route.query.tel
        // ) {
        //     this.phone = this.$route.query.tel.trim();
        //     this.$http_ignore_auth
        //         .post(
        //             "/scrm-api/custom-clue?updateSort=true",
        //             {
        //                 source: "UNKNOW",
        //                 name: "官网推送",
        //                 mobile: this.phone,
        //             },
        //             { headers: { uid: "0", mainId: mainId } }
        //         )
        //         .then((res) => {});
        // }
        this.$nextTick(() => {
            setTimeout(() => {
                this.readonlyStatus = true;
            }, 800)
        })
    },
    directives: {
        numberOnly: {
            bind(el) {
                el.handler = function () {
                    el.value = el.value.replace(/\D+/, "");
                    if (el.value.length > 11) {
                        el.value = el.value.substring(0, 11);
                    }
                };
                el.addEventListener("input", el.handler);
            },
            unbind(el) {
                el.removeEventListener("input", el.handler);
            },
        },
    },
    methods: {
        changephone(val) {
            if (this.phone.trim() !== "") {
                this.phoneTips = "";
            }
        },

        changeNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            // let reg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/
            if (this.newPassword === '') {
                this.newPasswordTips = "请输入密码";
            } else {
                if (this.repeatNewPassword !== '') {
                    this.newPasswordTips = ''
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = "两次输入的密码不一致";
                    } else {
                        // 校验通过
                        if(reg.test(this.newPassword)){
                            this.newPasswordTips = '';
                            this.disabledRegister = false;
                        } else {
                            this.newPasswordTips = "必需包含字母、数字且超过8位";
                            this.disabledRegister = true;
                        }
                        this.repeatNewPasswordTips = '';
                    }
                } else {
                    if(this.newPassword.length < 8){
                        this.newPasswordTips = "密码必需超过8位";
                    } else {
                            // 校验通过
                        if(reg.test(this.newPassword)){
                            this.newPasswordTips = '';
                            this.disabledRegister = false;
                            
                        }else{
                            // 校验不通过
                            this.newPasswordTips = "必需包含字母、数字且超过8位";
                        }
                    }
                }
            }
        },

        changeRepeatNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/
            // let reg = /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/
             if (this.repeatNewPassword === '') {
                this.repeatNewPasswordTips = "请再次输入密码！";
            } else {
                if (this.newPassword !== '') {
                    this.newPasswordTips = ''
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = "两次输入的密码不一致";
                    } else {
                        if(reg.test(this.repeatNewPassword)){
                            this.repeatNewPasswordTips = '';
                            this.disabledRegister = false;
                        } else {
                            this.repeatNewPasswordTips = "必需包含字母、数字且超过8位";
                            this.disabledRegister = true;
                        }
                        this.newPasswordTips = '';
                    }
                } else{
                    if(this.repeatNewPassword.length < 8){
                        this.repeatNewPasswordTips = "密码必需超过8位";
                    } else {
                        // 校验通过
                        if(reg.test(this.repeatNewPassword)){
                            this.repeatNewPasswordTips = '';
                            this.disabledRegister = false;
                        } else{
                            // 校验不通过
                            this.repeatNewPasswordTips = "必需包含字母、数字且超过8位";
                        }
                    }
                }
            }
        },
        
        changeCheckCode() {
            if (this.checkCode.trim() !== "") {
                this.checkCodeTips = "";
            }
        },

        getCurrentIp() {
            this.$http_ignore_auth
                .get("https://www.askbot.cn/ext-api/ip")
                .then((res) => {
                    this.currentIp = res.data.ip;
                });
        },
        register() {
            if (this.disabledRegister) {
                return false;
            }
            if (this.phone.trim() === "") {
                this.phoneTips = "账号不能为空！";
                return false;
            }
            if (
                this.checkCode.trim() === "" ||
                this.checkCode.trim().length !== 6
            ) {
                this.checkCodeTips = "请输入6位验证码！";
                return false;
            }
            if (this.newPassword.trim() === "") {
                this.newPasswordTips = "请输入密码！";
                return false;
            }
            if (this.repeatNewPassword === "") {
                this.repeatNewPasswordTips = "请再次输入密码！";
                return false;
            }
            if (this.newPassword.trim() !== this.repeatNewPassword.trim()) {
                this.repeatNewPasswordTips = "两次输入密码不一致，请确认！";
                return false;
            }
            /*将密码encode*/
            let newPassword = this.newPassword.trim();
            newPassword = encodeURIComponent(newPassword);
            this.disabledRegister = true;
            this.$http_ignore_auth
                .post(
                    "/api/code/sms/check?phone=" +
                        this.phone.trim() +
                        "&code=" +
                        this.checkCode
                )
                .then((res) => {
                    if (res.data.code === "0" && res.data.data) {
                        this.$http_ignore_auth
                            .post(
                                "/api/register?phone=" +
                                    this.phone.trim() +
                                    "&password=" +
                                    newPassword +
                                    "&ip=" +
                                    this.currentIp
                            )
                            .then((res) => {
                                if (res.data.code === "0" && res.data.data) {
                                    window.location.href =
                                        redirectUri.sso +
                                        "/login.html?autoLogin=true&phone=" +
                                        this.phone.trim() +
                                        "&password=" +
                                        window.btoa(
                                            window.btoa(this.newPassword.trim())
                                        ) + "&username=" + this.phone.trim();
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            });

                        this.$http_ignore_auth
                            .post(
                                "/bearing-api//internal-work-order/base/clue/14676a0cb2ce11ea8f0900163e0964d5?updateSort=true",
                                {
                                    timestamp: 1648638326953,
                                    token: "B186BDFF97B6A39115785229D60CB6D0D1EF2E9EAA7C7B7AE82360119343F744D27AD3496DA418A210CEE7CB5C656AE2AE86182033742649A629D184C4752EEC4296187A84D91045AD860C4B847EAD681243F7555DDBF8FE00B3D23E0D6F9176938A4F39865EA18439AD92059EF42235",
                                    mobile: this.phone,
                                    name: "",
                                    companyName: "",
                                    description: "官网注册",
                                    ip: this.currentIp,
                                    source: "官网注册",
                                }
                            )
                            .then((res) => {});
                    } else {
                        this.checkCodeTips = "验证码输入有误，请确认后重试！";
                        this.disabledRegister = false;
                        return false;
                    }
                    this.disabledRegister = false;
                });
        },
        getCheckcode() {
            if (this.phone.trim() === "") {
                this.phoneTips = "手机号不能为空！";
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.phone.trim())) {
                    this.phoneTips = "请检查手机号码是否正确";
                    return false;
                }
            }
            this.$http_ignore_auth
                .get("/api/register/check?phone=" + this.phone.trim())
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.phoneTips = "手机号已经存在";
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = "重新获取";
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
    },
};
</script>
<style lang="less" scoped>
.register {
    width: 100%;
    display: flex;
    height: 100vh;
    background-color: white;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

.exit-acc {
    margin-top: -32px;
    text-align: left;
    a {
        color: #3682ff;
    }
}

.gr-tips {
    color: #f56c6c;
    text-align: right;
    font-size: 11px;
}
.bgc-gr-tips{
    color: #FE5965;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 3px;
    height: 18px;
    background: #FFEBEC;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    

@media screen and (max-width: 960px) {
    .gr-register-left {
        display: none;
    }

    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.gr-register-left {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-left-ebot {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-right {
    width: 38vw;
    min-width: 556px;
    height: 370px;
    margin: auto 0;
}

.gr-register-right-c {
    margin-top: -100px;
}

.gr-register-logo {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-logo-ebot {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-title {
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
    height: 14px;
}

.gr-register-forms {
    max-width: 60%;
    min-width: 342px;
    margin: 30px auto 0;
}

.gr-register-forms .form-item {
    width: 100%;
    height: 82px;
    .el-select {
        width: 100%;
        input {
            width: 100%;
        }
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #9093997d;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}

/deep/.gr-register-forms .form-item input {
    // width: calc(100% - 30px);
    outline-style: none;
    font-size: 14px;
    height: 38px;
}
/deep/.gr-register-forms .mark .el-input__inner{
    border-radius: 4px 0 0 4px;
}

.gr-register-forms .form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .tips,.iconfont{
        font-size: 12px;
        color: #A9B3C6;
    }
}

input::-webkit-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input::-moz-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:-ms-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:focus {
    border-color: #366aff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
}

.gr-register-btn {
    padding-top: 18px;
}

.gr-register-btn button {
    width: 100%;
    height: 38px;
    background: #3682ff;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.gr-register-btn button:hover {
    background-color: #366aff;
}

.gr-register-login {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.disabled-btn {
    cursor: not-allowed !important;
    opacity: 0.8;
}
</style>