<template>
  <div class="dataSet">
    <div class="enterpriseKnowledge">
      <div class="title">
        <span>{{$t('botLink.knowledgeRange')}}</span>
        <div class="addConditionBtn" @click="addCondition">{{$t('botLink.addCondition')}}</div>
      </div>
      <div class="conditionList">
        <div class="conditionItem" v-for="(items,index) in dataSetCondition" :key="index">
          <div class="and-item" >
            <div class="select-type">
              <el-select
                  v-model="items.variable"
                  :placeholder="$t('common.selectPlaceholder')"
                  @change="changeVariable($event,items)"
              >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="$t('common.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="select-condition" v-if="items.variable !== 'ALL_KNOWLEDGE'">
              <el-select
                  v-model="items.relation"
                  :placeholder="$t('common.selectPlaceholder')"
                  @change="changeRelation"
              >
                <el-option
                    v-for="item in conditionOptions"
                    :key="item.value"
                    :label="$t('common.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="input-value" v-if="items.variable !== 'ALL_KNOWLEDGE'">
              <template  v-if="items.variable === 'SPECIFY_KNOWLEDGE_LABELS'">
                <el-select
                    v-model="items.extInfo.values"
                    :multiple="true"
                    ref="seleteTree"
                    :placeholder="$t('common.selectPlaceholder')"
                    :collapse-tags="true"
                    :popper-append-to-body="false"
                    style="width: 100%"
                    value-key="_id"
                    @remove-tag="removeTag($event,items)"
                >
                  <el-option :label="selectLabel" :value="selectValue" class="setStyle" disabled>
                    <el-tree
                        :data="tagList"
                        ref="tree"
                        node-key="_id"
                        check-strictly
                        :expand-on-click-node="false"
                        check-on-click-node
                        @node-click="(data,node) => {
                          nodeClick(data,node,items,'tag')
                        }"
                        :default-expanded-keys="defaultExpandedCids"
                        :load="loadTagNode"
                        lazy
                        :props="{id:'_id', children: 'children',label: 'name',isLeaf:'isLeaf'}"
                    >
                      <div class="custom-tree-node"
                           slot-scope="{ data }"
                      >
                        <div class="node-left">
                          <span>{{ data.name }}</span>
                        </div>
                        <span class="node-right">
                        <i class="iconfont guoran-tongyichicun-kaiqi1" v-if="isChecked(items.value,data.id)"></i>
                      </span>
                      </div>
                    </el-tree>
                  </el-option>
                </el-select>
              </template>
              <template v-else-if="items.variable === 'SPECIFY_KNOWLEDGE'">
                <el-select
                    v-model="items.extInfo.values"
                    :multiple="true"
                    ref="seleteTree"
                    :placeholder="$t('common.selectPlaceholder')"
                    :collapse-tags="true"
                    :popper-append-to-body="false"
                    style="width: 100%"
                    value-key="id"
                    @remove-tag="removeTag($event,items)"

                >
                  <el-option :label="selectLabel" :value="selectValue" class="setStyle" disabled>
                    <el-tree
                        :data="knowledgeList"
                        ref="knowledgeTree"
                        node-key="id"
                        check-strictly
                        :expand-on-click-node="false"
                        check-on-click-node
                        @node-click="(data,node) => {
                          nodeClick(data,node,items)
                        }"
                        :default-expanded-keys="defaultExpandedKnowledgeCids"
                        :load="loadNode"
                        lazy
                        :props="{id:'id', children: 'children',label: 'name',isLeaf:'isLeaf'}"
                    >
                      <div class="custom-tree-node" slot-scope="{ data }">
                        <div class="node-left" :class="data.dataType === 'hasNext' ?'loadMore':''">
                          <span>{{ data.name }}</span>
                        </div>
                        <span class="node-right">
                        <i class="iconfont guoran-tongyichicun-kaiqi1" v-if="isChecked(items.value,data.id)"></i>
                      </span>
                      </div>
                    </el-tree>
                  </el-option>
                </el-select>
              </template>
            </div>
          </div>
          <i class="iconfont guoran-a-16-09" @click="removeCondition(index)"></i>
          <div class="and-box" v-if="index !== dataSetCondition.length - 1">{{$t('botLink.or')}}</div>
        </div>
      </div>
      <div class="addCondition" v-if="false">
        <div class="addBtn" @click="addCondition">
          <i class="iconfont guoran-a-18-35"></i>
        </div>
        <span @click="addCondition" style="cursor: pointer">{{$t('botLink.addCondition')}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "dataSet",
  components: { },
  data(){
    return{
      options:[
        {
          value:"SPECIFY_KNOWLEDGE",
          label:"指定知识"
        },
        // {
        //   value:"SPECIFY_KNOWLEDGE_LABELS",
        //   label:"指定知识标签"
        // },
        {
          value:"ALL_KNOWLEDGE",
          label:"全部知识"
        }
      ],
      conditionOptions:[
        {
          label:"包含任意",
          value:"CONTAIN_ANY"
        },
        {
          label:"不包含任意",
          value:"NOT_CONTAIN_ANY"
        }
      ],
      dataSetCondition:[
        {
          variable:"ALL_KNOWLEDGE",
          relation:"",
          value:[],
          extInfo:{
            values:[]
          }
        },
      ],
      selectLabel:"",
      selectValue:[],
      knowledgeList:[],
      tagList:[],
      defaultExpandedCids:[],
      folderId:"",
      defaultExpandedKnowledgeCids:[],
      currentPage:{}
    }
  },
  methods:{
    addCondition(){
      this.dataSetCondition.push({
        variable:"ALL_KNOWLEDGE",
        relation:"",
        value:[],
        extInfo:{
          values:[]
        }
      })
      this.$emit('saveKnowledgeScope')
    },
    removeCondition(index){
      this.dataSetCondition.splice(index,1)
      this.$emit('saveKnowledgeScope')
    },
    getKnowledgeList(callback){
      let url = this.requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      let corpId = localStorage.getItem('_corpId');
      let mainId = localStorage.getItem('_mainId');
      url += '?mainId=' + mainId + '&corpId=' + corpId  + '&parentId=' + "-1";
      this.$http.get(url).then(res => {
        console.log('res',res)
        if (res.data.code == 0){
          this.folderId = res.data.data && res.data.data[0].id;
          console.log('this.folderId',this.folderId)
          if (callback){
            callback()
          }
        }
      })
    },
    loadNode(node, resolve) {
      let treeData =  node.data || {};
      let url = this.requestUrl.knowledgeBase.getFolderAndFile;
      let corpId = localStorage.getItem('_corpId');
      let mainId = localStorage.getItem('_mainId');
      if (!this.currentPage[treeData.id]){
        this.currentPage[treeData.id] = 1;
      }
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&page=' + this.currentPage[treeData.id] + '&size=20'
      if (node.level === 0) {
        this.getKnowledgeList(() => {
          this.defaultExpandedCids = []
          url += '&folderId=' + this.folderId;
          console.log('this.folderId2',this.folderId)
          this.$http.get(url).then(res => {
            console.log('res',res)
            if (res.data.code == 0 && res.data.data){
              let data = res.data.data.list;
              data.forEach(item => {
                if (item.type === 1){
                  item.isLeaf = true;
                }
              })
              if (res.data.data.hasNextPage){
                let info = {
                  name: "加载更多",
                  dataType: 'hasNext',
                  id: this.folderId + 'hasNext',
                  parentId: this.folderId,
                  currentPage: res.data.data.nextPage ? res.data.data.nextPage : 1,
                  isLeaf:true
                }
                data = data.concat([info])
              }
              console.log('data',data)
              this.knowledgeList = data;
              resolve(data)
            }
          })
        });
      }
      if (node.level >= 1) {
        url += '&folderId=' + treeData.id;
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data.list){
            let data = res.data.data.list;
            data.forEach(item => {
              if (item.type === 1){
                item.isLeaf = true;
              }
            })
            if (res.data.data.hasNextPage){
              let info = {
                name: "加载更多",
                dataType: 'hasNext',
                id: treeData.id + 'hasNext',
                parentId: treeData.id,
                currentPage: res.data.data.nextPage ? res.data.data.nextPage : 1,
                isLeaf:true
              }
              data = data.concat([info])
            }
            treeData.children = data;
            resolve(data);
          }
        })
      }
    },
    nodeClick(data,node,items,type){
      console.log(items,data,node)
      if (data.dataType === "hasNext") {
        /* 点击加载更多 */
        const parent = node.parent;
        const currentPageId = parent.data.id ? parent.data.id : this.folderId
        this.currentPage[currentPageId] = data.currentPage;
        this.loadKnowledge(data,node);
      } else {
        if (type === 'tag'){
          items.value = items.value.map(item => Number(item));
          if (items.value.indexOf(data._id) == -1){
            items.value.push(data._id);
            items.extInfo.values.unshift(data.name);
          } else {
            let index = items.value.indexOf(data._id);
            items.value.splice(index,1);
            let indexReversed = items.extInfo.values.length - 1 - index
            items.extInfo.values.splice(indexReversed,1)
          }
        } else {
          items.value = items.value.map(item => Number(item));
          if (items.value.indexOf(data.id) == -1){
            items.value.push(data.id);
            items.extInfo.values.unshift(data.name);
          } else {
            let index = items.value.indexOf(data.id);
            items.value.splice(index,1);
            let indexReversed = items.extInfo.values.length - 1 - index
            items.extInfo.values.splice(indexReversed,1)
          }
        }
        this.$emit('saveKnowledgeScope')
      }
    },
    loadKnowledge(datas,node){
      let url = this.requestUrl.knowledgeBase.getFolderAndFile;
      let corpId = localStorage.getItem('_corpId');
      let mainId = localStorage.getItem('_mainId');
      const parent = node.parent;
      const currentPageId = parent.data.id ? parent.data.id : this.folderId;
      console.log('currentPageId',currentPageId)
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&page=' + this.currentPage[currentPageId] + '&size=20';
      url += '&folderId=' + datas.parentId;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data.list){
          let data = res.data.data.list;
          data.forEach(item => {
            if (item.type === 1){
              item.isLeaf = true;
            }
          })
          if (res.data.data.hasNextPage){
            let info = {
              name: "加载更多",
              dataType: 'hasNext',
              id: datas.parentId + 'hasNext',
              parentId: datas.parentId,
              currentPage: res.data.data.nextPage ? res.data.data.nextPage : 1,
              isLeaf:true
            }
            data = data.concat([info])
          }
          const children = parent.data.children || parent.data;
          const index = children.findIndex(d => d.id === datas.id);
          children.splice(index, 1);
          console.log('index',index)
          parent.childNodes.splice(index,1)
          if (datas.parentId == this.folderId){
            this.knowledgeList = this.knowledgeList.concat(data)
          } else {
            node.parent.data.children = node.parent.data.children.concat(data);
            console.log('nodes',node)
          }
        }
      })
    },
    loadTagNode(node, resolve) {
      let treeData =  node.data || {};
      if (node.level === 0) {
        this.defaultExpandedCids = []
        this.treeData = node;
        this.resolve = resolve;
        let url = this.requestUrl.knowledgeBase.tagTree;
        let corpId = localStorage.getItem('_corpId');
        let mainId = localStorage.getItem('_mainId');
        let uid = localStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + "-1";
        this.$http.get(url).then(res => {
          if (res.data.code == 0){
            let data = res.data.data;
            console.log('loadTagNode',data)
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true
              }
            })
            if (data.length <= 6){
              data.forEach(item => {
                if (item.hasChildren){
                  this.defaultExpandedCids.push(item._id)
                }
              })
            }
            this.tagList = data;
            resolve(data)
          }
        })
      }
      if (node.level >= 1) {
        let url = this.requestUrl.knowledgeBase.tagTree
        let corpId = localStorage.getItem('_corpId');
        let mainId = localStorage.getItem('_mainId');
        let uid = localStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + (treeData._id ? treeData._id : "-1");
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data;
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true
              }
            })
            resolve(data);
          }
        })
      }
    },
    changeVariable(value,items){
      items.variable = "";
      items.variable = value;
      items.relation = "CONTAIN_ANY";
      let extInfo = {
        values:[]
      }
      this.$set(items,'extInfo',extInfo)
      this.$set(items,'value',[])
      this.$emit('saveKnowledgeScope')
    },
    changeRelation(){
      this.$emit('saveKnowledgeScope')
    },
    removeTag(e,items){
      let index = items.extInfo.values.indexOf(e);
      items.value.splice(index,1);
      this.$emit('saveKnowledgeScope')
    },
    isChecked(values,id){
      let bool = false;
      let numberArray = values.map(item => Number(item));
      if (numberArray.indexOf(id) !== -1){
        bool =true;
      }
      return bool
    }
  },
  mounted() {
    // this.getKnowledgeList();
  }
};
</script>

<style scoped lang="less">
.dataSet{
  .enterpriseKnowledge{
    .title{
      padding-bottom: 10px;
      color: #616161;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .addConditionBtn{
        color: #366aff;
        cursor:pointer;
      }
    }
    .conditionList{
    }
    .addCondition{
      display: flex;
      align-items: center;
      margin-top: 20px;
      .addBtn{
        flex: none;
        width: 24px;
        height: 24px;
        background: #366AFF;
        border: 1px solid #366AFF;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    .conditionItem{
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      i{
        color: #366aff;
        margin-left: 10px;
        cursor:pointer;
      }
      .and-item{
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        background-color: #ffffff;
        width: 100%;
        /deep/.el-input__inner{
          border: none;
          padding-right: 0;
        }
        /deep/.el-input__suffix{
          display: none;
        }
        .select-type{
          flex: none;
          width: 100px;
        }
        .select-condition{
          flex: none;
          width: 120px;
        }
        .input-value{
          flex: auto;
          /deep/.el-input__icon{
            display: none;
          }
          /deep/.el-date-editor--daterange.el-input__inner{
            width: 320px;
          }
          .setStyle {
            min-height: 250px;
            padding: 0 !important;
            margin: 0;
            //overflow-y: auto;
            cursor: default !important;
            background-color: #ffffff;
            overflow: hidden;
          }
          /deep/.el-tree{
            height: 250px;
            overflow-y: scroll;
          }
          .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 10px);
            padding-right: 10px;
            .node-left{
              display: flex;
              align-items: center;
              .guoran-AI{
                color: #366aff;
                margin-right: 6px;
                font-size: 15px;
              }
            }
            .loadMore{
              display: flex;
              align-items: center;
              justify-content: center;
              color: #366aff;
              cursor:pointer;
              width: 100%;
              line-height: 20px;
            }
            .node-right{
              flex: none;
              width: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                font-size: 14px;
                color: #366aff;
              }
            }
          }
          /deep/.el-tree-node__content{
            height: 34px;
          }
          /deep/.el-select-dropdown__item{
            height: 40px;
            line-height: 40px;
          }
          .el-select-dropdown__item.hover{
            background-color: #ffffff;
          }
        }
      }
    }
    .and-box{
      position: absolute;
      left: 0px;
      top: 36px;
      width: 18px;
      height: 18px;
      background: #366AFF;
      font-size: 12px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      color: #ffffff;
      z-index: 99;
    }
  }
  .myKnowledge{
    padding-left: 20px;
    padding-top: 20px;
    .title{
      padding-bottom: 10px;
      border-bottom: 1px solid #e0e6f7;
      color: #616161;
    }
    .uploadFile{
      .fileItem{
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e6f7;
        .fileName{
          width: 100%;
          display: flex;
          align-items: center;
          .iconfont{
            width: 20px;
            height: 20px;
            background: #70CDAD;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 12px;
            margin-right: 8px;
          }
        }
        i{
          color: #366aff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>