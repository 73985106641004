import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
// import aoteman from "@ckeditor/ckeditor5-basic-styles/theme/icons/bold.svg";
// import aoteman from '../../assets/image/2.jpg'

// console.log(aoteman.replace('/',''));
export default class BoldToolbarUI extends Plugin {
  init() {
    this._createToolbarButton();
  }

  _createToolbarButton() {
    const editor = this.editor;
    const command = editor.commands.get('fileUpload');
    editor.ui.componentFactory.add('MyFileUpload', (locale) => {
      const view = new ButtonView(locale);
      view.set({
        label: '上传图片或附件',
        tooltip: true,
        withText: true, // 在按钮上展示 label
        // icon:`<svg t="1674962541538" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30473" width="14" height="14"><path d="M81.92 432.64L0 732.672V156.672C0 126.976 24.064 102.4 54.272 102.4h256c14.336 0 27.648 5.632 38.4 15.872l110.08 110.08c2.048 2.048 4.608 3.072 7.68 3.072h385.024c29.696 0 54.272 24.064 54.272 54.272v75.776H176.128c-44.544 0-82.944 28.672-94.208 71.168z m931.328-7.168c-9.728-13.824-26.112-20.992-43.008-20.992H175.616c-24.064 0-45.568 16.384-51.712 39.936L12.288 852.992c-7.68 28.672 8.704 58.368 37.888 66.048 4.608 1.024 9.216 2.048 14.336 2.048h794.112c24.064 0 45.568-16.384 51.712-39.936l111.616-409.088c4.608-16.384 1.024-33.28-8.704-46.592z" p-id="30474"></path></svg>`,
        // icon:'<svg t="1674984294045" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="width: 20px !important;height: 18px !important;" p-id="30878" ><path d="M94.72 444.928L14.848 737.792V175.616C14.848 146.432 38.4 122.88 67.584 122.88H317.44c13.824 0 27.136 5.632 37.376 15.36l107.52 107.52c2.048 2.048 4.608 3.072 7.68 3.072h375.808c29.184 0 52.736 23.552 52.736 52.736v73.728h-711.68c-43.52 0-80.896 28.16-92.16 69.632zM1003.52 437.76c-9.728-13.312-25.6-20.48-41.984-20.48H186.368c-23.552 0-44.544 15.872-50.688 38.912L26.624 855.04c-7.68 28.16 8.704 56.832 36.864 64.512 4.608 1.024 9.216 2.048 13.824 2.048h775.168c23.552 0 44.544-15.872 50.688-38.912l109.056-399.36c4.608-15.872 1.024-32.768-8.704-45.568z" p-id="30879"></path></svg>',
      });

      // console.log(Template);
      //  将按钮的状态关联到命令对应值上
      view.bind("isOn", "isEnabled").to(command, "value", "isEnabled");
      // 点击按钮时触发相应命令
      this.listenTo(view, "execute", () => {
        editor.execute('fileUpload')
      });
      return view;

    })
  }
}
