const redirectUri = {
    sso:  process.env.VUE_APP_CONFIG_ENV === 'production' ? 'https://signin.askbot.cn' : 'https://test.signin.askbot.cn',
    production: {
        staff:'https://open.askbot.cn',
        callback:'https://portal.askbot.cn/',
        appId:"cli_a2611713cc795013",
        authWx: 'https://auth.wx.guoranbot.com',
        component_appid: "wx5cc5391649410ef8",
        // wisdom: "https://wisdom.askbot.cn/lightning-web",
        wisdom: "https://wisdom.askbot.cn",
    },
    development:{
        staff:'https://test.open.askbot.cn',
        callback:'https://portal.test.askbot.cn/',
        appId:"cli_a217d98a6af9900e",
        authWx: 'https://test.auth.wx.guoranbot.com',
        component_appid: "wx716d3c9c26092d55",
        // wisdom: "https://wisdom.test.askbot.cn/lightning-web",
        wisdom: "https://wisdom.test.askbot.cn",
    }
};

function redirect2LoginPage(isAskLightning, token){
    let redirect = window.location.origin + '/#/auth';
    if (isAskLightning) {
        window.location = redirectUri.sso + '?knowledgeToken=' + token + '&redirect_uri=' + encodeURIComponent(redirect)
    } else { 
        window.location = redirectUri.sso + '?redirect_uri=' + encodeURIComponent(redirect);
    }
}

export { redirectUri ,redirect2LoginPage}
