<template>
    <div id="account-bind">
        <el-table
            :data="accountList"
            style="width: 100%"
            :header-cell-style="{
                fontSize: '13px',
                backgroundColor: '#F6F8FD',
                height: '50px',
                color: '#000000',
            }"
            :row-style="{ fontSize: '13px' }"
        >
            <el-table-column prop="type" align="left">
                <template slot="header">
                    <div style="padding-left: 16px">{{$t('personal.accountType')}}</div>
                </template>
                <template scope="scope">
                    <div class="acc-type-box">
                        <img
                            v-if="scope.row.avtUrl !== ''"
                            height="16px"
                            width="16px"
                            :src="scope.row.avtUrl"
                            alt=""
                        />
                        <span style="font-size: 13px">{{
                            scope.row.type
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('personal.detail')">
                <template scope="scope">
                    <div class="detail-info">
                        <div class="detail-avatar" v-if="scope.row.avatar">
                            <el-avatar
                                :size="26"
                                :src="scope.row.avatar"
                            ></el-avatar>
                        </div>
                        <div class="detail-name">
                            {{ scope.row.name ? scope.row.name : "--" }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('personal.bindTime')" align="center">
                <template scope="scope">
                    <span style="font-size: 13px">{{
                        scope.row.createTime
                            ? new Date(scope.row.createTime).Format(
                                  "yyyy-MM-dd hh:mm:ss"
                              )
                            : "--"
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('personal.bindStatus')" align="center">
                <template scope="scope">
                    <div class="bindStatus">
                        <span
                            class="bindStatusBtn"
                            :class="
                                scope.row.isBind
                                    ? 'bindStatusBtn'
                                    : 'no-bindStatusBtn'
                            "
                        >
                        </span>
                        <span style="font-size: 13px">{{
                            scope.row.isBind ? $t('personal.used') : $t('personal.noBind')
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('personal.operation')" width="100" align="center">
                <template slot-scope="scope">
                    <el-tooltip :content="$t('personal.edit')" placement="top">
                      <span
                          v-if="scope.row.isBind && scope.row.type === '容联'"
                          @click="dialogRonglian = true"
                          class="iconfont guoran-tongyichicun-16-15-lianjibianji gr-bind-icon"
                      ></span>
                    </el-tooltip>
                    <el-tooltip :content="$t('personal.unbind')" placement="top">
                        <span
                            v-if="scope.row.isBind"
                            @click="unBind(scope.row.type, scope.row)"
                            :class="scope.row.type === '云之家' ? 'yunzhijia-class':''"
                            class="iconfont guoran-jiechubangding gr-bind-icon"
                        ></span>
                    </el-tooltip>
                    <el-tooltip :content="$t('personal.bindNow')" placement="top">
                        <span
                            v-if="!scope.row.isBind && scope.row.type !== '云之家'"
                            @click="bindNow(scope.row.type, scope.row)"
                            :class="scope.row.type === '云之家' ? 'yunzhijia-class':''"
                            class="iconfont guoran-bangding gr-bind-icon"
                        ></span>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <popup v-if="dialogEmail" @closeEvent="dialogEmail = false">
            <div slot="popup-name" class="popup-name">{{$t('personal.bindEmail')}}</div>
            <div slot="popup-con">
                <el-form label-position="top">
                    <el-form-item>
                        <span slot="label" style="text-align: left">{{$t('personal.email')}}</span>
                        <el-input
                            v-model="emailValue"
                            :placeholder="$t('personal.emailPlaceholder')"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="dialogEmail = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="bindEmail"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
      <popup v-if="dialogRonglian" @closeEvent="dialogRonglian = false">
        <div slot="popup-name" class="popup-name">{{$t('personal.bindRongLian')}}</div>
        <div slot="popup-con">
          <el-form label-position="top">
            <el-form-item>
              <span slot="label" style="text-align: left">
                {{$t('personal.accountName')}}<span style="color: red">*</span>
              </span>
              <el-input
                  v-model="rongLianInfo.name"
                  :placeholder="$t('personal.accountNamePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label" style="text-align: left">
                {{$t('personal.password')}}<span style="color: red">*</span>
              </span>
              <el-input
                  v-model="rongLianInfo.password"
                  :placeholder="$t('personal.passwordPlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label" style="text-align: left">
                {{$t('personal.way')}}<span style="color: red">*</span>
              </span>
              <el-select v-model="rongLianInfo.way" :placeholder="$t('common.selectPlaceholder')" style="width: 100%">
                <el-option
                    v-for="item in wayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <span slot="label" style="text-align: left">
                {{$t('personal.organizationId')}}<span style="color: red">*</span>
              </span>
              <el-select v-model="rongLianInfo.dept" :placeholder="$t('common.selectPlaceholder')" style="width: 100%">
                <el-option
                    v-for="item in deptList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="dialog-footer">
          <el-button class="btn" @click="dialogRonglian = false"
          >{{$t('common.cancel')}}</el-button
          >
          <el-button
              type="primary"
              class="btn"
              style="color: #ffffff"
              @click="bindRongLian"
          >{{$t('common.bind')}}</el-button
          >
        </div>
      </popup>
    </div>
</template>

<script>
import { redirectUri } from "./../../../const/redirectUri";
import Popup from "@/components/popup";
import { isMnHr } from '@/views/companyManage/staffManage/utils/customerMainId'

export default {
    name: "accountBind",
    data() {
        return {
            dialogEmail: false,
            emailValue: "",
            accountList: [],
            pweChatOpenInfo: [],
            openInfoMap: {},
            openInfoArr: [],
            newwindowWx: null,
            loopWx: null,
            wxBindCode:'',
            dialogRonglian:false,
            rongLianInfo:{
                name:"",
                password:"",
                way:"Local",
                dept:"HR"
            },
            wayList:[
                {
                    label:this.$t('personal.Local'),
                    value:"Local"
                },
                {
                    label:this.$t('personal.gateway'),
                    value:"gateway"
                },
                {
                    label:this.$t('personal.sip'),
                    value:"sip"
                }
            ],
            deptList:[
              {
                label:this.$t('personal.HR'),
                value:"HR"
              },
              {
                label:this.$t('personal.FD'),
                value:"FD"
              }
            ],
            isMnHr:false
        };
    },
    components: {
        Popup,
    },
    props: [],
    methods: {
        qrcode(url) {
            new this.QRCode("qrcode", {
                width: 200, // 设置宽度，单位像素
                height: 200, // 设置高度，单位像素
                text: url, // 设置二维码内容或跳转地址
            });
        },
        //获取用户的角色
        getBind() {
            this.accountList = [];
            this.$http.get("/api/account/current").then(async (res) => {
                if (res.data.code === "0") {
                    let userInfo = res.data.data;
                    this.pweChatOpenInfo = res.data.data.pweChatOpenInfo;
                    if (res.data.data.pweChatOpenInfo == null) {
                        this.openInfoArr = [];
                        this.openInfoMap = {};
                    } else {
                        if (res.data.data.pweChatOpenInfo.openInfoMap == null) {
                            this.openInfoArr = [];
                            this.openInfoMap = {};
                        } else {
                            this.openInfoArr = [];
                            this.openInfoMap =
                                res.data.data.pweChatOpenInfo.openInfoMap;
                            for (let k in res.data.data.pweChatOpenInfo
                                .openInfoMap) {
                                this.openInfoArr.push(k);
                            }
                        }
                    }
                    await this.$http.get("/api/bind-info/we-work/default-bind-code?mainId=" + localStorage.getItem('_mainId')).then(resloute =>{
                        if(resloute.data.code == 0) {
                            this.wxBindCode = resloute.data.data
                        }
                    })
                    let qiyeinfo = {
                        type: this.$t('personal.eWechat'),
                        name: "",
                        isBind: false,
                        avatar: "",
                        createTime: "",
                        avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_qiyeweixin.png",
                    };
                    let youxianginfo = {
                        type: this.$t('personal.email'),
                        name: "",
                        isBind: false,
                        avatar: "",
                        createTime: "",
                        avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_youxiang.png",
                    };
                    let dingTalkInfo = {
                        type: this.$t('personal.dingDing'),
                        name: "",
                        isBind: false,
                        avatar: "",
                        createTime: "",
                        avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_dingding.png",
                    };
                    let feiShuInfo = {
                      type: this.$t('personal.feiShu'),
                      name: "",
                      isBind: false,
                      avatar: "",
                      createTime: "",
                      avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_feishu.png",
                    }
                    let yunzhijia = {
                      type: this.$t('personal.yunZhiJia'),
                      name: "",
                      isBind: false,
                      avatar: "",
                      createTime: "",
                      avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_yunzhijia.png",
                    }
                    let ronglian = {
                      type:this.$t('personal.rongLian'),
                      name:"",
                      isBind:false,
                      createTime:"",
                      avtUrl: "https://static.guoranbot.com/cdn-office-website/images/ac_type_ronglian.png",
                    }
                    if (userInfo.weWorkQrCodeLoginInfo) {
                        qiyeinfo.name = userInfo.weWorkQrCodeLoginInfo.user_info
                            ? userInfo.weWorkQrCodeLoginInfo.user_info.name
                                ? userInfo.weWorkQrCodeLoginInfo.user_info.name
                                : userInfo.weWorkQrCodeLoginInfo.user_info
                                    .userid
                            : "";
                        qiyeinfo.isBind =
                            userInfo.weWorkQrCodeLoginInfo.user_info &&
                            userInfo.weWorkQrCodeLoginInfo.user_info.userid
                                ? true
                                : false;
                        qiyeinfo.avatar = userInfo.weWorkQrCodeLoginInfo
                            .user_info
                            ? userInfo.weWorkQrCodeLoginInfo.user_info.avatar
                            : "";
                        qiyeinfo.createTime = userInfo.weWorkQrCodeLoginInfo
                            ? userInfo.weWorkQrCodeLoginInfo.createTime
                            : "";
                    }
                    if (userInfo.email) {
                        youxianginfo.name = userInfo.email
                            ? userInfo.email
                            : "";
                        youxianginfo.isBind = userInfo.email ? true : false;
                    }
                    if (userInfo.dingTalkLoginInfo) {
                        dingTalkInfo.name = userInfo.dingTalkLoginInfo.nick;
                        dingTalkInfo.avatar =
                            userInfo.dingTalkLoginInfo.avatarUrl;
                        dingTalkInfo.createTime =
                            userInfo.dingTalkLoginInfo.infoCreateTime;
                        dingTalkInfo.isBind =
                            userInfo.dingTalkLoginInfo.openId != null;
                    }
                    if (userInfo.feishuLoginInfo && userInfo.feishuLoginInfo.name){
                        feiShuInfo.name = userInfo.feishuLoginInfo.name;
                        feiShuInfo.isBind = true;
                        feiShuInfo.avatar = userInfo.feishuLoginInfo.avatar_url;
                        feiShuInfo.createTime = userInfo.feishuLoginInfo.createTime
                    }
                    if (userInfo.yunzhijiaLoginInfo){
                        yunzhijia.name = userInfo.yunzhijiaLoginInfo.username;
                        yunzhijia.isBind = true;
                    }
                    if (userInfo.rongLianBindInfo && userInfo.rongLianBindInfo.loginName){
                        ronglian.name = userInfo.rongLianBindInfo.loginName;
                        ronglian.isBind = true;
                        this.rongLianInfo.name = userInfo.rongLianBindInfo.loginName;
                        this.rongLianInfo.password = userInfo.rongLianBindInfo.password;
                        this.rongLianInfo.way = userInfo.rongLianBindInfo.sip;
                        this.rongLianInfo.dept = userInfo.rongLianBindInfo.dept;
                    }
                    if(this.wxBindCode && this.wxBindCode !== 'null') {
                        this.accountList.push(qiyeinfo);
                    }
                    this.accountList.push(youxianginfo);
                    this.accountList.push(dingTalkInfo);
                    this.accountList.push(feiShuInfo);
                    this.accountList.push(yunzhijia);
                    if (this.isMnHr){
                        this.accountList.push(ronglian);
                    }
                    this.getWechatBindList();
                }
            });
        },
        getWechatBindList() {
            this.$http
                .get("/api-open/channel/access/list/1")
                .then((res) => {
                    let uid = localStorage.getItem("_uid");
                    let mainId = localStorage.getItem("_mainId");
                    if (res.status === 200) {
                        if (res.data.data && res.data.data.length.length != 0)
                            res.data.data.forEach((item) => {

                                // this.accountList.push({
                                //     type: "公众号-" + item.agentName,
                                //     name: '',
                                //     isBind: false,
                                //     avatar: "",
                                //     createTime: item.updateTime,
                                //     // avtUrl: this.openInfoMap[item.agentId].headimgurl,
                                //     avtUrl: "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
                                //     agentId: item.agentId,
                                //     component_appid: 'wx716d3c9c26092d55', // 测试环境 wx716d3c9c26092d55 生产 wx5cc5391649410ef8
                                //     state: item.bindCode,
                                //     redirect_uri: 'https://portal.test.askbot.cn/#/_personal',
                                // });
                                if (
                                    this.openInfoArr.indexOf(item.agentId) !==
                                    -1
                                ) {
                                    this.accountList.push({
                                        type: "公众号-" + item.agentName,
                                        name: this.openInfoMap[item.agentId]
                                            .nickname,
                                        isBind: true,
                                        avatar: "",
                                        createTime: this.openInfoMap[item.agentId]
                                            .createTime,
                                        // avtUrl: this.openInfoMap[item.agentId].headimgurl,
                                        avtUrl: "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
                                        agentId: item.agentId,
                                        component_appid:
                                            redirectUri[
                                                process.env.VUE_APP_NODE_ENV
                                            ].component_appid, // 测试环境 wx716d3c9c26092d55 生产 wx5cc5391649410ef8
                                        state: item.bindCode,
                                        redirect_uri: `${
                                            redirectUri[
                                                process.env.VUE_APP_NODE_ENV
                                            ].authWx
                                        }/p-wechat-login-callback.html?scope=snsapi_userinfo&uid=${uid}&mid=${mainId}`,
                                    });
                                } else {
                                    this.accountList.push({
                                        type: "公众号-" + item.agentName,
                                        name: "",
                                        isBind: false,
                                        avatar: "",
                                        createTime: "",
                                        avtUrl: "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
                                        agentId: item.agentId,
                                        component_appid:
                                            redirectUri[
                                                process.env.VUE_APP_NODE_ENV
                                            ].component_appid, // 测试环境 wx716d3c9c26092d55 生产 wx5cc5391649410ef8
                                        state: item.bindCode,
                                        redirect_uri: `${
                                            redirectUri[
                                                process.env.VUE_APP_NODE_ENV
                                            ].authWx
                                        }/p-wechat-login-callback.html?scope=snsapi_userinfo&uid=${uid}&mid=${mainId}`,
                                    });
                                }
                            });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    // this.$message.warning("返回个人中心重新操作");
                });
        },
        unBind(type, infoObj) {
            if (type === this.$t('personal.yunZhiJia')){
              return
            }
            let uid = localStorage.getItem("_uid");
            let fields = null;
            if (type === this.$t('personal.eWechat')) {
                fields = "weWorkQrCodeLoginInfo";
            } else if (type === this.$t('personal.email')) {
                fields = "email";
            } else if (type === this.$t('personal.dingDing')) {
                fields = "dingTalkLoginInfo";
            } else if (type === this.$t('personal.feiShu')){
               fields = "feishuLoginInfo"
            } else {
                fields = "pWeChatOpenInfo";
            }

            if (fields == null) {
                console.warn("not support bind type to unbind", type);
            }
            this.$emit('changeDialog',true)
            this.$confirm(this.$t('personal.unBindTip'), this.$t('personal.unBindTitle'), {
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                type: "warning",
            })
                .then(() => {
                    if (type === this.$t('personal.rongLian')){
                      let uid = localStorage.getItem('_uid');
                      let url = "/api/bind-info/ronglian/do-bing?uid=" + uid;
                      let obj = {
                        "loginName": "",
                        "password": "",
                        "sip": ""
                      }
                      this.$http.post(url,obj).then(res => {
                        if (res.data.code == 0){
                          this.getBind();
                          this.$message.success(this.$t('personal.unBindSuccess'));
                          this.rongLianInfo = {
                            name:"",
                            password:"",
                            way:"Local",
                            dept:"HR"
                          }
                          this.dialogRonglian = false;
                        }
                      })
                    } else {
                      let url = "/api/account/field/" + uid + "/" + fields;

                      if (fields == "pWeChatOpenInfo") {
                        url = url + "?pWechatAppId=" + infoObj.agentId;
                      }

                      this.$http.delete(url).then((res) => {
                        if (res.data.code === "0") {
                          this.getBind();
                          this.$message.success(this.$t('personal.unBindSuccess'));
                          setTimeout(() =>{
                            this.$emit('changeDialog',false)
                          }, 300)
                        }
                      });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t('common.cancel'),
                    });
                    setTimeout(() =>{
                        this.$emit('changeDialog',false)
                    }, 300)
                });
        },
        async bindNow(type, cellObj) {
            if (type === this.$t('personal.yunZhiJia')){
                return
            }
            if (type === this.$t('personal.eWechat')) {
                let url = ''
                await this.$http.get('/api/bind-info/we-work/url?bindCode=' + this.wxBindCode).then(res =>{
                    // url = res.data
                    let w = window.open(
                        res.data,
                        "newwindow",
                        "height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
                    );
                    let loop = setInterval(()=> {
                        if (w != null && w.closed) {
                            clearInterval(loop);
                            //do something 在这里执行回调
                            this.getBind();
                        }
                    }, 800);
                }).catch(() => {
                    this.$message.warning(this.$t('personal.backTip'));
                });
                // let url =
                //     "/scrm-api/we-work/sso/3rd-qr-connect?redirect_uri=" +
                //     encodeURIComponent(
                //         redirectUri[process.env.VUE_APP_NODE_ENV].callback +
                //             "/qrcode-callback-we-work-own-app.html"
                //     );
                // this.$http
                //     .get(url)
                //     .then((res) => {
                //         if (res.status === 200) {
                //             let that = this;
                           
                //         }
                //     })
                //     .catch(() => {
                //         this.$message.warning("返回个人中心重新操作");
                //     });
            } else if (type === this.$t('personal.dingDing')) {
                let url =
                    " /ding-talk-api/auth/askbot/url?state=" +
                    localStorage.getItem("_mainId") +
                    "&redirectUri=" +
                    encodeURIComponent(
                        redirectUri[process.env.VUE_APP_NODE_ENV].callback +
                            "/dingtalk-login-callback.html"
                    );
                this.$http
                    .get(url)
                    .then((res) => {
                        if (res.status === 200) {
                            let that = this;
                            let w = window.open(
                                res.data,
                                "newwindow",
                                "height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
                            );
                            var loop = setInterval(function () {
                                if (w != null && w.closed) {
                                    clearInterval(loop);
                                    //do something 在这里执行回调
                                    that.getBind();
                                }
                            }, 800);
                        }
                    })
                    .catch(() => {
                        this.$message.warning(this.$t('personal.backTip'));
                    });
            } else if (type === this.$t('personal.email')) {
                this.dialogEmail = true;
                this.emailValue = "";
            } else if (type === this.$t('personal.feiShu')){
                let url =
                    "https://open.feishu.cn/open-apis/authen/v1/index?app_id=" +
                    redirectUri[process.env.VUE_APP_NODE_ENV].appId +
                    "&redirect_uri=" +
                    encodeURIComponent(
                        redirectUri[process.env.VUE_APP_NODE_ENV].callback +
                        "feishu-login-callback.html"
                    ) + '&state=' + localStorage.getItem("_mainId");
                let that = this;
                let w = window.open(
                    url,
                    "newwindow",
                    "height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
                );
                var loop = setInterval(function () {
                    if (w != null && w.closed) {
                        clearInterval(loop);
                        //do something 在这里执行回调
                        that.getBind();
                    }
                }, 800);

            } else if(type === this.$t('personal.rongLian')) {
                this.dialogRonglian = true;
            } else {
                this.$nextTick(() => {
                    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
                        cellObj.agentId
                    }&redirect_uri=${encodeURIComponent(
                        cellObj.redirect_uri
                    )}&response_type=code&scope=snsapi_userinfo&state=${
                        cellObj.state
                    }&component_appid=${
                        cellObj.component_appid
                    }#wechat_redirect`;

                    this.newwindowWx = window.open(
                        redirectUri[process.env.VUE_APP_NODE_ENV].callback +
                            "/wxchatCode.html?imageUrl=" +
                            encodeURIComponent(url),
                        "newwindow",
                        "height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
                    );

                    // this.newwindowWx = window.open(
                    //     'https://www.baidu.com/s?wd=%E7%BF%BB%E8%AF%91&rsv_spt=1&rsv_iqid=0xc7f9a1890000045c&issp=1&f=3&rsv_bp=1&rsv_idx=2&ie=utf-8&rqlang=cn&tn=baiduhome_pg&rsv_dl=ts_0&rsv_enter=1&oq=es6%2520%25E9%2581%258D%25E5%258E%2586%25E5%25AF%25B9%25E8%25B1%25A1&rsv_t=32708PUn85Ju4fn1F6ubUObv0BzeEFn4odfFP%2BgM5cRI5Gysi5UOYtoK5d46A9YxKeaN&rsv_btype=t&rsv_pq=812ad53100055b3b&inputT=2334&rsv_sug3=69&rsv_sug1=59&rsv_sug7=101&rsv_sug2=0&prefixsug=fanyi&rsp=0&rsv_sug4=2334',
                    //     "newwindow",
                    //     "height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
                    // );

                    this.loopWx = setInterval(() => {
                        if(this.newwindowWx != null && this.newwindowWx.closed){
                            clearInterval(this.loopWx);
                        }
                        this.getPweChatOpenInfo();
                    }, 1000)
                });
            }
        },
        getPweChatOpenInfo(callback) {
            this.$http.get("/api/account/current").then((res) => {
                if (res.data.code === "0") {
                    let openInfoArr = [];
                    if (res.data.data.pweChatOpenInfo == null) {
                        openInfoArr = [];
                    } else {
                        if (res.data.data.pweChatOpenInfo.openInfoMap == null) {
                            openInfoArr = [];
                        } else {
                            for (let k in res.data.data.pweChatOpenInfo
                                .openInfoMap) {
                                openInfoArr.push(k);
                            }
                        }
                    }

                    if (this.openInfoArr.length !== openInfoArr.length) {
                        clearInterval(this.loopWx);
                        this.newwindowWx.close();
                        this.getBind();
                    }
                }
            });
        },
        bindEmail() {
            let uid = localStorage.getItem("_uid");
            let url = "/api/account/field/" + uid + "/email";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            let email = this.emailCheck(this.emailValue);
            if (!email) {
                return;
            }
            this.$http
                .put(url, JSON.stringify(this.emailValue), config)
                .then((res) => {
                    if (res.data.code === "0") {
                        this.getBind();
                        this.$message.success(this.$t('personal.bindSuccess'));
                    }
                });
            this.dialogEmail = false;
        },
        bindRongLian(){
          if (!this.rongLianInfo.name){
            this.$message({
              message:this.$t('personal.accountNameRequired'),
              type:"warning"
            })
            return
          }
          if (!this.rongLianInfo.password){
            this.$message({
              message:this.$t('personal.passwordRequired'),
              type:"warning"
            })
            return
          }
          if (!this.rongLianInfo.way){
            this.$message({
              message:this.$t('personal.wayRequired'),
              type:"warning"
            })
            return
          }
          if (!this.rongLianInfo.dept){
            this.$message({
              message:this.$t('personal.deptRequired'),
              type:"warning"
            })
            return
          }
          let uid = localStorage.getItem('_uid');
          let url = "/api/bind-info/ronglian/do-bing?uid=" + uid;
          let obj = {
            "loginName": this.rongLianInfo.name,
            "password": this.rongLianInfo.password,
            "sip": this.rongLianInfo.way,
            dept:this.rongLianInfo.dept
          }
          this.$http.post(url,obj).then(res => {
            if (res.data.code == 0){
              this.getBind();
              this.$message({
                message:this.$t('personal.bindSuccess'),
                type:'success'
              })
              this.dialogRonglian = false;
            }
          })
        },
        editRonglian(){

        },
        emailCheck(value) {
            /*const reg= /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/*/
            /* const reg= /^\w+@[a-z0-9]+\.[a-z]{2,4}$/*/
            const reg =
                /^([a-z0-9A-Z]+[-|_|\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-zA-Z]{2,4}$/;
            let bool = true;
            if (value) {
                if (!reg.test(value)) {
                    bool = false;
                    this.$message({
                        message: this.$t('personal.checkEmail'),
                        duration: 2000,
                        type: "warning",
                    });
                }
            }
            return bool;
        },
    },
    mounted() {
        this.getBind();
        this.isMnHr = isMnHr()
    },
    watch:{
        dialogEmail(value) {
            this.$emit('changeDialog',value)
        }
    }
};
</script>

<style lang="less">
#account-bind {
    font-size: 13px !important;
    .gr-bind-icon {
        cursor: pointer;
        color: #366aff;
    }
  .guoran-tongyichicun-16-15-lianjibianji{
    margin-right: 10px;
  }
  .yunzhijia-class{
    cursor: default!important;
    color: #999999;
  }
    .acc-type-box {
        margin-left: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            margin-top: -3px;
            margin-right: 4px;
        }
        span {
            line-height: 23px;
            height: 23px;
        }
    }
    .detail-info {
        display: flex;
        align-items: center;
        .detail-avatar {
            margin-right: 8px;
            height: 26px;
        }
        .detail-name {
            font-size: 13px;
        }
    }
    .detailName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 400px;
    }
    .bindStatus {
        display: flex;
        align-items: center;
        justify-content: center;
        .bindStatusBtn {
            width: 6px;
            height: 6px;
            background-color: #366aff;
            margin-right: 7px;
        }
        .no-bindStatusBtn {
            width: 6px;
            height: 6px;
            background-color: #cccccc;
            margin-right: 7px;
        }
    }
    /deep/.el-form-item__label {
        display: flex;
    }
}

.el-message-box__wrapper{
    background: rgba(0, 0, 0, 0.3) !important;
    animation: dialog-fade-in 0.3s ease-in  !important;
}
.v-modal {
    background: none !important;
    animation: dialog-fade-in 0.3s ease-in;
}
</style>
