function operable(type) {
    let Privileges=[]
    let value3=JSON.parse(localStorage.getItem('rolePrivileges'))
    value3.forEach(item=>{
        Privileges.push(item.id)
    })
    if (Privileges.indexOf(type)!==-1){
        return  true
    }
    /*if (roleList.length > 0){
        let roleId=localStorage.getItem('roleId')
        if (roleList.indexOf(-1)!==-1||roleList.indexOf(0)!==-1||roleList.indexOf(Number(roleId))!==-1){
            if (Privileges.indexOf(type)!==-1){
                return true
            }
        }
    }*/
}
function operableAll(roleType,RoleId) {
    let roleList2=[]
    let value2 = JSON.parse(localStorage.getItem('rolePrivileges'))
    value2.forEach(item => {
        if (item.id === 'PORTAL_MANAGEMENT') {
            if (item.items.length > 0) {
                item.items.forEach(role => {
                    if (role.checked) {
                        roleList2.push(role.id)
                    }
                })
            }
        }
    })
    let Privileges=[]
    let value3=JSON.parse(localStorage.getItem('rolePrivileges'))
    value3.forEach(item=>{
        Privileges.push(item.id)
    })
    if (roleList2.length > 0){
        if (roleList2.indexOf(0)!==-1){
            if (Privileges.indexOf(roleType)!==-1){
                return true
            }
        }
        if (roleList2.indexOf(Number(RoleId))!==-1){
            if (Privileges.indexOf(roleType)!==-1){
                return true
            }
        }
        let id=Number(localStorage.getItem('roleId'))
        if (roleList2.indexOf(-2)!==-1){
            if (Number(RoleId)===id){
                if (Privileges.indexOf(roleType)!==-1){
                    return true
                }
            }
        }
    }
}
export {operable,operableAll}