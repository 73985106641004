<template>
  <div class="dingGroupTable">
    <el-table
        :data="dingGroupData"
        style="width: 100%"
        class="groupTable"
    >
      <el-table-column
          prop="date"
          :label="$t('botLink.dingGroupListDrawer.name')"
          width="200px"
          align="center"
      >
        <template slot-scope="scope">
          {{scope.row.dingTalkGroupInfo.groupName}}
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          :label="$t('botLink.dingGroupListDrawer.id')"
          width="200px"
          align="center"
      >
        <template slot-scope="scope">
          {{scope.row.openConversationId}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          :label="$t('botLink.dingGroupListDrawer.bindTime')"
          width="180px"
          align="center"
      >
        <template slot-scope="scope">
          <div class="bindTime">
            <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
            {{
              new Date(scope.row.updateTime).Format(
                  "yyyy-MM-dd hh:mm:ss"
              )
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="绑定机器人"
          min-width="600px"
          class-name="bindBotsColumn"
      >
        <template slot="header">
          <div class="bindTitle">
            <div class="bindBots">{{$t('botLink.bindBot')}}</div>
            <div class="bindRange">{{$t('botLink.consultationScope')}}</div>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="bindContainer" v-if="!scope.row.edit">
            <div class="bindBots">
              <div
                  v-for="(cell, cellIndex) in scope.row.bindBot ? scope.row.bindBot.botInfos : []"
                  :key="cell.botId"
                  class="bindBotName"
              >
                <i class="iconfont guoran-tongyichicun-jiqirentouxiang_didaohang"></i>
                {{ cell.name }}
              </div>
            </div>
            <div class="bindRange">
              <div
                  v-for="(cell, cellIndex) in scope.row.bindBot ? scope.row.bindBot.botInfos : []"
                  :key="cell.botId"
                  class="useRange"
              >
                <template v-if="!cell.conditions">所有人</template>
                <template v-else>
              <span v-for="(condition,conditionIndex) in cell.conditions">
                <span v-if="conditionIndex !== 0">,</span>
                <template v-if="condition.expect && condition.expect.length > 0">
                   <span v-for="(expect,expectIndex) in condition.expect">
                     <span v-if="expectIndex !== 0">,</span>
                     {{expect.name}}
                   </span>
                </template>
               <template v-else>所有人</template>
              </span>
                </template>
              </div>
            </div>
          </div>
          <div class="edit_bot" v-else>
            <bind-information
                :bindDetail="scope.row"
                :ref="'bindInformation' + scope.row.id"
                :fromSource="'dingGroupTable'"
            ></bind-information>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="回复是否全员可见"
          class-name="reVisibleColumn"
          width="220"
      >
        <template #header>
          <div class="reVisibleTitle">
            <span class="text">{{$t('botLink.dingGroupListDrawer.isItVisible')}}</span>
            <el-switch
                v-model="replyVisible"
                @change="changeBatchSwitch"
                active-color="#366AFF"
                inactive-color="#A9B3C6"
            >
            </el-switch>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="reVisible">
            <el-switch
                @change="changeSingSwitch($event, scope.row)"
                v-model="scope.row.replyVisible"
                active-color="#366AFF"
                inactive-color="#A9B3C6"
            >
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column
         :label="$t('common.operation')"
          width="80"
          align="center"
      >
        <template slot-scope="scope">
          <div class="operation">
            <i class="iconfont guoran-tongyichicun-16-14-shezhiliebiao" @click="setEdit(scope.row)"></i>
            <i class="iconfont guoran-jiechubangding" @click="postDingdingGroupUnbind(scope.row.id)"></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @currentChange="currentChange"
    ></pagination>
  </div>
</template>

<script>
import pagination from "../../../../components/pagination";
import BindInformation from "../bindInformation";
export default {
  name: "dingGroupTable",
  data(){
    return{
      dingGroupData:[],
      currentPage:1,
      pageSize:20,
      total:0,
      replyVisible:false,
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  components:{
    BindInformation,
    pagination
  },
  methods:{
    // 获取钉钉群绑定列表
    getDingdingGroupBindList() {
      this.$http.get(
          this.requestUrl.dingdingGroup.getDingdinggroupByTemplate +
          this.bindDetail.groupTemplateId,
          {
            page: this.currentPage,
            pageSize: this.pageSize,
          }
      ).then((res) => {
        if (res.data.code === "0") {
          this.dingGroupData = [...res.data.data.list];
          this.total = res.data.data.total;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    changeBatchSwitch(val) {
      this.$http.put(
          this.requestUrl.dingdingGroup.dingtalkGroupBatchChange +
          "/" +
          this.bindDetail.groupTemplateId +
          "?visible=" +
          val
      ).then((res) => {
        if (res.data.code === "0") {
          this.getDingdingGroupBindList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    changeSingSwitch(val, obj) {
      let bindId = obj.id;
      this.$http.put(
          this.requestUrl.dingdingGroup.dingtalkGroupChange +
          "/" +
          bindId +
          "?visible=" +
          val
      ).then((res) => {
        if (res.data.code === "0") {

        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    postDingdingGroupUnbind(id){
      this.$confirm(this.$t('botLink.dingGroupListDrawer.unBindMsg'))
          .then((_) => {
            this.$http.post(this.requestUrl.dingdingBind.unBind + '?bindId=' + id).then((res) => {
              if (res.data.code === "0") {
                this.$message.success(this.$t('botLink.dingGroupListDrawer.unBindSuccess'));
                this.getDingdingGroupBindList();
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch((_) => {});
    },
    currentChange(val){
      this.currentPage = val;
      this.getDingdingGroupBindList();
    },
    setEdit(item){
      this.$set(item,'edit',true)
    },
    // 获取自定义标签数据
    getTagsOptions(corpId, callBack){
      this.$http.get(this.requestUrl.bindV2.getTagsOptions).then((res) => {
        if (res.data.code == 0) {
          this.tagsOptions = res.data.data;
          if (callBack) {
            let tagListIds = [];
            if (this.tagsOptions && this.tagsOptions.length != 0) {
              tagListIds = this.tagsOptions.map(item => item.id);
            }
            localStorage.setItem("ask_tagListIds", tagListIds);
            callBack(tagListIds);
          }
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getDingdingGroupBindList();
      this.getTagsOptions();
    })
  },
  watch:{
    bindDetail:{
      handler(){
        if (Object.keys(this.bindDetail).length > 0){
          this.replyVisible = this.bindDetail.replyVisible
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.dingGroupTable{
  margin-top: 30px;
  border-radius: 5px 5px 0px 0px;
  .groupTable{
    .bindTime{
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        color: #D2D8E3;
        padding-right: 4px;
      }
    }
    .bindTitle{
      display: flex;
      align-items: center;
      text-align: left;
      padding: 0 10px;
      .bindBots{
        max-width: 248px;
        flex: 1;
        padding: 0;
      }
      .bindRange{
        flex: 1;
        max-width: 328px;
        padding: 0;
      }
    }
    .bindContainer{
      display: flex;
      align-items: center;
      text-align: left;
      .bindBots{
        max-width: 248px;
        flex: 1;
        .bindBotName{
          padding: 8px 10px;
          display: flex;
          align-items: center;
          i{
            color: #366aff;
            margin-right: 10px;
          }
        }
      }
      .bindRange{
        max-width: 328px;
        flex: 1;
        .useRange{
          padding: 8px 10px;
        }
      }
    }
    .bindBotsColumn{
      padding: 0;
    }
    .edit_bot{
      width: 600px;
    }
    .reVisibleTitle{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 20px!important;
      div{
        line-height: 20px!important;
      }
    }
    .reVisible{

    }
    .operation{
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        color: #366aff;
        cursor: pointer;
      }
      .guoran-jiechubangding{
        margin-left: 14px;
      }
    }
  }
  .reVisibleColumn{
    width: 10%;
  }

}
</style>