<template>
    <div class="set-sys-user">
        <div class="ssu-cell">
            <div
                v-if="JSON.parse(selectedList).length === 1"
                class="ssu-cell-title"
            >
                {{$t('staffManage.selected')}}{{ handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department') }}:
                <span
                    v-if="corpType == 0 && JSON.parse(selectedList)[0].name.substr(0, 4) === 'woWe'"
                    class="selected-cell-tag">
                    <open-data
                        :type="handleType === 'staff' ? 'userName' : 'departmentName'"
                        :openid="JSON.parse(selectedList)[0].name">
                    </open-data>
                </span>
                <!-- <span
                    class="selected-cell-tag"
                    v-else-if="JSON.parse(selectedList)[0].type === 1 && isNumber(JSON.parse(selectedList)[0].name)"
                >
                    <open-data
                        :type="'departmentName'"
                        :openid="JSON.parse(selectedList)[0].name"
                    ></open-data>
                </span> -->
                <span v-else class="selected-cell-tag">{{
                    JSON.parse(selectedList)[0].name
                }}</span>
            </div>
            <div
                v-if="JSON.parse(selectedList).length > 1"
                class="ssu-cell-title"
            >
              {{$t('staffManage.selected')}}{{ handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department') }}
            </div>
            <div
                v-if="JSON.parse(selectedList).length > 1"
                class="ssu-cell-content ssu-cell-content-tag"
            >
                <span
                    v-for="(item, iIndex) in JSON.parse(selectedList).slice(0,10)"
                    :key="iIndex"
                    class="selected-cell-tag">
                    <!-- <span
                        v-if="
                            item.source === 0 &&
                            (item.name.substr(0, 4) === 'woWe' ||
                                isStrings(item.name))
                        "
                        class="selected-cell-tag"
                    >
                        <open-data
                            type="userName"
                            :openid="item.name"
                        ></open-data>
                    </span> -->
                    <span
                        v-if="corpType == 0 && item.name.substr(0, 4) === 'woWe'"
                        class="selected-cell-tag">
                        <open-data
                            :type="handleType === 'staff' ? 'userName' : 'departmentName'"
                            :openid="item.name">
                        </open-data>
                    </span>
                    <span v-else class="selected-cell-tag">{{
                        item.name
                    }}</span></span
                >
                <span>
                    <span v-if="JSON.parse(selectedList).length > 10"> {{$t('staffManage.etc')}}</span
                    >{{ JSON.parse(selectedList).length }}个{{
                        handleType === "staff" ? $t('staffManage.staff') : $t('staffManage.department')
                    }}</span
                >
            </div>
        </div>
        <div class="ssu-cell ssu-cell-2">
            <div class="ssu-cell-title">{{$t('staffManage.setSystemPermissions')}}</div>
            <div class="ssu-cell-content">
                <div class="ssu-cell-content-sys">
                    <el-checkbox
                        @change="changeCheckedOpen"
                        :disabled="authFollowDepart"
                        v-model="checkedOpen"
                        >{{$t('staffManage.bot')}}</el-checkbox
                    >
                    <el-checkbox
                        v-model="bigModel"
                        :disabled="authFollowDepart"
                        @change="changeModel">
                        {{$t('staffManage.bigModelCapability')}}
                    </el-checkbox>
                    <el-checkbox
                        @change="changeCheckedWorkorder"
                        v-model="checkedWorkorder"
                        :disabled="authFollowDepart"
                        >{{$t('staffManage.workOrder')}}</el-checkbox
                    >
                    <el-checkbox
                        v-if="handleType == 'staff'"
                        v-model="authFollowDepart"
                        @change="changeAuthFollowDepart"
                        >{{$t('staffManage.followDepart')}}</el-checkbox
                    >
                </div>

                <div v-if="checkedOpen" class="ssu-cell-content-sys-info">
                    <div class="sccsi-active">
                        <span class="sccsi-active-pre sccsi-active-pre-o"
                            >{{$t('staffManage.work')}}</span
                        >
                        <span class="sccsi-active-name">{{$t('staffManage.numberOfConsultationBot')}}</span>
                        <span
                            v-if="totalUseBotPeopleNum != -1"
                            class="sccsi-active-num"
                            >{{ totalUseBotPeopleNum }} {{$t('staffManage.people')}}</span
                        >
                        <span
                            v-if="totalUseBotPeopleNum == -1"
                            class="sccsi-active-num"
                            >{{$t('staffManage.unrestricted')}}</span
                        >
                    </div>
                    <div class="sccsi-line"></div>
                    <div class="sccsi-active">
                        <span class="sccsi-active-pre sccsi-active-pre-w"
                            >{{$t('staffManage.choose')}}</span
                        >
                        <span class="sccsi-active-name">{{$t('staffManage.selectedNumberOfPeople')}}</span>
                        <span class="sccsi-active-num"
                            >{{ existUseBotPeopleNum }} {{$t('staffManage.people')}}</span
                        >
                        <template v-if="existUseBotPeopleNum > totalUseBotPeopleNum && totalUseBotPeopleNum != -1">
                            <div class="sccsi-active-more" v-if="CompanyIsOld">{{$t('staffManage.exceeded')}}</div>
                            <template v-else>
                                <div class="sccsi-active-more" v-if="promoteSurplusDay < 0">{{$t('staffManage.exceeded')}}</div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OpenData from "@/components/openData";
export default {
    props: ["handleType", "selectedList","activeTreeItem","activeTreeItemNode","setSysAccDialog","corpType"],
    data() {
        return {
            checkedOpen: false,
            checkedWorkorder: false,
            totalUseBotPeopleNum: "",
            existUseBotPeopleNum: "",
            authFollowDepart: false,
            promoteSurplusDay:-1,
            CompanyIsOld:true,
            bigModel:false,

        };
    },
    components:{OpenData},
    watch:{
        setSysAccDialog:{
            handler(n){
                if(n){
                    this.checkedOpen = this.activeTreeItem.botAuth;
                    this.checkedWorkorder = this.activeTreeItem.workOrderAuth;
                    this.bigModel = this.activeTreeItem.bigModel;
                    this.authFollowDepart = this.activeTreeItem.authFollowDepart || false;
                }
            },
            immediate:true
        }
    },
    methods: {
        changeModel(){

        },
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        isStrings(value) {
            const res = new RegExp("[\u4E00-\u9FA5]+");
            console.debug("是否是中文", res.test(value));
            return !res.test(value);
        },
        changeAuthFollowDepart(val) {
            if (val && this.promoteSurplusDay < 0) {
                this.checkedOpen = false;
                this.checkedWorkorder = false;
                this.bigModel = false
                this.$emit("setNoDisableSave");
            }
            if(val){
                this.checkedOpen = false;
                this.checkedWorkorder = false;
                this.bigModel = false
            }
           
        },
        changeCheckedWorkorder(val){
            if (val && !this.checkedOpen) {
                this.$emit("setNoDisableSave");
            }
        },
        changeCheckedOpen(val) {
            if (!val) {
                this.$emit("setNoDisableSave");
            }
            let param = {
                departmentId: [],
                memberId: [],
            };
            if (this.handleType === "staff") {
                param.memberId = JSON.parse(this.selectedList).map(
                    (item) => item.id
                );
            } else {
                param.departmentId = JSON.parse(this.selectedList).map(
                    (item) => item.id
                );
            }
            console.log(this.checkedOpen,'checkedOpen');
            val &&
                this.$http
                    .post(
                        this.requestUrl.department.getQueryPeopleAuthNum +
                            "?type=" +
                            (this.handleType === "staff" ? 1 : 0) +
                            "&authBot=" +
                            this.checkedOpen,
                        JSON.parse(this.selectedList).map((item) => item.id),
                        {
                            emulateJSON: true,
                        }
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(res.data);
                            this.totalUseBotPeopleNum =
                                res.data.data.totalUseBotPeopleNum;
                            this.existUseBotPeopleNum =
                                res.data.data.existUseBotPeopleNum;
                                if(this.CompanyIsOld){
                                    if (
                                        this.existUseBotPeopleNum >
                                        this.totalUseBotPeopleNum &&
                                        this.totalUseBotPeopleNum != -1
                                    ) {
                                        this.$emit("setDisableSave");
                                    }
                                } else {
                                    if (
                                        this.existUseBotPeopleNum >
                                        this.totalUseBotPeopleNum &&
                                        this.totalUseBotPeopleNum != -1&&
                                        this.promoteSurplusDay < 0
                                    ) {
                                        this.$emit("setDisableSave");
                                    }
                                }
                            if (
                                this.existUseBotPeopleNum >
                                this.totalUseBotPeopleNum &&
                                this.totalUseBotPeopleNum != -1 &&
+                                this.promoteSurplusDay < 0
                            ) {
                                this.$emit("setDisableSave");
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
        },
        saveSetSysAccDialog() {
            let param = {
                authFollowDepart: false,
                botAuth: this.checkedOpen || false, // 可使用机器人权限，如果未选择传一个false过来
                departmentId: [],
                memberId: [],
                type: this.handleType === "staff" ? 1 : 0, // 设置类型0：部门，1：成员，2：上下游部门，3：上下游成员
                workOrderAuth: this.checkedWorkorder || false, // 可使用工单权限，如果未选择传一个false过来
                bigModel:this.bigModel || false
            };
            if (this.handleType === "staff") {
                param.authFollowDepart = this.authFollowDepart;
                param.memberId = JSON.parse(this.selectedList).map(
                    (item) => item.id
                );
                if(!this.checkedOpen && !this.bigModel && !this.checkedWorkorder && !this.authFollowDepart){
                    this.$message.warning(this.$t('staffManage.permissionRequired'));
                    return false
                }
            } else {
                param.departmentId = JSON.parse(this.selectedList).map(
                    (item) => item.id
                );
                if(!this.checkedOpen && !this.bigModel && !this.checkedWorkorder){
                    this.$message.warning(this.$t('staffManage.permissionRequired'));
                    return false
                }
            }
            console.log(param,'param');
            this.$http
                .post(this.requestUrl.department.setSysPermissions, param)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.$message.success(this.$t('staffManage.permissionSuccess'));
                        this.$emit("saveSetSysAccSuccess");
                        // this.$emit("reloadData");
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
    mounted() {
        console.log(this.selectedList,'selectedList',this.handleType);
        this.CompanyIsOld = sessionStorage.getItem('CompanyIsOld') == 'true' ? true : false;
        this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
        if (
            this.handleType == "depart" &&
            JSON.parse(this.selectedList).length == 1
        ) {
            this.$nextTick(() => {
                this.checkedOpen = JSON.parse(this.selectedList)[0].botAuth;
                this.checkedWorkorder = JSON.parse(
                    this.selectedList
                )[0].workOrderAuth;
                this.bigModel = JSON.parse(this.selectedList)[0].bigModel;
                this.changeCheckedOpen(true);
            });
        }
    },
};
</script>
<style lang="less" scoped>
.set-sys-user {
    .ssu-cell {
        .ssu-cell-title {
            height: 38px;
            font-size: 14px;
            color: #616161;
            line-height: 38px;
            .selected-cell-tag {
                margin-left: 12px;
                display: inline-block;
                height: 23px;
                line-height: 23px;
                padding: 0 8px;
                text-align: center;
                background: #ecf1ff;
                color: #366aff;
                font-weight: 600;
                border-radius: 3px;
            }
        }
        .ssu-cell-content-tag {
            padding: 8px;
            background: #ffffff;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            .selected-cell-tag {
                margin-right: 8px;
                display: inline-block;
                height: 23px;
                line-height: 23px;
                padding: 0 8px;
                text-align: center;
                background: #ecf1ff;
                color: #366aff;
                font-weight: 600;
                border-radius: 3px;
                white-space: nowrap;
            }
        }
        .ssu-cell-content {
            .ssu-cell-content-sys-info {
                margin: 16px 0;
                .sccsi-active {
                    display: flex;
                    justify-content: flex-start;
                    .sccsi-active-pre {
                        flex: none;
                        width: 21px;
                        height: 21px;
                        line-height: 21px;
                        text-align: center;
                        font-size: 12px;
                        color: white;
                        border-radius: 50%;
                    }

                    .sccsi-active-pre-o {
                        background: #09ddd5;
                    }

                    .sccsi-active-pre-w {
                        background: #87a6ff;
                    }
                    .sccsi-active-name {
                        height: 21px;
                        line-height: 21px;
                        font-size: 13px;
                        font-weight: 400;
                        color: #a9b3c6;
                        margin-left: 8px;
                    }
                    .sccsi-active-num {
                        margin-left: 8px;
                        margin-right: 8px;
                    }

                    .sccsi-active-more {
                        height: 21px;
                        line-height: 21px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #fe5965;
                        background-color: #ffebec;
                        padding: 0 6px;
                    }
                }
                .sccsi-line {
                    height: 16px;
                    width: 9px;
                    border-right: dashed 1px #e0e6f7;
                }
            }
            .ssu-cell-content-sys {
                line-height: 40px;
                height: 40px;
                padding: 0 8px;
                background: #fbfcfd;
                border-radius: 5px;
                display: flex;
                // justify-content: space-between;
                // justify-content: space-evenly;
            }
        }
    }
    .ssu-cell-2 {
        margin-top: 16px;
    }
}
</style>