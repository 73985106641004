<!-- 
/**
pageName:右侧上部分公共字段配置 标题/提示文字/是否必填
auther:liuyuli
time:2022-4-22
 */
 -->
<template>
    <div class="top-common">
        <el-form :label-position="labelPosition"  :model="activeObjs">
            <!-- 标题 -->
            <el-form-item  v-if="activeObjs.type !== 'REF' && activeObjs.type !== 'COPY'">
                <div class="is-inline">
                    <div class="left">
                        <span>
                          标题<span class="red">*</span>
                        </span>
                        <span class="tips">最多20个字</span>
                    </div>
                    <div class="bottom">
                        <el-input v-model="activeObjs.name" :maxlength="20" placeholder="请输入" :disabled="activeObjs.extInfo.isCommon && !$route.query.type"></el-input>
                    </div>
                </div>
            </el-form-item>
            <!--  提示文字-->
             <el-form-item 
                v-if="activeObjs.type !== 'REF' && activeObjs.type !== 'COPY' &&  activeObjs.type !== 'REF_TEMPLATE' &&  activeObjs.type !== 'RADIO' && activeObjs.type !== 'CHECKBOX'"
                label="提示文字">
                <el-input v-model="activeObjs.extInfo.placeholder" :maxlength="40" placeholder="请输入" :disabled="activeObjs.extInfo.isCommon && !$route.query.type"></el-input>
            </el-form-item>
            <!-- 时间日期范围的标题 -->
            <template v-if="activeObjs.type === 'TIME_PICKER_RANGE'&&false">
                <el-form-item >
                    <div class="is-inline">
                        <div class="left">
                            <span>标题2 <span class="red">*</span></span>
                            
                            <span class="tips">最多20个字</span>
                        </div>
                        <div class="bottom">
                            <el-input v-model="activeObjs.extInfo.to.name" :maxlength="20" placeholder="请输入" ></el-input>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="标题2提示文字">
                    <el-input v-model="activeObjs.extInfo.to.placeholder" :maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
            </template>

        </el-form>

    </div>
</template>

<script>

export default {

    data(){
        return {
            labelPosition:'top',
            list:[],
            chooseTreeDialog:false,
            dataFilterPopupDialog:false,
            showDefaultType:'', // 展示默认值的类型
            selectOption:[], // 单选/多选/下拉的下拉中的值
        }
    },
    props:{
		activeObj:{
			type:Object,
			default(){
				return{}
			}
		}
	},
    computed:{
        activeObjs(){
            return this.activeObj;
        },
    },
    methods:{



    }
}
</script>

<style lang="less" scoped>
    .top-common{
        .red{
            color: red;
        }
        .is-inline{
			font-size: 14px;
			color:#616161;
			.left{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.tips{
					color: #A9B3C6;
					margin-left: 10px;
				}
			}
		}
        .el-input,.el-select{
            width: 100%;
        }
        .bottom-input{
			height: 38px;
			width:100%;
			border-radius: 5px;
			border:1px solid #E0E6F7;
			cursor: pointer;
			display: flex;
			align-items: center;
            &.date{
                cursor: default;
            }
			.placeholder-text{
				padding-left: 15px;
				color: #A9B3C6;
                font-size: 14px;
			}
			.client-value{
				padding: 5px;
				color: #606266;
				display: flex;
				// justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				line-height: 30px;
				width: 100%;
				.item{
					border:1px solid hsl(240, 5%, 92%);
					height: 24px;
					line-height: 24px;
					padding: 0 8px;
					position: relative;
					color: #fff;
					font-size: 12px;
					margin: 2px 0 2px 6px;
                    height: 24px;
                    background: #366AFF;
                    border-radius: 3px;
                    cursor: pointer;
					.el-icon-close{
						margin-left: 10px;
						cursor: pointer;
					}
				}
			}
            .default-date{
                color: #606266;
                font-size: 14px;
                margin-left: 15px;
            }
        }
    }
</style>>

