<template>
    <div class="sub-menu">
        <el-menu
            :default-active="currentPath"
            class="el-menu-vertical-demo"
            router
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="/_accountManage" v-if="isShowOther">
                <i class="arsenal_icon arsenalzhanghaoguanli"></i>
                <span slot="title">账号管理</span>
            </el-menu-item>
            <!-- <el-menu-item index="/wxAccount">
                <i style="font-size: 16px;width: 16px;margin-right:10px"  class="el-icon-service"></i>
                <span slot="title">微信客服账号</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="/companyInfo">
                <i class="arsenal_icon arsenalqiye"></i>
                <span slot="title">企业信息</span>
            </el-menu-item> -->
            <el-menu-item index="/_staffManage" v-if="isShowOther">
                <i class="arsenal_icon arsenalkehuguanli"></i>
                <span slot="title">员工管理</span>
            </el-menu-item>
            <el-menu-item index="/_upDownManage" v-if="isShowUpDownManage">
                <i class="iconfont guoran-shangxiayou" style="margin-right:10px;"></i>
                <span slot="title">上下游管理</span>
            </el-menu-item>
            <el-menu-item index="/_roleManage" v-if="isShowOther">
                <i class="arsenal_icon arsenaluser1"></i>
                <span slot="title">角色管理</span>
            </el-menu-item>
           <!-- <el-menu-item index="/workTime">
                <i class="arsenal_icon arsenaluser1"></i>
                <span slot="title">工作时间</span>
            </el-menu-item>-->
           <!-- <el-menu-item index="/companyInfo">
                <i class="arsenal_icon arsenalqiye" style="font-size:22px;margin-right:6px;margin-left:-2px"></i>
                <span slot="title">企业信息</span>
            </el-menu-item>-->
            <el-menu-item index="/_dataRoleManage" v-if="isShowOther">
                <i class="arsenal_icon arsenalserver-solid"></i>
                <span slot="title">数据权限管理</span>
            </el-menu-item>
            </el-menu>
    </div>

</template>
<script>
export default {
    data(){
       return{
            currentPath:'', 
            isShowUpDownManage:false, // 是否显示上下游管理
            isShowOther: false, // 其他菜单是否显示 
       } 
    },
    methods:{
        getCurrentUrl(){
            this.currentPath = this.$route.path;
        },
        handleOpen(){

        },
        handleClose(){
            
        }
    },
    mounted(){
        this.getCurrentUrl();
        this.isShowOther = localStorage.getItem('isShowOther') === 'true' ? true : false;
        this.isShowUpDownManage = localStorage.getItem('changeShowUpDownManage') === 'true' ? true : false;
        console.log(this.isShowOther,this.isShowUpDownManage);
    }
}
</script>
<style lang="less" scoped>
.sub-menu{
    width: 150px;
    background-color: #ffffff;
}
</style>