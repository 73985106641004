<!---次数使用明细-->
<template>
    <el-drawer
        custom-class="use-detail-drawer"
        title="次数使用明细"
        :visible.sync="useDetailVisible"
        :with-header="false"
        :destroy-on-close="true"
		size="700px"
		:wrapperClosable="false"
        @close="closeDrawer">
            <div class="add-member-title">
				<h4>次数使用明细</h4>
				<i class="el-icon-close" @click="closeDrawer"></i>
			</div>
           <div class="drawer-content">
                <div class="search-box">
                    <el-input v-model="keyword" @change="getTableData"  placeholder="搜索员工/部门" prefix-icon="el-icon-search"></el-input>
                    <el-select v-model="sortValue" multiple placeholder="请选择" @change="changeSortValue">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-table
                    :data="tableData"
                    class="table-box">
                    <el-table-column
                        prop="userName"
                        align="left"
                        class-name="user-name-cell"
                        label="姓名">
                        <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.profilePhoto && scope.row.profilePhoto !== ''"
                                class="user-icon profilePhoto"
                                :src="scope.row.profilePhoto"
                                :fit="'container'">
                            </el-image>
                            <div v-else class="user-icon">
                                <span  v-if="!scope.row.ewechat">{{scope.row.userName.slice(1,3)}}</span>
                                <i v-else class="iconfont guoran-a-18-32"></i>
                            </div>
                            <div class="user-name">
                                <el-tooltip class="item" :open-delay="500" effect="dark" :content="scope.row.userName" placement="top-end">
                                    <div class="one-column-ellipsis" v-if="!scope.row.ewechat">{{scope.row.userName}}</div>
                                    <open-data v-else :type="'userName'" :openid="scope.row.userName"></open-data>
                                </el-tooltip>
                                <div class="dept one-column-ellipsis">
                                    <span v-for="(item,index) in scope.row.departmentNameList" :key="index">
                                        <el-tooltip placement="top" effect="light">
                                            <div slot="content">
                                                <span v-if="!scope.row.ewechat">{{item}}</span>
                                                <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                            </div>
                                            <span v-if="!scope.row.ewechat">{{item}}</span>
                                            <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                            <span v-if="index + 1 < scope.row.departmentNameList.length">/</span>
                                        </el-tooltip>
                                       
                                    </span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        prop="dept"
                        align="left"
                        show-overflow-tooltip
                        label="部门">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.departmentNameList" :key="index">
                                <span v-if="!scope.row.eWechat">{{item}}</span>
                                <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                <span v-if="index + 1 < scope.row.departmentNameList.length">/</span>
                            </span>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        prop="useCount"
                        width="100"
                        align="left"
                        label="已用总次数">
                        <template slot-scope="scope">
                            <span>{{scope.row.useCount ? scope.row.useCount + '次' : '-'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        align="left"
                        width="120"
                        label="企业次数剩余">
                         <template slot-scope="scope">
                             <!-- -1处理成无限制，-3 处理成不可用  -->
                            <span v-if="scope.row.companyFlowSurplus === 0" style="color:#FE5965">次数已用尽</span>
                            <span v-else-if="scope.row.companyFlowSurplus === -1" style="color:#38C175">无限制</span>
                            <span v-else-if="scope.row.companyFlowSurplus === -3" style="color:#FE5965">不可用</span>
                            <span v-else>{{scope.row.companyFlowMonthRefresh ? '本月剩余' : ''}}{{scope.row.companyFlowSurplus}}次</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        align="left"
                        width="120"
                        label="自购次数包剩余">
                        <template slot-scope="scope">
                           <span>{{scope.row.flowPackageSurplus ? scope.row.flowPackageSurplus + '次' : '-'}}</span>
                        </template>
                    </el-table-column>
                </el-table>
           </div>
            <pagination 
                :pageSize="pageSize" 
                :currentPage="currentPage" 
                :total="total"
                @handleSizeChange="handleSizeChange" 
                @currentChange="handleCurrentChange">
            </pagination>
    </el-drawer>    
</template>

<script>
import pagination from "../../../../components/pagination.vue";
import OpenData from "@/components/openData";
export default {
    data(){
        return{
            keyword:'',
            sortValue:[1],
            options:[{
                value:1,
                label:'按已用总次数倒序'
            },{
                value:0,
                label:'按已用总次数升序'
            },{
                value:2,
                label:'次数用尽置顶'
            }],
            pageSize:15,
			currentPage:1,
			total:0,
        }
    },
    components:{pagination,OpenData},
    props:{
        useDetailVisible:{
            typeof:Boolean,
            default:false
        },
        pageType:{
            type:String,
            default:""
        },
        corpId:{
            type:String,
            default:""
        }
    },
    watch:{
        useDetailVisible(n){
            if(n){
                this.getTableData()
            }
        }
    },
    methods:{
        closeDrawer(){
            this.$emit('update:useDetailVisible',false)
        },
        changeSortValue(value){
            // 0 升序  1  倒序  2 置顶
            if(this.sortValue.indexOf(0) !== -1 && this.sortValue.indexOf(1) !== -1){
                this.sortValue.splice(this.sortValue[this.sortValue.length - 1] === 0 ? this.sortValue.indexOf(1) :this.sortValue.indexOf(0),1)
            } 
            this.getTableData();
        },
        getTableData(){
            let url = this.requestUrl.knowledge.selectFlowUseApply;
            let params = {
                "page":this.currentPage, // 页码
                "size":this.pageSize, // 每页条数
                "mainId":localStorage.getItem('_mainId'),
                "corpId":this.corpId,
                "keyword":this.keyword, // 搜索关键词
                "sort":1, // 0 申请时间升序，1 申请时间降序  默认给1
            }
            // 0 升序  1  倒序  2 置顶
            let sortList = JSON.parse(JSON.stringify(this.sortValue));
            if(sortList.indexOf(2) !== -1){
                params.exhaustTop = true;
                sortList.splice(this.sortValue.indexOf(2),1)
            } 
            if(sortList.length === 0){
                params.sort = 1;
            } else {
                if(sortList.indexOf(2) === -1){
                    params.sort = sortList[0]
                } 
            }
            this.$http.post(url,params).then((res) => {
                console.log(res,'res');
                if(res.data){
                    res.data.data.list.forEach((item,index) => {
                        item.departmentNameList = JSON.parse(item.userDepartName) || [];
                    })
                    this.tableData = res.data.data.list;
                    console.log(this.tableData);
                    this.total = res.data.data.total;
                    this.$forceUpdate()
                }
            })
        },
       
    }
}
</script>

<style lang="less" scoped>
.use-detail-drawer{
    .add-member-title {
		height: 45px;
		background: #f6f8fd;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h4 {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}
		i {
			cursor: pointer;
		}
	}
    .drawer-content{
        padding: 20px;
        width: 100%;
        height: calc(100% - 95px);
        .search-box{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
            /deep/.el-input{
                width: 284px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                .el-input__inner{
                    width: 284px;
                    height: 32px;
                }
                .el-input__icon{
                    font-size: 16px;
                    line-height: 32px;
                }
            }
            /deep/.el-select{
                .el-input__inner,.el-input{
                    min-width: 174px;
                }
            }
        }
        /deep/.el-table{
            height: calc(100% - 30px);
            overflow-x: hidden;
            overflow-y: auto;
            .el-table__header-wrapper{
                height: 40px;
                line-height: 40px;
                background-color: #EFF3FF;
                th{
                    height: 40px;
                    padding: 0;
                    background: #EFF3FF;
                }
            }
            .cell{
                text-align: left;
            }
            .el-table__body{
                td{
                    height: 60px;
                    padding: 0;
                }
            }
            .user-name-cell .cell{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .user-icon{
                    width: 30px;
                    height: 30px;
                    background: #366AFF;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    margin-right: 6px;
                    flex-shrink: 0;
                    span{
                        font-size: 10px;
                        transform: scale(0.5);
                    }
                }
                .user-name{
                    text-align: left;
                    .dept{
                        font-size: 12px;
                        color: #999999;
                    }
                }
                
            }
        }
    }
}
</style>