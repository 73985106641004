<template>
    <div id="manage-duty-group">
        <div class="view-duty-header">
            <span class="vdh-title">{{$t('workDuty.manageSchedulingTeams')}} <span v-if="addDutyGroupAcc" @click="addDutyGroup" class="vdh-title-handle"><i
                        class="iconfont guoran-a-16-13"></i>{{$t('workDuty.addSchedulingTeams')}}</span> </span>
            <span @click="closeGroupManage" class="vdh-close arsenal_icon arsenalcuo1"></span>
        </div>
        <div class="view-duty-content">
            <div class="vdc-title">
                <span class="vdc-title-name">{{$t('workDuty.groupName')}}</span>
                <span class="vdc-title-duty">{{$t('workDuty.duty')}}</span>
                <span class="vdc-title-time">{{$t('workDuty.dutyScope')}}</span>
                <span class="vdc-title-handle">{{$t('workDuty.operation')}}</span>
            </div>
            <div class="vdc-list">
                <div v-for="(item, index) in groupList" :key="item.id" class="vdc-list-cell">
                    <div class="vlc-name">
                        <span class="vlc-name-text">{{ item.name }}</span>
                    </div>
                    <div class="vdc-duty">
                        {{ item.principalName }}
                    </div>
                    <div class="vlc-time">
                        {{ item.scopeOfDutyName }}
                    </div>
                    <div class="vlc-handle">
                        <span :style="!editDutyGroupAcc ? 'opacity: 0.5;' : 'opacity: 1;'" @click="editGroup(item)"
                            class="vlc-hanlde">
                            <i class="iconfont guoran-a-16-15"></i>
                        </span>
                        <span :style="!deleteDutyGroupAcc ? 'opacity: 0.5;' : 'opacity: 1;'" @click="delteGroup(item)"
                            v-if="(groupList.length != 1)" class="vlc-hanlde">
                            <i class="iconfont guoran-a-18-13"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="view-duty-footer">
            <span></span>
            <div @click="closeGroupManage" class="view-duty-close">
              {{$t('common.close')}}
            </div>
        </div>
        <popup @closeEvent="addDutyGroupDia = false" v-if="addDutyGroupDia">
            <div slot="popup-name" class="popup-name">{{$t('workDuty.name')}}</div>
            <div slot="popup-tip">{{$t('workDuty.name')}}</div>
            <div slot="popup-con" class="duty-manage-contanier">
                <div>
                    <div class="select-cous ask-select-item">
                        <span class="ask-select-item-title">{{$t('workDuty.name')}} <em>*</em></span>
                        <div class="ask-select-item-content">
                            <el-input maxLength="50" v-model="groupName" :placeholder="$t('workDuty.namePlaceholder')"></el-input>
                        </div>
                    </div>
                    <div class="select-cous ask-select-item">
                        <span class="ask-select-item-title">{{$t('workDuty.duty')}} <em>*</em></span>
                        <div class="ask-select-item-content">
                            <el-cascader v-model="principal" :options="optionsWithoutAll" :placeholder="$t('workDuty.dutyPlaceholder')"
                                key="principal1"
                                :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: false, label: 'name', value: 'id' }"
                                @change="handleChange"></el-cascader>
                        </div>
                    </div>
                    <div class="select-cous ask-select-item">
                        <span class="ask-select-item-title">{{$t('workDuty.dutyScope')}} <em>*</em></span>
                        <div class="ask-select-item-content">
                            <el-cascader :placeholder="$t('workDuty.dutyScopePlaceholder')" key="dutyRange1" v-model="dutyRange" :options="options"
                                :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true, label: 'name', value: 'id' }"></el-cascader>
                        </div>
                    </div>
                    <div class="select-cous ask-select-item">
                        <span class="ask-select-item-title">{{$t('workDuty.dutyTime')}} <em>*</em></span>
                        <div class="ask-select-item-content ask-select-item-content-bgc">
                            <div class="ask-select-item-content-bgc-inner">
                                <div class="ask-select-item-content-radio">
                                    <el-radio v-model="dutyTime" :label="0">{{$t('workDuty.accountTime')}}</el-radio>
                                    <el-radio v-model="dutyTime" :label="1">{{$t('workDuty.chooseWorkTime')}}</el-radio>
                                </div>
                                <el-select v-show="dutyTime == 1" v-model="workTimeId" filterable placeholder="请选择">
                                    <el-option v-for="item in workTimeOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeAddduty" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitAddduty" size="small">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <delete-dialog @closeEvent="deleteDialogVisible = false" v-if="deleteDialogVisible">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('workDuty.deleteTitle')}}</div>
                <div>
                    <span style="text-align: left; padding-left: 14px">{{$t('workDuty.deleteTip')}}
                        <span style="padding-left: 4px; font-weight: 600">{{
                            activeHandleGroup.name
                        }}</span></span>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="deleteDialogVisible = false" size="small" plain>{{$t('common.cancel')}}</el-button>
                <el-button @click="confirmDelete" style="background-color: #fe5965; color: white" size="small">{{$t('common.delete')}}</el-button>
            </div>
        </delete-dialog>
    </div>
</template>
<script>
import Popup from "@/components/popup";
import DeleteDialog from "@/components/deleteDialogue.vue";
export default {
    name: "",
    components: {
        Popup,
        DeleteDialog
    },
    data() {
        return {
            groupList: [],
            addDutyGroupDia: false,
            groupName: "",
            principal: [],
            options: null,
            optionsWithoutAll: [],
            dutyRange: [],
            handleType: "add",
            activeHandleGroup: "",
            deleteDialogVisible: false,
            addDutyGroupAcc: false,
            editDutyGroupAcc: false,
            deleteDutyGroupAcc: false,
            dutyTime: 0,
            workTimeId: "",
            workTimeOptions: [],
        }
    },
    methods: {
        // 分页获取工作时间列表
        getWorkTimeList() {
            this.$http
                .get(
                    this.requestUrl.workTime.queryAllUserWorkTimePage +
                    "?pageNo=" +
                    1 +
                    "&pageSize=" +
                    200
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.workTimeOptions = [...res.data.data.list];
                        this.workTimeOptions.forEach(item => {
                            item.value = item.id;
                            item.label = item.workTimeName;
                        })
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        canScheduling() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.queryUserPermissions}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        console.log(res.data.data);
                        let arr = [];
                        if (res.data.data != null) {
                            arr = res.data.data;
                        }
                        if (arr.indexOf('PORTAL_MANAGEMENT_SCHEDUL_ADD_GROUP') != -1) {
                            this.addDutyGroupAcc = true;
                        }
                        if (arr.indexOf('PORTAL_MANAGEMENT_SCHEDUL_UPDATE_GROUP') != -1) {
                            this.editDutyGroupAcc = true;
                        }
                        if (arr.indexOf('PORTAL_MANAGEMENT_SCHEDUL_DELETE_GROUP') != -1) {
                            this.deleteDutyGroupAcc = true;
                        }
                    }
                });
        },
        // guoran-tongyichicun-12-09-shijianriqi
        delteGroup(item) {
            if (!this.deleteDutyGroupAcc) {
                this.$message.warning(this.$t('workDuty.notDelete'));
            } else {
                this.activeHandleGroup = item;
                this.deleteDialogVisible = true;
            }
        },
        confirmDelete() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.deleteSchedulingGroups}?schedulingGroupId=${this.activeHandleGroup.id}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.getAllGroup();
                        this.$emit("updateGroupList");
                        this.deleteDialogVisible = false;
                    }
                });
        },
        editGroup(item) {
            if (!this.editDutyGroupAcc) {
                this.$message.warning(this.$t('workDuty.notEdit'));
            } else {
                this.groupName = item.name;
                this.principal = JSON.parse(item.ext).principal;
                this.dutyRange = JSON.parse(item.ext).dutyRange;
                this.activeHandleGroup = item;
                this.handleType = "edit";
                this.dutyTime = item.dutyTime;
                if (this.dutyTime != 0 && this.dutyTime != 1) {
                    this.dutyTime = 0;
                    this.workTimeId = "";
                }
                this.workTimeId = item.workTimeId;
                setTimeout(() => {
                    this.addDutyGroupDia = true;
                }, 300)
            }
        },
        closeGroupManage() {
            this.$emit("closeGroupManage");
        },
        addDutyGroup() {
            this.handleType = "add";
            this.groupName = "";
            this.principal = [];
            this.dutyRange = [];
            setTimeout(() => {
                this.addDutyGroupDia = true;
            }, 300)
        },

        closeAddduty() {
            this.addDutyGroupDia = false;
        },
        submitAddduty() {
            if (this.groupName.trim() == '') {
                this.$message.warning(this.$t('workDuty.nameRequired'));
                return false;
            }
            if (this.principal.length == 0) {
                this.$message.warning(this.$t('workDuty.dutyRequired'));
                return false;
            }
            if (this.dutyRange.length == 0) {
                this.$message.warning(this.$t('workDuty.dutyScopeRequired'));
                return false;
            }
            if (this.dutyTime == 1 && this.workTimeId == "") {
                this.$message.warning(this.$t('workDuty.workTimeRequired'));
                return false;
            }

            let dutyGroupIds = [], dutyPeopleIds = [], selectAll = false;
            this.dutyRange.forEach(item => {
                if (item.length == 2) {
                    dutyPeopleIds.push(Number(item[1]));
                } else {
                    if (item[0] != -1) {
                        dutyGroupIds.push(Number(item[0]));
                    } else {
                        selectAll = true;
                    }
                }
            })

            if (this.handleType == "add") {
                this.$http
                    .post(
                        `${this.requestUrl.schedulingManagement.configureSchedulingGroups}`, {
                        name: this.groupName.trim(),  // 排班组名称
                        principalId: JSON.stringify(this.principal.map(item => { return Number(item[1]) })),     // 排班组负责人ID
                        scopeOfDuty: {        // 值班范围
                            dutyGroupIds: dutyGroupIds,
                            dutyPeopleIds: dutyPeopleIds
                        },
                        selectAll: selectAll,
                        dutyTime: this.dutyTime,
                        workTimeId: this.workTimeId,
                        ext: JSON.stringify({
                            principal: this.principal, dutyRange: this.dutyRange, dutyTime: this.dutyTime,
                            workTimeId: this.workTimeId,
                        })
                    }
                    )
                    .then((res) => {
                        if (res.data.code === "0") {
                            this.getAllGroup();
                            this.$emit("updateGroupList");
                            this.addDutyGroupDia = false;
                            this.groupName = "";
                            this.principal = [];
                            this.dutyRange = [];
                            this.dutyTime = 0;
                            this.workTimeId = "";
                        }
                    });
            } else {
                let param = this.activeHandleGroup;
                param.name = this.groupName.trim();
                param.principalId = JSON.stringify(this.principal.map(item => { return Number(item[1]) }));
                param.scopeOfDuty = {        // 值班范围
                    dutyGroupIds: dutyGroupIds,
                    dutyPeopleIds: dutyPeopleIds
                };
                param.selectAll = selectAll;
                param.dutyTime = this.dutyTime;
                param.workTimeId = this.workTimeId;
                param.ext = JSON.stringify({
                    principal: this.principal, dutyRange: this.dutyRange, dutyTime: this.dutyTime,
                    workTimeId: this.workTimeId,
                });
                this.$http
                    .put(
                        `${this.requestUrl.schedulingManagement.updateSchedulingGroups}`, param
                    )
                    .then((res) => {
                        if (res.data.code === "0") {
                            this.getAllGroup();
                            this.$emit("updateGroupList");
                            this.addDutyGroupDia = false;
                            this.groupName = "";
                            this.principal = [];
                            this.dutyRange = [];
                            this.dutyTime = 0;
                            this.workTimeId = "";
                        }
                    });
            }



        },
        handleChange() { },
        getAllGroup() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.queryAllSchedulingGroups}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        let tampArr = res.data.data;
                        // tampArr.forEach(item => {
                        //     if (item.selectAll) {
                        //         item.ext = JSON.stringify({ "principal": [], "dutyRange": [[-1]] })
                        //     }
                        // })
                        this.groupList = tampArr;
                    }
                });
        },
        getContactPersonList() {
            this.$http
                .get(
                    `${this.requestUrl.schedulingManagement.getContactPersonList}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.options = res.data.data;
                        this.options.forEach(item => {
                            item.id = String(item.id);
                            item.children = item.accountInfos;
                            item.children.forEach(cell => {
                                cell.id = String(cell.id);
                            })
                        })
                        this.optionsWithoutAll = JSON.parse(JSON.stringify(this.options));
                        this.options.unshift({
                            id: -1,
                            name: "全部",
                            children: [],
                            accountInfos: []
                        })
                        this.options = [...this.options];
                    }
                });
        },
    },
    mounted() {
        // 获取所有排班组
        this.getAllGroup();
        this.canScheduling();
        this.getContactPersonList();
        this.getWorkTimeList();
    }
}
</script>
<style lang="less" scoped>
#manage-duty-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;



    .duty-manage-contanier {
        position: relative;

        .mod-box {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #FF587B;
            top: 0;
            opacity: 0;
        }

        .ask-select-item {
            display: flex;
            flex-direction: column;

            .ask-select-item-title {
                margin-top: 8px;
                height: 38px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #616161;
                line-height: 38px;

                em {
                    color: #FF587B;
                }
            }

            .ask-select-item-content-bgc {
                background-color: #FBFCFD;

                .ask-select-item-content-bgc-inner {
                    height: 56px;
                    display: flex;
                    align-items: center;
                    padding: 0px 12px;

                    .ask-select-item-content-radio {
                        flex: none;
                        width: 240px;
                        display: flex;
                        justify-content: flex-start;

                        /deep/label {
                            margin-right: 15px;
                        }
                    }
                }
            }

            .ask-select-item-content {
                .el-cascader {
                    width: 100%;
                }

                .el-select {
                    width: 100%;

                    /deep/.el-select__tags {
                        display: none;
                    }
                }

                .ask-select-item-content-selected {
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    padding: 6px;

                    .asics-cell {
                        height: 23px;
                        line-height: 23px;
                        background: #ECF1FF;
                        color: #366AFF;
                        margin: 6px;
                        padding: 0 6px 0 8px;
                        display: flex;
                        align-items: center;

                        .asics-cell-delete {
                            scale: 0.8;
                            margin-left: 6px;
                            font-size: 12px;
                            width: 12px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .view-duty-header {
        width: 100%;
        flex: none;
        height: 45px;
        background: #F6F8FD;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vdh-title {
            margin-left: 20px;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;

            .vdh-title-handle {
                margin-left: 16px;
                color: white;
                display: inline-block;
                width: 103px;
                height: 30px;
                line-height: 30px;
                background: #366AFF;
                border: 1px solid #366AFF;
                border-radius: 15px;
                font-size: 14px;
                display: flex;
                align-items: center;
                padding-left: 12px;
                cursor: pointer;
            }
        }

        .vdh-close {
            margin-right: 20px;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .view-duty-content {
        margin-top: 30px;
        flex: auto;
        width: 100%;

        .vdc-title {
            margin: 0 20px;
            height: 38px;
            line-height: 38px;
            background: #F6F8FD;
            display: flex;
            align-items: center;

            .vdc-title-name {
                padding-left: 20px;
                flex: 1;
                text-align: left;
            }

            .vdc-title-duty {
                flex: 1;
                text-align: left;
            }

            .vdc-title-time {
                flex: 1;
                text-align: left;
            }

            .vdc-title-handle {
                width: 80px;
                flex: none;
                text-align: center;
            }
        }

        .vdc-list {
            flex: none;
            overflow-y: auto;
            width: calc(100% - 40px);
            height: calc(100vh - 240px);
            margin-left: 20px;

            .vdc-list-cell {
                display: flex;
                height: 48px;
                align-items: center;
                border-bottom: solid 1px #E0E6F7;

                .vlc-handle {
                    width: 80px;
                    flex: none;
                    text-align: center;
                    display: flex;
                    justify-content: space-evenly;
                    color: #366AFF;

                    .vlc-hanlde {
                        cursor: pointer;
                    }

                }

                .vlc-name {
                    flex: none;
                    width: 172px;
                    display: flex;
                    margin-left: 20px;
                    margin-right: 16px;

                    .vlc-name-text {
                        width: 172px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .vdc-duty {
                    flex: none;
                    text-align: left;
                    width: 186px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .vlc-time {
                    flex: none;
                    text-align: left;
                    width: 186px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .view-duty-footer {
        width: 100%;
        flex: none;
        height: 36px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        .manage-duty {
            margin-left: 20px;
            width: 90px;
            height: 30px;
            line-height: 30px;
            background: #FFFFFF;
            border: 2px solid #A1B9FF;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 0 3px;
            color: #366AFF;
            font-weight: 600;
            cursor: pointer;
        }

        .view-duty-close {
            margin-right: 20px;
            width: 99px;
            height: 33px;
            line-height: 33px;
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            border-radius: 19px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
