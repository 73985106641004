<template>
    <div class="gr-left-content-bottom-b">
        <div class="gr-left-cbb-c gr-left-cbb-l">
            <div class="gr-left-cbb-l-t-b">
                <span class="icon iconfont guoran-jieshengrenli"></span>
            </div>
            <div class="gr-left-cbb-l-chart-b">
                <div class="number-box">
                    <span class="number">{{ economizeOnManpower }}</span>
                    <span class="a-text">{{$t('index.person')}}</span>
                </div>
                <span class="a-text-bottom">{{$t('index.saveBackendManpower')}}</span>
            </div>
        </div>
        <div class="gr-left-cbb-c gr-left-cbb-r">
            <div class="gr-left-cbb-l-t-g">
                <span class="icon iconfont guoran-jieshengchengben"></span>
            </div>
            <div class="gr-left-cbb-l-chart-b">
                <div class="number-box">
                    <span class="number">{{ economizeCost }}</span>
                    <span class="a-text">{{$t('index.tenThousand')}}</span>
                </div>
                <span class="a-text-bottom">{{$t('index.costSavings')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            economizeOnManpower: "",
            economizeCost: "",
        };
    },
    mounted() {
        this.$http
            .get(this.requestUrl.companyManage.selectEstimateValue)
            .then((res) => {
                if (res.data && res.data.code === "0") {
                    this.economizeOnManpower =
                        res.data.data.economizeOnManpower;
                    this.economizeCost = (
                        res.data.data.economizeCost / 10000
                    ).toFixed(1);
                    this.$emit("changeEconomizeOnManpower", this.economizeCost);
                }
            })
            .catch((res) => {});
    },
};
</script>
<style lang="less" scoped>
.gr-left-content-bottom-b {
    background-color: white;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;

    .gr-left-cbb-c {
        flex: 1;
        align-items: center;
        text-align: center;
        display: flex;
        max-width: 150px;

        .gr-left-cbb-l-t-b {
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: #eff3ff;
            border-radius: 50%;
            text-align: center;
            color: rgba(54, 106, 255, 1);
        }

        .gr-left-cbb-l-t-g {
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: #e1faf9;
            border-radius: 50%;
            text-align: center;
            color: rgba(9, 221, 213, 1);

            .a-text {
                color: rgba(97, 97, 97, 1);
            }
        }

        .gr-left-cbb-l-chart-b {
            height: 50px;
            text-align: center;
            line-height: 50px;
            margin-left: 8px;
            .number-box {
                display: flex;
                line-height: 35px;
                .a-text {
                    margin-left: 8px;
                    line-height: 40px;
                    color: rgba(97, 97, 97, 1);
                }
                .number {
                    letter-spacing: -3px;
                    font-size: 26px;
                    font-weight: 800;
                }
            }
            .a-text-bottom{
                text-align: left;
                display: block;
                line-height: 14px;
            }
        }
    }
}
</style>