import { render, staticRenderFns } from "./accessConfiguration.vue?vue&type=template&id=e6d12aee&scoped=true"
import script from "./accessConfiguration.vue?vue&type=script&lang=js"
export * from "./accessConfiguration.vue?vue&type=script&lang=js"
import style0 from "./accessConfiguration.vue?vue&type=style&index=0&id=e6d12aee&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6d12aee",
  null
  
)

export default component.exports