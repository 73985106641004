function formatTree(arr, labelIds) {
    arr.forEach((item, index) => {
        item.indexNumber = index;
        item.labelStr = item.label + "+++___+++" + index + "+++___+++" + arr.length;
        if (labelIds && item.tags && item.tags.length>0) {
            item.tags = item.tags.filter(i => labelIds.includes(i));
        }
        if (item.children && item.children.length > 0) {
            formatTree(item.children, labelIds);
        }
    });
    return arr;
}
function filterTree(tree,ids,arr=[]){
    if (!tree.length) return []
    console.debug('tree',tree,ids)
    for (let item of tree){
        if (ids.indexOf(item.id) !==-1) continue
        let node = {...item,children:[]}
        arr.push(node)
        if (item.children && item.children.length){
            filterTree(item.children,ids,node.children)
        }
    }
    return arr
}
export { formatTree,filterTree } 