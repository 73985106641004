import axios from 'axios';
import { ssoCookieChanged, initSsoUid } from './cookie';
import { isInnerIP } from './ip';

const instance = axios.create();

let doingReSetStorage = false;

async function doReSetStorage(onSuccess, onException, token) {
  if (doingReSetStorage) {
    return;
  }
  doingReSetStorage = true;
  let url = '/api/sso/auth';
  if (token != null) {
    url = url + '?token=' + token;
  }

  await instance.get(url)
    .then((res) => {
      console.log(21, res.data.data.userId)
      localStorage.setItem("_uid", res.data.data.userId);
      localStorage.setItem('_mainId', res.data.data.mainId);
      localStorage.setItem('_corpId',res.data.data.corpId);
      localStorage.setItem("roleId", res.data.data.roleId);
      localStorage.setItem("username", res.data.data.username);
      localStorage.setItem("username", res.data.data.realName);
      localStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
      localStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges ? res.data.data.rolePrivileges : []))
      initSsoUid();
      doingReSetStorage = false;
      console.log(36, onSuccess);
    
      if (onSuccess) {
        onSuccess(res);
      }
      
    }).catch((err) => {
      doingReSetStorage = false;
      console.info('do auth exception', err);
      if (onException) {
        onException(err);
      }
    });
}

function doCheckAndRestStorageInfo() {
  if (isInnerIP) {
    return;
  }
  if (ssoCookieChanged()) {
    doReSetStorage();
  }
}

export { doCheckAndRestStorageInfo, doReSetStorage }