var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"version"}},[_c('div',{staticClass:"gr-content-container"},[_c('div',{staticClass:"personalHeader"},[_c('top-header',[_c('div',{staticClass:"setup-header-left",attrs:{"slot":"setup-header-left"},slot:"setup-header-left"},[_c('span',{staticClass:"setup-header-left-icon"},[_c('i',{staticClass:"iconfont guoran-gongdan"})]),_c('span',[_vm._v(_vm._s(_vm.$t('version.versionDetail')))])])])],1),_c('div',{staticClass:"version-content"},[_c('div',{staticClass:"vc-bn"},[_c('div',{staticClass:"vc-bn-title"},[_c('span',{staticClass:"vc-bn-title-pre"},[_vm._v(_vm._s(_vm.$t('version.versionPre')))]),_c('span',{staticClass:"vc-bn-title-aft"},[_vm._v(" "+_vm._s(_vm.$t('version.versionAft')))])]),_c('div',{staticClass:"vc-bn-des"},[_vm._v(_vm._s(_vm.$t('version.versionDes')))])]),_c('div',{staticClass:"vc-tab"},[_c('span',{class:[
                        _vm.activeSysType === 'OPEN'
                            ? 'vc-tab-cell-active'
                            : 'vc-tab-cell',
                    ],on:{"click":function($event){return _vm.switchSys('OPEN')}}},[_c('i',{staticClass:"pre-icon iconfont guoran-askbotdandutubiao2"}),_c('i',{staticClass:"pre-text iconfont guoran-askbotwenzi2"})]),_c('span',{class:[
                        _vm.activeSysType === 'WORKORDER'
                            ? 'vc-tab-cell-active'
                            : 'vc-tab-cell',
                    ],on:{"click":function($event){return _vm.switchSys('WORKORDER')}}},[_c('i',{staticClass:"pre-icon iconfont guoran-askservice-logo03"}),_c('i',{staticClass:"pre-text iconfont guoran-askservice-logo05"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSysType === 'OPEN'),expression:"activeSysType === 'OPEN'"}],staticClass:"vc-info"},[_c('div',{staticClass:"vc-info-content"},[_c('div',{staticClass:"vc-info-content-inner"},[_c('div',{staticClass:"vc-info-cc vc-info-cc-l-bgc"},[_c('div',{staticClass:"vc-info-cc-inner"},[_c('div',{staticClass:"vc-info-cc-title"},[(_vm.openActiveVersion == 2)?_c('span',{staticClass:"active-version-tip"},[_vm._v(_vm._s(_vm.$t('version.current')))]):_vm._e(),_c('span',{staticClass:"vc-info-cc-title-t"},[_vm._v(_vm._s(_vm.$t('version.major')))]),_c('span',{staticClass:"vc-info-cc-title-des"},[_vm._v(_vm._s(_vm.professional[0][0].description))])]),_c('div',{staticClass:"vc-info-cc-p"},[_c('div',{staticClass:"vc-info-cc-pc"},[_c('span',{staticClass:"vc-info-cc-pc-num vc-info-cc-pc-num-l"},[_vm._v(_vm._s(_vm.professional[0][0].price))]),_c('span',{staticClass:"vc-info-cc-pc-t"},[_vm._v(_vm._s(_vm.$t('version.price')))])]),_c('span',{staticClass:"vc-info-cc-pc-b vc-info-cc-pc-b-b"})]),_c('div',{staticClass:"vc-info-cc-i"},_vm._l((_vm.professional[0][0]
                                            .versionFunctionInfo),function(item,index){return _c('div',{key:index,staticClass:"vc-info-cc-i-cell"},[_vm._m(0,true),_c('span',{staticClass:"vc-info-cc-i-cell-text"},[_vm._v(_vm._s(item))])])}),0)]),(_vm.openActiveVersion == 1)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:('4001-789-800'),expression:"'4001-789-800'",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"vc-info-cc-tel vc-info-cc-tel-b"},[_vm._v(" "+_vm._s(_vm.$t('version.phone'))+" ")]):_vm._e()]),_c('div',{staticClass:"vc-info-cc vc-info-cc-r-bgc"},[_c('div',{staticClass:"vc-info-cc-inner"},[_c('div',{staticClass:"vc-info-cc-title"},[(_vm.openActiveVersion == 3)?_c('span',{staticClass:"active-version-tip"},[_vm._v(_vm._s(_vm.$t('version.current')))]):_vm._e(),_c('span',{staticClass:"vc-info-cc-title-t"},[_vm._v(_vm._s(_vm.$t('version.enterpriseEdition')))]),_c('span',{staticClass:"vc-info-cc-title-des"},[_vm._v(_vm._s(_vm.professional[0][1].description))])]),_c('div',{staticClass:"vc-info-cc-p"},[_c('div',{staticClass:"vc-info-cc-pc"},[_c('span',{staticClass:"vc-info-cc-pc-num vc-info-cc-pc-num-r"},[_vm._v(_vm._s(_vm.professional[0][1].price))]),_c('span',{staticClass:"vc-info-cc-pc-t"},[_vm._v(_vm._s(_vm.$t('version.price')))])]),_c('span',{staticClass:"vc-info-cc-pc-b vc-info-cc-pc-b-g"})]),_c('div',{staticClass:"vc-info-cc-cl"},[_vm._v(" "+_vm._s(_vm.$t('version.versionInfo'))+"： ")]),_c('div',{staticClass:"vc-info-cc-i"},_vm._l((_vm.professional[0][1]
                                            .versionFunctionInfo),function(item,index){return _c('div',{key:index,staticClass:"vc-info-cc-i-cell"},[_vm._m(1,true),_c('span',{staticClass:"vc-info-cc-i-cell-text"},[_vm._v(_vm._s(item))])])}),0)]),(_vm.openActiveVersion == 1 || _vm.openActiveVersion == 2)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:('4001-789-800'),expression:"'4001-789-800'",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"vc-info-cc-tel vc-info-cc-tel-g"},[_vm._v(" "+_vm._s(_vm.$t('version.phone'))+" ")]):_vm._e()])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSysType === 'WORKORDER'),expression:"activeSysType === 'WORKORDER'"}],staticClass:"vc-info"},[_c('div',{staticClass:"vc-info-content"},[_c('div',{staticClass:"vc-info-content-inner"},[_c('div',{staticClass:"vc-info-cc vc-info-cc-l-bgc"},[_c('div',{staticClass:"vc-info-cc-inner"},[_c('div',{staticClass:"vc-info-cc-title"},[(_vm.workorderActiveVersion == 4)?_c('span',{staticClass:"active-version-tip"},[_vm._v(_vm._s(_vm.$t('version.current')))]):_vm._e(),_c('span',{staticClass:"vc-info-cc-title-t"},[_vm._v(_vm._s(_vm.$t('version.freeEdition')))]),_c('span',{staticClass:"vc-info-cc-title-des"})]),_c('div',{staticClass:"vc-info-cc-p"},[_c('div',{staticClass:"vc-info-cc-pc"},[_c('span',{staticClass:"vc-info-cc-pc-num vc-info-cc-pc-num-l"},[_vm._v(_vm._s(_vm.professional[1][1].price))]),_c('span',{staticClass:"pre-vc-info-cc-pc-num"},[_vm._v("1500")]),_c('span',{staticClass:"vc-info-cc-pc-t"},[_vm._v(_vm._s(_vm.$t('version.accountPrice')))])]),_c('span',{staticClass:"vc-info-cc-pc-b vc-info-cc-pc-b-b"})]),_c('div',{staticClass:"vc-info-cc-i"},_vm._l((_vm.professional[1][1]
                                            .versionFunctionInfo),function(item,index){return _c('div',{key:index,staticClass:"vc-info-cc-i-cell"},[_vm._m(2,true),_c('span',{staticClass:"vc-info-cc-i-cell-text"},[_vm._v(_vm._s(item))])])}),0)])]),_c('div',{staticClass:"vc-info-cc vc-info-cc-l-bgc"},[_c('div',{staticClass:"vc-info-cc-inner"},[_c('div',{staticClass:"vc-info-cc-title"},[(_vm.workorderActiveVersion == 2)?_c('span',{staticClass:"active-version-tip"},[_vm._v(_vm._s(_vm.$t('version.current')))]):_vm._e(),_c('span',{staticClass:"vc-info-cc-title-t"},[_vm._v(_vm._s(_vm.$t('version.major')))]),_c('span',{staticClass:"vc-info-cc-title-des"})]),_c('div',{staticClass:"vc-info-cc-p"},[_c('div',{staticClass:"vc-info-cc-pc"},[_c('span',{staticClass:"vc-info-cc-pc-num vc-info-cc-pc-num-l"},[_vm._v(_vm._s(_vm.professional[1][0].price))]),_c('span',{staticClass:"vc-info-cc-pc-t"},[_vm._v(_vm._s(_vm.$t('version.accountPrice')))])]),_c('span',{staticClass:"vc-info-cc-pc-b vc-info-cc-pc-b-b"})]),_c('div',{staticClass:"vc-info-cc-cl-z"},[_vm._v(" "+_vm._s(_vm.$t('version.freeVersion'))+"： ")]),_c('div',{staticClass:"vc-info-cc-i"},_vm._l((_vm.professional[1][0]
                                            .versionFunctionInfo),function(item,index){return _c('div',{key:index,staticClass:"vc-info-cc-i-cell"},[_vm._m(3,true),_c('span',{staticClass:"vc-info-cc-i-cell-text"},[_vm._v(_vm._s(item))])])}),0)]),(_vm.workorderActiveVersion == 1)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:('4001-789-800'),expression:"'4001-789-800'",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"vc-info-cc-tel vc-info-cc-tel-b"},[_vm._v(" "+_vm._s(_vm.$t('version.phone'))+" ")]):_vm._e()])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSysType === 'OPEN'),expression:"activeSysType === 'OPEN'"}],staticClass:"vc-version-d"},[_c('div',{staticClass:"vc-version-d-inner"},[_c('div',{staticClass:"vc-version-d-header"},[_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-l"},[_vm._v(_vm._s(_vm.$t('version.standardProducts')))]),_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-m"},[_vm._v(_vm._s(_vm.$t('version.major')))]),_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-r"},[_vm._v(_vm._s(_vm.$t('version.enterpriseEdition')))])]),_c('div',{staticClass:"vc-version-d-content"},_vm._l((_vm.professionalVersionDetail),function(item,index){return _c('div',{key:index,staticClass:"vc-version-d-content-cell"},[_c('span',{staticClass:"vc-version-dcc-name"},[_c('span',{class:[
                                        'vc-version-dcc-name-i',
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[_c('span',{staticClass:"circ-i"}),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('span',{staticClass:"vc-version-dcc-professional"},[_c('span',{class:[
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[(
                                            !(
                                                item.description !==
                                                    'null' &&
                                                item.description
                                            ) && item.support
                                        )?_c('i',{staticClass:"icon-checked iconfont guoran-a-16-17"}):_vm._e(),(
                                            !(
                                                item.description !==
                                                    'null' &&
                                                item.description
                                            ) && !item.support
                                        )?_c('i',{staticClass:"icon-clocsed iconfont guoran-shanchu"}):_vm._e(),_vm._v(" "+_vm._s(item.description !== "null" && item.description ? `${item.description} ` : "")+" ")])]),_c('span',{staticClass:"vc-version-dcc-enterprise"},[_c('span',{class:[
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[(
                                            !(
                                                item.companyDescription !==
                                                    'null' &&
                                                item.companyDescription
                                            ) && item.companySupport
                                        )?_c('i',{staticClass:"icon-checked iconfont guoran-a-16-17"}):_vm._e(),(
                                            !(
                                                item.companyDescription !==
                                                    'null' &&
                                                item.companyDescription
                                            ) && !item.companySupport
                                        )?_c('i',{staticClass:"icon-clocsed iconfont guoran-shanchu"}):_vm._e(),_vm._v(" "+_vm._s(item.companyDescription !== "null" && item.companyDescription ? `${item.companyDescription} ` : ""))])])])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSysType === 'WORKORDER'),expression:"activeSysType === 'WORKORDER'"}],staticClass:"vc-version-d"},[_c('div',{staticClass:"vc-version-d-inner"},[_c('div',{staticClass:"vc-version-d-header"},[_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-l"},[_vm._v(_vm._s(_vm.$t('version.standardProducts')))]),_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-m"},[_vm._v(_vm._s(_vm.$t('version.freeEdition')))]),_c('span',{staticClass:"vc-version-d-header-cell vc-version-d-header-cell-r-z"},[_vm._v(_vm._s(_vm.$t('version.major')))])]),_c('div',{staticClass:"vc-version-d-content"},_vm._l((_vm.enterpriseVersionDetail),function(item,index){return _c('div',{key:index,staticClass:"vc-version-d-content-cell"},[_c('span',{staticClass:"vc-version-dcc-name"},[_c('span',{class:[
                                        'vc-version-dcc-name-i',
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[_c('span',{staticClass:"circ-i"}),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('span',{staticClass:"vc-version-dcc-professional"},[_c('span',{class:[
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[(
                                            !(
                                                item.description !==
                                                    'null' &&
                                                item.description
                                            ) && item.freeSupport
                                        )?_c('i',{staticClass:"icon-checked iconfont guoran-a-16-17"}):_vm._e(),(
                                            !(
                                                item.description !==
                                                    'null' &&
                                                item.description
                                            ) && !item.freeSupport
                                        )?_c('i',{staticClass:"icon-clocsed iconfont guoran-shanchu"}):_vm._e(),_vm._v(" "+_vm._s(item.description !== "null" && item.description ? `${item.description}` : "")+" ")])]),_c('span',{staticClass:"vc-version-dcc-enterprise-z"},[_c('span',{class:[
                                        index % 2 === 0
                                            ? 'opcity-cell'
                                            : '',
                                    ]},[(
                                            !(
                                                item.companyDescription !==
                                                    'null' &&
                                                item.companyDescription
                                            ) && item.support
                                        )?_c('i',{staticClass:"icon-checked iconfont guoran-a-16-17"}):_vm._e(),(
                                            !(
                                                item.companyDescription !==
                                                    'null' &&
                                                item.companyDescription
                                            ) && !item.support
                                        )?_c('i',{staticClass:"icon-clocsed iconfont guoran-shanchu"}):_vm._e(),_vm._v(" "+_vm._s(item.companyDescription !== "null" && item.companyDescription ? `${item.companyDescription}` : ""))])])])}),0)])])])]),_c('SideNav',{ref:"leftMenuObj"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"vc-info-cc-i-cell-icon vc-info-cc-i-cell-icon-b"},[_c('i',{staticClass:"iconfont guoran-xuanzhong-"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"vc-info-cc-i-cell-icon vc-info-cc-i-cell-icon-g"},[_c('i',{staticClass:"iconfont guoran-xuanzhong-"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"vc-info-cc-i-cell-icon vc-info-cc-i-cell-icon-b"},[_c('i',{staticClass:"iconfont guoran-xuanzhong-"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"vc-info-cc-i-cell-icon vc-info-cc-i-cell-icon-b"},[_c('i',{staticClass:"iconfont guoran-xuanzhong-"})])
}]

export { render, staticRenderFns }