
<template>
    <div id="data-role-manage">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-03"></i
                        ></span>
                        <span>{{$t('common.enterpriseEdition')}} - </span>
                        <span>{{$t('dataRole.title')}}</span>
                    </div>
                    <div
                        class="setup-header-right-l"
                        slot="setup-header-right-l"
                    >
                        <el-button
                            size="small"
                            type="primary"
                            @click="addRole"
                            v-if="operableRole('PORTAL_MANAGEMENT_ROLE_ADD')"
                        >
                            <i class="iconfont guoran-a-16-13"></i>
                            {{$t('dataRole.addDataRoleText')}}</el-button
                        >
                    </div>
                </top-header>
            </div>
            <div class="content">
                <div class="manage-nav-section">
                    <ManangeNav></ManangeNav>
                </div>
                <div class="content-section">
                    <div class="content-box">
                        <role-table
                            ref="roleTable"
                            @c_editDataRole="p_editDataRole"
                        ></role-table>
                    </div>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
        <popup v-if="handleVisible" @closeEvent="handleVisible = false">
            <div slot="popup-name" class="popup-name">{{ addDialoTitle }}</div>
            <div slot="popup-con">
                <div id="add-or-edit-data-role-box">
                    <AddDataRole
                        ref="addDataRoleInner"
                        v-if="handleVisible"
                        :editObjStr="editObjStr"
                        :handleType="handleType"
                        @c_closeHandleDataRole="p_closeHandleDataRole"
                    />
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="handleVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="saveDataRole"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from "@/components/popup";
import SideNav from "./../../menu/SideNav.vue";
import TopHeader from "./../../../components/top-header.vue";
import DeleteDialog from "./../../../components/deleteDialogue.vue";
import pagination from "./../../../components/pagination.vue";
import ManangeNav from "./../../menu/ManageNave.vue";
import RoleTable from "./components/dataRoleTableU";
import AddDataRole from "./components/addDataRoleU.vue";
import { operable } from "../js/operable";

export default {
    name: "roleManage",
    data() {
        return {
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            rolePrivilegesList: [],
            handleVisible: false,
            addDialoTitle: this.$t('dataRole.addDataRoleText'),
            handleType: "add", // add edit
            editObjStr: "",
        };
    },
    components: {
        RoleTable,
        Popup,
        SideNav,
        TopHeader,
        DeleteDialog,
        pagination,
        ManangeNav,
        AddDataRole,
    },
    mounted() {
        if (this.$route.params.showAdd) {
            this.handleVisible = true;
        }
    },
    computed: {
        operableRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        saveDataRole(){
            this.$refs.addDataRoleInner.saveDataRole();
        },
        addRole() {
            this.editObjStr = "";
            this.addDialoTitle = this.$t('dataRole.addDataRoleText');
            this.handleType = "add";
            this.handleVisible = true;
        },
        p_closeHandleDataRole(type) {
            if (type == "save") {
                this.$refs.roleTable.getRoles();
            }
            this.handleVisible = false;
        },
        p_editDataRole(row) {
            this.handleType = "edit";
            this.addDialoTitle = this.$t('dataRole.editDataRoleText');
            let skills = [];
            JSON.parse(row).userBotPrivileges.forEach((bot) => {
                if (!bot.skillIds || bot.skillIds.length == 0) {
                    skills.push([bot.botId]);
                } else {
                    skills = skills.concat(
                        bot.skillIds.map((id) => {
                            return [bot.botId, id];
                        })
                    );
                }
            });
            let woInfoList = [];
            JSON.parse(row).userWorkOrderPrivileges.forEach((wo) => {
                woInfoList.push({
                    type: wo.fatherNodeType,
                    name: wo.fatherNodeTag,
                    onlyAssignToGroup:wo.onlyAssignToGroup,
                    scopes:
                        wo.fatherNodeType == 4
                            ? wo.workOrderId.map((x) => {
                                if(x == "CURRENT_ACCOUNT_GROUP") {
                                    return x
                                }
                                return Number(x)
                            })
                            : wo.workOrderId,
                });
            });

            let workOrderStatisticsPrivileges = [];
            // if (JSON.parse(row).userWorkOrderStatisticsPrivileges == null || JSON.parse(row).userWorkOrderStatisticsPrivileges == 'null') {
            //     workOrderStatisticsPrivileges = "[]"
            // } else {
            //     workOrderStatisticsPrivileges = JSON.parse(row).userWorkOrderStatisticsPrivileges;
            // }
            JSON.parse(row).userWorkOrderStatisticsPrivileges.forEach((wo) => {
                workOrderStatisticsPrivileges.push({
                    type: wo.fatherNodeType,
                    name: wo.fatherNodeTag,
                    onlyAssignToGroup:wo.onlyAssignToGroup,
                    scopes:
                        wo.fatherNodeType == 4
                            ? wo.workOrderId.map((x) => {
                                if(x == "CURRENT_ACCOUNT_GROUP") {
                                    return x
                                }
                                return Number(x)
                            })
                            : wo.workOrderId,
                });
            });

            this.editObjStr = JSON.stringify({
                name: JSON.parse(row).privilegesName,
                description: JSON.parse(row).description,
                id: JSON.parse(row).id,
                skills: skills,
                woInfoList: woInfoList,
                workOrderStatisticsPrivileges: workOrderStatisticsPrivileges
            });
            this.handleVisible = true;
        },
        handleSizeChange() {},
        handleCurrentChange() {},
    },
};
</script>

<style scoped lang="less">
@import "../../../assets/less/dataRoleManage/dataRoleManageU";
</style>