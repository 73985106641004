import { render, staticRenderFns } from "./setDetail.vue?vue&type=template&id=0e7c0d74&scoped=true"
import script from "./setDetail.vue?vue&type=script&lang=js"
export * from "./setDetail.vue?vue&type=script&lang=js"
import style0 from "./setDetail.vue?vue&type=style&index=0&id=0e7c0d74&prod&lang=less"
import style1 from "./setDetail.vue?vue&type=style&index=1&id=0e7c0d74&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7c0d74",
  null
  
)

export default component.exports