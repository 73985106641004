import { render, staticRenderFns } from "./deleteDialogue.vue?vue&type=template&id=bc2a19f4&scoped=true"
import script from "./deleteDialogue.vue?vue&type=script&lang=js"
export * from "./deleteDialogue.vue?vue&type=script&lang=js"
import style0 from "./deleteDialogue.vue?vue&type=style&index=0&id=bc2a19f4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc2a19f4",
  null
  
)

export default component.exports