export default{
	data(){
		return{
			checkedDeptPopup:[]
		}
	},
    methods:{
		handlerChildrens(data,checkedList,newList,type) {
			data.forEach((item,index) => {
				delete item.isFirstNode;
                delete item.isTop;
                delete item.isBottom;
                delete item.length;
                delete item.parentObj;
                delete item.copyList;
				if (checkedList && checkedList.length > 0) {
					checkedList.forEach(v => { 
						if (v.id === item.id) {
							data.splice(index, 1);
						} else { 
							if (item.children && item.children.length > 0) {
								this.handlerChildrens(item.children,checkedList,newList,type);
							}
						}
					})
					// if(checkedList[0].id === item.id){
					// 	data.splice(index,1);
					// } else {
					// 	if (item.children && item.children.length > 0) {
					// 		this.handlerChildrens(item.children,checkedList,newList,type);
					// 	}
					// }
				} else {
					if (item.children && item.children.length > 0) {
						this.handlerChildrens(item.children);
					}
				}
			});
			return data;
		},
		// 递归取出checked=true的数据
		judgeChildrens(data) {
			data.forEach(item => {
				if (item.checked) {
					this.checkedDeptPopup.push(item);
				} 
				if (item.children && item.children.length > 0) {
					this.judgeChildrens(item.children || []);
				}
			});
			return 
		},
        // 递归获取所有节点
		 getAllNodeId (moduleDataList,data) {
			return new  Promise((resolve)=>{
				if (moduleDataList){
					for (let i = 0; i < moduleDataList.length; i++) {
						if(data.id === moduleDataList[i].id){
							this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
						} else {
							if(!this.isMultiple){
								this.$set(moduleDataList[i],'checked',false);
							}
						}
						if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
							this.getAllNodeId( moduleDataList[i].children,data)
						}
					}
				}
				resolve(true)
			})

		},
		
    }
}