<template>
  <div class="input-box">
    <div 
        class="input-content"
        @keydown="keydown" 
        @keyup="keyup" 
        @paste="paste" 
        @mousewheel="mousewheel"
        @input="inputEvent"
        @blur="blurEvent"
        tabindex="-1"
        >
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[0] = input[0].replace(/[^\d]/g,'')"  @afterpaste="input[0] = input[0].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="0" v-model.trim.number="input[0]" type="number" ref="firstinput"/>
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[1] = input[1].replace(/[^\d]/g,'')"  @afterpaste="input[1] = input[1].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="1" v-model.trim.number="input[1]" type="number"/>
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[2] = input[2].replace(/[^\d]/g,'')"  @afterpaste="input[2] = input[2].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="2" v-model.trim.number="input[2]" type="number"/>
      <div class="line"></div>
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[3] = input[3].replace(/[^\d]/g,'')"  @afterpaste="input[3] = input[3].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="3" v-model.trim.number="input[3]" type="number"/>
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[4] = input[4].replace(/[^\d]/g,'')"  @afterpaste="input[4] = input[4].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="4" v-model.trim.number="input[4]" type="number"/>
      <!-- <input type="password" style="display: none;"> -->
      <input auto-complete='new-password'  autocomplete='off'  @keyup="input[5] = input[5].replace(/[^\d]/g,'')"  @afterpaste="input[5] = input[5].replace(/[^\d]/g,'') "  max="9" min="0" maxlength="1" data-index="5" v-model.trim.number="input[5]" type="number" ref="lastinput"/>
    </div>
  </div>
</template>


<script>
export default {
    data() {
      return {
        // 存放粘贴进来的数字
        pasteResult: [],
      };
    },
    props: ['code'],
    computed: {
      input() {
        // code 是父组件传进来的默认值，必须是6位长度的数组，这里就不再做容错判断处理
        // 最后空数组是默认值
        // console.log(this.code || this.pasteResult.length === 6 ? this.pasteResult : ['', '', '', '', '', ''],'默认值');
        return this.code || this.pasteResult.length === 6 ? this.pasteResult : ['', '', '', '', '', '']

      }
    },
    watch:{
        input:{
            handler(n){
                console.log(n,'mmm');
                // this.$nextTick(() => {
                //     document.querySelector('.input-content').addEventListener('focus',() => {
                //         console.log(2222);
                //     })
                //      document.querySelector('.input-content').addEventListener('click',() => {
                //         console.log(33333);
                //     })
                // })
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
        // 解决一个输入框输入多个字符
        inputEvent(e) {
            let index = e.target.dataset.index * 1;
            let el = e.target;
            this.$set(this.input, index, el.value.slice(0, 1))
        },
        keydown(e) {
            
            let index = e.target.dataset.index * 1;
            let el = e.target;
            // console.log(e.key);
            if (e.key === 'Backspace') {
                // console.log(this.input[index]);
                if (this.input[index] > 0) {
                    this.$set(this.input, index, '')
                } else {
                    // 返回指定元素的前一个元素
                    this.handlerPreviousFocus(el)
                    if (el.previousElementSibling) {
                        this.$set(this.input, index - 1, '')
                    }
                }
            } else if (e.key === 'Delete') {
                // Delete = fn+delete键。
                // console.log(el.previousElementSibling);
                if (this.input[index] > 0) {
                    this.$set(this.input, index, '')
                } else {
                    this.handlerPreviousFocus(el)
                    if (el.previousElementSibling) {
                        this.$set(this.input, index - 1, '')
                    }
                }
            } else if (e.key === 'Home') {
                // Home 键= fn+左方向
                el.parentElement.children[0] && el.parentElement.children[0].focus()
            } else if (e.key === 'End') {
                // End 键= fn+右方向
                
                this.$refs.lastinput.focus()
                /** el.parentElement.children[this.input.length - 1] && el.parentElement.children[this.input.length - 1].focus() */
            } else if (e.key === 'ArrowLeft') {
                this.handlerPreviousFocus(el)
                // if (el.previousElementSibling) {
                   
                //     el.previousElementSibling.focus()
                // }
            } else if (e.key === 'ArrowRight') {
                this.handlerNextFocus(el)
                // if (el.nextElementSibling) {
                //     el.nextElementSibling.focus()
                // }
            } else if (e.key === 'ArrowUp') {
                if (this.input[index] * 1 < 9) {
                    this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
                }
            } else if (e.key === 'ArrowDown') {
                if (this.input[index] * 1 > 0) {
                    this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
                }
            }
            console.log('keydown');
            this.$emit('complete', this.input);
        },
        keyup(e) {
            
            let index = e.target.dataset.index * 1;
            let el = e.target;
            if (/Digit|Numpad/i.test(e.code)) {
                // console.log('el',el);
                this.$set(this.input, index, e.code.replace(/Digit|Numpad/i, ''));
                // 返回元素的下一个兄弟元素，如果没有下一个兄弟元素则返回 null。
                // console.log(el.nextElementSibling && el.nextElementSibling.className,'下一个节点');
                this.handlerNextFocus(el)
                // if (index === 5) {
                    // if (this.input.join('').length === 6) {
                        // document.activeElement.blur();
                        console.log('keyup');
                        this.$emit('complete', this.input);
                    // }
                // }
            } else {
                if (this.input[index] === '') {
                    this.$set(this.input, index, '');
                }
            }
        },
        // 上一个元素聚焦处理
        handlerPreviousFocus(el){
            if(el.previousElementSibling){
                if(el.previousElementSibling.className && el.previousElementSibling.className === 'line'){
                    el.previousElementSibling.previousElementSibling && el.previousElementSibling.previousElementSibling.focus();
                } else {
                    el.previousElementSibling && el.previousElementSibling.focus();
                }
            }
        },
        // 下一个元素聚焦处理
        handlerNextFocus(el){
            if( el.nextElementSibling){
                if(el.nextElementSibling.className && el.nextElementSibling.className === 'line'){
                    el.nextElementSibling.nextElementSibling && el.nextElementSibling.nextElementSibling.focus();
                } else {
                    el.nextElementSibling && el.nextElementSibling.focus();
                }
            }
        },
        mousewheel(e) {
            // var index = e.target.dataset.index;
            // if (e.wheelDelta > 0) {
            // if (this.input[index] * 1 < 9) {
            //     this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
            // }
            // } else if (e.wheelDelta < 0) {
            // if (this.input[index] * 1 > 0) {
            //     this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
            // }
            // } else if (e.key === 'Enter') {
            // if (this.input.join('').length === 6) {
            //     document.activeElement.blur();
            //     this.$emit('complete', this.input);
            // }
            // }
        },
        paste(e) {
            // 当进行粘贴时 1234567
            e.clipboardData.items[0].getAsString(str => {
                // console.log(str,'str');
                if(str.toString().length > 6){
                    let strs = str.slice(0,6)
                    this.pasteResult = strs.split('');
                    this.$emit('complete', this.input);
                } else if (str.toString().length === 6) {
                    this.pasteResult = str.split('');
                   
                }
                this.pasteResult = str.split('');
                document.activeElement.blur();
            })
        },
        blurEvent(){
            // console.log('失焦--');
            this.$emit('blurEvent', this.input);
        },
    },
    // mounted() {
    //   // 等待dom渲染完成，在执行focus,否则无法获取到焦点
    //   this.$nextTick(() => {
    //     this.$refs.firstinput.focus()
    //   })
    // },
}
</script>

<style lang="less" scoped>
    .input-box {
        .input-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            input {
                color: inherit;
                border: 0;
                outline: 0;
                width: 39px;
                height: 38px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 11px;
                text-align: center;
                margin-right: 10px;
                flex-shrink: 0;
                font-size: 18px;
                &:last-child{
                    margin-right: 0;
                }
                &:focus{
                    border-color: #366AFF;
                }
            }
            div{
                // position: absolute;
                // left: 160px;
                margin-right:  10px;
                width: 10px;
                height: 1px;
                background-color: #A9B3C6;
                flex-shrink: 0;

            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }
</style>
