<template>
    <div id="staff-list">
        <div
            v-loading="outerLoading"
            ref="slleftreeoter"
            v-show="!isSeaching"
            class="sl-left-tree"
        >
            <!-- <el-tree
                v-show="treeVisable && lazyLoad"
                id="selectOrganizationTree"
                :data="treeData"
                node-key="id"
                :expand-on-click-node="false"
                :current-node-key="activeNodeId"
                :default-expanded-keys="defaultCheckedKeys"
                :default-checked-keys="defaultCheckedKeys"
                @node-click="handleNodeClick"
                @node-collapse="nodeCollapse"
                @node-expand="nodeExpand"
                :default-expand-all="!lazyLoad"
                :load="loadNode"
                :props="defaultProps"
                lazy
                ref="lefttree"
            >
                <span class="el-tree-title" slot-scope="{ node, data }">
                    <span>
                        <span class="el-tree-title-word">
                            <i
                                style="color: #a1b9ff; margin-right: 5px"
                                class="
                                    iconfont
                                    guoran-wenjianjia-zhankaizhuangtai--folderOpen
                                "
                            ></i>
                        </span>
                        <span
                            class="left-name"
                            v-if="corpType == 0 && authType == 0"
                        >
                            <open-data
                                :type="'departmentName'"
                                :openid="data.label"
                            ></open-data>
                        </span>
                        <span v-else class="el-tree-title-word-name">{{
                            data.label
                        }}</span>
                    </span>
                    <span>
                        <el-dropdown @command="handleCommand($event, node, data)" trigger="hover">
                            <span class="el-dropdown-link">
                                <i class="arsenal_icon arsenalgengduo"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dept-operation-dropdown">
                                <el-dropdown-item :command="'addSameDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>添加同级部门</span>
                                </el-dropdown-item>
                                <el-dropdown-item command="setSysAcc">
                                    <i class="iconfont guoran-shezhiquanxian"></i>
                                    <span>设置系统权限</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>添加子部门</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveUp'" :disabled="data.order === 0" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-shangyi"></i>
                                    <span>上移</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveDown'" :disabled="data.order === node.parent.childNodes.length - 1" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-xiayi"></i>
                                    <span>下移</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_UPDATE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-16-15"></i>
                                    <span>编辑</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_DELETE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-18-13"></i>
                                    <span>删除</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
                </span>
            </el-tree> -->
            <el-tree
                key="selectOrganizationTreeLazy"
                v-show="treeVisable && lazyLoad"
                :data="treeData"
                id="selectOrganizationTreeNew"
                class="filter-tree"
                node-key="id"
                :default-expanded-keys="defaultExpandedKeysMember"
                :expand-on-click-node="false"
                @node-expand="nodeExpandNew"
                @node-click="handleNodeClick"
                ref="newTreeLazy'"
            >
                <span
                    class="el-tree-title"
                    slot-scope="{ node, data }"
                >
                <!-- @click="handleNodeClick(data, '', item, node)" -->

                    <span>
                        <i
                            :class="['el-icon-caret-new',
                                data.showChildren ? 'el-icon-caret-bottom' : 'el-icon-caret-right',
                            ]"
                            v-show="data.type == 1"
                            @click.stop="nodeExpandNew(data, node)"
                        >
                        </i>
                        <i
                            class="el-icon-caret-new"
                            v-show="data.type !== 1"
                        >
                        </i>
                        <span v-show="data.dataType !== 'hasNext' && data.type !== 0" class="el-tree-title-word">
                            <i
                                style="color: #a1b9ff; margin-right: 5px"
                                class="
                                    iconfont
                                    guoran-wenjianjia-zhankaizhuangtai--folderOpen
                                "
                            ></i>
                        </span>
                        <span
                            class="left-name"
                            v-if="corpType == 0 && authType == 0"
                        >
                        <!-- v-if="data.type === 1 && isNumber(data.label)" -->
                            <open-data
                                :type="'departmentName'"
                                :openid="data.label"
                            ></open-data>
                        </span>
                        <span v-else :class="['el-tree-title-word-name', data.dataType == 'hasNext'?'loadMoreTree':'']">{{
                            data.label
                        }}</span>
                        <template v-if="data.tags && data.tags.length != 0">
                            <span class="dept_tag">
                               {{ filterTagName(data.tags[0]) }}
                            </span>
                            <el-popover
                                placement="bottom-start"
                                trigger="hover"
                                v-if="data.tags && data.tags.length > 1"
                                >
                                <div class="more_dept_tag_content">
                                    <span class="more_dept_tag_content-cell" v-for="(tagId, tagIndex) in data.tags" :key="tagIndex">{{ filterTagName(tagId) }}</span>
                                </div>
                                <section slot="reference" class="more_dept_tag_btn">
                                    <i class="arsenal_icon arsenalgengduo"></i>
                                </section>
                            </el-popover>
                        </template>
                    </span>
                    <span v-show="data.dataType !== 'hasNext'">
                        <el-dropdown @command="handleCommand($event, node, data)" trigger="hover">
                            <span class="el-dropdown-link">
                                <i class="arsenal_icon arsenalgengduo"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dept-operation-dropdown">
                                <el-dropdown-item :command="'addSameDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>{{$t('staffManage.addSameDepart')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item command="setSysAcc">
                                    <i class="iconfont guoran-shezhiquanxian"></i>
                                    <span>{{$t('staffManage.setSystemPermissions')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item command="setTags">
                                    <i class="iconfont guoran-shezhiquanxian"></i>
                                    <span>{{$t('staffManage.setLabel')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>{{$t('staffManage.addChildrenDepart')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveUp'" :disabled="data.order === 0" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-shangyi"></i>
                                    <span>{{$t('staffManage.up')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveDown'" :disabled="data.order === node.parent.childNodes.length - 1" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-xiayi"></i>
                                    <span>{{$t('staffManage.down')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_UPDATE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-16-15"></i>
                                    <span>{{$t('staffManage.edit')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_DELETE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-18-13"></i>
                                    <span>{{$t('staffManage.delete')}}</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
                </span>
            </el-tree>
            <el-tree
                v-show="treeVisable && !lazyLoad"
                id="selectOrganizationTree"
                :data="treeData"
                node-key="id"
                :expand-on-click-node="false"
                :current-node-key="activeNodeId"
                :default-expanded-keys="defaultCheckedKeys"
                :default-checked-keys="defaultCheckedKeys"
                @node-click="handleNodeClick"
                @node-collapse="nodeCollapse"
                @node-expand="nodeExpand"
                :default-expand-all="true"
                ref="lefttree_false"
            >
                <span class="el-tree-title" slot-scope="{ node, data }">
                    <span>
                        <span class="el-tree-title-word">
                            <i
                                style="color: #a1b9ff; margin-right: 5px"
                                class="
                                    iconfont
                                    guoran-wenjianjia-zhankaizhuangtai--folderOpen
                                "
                            ></i>
                        </span>
                        <span
                            class="left-name"
                            v-if="corpType == 0 && authType == 0"
                        >
                        <!-- v-if="data.type === 1 && isNumber(data.label)" -->
                            <open-data
                                :type="'departmentName'"
                                :openid="data.label"
                            ></open-data>
                        </span>
                        <span v-else class="el-tree-title-word-name">{{
                            data.label
                        }}</span>
                        <template v-if="data.tags && data.tags.length != 0">
                            <span class="dept_tag">
                               {{ filterTagName(data.tags)[0] }}
                            </span>
                            <el-popover
                                placement="bottom-start"
                                trigger="hover"
                                v-if="data.tags && data.tags.length > 1"
                                >
                                <div class="more_dept_tag_content">
                                    <span v-for="(tagId, tagIndex) in data.tags" :key="tagIndex">{{ filterTagName(tagId) }}</span>
                                </div>
                                <section slot="reference" class="more_dept_tag_btn">
                                    <i class="arsenal_icon arsenalgengduo"></i>
                                </section>
                            </el-popover>
                        </template>
                    </span>
                    <span>
                        <el-dropdown @command="handleCommand($event, node, data)" trigger="hover">
                            <span class="el-dropdown-link">
                                <i class="arsenal_icon arsenalgengduo"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dept-operation-dropdown">
                                <el-dropdown-item :command="'addSameDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>{{$t('staffManage.addSameDepart')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item command="setSysAcc">
                                    <i class="iconfont guoran-shezhiquanxian"></i>
                                    <span>{{$t('staffManage.setSystemPermissions')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item command="setTags">
                                    <i class="iconfont guoran-shezhiquanxian"></i>
                                    <span>{{$t('staffManage.setLabel')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'addChildDept'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_ADD') !== -1 && corpType == 21">
                                    <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
                                    <span>{{$t('staffManage.addChildrenDepart')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveUp'" :disabled="data.order === 0" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-shangyi"></i>
                                    <span>{{$t('staffManage.up')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'moveDown'" :disabled="data.order === node.parent.childNodes.length - 1" v-if="node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-xiayi"></i>
                                    <span>{{$t('staffManage.down')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'edit'" v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_UPDATE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-16-15"></i>
                                    <span>{{$t('staffManage.edit')}}</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'del'"  v-if="rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DEPT_DELETE') !== -1 && node.level !== 1 && corpType == 21">
                                    <i class="iconfont guoran-a-18-13"></i>
                                    <span>{{$t('staffManage.delete')}}</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
                </span>
            </el-tree>
        </div>
        <div class="sl-content">
            <div :class="isSeaching ? 'staff-table-search' : 'staff-table'" v-loading="tableLoading">
                <!-- :row-key="bridgecode" -->
                <el-table
                    :data="tableData"
                    height="calc(100vh - 218px)"
                    :row-key="bridgecode"
                    :style="{
                        width: 'calc(100vw - ' + (420 + treeWidth) + 'px)',
                    }"
                    :header-cell-style="{textAlign:'left'}"
                    ref="multipleTable"
                    v-loading="staffLoading"
                    @selection-change="handleSelectionChange"
                >
                    <template slot="empty">
                        <DefaultImage></DefaultImage>
                    </template>
                    <el-table-column type="selection" :reserve-selection="true">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('staffManage.name')" width="200"  align="left">
                        <template slot-scope="scope">
                            <div class="user-dept-box">
                                <template
                                    v-if="
                                        scope.row.source === 0 &&
                                        (scope.row.name.substr(0, 4) ===
                                            'woWe' ||
                                            isStrings(scope.row.name))
                                    "
                                >
                                    <span class="sl-name-pre">
                                        <span class="order-reporter-avatar">
                                            <i
                                                class="iconfont guoran-a-18-32"
                                            ></i>
                                        </span>
                                    </span>
                                    <div class="dept-box">
                                        <span class="userName">
                                            <open-data :type="'userName'" :openid="scope.row.name"></open-data>
                                        </span>
                                        <div
                                            v-for="(cell, index) in scope.row.departments"
                                            :key="index"
                                        >
                                            <el-tooltip placement="top" effect="light">
                                                <div slot="content">
                                                    <open-data :type="'departmentName'" :openid="cell.name"></open-data>
                                                </div>
                                                <open-data :type="'departmentName'" :openid="cell.name"></open-data>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <span class="sl-name-pre">{{scope.row.name.substr(0, 1)}}</span>
                                    <div style="display:inline-block">
                                        <span>{{ scope.row.name }}</span>
                                        <div class="dept-box dept-boxs">
                                            <div
                                                v-for="(cell, index) in scope.row.departments"
                                                :key="index">
                                                <el-tooltip placement="top" effect="light">
                                                    <div slot="content">
                                                        <span v-html="cell.fullName"></span>
                                                    </div>
                                                    <span style="font-size:12px">{{ cell.name }}</span>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                     <el-table-column
                        class-name="activate-status"
                        align="left"
                        width="120"
                        :label="$t('staffManage.phone')">
                        <template slot-scope="scope">
                            <span>{{phoneShowToUser(scope.row.mobile)}}</span>
                        </template>
                     </el-table-column>
                    <!-- <el-table-column width="180" label="部门">
                        <template slot-scope="scope">
                            <div
                                v-for="(cell, index) in scope.row.departments"
                                :key="index"
                            >
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                        <span
                                            v-if="
                                                cell.source === 1 &&
                                                isNumber(cell.fullName)
                                            "
                                        >
                                          <open-data :type="'departmentName'" :openid="cell.fullName"></open-data>
                                        </span>
                                        <span
                                            v-else
                                            v-html="cell.fullName"
                                        ></span>
                                    </div>
                                    <span
                                        v-if="
                                            cell.source === 1 &&
                                            isNumber(cell.name)
                                        "
                                    >
                                      <open-data :type="'departmentName'" :openid="cell.name"></open-data>
                                    </span>
                                    <span v-else>{{ cell.name }}</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column width="120" :label="$t('staffManage.birthday')">
                        <template slot-scope="scope">
                            <span v-if="scope.row.birthday">{{scope.row.birthday}}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" :label="$t('staffManage.staffIn')" v-if="!isMnHr">
                        <template slot-scope="scope">
                            <span v-if="scope.row.staffIn">{{scope.row.staffIn}}</span>
                            <span v-else-if="scope.row.ext && scope.row.ext.hiredDate">
                                {{ new Date(scope.row.ext.hiredDate).Format('yyyy-MM-dd')}}
                            </span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="tags"
                        min-width="260"
                        max-width="300"
                        class-name="member_tags"
                        :label="$t('staffManage.memberTag')">
                        <template #header>
                                <el-dropdown @command="handleMemberTags" trigger="click" @visible-change="visibleTagsChange">
                                    <span class="el-dropdown-link">
                                        {{$t('staffManage.tag')}}
                                        <i :class="tagsIcon"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="null">{{$t('staffManage.all')}}</el-dropdown-item>
                                        <el-dropdown-item class="tags-dropdown" v-for="tag in tagsList" :key="tag.id" :command="tag.id">
                                            <span class="tags-dropdown-name">{{tag.label}}</span>
                                            <i class="el-icon-check" v-show="tagsId.includes(tag.id)"></i>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                        </template>
                        <template slot-scope="scope">
                            <div class="member_tags_content">
                                <template v-if="scope.row.tags && scope.row.tags.length != 0">
                                    <span class="member_tags_label" v-for="(label, labelIndex) in filterTagName(scope.row.tags)" :key="labelIndex">
                                        {{ label }}
                                    </span>
                                </template>
                                <template v-else>
                                    --
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                    <template v-if="isMnHr && mnHrFieldList.length != 0">
                        <el-table-column  v-for="(field,fieldIndex) in mnHrFieldList"
                            :key="fieldIndex"
                            width="120" :label="field.label">
                        <template slot-scope="scope">
                            <span v-if="scope.row.mengNiuEmployee">
                                {{ mnHrFieldEcho(scope.row.mengNiuEmployee, field.key) }}
                            </span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    </template>
                    

                    <el-table-column
                        prop="activateStatus"
                        min-width="150"
                        class-name="activate-status"
                        align="left"
                        :label="$t('staffManage.botActivation')">
                        <template #header>
                                <el-dropdown @command="handleCommandStatus" @visible-change="visibleChange">
                                <span class="el-dropdown-link">
                                    {{activeStatusName}}
                                    <i :class="sortGroupIcon"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="null">{{$t('staffManage.all')}}</el-dropdown-item>
                                    <el-dropdown-item :command="0">{{$t('staffManage.notActive')}}</el-dropdown-item>
                                    <el-dropdown-item :command="1">{{$t('staffManage.activationSuccess')}}</el-dropdown-item>
                                    <el-dropdown-item :command="2">{{$t('staffManage.activationFail')}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                        <template slot-scope="scope">
                            <!-- 0 未激活 1 激活 2激活失败 -->
                            <span v-if="scope.row.activateStatus === 0" style="color:#9DA5BF">{{$t('staffManage.notActive')}}</span>
							<span v-else-if="scope.row.activateStatus === 1" style="color:#39C176">{{$t('staffManage.activationSuccess')}}</span>
                            <span v-else style="color:#FE5965">
                                <span>{{$t('staffManage.activationFail')}}</span>
                                <el-tooltip v-if="promoteSurplusDay < 0 && scope.row.botAuthBeyond"  :content="$t('staffManage.notAvailable')" :visible-arrow="false" placement="bottom" effect="light" popper-class="beyond-tooltip">
                                    <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe" style="color:#666666;cursor:pointer;margin-left:4px;vertical-align:middle;"></i>
                                </el-tooltip>
                            </span>
                            <!-- <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 && scope.row.botAuthBeyond && scope.row.activateStatus === 2">超出购买人数，不可激活</span>  -->
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bigModelActivateStatus"
                        min-width="150"
                        class-name="activate-status"
                        align="left"
                        :label="$t('staffManage.modelActive')">
                        <template #header>
                                <el-dropdown @command="handleCommandStatusBigStatus" @visible-change="visibleChangeBigModel">
                                <span class="el-dropdown-link">
                                    {{activeStatusNameBigModel}}
                                    <i :class="sortGroupIcon"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="null">{{$t('staffManage.all')}}</el-dropdown-item>
                                    <el-dropdown-item :command="0">{{$t('staffManage.notActive')}}</el-dropdown-item>
                                    <el-dropdown-item :command="1">{{$t('staffManage.activationSuccess')}}</el-dropdown-item>
                                    <el-dropdown-item :command="2">{{$t('staffManage.activationFail')}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                        <template slot-scope="scope">
                            <!-- 0未激活，1激活，2激活失败，null就处理成和0一样 -->
                            <span v-if="!scope.row.bigModelActivateStatus || scope.row.bigModelActivateStatus == 0" style="color:#9DA5BF">{{$t('staffManage.notActive')}}</span>
							<span v-else-if="scope.row.bigModelActivateStatus == 1" style="color:#39C176">{{$t('staffManage.activationSuccess')}}</span>
                            <span v-else style="color:#FE5965">
                                <span>{{$t('staffManage.activationFail')}}</span>
                                <span class="left-sys-acc-tip" v-if="promoteSurplusDay < 0 && scope.row.botAuthBeyond">{{$t('staffManage.unactivable')}}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="botAuth"
                        align="left"
                        width="90"
                        :label="$t('staffManage.bot')">
                        <template slot-scope="scope">
                          <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;" v-if="scope.row.botAuth"></i>
                          <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bigModel"
                        align="left"
                        width="90"
                        :label="$t('staffManage.bigModelCapability')">
                        <template slot-scope="scope">
                          <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="scope.row.bigModel"></i>
                          <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="workOrderAuth"
                        align="left"
                        width="90"
                        :label="$t('staffManage.workOrder')">
                        <template slot-scope="scope">
                          <i class="iconfont guoran-icon_duihao-mian" style="color:#38C175;"  v-if="scope.row.workOrderAuth"></i>
                          <i class="iconfont guoran-cuohao" v-else style="color:#FE5965;"></i>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column min-width="180" label="系统权限">
                        <template slot-scope="scope">
                            <div
                                style="text-align: center"
                                class="sys-acc-cell"
                            >
                                <span v-if="scope.row.botAuth"
                                    >可咨询机器人</span
                                >
                                <span
                                    v-if="
                                        scope.row.botAuth &&
                                        scope.row.workOrderAuth
                                    "
                                    >，</span
                                >
                                <span v-if="scope.row.workOrderAuth"
                                    >可使用工单</span
                                >
                                <span
                                    v-if="
                                        !scope.row.botAuthBeyond &&
                                        !scope.row.botAuth &&
                                        !scope.row.workOrderAuth
                                    "
                                    >--</span
                                >
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.station')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.title">
                                {{ scope.row.ext.title }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.minDepart')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["最小部门"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.rank')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["职级"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.belongCompany')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["归属公司"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="120"
                        :label="$t('staffManage.costAttributionCompany')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["成本归属公司"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.attributionType')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["归属类型"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.supervisorOrNot')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["是否主管"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        label="HRBP"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["HRBP"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.teamBuildingExpenses')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["团建费"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.availableAnnualLeave')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["可用年假"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="showTdSyncMessage"
                        width="100"
                        :label="$t('staffManage.attendanceType')"
                    >
                        <template  slot-scope="scope">
                            <span v-if="scope.row.ext && scope.row.ext.extension">
                                {{ JSON.parse(scope.row.ext.extension)["考勤类型"] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        :label="$t('staffManage.operation')"
                        align="center"
                        width="60"
                    >
                        <template slot-scope="scope">
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-around;
                                "
                            >
                                <span
                                    @click="editRow(scope.$index, scope.row)"
                                    class="table-handle-icon"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="$t('staffManage.edit')"
                                        placement="top"
                                    >
                                        <span>
                                            <i
                                                class="iconfont guoran-a-16-15"
                                            ></i
                                        ></span>
                                    </el-tooltip>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
              <pagination
                  :pageSize="pageSize"
                  :currentPage="searchCurrentPage"
                  :total="searchTotal"
                  @currentChange="searchCurrentChange"></pagination>
            </div>
            <div v-if="!isSeaching" class="pagination">
                <pagination
                    :pageSize="15"
                    :currentPage="currentPage"
                    :total="totalNumber"
                    @currentChange="handleCurrentChange"
                ></pagination>
            </div>
        </div>
        <popup v-if="setSysAccDialog" @closeEvent="setSysAccDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.setSysAccTitle')}}</div>
            <div slot="popup-tip">{{$t('staffManage.setSysAccTip')}}</div>
            <div slot="popup-con">
                <SetSysuse
                    ref="setSysuse"
                    :corpType="corpType"
                    :setSysAccDialog="setSysAccDialog"
                    :handleType="handleType"
                    :activeTreeItem="activeTreeItem"
                    :activeTreeItemNode="activeTreeItemNode"
                    :selectedList="JSON.stringify(selectedList)"
                    @reloadData="reloadData"
                    @setDisableSave="setDisableSave"
                    @setNoDisableSave="setNoDisableSave"
                    @saveSetSysAccSuccess="saveSetSysAccSuccess"
                />
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="setSysAccDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="saveSetSysAccDialog"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>

        <popup v-if="setTagDialog" @closeEvent="setTagDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.setLabel')}}</div>
            <div slot="popup-tip">{{$t('staffManage.setLabelTip')}}</div>
            <div slot="popup-con">
                <div class="set-tags-content">
                    <p>{{$t('staffManage.tag')}}</p>
                    <el-select 
                        v-model="deptTagsList[0].tags" 
                        multiple 
                        collapse-tags
                        size="smart"
                        style="width:100%"
                        :placeholder="$t('common.selectPlaceholder')"
                    >
						<el-option
							v-for="item in tagsList"
							:key="item.id"
							:label="item.label"
                            :disabled="item.source == 1"
							:value="~~item.id">
						</el-option>
					</el-select>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="setTagDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="saveTagDialog"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
        <popup v-if="editStaffDialog" @closeEvent="editStaffDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.editStaff')}}</div>
            <!-- <div slot="popup-tip">设置可使用哪个系统</div> -->
            <div slot="popup-con">
                <div class="info-cell">
                    <div class="info-cell-title">{{$t('staffManage.birthday')}}</div>
                    <div class="info-cell-input">
                        <el-date-picker
                            v-model="staffInfo.birthday"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="info-cell">
                    <div class="info-cell-title">{{$t('staffManage.staffIn')}}</div>
                    <div class="info-cell-input">
                        <el-date-picker
                            v-model="staffInfo.staffIn"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="editStaffDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    class="btn"
                    style="color: #ffffff"
                    @click="saveEditStaff"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
        <addDept 
            :addDeptVisible.sync="addDeptVisible" 
            :treeData="treeData" 
            :deleteMemberDialog.sync="deleteMemberDialog" 
            :activeTreeItem="activeTreeItem"
            :addDeptType="addDeptType"
            :corpId="corpId"
            :activeTreeItemNode="activeTreeItemNode"
            @addDeptSuccess="addDeptSuccess"
            @deleteDept="deleteDept">
        </addDept>
        <delete-dialog @closeEvent="deleteDeptPopup = false" v-if="deleteDeptPopup">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('staffManage.deleteDeptTitle')}}</div>
                <div>
                  {{$t('staffManage.deleteDeptTip')}}
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="deleteDeptPopup = false"
                    size="small"
                    plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteDept"
                    style="
                        background-color: #fe5965;
                        color: white;
                    "
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
    </div>
</template>
<script>
import { formatTree } from "./../utils/formatTree";
import pagination from "./../../../../components/pagination.vue";
import DefaultImage from "./../../../../components/DefaultImage.vue";
import SetSysuse from "./SetSysuse.vue";
import Popup from "@/components/popup";
import OpenData from "@/components/openData";
import addDept from './addDept.vue';
import DeleteDialog from "./../../../../components/deleteDialogue.vue";
import { isTB, isMnHr, mnFieldList } from "../utils/customerMainId"
export default {
    props: ["corpId","corpType","authType","tagsList"],
    components: { pagination, Popup, SetSysuse, DefaultImage, OpenData, addDept, DeleteDialog },
    data() {
        return {
            tagListIds: [],
            outerLoading: true,
            treeWidth: 340,
            editStaffDialog: false,
            staffInfo: {
                birthday: "",
                staffIn: "",
                id: "",
            },
            treeData: [],
            lazyLoad: true,
            treeVisable: false,
            defaultProps: {
                isLeaf: "isLeaf",
            },
            // defaultPropsNew: {
            //     label: "label",
            //     children: "children",
            //     isLeaf: "leaf",
            // },
            staffLoading: false,
            activeNodeId: "",
            defaultCheckedKeys: [],
            isSeaching: false,
            totalNumber: 0,
            currentPage: 1,
            activeDeptId: "",
            tableData: [],
            staffChecked: [],
            staffCheckedIds: [],
            // 设置系统权限
            setSysAccDialog: false,
            setTagDialog:false,
            selectedList: [],
            handleType: "staff",
            disableSave: false,
            keyword: "",
            corpId: "",
            deptId:"",
            pageSize:20,
            searchCurrentPage:1,
            searchTotal:0,
            rolePrivilegesList:[],
            activateStatus:null,
            tagsId: [] ,
            activeStatusName:this.$t('upDownManage.seniorSearch.botActivationStatus'),
            sortGroupIcon: "el-icon-arrow-down",
            promoteSurplusDay:-1,
            tableLoading:true,
            addDeptVisible:false,
            deleteMemberDialog:false,
            activeTreeItem:{},
            addDeptType:'',
            activeTreeItemNode:{},
            deleteDeptPopup:false,
            sortGroupIconBigModel:"el-icon-arrow-down",
            activateStatusBigModel:null,
            activeStatusNameBigModel:this.$t('upDownManage.seniorSearch.modelActivationStatus'),
            newCurrentPage: 1,
            newPageSize: 20,
            defaultExpandedKeys: [],
            defaultExpandedKeysMember: [],
            showTdSyncMessage:false,
            tagsIcon:'el-icon-arrow-down',
            deptTagsList:[],
            isTB:false,
            isMnHr:false,
            mnHrFieldList:[]
        };
    },
    mounted() {
        this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
        this.rolePrivilegesList = this.getRolePrivileges();
        if(this.$route.query.showTdSyncMessage && ["fb348d095c0b4fd7bbd37826563dac7d", "31623ccfe9dd4957bbd59c5823878bbe"].includes(localStorage.getItem("_mainId"))) {
            this.showTdSyncMessage = true
        }
        this.isTB = isTB()
        this.isMnHr = isMnHr()
        if(this.isMnHr) {
            this.$set(this, 'mnHrFieldList', mnFieldList())
        }
        // this.getdepartmentTree(this.corpId);
    },
    watch: {
        treeData: {
            handler(n) {
                if(n.length === 0){
                    this.tableLoading = false;
                }
            },
            deep: true,
        },
    },
    computed:{
    },
    methods: {

        getLabelList(corpId, callback) {
            let url =
                this.requestUrl.lable.getTagList + "?corpId=" + corpId;
            this.$http.get(url).then((res) => {
                let tagsArr = [];
                if (res.data && res.data.code == 0 && res.data.data) {
                    tagsArr = res.data.data.map(item => item.id)
                }
                if (callback) {
                    callback(tagsArr);
                }
            });
        },
        mnHrFieldEcho(mengNiuEmployee, key) {
            if(key == 'type') {
                let memberTypeMap = {
                    1 : "蒙牛员工",
                    2: "经销商人员",
                    5: "门店店主",
                    6: "三方人员",
                    7: "门店店员"
                }
                return memberTypeMap[mengNiuEmployee.type]
            }
            if(mengNiuEmployee[key] !== undefined) {
                return mengNiuEmployee[key] ? mengNiuEmployee[key] : '--'
            } else if(mengNiuEmployee.bizInfos && mengNiuEmployee.bizInfos[0][key] !== undefined) {
                return mengNiuEmployee.bizInfos[0][key] ?
                mengNiuEmployee.bizInfos[0][key] : '--'
            } else if(mengNiuEmployee.bizInfos && mengNiuEmployee.bizInfos[0].extra[key] !== undefined) {
                return mengNiuEmployee.bizInfos[0].extra[key] ?
                mengNiuEmployee.bizInfos[0].extra[key] : '--'
            } else {
                return '--'
            }
        },
        filterTagName(tags) {
            if(tags && this.tagsList.length != 0 ) {
                if(Array.isArray(tags) && tags.length != 0) {
                    return this.tagsList.filter(item =>{ 
                        return tags.includes(Number(item.id))
                    }).map(item =>{return item.label})
                } else {
                    let obj = this.tagsList.find(item =>{
                        return tags == Number(item.id)
                    })
                    if (obj) {
                        return obj.label
                    } else {
                        return ""
                    }
                }
            } else {
                return ''
            }   
        },
        // 节点被展开
        nodeExpandNew(data, node) {
            console.log(data, 'datadt');
            // return
            data.showChildren = !data.showChildren;
            if (data.showChildren) {
                this.newCurrentPage = 1;
                this.getLabelList(this.corpId, () => {
                    this.getData(data.id, data, node);
                })
            } else {
              this.newCurrentPage = 1;
                data.children = [];
            }
        },
        // 获取员工数据
        getData(departId, data, node, isLoadMore) {
            console.log(1093, departId);
            let axiosDatas = [];
            let userId = localStorage.getItem("_uid");
            if (!isLoadMore) {
                this.currentPage = 1;
            }
            let url = ''
            if(departId) {
                url += "/api/department/corpDeptListLazy" +
                    "?departId=" +
                    departId +
                    "&userId=" +
                    userId +
                    "&corpId=" +
                    this.corpId + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize
            } else {
                url += "/api/department/corpListLazy-v2" +
                    "?userId=" +
                    userId +
                    "&corpId=" +
                    this.corpId  + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize;
            }
            url += '&needDepartTag=true'
                this.$http.get(url).then((res) => {
                // let memberId = Number(this.memberInfo4Client.memberId);
                let ids = [];
                let datas = res.data.list.filter(item => item.type !== 0)

                if (this.tagsList && this.tagsList.length != 0) {
                    this.tagListIds = this.tagsList.map(item => item.id);
                }
                datas.forEach((item, index) => {
                    item.rowIndex = index;
                    if (item.tags && item.tags.length != 0) {
                        item.tags = item.tags.filter(cell => this.tagListIds.includes(cell));
                        item.tags = [...new Set(item.tags)];
                    } else {
                        item.tags = [];
                    }
                });

                axiosDatas = this.handlerCheckedSelf(datas || [],
                    ids,
                    departId ? departId : -1
                );
                console.log(res.data.list, axiosDatas);

                this.getAllNodeId(axiosDatas, "show");
                if (departId) {
                    if (res.data.hasNextPage){
                        let info = {
                            label: "加载更多",
                            dataType: 'hasNext',
                            id: departId + 'hasNext',
                            parentId: departId,
                            currentPage: res.data.nextPage?res.data.nextPage:1
                        }
                        axiosDatas = axiosDatas.concat([info])
                    }
                    axiosDatas.forEach(element => {
                        if(element.type === 0 || element.dataType === 'hasNext') {
                            element.leaf = true
                        }
                    })
                    if (isLoadMore) {
                        let flagFind = true;
                        let findParent = (list) => {
                            if (flagFind) {
                                list.forEach(item => {
                                    if (item.id == departId) {
                                        flagFind = false;
                                        if (item.children[item.children.length - 1].dataType == 'hasNext') {
                                            item.children.length = item.children.length - 1;
                                        }
                                        item.children = [...item.children, ...axiosDatas];
                                        this.defaultExpandedKeysMember = [
                                            ...this.defaultExpandedKeysMember,
                                            ...[departId],
                                        ];
                                        item.showChildren = true;
                                    } else {
                                        findParent(item.children);
                                    }
                                })
                            }
                            
                        }
                        findParent(this.treeData);
                    } else {
                        data.children = [...data.children, ...axiosDatas];
                        this.defaultExpandedKeysMember = [
                            ...this.defaultExpandedKeysMember,
                            ...[departId],
                        ];
                        data.showChildren = true;
                    }
                } else {
                    this.treeData = [...axiosDatas];
                    this.defaultExpandedKeysMember = [];
                }
                console.log(this.treeData);
                this.treeData.forEach((item) => {
                    if (!departId) {
                        item.showChildren = false;
                        item.children = [];
                    }
                });
            });
        },
        handlerCheckedSelf(datas, ids, parentId) {
            datas.forEach((item) => {
                item.checked = false;
                item.showChildren = false;
                if (item.type === 1) {
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                if (ids.indexOf(item.id) > -1) {
                    let ref = item.id + "TreeLazy";
                    // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                } else {
                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                }
            });
            return datas;
        },
        // 递归获取所有节点
        getAllNodeId(moduleDataList, type) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        if (type) {
                            [].forEach((item) => {
                                if (moduleDataList[i].id == item) {
                                    // this.selectDepts.push(moduleDataList[i]);
                                    this.$set(
                                        moduleDataList[i],
                                        "name",
                                        moduleDataList[i].label
                                    );
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            });
                        }
                        if (moduleDataList[i].children) {
                            this.getAllNodeId(moduleDataList[i].children, type);
                        }
                    }
                }
                resolve(true);
            });
        },
        phoneShowToUser(phone){
            if (phone == null || phone =='null' || phone.length != 11) {
                return "--"
            } else {
                return phone.slice(0, 3) + "****" + phone.slice(phone.length - 4);;
            }
        },
        visibleChange(flag){
            if(flag){
                this.sortGroupIcon = "el-icon-arrow-up"
            } else {
                this.sortGroupIcon = "el-icon-arrow-down"
            }
        },
        visibleTagsChange(flag) {
            if(flag){
                this.tagsIcon = "el-icon-arrow-up"
            } else {
                this.tagsIcon = "el-icon-arrow-down"
            }
        },
        handleCommandStatus(command){
            this.activateStatus = command;
            if(this.activateStatus === 0){
                this.activeStatusName = this.$t('upDownManage.seniorSearch.notActive')
            } else if(this.activateStatus === 1){
                this.activeStatusName = this.$t('upDownManage.seniorSearch.activationSuccess')
            } else if(this.activateStatus === 2){
                this.activeStatusName = this.$t('upDownManage.seniorSearch.activationFail')
            }  else {
                this.activeStatusName = this.$t('upDownManage.seniorSearch.botActivationStatus')
            }
            this.search(this.keyword, this.corpId);
        },
        handleMemberTags(command) {
            if(command == null) {
                this.tagsId = []
            } else {
                // if(this.tagsId.includes(command)) {
                //     this.tagsId = this.tagsId.filter( id => { return id != command})
                // } else {
                //     this.tagsId.push(command)
                // }
                this.tagsId = [command]
            }
            this.search(this.keyword, this.corpId);
        },
        visibleChangeBigModel(flag){
            if(flag){
                this.sortGroupIconBigModel = "el-icon-arrow-up"
            } else {
                this.sortGroupIconBigModel = "el-icon-arrow-down"
            }
        },
        handleCommandStatusBigStatus(command){
            this.activateStatusBigModel = command;
            if(this.activateStatusBigModel === 0){
                this.activeStatusNameBigModel = this.$t('upDownManage.seniorSearch.notActive')
            } else if(this.activateStatusBigModel === 1){
                this.activeStatusNameBigModel = this.$t('upDownManage.seniorSearch.activationSuccess')
            } else if(this.activateStatusBigModel === 2){
                this.activeStatusNameBigModel = this.$t('upDownManage.seniorSearch.activationFail')
            }  else {
                this.activeStatusNameBigModel = this.$t('upDownManage.seniorSearch.botActivationStatus')
            }
            this.search(this.keyword, this.corpId);
        },
        showOuterLoading(val) {
            this.outerLoading = val;
            this.staffLoading = true;
        },
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        isStrings(value) {
            const res = new RegExp("[\u4E00-\u9FA5]+");
            return !res.test(value);
        },
        // 点击编辑成员
        editRow(index, data) {
            this.$emit('editStaff',data);
            // console.log(index, data);
            // this.staffInfo = {
            //     birthday: data.birthday,
            //     staffIn: data.staffIn,
            //     id: data.id,
            // };
            // this.editStaffDialog = true;
        },
        saveEditStaff() {
            let birthDay = "";
            if (this.staffInfo.birthday) {
                birthDay = new Date(this.staffInfo.birthday).Format(
                    "yyyy-MM-dd"
                );
            }
            let staffIn = "";
            if (this.staffInfo.staffIn) {
                staffIn = new Date(this.staffInfo.staffIn).Format("yyyy-MM-dd");
            }
            let param = {
                birthday: birthDay,
                staffIn: staffIn,
                id: this.staffInfo.id,
            };
            this.$http
                .put(
                    this.requestUrl.member.editMemberById +
                        "/" +
                        this.staffInfo.id,
                    param
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (this.isSeaching) {
                            this.search(this.keyword, this.corpId);
                        } else {
                            console.log(695);
                            this.getMemberListByDeptId({
                                id: this.activeDeptId,
                                currentPage: this.currentPage,
                            });
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.editStaffDialog = false;
                });
        },
        setDisableSave() {
            this.disableSave = true;
        },
        setNoDisableSave() {
            this.disableSave = false;
        },
        search(keyword, mainValue) {
            if (keyword.trim() === "") {
                this.currentPage = 1;
                this.isSeaching = false;
                console.log(717);
                this.getMemberListByDeptId({
                    id: this.activeDeptId,
                    currentPage: 1,
                });
                this.$nextTick(() => {
                                
                    setTimeout(() => {
                        this.treeWidth =
                                this.$refs.slleftreeoter.offsetWidth;
                        console.log(this.treeWidth);
                        this.$emit(
                            "changeWidth",
                            this.treeWidth
                        );
                        console.log(
                            53, this.$refs.slleftreeoter.offsetWidth
                        );
                    }, 200);
                });
            } else {

                this.tableLoading = true;
                this.keyword = keyword;
                this.corpId = mainValue;
                let url =  this.requestUrl.department.newSearchUser +
                            "?keyword=" +
                            keyword +
                            "&corpId=" +
                            mainValue +
                            '&deptId=' +
                            this.deptId +
                           '&page=' +
                            this.searchCurrentPage +
                            '&size=' + this.pageSize;

                if((this.activateStatus && this.activateStatus > -1) || this.activateStatus === 0){
                    url = this.requestUrl.department.newSearchUser +
                            "?keyword=" +
                            keyword +
                            "&corpId=" +
                            mainValue +
                            '&deptId=' +
                            this.deptId +
                           '&page=' +
                            this.searchCurrentPage +
                            '&size=' + this.pageSize+
                            "&activateStatus="+this.activateStatus
                }
                if((this.activateStatusBigModel && this.activateStatusBigModel > -1) || this.activateStatusBigModel === 0){
                    url += "&bigModelActivateStatus="+this.activateStatusBigModel
                }
                if(this.tagsId) {
                    url += "&tags=" + this.tagsId.join()
                }
                if(this.isMnHr) {
                    url += '&requireMengNiuEmployeeCode=true'
                }
                console.log('search',this.activateStatusBigModel);
                this.$http
                    .get(url)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log('memberList',res.data);
                            this.isSeaching = true;
                            this.tableData = res.data.data.list;
                            // this.tableData.forEach((item, index) => {
                            //   item.rowIndex = index;
                            // });
                            if (this.tagsList && this.tagsList.length != 0) {
                                this.tagListIds = this.tagsList.map(item => item.id);
                            }
                            this.tableData.forEach((item, index) => {
                                item.rowIndex = index;
                                if (item.tags && item.tags.length != 0) {
                                    item.tags = item.tags.filter(cell => this.tagListIds.includes(cell));
                                    item.tags = [...new Set(item.tags)];
                                } else {
                                    item.tags = [];
                                }
                            });
                            this.tableLoading = false;
                            this.searchTotal = res.data.data.total
                            this.$nextTick(() => {
                                
                                setTimeout(() => {
                                    this.treeWidth =
                                            this.$refs.slleftreeoter.offsetWidth;
                                    console.log(this.treeWidth);
                                    this.$emit(
                                        "changeWidth",
                                        this.treeWidth
                                    );
                                    console.log(
                                        53, this.$refs.slleftreeoter.offsetWidth
                                    );
                                }, 200);
                            });
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        reloadData() {
            // this.getdepartmentTree(this.corpId,true)
            if (this.isSeaching) {
                this.search(this.keyword, this.corpId);
            } else {
                this.getMemberListByDeptId({
                    id: this.activeDeptId,
                    currentPage: this.currentPage,
                });
            }
            this.$refs.multipleTable.clearSelection();
            this.setSysAccDialog = false;
            this.disableSave = false;
        },
        saveSetSysAccDialog() {
            this.$refs.setSysuse.saveSetSysAccDialog();
        },
        saveTagDialog() {
            this.$http.post("/api/setDepartmentTag", {
                departmentId: this.deptTagsList.map(dp =>{ return dp.id }),
                tags:this.deptTagsList[0].tags,
                mainId:localStorage.getItem("_mainId"),
                corpId: this.corpId
            }).then(res =>{
                if(res.data.code == "0") {
                    this.$message.success(this.$t('staffManage.setSuccess'))
                    this.deptTagsList = []
                    this.setTagDialog = false
                    this.getdepartmentTree(this.corpId, true, true);
                }
            })
        },
        handleCommand(command, node, data) {
            console.log(command, node, data);
            this.activeTreeItem = data;
            this.activeTreeItemNode = node;
            this.deleteMemberDialog = false;
            this.addDeptVisible = false;
            this.addDeptType = command;
            if(command === 'addSameDept' || command === 'addChildDept' || command === 'edit'){
                this.addDeptVisible = true;
            } else  if(command === 'setSysAcc'){
                this.handleType = "depart";
                let item = data;
                item.name = item.label;
                this.selectedList = [item];
                this.disableSave = false;
                this.setSysAccDialog = true;
            } else if(command === "setTags"){
                this.setTagDialog = true
                let item = data;
                item.name = item.label;
                this.deptTagsList = JSON.parse(JSON.stringify([item]));
            } else if(command === 'moveUp' || command === 'moveDown'){
                this.moveDepartment(command);
            } else if(command === 'del'){
                // this.judgeDeptIsHaveMember()
                this.deleteMemberDialog = true;
            }
        },
        setSysAcc(mainId) {
            this.handleType = "staff";
            this.selectedList = [...this.staffChecked];
            if (this.selectedList.length === 0) {
                this.$message.warning(this.$t('staffManage.setPermissionTip'));
            } else {
                this.setSysAccDialog = true;
            }
        },

        loadNode(node, resolve) {
            let url = this.requestUrl.department.getDepartmentTreeLazy +
                            "?corpId=" +
                            this.corpId +
                            "&parentId=" +
                            node.data.id
            url += "&needDepartTag=true"
            node.data.id &&
                this.$http.get(url).then((res) => {
                        if (res.data && res.data.code === "0") {
                            if (res.data.data != null) {
                                if (res.data.data.length > 0) {
                                    console.log(res.data.data);
                                    let list = res.data.data;
                                    list.forEach((item) => {
                                        item.isLeaf = !item.haveChildren;
                                    });
                                    resolve(list);
                                } else {
                                    resolve([]);
                                }
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        console.log(this.$refs.slleftreeoter);
                                        this.treeWidth =
                                            this.$refs.slleftreeoter.offsetWidth;
                                        this.$emit(
                                            "changeWidth",
                                            this.treeWidth
                                        );
                                        console.log(
                                            this.$refs.slleftreeoter.offsetWidth
                                        );
                                    }, 200);
                                });
                            }
                        } else {
                            // this.$message.error(res.data.message);
                        }
                    });
        },
        bridgecode(row) {
            return row.id + "_" + row.rowIndex;
        },
        nodeCollapse() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.treeWidth = this.$refs.slleftreeoter.offsetWidth;
                    this.$emit("changeWidth", this.treeWidth);
                    console.log(this.$refs.slleftreeoter.offsetWidth);
                }, 400);
            });
        },
        nodeExpand() {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.treeWidth < this.$refs.slleftreeoter.offsetWidth) {
                        this.treeWidth = this.$refs.slleftreeoter.offsetWidth;
                        this.$emit("changeWidth", this.treeWidth);
                        console.log(this.$refs.slleftreeoter.offsetWidth);
                    }
                }, 400);
            });
        },
        handleSelectionChange(value) {
            this.staffChecked = value;
            let orderIds = [];
            for (let i = 0; i < value.length; i++) {
                orderIds.push(value[i].id);
            }
            this.staffCheckedIds = orderIds;
            console.log(this.staffChecked, orderIds);
            // this.$emit("handleSelectionChange", this.workOrderCheck, orderIds);
        },
        handleNodeClick(data, node, el) {
            console.log('=======',data, node, el);
            if (data.dataType === "hasNext") {
                this.newCurrentPage = data.currentPage;
                this.getLabelList(this.corpId, () => {
                    this.getData(data.parentId, {}, "", true);
                })

            } else {
                this.activeDeptId = data.id;
                this.activeNodeId = data.id;
                this.deptId = data.id
                this.getMemberListByDeptId({
                    id: data.id,
                    currentPage: 1,
                });
                this.$emit('switchDept',{
                    id: data.id,
                    label:data.label,
                    // isWechat:data.type === 1 && this.isNumber(data.label)
                    isWechat:this.corpType == 0 ? true : false
                })
            }
            

        },
        getdepartmentTree(val, isSwitchMain,isUpdate) {
            this.isSeaching = false;
            let labelList = [];
            let url =
                this.requestUrl.lable.getTagList + "?corpId=" + val;
            this.$http.get(url).then((res) => {
                if (res.data && res.data.code == 0 && res.data.data) {
                    labelList = res.data.data.map(item => item.id);
                }
                if (this.treeData.length === 0 || isSwitchMain) {
                let url = this.requestUrl.department.getDepartmentTreeLazy + "?corpId=" + val
                url += "&needDepartTag=true"
                this.$http.get(url).then((res) => {
                        if (res.data && res.data.code === "0") {
                            if (res.data.data != null) {
                                if (res.data.data.length > 0) {
                                    this.isSeaching = false;
                                    // this.defaultCheckedKeys = [res.data.data[0].id];
                                    console.log(954);
                                    this.getMemberListByDeptId({
                                        id: isUpdate ? res.data.data[0].id : this.activeDeptId,
                                        currentPage: 1,
                                    });
                                    this.treeData = [
                                        ...formatTree(res.data.data, labelList),
                                    ];
                                    this.$nextTick(function () {
                                        if(isUpdate){
                                            this.activeNodeId = 1;
                                            this.activeDeptId = res.data.data[0].id;
                                        }
                                        this.lazyLoad = this.treeData[0].lazy;
                                        this.treeVisable = true;
                                        if (this.lazyLoad) {
                                            this.$refs.lefttree.setCurrentKey(
                                                res.data.data[0].id
                                            );
                                        } else {
                                            this.$refs.lefttree_false.setCurrentKey(this.activeDeptId);
                                            // this.$refs.lefttree_false.setCurrentKey(
                                            //     res.data.data[0].id
                                            // );
                                        }
                                        this.$emit('switchDept',{
                                            id: res.data.data[0].id,
                                            label:res.data.data[0].label,
                                            // isWechat:res.data.data[0].type === 1 && this.isNumber(res.data.data[0].label)
                                            isWechat:this.corpType == 0 ? true : false
                                        })
                                    });
                                } else {
                                    console.log(983);
                                    this.getMemberListByDeptId({
                                        id: null,
                                        currentPage: 1,
                                    });
                                }
                            }
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    if (
                                        this.treeWidth <
                                        this.$refs.slleftreeoter.offsetWidth
                                    ) {
                                        this.treeWidth =
                                            this.$refs.slleftreeoter.offsetWidth;
                                        this.$emit(
                                            "changeWidth",
                                            this.treeWidth
                                        );
                                        console.log(
                                            727, this.$refs.slleftreeoter.offsetWidth
                                        );
                                    }
                                }, 400);
                            });
                        } else {
                            this.$message.error(res.data.message);
                        }
                        this.outerLoading = false;
                    });
            }
            });

        },
        // 分页改变数量
        handleCurrentChange(e) {
            this.currentPage = e;
            console.log(1017);
            this.getMemberListByDeptId({
                id: this.activeDeptId,
                currentPage: this.currentPage,
            });
        },
        //搜索出的员工分页加载
        searchCurrentChange(e){
            this.searchCurrentPage =e;
            this.search(this.keyword,this.corpId)
        },
        getMemberListByDeptId(data) {
            this.isSeaching = false;
            this.staffLoading = true;
            if (data.id == null) {
                this.treeData = [];
                this.totalNumber = 0;
                this.tableData = [...[]];
                this.staffLoading = false;
            } else {
                // this.activeDeptId = data.id;
                let url =  this.requestUrl.member.getMemberListByDeptId +
                            "/" +
                            data.id +
                            "?page=" +
                            data.currentPage +
                            "&size=15";
                if((this.activateStatus && this.activateStatus > -1) || this.activateStatus === 0){
                    url = this.requestUrl.member.getMemberListByDeptId +
                            "/" +
                            data.id +
                            "?page=" +
                            data.currentPage +
                            "&size=15"+
                            "&activateStatus="+this.activateStatus
                }
                if((this.activateStatusBigModel && this.activateStatusBigModel > -1) || this.activateStatusBigModel === 0){
                    url += "&bigModelActivateStatus="+this.activateStatusBigModel
                }
                if(this.tagsId.length != 0) {
                    url += '&tags=' + this.tagsId.join()
                }
                if(this.isMnHr) {
                    url += '&requireMengNiuEmployeeCode=true'
                }
                this.$http
                    .get(url)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.tableData = res.data.data.list;
                            if (this.tagsList && this.tagsList.length != 0) {
                                this.tagListIds = this.tagsList.map(item => item.id);
                            }
                            this.tableData.forEach((item, index) => {
                                item.rowIndex = index;
                                if (item.tags && item.tags.length != 0) {
                                    item.tags = item.tags.filter(cell => this.tagListIds.includes(cell));
                                    item.tags = [...new Set(item.tags)];
                                } else {
                                    item.tags = [];
                                }
                            });
                            this.totalNumber = res.data.data.total;
                            this.tableLoading = false;
                            this.$emit('updateDeptTree')
                            // WWOpenData.bind(this.$el);
                        } else {
                            this.$message.error(res.data.message);
                        }
                        this.staffLoading = false;
                    });
            }
        },
        addDeptSuccess(){
            this.addDeptType = ''
            this.addDeptVisible = false;
            this.getdepartmentTree(this.corpId,true,false)
        },
        // // 判断要删除的部门下是否存在成员
        // judgeDeptIsHaveMember(){
        //     this.$http.get(this.requestUrl.member.getMemberListByDeptId + "/" + this.activeTreeItem.id + "?page=15&size=15").then((res) => {
        //         if (res.data && res.data.code === "0") {
        //             console.log(res.data.data);
        //             if (res.data.data.total == 0) {
        //                this.deleteDept();
        //             } else {
        //                 this.deleteMemberDialog = true;
        //             }
        //         } else {
        //             this.$message.error(res.data.message);
        //         }
        //     });
        // },
        deleteDept(delDeptMoveTo){
            let url = '';
            if(delDeptMoveTo && delDeptMoveTo.id){
                url = this.requestUrl.department.deleteDepartmentById + "/" + this.activeTreeItem.id +  "?moveDeptId=" + delDeptMoveTo.id
            } else {
                url = this.requestUrl.department.deleteDepartmentById + "/" + this.activeTreeItem.id
            }
            this.$http.delete(url).then((res) => {
                if (res.data && res.data.code === "0") {
                    console.log(res.data.data);
                    this.$message({
                        type: "success",
                        message: this.$t('staffManage.deleteSuccess'),
                    });
                    this.addDeptType = ''
                    this.deleteMemberDialog = false;
                    this.getdepartmentTree(this.corpId,true,true)
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        moveDepartment(type) {
            let parentList = this.activeTreeItemNode.parent.childNodes;
            let params = {
                siblingDeptId:null,
                targetDeptId:null
            }
            parentList.forEach((item,index) => {
                if(type === 'moveUp' && this.activeTreeItem.id === item.data.id){
                    params = {
                        siblingDeptId:this.activeTreeItem.id,
                        targetDeptId:parentList[index-1].data.id,
                    }
                } else if (type === 'moveDown'  && this.activeTreeItem.id === item.data.id){
                    params = {
                        siblingDeptId:this.activeTreeItem.id,
                        targetDeptId:parentList[index+1].data.id,
                    }
                }
            })
            this.$http.post(this.requestUrl.department.moveDepartment,params)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.$message({
                            type: "success",
                            message: this.$t('staffManage.moveSuccess'),
                        });
                        this.getdepartmentTree(this.corpId,true,false)
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        saveSetSysAccSuccess(){
            this.getdepartmentTree(this.corpId,true,false);
            this.$refs.multipleTable.clearSelection();
            this.setSysAccDialog = false;
            this.disableSave = false;
        },
        batchSetTags(value) {
            console.log(value);
            if(this.staffChecked.length == 0) {
                this.$message.warning(this.$t('staffManage.selectStaff'))
            } else {
                this.$emit("openTagsDialog", this.staffChecked)
            }
        }
    },
};
</script>
<style lang="less">
.set-tags-content {
    .el-tag__close{
		display: none;
	}
}
.beyond-tooltip{
    border: none !important;
    // height: 47px;
    // line-height: 47px !important;
    font-size: 14px !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(57,63,79,0.21);
    border-radius: 5px;
}
.loadMoreTree{
    color: #366aff;
    cursor: pointer;
}
.more_dept_tag_content {
    max-width: 400px;
    overflow: hidden;
    span {
        display: block;
        width: 100%;
        line-height: 22px;
        margin-right: 5px;
        margin-top: 5px;
        background: #e0e8ff;
        color: #366AFF;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 4px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#selectOrganizationTreeNew {
    .el-tree-node__expand-icon {
        display: none;
        font-size: 14px;
    }
    .el-icon-caret-new {
        line-height: 20px;
        width: 20px;
        margin-left: 16px;
    }
    &.updown-tree {
        .el-tree-node__expand-icon {
            display: inline-block;
        }
    }
    
}

</style>
<style lang="less" scoped>
// /deep/.cell {
//     text-align: center;
// }
// /deep/.el-table_1_column_2 > .cell {
//     text-align: left !important;
// }
.info-cell {
    padding: 5px 0;
    .info-cell-title {
        margin: 8px 0;
    }
}
#staff-list {
    height: calc(100vh - 165px);
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    overflow-x: auto;
    /deep/.el-tree-title-word-name {
        display: block;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
    }
    .left-name {
        display: block;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
    }
    .sys-acc-cell {
        text-align: left;
        
    }
    .left-sys-acc-tip {
        height: 20px;
        background: #ffebec;
        border-radius: 3px;
        color: #fe5965;
        font-size: 12px;
        border-radius: 3px;
        padding: 2px 3px;
        white-space: nowrap;
        margin-left: 4px;
    }

    .sl-left-tree {
        flex: none;
        min-width: 340px;
        height: calc(100% - 30px);
        background: #ffffff;
        border-radius: 0;
        border-top: solid 1px #e0e6f7;
        padding: 15px 0;
        overflow: auto;
        ::v-deep .el-tree {
            min-width: 340px;
            .el-tree-node__content {
                height: 40px;
            }
            .arsenalgengduo {
                color: #366aff;
            }
            .el-tree-node.is-current > .el-tree-node__content {
                background: linear-gradient(
                    179deg,
                    #3682ff,
                    #366aff
                ) !important;
                color: #ffffff;
                .el-tree-title-num {
                    color: #fff;
                    background: rgba(255, 255, 255, 0.15);
                }
                .arsenalgengduo {
                    color: #fff;
                }
            }
            .el-tree-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                padding-right: 8px;
                width: 100%;
                > span {
                    display: flex;
                }
                .dept_tag {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 20px;
                    margin: 0 8px;
                    background: #e0e8ff;
                    color: #366AFF;
                    border-radius: 2px;
                    font-size: 12px;
                    padding: 0 4px;
                }
                .more_dept_tag_btn {
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                    background: #e0e8ff;
                    line-height: 20px;
                    text-align: center;
                    cursor: pointer;
                    i {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: #366AFF !important;
                    }
                }
                .el-tree-title-word {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    color: #000000;

                    /deep/.el-tree-title-word-name {
                        display: block;
                        max-width: 70px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        flex: 1;
                    }
                    .el-tree-title-word-lumb {
                        background: #616161;
                        width: 6px;
                        height: 6px;
                        display: inline-block;
                        margin-right: 8px;
                    }
                }
                .el-tree-title-num {
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    min-width: 20px;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #366aff;
                    background: #ecf1ff;
                    border-radius: 10px;
                    margin: 0 10px;
                    text-align: center;
                    padding: 0 5px;
                }
                .arsenalgengduo {
                    transform: rotate(90deg);
                    &:hover {
                        color: #366aff;
                    }
                }
            }
        }
    }
    .sl-content {
        flex: auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-left: solid 1px #e0e6f7;
        /deep/.el-table__fixed-right {
            height: auto !important;
            bottom: 16px !important;
        }
        .staff-table,.staff-table-search {
            // width: calc(100vw - 680px);
            overflow-y: hidden;
            flex: auto;
            /deep/ td .cell {
                text-align: left;
            }
            .member_tags {
                display: flex;
                flex-wrap: wrap;
                .el-dropdown-link {
                    cursor: pointer;
                }
                .member_tags_content {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .member_tags_label {
                display: flex;
                flex: none;
                padding: 0 4px;
                background: #e0e8ff;
                color: #366AFF;
                margin-right: 6px;
                margin-bottom: 4px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                max-width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;

            }
            // .cell > div {
            //     text-align: left !important;
            // }
            .user-dept-box{
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .userName{
                    ww-open-data{
                       font-size: 14px;  
                       color: #000;
                    }
                }
                .dept-box{
                    color: #999999;
                    font-size: 12px;
                    line-height: 18px;
                    display: inline-block;
                    ww-open-data{
                       font-size: 12px;  
                    }
                }
                .dept-boxs{
                    display: block;
                }
            }
            /deep/ .sl-name-pre {
                height: 30px;
                line-height: 30px;
                width: 30px;
                margin-right: 6px;
                text-align: center;
                display: inline-block;
                background-color: #366aff;
                border-radius: 15px;
                color: white;
            }
        }
        .staff-table-search {
            width: 100%;
            overflow-y: hidden;
            flex: auto;
            // .cell {
            //     text-align: left !important;
            // }
            // .cell > div {
            //     text-align: left !important;
            // }
            /deep/ .sl-name-pre {
                height: 30px;
                line-height: 30px;
                width: 30px;
                margin-right: 6px;
                text-align: center;
                display: inline-block;
                background-color: #366aff;
                border-radius: 15px;
                color: white;
            }
        }
        /deep/ .activate-status{
            text-align: left;
            .cell{
                 text-align: left;
            }
        }
      
        .pagination {
            flex: none;
            height: 50px;
        }
    }
}

#staff-list::-webkit-scrollbar {
  height: 8px; /* 设置滚动条的宽度 */
}
.dept-operation-dropdown{
    i{
        font-size: 13px;
    }
    .guoran-shezhiquanxian{
        font-size: 14px;
    }
}
.tags-dropdown {
    width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    // word-break: break-all;
    line-height: 24px;
    padding-bottom: 5px;
    padding-top: 5px;

    .tags-dropdown-name {
        display: inline-block;
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>