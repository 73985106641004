<template>
  <div class="thirdPartyAccess">
    <img src="../../../../assets/images/unauthorized.png">
    <div class="tips">{{$t('channel.dingding.thirdTip1')}}</div>
    <div class="tip2">{{$t('channel.dingding.thirdTip2')}}</div>
  </div>
</template>

<script>
export default {
  name: "thirdPartyAccess"
};
</script>

<style scoped lang="less">
.thirdPartyAccess{
  width: 700px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tips{
    font-size: 18px;
    margin: 20px;
  }
  .tip2{
    color: #616161;
    margin-bottom: 20px;
  }
}
</style>