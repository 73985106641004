<template>
    <div id="roleManage">
        <left-menu></left-menu>
        <div class="content-container-roleManage">
            <div class="roleManage-header">
                <div class="leftInfo">
                    <span class="headerName">企业管理</span>
                </div>
                <div class="rightButton" v-if="operableRole('PORTAL_MANAGEMENT_ROLE_ADD')">
                    <el-button type="primary" size="small" @click="addRole">新增角色</el-button>
                </div>
            </div>
            <div class="roleManage-content">
                <div style="width: 150px; height: 100vh; background: white">
                    <left-sub-menu></left-sub-menu>
                </div>
                <div class="right-content">
                    <role-table></role-table>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import leftSubMenu from "../../menu/leftSubMenu";
    import leftMenu from "../../menu/leftMenu";
    import RoleTable from "./components/roleTable";
    import {operable} from "../js/operable";

    export default {
        name: "roleManage",
        data(){
            return{
                roleTableTotal:20,
                currentPage:1,
                pageSize:20,
                rolePrivilegesList:[]
            }
        },
        components:{RoleTable, leftSubMenu,leftMenu},
        mounted() {
            this.rolePrivilegesList=this.getRolePrivileges()
        },
        computed:{
            operableRole(){
                return function (type) {
                    return operable(type)
                }
            }
        },
        methods:{
            addRole(){
                this.$router.push({
                    path:'/addRole',
                    query:{
                        type:"add"
                    }

                })
            },
            handleSizeChange(){},
            handleCurrentChange(){},
        }
    }
</script>

<style scoped lang="less">
@import "../../../assets/less/roleManage/roleManage";
</style>