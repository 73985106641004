<template>
    <div :class="['gr-left-content-top',uid == 20281 || uid == 117431 ? 'jd' : '']">
        <div class="gr-lct-comp-info">
            <span class="gr-lct-comp-info-name">{{
                JSON.parse(userCompany).name
            }}</span>
            <div v-if="showStatus" class="gr-lct-comp-info-status">
                <span
                    v-show="JSON.parse(userCompany).status == 1"
                    class="status-ing status-list"
                    >{{$t('index.underReview')}}</span
                >
                <span
                    v-show="
                        JSON.parse(userCompany).status == 2 && !serviceExpired
                    "
                    class="status-ed status-list"
                >
                    {{$t('index.certified')}}</span
                >
                <span
                    v-show="JSON.parse(userCompany).status == 3"
                    class="status-no-c status-list"
                    >{{$t('index.notPassed')}}
                </span>
                <span
                    v-show="JSON.parse(userCompany).status == 0"
                    class="status-no status-list"
                    >{{$t('index.unauthenticated')}}</span
                >
                <!-- <el-button
                    v-show="JSON.parse(userCompany).status == 0"
                    size="mini"
                    type="text"
                    @click="retryAuth"
                    >立即认证</el-button
                >
                <span
                    class="time-in-tip"
                    v-show="
                        JSON.parse(userCompany).status == 2 && serviceExpired
                    "
                    >您的部分服务已到期，请联系 4001-789-800。</span
                > -->
            </div>
        </div>
        <div class="gr-lct-comp-acc">
            <div class="gr-lct-comp-acc-a">
                <span class="gr-lct-comp-acc-a-icon">
                    <i class="iconfont guoran-a-14-03"></i> </span
                ><span class="gr-lct-comp-acc-a-info">
                    <span class="gr-lct-comp-accai-name">{{$t('index.enterpriseAccount')}}</span>
                    <span class="gr-lct-comp-accai-acc">{{ acc }}</span>
                </span>
            </div>
            <div class="gr-lct-comp-acc-r">
                <span class="gr-lct-comp-acc-r-icon">
                    <i class="iconfont guoran-jiaoseguanli"></i></span
                ><span class="gr-lct-comp-acc-r-info">
                    <span class="gr-lct-comp-accai-name">{{$t('index.accountRole')}}</span>
                    <span class="gr-lct-comp-accai-acc">{{
                        userInfo.roles[0]
                    }}</span>
                </span>
            </div>
        </div>
        <div v-if="false" class="gr-lct-comp-acc-num">
            <span class="gr-lct-comp-acc-num-pre"
                >当前员工与上下游成员总数</span
            >
            <span class="gr-lct-comp-acc-num-n">199999999</span>
            <span class="gr-lct-comp-acc-num-aft">人</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        userCompany: {
            type: String,
            default: "",
        },
        serviceExpired: {
            type: Boolean,
            default: true,
        },
        uid:{
            type: String,
            default: "",
        }
    },
    data() {
        return {
            acc: "—",
            rolePrivilegesList: [],
            userInfo: {
                realName: "",
                tel: "",
                email: "",
                username: "",
                roles: [""],
            },
            botLimitData: {
                canCreateBot: true,
                canCreateIntent: true,
                canCreateNewBotNum: -1,
                canCreateNewIntentNum: -1,
                createBotNum: 0,
                createIntentNum: 0,
            },
            statusText: [this.$t('index.unauthenticated'), this.$t('index.underReview'), this.$t('index.certified'), this.$t('index.notPassed')],
            showStatus: false,
        };
    },
    mounted() {
        this.rolePrivilegesList = this.getRolePrivileges();
        this.$http.get("/api/account/current").then((res) => {
                if (res.data.code === "0") {
                    this.userInfo = res.data.data;
                    this.$emit('getUserInfo',this.userInfo)
                    this.acc = this.userInfo.realName
                    ? this.userInfo.realName
                    : this.userInfo.tel
                    ? this.userInfo.tel
                    : this.userInfo.email
                    ? this.userInfo.email
                    : this.userInfo.username;
                }
            });
        setTimeout(() => {
            this.showStatus = true;
        }, 300);
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        //         this.userInfo = userInfo;
        //         this.acc = userInfo.realName
        //             ? userInfo.realName
        //             : userInfo.tel
        //             ? userInfo.tel
        //             : userInfo.emai
        //             ? userInfo.email
        //             : userInfo.username;
        //     }, 100);
        // });
    },
    methods: {
        retryAuth() {
            this.$emit("retryAuth");
        },
        percentageFormat(use, all) {
            let allval = Number(all),
                useVal = Number(use);
            if (
                allval === -1 ||
                allval === "∞" ||
                allval == 0 ||
                allval == "null"
            ) {
                return 5;
            } else {
                return (useVal / allval) * 100;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.no-limit {
    font-size: 14px;
}
.time-in-tip {
    font-size: 12px;
}
.gr-left-content-top {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &.jd{
        justify-content: flex-start;
    }

    .gr-lct-comp-info {
        margin-top: 10px;
        height: 30px;
        text-align: center;
        position: relative;

        .gr-lct-comp-info-name {
            display: block;
            height: 20px;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
        }

        .gr-lct-comp-info-status {
        }

        .status-list {
            position: absolute;
            width: 120px;
            height: 24px;
            line-height: 24px;
            top: -10px;
            right: -60px;
            transform: rotate(45deg);
        }

        .status-ing {
            background-color: #366aff;
            color: white;
        }

        .status-ed {
            background-color: #09ddd5;
            color: white;
        }

        .status-no-c {
            background-color: #FE5965;
            color: white;
        }

        .status-no {
            background-color: #D7DDEA;
            color: white;
        }
    }

    .gr-lct-comp-acc {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;

        .gr-lct-comp-acc-a {
            flex: 1;
            height: 82px;
            background: #eff5ff;
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;

            .gr-lct-comp-acc-a-icon {
                flex: none;
                width: 32px;
                height: 32px;
                line-height: 32px;
                background: #458fff;
                border-radius: 50%;
                color: white;
                text-align: center;
                font-size: 13px;
                margin: auto 10px;
            }

            .gr-lct-comp-acc-a-info {
                padding: 10px 0;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;

                .gr-lct-comp-accai-name {
                    color: #458fff;
                    font-size: 14px;
                    font-weight: 700;
                }

                .gr-lct-comp-accai-acc {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

        .gr-lct-comp-acc-r {
            flex: 1;
            margin-left: 16px;
            height: 82px;
            background: #e1faf9;
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;

            .gr-lct-comp-acc-r-icon {
                flex: none;
                width: 32px;
                height: 32px;
                line-height: 32px;
                background: #09ddd5;
                border-radius: 50%;
                color: white;
                text-align: center;
                font-size: 13px;
                margin: auto 10px;
            }

            .gr-lct-comp-acc-r-info {
                padding: 10px 0;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;

                .gr-lct-comp-accai-name {
                    color: #09ddd5;
                    font-size: 14px;
                    font-weight: 700;
                }

                .gr-lct-comp-accai-acc {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    .gr-lct-comp-acc-num {
        margin-top: 16px;
        height: 37px;
        background: #eff5ff;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;

        .gr-lct-comp-acc-num-pre {
            line-height: 37px;
            margin-left: 8px;
            color: #366aff;
            font-size: 13px;
        }
        .gr-lct-comp-acc-num-n {
            margin-left: 6px;
            color: #000000;
            font-size: 16px;
            line-height: 37px;
            font-weight: 500;
        }
        .gr-lct-comp-acc-num-aft {
            line-height: 38px;
            margin-left: 3px;
            font-size: 12px;
        }
    }
}
</style>