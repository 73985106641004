<!-- 
/**
pageName:时间日期/说明文字/图片/附件/视频
auther:liuyuli
time:2022-4-25
 */
 -->
<template>
    <div class="date-add-upload">
        <top-config 
            v-if="activeObjs.type === 'TIME_PICKER' || activeObjs.type === 'TIME_PICKER_RANGE'"
            :activeObj="activeObjs"></top-config>
        <!-- 时间如期 -->
        <div class="date-content" v-if="activeObjs.type === 'TIME_PICKER' || activeObjs.type === 'TIME_PICKER_RANGE'">
            <div class="item-title"><span>类型</span></div>
            <el-select v-model="activeObjs.extInfo.mold" @change="changeMold" placeholder="请选择" filterable>
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
          <ckeditor
              v-if="activeObjs.extInfo.mold === 'OTHER'"
              :editor="ckeditor.editor"
              v-model="activeObjs.extInfo.pattern"
              :config="ckeditor.editorConfig"
              @focus="(zh,editor)=>ckeditor.currentEditor=editor"
              @ready="(editor)=>ckeditor.currentEditor=editor"
          ></ckeditor>
<!--            <el-input
                v-if="activeObjs.extInfo.mold === 'OTHER'"
                type="textarea"
                :rows="10"
                placeholder="请输入"
                v-model="activeObjs.extInfo.pattern">
            </el-input>-->
            <div class="choose-type-box" v-if="activeObjs.extInfo.mold === 'OTHER'">
                <div 
                    :class="['date-item-type',item.checked ? 'active' : '']" 
                    v-for="(item,index) in dateTypeList" 
                    :key="index"
                    @click="chooseDateType(item,index)">
                    {{item.label}}
                </div>
            </div>
        </div>
        <!-- 图片/视频/附件 -->
        <div class="image-video-fujian" v-if="activeObjs.type === 'IMAGE' || activeObjs.type === 'VIDEO' || activeObjs.type === 'ATTACHMENT'">
            <div class="is-inline">
                <div class="left">
                    <span>标题</span>
                    <span class="tips">最多20个字</span>
                </div>
                <div class="bottom">
                    <el-input v-model="activeObjs.name" :maxlength="20" placeholder="请输入"></el-input>
                </div>
            </div>
            <div class="limit-num">
                <span>允许上传</span>
                <el-input v-model="activeObjs.extInfo.limit" type="number" :min="1" @change="changeLimit"></el-input>
                <span>{{getText(activeObjs.type)}}</span>
            </div>
        </div>
         <!-- 说明文字 -->
        <div class="image-video-fujian" v-if="activeObjs.type === 'EXPLAIN'">
            <div class="is-inline">
                <div class="left">
                    <span>说明内容 <span class="red">*</span> </span>
                    <span class="tips">最多100个字</span>
                </div>
                <div class="bottom">
                    <el-input v-model="activeObjs.extInfo.placeholder" :maxlength="100" type="textarea"  :rows="10" :placeholder="activeObjs.extInfo.placeholder"></el-input>
                </div>
            </div>
             <div class="is-inline">
                <div class="left">
                    <span>可输入链接跳转地址 </span>
                    <span class="tips">可点击跳转至链接</span>
                </div>
                <div class="bottom">
                    <el-input v-model="activeObjs.extInfo.url" :maxlength="100" type="textarea"  :rows="10" ></el-input>
                </div>
            </div>
        </div>
        <bottom-common  :activeObj="activeObjs" v-if="activeObjs.type !== 'EXPLAIN'"></bottom-common>
    </div>
</template>

<script>
import topConfig from '../common/topCommon.vue';
import bottomCommon from '../common/bottomCommon.vue';
import CKEDITOR from "ckeditor";
// import mixins from '../../mixins';
export default {
    // mixins:[mixins],
    components:{
       topConfig,
       bottomCommon
    },
    data(){
        return {
            dateActiveIndex:-1,
            options:[{
                label:'年-月-日',
                value:"DATE"
            },{
                label:'年-月-日 时:分',
                value:"DATE_TIME"
            },{
                label:'时:分',
                value:"TIME"
            },{
                label:'其他',
                value:"OTHER"
            }],
            dateTypeList:[{
                label:'年',
                value:"YEAR",
                checked:false
            },{
                label:'月',
                value:"MONTH",
                checked:false
            },{
                label:'日',
                value:"DAY",
                checked:false
            },{
                label:'时',
                value:"HOUR",
                checked:false
            },{
                label:'分',
                value:"MINUTE",
                checked:false
            },{
                label:'秒',
                value:"SECOND",
                checked:false
            }],
          ckeditor: {
            editor: CKEDITOR.ClassicEditor,
            editorConfig: {
              toolbar: [],
              askPluginListener: [
                {
                  event: "PLACEHOLDER",
                  process: (data, callback) => {
                    console.debug('editor call back', data, callback)
                  },
                }
              ], // 注册监听
            },
            currentEditor:null
          },
        }
    },
    props:{
		activeObj:{
			type:Object,
			default(){
				return{}
			}
		},
        fieldData:{
			type:Object,
			default(){
				return{}
			}
		}
	},
    computed:{
        activeObjs(){
            return this.activeObj;
        }
    },
    methods:{
        changeLimit(value){
            if (value < 1){
            this.activeObjs.extInfo.limit = 1
            }
        },
        chooseDateType(item,index){
          let command = this.ckeditor.currentEditor.commands.get("insertAskComponent");
          command.execute({
            tag: "span",
            options: {
              name: item.label,
              data: item.value,
            },
          });
           /* this.dateActiveIndex = index;
            item.checked = !item.checked;*/
        },
        getText(type){
            switch (type) {
                case 'IMAGE':
                    return '张图片';
                case 'VIDEO':
                    return '个视频';
                case 'ATTACHMENT':
                    return '个附件'
            
            }
        },
        changeMold() {
            this.activeObjs.defaultValue = ''
        }
    }

}
</script>

<style lang="less" scoped>
    .date-add-upload{
        .el-select{
            width: 100%;
            margin-bottom: 20px;
        }
        .item-title{
            margin-bottom: 10px;
            color: #616161;
        }
        .date-content{
          /deep/.ask-component-placeholder{
            background-color: rgba(0,89,255,.2);
            display: inline-block;
            padding: 3px 6px;
            border-radius: 3px;
            margin-right: 10px;
            margin-bottom: 8px;
          }
        }
        .choose-type-box{
            font-size: 14px;
            color: #366AFF;
            margin: 10px 0 20px 0;
            display: flex;
            justify-content: space-between;
            .date-item-type{
                width: 46px;
                height: 33px;
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                border-radius: 17px;
                text-align: center;
                line-height: 33px;
                cursor: pointer;
                &.active{
                    background-color: #366AFF;
                    color: #fff;
                }
            }
        }
        .image-video-fujian{
            .is-inline{
                font-size: 14px;
                color:#616161;
                 margin-bottom: 20px;
                .left{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    .tips{
                        color: #A9B3C6;
                        margin-left: 10px;
                    }
                    .red{
                        color: red;
                    }
                }
            }
        }
        .limit-num{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin: 20px 0;
            height: 58px;
            line-height: 58px;
            background: #FBFCFD;
            border-radius: 5px;
            span{
                white-space: nowrap
            }
            .el-input{
                width: 194px;
                margin: 0 10px;
            }
        }
    }
</style>>

