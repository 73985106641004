<template>
    <div class="invite-setting">
         <popup @closeEvent="closeEvent" v-if="inviteSettingPopup">
           <div slot="popup-name" class="popup-name">邀请设置</div>
              <div slot="popup-tip">设置邀请规则</div>
            <div slot="popup-con">
                <div class="allow-invite">
                    <div class="switch-and-icon">
                        <el-switch
                            @click.native.prevent="changeValue('switch')"
                            :width="42"
                            v-model="allowGeneralInvite"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2">
                        >
                        </el-switch>
                        <span
                            class="switch-open-icon"
                            @click="changeValue('open')"
                            v-if="allowGeneralInvite">
                            <i class="iconfont guoran-a-16-17"></i>
                        </span>
                        <span
                            class="switch-close-icon"
                            @click="changeValue('close')"
                            v-if="!allowGeneralInvite">
                            <i class="arsenal_icon arsenalcuo1"></i>
                        </span>
                    </div>
                    <span>允许非管理员邀请成员</span>
                </div>
                <div class="tips">
                    未开启时，仅拥有员工管理权限的管理员可以发起邀请；开启后，企业内普通成员也可以在客户端中发起邀请。
                </div>
				
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >取 消</el-button>
                <el-button type="primary" @click="saveSet" round >保 存</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
export default {
    components:{
		Popup,
	},
    props:{
		inviteSettingPopup:{
			default:false,
			type:Boolean
		},
        
            
       
	},
    data(){
        return {
            allowGeneralInvite:true,
        }
    },
    watch:{
        inviteSettingPopup(n,o){
            if(n){
                this.getInviteSetting()
            }
        },
    },
   methods:{
       closeEvent(){
           this.$emit('update:inviteSettingPopup',false)
       },
       saveSet(){
           this.$http.get(`${this.requestUrl.invite.updateCompanyInviteSet+'?allowGeneralInvite='+this.allowGeneralInvite}`)
                .then((res) => {
                    if (res.data.code === "0") {
                        this.$message.success(this.$t('inviteEnter.updateSuccess'));
                        this.$emit('update:inviteSettingPopup',false)
                    }
                })
           
       },
       // 改变
        changeValue(datas){
            if(datas === 'open'){
               this.allowGeneralInvite = false;
            } else if (datas === 'close'){
                this.allowGeneralInvite = true;
            } 
        },
       getInviteSetting(){
            this.$http.get(`${this.requestUrl.invite.selectCompanyInviteSet}`)
                .then((res) => {
                    if (res.data.code === "0") {
                       this.allowGeneralInvite = res.data.data.allowGeneralInvite;
                    }
                });
       },
       
   }
}
</script>

<style scoped lang="less">
.invite-setting{
   .allow-invite{
        height: 40px;
        background: #FBFCFD;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 20px;
        .switch-and-icon {
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            text-align: center;
            align-items: center;
            width: 42px;
            margin-right: 6px;
            .el-switch {
                text-align: center;
                
            }
        
            .switch-open-icon {
                position: absolute;
                // z-index: 999;
                top: 50%;
                transform: translateY(-50%);
                left: 3px;
        
                i {
                    color: white;
                    font-size: 18px;
                }
            }
        
            .switch-close-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 21px;
                i {
                    color: #6E6B7B;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        
            i {
                color: #366AFF;
                font-size: 18px;
            }
            .el-switch__core::after{
                width: 14px;
                height: 14px;
                top: 2px;
                left: 2px;
            }
            .is-checked .el-switch__core::after{
                left: 40px;
            }
            &.disabled{
                i,.el-switch__input,.el-switch__core{
                    cursor: no-drop;
                }
            }
        }
   }
   .tips{
        background: #E5F8ED;
        border: 1px solid #C0F4D5;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        color: #04B051;
        text-align: left;
        line-height: 18px;
        padding: 13px;
   }
  
}
</style>