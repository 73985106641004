<template>
    <div class="action-fast-select">
        <div class="fast-select-des">
            <span>
                {{$t('flowComponent.actionFastSelect.tip')}}</span
            >
        </div>
        <div class="fast-select-content">
            <div class="select-cell-title">
                <span class="select-cell-title-name">{{$t('flowComponent.actionFastSelect.name')}}</span>
                <span class="select-cell-title-action">{{$t('flowComponent.actionFastSelect.action')}}</span>
            </div>
            <div class="fast-select-content-sort">
                <div
                    v-for="(item, formIndex) in unit.content.list"
                    :key="formIndex"
                    class="fast-select-content-cell"
                >
                    <div class="move-box">
                        <span
                            class="
                                iconfont
                                guoran-tongyichicun-16-10-paixu
                                icon-handle-fast
                            "
                        ></span>
                    </div>
                    <div class="fast-select-content-cell-form">
                        <div class="select-cell-pre">
                            <el-input
                                size="small"
                                v-model="item.text"
                                maxlength="20"
                                :placeholder="$t('flowComponent.actionFastSelect.namePla')"
                            ></el-input>
                        </div>
                        <div class="select-cell">
                            <div class="select-cell-sub-content">
                                <div class="select-cell-sub-content-pre">
                                    <el-select
                                        v-model="item.action.scope"
                                        @change="changeScope(formIndex)"
                                        size="small"
                                        :placeholder="$t('common.selectPlaceholder')"
                                    >
                                        <el-option
                                            v-for="item in jumpTypeAddSendOptionsFast"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </div>
                                <div class="select-cell-sub-content-aft">
                                    <el-input
                                        v-if="
                                            item.action.scope ===
                                                'SEND_MSG_TO_BOT' ||
                                            item.action.scope ===
                                                'SEND_MSG_TO_USER' ||
                                            item.action.scope === 'LINK'
                                        "
                                        size="small"
                                        :placeholder="
                                            item.action.scope === 'LINK'
                                                ? $t('flowComponent.actionFastSelect.linkPla')
                                                : $t('flowComponent.actionFastSelect.sendContentPla')
                                        "
                                        v-model="item.action.value"
                                    >
                                    </el-input>
                                    <!-- <el-select
                                        v-if="item.action.scope === 'NODE'"
                                        v-model="item.action.value"
                                        :popper-append-to-body="false"
                                        filterable
                                        size="small"
                                        placeholder="请输入选择名称"
                                    >
                                        <el-option
                                            v-for="item in nodeOptions"
                                            :key="item.id"
                                            :popper-class="
                                                'pop_my' +
                                                item.classPop +
                                                item.parentNum
                                            "
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select> -->
                                    <SelectAddnode
                                        v-if="item.action.scope === 'NODE'"
                                        :nodeOptions="nodeOptions"
                                        :nodeId="item.action.value"
                                        :defaultParentNodeId="nodeId"
                                        :nodeSelectARef="`actionFast_select_${formIndex}_${unitIndex}`"
                                        :intentId="intentId"
                                        @getNodeOptions="getNodeOptions"
                                        @setAddNodeId="setAddNodeId"
                                        :index="formIndex"
                                    ></SelectAddnode>
                                    <el-cascader
                                        v-if="!isBotLink"
                                        filterable
                                        v-show="item.action.scope === 'INTENT'"
                                        v-model="item.action.value"
                                        size="small"
                                        :key="`actionFast_cascader_${formIndex}_${unitIndex}`"
                                        :options="intentTree4Radio"
                                        :placeholder="$t('flowComponent.confirmValue.jumpIntentPla')"
                                        :props="{
                                            emitPath: false,
                                            label: 'name',
                                            value: 'id',
                                        }"
                                    ></el-cascader>
                                    <template v-if="item.action.scope === 'INTENT' && isBotLink">
                                        <el-popover
                                            :key="`actionFast_popover_${formIndex}_${unitIndex}`"
                                            :ref="`actionFast_popover_${formIndex}_${unitIndex}`"
                                            placement="bottom"
                                            width="300"
                                            trigger="click"
                                            popper-class="set-welcome-message-search-intent-poprver"
                                            :visible-arrow="false"
                                            @show="showPover(item.action)">
                                            <el-input
                                                class="search-intent-input"
                                                size="small"
                                                :placeholder="$t('flowComponent.searchOrSelectIntent')"
                                                v-model="item.action.valueText"
                                                slot="reference"
                                                @input="inputFn($event,item.action.valueText)">
                                                <i slot="suffix" class="el-icon-arrow-down"></i>
                                            </el-input>
                                            <el-tree
                                                class="choose-intent-tree"
                                                style="width:300px;height:300px;overflow-x:hidden;overflow-y:auto;"
                                                :data="intentTree4Radio"
                                                :props="{
                                                    children: 'children',
                                                    label: 'name',
                                                    id:'id',
                                                    isLeaf: 'isLeafNode',
                                                }"
                                                :filter-node-method="filterNode"
                                                node-key="id"
                                                ref="tree">
                                                <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node,item.action,formIndex,unitIndex)">
                                                    <span class="custom-tree-node-left">
                                                        <span :class="['data-label']">{{ data.name }}</span>
                                                    </span>
                                                    <i class="el-icon-check" v-if="data.checked"></i>
                                                </span>
                                            </el-tree>
                                        </el-popover>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-a-handle-box">
                        <span
                            v-if="formIndex == 0"
                            @click="addToEntityCell"
                            class="answer-text-cell-handle-icon-add"
                        >
                            <i class="el-icon-plus"></i>
                        </span>
                        <span
                            v-else
                            @click="deleteToEntityCell(formIndex)"
                            class="answer-text-cell-handle-icon-delete"
                        >
                            <i class="el-icon-close"></i
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sortable from "sortablejs";
import SelectAddnode from "../cell/SelectAddnode.vue";
export default {
    name: "fastSelect",
    components: {
        SelectAddnode,
    },
    props: [
        "unit",
        "unitIndex",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "isStartOrEnd",
        "nodeId",
        "intentId",
        "isBotLink"
    ],
    data() {
        return {
            jumpTypeAddSendOptionsFast: [
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_BOT",
                    label: "向机器人发送内容",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
                {
                    value: "LINK",
                    label: "跳转链接",
                },
            ],
        };
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            this.$set(this.unit.content.list[index].action, "value", nodeId);
        },
        changeScope(formIndex) {
            this.unit.content.list[formIndex].action.value = "";
        },
        addToEntityCell() {
            this.unit.content.list.push({
                text: "",
                action: {
                    scope: this.isStartOrEnd ? "INTENT" : "NODE",
                    value: "",
                },
            });
        },
        deleteToEntityCell(index) {
            this.unit.content.list.splice(index, 1);
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        rowDrop() {
            const box = document.querySelector(".fast-select-content-sort");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-fast",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.list.splice(oldIndex, 1);
                    _this.unit.content.list.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.unit.content.list = [..._this.unit.content.list];
        },
        inputFn(val,item){
            this.$refs.tree[0].filter(val);
            this.$forceUpdate()
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data,node,item,formIndex,unitIndex){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item,'valueText',data.name);
            this.$set(item,'value',data.id)
            this.$set(data,'checked',true)
            this.$refs[`actionFast_popover_${formIndex}_${unitIndex}`][0].doClose()
        },
        showPover(data){
            let list = JSON.parse(JSON.stringify(this.intentTree4Radio))
            list.forEach(botItem => {
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(data.value == intentItem.id){
                                intentItem.checked = true;
                            } 
                        })
                    })
                })
            })
            this.intentTree4Radio =  JSON.parse(JSON.stringify(list))
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.rowDrop();
        });
        if (!this.isStartOrEnd) {
            this.jumpTypeAddSendOptionsFast.unshift({
                value: "NODE",
                label: "跳转到节点",
            });
        }
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";
.action-fast-select {
    .fast-select-des {
        background: #fff8f4;
        border-radius: 5px;
        font-size: 12px;
        color: #ff9555;
        padding: 8px;
        text-align: left;
        margin-bottom: 12px;
    }
    .fast-select-content {
        background: #fbfcfd;
        padding: 12px 12px 12px 0;
        .select-cell-title {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            margin-bottom: 12px;
            .select-cell-title-name {
                margin-left: 32px;
                flex: none;
                width: 175px;
            }
            .select-cell-title-action {
                flex: auto;
            }
        }
        .fast-select-content-cell {
            margin-bottom: 4px;
            border-radius: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .move-box {
                flex: none;
                width: 20px;
                text-align: center;
                display: flex;
                align-items: center;
                padding-left: 12px;
                cursor: pointer;
                span {
                    font-size: 14px;
                    color: #366aff;
                    font-weight: 900;
                }
            }
            .fast-select-content-cell-form {
                flex: auto;
                display: flex;
                justify-content: flex-start;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                /deep/.inline-input {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-cascader {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-input__inner {
                    border: none;
                    padding-right: 0;
                }
                /deep/.el-input__suffix {
                    display: none;
                }
                .select-cell-pre {
                    flex: none;
                    width: 160px;
                }
                .select-cell {
                    flex: auto;
                    .select-cell-sub-content {
                        display: flex;

                        justify-content: flex-start;
                        .select-cell-sub-content-pre {
                            flex: none;
                            width: 125px;
                            margin-right: 6px;
                        }
                        .select-cell-sub-content-aft {
                            flex: auto;
                            .el-input,
                            .el-select,
                            .el-cascader {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .d-a-handle-box {
                flex: none;
                width: 40px;
            }
        }
    }
}
</style>