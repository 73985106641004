/**
 *
 * jsapi_ticket: "HoagFKDcsGMVCIY2vOjf9jGhdooqR5TAS45OVa9SLYiKzG4SwP2e74j1yTMu-BMBz2u2r2vPdPHDeiwlwAfuLQ"
     nonceStr: "3588440d-03f8-4eb6-b05a-b7f2062c29fb"
     signature: "b5c5b0880fea372a4e4bc03f8b4ccaea7ccd9d3b"
     timestamp: "1630902814"
     url: "http://localhost:8081/"
 * @param
 * @param onSuccess
 */
function agentConfig(corpid,config,onSuccess) {
    //    corpid: '', // 必填，企业微信的corpid，必须与当前登录的企业一致
    //     agentid: '', // 必填，企业微信的应用id （e.g. 1000247）
    //     timestamp: , // 必填，生成签名的时间戳
    //     nonceStr: '', // 必填，生成签名的随机串
    //     signature: '',/
    console.info('config agent for config',config)
    wx.agentConfig({
        corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: config.agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp:config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['selectExternalContact'], //必填，传入需要使用的接口名称
        success: function (res) {
            // 回调
            if (onSuccess){
                onSuccess(res);
            }
        },
        fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
                alert('版本过低请升级')
            }

            console.error('agent config fail',JSON.stringify(res));
        }
    });
}

export default agentConfig