<template>
    <div id="personal">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header ref="topHeader">
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-a-14-14"></i
                        ></span>
                        <span>{{$t('personal.title')}}</span>
                    </div>
                </top-header>
            </div>
            <div class="content">
                <personalContent @reload="reload"></personalContent>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
    </div>
</template>

<script>
// import Popup from "@/components/popup";
import SideNav from "./../menu/SideNav.vue";
import TopHeader from "./../../components/top-header.vue";
// import accountBind from "./components/AccountBind.vue";
// import EditPassword from "./components/EditPassword.vue";
// import EditPhone from "./components/EditPhone.vue";
// import EditNick from "./components/EditNick.vue";
import personalContent from "./components/personalContent.vue";
import { redirect2LoginPage } from "../../const/redirectUri";

export default {
    name: "personal",
    data() {
        return {
            rolePrivilegesList: [],
            labelPosition: "top",
            formLabelAlign: {
                petName: "",
                realName: "",
                phone: null,
                accountNumber: "",
                userRole: "",
                userDelGoodAtWorkOrders: [],
                admin: false,
            },
            fileList: "",
            uploadUrl: "/api-open/oss/public",
            uploadAvatarParams: {
                token: "8c98087dfd2d48f856d8c95c09115def",
            },
            uploadImg: "",
            userInfo: {},
            uid: localStorage.getItem("_uid"),
            active: "personal",
            passWord: {
                oldPassword: "",
                newPassword: "",
                againPassword: "",
            },
            OriginalPassword: "", //原始密码
            userSource: 0,
            accountList: [],
            // 擅长工单
            editWorkDialog: false,
            workOrderTypesStr: "",
            // 工单类别
            workOrderType: [],
            workOrderTypeTree: [],
            typeTreeDefaultProps: {
                children: "children",
                label: "workOrderTypeName",
            },
            workOrderSelectIng: false,
            isShowWorkOrder: true,
            workOrderDefaultCheckedKeys: [],
            haveWorkOrderPermission: false,
        };
    },
    components: {
        SideNav,
        TopHeader,
        personalContent
    },
    mounted() {
        // this.getUserInfo();
        // // 入口权限
        // this.rolePrivilegesList = this.getRolePrivileges();
    },
    filters: {
        realNameAvt: function (value) {
            if (value != null && String(value).length > 0) {
                return value.substring(0, 1);
            }
        },
    },
    methods:{
        reload() {
            this.$refs.topHeader.getUserInfo()
        }
    }
    // methods: {
    //     editWorkorder() {
    //         this.editWorkDialog = true;
    //     },
    //     saveUserGoodAtWorkOrders() {
    //         this.saveCustomerInfo("workorder");
    //     },
    //     editPassword() {
    //         this.$refs.editPassword.initShow();
    //     },
    //     editPhone(phone) {
    //         this.$refs.editPhone.initShow({ type: "edit", phone: phone });
    //     },
    //     editNickname() {
    //         this.$refs.editNick.initShow(this.formLabelAlign.petName);
    //     },
    //     bindPhone() {
    //         this.$refs.editPhone.initShow({ type: "bind" });
    //     },
    //     saveCustomerInfo(type, val) {
    //         let userInfo = {
    //             nickName: this.formLabelAlign.petName,
    //             realName: this.formLabelAlign.realName,
    //         };
    //         if (type === "avt") {
    //             userInfo.customerProfilePhoto = val;
    //         }
    //         if (type === "nickName") {
    //             userInfo.nickName = val;
    //         }
    //         if (type === "workorder") {
    //             userInfo.admin = this.formLabelAlign.admin;
    //             userInfo.userGoodAtWorkOrders = this.workOrderType.map(
    //                 (item) => {
    //                     if (this.formLabelAlign.admin) {
    //                         return item;
    //                     } else {
    //                         if (!item.adminAssign) {
    //                             return item;
    //                         }
    //                     }
    //                 }
    //             );
    //         }
    //         this.$http
    //             .put("/api/account/appoint/" + this.uid, userInfo)
    //             .then((res) => {
    //                 if (res.data.code === "0") {
    //                     this.$message.success("修改成功！");
    //                     if (type === "nickName" || type === "workorder") {
    //                         this.getUserInfo();
    //                         if (type === "workorder") {
    //                             this.editWorkDialog = false;
    //                         }
    //                     }
    //                 }
    //             });
    //     },
    //     treeNodeClick(data, node, el) {
    //         if (data.disabled) {
    //             this.$message.warning(
    //                 "您无法取消该擅长的类型，请联系管理员取消"
    //             );
    //         }
    //     },
    //     elDropdownvisibleChange(val) {
    //         this.workOrderSelectIng = val;
    //     },
    //     WorkOrderTypeHandleCheckChange(node, val, c_val, index) {
    //         if (val) {
    //             node.treeIndex = index;
    //             node.adminAssign = this.formLabelAlign.admin;
    //             node.workOrderTypeId = node.id;
    //             node.userId = localStorage.getItem("_uid");
    //             this.workOrderType.push(node);
    //         } else {
    //             this.workOrderType.forEach((item, index) => {
    //                 if (item.id == node.id) {
    //                     this.workOrderType.splice(index, 1);
    //                 }
    //             });
    //         }
    //         this.workOrderType = [...this.workOrderType];
    //     },
    //     // 上传头像
    //     handleAvatarSuccess(res, file) {
    //         if (res !== "") {
    //             this.uploadImg = res;
    //         }
    //         this.fileList = URL.createObjectURL(file.raw);
    //         this.saveCustomerInfo("avt", res);
    //     },
    //     handleError() {
    //         console.log("图片上传失败");
    //     },
    //     beforeAvatarUpload(file) {
    //         const isSupportedFormat =
    //             file.type === "image/jpeg" ||
    //             file.type === "image/png" ||
    //             file.type === "image/jpeg";
    //         const isLte2M = file.size / 1024 / 1024 <= 2;

    //         if (!isSupportedFormat) {
    //             this.$message.error(
    //                 "上传头像图片只能是 JPG 或 PNG 格式 或 JPEG 格式!"
    //             );
    //         }
    //         if (!isLte2M) {
    //             this.$message.error("上传头像图片大小不能超过 2MB!");
    //         }
    //         return isSupportedFormat && isLte2M;
    //     },
    //     fltDisabled(list) {
    //         let adminSetIds = this.workOrderType.map((item) => {
    //             if (item.adminAssign) {
    //                 return item.id;
    //             }
    //         });
    //         let tampArr = list;
    //         const fltTree = (list) => {
    //             list.forEach((item) => {
    //                 if (adminSetIds.indexOf(item.id) == -1) {
    //                     item.disabled = false;
    //                 } else {
    //                     if (!this.formLabelAlign.admin) {
    //                         item.disabled = true;
    //                     }
    //                 }
    //                 if (item.children != null && item.children.length != 0) {
    //                     fltTree(item.children);
    //                 }
    //             });
    //         };
    //         fltTree(tampArr);
    //         return tampArr;
    //     },
    //     //获取用户信息
    //     getUserInfo() {
    //         let uid = localStorage.getItem("_uid");
    //         this.$http.get("/api/account/appoint/" + uid).then((res) => {
    //             if (res.data.code === "0") {
    //                 this.userInfo = res.data.data;
    //                 //初始化姓名
    //                 if (this.userInfo.realName !== null) {
    //                     this.formLabelAlign.realName = this.userInfo.realName;
    //                 }
    //                 //初始化账户
    //                 this.formLabelAlign.accountNumber = this.userInfo.username;
    //                 //初始化昵称
    //                 if (this.userInfo.nickName !== null) {
    //                     this.formLabelAlign.petName = this.userInfo.nickName;
    //                 }
    //                 //初始化电话
    //                 if (this.userInfo.tel !== null) {
    //                     this.formLabelAlign.phone = this.userInfo.tel;
    //                 }
    //                 //初始化角色
    //                 if (this.userInfo.roles[0] !== null) {
    //                     this.formLabelAlign.userRole = this.userInfo.roles[0];
    //                 }
    //                 //初始化角色是否为管理员
    //                 if (this.userInfo.admin != null) {
    //                     this.formLabelAlign.admin = this.userInfo.admin;
    //                 }
    //                 // 判断是否展示工单类型选择
    //                 if (this.userInfo.haveWorkOrderPermission != null) {
    //                     this.haveWorkOrderPermission =
    //                         this.userInfo.haveWorkOrderPermission;
    //                 } else {
    //                     this.haveWorkOrderPermission = false;
    //                 }
    //                 //初始化角色
    //                 if (this.userInfo.userGoodAtWorkOrders != null) {
    //                     this.workOrderType = this.userInfo.userGoodAtWorkOrders;
    //                     this.workOrderDefaultCheckedKeys =
    //                         this.workOrderType.map((item) => item.id);

    //                     this.workOrderTypesStr = "";
    //                     this.userInfo.userGoodAtWorkOrders.forEach(
    //                         (item, index) => {
    //                             if (index === 0) {
    //                                 this.workOrderTypesStr +=
    //                                     item.workOrderTypeName;
    //                             } else {
    //                                 this.workOrderTypesStr +=
    //                                     " / " + item.workOrderTypeName;
    //                             }
    //                         }
    //                     );
    //                 }
    //                 if (this.userInfo.workOrderTypes != null) {
    //                     this.workOrderTypeTree =
    //                         this.userInfo.workOrderTypes.map((item, index) => {
    //                             item.treeIndex = index;
    //                             return [item];
    //                         });
    //                     this.workOrderTypeTree.forEach((item) => {
    //                         item = this.fltDisabled(item);
    //                     });
    //                 }

    //                 //初始化来源
    //                 if (this.userInfo.source !== null) {
    //                     this.userSource = this.userInfo.source;
    //                 } else {
    //                     let roles = localStorage.getItem("roleId");
    //                     let rolesName = "";
    //                     if (roles === "0") {
    //                         rolesName = "系统测试";
    //                     }
    //                     if (roles === "1") {
    //                         rolesName = "超级管理员";
    //                     }
    //                     if (roles === "2") {
    //                         rolesName = "机器人管理员";
    //                     }
    //                     if (roles === "3") {
    //                         rolesName = "机器人配置员";
    //                     }
    //                     if (roles === "4") {
    //                         rolesName = "人工客服";
    //                     }
    //                     if (roles === "5") {
    //                         rolesName = "工单管理员";
    //                     }
    //                     if (roles === "6") {
    //                         rolesName = "工单成员";
    //                     }
    //                     this.formLabelAlign.userRole = rolesName;
    //                 }
    //                 //初始化头像
    //                 this.fileList = this.userInfo.customerProfilePhoto
    //                     ? this.userInfo.customerProfilePhoto
    //                     : "https://static.guoranbot.com/cdn-office-website/images/default_a_avt.png";
    //                 this.uploadImg = this.userInfo.customerProfilePhoto
    //                     ? this.userInfo.customerProfilePhoto
    //                     : "https://static.guoranbot.com/cdn-office-website/images/default_a_avt.png";
    //             }
    //         });
    //     },
    // },
};
</script>

<style lang="less">
@import "../../assets/less/personal/personalV2.less";

.ImClassT {
    color: red;
}

.otherClassT {
    color: #366AFF;
}
.activeClass {
    border-right: 2px solid #366AFF;
}

.avt {
    .camera-m {
        display: none;
    }
}

.avt:hover {
    position: relative;
    .camera-m {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 20px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: 76px;
        color: white;
    }
}
</style>
