<template>
  <div>
    <div :class="'gac-header-b' + activeStep">
      <template v-for="(item,index) in stepList">
        <div :class="['gac-header-b-cell', 'gac-header-b-cell-' + (index + 1)]">
          <span class="ghbc-text"><span class="ghbc-number">{{ index + 1 }}</span>{{ $t('channel.steps.' + item.id) }}</span>
        </div>
        <div class="triangle-outer-blue"
             :class="activeStep === (index + 1) ? 'triangle-outer-blue' : 'triangle-outer-gray'">
          <span class="triangle"></span>
        </div>
      </template>
    </div>
    <!--        <div v-if="activeStep === 1" class="gac-header-b1">-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-1">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">1</span>基础配置</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-blue">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-2">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">2</span>能力管理</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-3">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">3</span>接入信息</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-4">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">4</span>完成接入</span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div v-if="activeStep === 2" class="gac-header-b2">-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-1">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">1</span>基础配置</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-2">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">2</span>能力管理</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-blue">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-3">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">3</span>接入信息</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-4">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">4</span>完成接入</span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div v-if="activeStep === 3" class="gac-header-b3">-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-1">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">1</span>基础配置</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-2">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">2</span>能力管理</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-3">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">3</span>接入信息</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-blue">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-4">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">4</span>完成接入</span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div v-if="activeStep === 4" class="gac-header-b4">-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-1">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">1</span>基础配置</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-2">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">2</span>能力管理</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-3">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">3</span>接入信息</span>-->
    <!--            </div>-->
    <!--            <div class="triangle-outer-gray">-->
    <!--              <span class="triangle"></span>-->
    <!--            </div>-->
    <!--            <div class="gac-header-b-cell gac-header-b-cell-4">-->
    <!--              <span class="ghbc-text"><span class="ghbc-number">4</span>完成接入</span>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeStep: 1
    };
  },
  props: {
    stepList: {
      type: Array,
      default() {
        return [
          {
            name: "基础配置"
          },
          {
            name: "能力管理"
          },
          {
            name: "接入信息"
          },
          {
            name: "完成接入"
          }
        ];
      }
    }
  },
  methods: {
    changeTopActiveStep(value) {
      let preStep = this.activeStep;
      this.$emit("changeTopActiveStep", preStep, value, () => {
        this.activeStep = value;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.gac-header-b1 {
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f6fc;
  overflow: hidden;

  .gac-header-b-cell {
    height: 42px;
    line-height: 42px;
    flex: 1;
    background-color: #366aff;
  }

  .gac-header-b-cell-1 {
    z-index: 2;
    text-align: center;

    .ghbc-text {
      color: white;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: #6990ff;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-2 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-3 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-4 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .triangle-outer-blue {
    z-index: 1;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #366aff;
    }
  }

  .triangle-outer-gray {
    z-index: 1;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #f2f6fc;
    }
  }
}

.gac-header-b2 {
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f6fc;
  overflow: hidden;

  .gac-header-b-cell {
    height: 42px;
    line-height: 42px;
    flex: 1;
    background-color: #366aff;
  }

  .gac-header-b-cell-1 {
    text-align: center;
    background-color: transparent;
    z-index: 4;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-2 {
    text-align: center;
    background-color: #366aff;
    margin-left: -20px;
    z-index: 2;

    .ghbc-text {
      color: white;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: #6990ff;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-3 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-4 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .triangle-outer-blue {
    z-index: 1;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #366aff;
    }
  }

  .triangle-outer-gray {
    z-index: 4;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #f2f6fc;
    }
  }
}

.gac-header-b3 {
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f6fc;
  overflow: hidden;

  .gac-header-b-cell {
    height: 42px;
    line-height: 42px;
    z-index: 3;
    flex: 1;
    background-color: #366aff;
  }

  .gac-header-b-cell-1 {
    z-index: 2;
    text-align: center;
    background-color: transparent;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-2 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-3 {
    background-color: #366aff;
    text-align: center;
    margin-left: -20px;

    .ghbc-text {
      color: white;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: #6990ff;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-4 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .triangle-outer-blue {
    z-index: 1;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #366aff;
    }
  }

  .triangle-outer-gray {
    z-index: 4;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #f2f6fc;
    }
  }
}

.gac-header-b4 {
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f6fc;
  overflow: hidden;

  .gac-header-b-cell {
    height: 42px;
    line-height: 42px;
    flex: 1;
    background-color: #366aff;
  }

  .gac-header-b-cell-1 {
    z-index: 2;
    text-align: center;
    background-color: transparent;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-2 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-3 {
    background-color: transparent;
    text-align: center;

    .ghbc-text {
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .gac-header-b-cell-4 {
    text-align: center;
    background-color: #366aff;
    margin-left: -20px;
    z-index: 2;

    .ghbc-text {
      color: white;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 6px;
        background: #6990ff;
        border-radius: 50%;
      }
    }
  }

  .triangle-outer-blue {
    z-index: 1;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #366aff;
    }
  }

  .triangle-outer-gray {
    z-index: 4;
    margin-left: -14px;
    height: 34px;
    width: 34px;
    background-color: white;
    transform: rotate(45deg);
    display: flex;
    align-items: center;

    .triangle {
      margin-top: 4px;
      display: block;
      width: 30px;
      height: 30px;
      // border: solid 10px white;
      background-color: #f2f6fc;
    }
  }
}
</style>