<template>
	<div :style="{ width: 'calc(100vw - ' + clientWidth + 'px)' }" id="senior-search">
		<div class="advanced-search-container">
			<el-form label-position="top" :model="ruleForm">
				<el-row :gutter="60">
					<el-col :span="12">
						<!-- <el-form-item label="组织架构"> -->
							<!-- <el-cascader
								ref="myCascader"
								v-model="ruleForm.dept"
								style="width:312px"
								:options="deptLists"
								:props="props"
								collapse-tags
								clearable></el-cascader> -->
								<!-- <div class="staff-input" @click="openMemberTree">
									<span class="placeholder-text" v-if="departmentIds.length === 0">请选择</span>
									<div v-else class="client-value">
										<div v-for="item in departmentIds" :key="item.id" class="item">
											{{item.name}}
										</div>
									</div>
								</div> -->
						<!-- </el-form-item>  -->
					</el-col>
					<el-col :span="12">
						<el-form-item :label="$t('upDownManage.seniorSearch.status')">
							<el-radio-group v-model="ruleForm.status">
								<el-radio :label="null">{{$t('upDownManage.seniorSearch.all')}}</el-radio>
								<el-radio :label="true">{{$t('upDownManage.seniorSearch.normal')}}</el-radio>
								<el-radio :label="false">{{$t('upDownManage.seniorSearch.disabled')}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item :label="$t('upDownManage.seniorSearch.activationStatus')">
							<el-radio-group v-model="ruleForm.activateStatus">
								<el-radio :label="null">{{$t('upDownManage.seniorSearch.all')}}</el-radio>
								<el-radio :label="0">{{$t('upDownManage.seniorSearch.notActive')}}</el-radio>
								<el-radio :label="1">{{$t('upDownManage.seniorSearch.activationSuccess')}}</el-radio>
								<el-radio :label="2">{{$t('upDownManage.seniorSearch.activationFail')}}</el-radio>
							</el-radio-group>
						</el-form-item>
						
					</el-col>
				</el-row>
				<el-row :gutter="60">
					<el-col :span="12">
						<el-form-item :label="$t('upDownManage.seniorSearch.birthday')">
							<el-date-picker
								style="width:312px"
								v-model="ruleForm.birthdayRange"
								type="daterange"
								:range-separator="$t('upDownManage.seniorSearch.range')"
								:start-placeholder="$t('upDownManage.seniorSearch.startDate')"
								:end-placeholder="$t('upDownManage.seniorSearch.endDate')">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item :label="$t('upDownManage.seniorSearch.staffIn')">
							<el-date-picker
								style="width:312px"
								v-model="ruleForm.staffInRange"
								type="daterange"
								:range-separator="$t('upDownManage.seniorSearch.range')"
								:start-placeholder="$t('upDownManage.seniorSearch.startDate')"
								:end-placeholder="$t('upDownManage.seniorSearch.endDate')">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="advanced-search-footer">
			<el-button  class="setButtonColor" @click="closeScreen">{{$t('common.cancel')}}</el-button>
			<el-button type="primary"  @click="screenCondition">{{$t('upDownManage.screen')}}</el-button>
		</div>
		<!-- <chooseDept :showPopup.sync="showPopup" :treeData="treeDataCopy.length === 0 ? treeData : treeDataCopy" @checkedDept="checkedDept"></chooseDept> -->
	</div>
</template>
<script>
import $ from "jquery";
// import chooseDept from './chooseDept.vue';
export default {
	components:{
		// chooseDept
	},
	name: "seniorSearch",
	data() {
		return {
			clientWidth: 700,
			ruleForm:{
				dept:[],
				status:null, //false禁用  true启用
				birthdayRange:[],
				staffInRange:[],
				activateStatus:null
			},
			props: { 
				multiple: true,
				value:'id',
				label:'name',
				children:'children'
				
			},
			cascaderKeys:0,
			deptLists:[],
			showPopup:false,
			// departmentIds:[],
			treeDataCopy:[]
		}
	},
	props:{
		// deptList:{
		// 	type:Array,
		// 	default(){
		// 		return[]
		// 	}
		// },
		checkedTreeItem:{
			type:Object,
			default(){
				return{}
			}
		},
	},
	watch:{
		// departmentIds:{
		// 	handler(n,o){
		// 		if(n.length === 0){
		// 			this.treeData = this.treeDataCopy = [];
		// 		}
		// 	},
		// 	deep:true,
		// 	immediate:true
		// }
	},
	
	methods: {
		resetSeniorSearch(item,type){
			if(type === 'all'){
				// this.departmentIds = [];
				this.ruleForm.status = null;
				this.ruleForm.birthdayRange = [];
				this.ruleForm.staffInRange = [];
				this.ruleForm.activateStatus = null;
				// this.clearChecked(this.treeData);
			} else {
				// if(item.key === 'departmentIds'){
				// 	this.departmentIds = [];
				// 	this.clearChecked(this.treeData);
				// } else
				 if(item.key === 'status'){
					this.ruleForm.status = null;
				} else if(item.key === 'birthdayRange'){
					this.ruleForm.birthdayRange = [];
				} else if(item.key === 'staffInRange'){
					this.ruleForm.staffInRange = [];
				} else if(item.key === 'activateStatus'){
					this.ruleForm.activateStatus = null;
				}
			}
		},
		// clearChecked(treeData){
		// 	treeData.forEach(item => {
		// 		item.checked = false;
		// 		if (item.children && item.children.length > 0) {
		// 			this.clearChecked(item.children)
		// 		}
		// 		return;
		// 	})
		// },
		// 关闭筛选
		closeScreen() {
			$('#senior-search').slideUp(300)
		},
		// 筛选
		screenCondition() {
			let obj = {
				birthdayRange:this.ruleForm.birthdayRange && this.ruleForm.birthdayRange.length > 0 ? [this.ruleForm.birthdayRange[0].getTime(),this.ruleForm.birthdayRange[1].getTime()] : [],
				staffInRange:this.ruleForm.staffInRange && this.ruleForm.staffInRange.length > 0 ? [this.ruleForm.staffInRange[0].getTime(),this.ruleForm.staffInRange[1].getTime()] : [],
				status:this.ruleForm.status,
				activateStatus:this.ruleForm.activateStatus
			}
			// if((this.ruleForm.activateStatus && this.ruleForm.activateStatus > -1) || this.ruleForm.activateStatus === 0){
			// 	obj.activateStatus = this.ruleForm.activateStatus
			// } else {

			// }
			this.$emit('screenCondition', obj)
		},
		checkedDept(list,treeData){
			console.log(treeData);
			this.showPopup = false;
		}
	}
	}
</script>
<style scoped lang="less">
	#senior-search {
		z-index: 999;
		position: absolute;
		width: calc(100vw - 700px);
		height: 300px;
		overflow-y: hidden;
		overflow-x: hidden;
		/*min-width: 560px;*/
		// min-width: 446px;
		padding: 48px 30px 0 30px;
		display: none;
		background: #ffffff;
		box-shadow: 0px 4px 9px 0px rgba(57, 63, 79, 0.21);
		border-radius: 5px;
		top: 70px;
		left: 10px;
		.advanced-search-container {
			height: 300px;
			// overflow-y: scroll;
			overflow-x: hidden;
			color: #616161;
			.checkbox-bg-color {
				background: #fbfcfd;
				border-radius: 5px;
				width: 88%;
				min-height: 40px;
				display: flex;
				align-items: center;
				padding-left: 12px;
			}
			.field-list {
				display: flex;
				flex-wrap: wrap;
			}
			.field-item-class:nth-child(even) {
				.field-name {
					padding-left: 30px;
					line-height: 20px;
				}
				.el-cascader {
					margin-left: 30px;
				}
				.el-select {
					margin-left: 30px;
				}
				.el-date-editor {
					margin-left: 30px;
				}
				.checkbox-bg-color {
					margin-left: 30px;
				}
			}
				.field-name {
					padding-bottom: 8px;
					line-height: 20px;
				}
				.field-item-class {
					padding-bottom: 20px;
					width: 50%;
					.field-name {
						padding-right: 30px;
					}
					.field-value {
						padding-right: 30px;
					}
				}
			.field-item-class-c {
				width: 100%;
				padding-bottom: 20px;
			}
		}
		.advanced-search-footer {
			position: absolute;
			bottom: 0;
			height: 66px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: calc(100vw - 700px);
		}
		.staff-input{
			// height: 36px;
			width:320px;
			border-radius: 5px;
			border:1px solid #E0E6F7;
			cursor: pointer;
			display: flex;
			align-items: center;
			.placeholder-text{
				padding-left: 15px;
				color: #A9B3C6;
			}
			.client-value{
				padding: 5px;
				color: #606266;
				display: flex;
				// justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				line-height: 30px;
				width: 100%;
				.item{
					border:1px solid hsl(240, 5%, 92%);
					height: 24px;
					line-height: 24px;
					padding: 0 8px;
					line-height: 22px;
					position: relative;
					background-color: #f4f4f5;
					color: #909399;
					border-radius: 3px;
					font-size: 12px;
					margin: 2px 0 2px 6px;
					.el-icon-close{
						display: inline-block;
						border-radius: 50%;
						text-align: center;
						position: relative;
						cursor: pointer;
						font-size: 12px;
						height: 14px;
						width: 14px;
						line-height: 14px;
						background-color: #c0c4cc;
						color: #909399;
						cursor: pointer;
					}
				}
				

			}
		}
		/deep/ .el-form-item__label {
			line-height: 20px;
			color: #616161;
		}
		/deep/ .el-radio__input.is-checked + .el-radio__label {
			color: #366AFF;
		}

		/deep/ .el-radio__input.is-checked .el-radio__inner {
			background-color: #366AFF;
			border-color: #366AFF;
		}
		.el-button--primary{
			background-color: #366AFF;
		}
	}
</style>