<template>
    <div class="gr-left-content-top">
        <div v-if="modulesList.indexOf('OPEN') !== -1" class="gr-lct-comp-acc-sys" style="margin-top: 0px">
            <div class="gr-lct-comp-acc-sys-lf">
                <span class="gr-lct-comp-acc-sys-s color-b"></span>
                <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-b"
                    style="fontSize:18px">{{ $t('common.intelligentBot') }}</span>
                <span class="gr-lct-comp-acc-sys-name color-b">
                    {{ isE() ? 'eBot' : 'AskBot' }}
                    <span style="fontSize:18px">{{ $t('common.bot') }}</span>
                </span>
            </div>
            <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.OPEN.config.moduleVersion.versionType}`">
                <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                    <span class="gr-lct-comp-vertion-s-icon-p
                  iconfont guoran-diamond"></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">{{ activeVersionObj.OPEN.config.moduleVersion.name }}</span>
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('OPEN') !== -1 && isShowVersionDuration()" class="gr-lct-comp-vertion">
            <div :class="[
                'gr-lct-comp-vertion-s',
                `gr-lct-comp-vertion-s-${activeVersionObj.OPEN.config.moduleVersion.versionType}`,
            ]" v-if="false">
                <span class="
                        gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-o
                    ">{{ $t('companyInfo.systemVersion') }}</span><span @click="goToVersion(activeVersionObj)"
                    class="gr-lct-comp-vertion-s-icon">
                    <span class="
                            gr-lct-comp-vertion-s-icon-p
                            iconfont
                            guoran-diamond
                        "></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">{{ activeVersionObj.OPEN.config.moduleVersion.name }}
                        <i v-if="false">></i></span>
                </span>
            </div>
            <div v-if="modulesList.indexOf('OPEN') !== -1" class="gr-lct-comp-vertion-t version-time">
                <span class="
                        gr-lct-comp-vertion-t-name gr-lct-comp-vertion-t-name-o
                    ">{{ $t('companyInfo.versionDuration') }}</span><span v-if="JSON.parse(userCompany).status == 2"
                    class="gr-lct-comp-vertion-t-p">
                    <el-progress v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" type="circle" width="20" stroke-width="3"
                        :show-text="false" :percentage="percentageFormat(
                            activeVersionObj.OPEN.expiresIn,
                            activeVersionObj.OPEN.config.expiresIn
                        )
                            " :color="colors"></el-progress>
                    <template
                        v-if="Number(activeVersionObj.OPEN.expiresIn) >= 0 || activeVersionObj.OPEN.expiresIn === '无限制'">
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.surplus') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            activeVersionObj.OPEN.expiresIn }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.overdue') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn == '无限制'" class="gr-lct-comp-vertion-t-p-t">{{
                            activeVersionObj.OPEN.expiresIn }}</span>
                    </template>
                    <template v-else>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.expired') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            openBeOverdueTime }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.day') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" @click="nowRenew"
                            class="now-renew">{{ $t('companyInfo.renewImmediately') }}</span>
                    </template>
                </span>
                <span v-if="JSON.parse(userCompany).status != 2" class="gr-lct-comp-vertion-t-p">{{
                    statusText[JSON.parse(userCompany).status] }}</span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('OPEN') !== -1" class="gr-lct-comp-progress CompanyIsOld">
            <div class=" gr-lct-comp-progress-cell gr-lct-comp-progress-cel-m">
                <div class="gr-lct-comp-progress-c-t">
                    <span style="color: #458fff"
                        class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.Activated/Purchased') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="#458FFF" :percentage="percentageFormat(
                            botLimitData.activatePeopleNum,
                            botLimitData.buyUseBotPeopleNum
                        )
                            " :show-text="false" status="success"></el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ botLimitData.activatePeopleNum }} /
                        <span v-if="botLimitData.buyUseBotPeopleNum == '∞'" class="no-limit">
                            {{ $t('companyInfo.unlimited') }}
                        </span><span v-else>{{
                            botLimitData.buyUseBotPeopleNum
                        }}</span> {{ botLimitData.buyUseBotPeopleNum == '∞' ? '' : $t('companyInfo.person') }}</span>
                </div>
            </div>
            <template v-if="JSON.parse(userCompany).promoteTime && JSON.parse(userCompany).promoteTime > 0">
                <!-- 推广期内 -->
                <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-goto margin-l-12"
                    v-if="(JSON.parse(userCompany).promoteSurplusDay > -1 && botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum) ||
                        (JSON.parse(userCompany).promoteSurplusDay > -1 && botLimitData.activatePeopleNum <= botLimitData.buyUseBotPeopleNum)">
                    <span class="gr-lct-limit-des center"
                        v-if="botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum">{{ $t('companyInfo.progressTip') }}{{ JSON.parse(userCompany).promoteSurplusDay }}{{ $t('companyInfo.expiredTip2') }}
                        <span @click="goBuyMore"
                            class="gr-lct-limit-btn">{{ $t('companyInfo.toAdditionalPurchase') }}</span></span>
                    <span class="gr-lct-limit-des" v-else>
                        <span>{{ $t('companyInfo.progressTip') }}{{ JSON.parse(userCompany).promoteSurplusDay }}{{ $t('companyInfo.day') }}</span>
                        <span @click="goBuyMore" class="gr-lct-limit-btn">{{ $t('companyInfo.toAdditionalPurchase') }}</span>
                    </span>
                </div>
            </template>
            <!-- 推广期外 -->
            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-goto margin-l-12"
                v-if="(JSON.parse(userCompany).promoteSurplusDay < 0 && botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum) ||
                    (JSON.parse(userCompany).promoteSurplusDay < 0 && botLimitData.activatePeopleNum >= botLimitData.buyUseBotPeopleNum * 0.8)">
                <span class="gr-lct-limit-des"
                    v-if="botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum">{{ $t('companyInfo.progressTip2') }}<span
                        @click="goBuyMore" class="gr-lct-limit-btn">{{ $t('companyInfo.toAdditionalPurchase') }}</span></span>
                <span class="gr-lct-limit-des"
                    v-else-if="botLimitData.activatePeopleNum >= (botLimitData.buyUseBotPeopleNum * 0.8)">{{ $t('companyInfo.progressTip3') }}<span
                        @click="goBuyMore" class="gr-lct-limit-btn">{{ $t('companyInfo.toAdditionalPurchase') }}</span></span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('OPEN') !== -1" class="gr-lct-comp-progress">
            <!-- v-if="botLimitData.buyUseBotPeopleNum !== '∞' && (botLimitData.buyUseBotPeopleNum - botLimitData.canUseBotPeopleNum) <= 10" -->

            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cel-m">
                <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-c-t">
                    <span style="color: #458fff" class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.buildRobots') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="#458FFF" :percentage="percentageFormat(
                            botLimitData.createBotNum,
                            botLimitData.canCreateNewBotNum
                        )
                            " :show-text="false" status="success"></el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ botLimitData.createBotNum }} /
                        <span v-if="botLimitData.canCreateNewBotNum == '∞'" class="no-limit">
                            <!-- {{ botLimitData.canCreateNewBotNum }} -->
                            {{ $t('companyInfo.unlimited') }}
                        </span><span v-else>{{
                            botLimitData.canCreateNewBotNum
                        }}</span> {{ botLimitData.canCreateNewBotNum == '∞' ? '' : $t('companyInfo.individual') }}</span>
                </div>
            </div>
            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cel-m margin-l-12">
                <div class="gr-lct-comp-progress-c-t">
                    <span style="color: #458fff"
                        class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.numberOfPossibleIntentions') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="#458FFF" :percentage="percentageFormat(
                            botLimitData.createIntentNum,
                            botLimitData.canCreateNewIntentNum
                        )
                            " :show-text="false" status="success"></el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ botLimitData.createIntentNum }} /
                        <span v-if="botLimitData.canCreateNewIntentNum == '∞'" class="no-limit">
                            <!-- {{ botLimitData.canCreateNewIntentNum }} -->
                            {{ $t('companyInfo.unlimited') }}
                        </span><span v-else>{{
                            botLimitData.canCreateNewIntentNum
                        }} </span> {{ botLimitData.canCreateNewIntentNum == '∞' ? '' :
    $t('companyInfo.individual') }}</span>
                </div>
            </div>
        </div>
        <span class="sys-de-line" v-if="modulesList.indexOf('WORKORDER') !== -1"></span>
        <div v-if="modulesList.indexOf('WORKORDER') !== -1" class="gr-lct-comp-acc-sys">
            <div class="gr-lct-comp-acc-sys-lf">
                <span class="gr-lct-comp-acc-sys-s color-p"></span>
                <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-p"
                    style="fontSize:18px">{{ $t('common.workOrder') }}</span>
                <span class="gr-lct-comp-acc-sys-name color-p" v-else>

                    {{ isE() ? 'eService' : 'AskService' }}
                    <span style="fontSize:18px">{{ $t('common.workOrder') }}</span>
                </span>
            </div>
            <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.WORKORDER.config.moduleVersion.versionType}`">
                <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                    <span class="gr-lct-comp-vertion-s-icon-p
                  iconfont guoran-diamond"></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">{{ activeVersionObj.WORKORDER.config.moduleVersion.name
                    }}</span>
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('WORKORDER') !== -1 && isShowVersionDuration()" class="gr-lct-comp-vertion">
            <div :class="[
                'gr-lct-comp-vertion-s',
                `gr-lct-comp-vertion-s-${activeVersionObj.WORKORDER.config.moduleVersion.versionType}`,
            ]" v-if="false">
                <span class="
                        gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-w
                    ">{{ $t('companyInfo.systemVersion') }}</span><span @click="goToVersion(activeVersionObj)"
                    class="gr-lct-comp-vertion-s-icon">
                    <span class="
                            gr-lct-comp-vertion-s-icon-p
                            iconfont
                            guoran-diamond
                        "></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">{{
                        activeVersionObj.WORKORDER.config.moduleVersion.name
                    }}
                        <i v-if="false">></i></span>
                </span>
            </div>
            <div class="gr-lct-comp-vertion-t">
                <span class="
                        gr-lct-comp-vertion-t-name gr-lct-comp-vertion-t-name-w
                    ">{{ $t('companyInfo.versionDuration') }}</span><span v-if="JSON.parse(userCompany).status == 2"
                    class="gr-lct-comp-vertion-t-p">
                    <el-progress type="circle" v-if="activeVersionObj.WORKORDER.expiresIn !== '无限制'" width="20"
                        stroke-width="3" :show-text="false" :percentage="percentageFormat(
                            activeVersionObj.WORKORDER.expiresIn,
                            activeVersionObj.WORKORDER.config.expiresIn
                        )
                            " :color="colors"></el-progress>
                    <template
                        v-if="Number(activeVersionObj.WORKORDER.expiresIn) >= 0 || activeVersionObj.WORKORDER.expiresIn === '无限制'">
                        <span v-if="activeVersionObj.WORKORDER.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.surplus') }}</span>
                        <span v-if="activeVersionObj.WORKORDER.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            activeVersionObj.WORKORDER.expiresIn }}</span>
                        <span v-if="activeVersionObj.WORKORDER.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.overdue') }}</span>
                        <span v-if="activeVersionObj.WORKORDER.expiresIn == '无限制'" class="gr-lct-comp-vertion-t-p-t">{{
                            activeVersionObj.WORKORDER.expiresIn }}</span>
                    </template>
                    <template v-else>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.expired') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            workerBeOverdueTime }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.day') }}</span>
                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" @click="nowRenew"
                            class="now-renew">{{ $t('companyInfo.renewImmediately') }}</span>
                    </template>
                </span>
                <span v-if="JSON.parse(userCompany).status != 2" class="gr-lct-comp-vertion-t-p">
                    {{ statusText[JSON.parse(userCompany).status] }}
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('WORKORDER') !== -1" class="gr-lct-comp-progress">
            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                <div class="gr-lct-comp-progress-c-t">
                    <span style="color: #a39af5"
                        class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.orderAccount') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="#877EF2" :percentage="percentageFormat(
                            JSON.parse(userCompany)
                                .canUseWorkOrderUserNum,
                            JSON.parse(userCompany)
                                .canUseWorkOrderUserNumLimit
                        )
                            " :show-text="false" status="success"></el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ JSON.parse(userCompany).canUseWorkOrderUserNum }} /
                        <span v-if="JSON.parse(userCompany)
                                .canUseWorkOrderUserNumLimit == null ||
                            JSON.parse(userCompany)
                                .canUseWorkOrderUserNumLimit == -1
                            " class="no-limit">
                            {{ $t('companyInfo.unlimited') }}
                            <!-- ∞ -->
                        </span><span v-else>{{
                            JSON.parse(userCompany).canUseWorkOrderUserNumLimit
                        }}</span> {{ JSON.parse(userCompany).canUseWorkOrderUserNumLimit == null
    || JSON.parse(userCompany).canUseWorkOrderUserNumLimit == -1 ? '' :
    $t('companyInfo.individual') }}</span>
                </div>
            </div>
        </div>
        <template v-if="modulesList.indexOf('IM') !== -1">
            <span class="sys-de-line"></span>
            <div v-if="modulesList.indexOf('IM') !== -1" class="gr-lct-comp-acc-sys">
                <div class="gr-lct-comp-acc-sys-lf">
                    <span class="gr-lct-comp-acc-sys-s color-i"></span>
                    <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-i"
                        style="fontSize:18px">{{ $t('common.IM') }}</span>
                    <span class="gr-lct-comp-acc-sys-name color-i" v-else>

                        {{ isE() ? 'eChat' : 'AskChat' }}
                        <span style="fontSize:18px">{{ $t('common.IM') }}</span>
                    </span>
                </div>
                <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.IM.config.moduleVersion.versionType}`">
                    <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                        <span class="gr-lct-comp-vertion-s-icon-p
                    iconfont guoran-diamond"></span>
                        <span class="gr-lct-comp-vertion-s-icon-t">{{ activeVersionObj.IM.config.moduleVersion.name
                        }}</span>
                    </span>
                </div>
            </div>
            <div v-if="modulesList.indexOf('IM') !== -1 && isShowVersionDuration()" class="gr-lct-comp-vertion">
                <div :class="[
                    'gr-lct-comp-vertion-s',
                    `gr-lct-comp-vertion-s-${activeVersionObj.IM.config.moduleVersion.versionType}`,
                ]" v-if="false">
                    <span class="
                        gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-i
                    ">系统版本</span><span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                        <span class="
                            gr-lct-comp-vertion-s-icon-p
                            iconfont
                            guoran-diamond
                        "></span>
                        <span class="gr-lct-comp-vertion-s-icon-t">{{
                            activeVersionObj.IM.config.moduleVersion.name
                        }}
                            <i v-if="false">></i></span>
                    </span>
                </div>
                <div class="gr-lct-comp-vertion-t">
                    <span class="
                        gr-lct-comp-vertion-t-name gr-lct-comp-vertion-t-name-i
                    ">{{ $t('companyInfo.versionDuration') }}</span><span v-if="JSON.parse(userCompany).status == 2"
                        class="gr-lct-comp-vertion-t-p">
                        <el-progress type="circle" v-if="activeVersionObj.IM.expiresIn !== '无限制'" width="20"
                            stroke-width="3" :show-text="false" :percentage="percentageFormat(
                                activeVersionObj.IM.expiresIn,
                                activeVersionObj.IM.config.expiresIn
                            )
                                " :color="colors"></el-progress>
                        <template
                            v-if="Number(activeVersionObj.IM.expiresIn) >= 0 || activeVersionObj.IM.expiresIn === '无限制'">
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.surplus') }}</span>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                                activeVersionObj.IM.expiresIn }}</span>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.overdue') }}</span>
                            <span v-if="activeVersionObj.IM.expiresIn == '无限制'" class="gr-lct-comp-vertion-t-p-t">{{
                                activeVersionObj.IM.expiresIn }}</span>
                        </template>
                        <template v-else>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{$t('companyInfo.expired')}}</span>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                                IMBeOverdueTime }}</span>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{$t('companyInfo.day')}}</span>
                            <span v-if="activeVersionObj.IM.expiresIn !== '无限制'" @click="nowRenew"
                                class="now-renew">{{$t('companyInfo.renewImmediately')}}</span>
                        </template>
                    </span>
                    <span v-if="JSON.parse(userCompany).status != 2" class="gr-lct-comp-vertion-t-p">
                        {{ statusText[JSON.parse(userCompany).status] }}
                    </span>
                </div>
            </div>
            <div v-if="modulesList.indexOf('IM') !== -1" class="gr-lct-comp-progress">
                <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                    <div class="gr-lct-comp-progress-c-t">
                        <span style="color: #a39af5"
                            class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.IMAccount') }}</span>
                        <div class="gr-lct-comp-progress-c-c">
                            <el-progress color="#877EF2" :percentage="percentageFormat(
                                JSON.parse(userCompany)
                                    .canUseCustomerServiceNum,
                                JSON.parse(userCompany)
                                    .canUseCustomerNumLimit
                            )
                                " :show-text="false" status="success"></el-progress>
                        </div>
                        <span class="gr-lct-comp-progress-c-t-n">{{ JSON.parse(userCompany).canUseCustomerServiceNum }} /
                            <span v-if="JSON.parse(userCompany)
                                    .canUseCustomerNumLimit == null ||
                                JSON.parse(userCompany)
                                    .canUseCustomerNumLimit == -1
                                " class="no-limit">
                                {{ $t('companyInfo.unlimited') }}
                                <!-- ∞ -->
                            </span><span v-else>{{
                                JSON.parse(userCompany).canUseCustomerNumLimit
                            }}</span> {{ JSON.parse(userCompany).canUseCustomerNumLimit == null ||
    JSON.parse(userCompany).canUseCustomerNumLimit == -1 ? '' :
    $t('companyInfo.individual') }}</span>
                    </div>
                </div>
            </div>
        </template>

        <!-- ------开始--- -->
        <span class="sys-de-line" v-if="modulesList.indexOf('KNOWLEDGE') !== -1"></span>
        <div v-if="modulesList.indexOf('KNOWLEDGE') !== -1" class="gr-lct-comp-acc-sys">
            <div class="gr-lct-comp-acc-sys-lf">
                <span class="gr-lct-comp-acc-sys-s color-b"></span>
                <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-b"
                    style="fontSize:18px">{{ $t('common.knowledge') }}</span>
                <span class="gr-lct-comp-acc-sys-name color-b" v-else>
                    {{ isE() ? 'eKMS' : 'AskKMS' }}
                    <span style="fontSize:18px">{{ $t('common.knowledge') }}</span>
                </span>
            </div>
            <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.KNOWLEDGE.config.moduleVersion.versionType}`">
                <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                    <span class="gr-lct-comp-vertion-s-icon-p
                    iconfont guoran-diamond"></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">基础版</span>
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('KNOWLEDGE') !== -1 && isShowVersionDuration()" class="gr-lct-comp-vertion">
            <div v-if="false"
                :class="['gr-lct-comp-vertion-s', `gr-lct-comp-vertion-s-${activeVersionObj.KNOWLEDGE.config.moduleVersion.versionType}`]">
                <span
                    class="gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-o">{{ $t('companyInfo.systemVersion') }}</span><span
                    @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                    <span class="gr-lct-comp-vertion-s-icon-p iconfont guoran-diamond"></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">
                        基础版
                        <!-- {{activeVersionObj.WORKORDER.config.moduleVersion.name}} -->
                    </span>
                </span>
            </div>
            <div class="gr-lct-comp-vertion-t">
                <span
                    class="gr-lct-comp-vertion-t-name gr-lct-comp-vertion-s-name-o">{{ $t('companyInfo.versionDuration') }}</span>
                <span v-if="JSON.parse(userCompany).status == 2" class="gr-lct-comp-vertion-t-p">
                    <el-progress type="circle" v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'" width="20"
                        stroke-width="3" :show-text="false"
                        :percentage="percentageFormat(activeVersionObj.KNOWLEDGE.expiresIn, activeVersionObj.KNOWLEDGE.config.expiresIn)"
                        :color="colors">
                    </el-progress>
                    <template
                        v-if="Number(activeVersionObj.KNOWLEDGE.expiresIn) >= 0 || activeVersionObj.KNOWLEDGE.expiresIn === '无限制'">
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.surplus') }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            activeVersionObj.KNOWLEDGE.expiresIn }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.overdue') }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn == '无限制'" class="gr-lct-comp-vertion-t-p-t">{{
                            activeVersionObj.KNOWLEDGE.expiresIn }}</span>
                    </template>
                    <template v-else>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.expired') }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                            knowledgeBeOverdueTime }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'"
                            class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.day') }}</span>
                        <span v-if="activeVersionObj.KNOWLEDGE.expiresIn !== '无限制'" @click="nowRenew"
                            class="now-renew">{{ $t('companyInfo.renewImmediately') }}</span>
                    </template>
                </span>
                <span v-if="JSON.parse(userCompany).status != 2" class="gr-lct-comp-vertion-t-p">
                    {{ statusText[JSON.parse(userCompany).status] }}
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('KNOWLEDGE') !== -1" class="gr-lct-comp-progress">
            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                <div class="gr-lct-comp-progress-c-t">
                    <span style="color: rgb(69, 143, 255);"
                        class="gr-lct-comp-progress-c-t-p knowledge-text">{{ $t('companyInfo.storageCapacity') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="rgb(69, 143, 255)"
                            :percentage="percentageFormat(knowdgeData.usedCapacity, knowdgeData.totalCapacity)"
                            :show-text="false" status="success">
                        </el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ knowdgeData.usedCapacityCopy }} /
                        <span>{{ knowdgeData.totalCapacityCopy }}</span></span>
                    <!-- <div @click="goBuy('capacity')" class="buy-text">扩容</div> -->
                </div>
            </div>
        </div>
        <!-- ----结束----- -->

        <template v-if="modulesList.indexOf('ASKAI') !== -1">
            <span class="sys-de-line"></span>
            <div v-if="modulesList.indexOf('ASKAI') !== -1" class="gr-lct-comp-acc-sys">
                <div class="gr-lct-comp-acc-sys-lf">
                    <span class="gr-lct-comp-acc-sys-s color-ai"></span>
                    <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-ai"
                        style="fontSize:18px">{{ $t('common.modelBot') }}</span>
                    <span class="gr-lct-comp-acc-sys-name color-ai" v-else>
                        {{ isE() ? 'eAI' : 'AskAI' }}
                        <span style="fontSize:18px">{{ $t('common.modelBot') }}</span>
                    </span>
                </div>
                <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.ASKAI.config.moduleVersion.versionType}`">
                    <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                        <span class="gr-lct-comp-vertion-s-icon-p
                    iconfont guoran-diamond"></span>
                        <span class="gr-lct-comp-vertion-s-icon-t">Azure ChatGPT {{ $t('companyInfo.edition') }}</span>
                    </span>
                </div>
            </div>
            <div v-if="modulesList.indexOf('ASKAI') !== -1 && isShowVersionDuration()" class="gr-lct-comp-vertion">
                <div v-if="false"
                    :class="['gr-lct-comp-vertion-s', `gr-lct-comp-vertion-s-${activeVersionObj.ASKAI.config.moduleVersion.versionType}`]">
                    <span class="gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-o">系统版本</span><span
                        @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                        <span class="gr-lct-comp-vertion-s-icon-p iconfont guoran-diamond"></span>
                        <span class="gr-lct-comp-vertion-s-icon-t">
                            Azure ChatGPT版
                            <!-- {{activeVersionObj.WORKORDER.config.moduleVersion.name}} -->
                        </span>
                    </span>
                </div>
                <div class="gr-lct-comp-vertion-t">
                    <span
                        class="gr-lct-comp-vertion-t-name gr-lct-comp-vertion-s-name-o">{{ $t('companyInfo.versionDuration') }}</span>
                    <span v-if="JSON.parse(userCompany).status == 2" class="gr-lct-comp-vertion-t-p">
                        <el-progress type="circle" v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'" width="20"
                            stroke-width="3" :show-text="false"
                            :percentage="percentageFormat(activeVersionObj.ASKAI.expiresIn, activeVersionObj.ASKAI.config.expiresIn)"
                            :color="colors">
                        </el-progress>
                        <template
                            v-if="Number(activeVersionObj.ASKAI.expiresIn) >= 0 || activeVersionObj.ASKAI.expiresIn === '无限制'">
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.surplus') }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                                activeVersionObj.ASKAI.expiresIn }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.overdue') }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn == '无限制'" class="gr-lct-comp-vertion-t-p-t">{{
                                activeVersionObj.ASKAI.expiresIn }}</span>
                        </template>
                        <template v-else>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.expired') }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{
                                askAIBeOverdueTime }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'"
                                class="gr-lct-comp-vertion-t-p-t">{{ $t('companyInfo.day') }}</span>
                            <span v-if="activeVersionObj.ASKAI.expiresIn !== '无限制'" @click="nowRenew"
                                class="now-renew">{{ $t('companyInfo.renewImmediately') }}</span>
                        </template>
                    </span>
                    <span v-if="JSON.parse(userCompany).status != 2" class="gr-lct-comp-vertion-t-p">
                        {{ statusText[JSON.parse(userCompany).status] }}
                    </span>
                </div>
            </div>
            <div v-if="modulesList.indexOf('ASKAI') !== -1" class="gr-lct-comp-progress">
                <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                    <div class="gr-lct-comp-progress-c-t">
                        <span style="color: rgb(69, 143, 255);"
                            class="gr-lct-comp-progress-c-t-p knowledge-text">{{ $t('companyInfo.activatePeople') }}</span>
                        <div class="gr-lct-comp-progress-c-c">
                            <el-progress color="rgb(69, 143, 255)"
                                :percentage="percentageFormat(knowdgeData.activatePeople, knowdgeData.buyTotalPeople)"
                                :show-text="false" status="success">
                            </el-progress>
                        </div>
                        <span class="gr-lct-comp-progress-c-t-n">{{ knowdgeData.activatePeople }} /
                            <span>{{ knowdgeData.buyTotalPeople }} {{ $t('companyInfo.person') }}</span></span>
                        <div @click="goBuyMore" class="buy-text">{{ $t('companyInfo.numberOfBuyers') }}</div>
                    </div>
                </div>
            </div>
            <div v-if="modulesList.indexOf('ASKAI') !== -1" class="gr-lct-comp-progress">
                <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                    <div class="gr-lct-comp-progress-c-t">
                        <span style="color: rgb(69, 143, 255);"
                            class="gr-lct-comp-progress-c-t-p knowledge-text">{{ $t('companyInfo.intelligentQ&AAndWritingFrequency') }}</span>
                        <div class="gr-lct-comp-progress-c-c">
                            <el-progress color="rgb(69, 143, 255)"
                                :percentage="percentageFormat(knowdgeData.usedFlow, knowdgeData.totalFlow)"
                                :show-text="false" status="success">
                            </el-progress>
                        </div>
                        <span class="gr-lct-comp-progress-c-t-n">{{ knowdgeData.usedFlow }} / <span>{{ knowdgeData.totalFlow }}
                                {{ $t('companyInfo.second') }}</span></span>
                        <div @click="goBuy('flow')" class="buy-text">{{ $t('companyInfo.purchaseFrequency') }}</div>
                    </div>
                </div>
            </div>
        </template>
        <span class="sys-de-line" v-if="modulesList.indexOf('QUESTION') !== -1"></span>
        <div v-if="modulesList.indexOf('QUESTION') !== -1" class="gr-lct-comp-acc-sys">
            <div class="gr-lct-comp-acc-sys-lf">
                <span class="gr-lct-comp-acc-sys-s color-b"></span>
                <span v-if="uid == 20281 || uid == 117431" class="gr-lct-comp-acc-sys-name color-b"
                    style="fontSize:18px">{{ $t('common.question') }}</span>
                <span class="gr-lct-comp-acc-sys-name color-b" v-else>

                    {{ isE() ? 'eService' : 'AskQNR' }}
                    <span style="fontSize:18px">{{ $t('common.question') }}</span>
                </span>
            </div>
            <div :class="`gr-lct-comp-vertion-s-${activeVersionObj.QUESTION.config.moduleVersion.versionType}`">
                <span @click="goToVersion(activeVersionObj)" class="gr-lct-comp-vertion-s-icon">
                    <span class="gr-lct-comp-vertion-s-icon-p
                  iconfont guoran-diamond"></span>
                    <span class="gr-lct-comp-vertion-s-icon-t">{{ activeVersionObj.QUESTION.config.moduleVersion.name
                    }}</span>
                </span>
            </div>
        </div>
        <div v-if="modulesList.indexOf('QUESTION') !== -1" class="gr-lct-comp-progress">
            <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-b">
                <div class="gr-lct-comp-progress-c-t">
                    <span style="color: #366AFF"
                        class="gr-lct-comp-progress-c-t-p">{{ $t('companyInfo.questionnaire') }}</span>
                    <div class="gr-lct-comp-progress-c-c">
                        <el-progress color="#877EF2" :percentage="percentageFormat(
                            activeVersionObj.QUESTION.config.moduleVersion.questionnaireNum,
                            activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit
                        )
                            " :show-text="false" status="success"></el-progress>
                    </div>
                    <span class="gr-lct-comp-progress-c-t-n">{{ activeVersionObj.QUESTION.config.moduleVersion.questionnaireNum }} /
                        <span v-if="activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit == null ||
                                activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit == -1
                            " class="no-limit">
                            {{ $t('companyInfo.unlimited') }}
                            <!-- ∞ -->
                        </span><span v-else>{{
                            activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit
                        }}</span> {{ activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit == null
                        || activeVersionObj.QUESTION.config.moduleVersion.questionnaireNumLimit == -1 ? '' :
                        $t('companyInfo.individual') }}</span>
                </div>
            </div>
        </div>


        <popup @closeEvent="buyMoreVisible = false" v-if="buyMoreVisible" class="buy-more-popup">
            <div slot="popup-name" class="popup-name">{{ $t('companyInfo.additionalPurchase') }}</div>
            <div slot="popup-con">
                <div class="first-column">
                    <div class="buy-num">{{ $t('companyInfo.buyTotalPeople') }} : {{ knowdgeData.buyTotalPeople }}
                        {{ $t('companyInfo.person') }}</div>
                    <div> {{ $t('companyInfo.activatePeople') }} : {{ knowdgeData.activatePeople }} {{ $t('companyInfo.person') }}
                    </div>
                </div>
                <div class="second-column">
                    <template v-if="botLimitData.buyUseBotPeopleNum !== '∞'">
                        <!-- 推广期内 -->
                        <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-goto margin-l-12"
                            v-if="(JSON.parse(userCompany).promoteSurplusDay > -1 && botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum) ||
                                (JSON.parse(userCompany).promoteSurplusDay > -1 && botLimitData.activatePeopleNum <= botLimitData.buyUseBotPeopleNum)">
                            <span class="gr-lct-limit-des"
                                v-if="botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum">{{ $t('companyInfo.progressTip') }}{{ JSON.parse(userCompany).promoteSurplusDay }}{{ $t('companyInfo.expiredTip2') }}
                                <span @click="goBuyMore"
                                    class="gr-lct-limit-btn">{{ $t('companyInfo.toAdditionalPurchase') }}</span></span>
                            <span class="gr-lct-limit-des" v-else>
                                <span>{{ $t('companyInfo.progressTip') }}{{ JSON.parse(userCompany).promoteSurplusDay }}{{ $t('companyInfo.day') }}</span>
                            </span>
                        </div>
                    </template>
                    <!-- 推广期外 -->
                    <div class="gr-lct-comp-progress-cell gr-lct-comp-progress-cell-goto margin-l-12"
                        v-if="(JSON.parse(userCompany).promoteSurplusDay < 0 && botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum) ||
                            (JSON.parse(userCompany).promoteSurplusDay < 0 && botLimitData.activatePeopleNum >= botLimitData.buyUseBotPeopleNum * 0.8)">
                        <span class="gr-lct-limit-des"
                            v-if="botLimitData.activatePeopleNum > botLimitData.buyUseBotPeopleNum">{{ $t('companyInfo.progressTip2') }}</span>
                        <span class="gr-lct-limit-des"
                            v-else-if="botLimitData.activatePeopleNum >= (botLimitData.buyUseBotPeopleNum * 0.8)">{{ $t('companyInfo.progressTip3') }}</span>
                    </div>
                </div>
                <div class="buy-more-telphone">{{ $t('companyInfo.purchasePhone') }}</div>
            </div>
        </popup>
        <popup @closeEvent="promptVisible = false" v-if="promptVisible" class="buy-more-popup">
            <div slot="popup-name" class="popup-name">{{ $t('companyInfo.expiredTitle') }}</div>
            <div slot="popup-con">
                <div class="second-column">
                    <div>
                        {{ $t('companyInfo.expiredTip') }}{{ JSON.parse(userCompany).promoteSurplusDay }}{{ $t('companyInfo.expiredTip2') }}
                    </div>
                </div>
                <div class="buy-more-telphone">{{ $t('companyInfo.purchasePhone') }}</div>
            </div>
        </popup>
        <popup @closeEvent="nowRenewPopup = false" v-if="nowRenewPopup">
            <div slot="popup-name" class="popup-name">{{ $t('companyInfo.renewImmediately') }}</div>
            <div slot="popup-con">
                <div style="text-align:center;color:#9da5bf;">{{ $t('companyInfo.expiredPhone') }}</div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="nowRenewPopup = false" plain round>{{ $t('common.close') }}</el-button>
                <el-button type="primary" round v-clipboard:copy="'4001-789-800'" v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
                    {{ $t('companyInfo.copyPhone') }}
                </el-button>
            </div>
        </popup>
        <payPopup ref="payPopup" :payPopupVisable.sync="payPopupVisable" @paySuccess="paySuccess">
        </payPopup>
    </div>
</template>
<script>
import Popup from '../../../components/popup.vue'
import payPopup from '../../companyManage/capacityAndFlowManage/components/payPopup.vue';
export default {
    props: {
        userCompany: {
            type: String,
            default: "",
        },
        serviceExpired: {
            type: Boolean,
            default: true,
        },
        activeVersionObj: {
            type: Object,
            default: {
                OPEN: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                WORKORDER: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
            },
        },
        knowdgeData: {
            type: Object,
            default () {
                return {}
            },
        },
        modulesList: {
            type: Array,
            default () {
                return []
            },
        }
    },
    components: { Popup, payPopup },
    data () {
        return {
            acc: "—",
            rolePrivilegesList: [],
            userInfo: {
                realName: "",
                tel: "",
                email: "",
                username: "",
                roles: [""],
            },
            botLimitData: {
                canCreateBot: true,
                canCreateIntent: true,
                canCreateNewBotNum: -1,
                canCreateNewIntentNum: -1,
                createBotNum: 0,
                createIntentNum: 0,
                buyUseBotPeopleNum: -1,
                canUseBotPeopleNum: 0,
                totalPeopleNum: 0
            },
            statusText: ["未认证", "审核中", "已认证", "未通过"],
            buyMoreVisible: false,
            promptVisible: false,
            CompanyIsOld: true,
            openBeOverdueTime: 0,
            workerBeOverdueTime: 0,
            IMBeOverdueTime:0,
            knowledgeBeOverdueTime:0,
            askAIBeOverdueTime:0,
            nowRenewPopup: false,
            uid: '',
            payPopupVisable: false,
            // modulesList:[]
        };
    },
    mounted () {
        this.uid = localStorage.getItem('_uid')
        this.rolePrivilegesList = this.getRolePrivileges();
        this.$nextTick(() => {
            setTimeout(() => {
                let userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.userInfo = userInfo;
                this.acc = userInfo.realName
                    ? userInfo.realName
                    : userInfo.tel
                        ? userInfo.tel
                        : userInfo.emai
                            ? userInfo.email
                            : userInfo.username;
            }, 100);
        });
        this.getLimitMsg();
    },
    watch: {
        userCompany: {
            handler (n) {
                // this.getCompanyIsOld()
                // let newValue = JSON.parse(n);
                // console.log(newValue,'newValuenewValuenewValue');
                // newValue.modules.forEach(item => {
                //     this.modulesList.push(item.moduleCode)
                // })
                // console.log(this.modulesList,'this.modulesList');
                // 如果推广期截止前，激活人数超出购买人数，推广期截止前7天每天第一次登录给弹窗强提醒
                if (JSON.parse(this.userCompany).promoteSurplusDay > -1) {
                    if (JSON.parse(this.userCompany).promoteSurplusDay <= 7 && JSON.parse(this.userCompany).promoteSurplusDay > -1 && this.botLimitData.activatePeopleNum > this.botLimitData.buyUseBotPeopleNum && !JSON.parse(this.userCompany).remind) {
                        this.promptVisible = true;
                        this.$http.get(this.requestUrl.companyManage.recordRemind + '/' + JSON.parse(this.userCompany).id).then((res) => {
                            if (res.data && res.data.code === "0") {
                                console.log(res);
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                    }
                }
            },
            deep: true
        },
        activeVersionObj: {
            handler (n) {
                if (n.OPEN.expiresIn !== '无限制') {
                    this.openBeOverdueTime = Math.abs(Number(n.OPEN.expiresIn));
                }
                if (n.WORKORDER.expiresIn !== '无限制') {
                    this.workerBeOverdueTime = Math.abs(Number(n.WORKORDER.expiresIn));
                }
                if (n.KNOWLEDGE.expiresIn !== '无限制'){
                  this.knowledgeBeOverdueTime = Math.abs(Number(n.KNOWLEDGE.expiresIn));
                }
                if (n.IM.expiresIn !== '无限制'){
                  this.IMBeOverdueTime = Math.abs(Number(n.IM.expiresIn));
                }
                if (n.ASKAI.expiresIn !== '无限制'){
                  this.askAIBeOverdueTime = Math.abs(Number(n.ASKAI.expiresIn));
                }
            },
            deep: true
        },
        modulesList: {
            handler (n) {
                console.log(n, 'mmmm');
            },
            deep: true
        }
    },
    methods: {
        // 立即续费
        nowRenew () {
            this.nowRenewPopup = true;
        },
        // Copy成功
        onCopy (e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError (e) {
            this.$message.error("抱歉，复制失败！");
        },
        // 判断企业是不是旧客
        getCompanyIsOld () {
            this.$http.get(this.requestUrl.companyManage.getCompanyIsOld).then((res) => {
                if (res.data.code === "0") {
                    this.CompanyIsOld = res.data.data;
                    sessionStorage.setItem('CompanyIsOld', this.CompanyIsOld)
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 去增购
        goBuyMore () {
            this.buyMoreVisible = true;
        },
        gotoSetSys () {
            this.$router.push({ path: "/_staffManage" });
        },
        retryAuth () {
            this.$emit("retryAuth");
        },
        percentageFormat (use, all) {
            let allval = Number(all),
                useVal = Number(use);
            if ((allval === -1 || allval === "∞" || allval == 0 || allval == "null") && useVal != 0) {
                return 5;
            } else if (useVal == 0) {
                return 0;
            } else {
                return (useVal / allval) * 100;
            }
        },
        goToVersion (varsionInfo) {
            // this.$router.push({ name: "version", params: varsionInfo });
        },
        getLimitMsg () {
            this.$http
                .get(this.requestUrl.version.selectLimitMsg)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.botLimitData = res.data.data;
                        this.$parent.botLimitData.totalPeopleNum = res.data.data.totalPeopleNum;
                        this.botLimitData.activatePeopleNum = this.botLimitData.activatePeopleNum ? this.botLimitData.activatePeopleNum : 0;
                        if (this.botLimitData.canCreateNewBotNum === -1) {
                            this.botLimitData.canCreateNewBotNum = "∞";
                        }
                        if (this.botLimitData.canCreateNewIntentNum === -1) {
                            this.botLimitData.canCreateNewIntentNum = "∞";
                        }
                        if (this.botLimitData.buyUseBotPeopleNum === -1) {
                            this.botLimitData.buyUseBotPeopleNum = "∞";
                        }

                    }
                })
                .catch((res) => { });
        },
        goBuy (type) {
            this.$refs.payPopup.popupType = type;
            if (type === 'capacity') {
                this.$refs.payPopup.popupTips = '购买永久容量';
            } else {
                this.$refs.payPopup.popupTips = '次数包有效期为365天';
            }
            this.payPopupVisable = true;
        },
        paySuccess () {
            this.payPopupVisable = false;
            this.$emit('paySuccess');
        },
        isShowVersionDuration () {
            let bool = true;
            let arr = [];
            let modules = JSON.parse(this.userCompany).modules
            if (modules) {
                for (let i = 0; i < modules.length; i++) {
                    let module = modules[i]
                    if (module.moduleCode !== 'MANAGEMENT' && arr.indexOf(module.expiresIn) === -1) {
                        arr.push(module.expiresIn)
                    }
                }
            }
            if (arr.length === 1) {
                bool = false;
            }
            return bool
        }
    },
};
</script>
<style lang="less" scoped>
.no-limit {
    font-size: 14px;
}

.time-in-tip {
    font-size: 12px;
}

.gr-left-content-top {
    padding-bottom: 10px;
    height: calc(100% - 74px);
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sys-de-line {
        margin-top: 10px;
        // margin-bottom: 10px;
        display: block;
        height: 1px;
        background: #e0e6f7;
    }

    .gr-lct-comp-acc-sys {
        padding: 22px 24px 14px;
        display: flex;
        justify-content: space-between;

        .gr-lct-comp-acc-sys-lf {
            display: flex;
            justify-content: flex-start;
        }

        .gr-lct-comp-vertion-s-icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;


            .gr-lct-comp-vertion-s-icon-p {
                font-size: 24px;
                margin-top: -1px;
            }

            .gr-lct-comp-vertion-s-icon-t {
                height: 18px;
                line-height: 18px;
                font-size: 12px;
                font-weight: 400;
                border-radius: 0 15px 15px 0;
                padding-right: 6px;
                padding-left: 16px;
                margin-left: -13px;
                white-space: nowrap;
            }
        }

        .gr-lct-comp-vertion-s-1 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #392f41;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #392f41;
                    border: solid 1px #392f41;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-3 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #c19743;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #c19743;
                    border: solid 1px #c19743;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-2 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #5680ff;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #5680ff;
                    border: solid 1px #5680ff;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-4 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #5680ff;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #5680ff;
                    border: solid 1px #5680ff;
                    border-left: none;
                }
            }
        }

        .color-b {
            font-size: 20px;
            border-color: #366aff transparent transparent #366aff;
            color: #366aff;
        }

        .color-p {
            font-size: 20px;
            border-color: #877ef2 transparent transparent #877ef2;
            color: #877ef2;
        }

        .color-i {
            font-size: 20px;
            border-color: #09ddd5 transparent transparent #09ddd5;
            color: #09ddd5;
        }

        .color-ai {
            font-size: 20px;
            border-color: #366aff transparent transparent #366aff;
            color: #366aff;
        }

        .gr-lct-comp-acc-sys-s {
            flex: none;
            height: 0px;
            border-width: 6px;
            border-style: solid;
        }

        .gr-lct-comp-acc-sys-name {
            flex: none;
            margin-left: 6px;

            font-weight: 800;
        }
    }

    .gr-lct-comp-vertion {
        padding: 0 24px;
        display: flex;
        margin-bottom: 25px;

        .gr-lct-comp-vertion-s {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            //line-height: 50px;
            padding: 0 16px;
            background: #eff3ff;
            border-radius: 5px;

            .gr-lct-comp-vertion-s-name {
                font-size: 14px;
                font-weight: 400;
                color: #616161;
                white-space: nowrap;
            }

            .gr-lct-comp-vertion-s-name-o {
                color: #366aff;
                font-weight: 500;
            }

            .gr-lct-comp-vertion-s-name-w {
                color: #877ef2;
                font-weight: 500;
            }

            .gr-lct-comp-vertion-s-name-i {
                color: #09ddd5;
                font-weight: 500;
            }

            .gr-lct-comp-vertion-s-icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;


                .gr-lct-comp-vertion-s-icon-p {
                    font-size: 24px;
                    margin-top: -1px;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    height: 18px;
                    line-height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 0 15px 15px 0;
                    padding-right: 6px;
                    padding-left: 16px;
                    margin-left: -13px;
                    white-space: nowrap;
                }
            }
        }

        .gr-lct-comp-vertion-s-1 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #392f41;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #392f41;
                    border: solid 1px #392f41;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-3 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #c19743;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #c19743;
                    border: solid 1px #c19743;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-2 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #5680ff;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #5680ff;
                    border: solid 1px #5680ff;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-s-4 {
            .gr-lct-comp-vertion-s-icon {
                .gr-lct-comp-vertion-s-icon-p {
                    color: #5680ff;
                }

                .gr-lct-comp-vertion-s-icon-t {
                    color: #5680ff;
                    border: solid 1px #5680ff;
                    border-left: none;
                }
            }
        }

        .gr-lct-comp-vertion-t {
            display: flex;
            flex: 1;
            justify-content: space-between;
            //margin-left: 16px;
            // margin-right: 16px;
            height: 50px;
            line-height: 50px;
            padding: 0 16px;
            background: #eff3ff;
            border-radius: 5px;

            &.version-time {
                //margin-right: 16px;
            }

            .gr-lct-comp-vertion-t-name {
                font-size: 14px;
                font-weight: 400;
                color: #616161;
                white-space: nowrap;
            }

            .gr-lct-comp-vertion-t-name-o {
                color: #366aff;
                font-weight: 500;
                white-space: nowrap;
            }

            .gr-lct-comp-vertion-t-name-w {
                color: #877ef2;
                font-weight: 500;
            }

            .gr-lct-comp-vertion-t-name-i {
                color: #09ddd5;
                font-weight: 500;
            }

            .gr-lct-comp-vertion-t-p {
                height: 30px;
                margin-top: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .gr-lct-comp-vertion-t-p-t {
                    margin-left: 6px;
                    white-space: nowrap;
                }

                .gr-lct-comp-vertion-t-p-n {
                    margin-left: 6px;
                    font-size: 20px;
                }
            }
        }
    }

    .now-renew {
        margin-top: -2px;
        margin-left: 18px;
        text-align: center;
        // width: 136px;
        padding: 0 10px;
        height: 22px;
        line-height: 22px;
        background: #366aff;
        border-radius: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        white-space: nowrap;
    }

    .gr-lct-comp-progress {
        padding: 0 24px;
        display: flex;

        .margin-l-12 {
            margin-left: 12px !important;
        }

        .margin-r-12 {
            margin-right: 6px !important;
        }

        .margin-r-16 {
            margin-right: 16px !important;
        }

        .gr-lct-comp-progress-cell-goto {
            display: flex;
            justify-content: flex-start;

            .gr-lct-limit-des {
                // width: 168px;
                font-size: 12px;
                font-weight: 400;
                color: #9da5bf;
            }

            .gr-lct-limit-btn {
                margin-top: -2px;
                margin-left: 18px;
                text-align: center;
                // width: 136px;
                padding: 0 10px;
                height: 20px;
                line-height: 20px;
                background: #ffffff;
                border: 1px solid #a1b9ff;
                border-radius: 13px;
                font-size: 12px;
                font-weight: 400;
                color: #366aff;
                cursor: pointer;
                white-space: nowrap;
            }
        }

        .gr-lct-comp-progress-cell {
            flex: 1;

            .gr-lct-comp-progress-c-t {
                //height: 20px;
                //line-height: 20px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                .gr-lct-comp-progress-c-t-p {
                    flex: none;
                    width: 110px;
                    word-break: break-word;
                    color: #00d1c9;
                }

                .knowledge-text {
                    width: 140px;
                }

                .gr-lct-comp-progress-c-c {
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .el-progress {
                        margin-top: 7px;
                        width: 100%;
                    }
                }

                .gr-lct-comp-progress-c-t-n {
                    flex: none;
                    // width: 110px;
                    margin-left: 20px;
                    text-align: right;

                    span {
                        color: #9da5bf;
                    }
                }

                .buy-text {
                    width: 80px;
                    text-align: right;
                    color: #366aff;
                    cursor: pointer;
                }
            }
        }

        .gr-lct-comp-progress-cel-m {
            margin: 0;
        }

        .gr-lct-comp-progress-cell-b {
            border-bottom: none;
        }

        &.CompanyIsOld {
            .gr-lct-limit-des {
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                color: #FE5965;
                display: flex;
                justify-content: space-between;
            }

            .center {
                align-items: center;
            }
        }
    }

    /deep/ .buy-more-popup {
        .first-column,
        .second-column,
        .buy-more-telphone {
            margin: 15px 0;
            font-size: 14px;
            font-weight: 500;
            color: #616161;
        }

        .first-column {
            display: flex;

            .buy-num {
                margin-right: 40px;
            }
        }

        .second-column {
            color: #A9B3C6;
        }

        .popup-tip {
            padding-top: 0 !important;
        }

        .dialog-footer {
            padding-top: 0 !important;
        }
    }
}
</style>
