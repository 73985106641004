import { render, staticRenderFns } from "./bottomCommon.vue?vue&type=template&id=18630569&scoped=true"
import script from "./bottomCommon.vue?vue&type=script&lang=js"
export * from "./bottomCommon.vue?vue&type=script&lang=js"
import style0 from "./bottomCommon.vue?vue&type=style&index=0&id=18630569&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18630569",
  null
  
)

export default component.exports