<template>
<div class="content">
  <div class="nav">
    <div class="logo">
      <img height="30px" width="30px" src="../../assets/images/logo1.png" alt/>
    </div>
    <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#333333"
          text-color="#fff"
          active-text-color="#366AFF"
          :router="true"
          :collapse="true"
    >
      <el-menu-item index="/"><i class="el-icon-s-home" :class=" isHomeActive? 'active-item':'' "></i><span slot="title">主页</span></el-menu-item>
      <el-menu-item :index="activeIndex" v-if="roles !== '人工客服'&&roleVisable"><i class="el-icon-office-building" :class="isCompanyManageActive? 'active-item':'' "></i><span slot="title">企业管理</span></el-menu-item>
      <el-menu-item index="/personal"><i class="el-icon-s-custom" :class="isPersonalActive ? 'active-item':'' "></i><span slot="title">个人中心</span></el-menu-item>
      <!-- <el-menu-item index="3"><i class="el-icon-setting"></i></el-menu-item> -->
    </el-menu>
    <div v-if="auditStatus==0" class="certified-mark dark">未认证</div>
    <div v-if="auditStatus==1" class="certified-mark blue">审核中</div>
    <div v-if="auditStatus==2" class="certified-mark orange">已认证</div>
    <div v-if="auditStatus==3" class="certified-mark red">未通过</div>
    <div class="user-info">
      <el-dropdown @command="handleCommand">
        <span class="head_portrait">
          <img src="../../assets/images/index.png">
        </span>
        <el-dropdown-menu slot="dropdown" style="min-width: 150px;">
          <el-dropdown-item  style="color: black;word-break: break-word;border-bottom: 2px solid #f5f8fb;line-height: 30px">{{userInfo.username}}</el-dropdown-item>
          <el-dropdown-item command="a" class="borderBottom" ><i class="el-icon-user-solid" ></i>个人中心</el-dropdown-item>
          <el-dropdown-item command="logout" style="line-height: 40px"><i class="el-icon-switch-button" style="font-size: 16px;padding-right: 10px;color: #366AFF"></i>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
</div>
</div>
</template>
<script>
import {redirectUri} from "../../const/redirectUri";
import {redirect2LoginPage} from "../../const/redirectUri";
import {operable} from "../companyManage/js/operable";

export default {
  data(){
    return{
      currentPath:'',
      isHomeActive: false,
      isCompanyManageActive :false,
      isCertified: true,
      isPersonalActive:false,
      auditStatus:0,
      roles:'',
      userInfo:{},
	  activeIndex:''
    }
  },
  computed:{
    roleVisable:function () {
      return operable('PORTAL_MANAGEMENT')
    }
  },
  methods:{
    handleCommand(command) {
        //this.$message('click on item ' + command);
        if(command == 'a'){
          this.$router.push({path: '/personal'});
        }else if (command === "logout") {
            localStorage.setItem("cancel_bind_phone", 0);
            this.$http.delete("/api/sso/auth/sign-out").then(res => {
                if (res.status === 200) {
                   redirect2LoginPage();
                } else {
                    this.$message.error(res.message);
                }
            });
        }
    },
    getCurrentUrl(){
        this.currentPath = this.$route.path;
        if(this.currentPath == '/'){
          this.isCompanyManageActive = false;
          this.isHomeActive = true;
          this.isPersonalActive=false
        }
        else if(this.currentPath == '/accountManage' ||this.currentPath == '/companyInfo' || this.currentPath == '/staffManage' || this.currentPath=='/roleManage' || this.currentPath=='/addRole' || this.currentPath === '/dataRoleManage' || this.currentPath =='/upDownManage'){
          this.isCompanyManageActive = true
          this.isHomeActive = false;
          this.isPersonalActive=false
        }
        else if(this.currentPath =='/personal')
        {
          this.isPersonalActive=true
          this.isCompanyManageActive = false
          this.isHomeActive = false;
        }
    },
    getAuditStatus(){
        this.$http.get(this.requestUrl.companyManage.getUserCompany).then(res => {
            if (res.data && res.data.code === "0") {
                if(res.data.data!=null){
                    this.auditStatus = res.data.data.status;
                }
            }
        }).catch((res) => {
            // location.hash = "/auth";
        });
    },
    //获取用户的角色
    getRole(){
      
      this.$http.get('/api/account/current').then(res=>{
        if(res.data.code==='0'){

          this.userInfo=res.data.data
          localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
          console.log()
          if (this.$route.name == "Home") {
            this.$emit("setIsShowTel", {
              tel: res.data.data.tel,
              oldUser: res.data.data.oldUser,
              source: res.data.data.source
            });
            this.$emit("setHasCompanyName", res.data.data.realName);
          }
          if(this.userInfo.roles[0]!==null)
          {
            this.roles=this.userInfo.roles[0]
          }
        	// rolePrivileges json串 有一个关于上下游的选项选中了，并且source不等于5显示上下游和其他   boo = true && source !==5
			//  source=5就只显示上下游
			let boo = false;
			JSON.parse(localStorage.getItem('rolePrivileges')).forEach(item=>{
					if(item.id.indexOf('PORTAL_MANAGEMENT_UPANDDOWN') > -1){
						boo = true;
						return;
					}
				})
				
			let isShowOther = JSON.parse(localStorage.getItem('userInfo')).source !== 5 ? true : false;
			let isShowUpDownManage = (boo && JSON.parse(localStorage.getItem('userInfo')).source !== 5) || JSON.parse(localStorage.getItem('userInfo')).source === 5 ? true :false;
			this.activeIndex = isShowOther ? '/accountManage' : '/upDownManage';
			localStorage.setItem('isShowOther',isShowOther);
			localStorage.setItem('changeShowUpDownManage',isShowUpDownManage)
				
			
        }
      })
    }
  },
  mounted(){
      this.getCurrentUrl();
      this.getAuditStatus();
      this.getRole()
  }
}
</script>
<style lang="less" scoped>
.content{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 64px;
  .nav{
    width: 64px;
    background-color: #333333;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .logo{
      width: 100%;
      height: 55px;
      line-height: 55px;
      background-color: #366AFF;
      color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .certified-mark{
      padding: 5px 2px;
      height: 60px;
      line-height: 18px;
      width: 25px;
      margin: 0 auto;
      border-radius: 6px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }
    .orange{
      background-color: #ff9900;
    }
    .dark{
      background-color: #aaaaaa;
    }
    .blue{
      background-color: #366AFF;
    }
    .red{
      background-color: red;
    }
    .user-info{
      height: 40px;
      line-height: 40px;
      color: #f5f5f5;
      margin-top: 15px;
      .head_portrait{
        display: inline-block;
        overflow: hidden;
        color: #fff;
        font-size: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        img{
          width: 30px;
          height: 30px;
        }
      }
    }
}
}
.borderBottom{
  border-bottom: 2px solid #f5f8fb;
  line-height: 40px;
  i{
    color: #366AFF;
   font-size: 16px;
    padding-right: 10px;
  }
}
.el-menu-vertical-demo{
  width: 64px;
  height: 85vh;
}
.active-item{
  color: #366AFF !important;
}
.el-menu-item{
  i{
    font-size: 20px;
  }
}
</style>