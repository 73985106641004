import { render, staticRenderFns } from "./Action_enforce.vue?vue&type=template&id=1e77e54c&scoped=true"
import script from "./Action_enforce.vue?vue&type=script&lang=js"
export * from "./Action_enforce.vue?vue&type=script&lang=js"
import style0 from "./Action_enforce.vue?vue&type=style&index=0&id=1e77e54c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e77e54c",
  null
  
)

export default component.exports