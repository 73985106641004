<template>
    <div id="radio-fonfig">
        <div class="radio-btn-list-header">
            <span class="radio-btn-list-header-type" :style="$i18n.locale === 'en' ? 'width:165px' : ''">{{$t('flowComponent.radioConfig.title')}}</span>
            <span class="radio-btn-list-header-name">{{$t('flowComponent.webhook.radioName')}}</span>
            <span class="radio-btn-list-header-action">{{$t('flowComponent.webhook.radioClick')}}</span>
        </div>
        <div class="radio-btn-list">
            <div
                v-for="(rCell, rCellIndex) in activeRadioBtnList"
                :key="rCellIndex"
                class="radio-btn-list-cell"
            >
                <span class="select-cell-move icon-handle-radio-btn">
                    <i class="iconfont guoran-tongyichicun-16-10-paixu"></i>
                </span>
                <div class="select-cell select-cell-type" :style="$i18n.locale === 'en' ? 'width:170px' : ''">
                    <el-select
                        v-model="rCell.type"
                        @change="answerRadioTypeChange($event, rCellIndex)"
                        size="small"
                        :key="'rblcT' + rCellIndex"
                        :placeholder="$t('common.selectPlaceholder')"
                        class="select-type"
                        :style="$i18n.locale === 'en' ? 'width:100%' : 'width:110px'"
                    >
                        <el-option
                            v-for="item in selectTypeOptions"
                            :key="item.value"
                            :label="$t('flowComponent.radioConfig.' + item.value)"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div
                    v-if="rCell.type !== 'FIXED'"
                    class="select-cell select-cell-name"
                >
                    <!-- <input-insertentity class="answer-text-cell-input-insertentity" :placeholderType="placeholderType" :ref="'inputInsertaut' + rCellIndex" :unit="{'content': {'list': activeRadioBtnList}}" :activeRadioBtnList="activeRadioBtnList" unitIndex="0" :textCellInd="rCellIndex"
                    @templateConfigVisibleHandle="templateConfigVisibleHandle"></input-insertentity> -->
                    <div class="edit-ig-des-content">
                        <ckeditor
                            :ref="'auCkeditor_' + rCellIndex"
                            class="gr-answer-text-ckeditor"
                            :editor="editorInline"
                            :config="editorConfig"
                            v-model="rCell.name"
                            @focus="onEditorFocus"
                            @paste="handlePaste"
                        >
                        </ckeditor>
                        <el-tooltip
                            content="按钮名称只能包含一个变量，请删除后重新插入变量"
                            placement="top"
                        >
                            <span
                                v-show="
                                    rCell.name.includes(
                                        'ask-component-placeholder'
                                    )
                                "
                                class="insert-var"
                            >
                                <i
                                    class="iconfont guoran-tongyichicun-bianliang"
                                ></i>
                            </span>
                        </el-tooltip>
                        <span
                            v-show="
                                !rCell.name.includes(
                                    'ask-component-placeholder'
                                )
                            "
                            @click="
                                templateConfigVisibleHandle(
                                    'usual-text-deep-radio-placeholder',
                                    rCellIndex
                                )
                            "
                            class="insert-var"
                        >
                            <i
                                class="iconfont guoran-tongyichicun-bianliang"
                            ></i>
                        </span>
                    </div>
                </div>
                <div
                    v-if="rCell.type == 'FIXED'"
                    class="select-cell select-cell-name"
                >
                    <!-- maxlength="40" -->
                    <el-input
                        size="small"
                        v-model="rCell.name"
                        :placeholder="$t('flowComponent.webhook.radioNamePla')"
                    ></el-input>
                </div>
                <div class="select-cell select-cell-value">
                    <el-select
                        v-model="rCell.scope"
                        @change="answerRadioValueChange"
                        size="small"
                        :key="'rblcS' + rCellIndex"
                        :placeholder="$t('common.selectPlaceholder')"
                        class="select-type"
                        :style="$i18n.locale === 'en' ? 'width:110px' : ''"
                    >
                        <el-option
                            v-for="item in jumpTypeOptions"
                            v-show="
                                (isStartOrEnd && item.value != 'NODE') ||
                                !isStartOrEnd
                            "
                            :key="item.value"
                            :label="$t('common.' + item.value)"
                            :value="item.value"
                        ></el-option>
                    </el-select>

                    <SelectAddnode
                        v-if="rCell.scope === 'NODE'"
                        :nodeOptions="nodeOptions"
                        :nodeId="rCell.value"
                        :defaultParentNodeId="nodeId"
                        :nodeSelectARef="`actionEnforceAddnode_${activeRadioConfigIndex[0]}_${rCellIndex}`"
                        :intentId="intentId"
                        :selectAddnodeKey="'rblcSA' + rCellIndex"
                        @getNodeOptions="getNodeOptions"
                        @setAddNodeId="setAddNodeId"
                        :index="rCellIndex"
                    ></SelectAddnode>
                    <template v-if="!isBotLink">
                        <el-cascader
                            filterable
                            v-show="rCell.scope === 'INTENT'"
                            v-model="rCell.value"
                            :placeholder="$t('flowComponent.radioConfig.selectIntentTip')"
                            size="small"
                            :options="intentTree4Radio"
                            :key="'rblcEc' + rCellIndex"
                            :props="{
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </template>
                    <!-- 应用统一配置欢迎语 -->
                    <template v-else>
                        <el-popover
                            :ref="'radioConfigProver' + rCellIndex"
                            :key="'rblcEc' + rCellIndex"
                            placement="bottom"
                            width="300"
                            trigger="click"
                            popper-class="set-welcome-message-search-intent-poprver"
                            :visible-arrow="false"
                            @show="showPover(rCell)"
                        >
                            <el-input
                                class="search-intent-input"
                                size="small"
                                :placeholder="$t('flowComponent.searchOrSelectIntent')"
                                v-model="rCell.valueText"
                                slot="reference"
                                @input="inputFn($event, rCell)"
                            >
                                <i slot="suffix" class="el-icon-arrow-down"></i>
                            </el-input>
                            <el-tree
                                :key="'rblcEc' + rCellIndex"
                                class="choose-intent-tree"
                                style="
                                    width: 300px;
                                    height: 300px;
                                    overflow-x: hidden;
                                    overflow-y: auto;
                                "
                                :data="intentTree4Radio"
                                :props="{
                                    children: 'children',
                                    label: 'name',
                                    id: 'id',
                                    isLeaf: 'isLeafNode',
                                }"
                                :filter-node-method="filterNode"
                                node-key="id"
                                ref="tree"
                            >
                                <span
                                    :class="[
                                        'custom-tree-node',
                                        data.id.indexOf('B') === -1 &&
                                        data.id.indexOf('S') === -1 &&
                                        data.id.indexOf('T') === -1
                                            ? ''
                                            : 'disabled',
                                    ]"
                                    slot-scope="{ node, data }"
                                    @click="
                                        onNodeClick(
                                            data,
                                            node,
                                            rCell,
                                            rCellIndex
                                        )
                                    "
                                >
                                    <span class="custom-tree-node-left">
                                        <span :class="['data-label']">{{
                                            data.name
                                        }}</span>
                                    </span>
                                    <i
                                        class="el-icon-check"
                                        v-if="data.checked"
                                    ></i>
                                </span>
                            </el-tree>
                        </el-popover>
                    </template>
                </div>
                <span
                    v-if="rCellIndex === 0"
                    @click="addRCell"
                    class="answer-text-cell-handle-icon-add"
                >
                    <i class="el-icon-plus"></i>
                </span>
                <span
                    v-else
                    @click="deleteRCell(rCellIndex)"
                    class="answer-text-cell-handle-icon-delete"
                >
                    <i class="el-icon-close"></i
                ></span>
            </div>
        </div>
        <div class="page-config">
            <div class="page-config-pre">
              {{$t('flowComponent.radioConfig.page')}}
                <el-switch
                    size="mini"
                    v-model="groupVisible"
                    active-color="#366AFF"
                    inactive-color="#C0C4CC"
                >
                </el-switch>
                <span class="page-config-pre-des">
                    {{$t('flowComponent.radioConfig.openTip')}}
                </span>
            </div>
            <div class="page-config-after">
              {{$t('flowComponent.radioConfig.every')}}
                <el-input-number
                    v-model="groupNumber"
                    :step="1"
                    size="small"
                    :min="5"
                    step-strictly
                ></el-input-number>
                {{$t('flowComponent.radioConfig.tip2')}}
                <span class="page-config-after-des">{{$t('flowComponent.radioConfig.tip3')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import popup from "./../../../../components/popupNew.vue";
import SelectAddnode from "./../cell/SelectAddnode.vue";
import Sortable from "sortablejs";
import { v4 as uuidv4 } from "uuid";

function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";

export default {
    props: [
        "intentTree4Radio",
        "nodeOptions",
        "isStartOrEnd",
        "activeRadionConfigObj",
        "nodeId",
        "intentId",
        "activeRadioBtnList",
        "activeRadioConfigIndex",
        "isBotLink",
        "activeRadioBtGroupVisible",
        "activeRadioBtGroupNumber",
    ],
    components: { popup, SelectAddnode },
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            isCheck: true,
            selectTypeOptions: [
                {
                    value: "FIXED",
                    label: "固定点选",
                },
                {
                    value: "LOOP",
                    label: "循环点选",
                },
            ],
            placeholderType: "usual-text-placeholder",
            editorInline: CKEDITOR.InlineEditor,
            editorConfig: {
                toolbar: ["undo", "redo"],
            },
            currentEditorId: "",
            currentEditorPosition: "",
            groupVisible: false,
            groupNumber: 5,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.sortClickBtn();
        });
    },
    methods: {
        onEditorFocus(zh, editor) {
            changeEditor(editor);
            setTimeout(() => {
                console.log(editor);
            }, 300);
        },
        templateConfigVisibleHandle(type, cellIndex) {
            console.log(217, type, type);
            let param = { type: type };
            param.unitIndex = 0;
            param.lastEditor = lastEditor;
            param.multiple = false;
            param.webhookId_key = [""];
            param.cellIndex = cellIndex;
            param.isLimit = true;
            this.$emit("templateConfigVisibleHandle", param);
        },
        addConfirmDataResponse() {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span-editable",
                options: {
                    name: "单个webhook返回值",
                    editable: false,
                    data: webHookDataHolder(51, "data.name"),
                },
            });
        },

        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },

        addConfirmDataEntity(data, editable) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: entityDataHolder(data.name),
                },
            });
        },
        answerRadioTypeChange($event, rCellIndex) {
            console.log($event, rCellIndex);
            this.$set(this.activeRadioBtnList[rCellIndex], "name", "");

            setTimeout(() => {
                if ($event == "LOOP") {
                    this.$refs["auCkeditor_" + rCellIndex][0].$el.focus();
                }
            }, 300);
        },
        hasMultipleSpans(str) {
            // 匹配字符串中的所有 <span> 标签
            const spanRegex = /<span\b[^>]*>/g;
            const matches = str.match(spanRegex);

            // 如果匹配到的 <span> 标签数量大于 1，则返回 false
            return matches && matches.length > 1 ? true : false;
        },

        saveAnswerRadioBtnConfig() {
            let flag = true;
            this.activeRadioBtnList.forEach((item) => {
                if (item.name.trim() === "") {
                    flag && this.$message.error("点选按钮名称不能为空！");
                    flag = false;
                } else {
                    if (item.value === "" && flag) {
                        flag &&
                            this.$message.error("请选择跳转到的意图或节点！");
                        flag = false;
                    }
                }
            });
            // 校验是否有过歌变量
            let hasMultipleSpans = false;
            flag && this.activeRadioBtnList.forEach((item) => {
                if (this.hasMultipleSpans(item.name)) {
                    !hasMultipleSpans &&
                        this.$message.error(
                            "点选按钮名称不能含有多个变量，请检查后重新保存！"
                        );
                    hasMultipleSpans = true;
                }
            });
            if (flag && !hasMultipleSpans) {
                this.activeRadioBtnList.forEach((item) => {
                    let regex = /\${(.*?)}/;
                    let match = item.name.match(regex);
                    // 获取第一个匹配到的值
                    let value = match && match[1];
                    item.variable = value;

                    let str = item.name;
                    // 移除 <span> 标签及其内容
                    let withoutSpan = str.replace(
                        /<span.*?<\/span>/gi,
                        "${" + item.variable + "}"
                    );
                    // 删除所有 HTML 标签
                    let textOnly = withoutSpan.replace(/<[^>]+>/g, "");
                    item.text = textOnly;
                });
                this.$emit(
                    "confirmSaveAnswerRadioBtn",
                    this.activeRadioBtnList,
                    this.activeRadioConfigIndex[0]
                );
            }
        },
        addRCell() {
            this.activeRadioBtnList.push({
                name: "",
                scope: this.isStartOrEnd ? "INTENT" : "NODE",
                value: "",
                type: "FIXED",
                chooseIntentVisible: false,
            });
        },
        deleteRCell(rCellIndex) {
            this.activeRadioBtnList.splice(rCellIndex, 1);
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            this.$set(this.activeRadioBtnList[index], "value", nodeId);
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        sortClickBtn() {
            const box = document.querySelector(".radio-btn-list");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-radio-btn",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeRadioBtnList.splice(oldIndex, 1);
                    _this.activeRadioBtnList.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.activeRadioBtnList = _this.activeRadioBtnList;
        },
        inputFn(val, item) {
            this.$refs.tree[0].filter(val);
            this.$forceUpdate();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data, node, item, rCellIndex) {
            if (
                data.id.indexOf("B") > -1 ||
                data.id.indexOf("S") > -1 ||
                data.id.indexOf("T") > -1
            )
                return false;
            this.$set(item, "valueText", data.name);
            // this.$set(item,'valueList',[node.parent.parent.parent.data.id,node.parent.parent.data.id,node.parent.data.id,data.id])
            this.$set(item, "value", data.id);
            this.$set(data, "checked", true);
            this.$set(item, "apiKey", data.apiKey);
            this.$set(
                item,
                "answerId",
                "answerId" + uuidv4().toString().replaceAll("-", "")
            );
            this.$refs["radioConfigProver" + rCellIndex][0].doClose();
        },
        showPover(rCell) {
            let list = JSON.parse(JSON.stringify(this.intentTree4Radio));
            list.forEach((botItem) => {
                console.log(botItem, "botItem");
                botItem.children.forEach((skillItem) => {
                    skillItem.children.forEach((typeItem) => {
                        typeItem.children.forEach((intentItem) => {
                            intentItem.checked = false;
                            if (rCell.value == intentItem.id) {
                                intentItem.checked = true;
                            }
                        });
                    });
                });
            });
            this.intentTree4Radio = JSON.parse(JSON.stringify(list));
        },
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";
.edit-ig-des-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .gr-answer-text-ckeditor {
        width: 100%;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding-right: 38px;
      height: auto;
    }
    .ck-focused {
        border: 1px solid #366aff;
    }
    .insert-var {
        color: black;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-color: #dcdfe6;
        border-radius: 4px;
        cursor: pointer;
        margin-left: -32px;
        margin-right: 6px;
    }
}
.add-node-cell {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .add-node-cell-title {
        margin-bottom: 6px;
        text-align: left;
        .add-node-cell-title-pre {
            color: red;
        }
    }
    .add-node-cell-content {
        /deep/.el-select {
            width: 100%;
        }
    }
}
/deep/.el-cascader-node-createnode {
    display: flex !important;
    justify-content: flex-start !important;
    .el-cascader-node__label {
        flex: none;
        width: 60px;
        color: #366aff;
        padding: 0;
    }
    .el-cascader-node__prename {
        height: 38px;
        flex: auto;
    }
}
#radio-fonfig {
    border-radius: 5px;
    .page-config {
        .page-config-pre {
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-switch {
                margin: 0 10px;
            }
            .page-config-pre-des {
                font-size: 13px;
                color: #909399;
                text-align: left;
            }
        }
        .page-config-after {
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-input-number {
                margin: 0 10px;
            }
            .page-config-after-des {
                font-size: 13px;
                color: #909399;
            }
        }
    }
    .radio-btn-list-header {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 8px;
        .radio-btn-list-header-type {
            width: 120px;
            margin-left: 22px;
            flex: none;
        }
        .radio-btn-list-header-name {
            margin-left: 22px;
            width: 230px;
            flex: none;
        }
        .radio-btn-list-header-action {
            flex: auto;
        }
    }
    .radio-btn-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        .select-cell-move {
            color: #366aff;
            font-size: 20px;
            font-weight: 500;
            margin-right: 8px;
        }
        .select-cell-name {
            width: 215px;
            flex: none;
        }
        .select-cell-type {
            width: 125px;
            margin-right: 15px;
            flex: none;
        }
        .select-cell-value {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 15px;
            .select-type {
                width: 90px;
            }
            /deep/.inline-input {
                width: 170px;
                padding-right: 0;
            }
            /deep/.el-cascader {
                width: 170px;
                padding-right: 0;
            }
            /deep/.el-input__inner {
                border: none;
                padding-right: 0;
            }
            /deep/.el-input__suffix {
                display: none;
            }
        }
    }
}
</style>