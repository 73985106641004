<template>
  <div class="basicInfo">
    <el-form
        ref="bindForm"
        :rules="yunzhijiaBindRules"
        :model="yunzhijiaBindForm"
    >
      <el-form-item
          :label="$t('botLink.enterpriseNamePla')"
          prop="corpName"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.corpName"
            :placeholder="$t('botLink.enterpriseNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="corpId"
          :label="$t('botLink.yunZhiJiaCorpIdTip')"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.corpId"
            :placeholder="$t('botLink.EID')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('botLink.appName')"
          prop="appName"
      >
        <el-input
            v-model="yunzhijiaBindForm.appName"
            :placeholder="$t('botLink.appNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="appId"
          :label="$t('botLink.yunZhiJiaAppidTip')"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.appId"
            :placeholder="$t('botLink.appidPla')"
        ></el-input>
        <span
            v-if="warningVisible"
            style="
            color: #f56c6c;
            display: block;
            text-align: left;
            font-size: 12px;
            line-height: 16px;"
            class="warning-text"
        >*{{ $t("botLink.agentIdTip") }}</span
        >
      </el-form-item>
      <el-form-item
          prop="appSecret"
      >
        <el-input
            v-model="yunzhijiaBindForm.appSecret"
            :placeholder="$t('botLink.appSecretPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="signKey"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.signKey"
            :placeholder="$t('botLink.signKeyPla')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "basicInfo",
  data() {
    return {
      yunzhijiaBindRules: {
        appName: [
          {
            required: true,
            message: this.$t('botLink.appNamePla'),
            trigger: "blur"
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" }
        ],
        corpName: [
          {
            required: true,
            message: this.$t('botLink.enterpriseNamePla'),
            trigger: "blur"
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" }
        ],
        corpId: [
          {
            required: true,
            message: this.$t('botLink.EID'),
            trigger: "blur"
          },
          { max: 36, message: this.$t('channel.rule36Msg'), trigger: "blur" }
        ],
        appId: [
          {
            required: true,
            message: this.$t('botLink.appidPla'),
            trigger: "blur"
          },
          { max: 32, message: this.$t('channel.rule32Msg'), trigger: "blur" }
        ],
        appSecret: [
          {
            required: true,
            message: this.$t('botLink.appSecretPla'),
            trigger: "blur"
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" }
        ],
        signKey: [
          {
            required: true,
            message: this.$t('botLink.signKeyPla'),
            trigger: "blur"
          },
          { max: 64, message: this.$t('channel.rule64Msg'), trigger: "blur" }
        ]
      },
      yunzhijiaBindForm: {
        appId: "",
        appName: "",
        appSecret: "",
        contactSecret: "",
        corpId: "",
        corpName: "",
        signKey: ""
      },
      warningVisible: false
    };
  }
};
</script>

<style scoped>

</style>