<template>
  <div>
      <popup @closeEvent="closeEvent" v-if="showPopup" class="choose-dept">
            <div slot="popup-name" class="popup-name">{{$t('staffManage.chooseDeptTitle')}}</div>
            <div slot="popup-con">
				<el-tree
					key="chooseDept"
					ref="chooseDept"
					:data="treeDataCopy"
					@node-click="onNodeClick"
					:expand-on-click-node="false"
					:default-expand-all="true">
						<span
								class="custom-tree-node"
								slot-scope="{node,data}">
							<span class="custom-tree-node-left">
								<span>
									<i class="arsenal_icon arsenalsitemap1"></i>
								</span>
								<span class="data-label" >{{data.name}}</span>
							</span>
							<span v-show="data.checked">
								<i class="el-icon-check"></i>
							</span>
						</span>
                </el-tree>
            
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveChooseDept" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
  </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
import mixins  from '../mixins'  
export default {
	mixins:[mixins],
	props:{
		treeData:{
            type:Array,
            default(){
                return []
            }
        },
		showPopup:{
			type:Boolean,
			default:false
		},
		isMultiple:{
			type:Boolean,
			default:true
		},
	},
    components:{
		Popup
	},
    data(){
        return {
            checkedDeptPopup:[],
			treeDataCopy:[]
        }
    },
	watch:{
		 treeData:{
			handler(n){
				console.log(n,'nnnn');
				this.treeDataCopy = this.handlerChildrens(JSON.parse(JSON.stringify(n)));
			},
			deep:true
		},
	},
    methods:{
        // 关闭选择组织架构弹框
		closeEvent(){
            this.$emit('update:showPopup',false)
		},
		// 保存组织架构
		saveChooseDept(){
			this.checkedDeptPopup = [];
			this.judgeChildrens(this.treeDataCopy);
            this.$emit('checkedDept',this.checkedDeptPopup,this.treeDataCopy)
		},
	
		
		// 点击tree
		onNodeClick(data,node,el) {
			console.log(node,data);
            // JSON.parse(JSON.stringify(this.treeData)) 解决this.$set不生效的问题
            let list = JSON.parse(JSON.stringify(this.treeDataCopy))
			this.getAllNodeId(list,data);
            this.treeDataCopy = JSON.parse(JSON.stringify(list))
		},
		
    }
}
</script>

<style>

</style>