var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action_Jump"},[_c('div',{staticClass:"goto-list"},[_c('div',{staticClass:"jump-cell"},[_c('div',{staticClass:"jump-cell-l"},[_c('div',{staticClass:"left-select"},[_c('el-select',{staticStyle:{"width":"120px"},attrs:{"size":"small","placeholder":"请选择"},on:{"change":_vm.jumpTypeValueChange},model:{value:(_vm.unit.content.scope),callback:function ($$v) {_vm.$set(_vm.unit.content, "scope", $$v)},expression:"unit.content.scope"}},_vm._l((_vm.filterJumpTypeOptions(
                                _vm.jumpTypeOptions
                            )),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t('common.' + item.value),"value":item.value}})}),1)],1),_c('div',{staticClass:"right-select"},[(_vm.unit.content.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":_vm.unit.content.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionJumpAddnode_${_vm.com_index}`,"intentId":_vm.intentId,"index":0},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),(!_vm.isBotLink)?_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit.content.scope === 'INTENT'),expression:"unit.content.scope === 'INTENT'"}],attrs:{"filterable":"","placeholder":_vm.$t('flowComponent.selectIntent'),"size":"small","options":_vm.intentTree4Radio,"props":{
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }},model:{value:(_vm.unit.content.value),callback:function ($$v) {_vm.$set(_vm.unit.content, "value", $$v)},expression:"unit.content.value"}}):[_c('el-popover',{ref:"popover-jump",attrs:{"placement":"bottom","width":"300","trigger":"click","popper-class":"set-welcome-message-search-intent-poprver","visible-arrow":false},on:{"show":function($event){return _vm.showPover(_vm.unit.content)}}},[_c('el-input',{staticClass:"search-intent-input",attrs:{"slot":"reference","size":"small","placeholder":_vm.$t('flowComponent.searchOrSelectIntent')},on:{"input":function($event){return _vm.inputFn($event,_vm.unit.content.valueText)}},slot:"reference",model:{value:(_vm.unit.content.valueText),callback:function ($$v) {_vm.$set(_vm.unit.content, "valueText", $$v)},expression:"unit.content.valueText"}},[_c('i',{staticClass:"el-icon-arrow-down",attrs:{"slot":"suffix"},slot:"suffix"})]),_c('el-tree',{ref:"tree",staticClass:"choose-intent-tree",staticStyle:{"width":"300px","height":"300px","overflow-x":"hidden","overflow-y":"auto"},attrs:{"data":_vm.intentTree4Radio,"props":{
                                    children: 'children',
                                    label: 'name',
                                    id:'id',
                                    isLeaf: 'isLeafNode',
                                },"filter-node-method":_vm.filterNode,"node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{class:['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled'],on:{"click":function($event){return _vm.onNodeClick(data,node,_vm.unit.content)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{class:['data-label']},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('i',{staticClass:"el-icon-check"}):_vm._e()])}}])})],1)]],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }